import * as React from "react";
import FMTable from "../../../components/FMTable/FMTable";
import FMTableColumn from "../../../components/FMTable/FMTableColumn";
import { getMySubscriptionsFollowers } from "../../../store/actions/actionTypes";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks/reduxHooks";
import { AccountProjectNameCellFollower } from "./components/AccountProjectNameCell";
import { ActionsCell } from "./components/ActionsCell";
import { StatusCell } from "./components/StatusCell";

const LIMIT = 20;

export function YourFollowers () {
	const { data, count } = useAppSelector(state => state.copytrade.mySubscriptions.yourFollowers);
	const languageTrading = useAppSelector(state => state.language.languageTrading);
	const languageTrades = useAppSelector(state => state.language.languageTrades);
	const languageCopytrade = useAppSelector(state => state.language.languageCopytrade);
	const dispatch = useAppDispatch();

	const fetchYourFollowersByParts = React.useCallback(() => {
		if ((data && data.length < count) || !data) {
			const currentDataLength = data?.length || 0;
			const page = Math.ceil(currentDataLength / LIMIT + 1);
			dispatch(getMySubscriptionsFollowers({ page, limit: LIMIT }));
		}
	}, [ data, count ]);

	React.useEffect(() => {
		fetchYourFollowersByParts();
	}, [ data?.length ]);

	return (
		<FMTable
			data={data}
			targetRowCount={count}
			mobileScheme={{
				mode: "auto"
			}}
		>
			<FMTableColumn
				id="ConnectionId"
				label={languageTrading.trd_102}
			/>
			<FMTableColumn
				id="ProjectName"
				label={languageTrading.reb_txt_9}
				component={AccountProjectNameCellFollower}
			/>
			<FMTableColumn
				id="CreateTime"
				label={languageCopytrade.trd_joined}
			/>
			<FMTableColumn
				id="StatusId"
				label={languageTrades.hot_27}
				component={StatusCell}
			/>
			<FMTableColumn
				id="PendingRollOver"
				label={languageCopytrade.trd_278}
			/>
			<FMTableColumn
				id="Activity"
				label={languageTrading.trd_107}
				component={ActionsCell}
			/>
		</FMTable>
	);
}
