import * as React from "react";
import { Grid, Typography } from "@mui/material";
import { useIsRtl } from "../../../utils/hooks/useIsRtl";
import { FormContext } from "../Form";
import { useWatch } from "react-hook-form";
import { useIsMobile } from "../../../utils/hooks/useIsMobile";

export interface FormBottomWrapperProps {
	forFields: string[];
	label: string;
	onChange: (value: any) => string | number;
	children?: React.ReactNode;
}

export function FormBottomWrapper (props: FormBottomWrapperProps): JSX.Element {
	const { forFields, label, onChange, children } = props;
	const { control } = React.useContext(FormContext);
	const [ fieldValue, setFieldValue ] = React.useState<string | number>("");

	const fields = useWatch({ name: forFields, control });

	React.useEffect(() => {
		setFieldValue(onChange(fields));
	}, fields);

	return (
		<FormBottomWrapperUI
			label={label}
			value={fieldValue}
		>
			{children}
		</FormBottomWrapperUI>
	);
}

export interface FormBottomWrapperUIProps {
	value: string | number;
	label: string;
	children: React.ReactNode,
}

export function FormBottomWrapperUI (props: FormBottomWrapperUIProps): JSX.Element {
	const { value, label, children } = props;
	const isRtl = useIsRtl();
	const isMobile = useIsMobile();

	return (
		<Grid
			container
			flexDirection={isMobile ? "column" : isRtl ? "row-reverse" : "row"}
			alignItems="center"
			justifyContent="space-between"
			sx={{
				marginTop: isMobile ? "20px" : "0",
				background: "#E2E7E8",
				padding: "30px 32px"
			}}
		>
			<Grid
				sx={isMobile
					? {
						display: "flex",
						flexDirection: "column"
					}
					: {}}
				item
			>
				<Typography
					fontSize={18}
					className="bold"
					color="#5C7080"
					align={isMobile ? "center" : isRtl ? "right" : "left"}
				>
					{label}
				</Typography>
				<Typography
					fontSize={24}
					color="#5C7080"
					className="light"
					align={isMobile ? "center" : isRtl ? "right" : "left"}
				>
					{value}
				</Typography>
			</Grid>
			<Grid item>
				{children}
			</Grid>
		</Grid>
	);
}
