import React, { forwardRef, useState } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FormHelperText } from "@mui/material";

interface inputProps{
	value?: string | number | null | undefined
	label?: string
	inputType?: string
	type?:string
	error?: boolean
	helpertext?: string | null | undefined
}

const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
	event.preventDefault();
};

export const Input = forwardRef<HTMLInputElement, inputProps>((props, ref) => {
	const [ show, setShow ] = useState(false);
	const handleClickShowPassword = () => { setShow(!show); };

	const inputProps = {
		value: props.value,
		label: props.label
	};

	const EndAdornment = () => {
		if (props.inputType && props.inputType === "password") {
			return (
				<InputAdornment position="end">
					<IconButton
						aria-label="toggle password visibility"
						onClick={handleClickShowPassword}
						onMouseDown={handleMouseDownPassword}
						edge="end"
					>
						{show ? <VisibilityOff/> : <Visibility/>}
					</IconButton>
				</InputAdornment>
			);
		}
	};

	return (
		<FormControl
			sx={{ m: 1, width: "100%" }}
			error={props.error}
			variant="outlined"
			size="small"
		>
			<InputLabel
				// htmlFor="outlined-adornment-password"
			>
				{props.label}
			</InputLabel>
			{props.inputType === "password"
				? <OutlinedInput
					inputRef={ref}
					type={show ? "text" : "password"}
					fullWidth
					endAdornment={EndAdornment()}
					{...inputProps}
				/>
				: <OutlinedInput
					inputRef={ref}
					fullWidth
					{...inputProps}
				/>
			}
			<FormHelperText>{props.helpertext}</FormHelperText>
		</FormControl>
	);
});
