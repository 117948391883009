import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import DashboardFooter from "./DashboardFooter";
import DashboardPage from "./DashboardPage";
import { IChildren } from "../../types/common";

export default function DashboardMobileContent ({ children }: { children: IChildren}) {
	return (
		<Container sx={{ mt: "80px", mb: 4, marginX: 0 }}>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					height: "max-content",
					minHeight: "calc(100vh - 128px)",
					padding: 0,
					overflow: "hidden"
				}}
			>
				{children &&
					<DashboardPage>
						{children}
					</DashboardPage>
				}
				<DashboardFooter/>
			</Box>
		</Container>
	);
};
