import Swal from "sweetalert2";

export const FMAlert = Swal.mixin({
	// toast: true,
	backdrop: true,
	position: "center",
	showConfirmButton: true,
	// timer: 3000,
	timerProgressBar: true,
	// confirmButtonColor: "#2988CA",
	// cancelButtonColor: "#ED1F24",
	cancelButtonColor: "#d9534f",
	confirmButtonColor: "#5cb85c",
	iconHtml: "",
	didOpen: (toast) => {
		toast.addEventListener("mouseenter", Swal.stopTimer);
		toast.addEventListener("mouseleave", Swal.resumeTimer);
	}
});
