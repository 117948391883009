import * as React from "react";
import { Status } from "./types";
import { useQuery } from "../../utils/hooks/useQuery";
import { Alert, AlertColor } from "@mui/material";
import { useAppSelector } from "../../utils/hooks/reduxHooks";
import { useIsRtl } from "../../utils/hooks/useIsRtl";

const Statuses = {
	[Status.SUCCESS]: Success,
	[Status.CANCEL]: Cancel,
	[Status.FAIL]: Fail
};

export function TransactionStatus () {
	const query = useQuery();
	const status = React.useMemo(() => query.get("status") as Status, [ query ]);

	const UI = React.useMemo(() => Statuses[status], [ status ]);

	if (!status || !UI) return <></>;
	return <UI />;
};

function Success () {
	return <StatusUI
		languageCode="dspt-err-2"
		severity="success"
	/>;
}

function Cancel () {
	return <StatusUI
		languageCode="dspt-err-1"
		severity="error"
	/>;
}

function Fail () {
	return <StatusUI
		languageCode="with-p-16"
		severity="error"
	/>;
}

interface StatusUIProps {
	languageCode: string;
	severity: AlertColor;
}

function StatusUI (props: StatusUIProps) {
	const { languageCode, severity } = props;
	const lang = useAppSelector((status) => status.language.languageMyAccounts);
	const isRtl = useIsRtl();

	return (
		<Alert
			sx={{
				display: "flex",
				flexDirection: isRtl ? "row-reverse" : "row"
			}}
			elevation={0}
			variant="filled"
			severity={severity}
		>
			{lang[languageCode]}
		</Alert>
	);
}
