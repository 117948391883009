import * as Yup from "yup";
export const withdrawMobileMoneyValidationSchema = () => Yup.object().shape({
	amount: Yup.string().required(),
	accountNumber: Yup.string().required(),
	bankCurrency: Yup.string().required(),
	bankAccountName: Yup.string().required(),
	bankAccountNumber: Yup.string().required(),
	bankName: Yup.string().required(),
	branch: Yup.string().required()
});
