import * as React from "react";
import { Box } from "@mui/material";
import { CleanButton } from "../../../../../components/UI/CleanButton";
import { DefaultModal } from "../../../../../components/UI/Modals";
import { useAppSelector } from "../../../../../utils/hooks/reduxHooks";
import { PHOTOS_URL } from "./AvatarSelect";

export interface ISelectPhotoModalProps {
	isOpen: boolean;
	onClose: () => void;
	onSelect: (args: { selectedPhoto: string, path: string }) => void;
}

export const photos = [
	"avatar-01.png",
	"avatar-02.png",
	"avatar-03.png",
	"avatar-04.png",
	"avatar-05.png",
	"avatar-06.png",
	"avatar-07.png",
	"avatar-08.png",
	"avatar-09.png",
	"avatar-10.png",
	"avatar-11.png",
	"avatar-12.png",
	"avatar-13.png",
	"avatar-14.png",
	"avatar-15.png",
	"avatar-16.png",
	"avatar-17.png",
	"avatar-18.png"
];

export function SelectPhotoModal (props: ISelectPhotoModalProps) {
	const { isOpen, onClose, onSelect } = props;
	const { languageCopytrade } = useAppSelector(state => state.language);

	return (
		<DefaultModal
			title={languageCopytrade.select_photo_1}
			isOpen={isOpen}
			onClose={onClose}
		>
			<Box sx={{
				padding: "15px",
				display: "flex",
				flexWrap: "wrap"
			}}>
				{
					photos.map((photo) => (
						<Box key={photo} sx={{ padding: "8px" }}>
							<CleanButton onClick={() => onSelect(createValue(photo))}>
								<img src={PHOTOS_URL + photo} alt="copytrade-avatar" loading="lazy" width="50px" height="50px" />
							</CleanButton>
						</Box>
					))
				}
			</Box>
		</DefaultModal>
	);
}

function createValue (fileName: string) {
	return { selectedPhoto: fileName, path: PHOTOS_URL + fileName };
}
