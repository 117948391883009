import React, { FC } from "react";
import { Typography, TypographyProps } from "@mui/material";
import { useIsRtl } from "../../utils/hooks/useIsRtl";

const Title: FC<TypographyProps> = ({ children, ...props }) => {
	const isRtl = useIsRtl();
	return (
		<Typography
			fontWeight={"lighter"}
			fontSize={"18px"}
			lineHeight={"22px"}
			color={"secondary"}
			textAlign={isRtl ? "right" : "left"}
			{...props}
		>
			{children}
		</Typography>
	);
};

export default Title;
