import {
	SET_LANGUAGE_BONUS,
	SET_LANGUAGE_COPYTRADE,
	SET_LANGUAGE_DATATABLE,
	SET_LANGUAGE_FINANCE,
	SET_LANGUAGE_INFO,
	SET_LANGUAGE_MY_ACCOUNTS,
	SET_LANGUAGE_MY_TRADING,
	SET_LANGUAGE_PARTNERSHIP, SET_LANGUAGE_SMART_DOLLAR,
	SET_LANGUAGE_TRADING, SET_LANGUAGE_TRADES, SET_LANGUAGE_MAIL_SUPPORT, SET_LANGUAGE_BANNERS
} from "../actions/actionTypes";

const initial = {
	language: {
		for_pas_00: "Forgot Password?",
		for_pas_01: "We sent you an email containing the link to reset your password.",
		for_pas_02: "Fill in your email address. ",
		for_pas_03: "We will provide a link by email to reset your password.",
		for_pas_04: "Email",
		for_pas_05: "Account number",
		for_pas_06: "Submit",
		prof_fgt_01: "Your account has been temporarily deactivated due to inactivity. Please contact support for further assistance, Please enter valid e-mail address",
		prof_fgt_02: "Please enter valid e-mail address",
		prof_fgt_03: "Please enter valid account number",
		prof_fgt_04: "This password has already been used by other account under the same email.",
		prof_fgt_05: "Invalid user.",
		auth_part_client_mesg_07: "Or Sign in with",
		ps_dsc: "Log in to ForexMart Partner Cabinet by typing in your email or account number and password.",
		ps_kew: "ForexMart | Partner Login",
		ps_tit: "ForexMart | Partner Login",
		cs_dsc: "Access client cabinet and manage ForexMart account.",
		cs_kew: "ForexMart | Client Login",
		cs_tit: "ForexMart | Client Login",
		ps_01: "Sign in to Partner Area",
		ps_02: "Login",
		ps_03: "Password",
		ps_04: "Forgot password?",
		ps_05: "Sign In",
		ps_06: "New to ForexMart?",
		ps_07: "Partnership Registration",
		ps_08: "Open a Demo Account",
		ps_09: "Email or Account number",
		ps_10: "Password",
		ps_11: "Log in",
		cs_01: "Sign in to Client Area",
		cs_02: "Your new password has been sent to your E-mail",
		cs_03: "Login",
		cs_04: "Password",
		cs_05: "Forgot Password?",
		cs_06: "Submit",
		cs_07: "New to ForexMart?",
		cs_08: "Open a <span class=\"text-green\">Real Account</span>",
		cs_09: "Open a Demo Account",
		cs_10: "Register Here",
		cs_11: "Open a <span class=\"text-green\">Real Account</span>",
		login_field: "Log in",
		password_field: "Password",
		new_with_foo_01: " brand is authorized and regulated in various jurisdictions.",
		bn_li_f: "Feedback",
		bn_li_cu: "Contact Us",
		btm_nav_frx_txt1: "QUICK LINKS",
		bn_li_nw: "News",
		bn_li_ld: "Legal documentation",
		bn_li_rd: "Risk disclosure",
		bn_li_tnc: "Terms & Conditions",
		bn_li_pp: "Privacy Policy",
		sample_user: "Sample User",
		xnv_LO: "Logout",
		search: "Search...",
		xnv_R: "Register",
		xnv_PL: "Partner Login",
		xnv_CL: "Client Login",
		sb_li_0: "My Accounts",
		sb_li_1: "My Profile",
		curtra_03: "My Trading",
		sb_li_2: "Finance",
		sb_li_3: "Bonus",
		sb_li_4: "Partnership",
		sb_li_5: "Invite a friend",
		sb_li_6: "Mail Support",
		sb_li_7: "Rebate System",
		sb_li_8: "E-Statement",
		sb_li_9: "Promotional Materials",
		sb_li_10: "Credit Bonus $30",
		sb_li_11: "Cashback Program",
		sb_li_12: "Copytrading",
		sb_li_13: "Smartdollar",
		amount: "Amount",
		sb_a_0: "Verify Account",
		sb_a_1: "Deposit Funds",
		sb_a_2: "Withdraw Funds",
		sb_a_3: "Download MT4 Platform",
		xnv_webtrader: "Web Terminal",
		sc: "<p>Questions about deposit?<br> Let me help you.</p>",
		sc1: "<p>Questions? <br> How can I help you?</p>",
		sc2: "Start Chat",
		acc_status_text: [
			"PENDING",
			"VERIFIED LEVEL 2",
			"DECLINED",
			"VERIFIED LEVEL 1",
			"NO DOCUMENTS SUBMITTED",
			"You are just a step away from trading. Verify your account to get the full access to all ForexMart services, bonuses and other valuable offers.",
			"Unfortunately, some of your documents were declined. Please, resubmit the documents to complete verification. An email notification with further instructions has been sent to your mailbox.",
			"To proceed to upgrade verification up to the 2nd Level",
			"Verify my account",
			"Resubmit documents",
			"Upgrade to 2nd Level"
		],
		botnav_00: "About Us",
		botnav_01: "Deposit / Withdrawal",
		botnav_03: "Account Verification",
		botnav_05: "My Accounts",
		botnav_06: "My Profile",
		botnav_07: "Deposit Funds",
		botnav_08: "Withdraw Funds",
		botnav_09: "Platforms",
		botnav_11: "Feedback",
		botnav_13: "Follow Us",
		botnav_14: "Legal Documentation",
		botnav_15: "Privacy Policy",
		botnav_16: "Risk Disclosure",
		botnav_17: "Terms & Conditions",
		botnav_18: "Call Back",
		new_with_foo_02: "This website is operated by",
		new_with_foo_04: " (Reg No.23071, IBC 2015) with registered address at Shamrock Lodge, Murray Road, Kingstown, Saint Vincent and the Grenadines.",
		new_with_foo_05: "Restricted Regions:",
		new_with_foo_06: " does not provide services for the residents of certain countries, such as the United States, North Korea, Iran, Cuba, Sudan, Syria and some other regions.",
		gen_rw: " Risk Warning&#58;",
		footer_gen_risk: "Foreign exchange is highly speculative and complex in nature, and may not be suitable for all investors. Forex trading may result in a substantial gain or loss. Therefore, it is not advisable to invest money you cannot afford to lose. Before using the services offered by ForexMart, please acknowledge the risks associated with forex trading. Seek independent financial advice if necessary. Please note that neither past performance nor forecasts are reliable indicators of future results.",
		int_reg_37: "Thank you for choosing",
		session_expired: "Session Expired. Please login again.",
		fid_bck_01: "Good day! We'd love to hear your feedback about your Account.",
		fid_bck_02: "How would you rate your experience on a scale of 1-10?",
		fid_bck_03: "1",
		fid_bck_04: "2",
		fid_bck_05: "3",
		fid_bck_06: "4",
		fid_bck_07: "5",
		fid_bck_08: "6",
		fid_bck_09: "7",
		fid_bck_10: "8",
		fid_bck_11: "9",
		fid_bck_12: "10",
		fid_bck_13: "Poor",
		fid_bck_14: "Excellent",
		fid_bck_15: "Should you have any specific feedback, please select a category below.(optional)",
		fid_bck_16: "Category",
		fid_bck_17: "Thank  you very much, your feedback has been successfully submitted.",
		fid_bck_18: " If you'd like us to follow up on your feedback, please enter your email address below. Rest assured your email will never be used for any other purpose.",
		fid_bck_19: "Close",
		fid_bck_20: " Thank  you very much, E-mail has been submitted.",
		fid_bck_21: "Close",
		fid_bck_22: "Email",
		fid_bck_23: "Cancel",
		fid_bck_24: "Problem",
		fid_bck_25: "Suggestion",
		fid_bck_26: "Compliment",
		fid_bck_27: "Other",
		fid_bck_28: "Send Feedback",
		feedback_text_1: "Forex Major",
		feedback_text_2: "Forex Trading Platforms",
		feedback_text_3: "ForexMart Meta Trader 4",
		feedback_text_4: "Trade fixed or floating spreads with instant execution.",
		feedback_text_5: "Trade floating spreads with market execution",
		feedback_text_6: "Reasons to Choose Our Company",
		feedback_text_7: "Superior Trading Platform",
		feedback_text_8: "Prevail in forex trading using MetaTrader 4 and MetaTrader 5, the industry standards. Our glitch-free software has a user-friendly interface, enabling you to access several quotes in different timetables, as well as charts and indicators.",
		feedback_text_9: "Top-of-the-Line Trade Execution",
		feedback_text_10: "We provide transparent, competitive rates. To gain an edge in forex trading, we use the best tools to execute trades as fast as possible and at price you desire – or better.",
		feedback_text_11: "Reliable Customer Support",
		feedback_text_12: "Equipped with considerable knowledge, our group of customer service agents are on standby 24 hours a day and five days a week to answer forex trading-related concerns.",
		feedback_text_13: "Our Location",
		forex_major_1: "EURUSD",
		forex_major_2: "GBPUSD",
		forex_major_3: "USDJPY",
		forex_major_4: "USDCHF",
		forex_major_5: "USDCAD",
		forex_major_6: "EURJPY",
		forex_major_7: "EURCHF",
		forex_major_8: "GBPJPY",
		forex_major_9: "GBPCHF",
		forex_major_10: "Buy",
		forex_major_11: "Sell",
		forex_major_12: "Forex",
		forex_major_13: "Metals",
		forex_major_14: "Shares",
		forex_major_15: "Futures",
		its_35: "Back"
	},
	languageCopytrade: {},
	languageMyAccounts: {},
	languageMyTrading: {},
	languageFinance: {},
	languageBonus: {},
	languagePartnership: {},
	languageSmartDollars: {},
	languageDatatable: {},
	languageTrading: {},
	languageTrades: {},
	languageMailSupport: {},
	languageBanners: {}
};

export default function languageReducer (state = initial, action:{type:string, payload:any}) {
	switch (action.type) {
		case SET_LANGUAGE_INFO:
			return {
				...state,
				language: action.payload
			};
		case SET_LANGUAGE_COPYTRADE:
			return {
				...state,
				languageCopytrade: action.payload
			};
		case SET_LANGUAGE_MY_ACCOUNTS:
			return {
				...state,
				languageMyAccounts: action.payload
			};
		case SET_LANGUAGE_MY_TRADING:
			return {
				...state,
				languageMyTrading: action.payload
			};
		case SET_LANGUAGE_FINANCE:
			return {
				...state,
				languageFinance: action.payload
			};
		case SET_LANGUAGE_BONUS:
			return {
				...state,
				languageBonus: action.payload
			};
		case SET_LANGUAGE_PARTNERSHIP:
			return {
				...state,
				languagePartnership: action.payload
			};
		case SET_LANGUAGE_SMART_DOLLAR:
			return {
				...state,
				languageSmartDollars: action.payload
			};
		case SET_LANGUAGE_DATATABLE:
			return {
				...state,
				languageDatatable: action.payload
			};
		case SET_LANGUAGE_TRADING:
			return {
				...state,
				languageTrading: action.payload
			};
		case SET_LANGUAGE_TRADES:
			return {
				...state,
				languageTrades: action.payload
			};
		case SET_LANGUAGE_MAIL_SUPPORT:
			return {
				...state,
				languageMailSupport: action.payload
			};
		case SET_LANGUAGE_BANNERS:
			return {
				...state,
				languageBanners: action.payload
			};

		default:
			return state;
	}
}
