export type CryptoCurrencyInfoType = {
	"cryptocurrency-status": {
		invData: {
			input_address: string,
			order_id: string,
			create_date: string,
			status: number,
		}
	},
	qr_src: string;
}

export enum PaymentMethod {
	NETELLER = "NETELLER",
	SKRILL = "SKRILL",
	PAYCO = "PAYCO",
}

export type TResponseWithGatewayParams = {
	params: {
		fields: {
			amount: string,
			currency: string,
			customer_country: string,
			customer_email: string,
			customer_first_name: string,
			customer_ip: string,
			customer_last_name: string,
			customer_phone: string,
			merchant_reference: string,
			network: string,
			return_url: string,
			sign: string
		}
	},
} & {
	[key: string]: string | number
}
