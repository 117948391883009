import * as React from "react";
import { Box } from "@mui/material";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../interfaces/StoreInterfaces";
import {
	Bitcoin,
	BitcoinCash,
	Fasapay,
	Neteller,
	Payco,
	PerfectMoney,
	Skrill,
	LocalBankTransferInMalaysia,
	Tether,
	LocalDepositorMalaysia,
	LocalBankTransferIndonesia,
	LocalDepositorIndonesia,
	ThunderXPay,
	LocalDepositorTanzania,
	LocalBankTransferVietnam,
	LocalBankTransferThailand,
	LocalDepositorMyanmar,
	LocalDepositorNigeria,
	OnlineNaira,
	PayPort,
	GCash,
	PayMaya,
	DragonPay,
	Boleto,
	LocalBankTransferBrazil,
	MobileMoney
} from "../../../pages/Finance/Deposit";
import {
	Finance,
	InfoBar,
	PaymentNetworkLink,
	QuickNav
} from "../common";
import { TransactionStatus } from "../../../components/TransactionStatus";
import PageTitle from "../../../components/Dashboard/dashboardComponents/PageTitle";
import { useAppSelector } from "../../../utils/hooks/reduxHooks";
import LoadingSpinner from "../../../components/UI/LoadingSpinner/LoadingSpinner";
import { TPaymentMethod } from "../../../types/common";
import { withDevelopMode } from "../../../utils/withDevelopMode";
import { DEPOSIT_METHODS } from "../../../pages/Finance/Withdraw/constants";

export function DepositLayout () {
	const language:any = useSelector((state: StoreInterface) => state.language);
	const langFinance:any = useSelector((state:StoreInterface) => state.language.languageFinance);
	const paymentMethods = useAppSelector(state => withDevelopMode(state.user.userPaymentMethods, DEPOSIT_METHODS));
	const container = React.useRef(null);

	return (
		<Finance.Wrapper>
			<PageTitle
				layoutTitle={language.language.sb_li_2}
				pageTitle={langFinance.finav_01}
			/>
			{
				paymentMethods.length === 0
					? (
						<Box sx={{ transform: "translateY(400%)", width: "100%" }}>
							<LoadingSpinner />
						</Box>
					)
					: (
						<>
							<Finance.Links>
								{
									paymentMethods.map((paymentMethod: TPaymentMethod) => (
										<PaymentNetworkLink
											key={paymentMethod.id}
											icon={paymentMethod.imgBase64}
											title={paymentMethod.payment_method_name}
											to={`/app/deposit/${paymentMethod.payment_method_link}`}
										/>
									))
								}
							</Finance.Links>

							<Finance.Pages>
								<Box sx={{ marginBottom: "20px" }}>
									<QuickNav />
								</Box>

								<Box sx={{ marginBottom: "20px" }}>
									<TransactionStatus />
								</Box>

								<InfoBar extended />

								<div ref={container}></div>

								<Route exact path="/app/deposit">
									<Redirect to={`/app/deposit/${paymentMethods[0].payment_method_link}`} />
								</Route>
								<Route
									path="/app/deposit/thunderXPay"
									component={ThunderXPay}
								/>
								<Route
									path="/app/deposit/skrill"
									component={Skrill}
								/>
								<Route
									path="/app/deposit/neteller"
									component={Neteller}
								/>
								<Route
									path="/app/deposit/payco"
									component={Payco}
								/>
								<Route
									path="/app/deposit/cryptocurrency"
									component={Bitcoin}
								/>
								<Route
									path="/app/deposit/bitcoin-cash"
									component={BitcoinCash}
								/>
								<Route
									path="/app/deposit/crypt-api"
									component={Tether}
								/>
								<Route
									path="/app/deposit/fasapay"
									component={Fasapay}
								/>
								<Route
									path="/app/deposit/myr-local-bank-transfer"
								>
									<LocalBankTransferInMalaysia tabsMenuContainer={container} />
								</Route>
								<Route
									path="/app/deposit/local-depositor-myr"
									component={LocalDepositorMalaysia}
								/>
								<Route
									path="/app/deposit/idr-local-bank-transfer"
								>
									<LocalBankTransferIndonesia tabsMenuContainer={container} />
								</Route>
								<Route
									path="/app/deposit/local-depositor-idr"
									component={LocalDepositorIndonesia}
								/>
								<Route
									path="/app/deposit/perfect-money"
									component={PerfectMoney}
								/>
								<Route
									path="/app/deposit/vnd-local-bank-transfer"
								>
									<LocalBankTransferVietnam tabsMenuContainer={container} />
								</Route>
								<Route
									path="/app/deposit/thb-local-bank-transfer"
								>
									<LocalBankTransferThailand tabsMenuContainer={container} />
								</Route>
								<Route
									path="/app/deposit/local-depositor-tnz"
									component={LocalDepositorTanzania}
								/>
								<Route
									path="/app/deposit/local-depositor-mm"
									component={LocalDepositorMyanmar}
								/>
								<Route
									path="/app/deposit/local-depositor-ng"
									component={LocalDepositorNigeria}
								/>
								<Route
									path="/app/deposit/ngn-local-bank-transfer"
								>
									<OnlineNaira tabsMenuContainer={container} />
								</Route>
								<Route
									path="/app/deposit/vodafone"
									component={MobileMoney.Vodafone}
								/>
								<Route
									path="/app/deposit/payport"
									component={PayPort}
								/>
								<Route
									path="/app/deposit/vodacom"
									component={MobileMoney.Vodacom}
								/>
								<Route
									path="/app/deposit/gcash"
									component={GCash}
								/>
								<Route
									path="/app/deposit/paymaya"
									component={PayMaya}
								/>
								<Route
									path="/app/deposit/dragonpay"
									component={DragonPay}
								/>
								<Route
									path="/app/deposit/airtel"
									component={MobileMoney.Airtel}
								/>
								<Route
									path="/app/deposit/zamtel"
									component={MobileMoney.Zamtel}
								/>
								<Route
									path="/app/deposit/mtn"
									component={MobileMoney.MTN}
								/>
								<Route
									path="/app/deposit/orange"
									component={MobileMoney.Orange}
								/>
								<Route
									path="/app/deposit/eu-mobile"
									component={MobileMoney.EU}
								/>
								<Route
									path="/app/deposit/boleto"
									component={Boleto}
								/>
								<Route
									path="/app/deposit/bank-transfer-brazil"
									component={LocalBankTransferBrazil}
								/>
							</Finance.Pages>
						</>
					)
			}
		</Finance.Wrapper>
	);
}
