/*
    Created on : 12.10.2022, 06:18:59
    Author     : Andy Kar
*/

import {
	SET_SESSION_INFO, SET_SESSION_LANG, SET_SESSION_LOADED
} from "../actions/actionTypes";
import { SessionUserDataInterface } from "../../interfaces/StoreInterfaces";

export interface SessionState {
	userData: SessionUserDataInterface,
	loaded: boolean;
}

const initial: SessionState = {
	userData: {} as SessionUserDataInterface,
	loaded: false
};

export default function sessionReducer (state = initial, action: { type: string, payload: any }): SessionState {
	switch (action.type) {
		case SET_SESSION_INFO:
			return {
				...state,
				userData: action.payload
			};
		case SET_SESSION_LANG:
			return {
				...state,
				userData: {
					...state.userData,
					site_lang: action.payload.site_lang
				}
			};
		case SET_SESSION_LOADED:
			return {
				...state,
				loaded: action.payload
			};
		default:
			return state;
	}
}
