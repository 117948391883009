/*
    Created on : 03.03.2022, 06:18:59
    Author     : Andy Kar
*/

import React, { useState } from "react";
import "./AccountsActionCell.scss";
import { useDispatch, useSelector } from "react-redux";
import { SessionUserDataInterface, StoreInterface } from "../../../interfaces/StoreInterfaces";
import classNames from "classnames";
import { SEND_REMOVE_ACCOUNT_INFO, SEND_SWITCH_ACCOUNT_INFO } from "../../../store/actions/actionTypes";
import { Box } from "@mui/material";
import { CellText } from "../../../pages/MyAccounts/CellText/CellText";

function AccountsActionCell (props:any) {
	const langMyAccount:any = useSelector((state:StoreInterface) => state.language.languageMyAccounts);
	const init:any = useSelector((state:StoreInterface) => state.init.init);
	const session:SessionUserDataInterface = useSelector((state:StoreInterface) => state.session.userData);
	const [ dropdownOpen, setDropdownOpen ] = useState(() => false);
	const toggle = () => setDropdownOpen(prevState => !prevState);
	const accountNumber = props?.account_number;

	const dispatch = useDispatch();

	const switchAccount = () => {
		if (accountNumber) {
			const switchAccountData = new FormData();
			switchAccountData.append("username", String(accountNumber));
			dispatch({ type: SEND_SWITCH_ACCOUNT_INFO, switchAccountData });
		}
	};
	const removeAccount = () => {
		const answer = window.confirm("Would you like to close score?");
		if (answer && accountNumber) {
			const removeAccountData = new FormData();
			removeAccountData.append("account_number", String(accountNumber));
			dispatch({ type: SEND_REMOVE_ACCOUNT_INFO, removeAccountData });
		}
	};

	const dropdownActionMenu = () => {
		return (
			<ul className="dropdown-menu">
				<li
					style={{ cursor: "pointer", color: "black" }}
					onClick={switchAccount}
				>
					<a>
						{langMyAccount.mya_32}
					</a>
				</li>
				<li
					style={{ cursor: "pointer", color: "black" }}
					onClick={removeAccount}
				>
					<a>
						{langMyAccount.mya_41}
					</a>
				</li>
			</ul>
		);
	};

	return (
		<Box justifyContent={"center"}>
			{props?.id
				? <CellText>
                    N/A
				</CellText>
				: <div>
					{session.first_login
						? <a
							className="btn btn-primary"
							href={init.links.link_client_signin}
							// style="background: <?= $account['inactive']?'#aaabaa':'#29a643';?> ;white-space:normal!important;color: #fff;border: none;font-size: 12px;font-family: Open Sans;transition: all ease 0.3s;" <?= $account['inactive']?'disabled':'';?>
						>
							{langMyAccount.mya_32}
						</a>
						: <div className="dropdown drp-action drp-active">
							<button
								type="button"
								className="dropdown-toggle btn btn-primary"
								style={{ background: "#0d6efd" }}
								onClick={toggle}
							>
								{langMyAccount.mya_40}
							</button>
							<div className={classNames("dropdown-wrap", { open: (dropdownOpen) })} >
								{dropdownActionMenu()}
							</div>
						</div>
					}
				</div>
			}
		</Box>
	);
}

export default AccountsActionCell;
