import * as React from "react";

export function useModalControls () {
	const [ isOpen, setIsOpen ] = React.useState(false);

	const closeModal = React.useCallback(() => {
		setIsOpen(false);
	}, [ setIsOpen ]);

	const openModal = React.useCallback(() => {
		setIsOpen(true);
	}, [ setIsOpen ]);

	return {
		isOpen,
		openModal,
		closeModal
	};
}
