import { styled } from "@mui/system";
import * as React from "react";
import { FormContext } from "../../../../components/Form";
import { Controller } from "react-hook-form";
import { Grid, Typography } from "@mui/material";
import { Text } from "../../../../components/UI/Text";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../../interfaces/StoreInterfaces";

const UploadButton = styled("button")({
	borderRadius: "3px",
	background: "transparent",
	border: "1px solid #333333",
	color: "#333333",
	minWidth: "100px",
	marginRight: "10px"
});

export default function UploadInput () {
	const { control } = React.useContext(FormContext);
	const inputRef = React.useRef<HTMLInputElement | null>(null);
	const lang:any = useSelector((state:StoreInterface) => state.language.languageCopytrade);

	const selectFile = React.useCallback(() => {
		if (inputRef.current) inputRef.current.click();
	}, [ inputRef.current ]);

	return (
		<Controller
			control={control}
			name="file"
			render={({ field: { value, onChange }, fieldState: { error } }) => (
				<>
					<input
						ref={inputRef}
						type="file"
						hidden
						onChange={(event) => onChange(event.target.files ? event.target.files[0] : {})}
					/>
					<Text
						color="#ff0000"
						fontSize={12}
						sx={{
							position: "absolute",
							bottom: "20px",
							width: "100%"
						}}
					>
						{error?.message}
					</Text>
					<Grid
						container
						flexDirection="row"
						alignItems="center"
					>
						<Grid item>
							<UploadButton type="button" onClick={selectFile}>
								{lang.boo_str_01}
							</UploadButton>
						</Grid>
						<Grid item>
							<Typography sx={{ letterSpacing: 0 }} color="#333333">
								{value?.name ? value.name : lang.boo_str_02}
							</Typography>
						</Grid>
					</Grid>
				</>
			)}
		/>
	);
}
