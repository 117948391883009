/*
    Created on : 09.02.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { Typography } from "@mui/material";
type countryKeysType = "GB"|"AF"|"AL"|"DZ"|"AS"|"AD"|"AO"|"AI"|"AQ"|"AG"|"AR"|"AM"|"AW"|"AU"|"AT"|"AZ"|"BS"|"BH"|"BD"|"BB"|"BY"|"BE"|"BZ"|"BJ"|"BM"|"BT"|"BO"|"BA"|"BW"|"BV"|"BR"|"IO"|"BN"|"BG"|"BF"|"BI"|"KH"|"CM"|"CA"|"CV"|"KY"|"CF"|"TD"|"CL"|"CN"|"CX"|"CC"|"CO"|"KM"|"CG"|"CD"|"CK"|"CR"|"CI"|"HR"|"CU"|"CY"|"CZ"|"DK"|"DJ"|"DM"|"DO"|"TP"|"EC"|"EG"|"SV"|"GQ"|"ER"|"EE"|"ET"|"FK"|"FO"|"FJ"|"FI"|"FR"|"FX"|"GF"|"PF"|"TF"|"GA"|"GM"|"GE"|"DE"|"GH"|"GI"|"GR"|"GL"|"GD"|"GP"|"GU"|"GT"|"GN"|"GW"|"GY"|"HT"|"HM"|"VA"|"HN"|"HK"|"HU"|"IS"|"IN"|"ID"|"IR"|"IQ"|"IE"|"IL"|"IT"|"JM"|"JP"|"JO"|"KZ"|"KE"|"KI"|"KP"|"KW"|"KG"|"LA"|"LV"|"LB"|"LS"|"LR"|"LY"|"LI"|"LT"|"LU"|"MO"|"MK"|"MG"|"MW"|"MY"|"MV"|"ML"|"MT"|"MH"|"MQ"|"MR"|"MU"|"YT"|"MX"|"FM"|"MD"|"MC"|"MN"|"MS"|"MA"|"MZ"|"MM"|"NA"|"NR"|"NP"|"NL"|"AN"|"NC"|"NZ"|"NI"|"NE"|"NG"|"NU"|"NF"|"MP"|"NO"|"OM"|"PK"|"PW"|"PA"|"PG"|"PY"|"PE"|"PH"|"PN"|"PL"|"PT"|"PR"|"QA"|"RE"|"RO"|"RU"|"RW"|"KN"|"LC"|"VC"|"WS"|"SM"|"ST"|"SA"|"SN"|"RS"|"SC"|"SL"|"SG"|"SK"|"SI"|"SB"|"SO"|"ZA"|"GS"|"KS"|"ES"|"LK"|"SH"|"PM"|"SR"|"SJ"|"SZ"|"SE"|"CH"|"TW"|"TJ"|"TZ"|"TH"|"TG"|"TK"|"TO"|"TT"|"TN"|"TR"|"TM"|"TC"|"TV"|"UG"|"UA"|"AE"|"UM"|"UY"|"UZ"|"VU"|"VE"|"VN"|"VG"|"VI"|"WF"|"EH"|"YE"|"ZM"|"ZW"

function CountriesSelectOptions (props:any) {
	const countries = {
		GB: "United Kingdom",
		AF: "Afghanistan",
		AL: "Albania",
		DZ: "Algeria",
		AS: "American Samoa",
		AD: "Andorra",
		AO: "Angola",
		AI: "Anguilla",
		AQ: "Antarctica",
		AG: "Antigua And Barbuda",
		AR: "Argentina",
		AM: "Armenia",
		AW: "Aruba",
		AU: "Australia",
		AT: "Austria",
		AZ: "Azerbaijan",
		BS: "Bahamas",
		BH: "Bahrain",
		BD: "Bangladesh",
		BB: "Barbados",
		BY: "Belarus",
		BE: "Belgium",
		BZ: "Belize",
		BJ: "Benin",
		BM: "Bermuda",
		BT: "Bhutan",
		BO: "Bolivia",
		BA: "Bosnia and Herzegovina",
		BW: "Botswana",
		BV: "Bouvet Island",
		BR: "Brazil",
		IO: "British Indian Ocean Territory",
		BN: "Brunei Darussalam",
		BG: "Bulgaria",
		BF: "Burkina Faso",
		BI: "Burundi",
		KH: "Cambodia",
		CM: "Cameroon",
		CA: "Canada",
		CV: "Cape Verde",
		KY: "Cayman Islands",
		CF: "Central African Republic",
		TD: "Chad",
		CL: "Chile",
		CN: "China",
		CX: "Christmas Island",
		CC: "Cocos (Keeling) Islands",
		CO: "Colombia",
		KM: "Comoros",
		CG: "Congo",
		CD: "Congo, The Democratic Republic Of The",
		CK: "Cook Islands",
		CR: "Costa Rica",
		CI: "Cote D'Ivoire",
		HR: "Croatia (Local Name: Hrvatska)",
		CU: "Cuba",
		CY: "Cyprus",
		CZ: "Czech Republic",
		DK: "Denmark",
		DJ: "Djibouti",
		DM: "Dominica",
		DO: "Dominican Republic",
		TP: "East Timor",
		EC: "Ecuador",
		EG: "Egypt",
		SV: "El Salvador",
		GQ: "Equatorial Guinea",
		ER: "Eritrea",
		EE: "Estonia",
		ET: "Ethiopia",
		FK: "Falkland Islands (Malvinas)",
		FO: "Faroe Islands",
		FJ: "Fiji",
		FI: "Finland",
		FR: "France",
		FX: "France, Metropolitan",
		GF: "French Guiana",
		PF: "French Polynesia",
		TF: "French Southern Territories",
		GA: "Gabon",
		GM: "Gambia",
		GE: "Georgia",
		DE: "Germany",
		GH: "Ghana",
		GI: "Gibraltar",
		GR: "Greece",
		GL: "Greenland",
		GD: "Grenada",
		GP: "Guadeloupe",
		GU: "Guam",
		GT: "Guatemala",
		GN: "Guinea",
		GW: "Guinea-Bissau",
		GY: "Guyana",
		HT: "Haiti",
		HM: "Heard And Mc Donald Islands",
		VA: "Holy See (Vatican City State)",
		HN: "Honduras",
		HK: "Hong Kong",
		HU: "Hungary",
		IS: "Iceland",
		IN: "India",
		ID: "Indonesia",
		IR: "Iran (Islamic Republic Of)",
		IQ: "Iraq",
		IE: "Ireland",
		IL: "Israel",
		IT: "Italy",
		JM: "Jamaica",
		JP: "Japan",
		JO: "Jordan",
		KZ: "Kazakhstan",
		KE: "Kenya",
		KI: "Kiribati",
		KP: "Korea, Democratic People's Republic Of",
		KW: "Kuwait",
		KG: "Kyrgyzstan",
		LA: "Lao People's Democratic Republic",
		LV: "Latvia",
		LB: "Lebanon",
		LS: "Lesotho",
		LR: "Liberia",
		LY: "Libyan Arab Jamahiriya",
		LI: "Liechtenstein",
		LT: "Lithuania",
		LU: "Luxembourg",
		MO: "Macau",
		MK: "Macedonia, Former Yugoslav Republic Of",
		MG: "Madagascar",
		MW: "Malawi",
		MY: "Malaysia",
		MV: "Maldives",
		ML: "Mali",
		MT: "Malta",
		MH: "Marshall Islands",
		MQ: "Martinique",
		MR: "Mauritania",
		MU: "Mauritius",
		YT: "Mayotte",
		MX: "Mexico",
		FM: "Micronesia, Federated States Of",
		MD: "Moldova, Republic Of",
		MC: "Monaco",
		MN: "Mongolia",
		MS: "Montserrat",
		MA: "Morocco",
		MZ: "Mozambique",
		MM: "Myanmar",
		NA: "Namibia",
		NR: "Nauru",
		NP: "Nepal",
		NL: "Netherlands",
		AN: "Netherlands Antilles",
		NC: "New Caledonia",
		NZ: "New Zealand",
		NI: "Nicaragua",
		NE: "Niger",
		NG: "Nigeria",
		NU: "Niue",
		NF: "Norfolk Island",
		MP: "Northern Mariana Islands",
		NO: "Norway",
		OM: "Oman",
		PK: "Pakistan",
		PW: "Palau",
		PA: "Panama",
		PG: "Papua New Guinea",
		PY: "Paraguay",
		PE: "Peru",
		PH: "Philippines",
		PN: "Pitcairn",
		PL: "Poland",
		PT: "Portugal",
		PR: "Puerto Rico",
		QA: "Qatar",
		RE: "Reunion",
		RO: "Romania",
		RU: "Russian Federation",
		RW: "Rwanda",
		KN: "Saint Kitts And Nevis",
		LC: "Saint Lucia",
		VC: "Saint Vincent And The Grenadines",
		WS: "Samoa",
		SM: "San Marino",
		ST: "Sao Tome And Principe",
		SA: "Saudi Arabia",
		SN: "Senegal",
		RS: "Serbia",
		SC: "Seychelles",
		SL: "Sierra Leone",
		SG: "Singapore",
		SK: "Slovakia (Slovak Republic)",
		SI: "Slovenia",
		SB: "Solomon Islands",
		SO: "Somalia",
		ZA: "South Africa",
		GS: "South Georgia, South Sandwich Islands",
		KS: "South Korea",
		ES: "Spain",
		LK: "Sri Lanka",
		SH: "St. Helena",
		PM: "St. Pierre And Miquelon",
		SR: "Suriname",
		SJ: "Svalbard And Jan Mayen Islands",
		SZ: "Swaziland",
		SE: "Sweden",
		CH: "Switzerland",
		TW: "Taiwan",
		TJ: "Tajikistan",
		TZ: "Tanzania, United Republic Of",
		TH: "Thailand",
		TG: "Togo",
		TK: "Tokelau",
		TO: "Tonga",
		TT: "Trinidad And Tobago",
		TN: "Tunisia",
		TR: "Turkey",
		TM: "Turkmenistan",
		TC: "Turks And Caicos Islands",
		TV: "Tuvalu",
		UG: "Uganda",
		UA: "Ukraine",
		AE: "United Arab Emirates",
		UM: "United States Minor Outlying Islands",
		UY: "Uruguay",
		UZ: "Uzbekistan",
		VU: "Vanuatu",
		VE: "Venezuela",
		VN: "Viet Nam",
		VG: "Virgin Islands (British)",
		VI: "Virgin Islands (U.S.)",
		WF: "Wallis And Futuna Islands",
		EH: "Western Sahara",
		YE: "Yemen",
		ZM: "Zambia",
		ZW: "Zimbabwe"
	};

	const countriesOptions = () => {
		return Object.keys(countries).map((key, index) => {
			const country = countries[key as countryKeysType];
			return (<option value={key} key={index}>{country}</option>);
		});
	};

	return (
		<div className="form-group">
			<div className="form-horizontal-left-column flexRow">
				<Typography fontWeight={"bold"} color={"#5C7080"} className="form-horizontal-left-column flexRow">
					{props.label_text}
					{props.required
						? <cite className="form-horizontal-req">*</cite>
						: null
					}
				</Typography>
			</div>
			<div className="form-horizontal-right-column flexRow">
				<select
					defaultValue={props.defaultValue}
					name="country"
					className="form-control form-horizontal-input"
					onChange={(e) => props.changeCountry(e.target.value)}
				>
					{countriesOptions()}
				</select>
				<span
					className="name-error error"
					style={{ display: "none" }}
				>
					{props.error}
				</span>
			</div>
		</div>
	);
}

export default CountriesSelectOptions;
