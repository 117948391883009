/*
    Created on : 13.06.2021, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import AppBarAuthButtons from "./dashboardComponents/AppBarAuthButtons";
import AppBarAuthLang from "./dashboardComponents/AppBarAuthLang";
import DashboardAuthAppBarWrapper from "./dashboardComponents/DashboardAuthAppBarWrapper";

export default function DashboardAuthAppBar () {
	return (
		<DashboardAuthAppBarWrapper>
			<AppBarAuthButtons/>
			<AppBarAuthLang/>
		</DashboardAuthAppBarWrapper>
	);
};
