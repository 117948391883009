import * as React from "react";
import * as Yup from "yup";
import { Box, Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Form } from "../../../../components/Form";
import { useIsMobile } from "../../../../utils/hooks/useIsMobile";
import { FieldWrapper } from "../../../../components/UI/FieldWrapper";
import UploadInput from "./UploadInput";
import { UPLOAD_USER_DOC } from "../../../../store/actions/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { CollapseDocAlert } from "./CollapseDocAlert";
import { StoreInterface } from "../../../../interfaces/StoreInterfaces";

const Wrapper = FieldWrapper["with-tip"];

export interface IUploadFormProps {
	tips: string
	label: string
	type?: string
	name?: string
	level?: string
	fileHashName?: string
	decline?: string
}

export type ISendDoc = {
	setLoading: (value: boolean) => void
	values: { file: File }
	type?: string
	name?: string
	level?: string
}

export function UploadForm (props: IUploadFormProps) {
	const { type, name, level, tips, label, fileHashName, decline } = props;
	const lang:any = useSelector((state:StoreInterface) => state.language.languageMailSupport);

	const [ loading, setLoading ] = React.useState(false);
	const isMobile = useIsMobile();
	const dispatch = useDispatch();

	function onSubmit (props: ISendDoc) {
		const { values, type, name, level } = props;
		if (values.file) {
			dispatch({ type: UPLOAD_USER_DOC, docData: { file: values.file, type, name, level } });
			setLoading(false);
		}
	}

	return (
		<Wrapper tips={tips} label={label}>
			<CollapseDocAlert fileHashName={fileHashName} decline={decline}/>
			<Box
				sx={{
					width: "100%",
					borderRadius: "4px",
					background: isMobile ? "#F1F9FF" : "#E2E7E8",
					border: isMobile ? "0" : "1px solid #D4D4D4",
					padding: isMobile ? "10px" : "26px"
				}}
			>
				<Form
					onSubmit={(values: any) => onSubmit({ values, setLoading, type, name, level })}
					validationSchema={Yup.object().shape({
						file: Yup.mixed().required("File is required")
					})}
					defaultValues={{ file: null }}
				>
					<Grid
						container
						flexDirection="row"
						justifyContent="space-between"
						alignItems="center"
						sx={{ flexWrap: "nowrap" }}
					>
						<Grid item>
							<UploadInput />
						</Grid>
						<Grid item sx={{ width: isMobile ? "100%" : "auto" }}>
							<LoadingButton
								color="success"
								variant="contained"
								size="more-padding"
								type="submit"
								loading={loading}
								sx={{
									width: isMobile ? "100%" : "132px",
									textTransform: "none",
									marginTop: isMobile ? "6px" : "0",
									padding: isMobile ? "8px" : "13px 24px"
								}}
							>{lang.up_btn}</LoadingButton>
						</Grid>
					</Grid>
				</Form>
			</Box>
		</Wrapper>
	);
}
