import * as React from "react";
import TableCell from "@mui/material/TableCell";
import FMTableCollapsibleCell from "./FMTableCollapsibleCell";
import { filterFMTableColumnMobile, IFMTableMobileScheme } from "./mobileFMTable";
import { IFMTableRowObj } from "./formatDataFMTable";
import FMTableCellComponent from "./FMTableCellComponent";
import { IFMTableTheme, useFMTableTheme } from "./FMTableThemes";

interface IFMTableCell<T, M> {
	index: number
	row: IFMTableRowObj<T, M>
	page: number
	componentData?: M
	toggle: (index:number) => void
	open: number[]
	mobileScheme?: IFMTableMobileScheme
	mobileAutoSlice?: number
	breakpoint: number
}

export default function FMTableCells<T, M> (props: IFMTableCell<T, M>) {
	const { index, row, toggle, open, mobileScheme, mobileAutoSlice, breakpoint, page, componentData } = props;
	const rowLength = Object.keys(row).length;
	const theme: IFMTableTheme = useFMTableTheme();

	const renderCell = () => {
		const filteredRows = Object.keys(row)
			.filter((key:string, index: number) => filterFMTableColumnMobile({
				key, index, mobileScheme, rowLength, mobileAutoSlice, breakpoint
			}).include);

		return filteredRows.map((key, i) => {
			return <TableCell
				key={"cell-" + key + "-" + i}
				align={row[key]?.align || "center"}
				sx={{
					borderRight: theme.TableCellBorderColor !== "transparent" ? "1px solid" : "none",
					borderBottom: "none",
					borderLeft: "none",
					borderTop: "none",
					borderColor: theme.TableCellBorderColor,
					height: theme.TableCellHeight,
					padding: "0px 16px",
					"&:last-of-type": {
						borderRight: "none"
					}
				}}
			>
				{row[key].collapsibleComponent ||
				(!i && mobileScheme?.mode && Object.keys(row).length > filteredRows.length)
					? <FMTableCollapsibleCell index={index} toggle={toggle} open={open}>
						<FMTableCellComponent<T, M>
							pr={{
								searchSort: row[key].searchSort,
								label: row[key].label,
								align: row[key].align,
								index,
								page,
								componentData,
								toggleCollapsible: () => toggle(index),
								open: open[index]
							}}
							comp={row[key].component}
							data={row[key].rowItem}
						/>
					</FMTableCollapsibleCell>
					: row[key].component && <FMTableCellComponent<T, M>
						comp={row[key].component}
						pr={{
							searchSort: row[key].searchSort,
							label: row[key].label,
							align: row[key].align,
							index,
							page,
							componentData,
							toggleCollapsible: () => toggle(index),
							open: open[index]
						}}
						data={row[key].rowItem}
					/>
				}
			</TableCell>;
		});
	};
	return <>{renderCell()}</>;
};
