import * as Yup from "yup";
import { AGREE_CHECKBOX } from "../../../../components/Form/BonusSelector";

type DebitErrors = {
	bonus_err_01: string;
	account_number_err_01: string;
	currency_err_01: string;
	banks_err_01: string;
	account_number_err_02: string;
	account_number_err_03: string;
	card_number_err_01: string;
	card_number_err_02: string;
	full_name_err_01: string;
	email_err_01: string;
	number_err_01: string;
	card_image_err_01: string;
	deposit_err_04: string;
	deposit_err_03: string;
}

export const debitValidationSchema = (language: DebitErrors) => Yup.object().shape({
	accountNumber: Yup.string().required(language.account_number_err_01),
	amount: Yup
		.number()
		.typeError(language.deposit_err_04)
		.when([ "currency" ], {
			is: (currency: string) => currency === "THB",
			then: Yup
				.number()
				.min(500, "The minimum deposit amount is 500 THB")
				.max(500000, "The maximum deposit amount is 500,000 THB")
				.required(language.deposit_err_04)
		})
		.when([ "currency" ], {
			is: (currency: string) => currency === "LAK",
			then: Yup
				.number()
				.min(200000, "The minimum deposit amount is 200,000 LAK")
				.max(15000000, "The maximum deposit amount is 15,000,000 LAK")
				.required(language.deposit_err_04)
		})
		.when([ "currency" ], {
			is: (currency: string) => currency === "MMK",
			then: Yup
				.number()
				.min(50000, "The minimum deposit amount is 50,000 MMK")
				.max(20000000, "The maximum deposit amount is 20,000,000 MMK")
				.required(language.deposit_err_04)
		})
		.when([ "currency" ], {
			is: (currency: string) => currency === "KHR",
			then: Yup
				.number()
				.min(100000, "The minimum deposit amount is 100,000 KHR")
				.max(60000000, "The maximum deposit amount is 60,000,000 KHR")
				.required(language.deposit_err_04)
		})
		.when([ "currency" ], {
			is: (currency: string) => currency === "ZAR",
			then: Yup
				.number()
				.min(150, "The minimum deposit amount is 150 ZAR")
				.max(500000, "The maximum deposit amount is 500,000 ZAR")
				.required(language.deposit_err_04)
		}),
	bonuses: Yup.object().test(
		"is-empty",
		language.bonus_err_01,
		(bonuses) => !!Object.keys(bonuses).length && bonuses.hasOwnProperty(AGREE_CHECKBOX.code)
	),
	currency: Yup
		.string()
		.required(language.currency_err_01),
	banks: Yup
		.string()
		.required(language.banks_err_01),
	bankAccountNumber: Yup
		.number()
		.required(language.account_number_err_02)
		.typeError(language.account_number_err_03),
	idCardNumber: Yup
		.number()
		.required(language.card_number_err_01)
		.typeError(language.card_number_err_02),
	fullName: Yup
		.string()
		.required(language.full_name_err_01),
	email: Yup
		.string()
		.required(language.email_err_01),
	number: Yup
		.string()
		.required(language.number_err_01),
	cardImage: Yup
		.mixed()
		.required(language.card_image_err_01)
});
