import * as React from "react";
import { Grid, Typography } from "@mui/material";

export interface ISideWrapperProps {
	label: string;
	children: JSX.Element | JSX.Element[];
}

export function SideWrapper (props: ISideWrapperProps) {
	const { label, children } = props;

	return (
		<Grid
			container
			flexDirection="column"
			sx={{
				border: "1px solid #E2E7E8"
			}}
		>
			<Grid
				item
				sx={{
					padding: "20px",
					backgroundColor: "#E2E7E8"
				}}
			>
				<Typography
					color="#5C7080"
					sx={{
						letterSpacing: 0,
						lineHeight: "19px"
					}}
				>
					{label}
				</Typography>
			</Grid>
			<Grid
				item
				sx={{
					padding: "22px 20px",
					display: "flex",
					flexDirection: "column",
					alignItems: "stretch"
				}}
			>
				{children}
			</Grid>
		</Grid>
	);
}
