/*
    Created on : 13.11.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../interfaces/StoreInterfaces";
import { Box, Typography } from "@mui/material";
import FMTablePagination from "./FMTablePagination";
import SquareSpinner from "../UI/FetchSpinner/SquareSpinner";
import { useAdaptive } from "../../utils/hooks/themeBreakpointsHooks";

interface FMTableActionsInterface{
	rowsPerPage: number
	rowCount: number
	onChangePage: any
	currentPage: number
	targetRowCount?: number
	tableRowCount?: number
}

export default function FMTableFooter (props:FMTableActionsInterface) {
	const { currentPage, rowsPerPage, rowCount, onChangePage, targetRowCount, tableRowCount } = props;
	const langDatatable:any = useSelector((state:StoreInterface) => state.language.languageDatatable);
	const startItemNumber:number = (currentPage) * rowsPerPage;
	const pagesAll: number = Math.ceil(rowCount / rowsPerPage);
	const itemsOnCurrentPage = (pagesAll !== currentPage) ? rowsPerPage : (rowCount - ((pagesAll - 1) * rowsPerPage));
	const lastItemNumber:number = startItemNumber + itemsOnCurrentPage >= rowCount ? rowCount : startItemNumber + itemsOnCurrentPage;
	const paginationInfo = langDatatable.dta_tbl_02 ? langDatatable.dta_tbl_02.replace("_START_", startItemNumber + 1).replace("_END_", lastItemNumber).replace("_TOTAL_", rowCount) : "";

	const actionSx = useAdaptive({
		xs: {
			flexDirection: "column",
			justifyContent: "space-between",
			alignItems: "center",
			"&>div:first-of-type": {
				alignItems: "center",
				mb: "20px"
			}
		},
		sm: {
			flexDirection: "row",
			"&>div:first-of-type": {
				mb: 0
			}
		}
	});

	return (
		<Box
			className="flexRow"
			sx={actionSx}
		>
			<Box className="flexRow">
				<Typography sx={{ minWidth: "200px" }}>{paginationInfo}</Typography>
				{targetRowCount && tableRowCount && tableRowCount < targetRowCount
					? <>
						<SquareSpinner
							size={16}
							color={[ "#2988CA", "#29A643", "#2988CA", "#29A643" ]}
						/>
						<Typography sx={{ color: "#2988CA" }}>{ Math.ceil(rowCount / targetRowCount * 100) }%</Typography>
					</>
					: null
				}
			</Box>
			{rowsPerPage < rowCount &&
				<FMTablePagination
					rowsPerPage={rowsPerPage}
					rowCount={rowCount}
					onChangePage={onChangePage}
					currentPage={currentPage}
				/>
			}
		</Box>
	);
};
