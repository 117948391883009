/*
    Created on : 11.11.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../../interfaces/StoreInterfaces";
import FMTable from "../../../../components/FMTable/FMTable";
import FMTableColumn from "../../../../components/FMTable/FMTableColumn";
import { ITrade } from "../../../../store/reducers/tradesReducer";

export default function TradesHistoryTable () {
	const langTrades:any = useSelector((state:StoreInterface) => state.language.languageTrades);
	const trades: Array<ITrade> = useSelector((state:StoreInterface) => state.trades.tradesAll);
	const targetRowCount: number = useSelector((state:StoreInterface) => state.trades.tradesLength);

	return (
		<FMTable
			data={trades}
			// mobileScheme={{ mode: "auto" }}
			targetRowCount={targetRowCount}
			withPeriodFilter={true}
			theme="TradingTheme"
			mobileScheme={{
				mode: "auto",
				auto: { collapsibleColumns: [ 12, 11, 10, 1, 9, 7, 6, 8, 2, 3, 4 ] }
			}}
		>
			<FMTableColumn id="tradeNumber" label="#" />
			<FMTableColumn id="ticket" label={langTrades.curtra_04} />
			<FMTableColumn id="type" label={langTrades.curtra_05} />
			<FMTableColumn id="volume" label={langTrades.curtra_06} />
			<FMTableColumn id="symbol" label={langTrades.curtra_07} />
			<FMTableColumn id="openPrice" label={langTrades.curtra_08} />
			<FMTableColumn id="openTime" label={langTrades.curtra_16} />
			<FMTableColumn id="closePrice" label={langTrades.curtra_11} />
			<FMTableColumn id="date" label={langTrades.curtra_17} dataPropName="closeTime" />
			<FMTableColumn id="sl" label={langTrades.curtra_09} />
			<FMTableColumn id="tp" label={langTrades.curtra_10} />
			<FMTableColumn id="swap" label={langTrades.curtra_swap} />
			<FMTableColumn id="profit" label={langTrades.curtra_13} />
		</FMTable>
	);
};
