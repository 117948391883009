import { Grid } from "@mui/material";
import * as React from "react";
import { useIsMobile } from "../../../utils/hooks/useIsMobile";

export interface ColumnItemProps {
	children: React.ReactNode;
}

export function ColumnItem (props: ColumnItemProps): JSX.Element {
	const { children } = props;
	const isMobile = useIsMobile();
	const isLaptop = useIsMobile(1181);

	return (
		<Grid
			item
			flexDirection={isMobile ? "row" : "column"}
			sx={{
				display: "flex",
				flexWrap: "wrap",
				width: isLaptop ? "100%" : "auto",
				maxWidth: isMobile ? "410px" : isLaptop ? "577px" : "100%",
				margin: isMobile ? "0 auto" : "0"
			}}
		>
			{children}
		</Grid>
	);
}
