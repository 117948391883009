import * as React from "react";
import { Form } from "../../../../components/Form";
import { tetherWithdrawValidationSchema } from "./validation";
import { Input } from "../../../../components/Form/Input";
import { Select } from "../../../../components/Form/Select";
import { Option } from "../../../../components/Form/Option";
import { ButtonWithLoader } from "../../../../components/ButtonWithLoader";
import { FormBottomWrapper } from "../../../../components/Form/FormBottomWrapper";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { useErrorHandling } from "../../../../utils/hooks/useErrorHandling";
import { fetchTetherInfo } from "../../../../store/actions/actionTypes";
import { Alert, Box, Skeleton, Snackbar } from "@mui/material";
import { WaningNote } from "../../../../components/UI/Note";
import API from "../../../../utils/API";
import { AxiosResponse } from "axios";

/**
 * TODO: Add translations for fields labels
 */
enum TetherCurrencyTypes {
	TRC20 = "trc20_usdt",
	ERC20 = "erc20_usdt",
	BEP20 = "bep20_usdt"
}
export function Tether () {
	const [ success, setSuccess ] = React.useState(false);
	const [ isFetch, setIsFetch ] = React.useState(false);
	const language = useAppSelector((state) => state.language);
	const { account_number: accountNumber } = useAppSelector((state) => state.user.user);
	const {
		feeDetails,
		loading: infoLoading,
		complete,
		metadata_description: metadataDescription
	} = useAppSelector((state) => state.withdrawMethodsInfo.tether);
	const dispatch = useAppDispatch();
	const { isOpen, showError, hideError, errorMsg } = useErrorHandling();

	React.useEffect(() => {
		if (!complete) {
			dispatch(fetchTetherInfo({}));
		}
	}, []);
	async function submit () {
		setIsFetch(true);
		try {
			const formData = new FormData();

			// TODO: fill form data

			const { data } = await API.post<unknown, AxiosResponse<{ success: boolean, errorMsg: string }>>("withdrawTether", formData);

			if (!data.success) {
				showError(data.errorMsg || "Something went wrong");
				return;
			}

			setSuccess(true);
		} catch (e) {
			//
		} finally {
			setIsFetch(false);
		}
	}

	function calcFullAmount (values: [string, TetherCurrencyTypes]): string {
		const [ withdrawAmount ] = values;

		if (isNaN(parseInt(withdrawAmount))) {
			return "0 USD";
		}

		return isNaN(+withdrawAmount) ? "0 $" : `${+withdrawAmount} $`;
	}

	return (
		<Form
			onSubmit={submit}
			validationSchema={tetherWithdrawValidationSchema()}
			defaultValues={{
				accountNumber,
				amount: "0",
				currencyType: TetherCurrencyTypes.TRC20
			}}
		>
			<Input
				name="accountNumber"
				label={"Account Number"}
			/>
			<Select
				name="currencyType"
				label={"Currency Type"}
			>
				<Option value={TetherCurrencyTypes.TRC20}>TRC20-USDT</Option>
				<Option value={TetherCurrencyTypes.ERC20}>ERC20-USDT</Option>
				<Option value={TetherCurrencyTypes.BEP20}>BEP20-USDT</Option>
			</Select>
			<Input
				name="amount"
				label={"Amount to withdraw"}
			/>
			<FormBottomWrapper
				forFields={[ "amount", "currencyType" ]}
				onChange={calcFullAmount}
				label={language.languageMyAccounts.form_message_01}
			>
				<ButtonWithLoader
					isFetch={isFetch}
					color={"primary"}
					text={language.languageMyAccounts.chapas_04}
				/>
			</FormBottomWrapper>
			<Box sx={{ marginTop: "6px" }}>
				{
					infoLoading
						? (
							<Skeleton width={"220px"} height={"26px"} />
						)
						: (
							<>
								{feeDetails ? <WaningNote message={`Fee is ${feeDetails}`} /> : <></>}
								<WaningNote
									message={`Fee is ${metadataDescription}`}
								/>
							</>
						)
				}
			</Box>
			<Snackbar open={isOpen} autoHideDuration={4000} onClose={hideError}>
				<Alert variant="filled" color="error">
					{errorMsg}
				</Alert>
			</Snackbar>
			<Snackbar
				open={success}
				autoHideDuration={4000}
				onClose={() => setSuccess(false)}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
			>
				<Alert variant="filled" color="success">
					Success
				</Alert>
			</Snackbar>
		</Form>
	);
}
