/*
    Created on : 20.01.2022, 06:18:59
    Author     : Andy Kar
*/
import React, { useEffect } from "react";
import "./PageInternalTransitService.scss";
import { useDispatch, useSelector } from "react-redux";
import { StoreInterface } from "../../../interfaces/StoreInterfaces";
import PageTitle from "../../../components/Dashboard/dashboardComponents/PageTitle";
import { ReactComponent as InternalTransitServiceImage } from "../images/internalTransitService.svg";
import Typography from "@mui/material/Typography";
import CustomButton from "../../../components/CustomButton";
import Box from "@mui/material/Box";
import SecurityCodeField from "./SecurityCodeField";
import { useIsRtl } from "../../../utils/hooks/useIsRtl";
import {
	FETCH_PARTNERSHIP_SECURITY_CODE_DATA
} from "../../../store/actions/actionTypes";
import { IPartnershipSecurityCodeInterface } from "../../../saga/partnershipSaga";

const Title = () => (
	<Typography fontSize={24} color={"#5C7080"} fontWeight={"lighter"} mb={"11px"}>General Security Code</Typography>
);

export default function PageInternalTransitService () {
	const lang:any = useSelector((state:StoreInterface) => state.language.language);
	const langPartnership:any = useSelector((state:StoreInterface) => state.language.languagePartnership);
	const securityDate: IPartnershipSecurityCodeInterface = useSelector((state:StoreInterface) => state.partnership.security);

	const isRtl = useIsRtl();

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch({ type: FETCH_PARTNERSHIP_SECURITY_CODE_DATA });
	}, []);

	const getSecurityCode = () => {
		dispatch({ type: FETCH_PARTNERSHIP_SECURITY_CODE_DATA });
	};

	return (
		<div className="page-partnership-internal-transit-service">
			<PageTitle
				layoutTitle={lang.sb_li_4}
				pageTitle={langPartnership.oth_com_18}
			/>

			<Box sx={{ textAlign: isRtl ? "right" : "left", width: "50%", marginLeft: isRtl ? "auto" : 0 }}>
				<Box ml={{ textAlign: "center" }}>
					<InternalTransitServiceImage></InternalTransitServiceImage>
				</Box>
				<Title/>
				<Box sx={{
					mb: "15px",
					width: "100%",
					padding: "10px",
					border: "1px solid",
					borderColor: "#7A8B98",
					borderRadius: "4px",
					minHeight: "42px"
				}}>{securityDate.code}</Box>
				<CustomButton
					sx={{ width: "100%", marginBottom: "30px" }}
					text={"Generate Code"}
					color={"success"}
					onClick={getSecurityCode}
				/>
				<Title/>
				<SecurityCodeField>Security Code is used to let Partner(s) with ITS use the Request from Clients page by providing them the code.</SecurityCodeField>
				<SecurityCodeField>Security Code is unique and can only be used once by one (1) Partner.</SecurityCodeField>
				<SecurityCodeField>You can only generate new Security Code if the previous one is used for Request from Clients for funds.</SecurityCodeField>
			</Box>
		</div>
	);
};
