import React from "react";

const PtFlag = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
			<path fill="red" d="M256 0h384v480H256z"/>
			<path fill="#060" d="M0 0h256v480H0z"/>
			<g fill="#ff0" fillRule="evenodd" stroke="#000" strokeWidth=".573" strokeLinecap="round" strokeLinejoin="round">
				<path d="M339.456 306.176c-32.224-.97-179.99-93.205-181.003-107.893l8.16-13.608c14.657 21.297 165.717 110.998 180.555 107.82l-7.712 13.677" strokeWidth=".611"/>
				<path d="M164.896 182.827c-2.89 7.78 38.56 33.406 88.43 63.737 49.87 30.33 92.87 49.073 96.056 46.385.195-.348 1.57-2.71 1.443-2.692-.598.9-2.052 1.184-4.32.53-13.474-3.886-48.614-20.016-92.133-46.406-43.518-26.392-81.38-50.714-87.265-61.047-.41-.716-.7-2.023-.642-3.04h-.143l-1.253 2.19-.173.342zm175.317 123.776c-.546.99-1.565 1.024-3.5.812-12.053-1.334-48.628-19.12-91.906-45.028-50.358-30.144-91.947-57.61-87.435-64.79l1.228-2.17.242.076c-4.058 12.165 82.077 61.417 87.148 64.557 49.84 30.877 91.856 48.908 95.575 44.222l-1.353 2.326v-.002z" strokeWidth=".611"/>
				<path d="M256.18 207.18c32.254-.256 72.055-4.41 94.96-13.537l-4.936-8.018c-13.538 7.493-53.557 12.42-90.295 13.157-43.453-.4-74.124-4.446-89.49-14.757l-4.66 8.538c28.25 11.954 57.198 14.493 94.422 14.616" strokeWidth=".611"/>
				<path d="M352.47 193.824c-.79 1.26-15.727 6.412-37.732 10.214-14.92 2.274-34.383 4.22-58.67 4.242-23.076.022-41.926-1.62-56.197-3.555-23.1-3.622-35.02-8.66-39.428-10.442.42-.838.692-1.426 1.098-2.21 12.688 5.053 24.666 8.1 38.698 10.258 14.177 1.92 32.8 3.587 55.76 3.566 24.176-.025 43.424-2.117 58.258-4.324 22.565-3.64 34.892-8.324 36.623-10.5l1.593 2.752h-.002zm-4.332-8.13c-2.446 1.963-14.632 6.285-36.073 9.71-14.31 2.05-32.504 3.886-55.75 3.908-22.084.022-40.127-1.466-53.85-3.465-21.775-2.844-33.365-7.974-37.543-9.47.416-.72.84-1.432 1.274-2.148 3.25 1.636 14.435 6.176 36.508 9.303 13.568 1.924 31.638 3.358 53.613 3.335 23.136-.023 41.123-1.894 55.34-3.934 21.553-2.965 33.15-8.477 34.91-9.857l1.572 2.614v.003zm-197.866 60.343c19.838 10.67 63.9 16.047 105.594 16.417 37.963.06 87.42-5.868 105.916-15.67l-.51-10.678c-5.785 9.042-58.786 17.716-105.818 17.36-47.033-.354-90.707-7.618-105.266-17.022l.084 9.59" strokeWidth=".611"/>
				<path d="M362.795 244.5v2.548c-2.78 3.324-20.208 8.347-42.066 11.885-16.635 2.55-38.323 4.474-65.347 4.474-25.673 0-46.147-1.83-62.024-4.268-25.1-3.656-41.152-10.056-44.375-11.966l.015-2.97c9.68 6.435 35.905 11.142 44.71 12.584 15.775 2.42 36.127 4.238 61.672 4.238 26.898 0 48.463-1.91 64.994-4.444 15.68-2.266 38.02-8.157 42.418-12.08zm.01-9.057v2.547c-2.778 3.32-20.208 8.345-42.065 11.882-16.635 2.55-38.322 4.474-65.346 4.474-25.674 0-46.147-1.828-62.025-4.268-25.098-3.653-41.15-10.053-44.374-11.964l.014-2.97c9.68 6.434 35.905 11.143 44.712 12.582 15.774 2.423 36.126 4.24 61.67 4.24 26.898 0 48.464-1.91 64.994-4.446 15.68-2.265 38.02-8.156 42.418-12.08v.003zM255.776 304.34c-45.623-.27-84.716-12.435-92.97-14.446l6.02 9.424c14.58 6.133 52.718 15.274 87.388 14.262 34.67-1.01 64.97-3.697 86.323-14.092l6.172-9.765c-14.553 6.853-64.074 14.548-92.935 14.618" strokeWidth=".611"/>
				<path d="M344.853 297.3a143.02 143.02 0 0 1-2.77 4.086c-10.07 3.55-25.94 7.28-32.636 8.367-13.68 2.818-34.843 4.9-53.625 4.91-40.416-.592-73.5-8.504-89.063-15.253l-1.257-2.16.205-.323 2.13.826c27.678 9.902 58.764 13.853 88.21 14.562 18.71.066 37.436-2.144 52.58-4.852 23.222-4.653 32.612-8.16 35.493-9.75l.734-.41zm5.352-8.826c.023.028.047.054.07.083a287.226 287.226 0 0 1-2.093 3.48c-5.372 1.92-19.95 6.185-41.237 9.162-14.025 1.91-22.743 3.76-50.644 4.302-52.282-1.33-86.132-11.553-94.174-14.075l-1.192-2.286c30.3 7.91 61.25 13.433 95.37 13.997 25.525-.544 36.385-2.424 50.294-4.32 24.823-3.86 37.33-7.946 41.083-9.126a2.845 2.845 0 0 0-.164-.212l2.692-1.005-.002.002z" strokeWidth=".587"/>
				<path d="M350.752 237.61c.148 30.013-15.21 56.946-27.582 68.827-17.502 16.81-40.707 27.623-67.807 28.12-30.26.557-58.794-19.17-66.448-27.838-14.963-16.945-27.145-38.462-27.536-67.46 1.853-32.757 14.712-55.574 33.352-71.22 18.64-15.647 43.46-23.268 64.13-22.723 23.847.63 51.705 12.33 70.955 35.554 12.61 15.22 18.072 31.733 20.935 56.74zM255.62 134.847c58.118 0 105.916 47.294 105.916 105.283 0 57.987-47.798 105.283-105.916 105.283-58.117 0-105.52-47.295-105.52-105.284 0-57.99 47.403-105.284 105.52-105.284" strokeWidth=".611"/>
				<path d="M255.904 134.485c58.17 0 105.612 47.45 105.612 105.624 0 58.173-47.443 105.62-105.612 105.62-58.17 0-105.612-47.446-105.612-105.62 0-58.176 47.443-105.625 105.612-105.625zM152.617 240.11c0 56.81 46.65 103.297 103.287 103.297 56.637 0 103.29-46.487 103.29-103.298 0-56.814-46.654-103.3-103.29-103.3s-103.287 46.49-103.287 103.3z" strokeWidth=".611"/>
				<path d="M255.99 143.264c53.046 0 96.74 43.542 96.74 96.75 0 53.21-43.695 96.75-96.74 96.75-53.046 0-96.74-43.54-96.74-96.75 0-53.208 43.695-96.75 96.74-96.75zm-94.417 96.75c0 51.93 42.645 94.426 94.416 94.426 51.77 0 94.415-42.495 94.415-94.426 0-51.93-42.643-94.426-94.416-94.426-51.772 0-94.417 42.495-94.417 94.426z" strokeWidth=".611"/>
				<path d="M260.245 134.06h-9.05l.01 212.223h9.082z" strokeWidth=".611"/>
				<path d="M259.34 132.85h2.302l.02 214.666h-2.306l-.016-214.667zm-8.984 0h2.322l.003 214.668h-2.323V132.85z" strokeWidth=".611"/>
				<path d="M361.59 244.197v-7.845l-6.39-5.952-36.267-9.6-52.266-5.334-62.934 3.2-44.8 10.667-9.045 6.7v7.846L172.8 233.6l54.4-8.534h52.267l38.4 4.267 26.666 6.4z" strokeWidth=".611"/>
				<path d="M255.947 223.755c24.942-.046 49.14 2.363 68.336 6.1 19.807 3.96 33.746 8.913 38.512 14.476l-.006 2.756c-5.748-6.923-24.505-11.998-38.953-14.9-19.05-3.705-43.086-6.098-67.89-6.05-26.174.047-50.546 2.526-69.317 6.19-15.06 2.987-35.147 8.924-37.655 14.78v-2.868c1.377-4.053 16.334-10.11 37.316-14.31 18.912-3.69 43.33-6.126 69.657-6.173zm.01-9.06c24.942-.044 49.142 2.366 68.336 6.102 19.807 3.962 33.746 8.913 38.512 14.476l-.005 2.754c-5.748-6.92-24.505-11.997-38.953-14.897-19.048-3.707-43.085-6.1-67.89-6.052-26.174.047-50.427 2.528-69.2 6.188-14.534 2.756-35.44 8.928-37.772 14.784v-2.87c1.377-4.01 16.636-10.284 37.317-14.31 18.91-3.69 43.328-6.124 69.655-6.173zm-.512-46.205c39.306-.196 73.59 5.496 89.275 13.53l5.72 9.9c-13.632-7.348-50.618-14.988-94.937-13.845-36.11.222-74.696 3.975-94.055 14.304l6.83-11.424c15.89-8.24 53.358-12.42 87.17-12.463" strokeWidth=".611"/>
				<path d="M255.968 176.66c22.418-.058 44.08 1.206 61.308 4.315 16.043 2.986 31.344 7.467 33.53 9.877l1.698 2.998c-5.32-3.475-18.56-7.343-35.562-10.567-17.073-3.21-38.72-4.272-61.013-4.213-25.305-.087-44.963 1.25-61.835 4.19-17.843 3.34-30.223 8.11-33.277 10.375l1.662-3.168c5.934-3.028 15.35-6.677 31.172-9.525 17.447-3.187 37.315-4.143 62.317-4.28zm-.01-9.05c21.454-.055 42.637 1.14 59.15 4.11 13.022 2.534 25.9 6.492 30.617 10.014l2.48 3.942c-4.217-4.688-20.09-9.13-34.105-11.62-16.385-2.825-36.688-3.943-58.142-4.122-22.515.063-43.323 1.442-59.47 4.382-15.403 2.93-25.343 6.402-29.55 9.112l2.183-3.292c5.805-3.056 15.182-5.862 26.99-8.157 16.266-2.962 37.202-4.306 59.85-4.37zm52.469 116.4c-19.433-3.627-38.9-4.154-52.498-3.994-65.502.768-86.662 13.45-89.244 17.29l-4.895-7.98c16.677-12.088 52.345-18.866 94.493-18.173 21.886.358 40.773 1.812 56.66 4.89l-4.518 7.97" strokeWidth=".611"/>
				<path d="M255.552 278.89c18.22.273 36.106 1.025 53.37 4.244l-1.252 2.207c-16.033-2.958-33.125-4.09-52.056-4-24.174-.188-48.624 2.07-69.91 8.18-6.717 1.868-17.836 6.186-18.97 9.755l-1.244-2.05c.36-2.11 7.08-6.488 19.642-10.017 24.382-6.982 47.188-8.16 70.42-8.32v.003zm.827-9.17c18.877.354 38.372 1.227 57.322 4.98L312.4 277c-17.112-3.397-33.46-4.53-55.91-4.875-24.25.044-49.974 1.773-73.363 8.573-7.55 2.2-20.583 6.955-21.018 10.72l-1.244-2.203c.283-3.42 11.565-7.88 21.715-10.833 23.57-6.853 49.36-8.615 73.8-8.66z" strokeWidth=".587"/>
				<path d="M349.42 290.54l-7.872 12.21-22.615-20.083-58.666-39.467-66.134-36.267-34.336-11.744 7.318-13.57 2.485-1.353 21.333 5.333 70.4 36.267 40.534 25.6L336 272l13.867 16z" strokeWidth=".611"/>
				<path d="M158.56 195.51c6.022-4.085 50.282 15.63 96.592 43.556 46.188 28.004 90.322 59.65 86.338 65.57l-1.31 2.062-.6.474c.128-.092.792-.904-.066-3.1-1.968-6.475-33.275-31.457-85.22-62.82-50.64-30.197-92.844-48.397-97.064-43.195l1.33-2.548zm192.47 94.855c3.807-7.522-37.244-38.447-88.14-68.557-52.07-29.51-89.595-46.88-96.45-41.7l-1.522 2.77c-.014.153.055-.188.377-.436 1.246-1.088 3.312-1.015 4.244-1.03 11.802.175 45.51 15.688 92.806 42.802 20.723 12.07 87.542 54.923 87.287 66.975.018 1.034.086 1.248-.304 1.76l1.7-2.584v-.003z" strokeWidth=".611"/>
			</g>
			<g transform="translate(0 26.667) scale(1.06667)">
				<path d="M180.6 211.01c0 16.27 6.663 30.987 17.457 41.742 10.815 10.778 25.512 17.58 41.81 17.58 16.38 0 31.246-6.654 42.015-17.39 10.77-10.735 17.443-25.552 17.446-41.88h-.002v-79.19l-118.74-.14.012 79.278h.002z" fill="#fff" stroke="#000" strokeWidth=".67"/>
				<path d="M182.82 211.12v.045c0 15.557 6.44 29.724 16.775 40.01 10.354 10.304 24.614 16.71 40.214 16.71 15.68 0 29.91-6.36 40.22-16.625 10.31-10.265 16.698-24.433 16.7-40.044h-.002V134.39l-113.84-.02-.07 76.75m91.022-53.748l.004 48.89-.04 5.173c0 1.36-.082 2.912-.24 4.233-.926 7.73-4.48 14.467-9.746 19.708-6.164 6.136-14.67 9.942-24.047 9.942-9.326 0-17.638-3.938-23.828-10.1-6.35-6.32-10.03-14.986-10.03-23.947l-.013-54.022 67.94.122v.002z" fill="red" stroke="#000" strokeWidth=".507"/>
				<g id="e">
					<g id="d" fill="#ff0" stroke="#000" strokeWidth=".5">
						<path d="M190.19 154.43c.135-5.52 4.052-6.828 4.08-6.847.03-.02 4.232 1.407 4.218 6.898l-8.298-.05" stroke="none"/>
						<path d="M186.81 147.69l-.682 6.345 4.14.01c.04-5.25 3.975-6.124 4.07-6.104.09-.004 3.99 1.16 4.093 6.104h4.152l-.75-6.394-15.022.038v.002zm-.96 6.37h16.946c.357 0 .65.353.65.784 0 .43-.293.78-.65.78H185.85c-.357 0-.65-.35-.65-.78 0-.43.293-.784.65-.784z"/>
						<path d="M192.01 154.03c.018-3.313 2.262-4.25 2.274-4.248 0 0 2.342.966 2.36 4.248h-4.634m-5.8-8.98h16.245c.342 0 .623.318.623.705 0 .387-.28.704-.623.704H186.21c-.342 0-.623-.316-.623-.705 0-.387.28-.705.623-.705zm.34 1.42h15.538c.327 0 .595.317.595.704 0 .388-.268.704-.595.704H186.55c-.327 0-.595-.316-.595-.704 0-.387.268-.704.595-.704zm5.02-10.59l1.227.002v.87h.895v-.89l1.257.005v.887h.896v-.89h1.258l-.002 2.01c0 .317-.254.52-.55.52h-4.41c-.296 0-.57-.236-.57-.525l-.004-1.99zm4.62 2.69l.277 6.45-4.303-.015.285-6.452 3.74.017"/>
						<path id="a" d="M190.94 141.56l.13 3.478h-4.124l.116-3.478h3.88-.002z"/>
						<use height="100%" width="100%" xlinkHref="#a" x="10.609"/>
						<path id="b" d="M186.3 139.04l1.2.003v.872h.877v-.892l1.23.004v.89h.88v-.894l1.23.002-.003 2.012c0 .314-.25.518-.536.518h-4.317c-.29 0-.558-.235-.558-.525l-.003-1.99z"/>
						<use height="100%" width="100%" xlinkHref="#b" x="10.609"/>
						<path d="M193.9 140.61c-.026-.627.877-.634.866 0v1.536h-.866v-1.536" fill="#000" stroke="none"/>
						<path id="c" d="M188.57 142.84c-.003-.606.837-.618.826 0v1.187h-.826v-1.187" fill="#000" stroke="none"/>
						<use height="100%" width="100%" xlinkHref="#c" x="10.641"/>
					</g>
					<use height="100%" width="100%" xlinkHref="#d" y="46.32"/>
					<use height="100%" width="100%" xlinkHref="#d" transform="rotate(-45.202 312.766 180.004)"/>
				</g>
				<use height="100%" width="100%" xlinkHref="#d" x="45.714"/>
				<use height="100%" width="100%" xlinkHref="#e" transform="matrix(-1 0 0 1 479.792 0)"/>
				<g id="f" fill="#fff">
					<path d="M232.636 202.406v.005a8.34 8.34 0 0 0 2.212 5.69c1.365 1.467 3.245 2.378 5.302 2.378 2.067 0 3.944-.905 5.303-2.365 1.358-1.46 2.202-3.472 2.202-5.693v-10.768l-14.992-.013-.028 10.765" fill="#039"/>
					<circle cx="236.074" cy="195.735" r="1.486"/>
					<circle cx="244.392" cy="195.742" r="1.486"/>
					<circle cx="240.225" cy="199.735" r="1.486"/>
					<circle cx="236.074" cy="203.916" r="1.486"/>
					<circle cx="244.383" cy="203.905" r="1.486"/>
				</g>
				<use height="100%" width="100%" xlinkHref="#f" y="-26.016"/>
				<use height="100%" width="100%" xlinkHref="#f" x="-20.799"/>
				<use height="100%" width="100%" xlinkHref="#f" x="20.745"/>
				<use height="100%" width="100%" xlinkHref="#f" y="25.784"/>
			</g>
		</svg>
	);
};

export default PtFlag;
