export default function siteLanguagesList () {
	return [
		"english",
		"arabic",
		"french",
		"german",
		"indonesian",
		"portuguese",
		"russuan",
		"bulgarian",
		"malay",
		"polish",
		"urdu",
		"greek",
		"czech",
		"chinese",
		"bangladesh",
		// "spanish",
		"vietnam"
	];
};
