/*
    Created on : 10.11.2022, 06:18:59
    Author     : Andy Kar
*/

import { takeEvery, put, call } from "redux-saga/effects";
import {
	FETCH_USER_BONUSES_INFO,
	FETCH_USER_CORP_DOCS_INFO,
	FETCH_USER_DOCS_INFO,
	FETCH_USER_INFO,
	FETCH_USER_PAYMENT_METHODS_INFO, FETCH_USER_WITHDRAW_METHODS_INFO, SET_USER_BONUSES_INFO,
	SET_USER_CORP_DOCS_INFO,
	SET_USER_DOC_UPLOADING,
	SET_USER_DOCS_INFO,
	SET_USER_INFO,
	SET_USER_PAYMENT_METHODS_INFO, SET_USER_WITHDRAW_METHODS_INFO,
	UPLOAD_USER_DOC
} from "../store/actions/actionTypes";
import {
	UserDataInterface,
	UserBonusesDataInterface,
	UserWithdrawMethodsDataInterface,
	UserPaymentMethodsDataInterface
} from "../interfaces/StoreInterfaces";
import { ResponseGenerator } from "../interfaces/mainInterfaces";
import API from "../utils/API";

/**
 Get user data from server and set it to store
 **/
export function * userWatcher () {
	yield takeEvery(FETCH_USER_INFO, userWorker);
}
export function * userWorker () {
	const data: UserDataInterface = yield getUserInfo();
	if (Object.keys(data).length) { yield put({ type: SET_USER_INFO, payload: data }); }
}
async function getUserInfo () {
	const response = await API.get("user");
	return response?.data || {};
}

/**
 Get user Payment Methods data from server and set it to store
 **/
export function * userPaymentMethodsWatcher () {
	yield takeEvery(FETCH_USER_PAYMENT_METHODS_INFO, userPaymentMethodsWorker);
}
export function * userPaymentMethodsWorker () {
	const data: UserPaymentMethodsDataInterface = yield getUserPaymentMethodsInfo();
	if (Object.keys(data).length) { yield put({ type: SET_USER_PAYMENT_METHODS_INFO, payload: data.paymentMethods }); }
}
async function getUserPaymentMethodsInfo () {
	const response = await API.get("userPaymentMethods");
	return response?.data || {};
}

/**
 Get user Payment Methods data from server and set it to store
 **/
export function * userWithdrawMethodsWatcher () {
	yield takeEvery(FETCH_USER_WITHDRAW_METHODS_INFO, userWithdrawMethodsWorker);
}
export function * userWithdrawMethodsWorker () {
	const data: UserWithdrawMethodsDataInterface = yield getUserWithdrawMethodsInfo();
	if (Object.keys(data).length) { yield put({ type: SET_USER_WITHDRAW_METHODS_INFO, payload: data.withdrawMethods }); }
}
async function getUserWithdrawMethodsInfo () {
	const response = await API.get("userWithdrawMethods");
	return response?.data || {};
}

/**
 Get user Bonuses data from server and set it to store
 **/
export function * userBonusesWatcher () {
	yield takeEvery(FETCH_USER_BONUSES_INFO, userBonusesWorker);
}
export function * userBonusesWorker () {
	const data: UserBonusesDataInterface = yield getUserBonusesInfo();
	if (Object.keys(data).length) { yield put({ type: SET_USER_BONUSES_INFO, payload: data.userBonuses }); }
}
async function getUserBonusesInfo () {
	const response = await API.get("userBonusStatus");
	return response?.data || {};
}

/**
 Get user documents data from server and set it to store
 **/
interface IUserDocDataInterface {}
export function * userDocsWatcher () {
	yield takeEvery(FETCH_USER_DOCS_INFO, userDocsWorker);
}
export function * userDocsWorker () {
	const data: IUserDocDataInterface = yield getUserDocsInfo();
	if (Object.keys(data).length) { yield put({ type: SET_USER_DOCS_INFO, payload: data }); }
}
async function getUserDocsInfo () {
	const response = await API.get("getUserDocuments");
	return response?.data || {};
}
/**
 Get user corporate documents data from server and set it to store
 **/
export function * userCorpDocsWatcher () {
	yield takeEvery(FETCH_USER_CORP_DOCS_INFO, userCorpDocsWorker);
}
export function * userCorpDocsWorker () {
	const data: IUserDocDataInterface = yield getUserCorpDocsInfo();
	if (Object.keys(data).length) { yield put({ type: SET_USER_CORP_DOCS_INFO, payload: data }); }
}
async function getUserCorpDocsInfo () {
	const response = await API.get("getCorporateDocuments");
	return response?.data || {};
}

/**
 Upload user document to the server
 **/
interface IUploadUserDocInterface {
	upload_data?: {
		success: boolean
		error: boolean
	}
}
interface IUserDocInterface {
	file: File
	type: string
	name: string // doc_type
	level: string
	corporate?: boolean
}
export function * uploadUserDocWatcher () {
	yield takeEvery(UPLOAD_USER_DOC, uploadUserDocWorker);
}
export function * uploadUserDocWorker (action:{type:string, docData: IUserDocInterface}) {
	yield put({ type: SET_USER_DOC_UPLOADING, payload: true });
	const data:IUploadUserDocInterface = yield call(sendUploadUserDocData, action.docData);
	if (Object.keys(data).length && data.upload_data?.success && !data.upload_data?.error) {
		yield put({ type: FETCH_USER_DOCS_INFO });
	};
	yield put({ type: SET_USER_DOC_UPLOADING, payload: false });
}
async function sendUploadUserDocData (docData:IUserDocInterface) {
	const formData = new FormData();
	formData.append("file", docData.file, docData.file.name);
	formData.append(docData.name, docData.type);
	formData.append("level", docData.level);
	let url: string = "uploadUserDocuments";
	if (docData.corporate) url = "uploadCorporateDocuments";
	const response:ResponseGenerator = await API.post(url, formData);
	return response?.data || {};
}
