import * as React from "react";
import { Typography, TypographyTypeMap } from "@mui/material";
import { useIsRtl } from "../../../utils/hooks/useIsRtl";

export interface TextProps extends Omit<TypographyTypeMap["props"], "textAlign"> {
	children: string | undefined;
}

export function Text (props: TextProps) {
	const isRtl = useIsRtl();
	const { children } = props;

	return (
		<Typography
			{...props}
			textAlign={isRtl ? "right" : "left"}
		>
			{children}
		</Typography>
	);
}
