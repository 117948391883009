/*
    Created on : 20.01.2022, 06:18:59
    Author     : Andy Kar
*/
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import {
	FETCH_LANGUAGE_BONUS
} from "../../store/actions/actionTypes";
import {
	StoreInterface
} from "../../interfaces/StoreInterfaces";
import "./LayoutBonus.scss";
import PageBonusesStatistic from "../../pages/Bonus/PageBonusesStatistic/PageBonusesStatistic";
import PageBonuses from "../../pages/Bonus/PageBonuses/PageBonuses";
import AccountTypes from "../components/AccountTypes/AccountTypes";

const LayoutBonus = (props:any) => {
	const siteLang:string|undefined = useSelector((state:StoreInterface) => state.session.userData.site_lang);

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch({ type: FETCH_LANGUAGE_BONUS, language: siteLang });
	}, [ siteLang ]);

	return (
		<div className="layout layout-bonus">
			<Switch>
				<Route exact path="/app/bonus/bonuses" render={() => <PageBonuses {...props} />} />
				<Route exact path="/app/bonus/bonuses-statistic" render={() => <PageBonusesStatistic {...props} />} />
				<Redirect exact from="/app/bonus" to="/app/bonus/bonuses" />
			</Switch>
			<AccountTypes/>
		</div>
	);
};

export default withRouter(LayoutBonus);
