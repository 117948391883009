import React from "react";
import { FlagIconInterface } from "./FlagIconInterface";
import { Box } from "@mui/material";

const FlagIcon = (props:FlagIconInterface) => {
	const width = props.width ? props.width : "26px";
	const height = props.height ? props.height : width;
	const radius = props.radius ? props.radius : "50%";

	return (
		<div
			style={{
				width: `${width}`,
				height: `${height}`,
				borderRadius: `${radius}`,
				overflow: "hidden",
				display: "flex",
				justifyContent: "center",
				alignItems: "center"
			}}
		>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "100%",
					"& svg": {
						height: "100%"
					}
				}}
			>
				{props.icon}
			</Box>
		</div>
	);
};

export default FlagIcon;
