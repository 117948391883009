enum AppMode {
	DEVELOPMENT = "development",
}

export function withDevelopMode<T> (defaultMode: T, developmentMode: T): T {
	if (process.env.REACT_APP_MODE === AppMode.DEVELOPMENT) {
		return developmentMode;
	}

	return defaultMode;
}
