import * as React from "react";
import { DepositAsianBankForms } from "../../../../components/Forms/DepositAsianBankForms";
import { DepositAsianBankFormError } from "../../../../utils/errors/DepositAsianBankFormError";
import { calcInPHP } from "../../../../utils/calcInCurrencies";
import { TDepositAsianBankFormsOne } from "../../../../components/Forms/DepositAsianBankForms/One";
import API from "../../../../utils/API";
import { AxiosResponse } from "axios";

export function PayMaya () {
	async function submit (values: TDepositAsianBankFormsOne) {
		const formData = new FormData();

		formData.append("amount1", values.amount);
		formData.append("amount", values.amount);
		formData.append("first_name", values.firstName);
		formData.append("last_name", values.lastName);
		formData.append("phone_number", values.phone);

		const { data } = await API.post<unknown, AxiosResponse<unknown>>("depositPaymaya", formData);

		console.log(data);
		throw new DepositAsianBankFormError("Fix me");
	}

	return (
		<DepositAsianBankForms.One
			submit={submit}
			calcInCurrency={calcInPHP}
		/>
	);
}
