/*
    Created on : 20.01.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import "./PageChangePassword.scss";
import { PasswordChangeForm } from "../../../components/Forms/PasswordChangeForm/PasswordChangeForm";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../interfaces/StoreInterfaces";
import PageTitle from "../../../components/Dashboard/dashboardComponents/PageTitle";

export default function PageChangePassword () {
	const langMyAccounts:any = useSelector((state:StoreInterface) => state.language.languageMyAccounts);

	return (
		<div className="page-change-password">
			<PageTitle pageTitle={langMyAccounts.mfn_02}/>
			<PasswordChangeForm/>
		</div>
	);
};
