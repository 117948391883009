/*
    Created on : 13.01.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreInterface } from "../../../interfaces/StoreInterfaces";
import "./ForgotPassForm.scss";
import { AiOutlineUser, AiOutlineMail } from "react-icons/ai";
import { SEND_FORGOT_PASS_INFO } from "../../../store/actions/actionTypes";
import classNames from "classnames";

function ForgotPassForm () {
	const lang:any = useSelector((state:StoreInterface) => state.language.language);
	// const init:any = useSelector((state:StoreInterface) => state.init.init)
	// const session:SessionUserDataInterface = useSelector((state:StoreInterface) => state.session.userData)
	const auth:any = useSelector((state:StoreInterface) => state.auth.auth);
	// const authLoading:boolean = useSelector((state:StoreInterface) => state.auth.loading)

	const dispatch = useDispatch();
	const sendForgotPass = () => {
		const formName:any = "forgotPasswordForm";
		const forgotPasswordForm: HTMLFormElement | null = document.forms[formName];
		if (forgotPasswordForm) {
			const forgotPass = new FormData(forgotPasswordForm);
			dispatch({ type: SEND_FORGOT_PASS_INFO, forgotPassData: forgotPass });
		}
	};

	return (
		<div className="forgot-password-form-wrap">
			<div className="forgot-password-form">
				<h1 className="form-title">{lang.for_pas_00}</h1>
				<div>
					<form
						name="forgotPasswordForm"
						className="form-horizontal"
						acceptCharset="utf-8"
					>
						<div className="form-group">
							<p>{lang.for_pas_02}</p>
							<p>{lang.for_pas_03}</p>
							{auth.forgot_success
								? <p style={{ color: "green" }}>{lang.for_pas_01}</p>
								: null
							}
						</div>
						<div className="form-group">
							<div className={classNames("form-input", { error: (auth.errors && auth.errors.username) })} >
								<input
									type="text"
									name="Email"
									className="form-control"
									placeholder={lang.for_pas_04}
								/>
								<AiOutlineMail className="form-icon"/>
							</div>
							{auth.errors && auth.errors.Email
								? <span className="error-message">{auth.errors.Email}</span>
								: null
							}
						</div>
						<div className="form-group">
							<div className={classNames("form-input", { error: (auth.errors && auth.errors.password) })}>
								<input
									type="number"
									name="account_number"
									className="form-control"
									placeholder={lang.for_pas_05}
								/>
								<AiOutlineUser className="form-icon"/>
							</div>
							{auth.errors && auth.errors.account_number
								? <span className="error-message">{auth.errors.account_number}</span>
								: null
							}
						</div>
						<div className="form-group" style={{ display: "none" }}>
							{/* <div className="form-group ig" id="googlereecaptchabox" style={{marginTop: "5px", display: "none"}}> */}
							{/*    <div */}
							{/*        // className=" no-padding-column <?= (FXPP::html_url() == 'sa') ? 'col-lg-8 col-md-8 col-sm-12' : ''; ?>" */}
							{/*    > */}
							{/*        /!*<?php if (FXPP::isEUUrl()) { ?>*!/ */}
							{/*        <div className="g-recaptcha" */}
							{/*            // dataSitekey="6LcTOFEUAAAAAFhknXrp5-fPQ-tN7rSaHm1Rm-93" */}
							{/*        > */}
							{/*        </div> */}
							{/*        /!*<?php } elseif (FXPP::isCoUrl()) { ?>*!/ */}
							{/*        <div className="g-recaptcha" */}
							{/*            // dataSitekey="6Lc2u4sUAAAAAHSE7468HYcrX1sG0mvX7eBzuKKe" */}
							{/*        > */}
							{/*        </div> */}
							{/*        /!*<?php } elseif(FXPP::IsFxmdeal()) { ?>*!/ */}
							{/*        <div className="g-recaptcha" */}
							{/*            // dataSitekey="6Lc1G-QcAAAAAAuX84H9Ay9nxQtpvAOHWhl5UtSz" */}
							{/*        > */}
							{/*        </div> */}
							{/*        /!*<?php }elseif(FXPP::IsFxMartLive()) { ?>*!/ */}
							{/*        <div className="g-recaptcha" */}
							{/*            // dataSitekey="6LegqO0cAAAAAMAVbgvBlUQO7_40oWbsMhhBZkR4" */}
							{/*        > */}
							{/*        </div> */}
							{/*        /!*<?php }elseif(FXPP::forexMindo()) { ?>*!/ */}
							{/*        <div className="g-recaptcha" */}
							{/*            // dataSitekey="6Ldrqe0cAAAAADu1hzZKMdV4otUNy3H3dsVOmsTC" */}
							{/*        > */}
							{/*        </div> */}
							{/*        /!*<?php }else { ?>*!/ */}
							{/*        <div className="g-recaptcha" */}
							{/*            // dataSitekey="6LeJqQsTAAAAAKiXcxToS7gtj_omu_3IZHca_cwS" */}
							{/*        > */}
							{/*        </div> */}
							{/*        /!*<?php } ?>*!/ */}
							{/*    </div> */}
							{/* </div> */}
							{/* <button */}
							{/*    type="submit" */}

							{/* > */}
							{/*    {lang.cs_06} */}
							{/* </button> */}
							{/* <input */}
							{/*    type="hidden" */}
							{/*    name="goToPayment" */}
							{/*    // value="<?= $goToPayment ?>" */}
							{/* /> */}
						</div>
						<div className="form-group">
							<button
								type="button"
								onClick={sendForgotPass}
								className="submit-button btn-control"
							>
								{lang.for_pas_06}
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}
export default ForgotPassForm;
