import * as React from "react";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { filterFMTableColumnMobile, IFMTableMobileScheme } from "./mobileFMTable";
import { IOrder } from "./sortFMTable";
import { IHeadCell } from "./formatDataFMTable";
import { IFMTableTheme, useFMTableTheme } from "./FMTableThemes";

interface FMTableHeadProps<T> {
	headCells: Array<IHeadCell<T>>
	numSelected?: number;
	onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
	onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	order: IOrder;
	orderBy: string;
	rowCount: number;
	mobileScheme?: IFMTableMobileScheme
	mobileAutoSlice?: number
	breakpoint: number
}

export default function FMTableHead<T> (props: FMTableHeadProps<T>) {
	const { headCells, order, orderBy, onRequestSort, mobileScheme, mobileAutoSlice, breakpoint } = props;
	const rowLength = Object.keys(headCells).length;
	const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
		onRequestSort(event, property);
	};
	const theme: IFMTableTheme = useFMTableTheme();
	const sX: any = themeSx(theme);

	return (
		<TableHead>
			{headCells.length
				? <TableRow sx={{
					borderBottom: theme.TableHeadBgColor === "transparent" ? "2px solid #5C7080" : "none"
				}}>
					{headCells.filter(
						(headCell: any, index: number) => filterFMTableColumnMobile({
							key: headCell.id,
							index,
							mobileScheme,
							rowLength,
							mobileAutoSlice,
							breakpoint
						}).include
					).map((headCell: any) => (
						<TableCell
							key={headCell.id}
							align={"center"}
							padding={headCell.disablePadding ? "none" : "normal"}
							sortDirection={orderBy === headCell.id ? order : false}
							sx={{ ...sX.tableHeadTableCellSX, ...headCell.sx }}
						>
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
								onClick={createSortHandler(headCell.id)}
								sx={sX.tableHeadTableSortLabel}
							>
								{headCell.label}
								{orderBy === headCell.id && <Box component="span" sx={visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</Box>}
							</TableSortLabel>
						</TableCell>
					))}
				</TableRow>
				: <TableRow>
					<TableCell></TableCell>
				</TableRow>
			}
		</TableHead>
	);
};

const themeSx = (theme:IFMTableTheme) => {
	return {
		tableHeadTableCellSX: {
			height: theme.TableCellHeight,
			background: theme.TableHeadBgColor,
			borderRight: "1px solid",
			borderColor: theme.TableHeadBorderColor,
			"& .MuiButtonBase-root": {
				fontWeight: theme.TableHeadFontWeight,
				color: theme.TableHeadColor
			},
			"& .MuiButtonBase-root:hover": {
				color: theme.TableHeadColor
			},
			"& .Mui-active": {
				color: theme.TableHeadColor
			}
		},
		tableHeadTableSortLabel: {
			color: theme.TableHeadColor
		}
	};
};
