import * as React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { FieldWrapper as FieldWrapperUI } from "../../UI/FieldWrapper";
import { Controller } from "react-hook-form";
import { useIsRtl } from "../../../utils/hooks/useIsRtl";
import { FormContext } from "../Form";
import { TextField, Typography } from "@mui/material";
import styled from "styled-components";

export interface DatePickerProps {
	name: string;
	label: string;
	extValue?: string
	extOnChange?: (value: string | null, keyboardInputValue?: string | undefined) => void | undefined
}

export function DatePicker (props: DatePickerProps) {
	const { name, label, extValue, extOnChange } = props;
	const { control, fieldWrapperVariant } = React.useContext(FormContext);
	const FieldWrapper = React.useMemo(() => FieldWrapperUI[fieldWrapperVariant], [ fieldWrapperVariant ]);
	const isRtl = useIsRtl();

	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { value, onChange }, fieldState: { error } }) => (
				<FieldWrapper label={label}>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DesktopDatePicker
							inputFormat="MM/dd/yyyy"
							value={extValue || value}
							onChange={extOnChange || onChange}
							InputProps={{
								sx: { maxHeight: "42px" }
							}}
							label={false}
							renderInput={(params) => (
								<TextFieldUI
									{...params}
									sx={{ width: "100%" }}
									InputLabelProps={{ shrink: false }}
								/>
							)}
						/>
					</LocalizationProvider>
					<Typography
						color="#ff0000"
						fontSize={12}
						sx={{
							position: "absolute",
							top: "100%",
							width: "100%",
							textAlign: isRtl ? "right" : "left"
						}}
					>
						{error?.message}
					</Typography>
				</FieldWrapper>
			)}
		/>
	);
}

export const TextFieldUI = styled(TextField)`
  & .MuiOutlinedInput-root {
    .MuiOutlinedInput-notchedOutline {
      border-color: #7A8B98;
      border-width: 1px;
		legend span {
			display: none; 
		}
    }

    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: #7A8B98;
        border-width: 1px;
      }
    }
  }
`;
