import dayjs from "dayjs";

export type IRowObj<T> = {
	[K in keyof T]: string | number
};

interface IChart<T> {
	data: Array<IRowObj<T>>
	xId?: keyof T
	yId: keyof T
}

export function chartData<T> (props: IChart<T>): [ number, number ][] {
	const { data, xId = "date" as keyof T, yId } = props;
	const chartArray: [number, number][] = [];

	if (data) {
		data.map((dataItem: IRowObj<T>) => {
			if (xId && dataItem[xId] && dataItem[yId]) {
				let x: any = dataItem[xId];
				if (typeof dataItem[xId] === "string" && xId.toString().toLowerCase() === "date") {
					x = dayjs(dataItem[xId]).valueOf();
				} else if (typeof dataItem[xId] === "string") {
					x = parseFloat(dataItem[xId].toString());
				}
				const y = (typeof dataItem[yId] === "string")
					? parseFloat(dataItem[yId].toString())
					: dataItem[yId];
				chartArray.push([ x as number, y as number ]);
			}
			return null;
		});
	};
	return chartArray;
};

interface ISearchChartData<T> {
	data: Array<IRowObj<T>>
	dateFrom: number
	dateTo: number
}

export function searchChartData<T> (props: ISearchChartData<T>): Array<IRowObj<T>> {
	const { data = [], dateFrom, dateTo } = props;

	const filteredRows = () => {
		return data.filter((rowObj: IRowObj<T>) => {
			let dateInRange: boolean = true;
			if ((dateFrom || dateTo) && (rowObj["date" as keyof T])) {
				const unixDate = dayjs(rowObj["date" as keyof T]).valueOf();
				dateInRange = unixDate >= dateFrom && unixDate <= dateTo;
			}
			return dateInRange;
		});
	};

	return (dateFrom || dateTo) ? filteredRows() : data;
};
