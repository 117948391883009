import * as React from "react";
import { Grid, Typography } from "@mui/material";
import classNames from "classnames";
import { useIsRtl } from "../../../../utils/hooks/useIsRtl";
import { useIsMobile } from "../../../../utils/hooks/useIsMobile";

export interface InfoBarItemProps {
	label: string;
	value: string;
	first?: boolean;
}

export function InfoBarItem (props: InfoBarItemProps): JSX.Element {
	const { label, value, first } = props;
	const isRtl = useIsRtl();
	const isMobile = useIsMobile();

	return (
		<Grid
			item
			sx={{
				[isRtl ? "marginLeft" : "marginRight"]: isMobile ? "auto" : "36px"
			}}
			className={classNames(
				"info-bar__item",
				{ "info-bar__item_first": first },
				{ "info-bar__item_rtl": isRtl })
			}
		>
			<Typography
				fontSize={18}
				className="bold"
				color={first ? "#fff" : "#5C7080"}
				align={isRtl ? "right" : "left"}
				lineHeight="22px"
			>
				{value}
			</Typography>
			<Typography
				lineHeight="18px"
				textTransform="uppercase"
				color={first ? "#E2E7E8" : "#5C7080"}
				align={isRtl ? "right" : "left"}
			>
				{label}
			</Typography>
		</Grid>
	);
}
