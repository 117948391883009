import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import DashboardSidebarDesktopMenu from "./dashboardComponents/DashboardSidebarDesktopMenu";

const drawerWidth: number = 276;
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
	({ theme, open }) => ({
		background: "#E2E7E8",
		"& .MuiDrawer-paper": {
			background: "transparent",
			position: "relative",
			whiteSpace: "nowrap",
			border: "none",
			overflow: "visible",
			height: "max-content",
			minHeight: "100vh",
			zIndex: 1302,
			width: drawerWidth,
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen
			}),
			boxSizing: "border-box",
			...(!open && {
				transition: theme.transitions.create("width", {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.leavingScreen
				}),
				width: theme.spacing(7),
				[theme.breakpoints.up("sm")]: {
					width: theme.spacing(9)
				}
			})
		}
	})
);

export default function DashboardSidebar (props: any) {
	return (
		<Drawer
			variant="permanent"
			open={props.open}
		>
			<DashboardSidebarDesktopMenu { ...props }/>
		</Drawer>
	);
}
