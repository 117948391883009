/*
    Created on : 25.11.2022, 06:18:59
    Author     : Andy Kar
*/

import { takeEvery, put, call } from "redux-saga/effects";
import {
	FETCH_BANNERS, FETCH_BANNERS_SHOW, SET_BANNERS, SET_BANNERS_SHOW
} from "../store/actions/actionTypes";
import { ResponseGenerator } from "../interfaces/mainInterfaces";
import API from "../utils/API";
import {
	IBanners,
	IBannersShow
} from "../store/reducers/bannersReducer";

/**
 Get banners data from server and set it to store
 **/

export type IBannersInfoData = {
	banners: IBanners
}

export function * bannersWatcher () {
	yield takeEvery(FETCH_BANNERS, bannersWorker);
};

export function * bannersWorker () {
	const data:IBannersInfoData = yield call(getBannersInfo);
	if (Object.keys(data).length) {
		yield put({ type: SET_BANNERS, payload: data.banners });
	}
};

async function getBannersInfo () {
	const response:ResponseGenerator = await API.post("banners");
	return response?.data || {};
};

/**
 Get part of all trades data from server and set it to store
 **/

type IBannersData = {
	pageName: string
	lang: string
	af: string
}

type IBannersShowData = {
	bannersData: IBannersShow
}

export function * bannersShowWatcher () {
	yield takeEvery(FETCH_BANNERS_SHOW, bannersShowWorker);
};

export function * bannersShowWorker (action:{type:string, bannersData: any}) {
	const data:IBannersShowData = yield call(getBannersShowInfo, action.bannersData);
	if (Object.keys(data).length) {
		yield put({ type: SET_BANNERS_SHOW, payload: data.bannersData });
	}
};

async function getBannersShowInfo (bannersData :IBannersData) {
	const postData = new FormData();
	postData.append("pagename", bannersData.pageName ? bannersData.pageName : "130x600");
	postData.append("lang", bannersData.lang ? bannersData.lang : "EN");
	postData.append("af", bannersData.af ? bannersData.af : "");

	const response:ResponseGenerator = await API.post("bannersShow", postData);
	return response?.data || {};
};
