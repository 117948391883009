/*
    Created on : 12.10.2022, 06:18:59
    Author     : Andy Kar
*/

import { takeEvery, put } from "redux-saga/effects";
import {
	FETCH_SESSION_INFO,
	SET_SESSION_INFO, SET_SESSION_LOADED
} from "../store/actions/actionTypes";
import { SessionUserDataInterface } from "../interfaces/StoreInterfaces";
import API from "../utils/API";

/**
 Get session data from server and set it to store
 **/
export function * sessionWatcher () {
	yield takeEvery(FETCH_SESSION_INFO, sessionWorker);
}
export function * sessionWorker () {
	yield put({ type: SET_SESSION_LOADED, payload: true });
	const data: SessionUserDataInterface = yield getSessionInfo();
	if (Object.keys(data).length) {
		yield put({ type: SET_SESSION_INFO, payload: data });
		yield put({ type: SET_SESSION_LOADED, payload: false });
	} else {
		yield put({ type: SET_SESSION_INFO, payload: { logged: false } });
		yield put({ type: SET_SESSION_LOADED, payload: false });
	}
}
async function getSessionInfo () {
	const response = await API("session");
	return response?.data || {};
}
