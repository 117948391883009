/*
    Created on : 11.11.2022, 06:18:59
    Author     : Andy Kar
*/
import { takeEvery, put, call } from "redux-saga/effects";
import { ResponseGenerator } from "../interfaces/mainInterfaces";
import API from "../utils/API";
import {
	ACTIVATE_SMART_DOLLARS,
	FETCH_SMART_DOLLARS_INFO,
	REQUEST_SMART_DOLLARS,
	SET_ACTIVATE_SMART_DOLLARS,
	SET_REQUEST_SMART_DOLLARS_INFO,
	SET_SMART_DOLLARS_INFO
} from "../store/actions/actionTypes";

/**
 Get Smart Dollars data from server and set it to store
 **/

export interface ISmartDollarsInfo {
	success: boolean;
	LotsToTrade: number
	accountNumb: string;
	nextLevelPip?: string;
	nextLevel?: number
	lotsX: number;
	level: number;
	curLevel: string;
	curLevelPip: number;
	totalLots: number;
	cashFund: number
}

export interface ISmartDollarsWithdrawInfo {
	FromTicket: number;
	ToTicket: number;
	DateAdded: number;
	Amount: number;
	LotsToTrade: number;
	RemainingLot: number;
	Expiration: number;
	WithdrawnDate: number;
	Status: "PENDING" | "EXPIRED" | "COMPLETED";
}

export interface ISmartDollarsData {
	smart_status: boolean;
	smart_dollar_info: ISmartDollarsInfo;
	withdraw_info: ISmartDollarsWithdrawInfo[];
};

export function * smartDollarsWatcher () {
	yield takeEvery(FETCH_SMART_DOLLARS_INFO, smartDollarsWorker);
};

export function * smartDollarsWorker () {
	const data: ISmartDollarsData = yield call(getSmartDollarsInfo);
	if (Object.keys(data).length) {
		yield put({ type: SET_SMART_DOLLARS_INFO, payload: data });
	}
};

async function getSmartDollarsInfo () {
	const response:ResponseGenerator = await API.get("smartDollars");
	return response?.data || {};
};

/**
 Activate Smart Dollars
 **/

export function * activateSmartDollarsWatcher () {
	yield takeEvery(ACTIVATE_SMART_DOLLARS, activateSmartDollarsWorker);
};

export function * activateSmartDollarsWorker () {
	const data: Promise<any> = yield call(activateSmartDollars);
	if (Object.keys(data).length) {
		yield put({ type: SET_ACTIVATE_SMART_DOLLARS, payload: data });
	}
};

async function activateSmartDollars () {
	const response:ResponseGenerator = await API.get("activateSmartDollars");
	return response?.data || {};
};

/**
 Request Cache Smart Dollars
 **/

export function * requestSmartDollarsWatcher () {
	yield takeEvery(REQUEST_SMART_DOLLARS, requestSmartDollarsWorker);
};

export function * requestSmartDollarsWorker () {
	const data: Promise<any> = yield call(requestSmartDollars);
	if (Object.keys(data).length) {
		yield put({ type: SET_REQUEST_SMART_DOLLARS_INFO, payload: data });
	}
};

async function requestSmartDollars () {
	const response:ResponseGenerator = await API.post("smartDollars");
	return response?.data || {};
};
