import * as React from "react";
import { Form } from "../../../../components/Form";
import { brazilPixWithdrawValidationSchema } from "./validation";
import { Input } from "../../../../components/Form/Input";
import { InputRepeater } from "../../../../components/Form/InputRepeater";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { ButtonWithLoader } from "../../../../components/ButtonWithLoader";
import { FormBottomWrapper } from "../../../../components/Form/FormBottomWrapper";
import { fetchBrazilPixInfo } from "../../../../store/actions/actionTypes";
import { Alert, Box, Skeleton, Snackbar } from "@mui/material";
import { WaningNote } from "../../../../components/UI/Note";
import API from "../../../../utils/API";
import { AxiosResponse } from "axios";
import { useErrorHandling } from "../../../../utils/hooks/useErrorHandling";

// TODO: Add translations for fields labels
export function BrazilPix () {
	const [ success, setSuccess ] = React.useState(false);
	const [ isFetch, setIsFetch ] = React.useState(false);
	const {
		account_number: accountNumber,
		full_name: fullName
	} = useAppSelector((state) => state.user.user);
	const amount = useAppSelector((state) => state.account.funds.balance);
	const language = useAppSelector((state) => state.language);
	const {
		fee,
		addon,
		feeDetails,
		loading: infoLoading,
		complete
	} = useAppSelector((state) => state.withdrawMethodsInfo.brazilPix);
	const dispatch = useAppDispatch();
	const { isOpen, showError, hideError, errorMsg } = useErrorHandling();

	React.useEffect(() => {
		if (!complete) {
			dispatch(fetchBrazilPixInfo({}));
		}
	}, []);
	async function submit (values: {
		accountNumber: string,
		amount: string,
		fullName: string,
		cpf: string,
		pixKey: string
	}) {
		setIsFetch(true);
		try {
			const formData = new FormData();

			formData.append("amount_withdraw", values.amount);
			formData.append("pix_key", values.pixKey);
			formData.append("fullname", values.fullName);
			formData.append("cpf", values.cpf);

			const response = await API.post<unknown, AxiosResponse<{
				success: boolean,
				error: string,
				formError: { [key: string]: string }
			}>>("withdrawBrazilPix", formData);

			if (!response.data.success) {
				const firstKey = Object.keys(response.data.formError)[0];
				showError(response.data.formError[firstKey] ?? response.data.error);
				return;
			}

			setSuccess(true);
		} catch (e) {
			showError("Something went wrong!");
		} finally {
			setIsFetch(false);
		}
	}

	function calcFee (values: [string]): string {
		return _calcFee(values) + " USD";
	}

	function calcNewBalance (values: [string]): string {
		const [ withdrawAmount ] = values;
		const newBalance = amount - (+withdrawAmount) - _calcFee(values);
		return isNaN(newBalance) ? "0 USD" : `${newBalance.toFixed(3)} USD`;
	}

	function calcFullAmount (values: [string]): string {
		const [ withdrawAmount ] = values;

		const fullAmount = +withdrawAmount + _calcFee(values);

		return isNaN(fullAmount) ? "0 USD" : `${fullAmount.toFixed(3)} USD`;
	}

	function _calcFee (values: [string]): number {
		const [ withdrawAmount ] = values;

		if (isNaN(parseInt(withdrawAmount))) {
			return 0;
		}

		return (+withdrawAmount * fee + addon);
	}

	return (
		<Form
			onSubmit={submit}
			validationSchema={brazilPixWithdrawValidationSchema()}
			defaultValues={{
				accountNumber,
				amount: "0",
				fullName,
				cpf: "",
				pixKey: ""
			}}
		>
			<Input
				name="accountNumber"
				label={"Account Number"}
			/>
			<Input
				name="amount"
				label={"Amount to withdraw"}
				disabled={+amount <= 10}
			/>
			<InputRepeater
				fields="amount"
				label={"Fee"}
				onChange={calcFee}
			/>
			<InputRepeater
				fields="amount"
				label={"New balance"}
				onChange={calcNewBalance}
			/>
			<Input
				name="fullName"
				label={"Full Name"}
			/>
			<Input
				name="cpf"
				label={"CPF"}
			/>
			<Input
				name="pixKey"
				label={"PIX Key"}
			/>
			<FormBottomWrapper
				forFields={[ "amount" ]}
				onChange={calcFullAmount}
				label={language.languageMyAccounts.form_message_01}
			>
				<ButtonWithLoader
					isFetch={isFetch}
					color={"primary"}
					text={language.languageMyAccounts.chapas_04}
				/>
			</FormBottomWrapper>
			<Box sx={{ marginTop: "6px" }}>
				{
					infoLoading
						? (
							<Skeleton width={"220px"} height={"26px"} />
						)
						: feeDetails
							? (
								<WaningNote
									message={`Fee is ${feeDetails}`}
								/>
							)
							: <></>
				}
			</Box>
			<Snackbar
				open={success}
				autoHideDuration={4000}
				onClose={() => setSuccess(false)}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
			>
				<Alert variant="filled" color="success">
					Success
				</Alert>
			</Snackbar>
			<Snackbar open={isOpen} autoHideDuration={4000} onClose={hideError}>
				<Alert variant="filled" color="error">
					{errorMsg}
				</Alert>
			</Snackbar>
		</Form>
	);
}
