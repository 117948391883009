/*
    Created on : 24.01.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import PageTitle from "../../../components/Dashboard/dashboardComponents/PageTitle";
import FMTable from "../../../components/FMTable/FMTable";
import FMTableColumn from "../../../components/FMTable/FMTableColumn";
import { IUserSupportMail } from "../../../store/reducers/userMailsReducer";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../interfaces/StoreInterfaces";
import SubjectCell from "./components/SubjectCell";
import SupportMailMessage from "./components/SupportMailMessage";
import StatusCell from "./components/StatusCell";

export default function PageMailSupportMyEmail () {
	const lang:any = useSelector((state:StoreInterface) => state.language.languageMailSupport);
	const userMails: Array<IUserSupportMail> = useSelector((state:StoreInterface) => state.userMails.userMails);

	return (
		<div className="page-rebate-personal">
			<PageTitle
				layoutTitle={lang.ms_01}
				pageTitle={lang.ms_06}
			/>

			<FMTable
				id="MailSupportMyEmails"
				data={userMails}
				mobileScheme={{ mode: "auto" }}
				theme="TradingTheme"
				collapsibleComponent={SupportMailMessage}
				defaultPage={0}
			>
				<FMTableColumn id={"mail_support_id"} label={lang.ms_25} />
				<FMTableColumn id={"SubjectCell"} label={lang.ms_11} component={SubjectCell}/>
				<FMTableColumn id={"date_updated"} label={lang.ms_14} />
				<FMTableColumn id={"mail_status"} label={lang.ms_13} component={StatusCell}/>
			</FMTable>
		</div>
	);
};
