import * as Yup from "yup";

type LocalBankErrors = {
	account_number_01: string;
	account_number_02: string;
	account_number_err_03: string;
	deposit_err_06: string;
}

export const localBankWithdrawValidationSchema = (language: LocalBankErrors) => Yup.object().shape({
	accountNumber: Yup.string().required(language.account_number_01),
	amount: Yup
		.number()
		.typeError(language.deposit_err_06),
	bankAccountNumber: Yup
		.number()
		.required(language.account_number_01)
		.typeError(language.account_number_err_03)
});
