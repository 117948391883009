import * as React from "react";
import { Box } from "@mui/material";
import { IFMTableComponentProps } from "../../../../components/FMTable/FMTableCellComponent";
import { OptionUI } from "../../../../components/Form/Option";
import { SelectUI } from "../../../../components/Form/Select";
import { useInputStorage } from "../../../../components/Storage";
import { IRebateSystemPersonalProject } from "../../../../interfaces/RebateSystemInterfaces";
import { useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { useIsRtl } from "../../../../utils/hooks/useIsRtl";
import { useCellWrapperStyle } from "../../../../utils/hooks/useCellWrapperStyle";

export function ClientCell (props: IFMTableComponentProps<IRebateSystemPersonalProject & { index: number, blank: boolean }, {}>) {
	const { blank, client } = props;
	const wrapperStyle = useCellWrapperStyle();

	return blank
		? (
			<Clients />
		)
		: (
			<Box sx={wrapperStyle}>{client}</Box>
		);
}

function Clients () {
	const clients = useAppSelector((state) => state.rebateSystem.personalProjects);
	const referrals = useAppSelector((state) => state.rebateSystem.referrals);
	const isRtl = useIsRtl();
	const { value, handleChange } = useInputStorage("accountNumber", "");
	const wrapperStyle = useCellWrapperStyle();

	const referralsList = React.useMemo(() => {
		return referrals.filter((referral) => !clients.list?.find(({ client }) => client === referral));
	}, [ referrals, clients ]);

	return (
		<Box m={"9px 0"} sx={wrapperStyle}>
			<SelectUI value={value} onChange={handleChange as React.ChangeEventHandler<HTMLSelectElement>}>
				<OptionUI isRtl={isRtl} value="" label="Select" />
				{
					referralsList.map((referral) => (
						<OptionUI key={referral} isRtl={isRtl} value={referral} label={`${referral}`}/>
					))
				}
			</SelectUI>
		</Box>
	);
}
