import * as React from "react";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks/reduxHooks";
import { getMySubscriptionsPastSubscriptions } from "../../../store/actions/actionTypes";
import FMTable from "../../../components/FMTable/FMTable";
import FMTableColumn from "../../../components/FMTable/FMTableColumn";
import { StatusCell } from "./components/StatusCell";

const LIMIT = 1000;

export function PostSubscriptions () {
	const { languageTrading } = useAppSelector(state => state.language);
	const { data, count } = useAppSelector(state => state.copytrade.mySubscriptions.pastSubscriptions);
	const dispatch = useAppDispatch();

	const fetchPostSubscriptionsByParts = React.useCallback(() => {
		if ((data && data.length < count) || !data) {
			const currentDataLength = data?.length || 0;
			const page = Math.ceil(currentDataLength / LIMIT + 1);
			dispatch(getMySubscriptionsPastSubscriptions({ page, limit: LIMIT }));
		}
	}, [ data, count ]);

	React.useEffect(() => {
		fetchPostSubscriptionsByParts();
	}, [ data?.length ]);

	return (
		<FMTable
			data={data}
			targetRowCount={count}
			mobileScheme={{
				mode: "auto"
			}}
		>
			<FMTableColumn id="Account" label={languageTrading.trd_80} />
			<FMTableColumn id="CreateTime" label={languageTrading.trd_104} />
			<FMTableColumn id="LastModify" label={languageTrading.trd_105} />
			<FMTableColumn id="StatusId" label={languageTrading.trd_106} component={StatusCell} />
		</FMTable>
	);
}
