/*
    Created on : 20.01.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { Redirect, withRouter } from "react-router-dom";
import "./LayoutSmartDollar.scss";
import { useAppSelector } from "../../utils/hooks/reduxHooks";
import { InfoSmartDollars } from "../../pages/SmartDollar/InfoSmartDollars";

const LayoutSmartDollar = () => {
	const isActivated = useAppSelector((state) => state.smartDollars.smart_status || true);

	return (
		<div className="layout layout-smart-dollar">
			{
				isActivated
					? (
						<InfoSmartDollars />
					)
					: (
						<Redirect to="/app/loyalty-programs/smart-dollars/activate" />
					)
			}
		</div>
	);
};

export default withRouter(LayoutSmartDollar);
