import * as React from "react";
import * as Yup from "yup";
import { Form } from "../../../../../components/Form";
import { useAppDispatch, useAppSelector } from "../../../../../utils/hooks/reduxHooks";
import { AvatarSelect, PHOTOS_URL } from "./AvatarSelect";
import { photos } from "./SelectPhotoModal";
import { ButtonWithLoader } from "../../../../../components/ButtonWithLoader";
import API from "../../../../../utils/API";
import { Box } from "@mui/material";
import { createSetCpyAvatarAction } from "../../../../../store/actions/actionTypes";
import { RemoveAvatar } from "./RemoveAvatar";

const validation = Yup.object().shape({

});

export const AVATAR_URL = "https://my.forexmart.com/assets/user_images/";

export function AvatarChanger () {
	const [ isFetch, setIsFetch ] = React.useState(false);
	const dispatch = useAppDispatch();
	const languageTrading = useAppSelector(state => state.language.languageTrading);
	const initAvatar = useAppSelector(state => {
		const avatar = state.user.user.mt_accounts_cpy_avatar || "";
		if (!avatar) return "";

		if (photos.includes(avatar)) {
			return PHOTOS_URL + avatar;
		}

		return AVATAR_URL + avatar;
	});

	const submit = React.useCallback(async (values: any) => {
		setIsFetch(true);

		try {
			if (values.avatar.file) {
				const formData = new FormData();
				formData.append("new_avatar", values.avatar.file);
				const { data } = await API.post<null, { data: { image: string } }>("copytradeUpdateAvatar", formData);
				dispatch(createSetCpyAvatarAction({ mt_accounts_cpy_avatar: data.image }));
			}
			if (values.avatar.selectedPhoto) {
				const formData = new FormData();
				formData.append("imgFilename", values.avatar.selectedPhoto);
				await API.post("copytradeUpdateSelectedAvatar", formData);
				dispatch(createSetCpyAvatarAction({ mt_accounts_cpy_avatar: values.avatar.selectedPhoto }));
			}
		} catch (e) {
			//
		} finally {
			setIsFetch(false);
		}
	}, [ setIsFetch ]);

	return (
		<Box
			sx={{
				border: "1px solid #e2e7e8",
				borderTopWidth: 0
			}}
		>
			<Form
				defaultValues={{
					avatar: {
						path: initAvatar,
						file: null,
						selectedPhoto: ""
					}
				}}
				validationSchema={validation}
				onSubmit={submit}
			>
				<Box
					sx={{
						padding: "20px 15px 24px",
						borderBottom: "1px solid #e2e7e8",
						marginBottom: "20px"
					}}
				>
					<AvatarSelect name="avatar" />
				</Box>
				<ButtonWithLoader
					color="success"
					text={languageTrading.trd_175}
					isFetch={isFetch}
					sxButton={{
						maxWidth: "400px",
						width: "100%"
					}}
				/>
				<RemoveAvatar name="avatar" />
			</Form>
		</Box>
	);
}
