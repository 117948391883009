import * as React from "react";
import { FormContext } from "../../../../../components/Form";
import { FieldWrapper } from "../../../../../components/UI/FieldWrapper";
import { Row } from "../../../../../components/UI/Row";
import { LangDescSelectTab } from "./LangDescSelectTab";
import UKFlagIcon from "../../../../../images/united-kingdom-flag.png";
import RussiaFlagIcon from "../../../../../images/russia-flag.png";
import PolandFlagIcon from "../../../../../images/poland-flag.png";
import JapanFlagIcon from "../../../../../images/japan-flag.png";
import { Controller } from "react-hook-form";
import { Input } from "../../../../../components/UI/Input";
import { useAppSelector } from "../../../../../utils/hooks/reduxHooks";

export interface IMultipleFieldsProps {
	label: string;
	tips?: string;
}

enum DescriptionTabs { EN, RU, PL, JP }

const Fields = {
	[DescriptionTabs.EN]: EnDescription,
	[DescriptionTabs.RU]: RuDescription,
	[DescriptionTabs.PL]: PlDescription,
	[DescriptionTabs.JP]: JpDescription
};

export function MultipleFields (props: IMultipleFieldsProps) {
	const { tips, label } = props;
	const { fieldWrapperVariant } = React.useContext(FormContext);
	const Wrapper = React.useMemo(() => FieldWrapper[fieldWrapperVariant], [ fieldWrapperVariant ]);
	const [ tab, setTab ] = React.useState(DescriptionTabs.EN);

	const showEnTab = React.useCallback(() => setTab(DescriptionTabs.EN), [ setTab ]);
	const showRuTab = React.useCallback(() => setTab(DescriptionTabs.RU), [ setTab ]);
	const showPlTab = React.useCallback(() => setTab(DescriptionTabs.PL), [ setTab ]);
	const showJpTab = React.useCallback(() => setTab(DescriptionTabs.JP), [ setTab ]);

	const Tab = React.useMemo(() => Fields[tab], [ tab ]);

	return (
		<Wrapper
			label={label}
			tips={tips}
		>
			<Row sx={{ gap: "9px", flexWrap: "wrap", marginBottom: "8px" }}>
				<LangDescSelectTab
					active={tab}
					setActive={showEnTab}
					index={DescriptionTabs.EN}
					icon={<img src={UKFlagIcon} alt=""/>}
					text={"English"}
				/>
				<LangDescSelectTab
					active={tab}
					setActive={showRuTab}
					index={DescriptionTabs.RU}
					icon={<img src={RussiaFlagIcon} alt=""/>}
					text={"Russia"}
				/>
				<LangDescSelectTab
					active={tab}
					setActive={showPlTab}
					index={DescriptionTabs.PL}
					icon={<img src={PolandFlagIcon} alt=""/>}
					text={"Polish"}
				/>
				<LangDescSelectTab
					active={tab}
					setActive={showJpTab}
					index={DescriptionTabs.JP}
					icon={<img src={JapanFlagIcon} alt=""/>}
					text={"Japan"}
				/>
			</Row>
			<Tab />
		</Wrapper>
	);
}

function EnDescription () {
	const languageTrading = useAppSelector(state => state.language.languageTrading);

	return <Field name="descEn" placeholder={languageTrading.trd_182} />;
}

function RuDescription () {
	const languageTrading = useAppSelector(state => state.language.languageTrading);

	return <Field name="descRu" placeholder={languageTrading.trd_183} />;
}

function PlDescription () {
	const languageTrading = useAppSelector(state => state.language.languageTrading);

	return <Field name="descPl" placeholder={languageTrading.trd_184} />;
}

function JpDescription () {
	const languageTrading = useAppSelector(state => state.language.languageTrading);

	return <Field name="descJp" placeholder={languageTrading.trd_185} />;
}

function Field (props: { name: string, placeholder?: string }) {
	const { name, placeholder } = props;
	const { control } = React.useContext(FormContext);
	const InputComponent = React.useMemo(() => Input.rounded, []);

	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { value, onChange }, fieldState: { error } }) => (
				<InputComponent
					name={name}
					value={value}
					onChange={onChange}
					iserror={!!error}
					placeholder={placeholder}
				/>
			)}
		/>
	);
}
