import React from "react";

const WithdrawIcon = (props: any) => {
	let width = 16;
	let fill = "#fff";
	if (props.width) {
		width = props.width;
	}
	if (props.fill) {
		fill = props.fill;
	}

	return (
		<svg width={width} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 16">
			<defs>
				<clipPath id="clip-path">
					<rect width={width} height={width} transform="translate(35 595)" fill={fill} stroke="#707070" strokeWidth="1"/>
				</clipPath>
			</defs>
			<g transform="translate(-35 -595)" clipPath="url(#clip-path)">
				<path id="icon-fm-invert-withdraw-01" d="M2.107,7.206a.585.585,0,0,1-1.171,0V3.3a.585.585,0,0,1,.585-.585H16.351a.585.585,0,0,1,.585.585V7.011a.585.585,0,0,1-1.171,0V3.889H2.107ZM6.776,11.48a.583.583,0,0,1,.86-.786l.716.783V7.383a.586.586,0,0,1,1.171,0v4.094l.715-.783a.583.583,0,0,1,.86.786L9.373,13.367a.584.584,0,0,1-.866.007ZM3.522,4.572H14.351a.585.585,0,0,1,.585.585V15.206a.585.585,0,0,1-.585.585H3.522a.585.585,0,0,1-.585-.585V5.157a.585.585,0,0,1,.585-.585ZM13.766,5.743H4.107v8.878h9.658Z" transform="translate(34.063 593.745)" fill={fill}/>
			</g>
		</svg>
	);
};

export default WithdrawIcon;
