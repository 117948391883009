import React from "react";

const DeFlag = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
			<g fillRule="evenodd" strokeWidth="1pt">
				<path fill="#fc0" d="M0 320h640v160.002H0z"/>
				<path d="M0 0h640v160H0z"/>
				<path fill="red" d="M0 160h640v160H0z"/>
			</g>
		</svg>
	);
};

export default DeFlag;
