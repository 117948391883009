/*
    Created on : 20.01.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import "./PageChartStatistics.scss";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../interfaces/StoreInterfaces";
import PageTitle from "../../../components/Dashboard/dashboardComponents/PageTitle";
import Title from "../../../components/Typography/Title";
import StatisticChartsTabs from "./components/StatisticChartsTabs";
import { IRowObj } from "./components/formatStatisticData";

export interface IStatisticChartData<T> {
	name: string
	yId: keyof T
	data: Array<IRowObj<T>>
}

export default function PageChartStatistics<T> () {
	const lang:any = useSelector((state:StoreInterface) => state.language.language);
	const langPartnership:any = useSelector((state:StoreInterface) => state.language.languagePartnership);
	const commissionData = useSelector((state:StoreInterface) => state.partnership.commission?.data);
	const clicksData = useSelector((state:StoreInterface) => state.partnership.clicks?.data);
	const referralsData = useSelector((state:StoreInterface) => state.partnership.referralsRegistration?.referralRegistration);

	const statisticsChartsData: Array<IStatisticChartData<T>> = [
		{ name: langPartnership.parnav_04, yId: "sum" as keyof T, data: commissionData as any },
		{ name: langPartnership.parnav_05, yId: "num" as keyof T, data: clicksData as any },
		{ name: langPartnership.parnav_06, yId: "num" as keyof T, data: referralsData as any }
	];

	return (
		<div className="page-partnership-chart-statistics">
			<PageTitle
				layoutTitle={lang.sb_li_4}
				pageTitle={langPartnership.parnav_13}
			/>
			<Title mb={"14px"}>{langPartnership.com_01}</Title>
			<StatisticChartsTabs data={statisticsChartsData} />
		</div>
	);
};
