import React from "react";

export default function RebateSystemIcon (props:any) {
	let width = 16;
	let fill = "#417cbf"; // "#2a88c9";
	if (props.width) {
		width = props.width;
	}
	if (props.fill) {
		fill = props.fill;
	}

	return (
		<svg width={width} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 16">
			<defs>
				<clipPath id="clip-path-RebateSystemIcon">
					<rect width={width} height={width} transform="translate(20 22)" fill="#fff"/>
				</clipPath>
			</defs>
			<g transform="translate(-20 -22)" clipPath="url(#clip-path-RebateSystemIcon)">
				<g id="icon-fm-rebate" transform="translate(19.055 21.205)">
					<path d="M9.243,12.1a1.631,1.631,0,1,0,1.631-1.629A1.632,1.632,0,0,0,9.243,12.1Zm1.63.371a.371.371,0,1,1,.372-.372A.371.371,0,0,1,10.873,12.476Z" fill={fill}/>
					<path d="M6.162,12.368l.889.891,4.614-4.613.063-.064-.889-.891L6.225,12.3Z" fill={fill}/>
					<path d="M12.31,3.594a3.424,3.424,0,0,0-6.732,0H3.216V16.8H14.673V3.594ZM8.945,2.054a2.165,2.165,0,0,1,2.073,1.54H6.871A2.163,2.163,0,0,1,8.945,2.054ZM6.775,5.993V4.855h4.339V5.993h1.259V4.855h1.041V15.536H4.476V4.855h1.04V5.993Z" fill={fill}/>
					<path d="M7.016,10.476A1.631,1.631,0,1,0,5.385,8.845,1.634,1.634,0,0,0,7.016,10.476Zm0-1.262a.371.371,0,1,1,.369-.37A.371.371,0,0,1,7.016,9.215Z" fill={fill}/>
				</g>
			</g>
		</svg>
	);
};
