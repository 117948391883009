/* eslint-disable camelcase */
import * as React from "react";
import { Select } from "../../../../components/Form/Select";
import { Option } from "../../../../components/Form/Option";
import { DefaultForm } from "../../../../components/Forms/DepositForm/DefaultForm";
import { Input } from "../../../../components/Form/Input";
import { fasapayValidationSchema } from "./validation";
import { useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { getPaymentNetworkRequest } from "../../../../utils/getPaymentNetworkRequest";
import { getInfo } from "./api";

export function Fasapay () {
	const [ isFetch, setIsFetch ] = React.useState(false);
	const languageFinance = useAppSelector((state) => state.language.languageFinance);
	const { trd_276 } = languageFinance;
	const { mya_20 } = useAppSelector((state) => state.language.languageMyAccounts);

	const onSubmit = React.useCallback(async (values: any) => {
		try {
			setIsFetch(true);
			const { amount, currency, fasapayAccountNumber } = values;
			const info = await getInfo({
				sum: amount,
				currency,
				account: fasapayAccountNumber
			});
			const requestLink = getPaymentNetworkRequest(info.fasapay);
			window.open(requestLink, "_self");
		} catch (e) {
			// handle some error
		} finally {
			setIsFetch(false);
		}
	}, []);

	return (
		<DefaultForm
			defaultValues={{
				fasapayAccountNumber: "",
				currency: "USD"
			}}
			isFetch={isFetch}
			onSubmit={onSubmit}
			validationSchema={fasapayValidationSchema(languageFinance)}
		>
			<Input
				name="fasapayAccountNumber"
				label={trd_276}
				variant="rounded"
			/>
			<Select
				name="currency"
				label={mya_20}
			>
				<Option value="USD">USD</Option>
				<Option value="IDR">IDR</Option>
			</Select>
		</DefaultForm>
	);
}
