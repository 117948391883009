/*
    Created on : 17.06.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import ClientAuthOpenRealAccount from "./components/ClientAuthOpenRealAccount";
import AuthForm from "./components/AuthForm";
import AuthFormPaper from "./components/AuthFormPaper";
import PageAuthSection from "./components/PageAuthSection";
import { useAppSelector } from "../../../utils/hooks/reduxHooks";
import { Redirect } from "react-router-dom";

export function PageAuth () {
	const auth = useAppSelector(state => state.auth);
	const neededTFA = useAppSelector(state => state.session.userData.neededTFA);

	return (
		<PageAuthSection>
			{
				(auth.redirectToTFA || neededTFA) ? <Redirect to="/app/verify-tfa" /> : <></>
			}
			<ClientAuthOpenRealAccount/>
			<AuthFormPaper>
				<AuthForm/>
			</AuthFormPaper>
		</PageAuthSection>
	);
};
