/*
    Created on : 17.06.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { Box } from "@mui/material";
import { useAdaptive } from "../../../../utils/hooks/themeBreakpointsHooks";
import { IChildren } from "../../../../types/common";

export default function PageAuthSection ({ children }: { children: IChildren }) {
	const boxSX = useAdaptive(aSX);

	return (
		<Box
			className="container flexRow"
			sx={{
				width: "100%",
				alignItems: "flex-start",
				flexWrap: "wrap",
				height: "auto",
				margin: "0 auto",
				...boxSX
			}}
		>
			{children}
		</Box>
	);
};

const aSX = {
	mobile: {
		justifyContent: "center",
		maxWidth: "100%",
		padding: "10px 15px 110px"
	},
	laptop: {
		justifyContent: "space-between",
		maxWidth: "1103px",
		padding: "101px 15px 110px"
	}
};
