import * as React from "react";
import { useWatch } from "react-hook-form";
import { FormContext } from "../Form";

export interface ForProps {
	field: string;
	withValues: string;
	children: JSX.Element | JSX.Element[]
}

export function For (props: ForProps) {
	const { field, withValues, children } = props;
	const { control } = React.useContext(FormContext);

	const value = useWatch({
		control,
		name: field
	});

	return withValues.split(",").includes(value) ? <>{children}</> : <></>;
}
