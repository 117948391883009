import React from "react";

const IdFlag = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
			<g fillRule="evenodd" strokeWidth="1pt">
				<path fill="#e70011" d="M0 0h639.958v248.947H0z"/>
				<path fill="#fff" d="M0 240h639.958v240H0z"/>
			</g>
		</svg>
	);
};

export default IdFlag;
