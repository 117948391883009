import * as React from "react";
import { Portal } from "@mui/material";
import { FormNavigation } from "../../../../components/FormNavigation";
import { Redirect, Route } from "react-router-dom";
import { ZotaPay } from "./ZotaPay";
import { VNPay } from "./VNPay";
import { PayTrust } from "./PayTrust";

export interface LocalBankTransferVietnamProps {
	tabsMenuContainer: React.MutableRefObject<HTMLElement | null>
}
export function LocalBankTransferVietnam (props: LocalBankTransferVietnamProps) {
	const { tabsMenuContainer } = props;

	return (
		<>
			<Portal container={tabsMenuContainer.current}>
				<FormNavigation
					paths={[
						{
							to: "/app/withdraw/vnd-local-bank-transfer/vn-pay",
							title: "VN Pay",
							styles: {
								marginRight: "15px"
							}
						},
						{
							to: "/app/withdraw/vnd-local-bank-transfer/zota-pay",
							title: "ZotaPay",
							styles: {
								marginRight: "15px"
							}
						},
						{
							to: "/app/withdraw/vnd-local-bank-transfer/pay-trust",
							title: "PayTrust"
						}
					]}
				/>
			</Portal>

			<Route exact path="/app/withdraw/vnd-local-bank-transfer">
				<Redirect to="/app/withdraw/vnd-local-bank-transfer/zota-pay" />
			</Route>
			<Route
				path="/app/withdraw/vnd-local-bank-transfer/vn-pay"
			>
				<VNPay/>
			</Route>
			<Route
				path="/app/withdraw/vnd-local-bank-transfer/zota-pay"
			>
				<ZotaPay />
			</Route>
			<Route
				path="/app/withdraw/vnd-local-bank-transfer/pay-trust"
			>
				<PayTrust />
			</Route>
		</>
	);
}
