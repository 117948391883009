import * as React from "react";

export function useErrorHandling () {
	const [ isOpen, setIsOpen ] = React.useState(false);
	const [ errorMsg, setErrorMsg ] = React.useState("");

	const hideError = React.useCallback(() => {
		setIsOpen(false);
		setErrorMsg("");
	}, [ setErrorMsg, setIsOpen ]);

	const showError = React.useCallback((message: string) => {
		setErrorMsg(message);
		setIsOpen(true);
	}, [ setIsOpen, setErrorMsg ]);

	return {
		isOpen,
		errorMsg,
		hideError,
		showError
	};
}
