/*
    Created on : 24.01.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import PageTitle from "../../../components/Dashboard/dashboardComponents/PageTitle";
import CommonRebate from "../CommonRebate";
import PeriodForm from "../../Partnership/components/PeriodForm";
import Title from "../../../components/Typography/Title";
import { Box, Skeleton } from "@mui/material";
import { CartesianGrid, LineChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks/reduxHooks";
import getDateWithOffset from "../../../utils/getDateWithOffset";
import { getRebateSystemStatistics } from "../../../store/actions/actionTypes";

const Chart = () => {
	const chartData = useAppSelector((state) => state.rebateSystem.statistics.data ?? []);
	const { languageTrading } = useAppSelector((state) => state.language);
	const isFetch = useAppSelector((state) => state.rebateSystem.statistics.isFetch);
	console.log(chartData);
	return (
		<>
			<Title
				textAlign="center"
				fontSize={24}
			>
				{languageTrading.reb_statistics}
			</Title>
			<Box
				height="294px"
				border="1px solid #5C7080"
				m="12px 0 0"
			>{
					isFetch
						? (
							<Skeleton
								variant="rectangular"
								animation="wave"
								sx={{ width: "100%", height: "100%" }}
							/>
						)
						: (
							<ResponsiveContainer height={294}>
								<LineChart data={chartData.reverse()}>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis dataKey="x" />
									<YAxis orientation="right" />
									<Line key="client" dataKey="y" name="Amount" />
									<Tooltip />
								</LineChart>
							</ResponsiveContainer>
						)
				}
			</Box>
		</>
	);
};

export default function PageRebateStatistics () {
	const referrals = useAppSelector((state) => state.rebateSystem.referrals);
	const {
		languageCopytrade,
		languageTrading,
		languagePartnership,
		languageFinance
	} = useAppSelector((state) => state.language);
	const dispatch = useAppDispatch();

	const getStatistics = React.useCallback((values: { client: number, dateFrom: number; dateTo: number }) => {
		dispatch(getRebateSystemStatistics({
			client: (values.client && values.client !== languageCopytrade.copytrade_12) ? values.client : 0,
			from: new Date(values.dateFrom).toISOString(),
			to: new Date(values.dateTo).toISOString()
		}));
	}, [ dispatch ]);

	return (
		<div className="page-rebate-statistic">
			<PageTitle
				layoutTitle={languageTrading.reb_txt_1}
				pageTitle={languageTrading.reb_txt_7}
			/>

			<CommonRebate/>

			<PeriodForm
				schema={{
					buttons: {
						chart: {
							title: languageTrading.show_chart,
							color: "success",
							type: "submit"
						}
					},
					inputs: {
						client: {
							type: "select",
							label: languagePartnership.choose_account_01,
							options: [ languageCopytrade.copytrade_12, ...referrals.map((referral) => `${referral}`) ]
						},
						dateFrom: {
							label: languageFinance.trans_00,
							type: "date"
						},
						dateTo: {
							type: "date",
							label: languageFinance.trans_01
						}
					}
				}}
				defaultValues={{
					client: languageCopytrade.copytrade_12,
					dateFrom: getDateWithOffset(1),
					dateTo: Date.now()
				}}
				onSubmit={getStatistics}
			/>

			<Chart />
		</div>
	);
};
