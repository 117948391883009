import { Box } from "@mui/material";
import * as React from "react";
import ReactDOM from "react-dom";

export interface IModalPortalProps {
	children: JSX.Element | JSX.Element[];
}

export function ModalPortal (props: IModalPortalProps) {
	const modalWrapper = React.useMemo(() => document.getElementById("modal"), []);

	return modalWrapper
		? ReactDOM.createPortal(
			<Box sx={{ width: "100vw", height: "100vh", background: "rgba(92, 112, 128, 0.70)" }}>
				{props.children}
			</Box>,
			modalWrapper
		)
		: <></>;
}
