import { Breakpoint, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export function useIsMobile (key: number | Breakpoint = "laptop") {
	const theme = useTheme();
	return useMediaQuery(theme.breakpoints.down(key));
}

export function useDevice () {
	const theme = useTheme();
	return theme.breakpoints.keys.filter((key) => key.length > 2).reduce(function (points: Array<any>, key) {
		if (key && useMediaQuery(theme.breakpoints.up(key))) { points.push(key); }
		return points;
	}, []);
}
