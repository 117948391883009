import * as React from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import IconButton from "@mui/material/IconButton";
import { useIsRtl } from "../../../utils/hooks/useIsRtl";
import DashboardSidebarMenu from "./DashboardSidebarMenu";

export default function DashboardSidebarDesktopMenu (props: any) {
	const isRtl = useIsRtl();
	const toggleDrawer = () => {
		props.setOpen(!props.open);
	};

	return (
		<>
			<IconButton
				sx={{
					color: "#8dc2e5",
					position: "absolute",
					top: "12px",
					left: !isRtl ? "auto" : "-40px",
					right: !isRtl ? "-40px" : "auto",
					borderRadius: !isRtl ? "0 50% 50% 0" : "50% 0 0 50% "
				}}
				onClick={toggleDrawer}>
				{props.open
					? !isRtl ? <ChevronLeftIcon/> : <ChevronRightIcon/>
					: !isRtl ? <ChevronRightIcon/> : <ChevronLeftIcon/>
				}
			</IconButton>
			<DashboardSidebarMenu { ...props }/>
		</>
	);
}
