/*
    Created on : 20.01.2022, 06:18:59
    Author     : Andy Kar
*/
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import {
	FETCH_LANGUAGE_PARTNERSHIP,
	FETCH_PARTNERSHIP_CLICKS_DATA,
	FETCH_PARTNERSHIP_COMMISSION_DATA,
	FETCH_PARTNERSHIP_DATA,
	FETCH_PARTNERSHIP_REFERRALS_DATA, FETCH_PARTNERSHIP_REFERRALS_REGISTRATION_DATA
} from "../../store/actions/actionTypes";
import {
	StoreInterface
} from "../../interfaces/StoreInterfaces";
import "./LayoutPartnership.scss";
import PageCommission from "../../pages/Partnership/PageCommission/PageCommission";
import PageClicks from "../../pages/Partnership/PageClicks/PageClicks";
import PageReferrals from "../../pages/Partnership/PageReferrals/PageReferrals";
import AccountTypes from "../components/AccountTypes/AccountTypes";
import PageInternalTransitService from "../../pages/Partnership/PageInternalTransitService/PageInternalTransitService";
import PageChartStatistics from "../../pages/Partnership/PageChartStatistics/PageChartStatistics";
import { useAppSelector } from "../../utils/hooks/reduxHooks";

const LayoutPartnership = (props:any) => {
	const siteLang:string|undefined = useSelector((state:StoreInterface) => state.session.userData.site_lang);
	const code = useAppSelector(state => state.partnership.info?.affiliate_code);
	const actualCode = useAppSelector(state => state.partnership.clicks?.code);
	const accountNumber = useAppSelector(state => state.partnership.info.data?.accountnumber);
	const actualAccountNumber = useAppSelector(state => state.partnership.commission?.account);

	const dispatch = useDispatch();
	useEffect(() => {
		if (!accountNumber) {
			dispatch({ type: FETCH_PARTNERSHIP_DATA });
		}
	}, []);

	useEffect(() => {
		if ((code !== actualCode) || (accountNumber !== actualAccountNumber)) {
			dispatch({ type: FETCH_PARTNERSHIP_CLICKS_DATA, partnershipClicksData: { code } });
		}
	}, [ code, actualCode, accountNumber, actualAccountNumber, null ]);

	useEffect(() => {
		if (accountNumber !== actualAccountNumber) {
			dispatch({ type: FETCH_PARTNERSHIP_REFERRALS_DATA });
			dispatch({ type: FETCH_PARTNERSHIP_REFERRALS_REGISTRATION_DATA });
			dispatch({ type: FETCH_PARTNERSHIP_COMMISSION_DATA });
		}
	}, [ accountNumber ]);

	useEffect(() => {
		dispatch({ type: FETCH_LANGUAGE_PARTNERSHIP, language: siteLang });
	}, [ siteLang ]);

	return (
		<div className="layout layout-partnership">
			<Switch>
				<Route exact path="/app/partnership/commission" render={() => <PageCommission {...props} />} />
				<Route exact path="/app/partnership/clicks" render={() => <PageClicks {...props} />} />
				<Route exact path="/app/partnership/referrals" render={() => <PageReferrals {...props} />} />
				<Route exact path="/app/partnership/chart-statistics" render={() => <PageChartStatistics {...props} />} />
				<Route exact path="/app/partnership/internal-transit-service" render={() => <PageInternalTransitService {...props} />} />
				<Redirect exact from="/app/partnership" to="/app/partnership/commission" />
			</Switch>
			<AccountTypes/>
		</div>
	);
};

export default withRouter(LayoutPartnership);
