import { Box, Grid, SxProps } from "@mui/material";
import * as React from "react";
import * as Yup from "yup";
import { ButtonWithLoader } from "../../../../../components/ButtonWithLoader";
import { Form } from "../../../../../components/Form";
import { Input } from "../../../../../components/Form/Input";
import { Option } from "../../../../../components/Form/Option";
import { Select } from "../../../../../components/Form/Select";
import { Row } from "../../../../../components/UI/Row";
import { createUpdateProfileDetailsAction } from "../../../../../store/actions/actionTypes";
import API from "../../../../../utils/API";
import { useAppDispatch, useAppSelector } from "../../../../../utils/hooks/reduxHooks";
import { useAdaptive } from "../../../../../utils/hooks/themeBreakpointsHooks";
import { useIsMobile } from "../../../../../utils/hooks/useIsMobile";

const validation = Yup.object().shape({});

const xsSelectStyles: SxProps = {
	width: "70%"
};

const smSelectStyles: SxProps = {
	width: "80%",
	marginLeft: "15px"
};

const xsSelectWrapperStyles: SxProps = {
	marginBottom: "41px"
};

const smSelectWrapperStyles: SxProps = {
	marginBottom: "20px"
};

const xsFirstSelectWrapperStyles: SxProps = {
	...xsSelectWrapperStyles,
	paddingTop: "20px"
};

const smFirstSelectWrapperStyles: SxProps = {
	...smSelectWrapperStyles,
	paddingTop: 0
};

export function ContactInformation () {
	const languageInit = useAppSelector(state => state.language.language);
	const languageCopytrade = useAppSelector(state => state.language.languageCopytrade);
	const languageTrading = useAppSelector(state => state.language.languageTrading);
	const {
		AccountName,
		Email,
		Phone,
		Skype,
		Icq,
		ShowEmail,
		ShowPhone,
		ShowSkype,
		ShowIcq,
		ShowAccountName
	} = useAppSelector(state => state.copytrade.profile.details || {});
	const dispatch = useAppDispatch();

	const [ isFetch, setIsFetch ] = React.useState(false);
	const isMobile = useIsMobile(649);

	const submit = React.useCallback(async (values: any) => {
		setIsFetch(true);
		try {
			const formData = new FormData();
			formData.append("icq", values.icq);
			formData.append("skype", values.skype);
			formData.append("email", values.email);
			formData.append("phone", values.phone);
			formData.append("show_acc_name", values.show_acc_name);
			formData.append("show_icq", values.show_icq);
			formData.append("show_skype", values.show_skype);
			formData.append("show_phone", values.show_phone);
			formData.append("show_email", values.show_email);

			await API.post("copytradeUpdateContactInformation", formData);

			dispatch(createUpdateProfileDetailsAction({
				Icq: values.icq,
				Skype: values.skype,
				Email: values.email,
				Phone: values.phone,
				ShowAccountName: values.show_acc_name,
				ShowIcq: values.show_icq,
				ShowSkype: values.show_skype,
				ShowPhone: values.show_phone,
				ShowEmail: values.show_email
			}));
		} catch (e) {
			//
		} finally {
			setIsFetch(false);
		}
	}, []);

	const adaptiveSelectStyles = useAdaptive({
		xs: xsSelectStyles,
		sm: smSelectStyles
	});

	const adaptiveSelectWrapperStyles = useAdaptive({
		xs: xsSelectWrapperStyles,
		sm: smSelectWrapperStyles
	});

	const adaptiveFirstSelectWrapperStyles = useAdaptive({
		xs: xsFirstSelectWrapperStyles,
		sm: smFirstSelectWrapperStyles
	});

	return (
		<Form
			defaultValues={{
				accountName: AccountName,
				email: Email,
				phone: Phone,
				skype: Skype,
				icq: Icq,
				show_acc_name: ShowAccountName,
				show_icq: ShowIcq,
				show_skype: ShowSkype,
				show_phone: ShowPhone,
				show_email: ShowEmail
			}}
			validationSchema={validation}
			onSubmit={submit}
			fieldWrapperVariant={isMobile ? "with-tip" : "inline-bold"}
		>
			<Box
				sx={{
					border: "1px solid #e2e7e8",
					borderTop: 0,
					padding: "23px"
				}}
			>
				<Row>
					<Grid
						container
						flexDirection="column"
						sx={{ marginRight: "15px" }}
					>
						<Grid item sx={{ marginBottom: "20px" }}>
							<Input name="accountName" label={languageTrading.trd_190} variant="rounded" />
						</Grid>
						<Grid item sx={{ marginBottom: "20px" }}>
							<Input name="icq" label={languageCopytrade.copytrade_40} variant="rounded" />
						</Grid>
						<Grid item sx={{ marginBottom: "20px" }}>
							<Input name="skype" label={languageCopytrade.copytrade_46} variant="rounded" />
						</Grid>
						<Grid item sx={{ marginBottom: "20px" }}>
							<Input name="email" label={languageCopytrade.copytrade_44} variant="rounded" />
						</Grid>
						<Grid item>
							<Input name="phone" label={languageCopytrade.copytrade_42} variant="rounded" />
						</Grid>
					</Grid>
					<Grid
						container
						flexDirection="column"
						sx={adaptiveSelectStyles}
					>
						<Grid item sx={adaptiveFirstSelectWrapperStyles}>
							<Select name="show_acc_name" label="">
								<Option value="0">{languageCopytrade.hidden}</Option>
								<Option value="1">{languageCopytrade.available_to_all}</Option>
								<Option value="2">{languageCopytrade.available_to_f}</Option>
							</Select>
						</Grid>
						<Grid item sx={adaptiveSelectWrapperStyles}>
							<Select name="show_icq" label="">
								<Option value="0">{languageCopytrade.hidden}</Option>
								<Option value="1">{languageCopytrade.available_to_all}</Option>
								<Option value="2">{languageCopytrade.available_to_f}</Option>
							</Select>
						</Grid>
						<Grid item sx={adaptiveSelectWrapperStyles}>
							<Select name="show_skype" label="">
								<Option value="0">{languageCopytrade.hidden}</Option>
								<Option value="1">{languageCopytrade.available_to_all}</Option>
								<Option value="2">{languageCopytrade.available_to_f}</Option>
							</Select>
						</Grid>
						<Grid item sx={adaptiveSelectWrapperStyles}>
							<Select name="show_email" label="">
								<Option value="0">{languageCopytrade.hidden}</Option>
								<Option value="1">{languageCopytrade.available_to_all}</Option>
								<Option value="2">{languageCopytrade.available_to_f}</Option>
							</Select>
						</Grid>
						<Grid item>
							<Select name="show_phone" label="">
								<Option value="0">{languageCopytrade.hidden}</Option>
								<Option value="1">{languageCopytrade.available_to_all}</Option>
								<Option value="2">{languageCopytrade.available_to_f}</Option>
							</Select>
						</Grid>
					</Grid>
				</Row>
			</Box>
			<ButtonWithLoader
				sxButton={{
					maxWidth: "240px",
					width: "100%"
				}}
				sx={{
					marginTop: "30px",
					maxWidth: "240px"
				}}
				color="success"
				isFetch={isFetch}
				text={languageInit.cs_06}
			/>
		</Form>
	);
}
