import * as React from "react";
import {
	useAppDispatch,
	useAppSelector
} from "../../../../../../utils/hooks/reduxHooks";
import { getOpenTradesHistory } from "../../../../../../store/actions/actionTypes";
import FMTable from "../../../../../../components/FMTable/FMTable";
import FMTableColumn from "../../../../../../components/FMTable/FMTableColumn";
import { Box } from "@mui/material";

const LIMIT = 30;

export interface IOpenTradesProps {
	connectionId: number;
}

export function OpenTrades (props: IOpenTradesProps) {
	const { connectionId } = props;
	const {
		data,
		count = 0,
		page = 1,
		limit
	} = useAppSelector(state => state.copytrade.mySubscriptions.traders.data?.find((trader) => trader.ConnectionId === connectionId)?.tradesHistory?.openTrades || {});
	const langCopytrade = useAppSelector((state) => state.language.languageCopytrade);
	const langTrading = useAppSelector((state) => state.language.languageTrading);
	const dispatch = useAppDispatch();

	const getPartOfOpenTrades = React.useCallback(() => {
		if ((data && data.length < count) || !data) {
			dispatch(getOpenTradesHistory({ start: (page - 1) * LIMIT, limit: LIMIT, connectionId }));
		}
	}, [ page, limit ]);

	React.useEffect(() => {
		getPartOfOpenTrades();
	}, [ data?.length, getPartOfOpenTrades ]);

	return (
		<Box sx={{ paddingTop: "10px" }} >
			<FMTable
				data={data}
				targetRowCount={count}
				withSearch={false}
				rowsPerPageOptions={[]}
				mobileScheme={{
					mode: "points",
					1920: {
						collapsibleColumns: [ 14, 13, 12 ]
					},
					1400: {
						collapsibleColumns: [ 14, 13, 12, 11, 10 ]
					},
					1100: {
						collapsibleColumns: [ 14, 13, 12, 11, 10, 9, 8, 7 ]
					},
					800: {
						collapsibleColumns: [ 14, 13, 12, 11, 10, 9, 8, 7, 6, 5 ]
					},
					500: {
						collapsibleColumns: [ 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3 ]
					}
				}}
			>
				<FMTableColumn id="CopyId" label={langCopytrade.copytrade_65} />
				<FMTableColumn id="CopyStatus" label={langTrading.trd_106} />
				<FMTableColumn id="MasterTicket" label={langCopytrade.copytrade_66} />
				<FMTableColumn id="Ticket" label={langTrading.trd_288} />
				<FMTableColumn id="Symbol" label={langTrading.trd_126} />
				<FMTableColumn id="CmdStatus" label={langCopytrade.copytrade_67} />
				<FMTableColumn id="Volume" label={langTrading.trd_126} />
				<FMTableColumn id="OpenPrice" label={langTrading.trd_127} />
				<FMTableColumn id="ClosePrice" label={langCopytrade.curtra_11} />
				<FMTableColumn id="OpenTimed" label={langCopytrade.curtra_16} />
				<FMTableColumn id="Sl" label={langCopytrade.copytrade_65} />
				<FMTableColumn id="Tp" label={langCopytrade.copytrade_65} />
				<FMTableColumn id="Profit" label={langCopytrade.copytrade_48} />
			</FMTable>
		</Box>
	);
}
