import { AxiosResponse } from "axios";
import API from "../../../../utils/API";
import { PaymentMethod } from "../types";

export type NetellerInfoType = {
	redirect: string;
}

export async function getInfo ({
	amount,
	account,
	currency
}: {
	amount: string,
	account: string,
	currency: string,
}): Promise<NetellerInfoType> {
	const formData = new FormData();
	formData.append("amount", amount);
	formData.append("neteller_account", account);
	formData.append("currency", currency);
	formData.append("payment_method", PaymentMethod.NETELLER);

	const { data }: AxiosResponse<NetellerInfoType> = await API.post("depositNeteller", formData);

	return data;
};
