import * as React from "react";
import Box from "@mui/material/Box";
import { IChildren } from "../../types/common";
import {
	useCurrentThemeBreakpointsCodes
} from "../../utils/hooks/themeBreakpointsHooks";
import { ThemeBreakpointContext } from "../../utils/Contexts/ThemeBreakpointContext";

export default function DashboardPage ({ children }: { children: IChildren}) {
	const breakpointsData = useCurrentThemeBreakpointsCodes();
	return (
		<Box id="mainContent" sx={{ flexGrow: 1 }} ref={breakpointsData.ref} >
			<ThemeBreakpointContext.Provider value={breakpointsData.codes}>
				{children}
			</ThemeBreakpointContext.Provider>
		</Box>
	);
};
