import * as React from "react";
import Box from "@mui/material/Box";
import DashboardSidebar from "./DashboardSidebar";
import DashboardAppBar from "./DashboardAppBar";
import DashboardSocialBox from "./DashboardSocialBox";
import DashboardLveAgent from "./dashboardComponents/DashboardLveAgent";
import { useIsRtl } from "../../utils/hooks/useIsRtl";
import DashboardContent from "./DashboardContent";
import { IChildren } from "../../types/common";

const drawerWidth: number = 276;

function Dashboard ({ children }: { children: IChildren}) {
	const [ open, setOpen ] = React.useState(true);
	const [ accordion, setAccordion ] = React.useState<string | false>(false);
	const isRtl = useIsRtl();

	return (
		<Box sx={{ display: "flex" }} className={"flexRow"}>
			<DashboardSidebar
				open={open}
				setOpen={setOpen}
				accordion={accordion}
				setAccordion={setAccordion}
				anchor={null}
			/>
			<Box component="main"
				sx={{
					display: "flex",
					flexDirection: "column",
					position: "relative",
					flexGrow: 1,
					overflow: "hidden",
					maxWidth: "100%",
					minHeight: "max-content",
					alignItems: isRtl ? "flex-end" : "flex-start",
					width: `calc(100% - ${drawerWidth}px)`
				}}
			>
				<DashboardAppBar
					open={open}
					setOpen={setOpen}
					setAccordion={setAccordion}
				/>
				<DashboardContent>
					{children}
				</DashboardContent>
			</Box>
			<DashboardSocialBox/>
			<DashboardLveAgent/>
		</Box>
	);
}

export default Dashboard;
