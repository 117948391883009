/*
    Created on : 12.10.2021, 06:18:59
    Author     : Andy Kar
*/

import {
	SET_LENGTH_LOADED_TRADES,
	SET_LENGTH_TRADES, SET_OPENED_TRADES,
	SET_PART_TRADES
} from "../actions/actionTypes";

export type ITrade = {
	tradeNumber: number,
	ticket: number,
	type: string,
	volume: number,
	symbol: string,
	OpenPrice: number,
	closePrice?: number,
	openTime: string
	closeTime?: string,
	sl: number,
	tp: number,
	swap: number,
	profit?: number
};

const initial = {
	loadingTrades: false,
	tradesLength: 0,
	tradesAll: null as [] | null,
	tradesAllLength: 0,
	tradesOpened: null as [] | null
};

export default function tradesReducer (state = initial, action:{type:string, payload: any}) {
	switch (action.type) {
		case SET_PART_TRADES:
			return {
				...state,
				tradesAll: state.tradesAll ? state.tradesAll.concat(action.payload) : action.payload
			};
		case SET_LENGTH_TRADES:
			return {
				...state,
				tradesLength: action.payload
			};
		case SET_LENGTH_LOADED_TRADES:
			return {
				...state,
				tradesAllLength: state.tradesAllLength + action.payload
			};
		case SET_OPENED_TRADES:
			return {
				...state,
				tradesOpened: action.payload
			};
		default:
			return state;
	}
}
