/*
    Created on : 10.12.2021, 06:18:59
    Author     : Andy Kar
*/

import { combineReducers } from "redux";
import sessionReducer from "./sessionReducer";
import languageReducer from "./languageReducer";
import initReducer from "./initReducer";
import feedbackReducer from "./feedbackReducer";
import userReducer from "./userReducer";
import copytradeReducer from "./copytradeReducer";
import authReducer from "./authReducer";
import myAccountsReducer from "./myAccountsReducer";
import accountReducer from "./accountReducer";
import partnershipReducer from "./partnershipReducer";
import tradesReducer from "./tradesReducer";
import financeReducer from "./financeReducer";
import { expandedRowReducer } from "./expandedRowReducer";
import { rebateSystemReducer } from "./rebateSystemReducer";
import userMailsReducer from "./userMailsReducer";
import bannersReducer from "./bannersReducer";
import { cryptoCurrencyStatusReducer } from "./cryptoCurrencyStatusReducer";
import { bonusReducer } from "./bonusReducer";
import { withdrawMethodsInfoReducer } from "./withdrawMethodsInfoReducer";
import smartDollarsReducer from "./smartDollarReducers";
import myAchievementsReducer from "./myAchievementsReducer";

export default combineReducers({
	session: sessionReducer,
	auth: authReducer,
	user: userReducer,
	init: initReducer,
	language: languageReducer,
	feedback: feedbackReducer,
	copytrade: copytradeReducer,
	trades: tradesReducer,
	myAccounts: myAccountsReducer,
	account: accountReducer,
	partnership: partnershipReducer,
	finance: financeReducer,
	expandedRowPages: expandedRowReducer,
	rebateSystem: rebateSystemReducer,
	userMails: userMailsReducer,
	banners: bannersReducer,
	cryptoCurrencyStatus: cryptoCurrencyStatusReducer,
	bonus: bonusReducer,
	withdrawMethodsInfo: withdrawMethodsInfoReducer,
	smartDollars: smartDollarsReducer,
	myAchievements: myAchievementsReducer
});
