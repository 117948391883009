/*
    Created on : 30.12.2021, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { Paper } from "@mui/material";
import { useAdaptive } from "../../../../utils/hooks/themeBreakpointsHooks";
import { IChildren } from "../../../../types/common";

export default function AuthFormPaper ({ children }: { children: IChildren}) {
	const paperSX = useAdaptive(aSX);
	return (
		<Paper
			variant="outlined"
			square
			sx={paperSX}
		>
			{children}
		</Paper>
	);
};

const aSX = {
	mobile: {
		padding: "25px 20px",
		maxWidth: "600px",
		width: "100%"
	},
	tablet: {
		padding: "39px 41px 34px"
	}
};
