import * as React from "react";
import { Box, Typography } from "@mui/material";
import { ReactComponent as GreenSuccessIcon } from "../../../../images/GreenSuccessIcon.svg";
import { ICheckboxProps } from "../types";

export interface ISquareCheckBoxProps extends ICheckboxProps {}

export function SquareCheckBox (props: ISquareCheckBoxProps) {
	const { label, onChange, name, value } = props;
	const [ isChecked, setIsChecked ] = React.useState(value);
	const ref = React.useRef<HTMLInputElement | null>(null);

	const _onChange = React.useCallback(() => {
		onChange(!isChecked);
		setIsChecked(!isChecked);
	}, [ isChecked, onChange, setIsChecked ]);

	const change = React.useCallback(() => {
		if (ref.current) {
			ref.current.click();
		}
	}, [ ref ]);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				cursor: "pointer"
			}}

			onClick={change}
		>
			<Box sx={{
				width: "14px",
				height: "14px",
				borderRadius: "4px",
				border: "1px solid",
				borderColor: isChecked ? "transparent" : "#707070",
				padding: "2px",
				marginRight: "8px",
				background: isChecked ? "transparent" : "#fff"

			}}>
				<Box sx={{ position: "absolute", top: "50%", left: "0", marginTop: "-10px" }}>
					{ isChecked ? <GreenSuccessIcon/> : <></> }
				</Box>
			</Box>
			<Typography lineHeight="1.1875rem" color="#707070">
				{label}
			</Typography>
			<input ref={ref} name={name} type="checkbox" hidden onChange={_onChange}/>
		</Box>
	);
}
