/*
    Created on : 30.12.2021, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { Box, Button, Typography } from "@mui/material";
import languageLink from "../../../../utils/languageLink";
import { useSelector } from "react-redux";
import { SessionUserDataInterface, StoreInterface } from "../../../../interfaces/StoreInterfaces";
import { useAdaptive } from "../../../../utils/hooks/themeBreakpointsHooks";
import { SignInType } from "./AuthForm";

export default function SignInHeader ({ title, type } : { title: string, type: SignInType }) {
	const lang:any = useSelector((state:StoreInterface) => state.language.language);
	const init:any = useSelector((state:StoreInterface) => state.init.init);
	const session:SessionUserDataInterface = useSelector((state:StoreInterface) => state.session.userData);
	const titleSX = useAdaptive(aSX);

	return (
		<Box sx={boxSX}>
			<Typography component="h3" sx={titleSX}>
				{/* {lang.cs_01} */}
				Sign In<span style={{ display: "block", fontWeight: "bold" }}>{title}</span>
			</Typography>
			<Button
				sx={buttonSX}
				variant="contained"
				target="_blank"
				rel="noreferrer"
				href={init.links["link_" + type + "_register"] && session.site_lang ? languageLink(init.links["link_" + type + "_register"], session.site_lang) : ""}
			>{lang.xnv_R}</Button>
		</Box>
	);
};

const boxSX = {
	display: "flex",
	justifyContent: "space-between"
};

const buttonSX = {
	borderRadius: 0,
	padding: "0 13px",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	textTransform: "none",
	minWidth: "120px",
	backgroundColor: "#DDDDDD",
	color: "#333",
	maxHeight: "45px",
	"&:hover": {
		backgroundColor: "#d7d4d4",
		color: "#333"
	}
};

const aSX = {
	mobile: {
		fontSize: "20px",
		textAlign: "left",
		lineHeight: "30px",
		"& span": {
			fontSize: "29px",
			lineHeight: "43px"
		}
	},
	tablet: {
		fontSize: "29px",
		lineHeight: "39px",
		"& span": {
			fontSize: "38px",
			lineHeight: "52px"
		}
	}
};
