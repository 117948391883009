import * as React from "react";
import * as Yup from "yup";
import { Box } from "@mui/material";
import { getReceivedValue } from "../../../utils/getReceivedValue";
import { Form } from "../../Form";
import { BonusSelector } from "../../Form/BonusSelector";
import { FormBottomWrapper } from "../../Form/FormBottomWrapper";
import { Input } from "../../Form/Input";
import { WaningNote } from "../../UI/Note";
import { defaultValidationSchema } from "./validation";
import { useAppSelector } from "../../../utils/hooks/reduxHooks";
import { ButtonWithLoader } from "../../ButtonWithLoader";

export interface DefaultFormProps {
	validationSchema?: Yup.AnyObjectSchema,
	onSubmit: (values: {[name: string]: any}) => void | Promise<void>;
	notes?: string | JSX.Element;
	children?: React.ReactNode;
	defaultValues?: { [name: string]: any };
	isFetch?: boolean;
	agree?: React.ReactNode;
}

export function DefaultForm (props: DefaultFormProps): JSX.Element {
	const {
		validationSchema,
		onSubmit,
		notes,
		children,
		defaultValues,
		isFetch = false,
		agree
	} = props;
	const accountNumber = useAppSelector((state) => `${state.session.userData.account_number ?? ""}`);
	const language = useAppSelector((state) => state.language);
	const currency = useAppSelector((state) => state.session.userData.currency ?? "USD");

	const getReceivedValueCB = React.useCallback((values: any) => getReceivedValue(values, currency), [ currency ]);
	const schema = React.useMemo(function () {
		return validationSchema
			? defaultValidationSchema(language.languageFinance).concat(validationSchema)
			: defaultValidationSchema(language.languageFinance);
	}, [ validationSchema ]);

	return (
		<Box component="div" sx={{ marginTop: "20px" }}>
			<Form
				defaultValues={{
					accountNumber,
					amount: "",
					...defaultValues,
					bonuses: {}
				}}
				onSubmit={onSubmit}
				validationSchema={schema}
			>
				<Input
					name="accountNumber"
					label={language.languageCopytrade.cpy_mon_accnum}
					variant="rounded"
				/>
				<Input
					name="amount"
					label={language.languageFinance.s_03}
					variant="rounded"
				/>

				{children}

				<BonusSelector
					name="bonuses"
					label="Bonus"
				/>

				{agree}

				<FormBottomWrapper
					forFields={[ "bonuses", "amount", "currency" ]}
					onChange={getReceivedValueCB}
					label={language.languageMyAccounts.form_message_01}
				>
					<ButtonWithLoader
						isFetch={isFetch}
						color={"primary"}
						text={language.languageMyAccounts.chapas_04}
					/>
				</FormBottomWrapper>
				{
					notes
						? (
							<Box sx={{ marginTop: "6px" }}>
								<WaningNote
									message={notes}
								/>
							</Box>

						)
						: (<></>)
				}
			</Form>
		</Box>
	);
}
