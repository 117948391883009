import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import FMTableHead from "./FMTableHead";
import FMTableBody from "./FMTableBody";
import { useScrollElement } from "../../utils/hooks/themeBreakpointsHooks";
import FMTableHeader from "./FMTableHeader";
import { IFMTable } from "./IFMTable";
import { useEffect, useState } from "react";
import FMTableRowLoading from "./FMTableRowLoading";
import FMTableFooter from "./FMTableFooter";
import { IOrder } from "./sortFMTable";
import { IFMTableRowObj, IHeadCell } from "./formatDataFMTable";
import FMTableRowNoFound from "./FMTableRowNoFound";
import FMTableRowNoData from "./FMTableRowNoData";
import { IFMTableTheme, useFMTableTheme } from "./FMTableThemes";
import { getLocalStorageObj, removeLocalStorageItem, setLocalStorageObj } from "../../utils/LocalStorage";

type IFMTablePreData = {
	page: number,
	rowsPerPage: number,
} | null

export default function FMTableMain<T, M> (props: IFMTable<T, M>) {
	const { mobileScheme, data, defaultPage, id, rowWrapper, componentData, disableFooter = false } = props;
	const [ order, setOrder ] = React.useState<IOrder>("asc");
	const [ rowsPerPage, setRowsPerPage ] = React.useState(props.rowsPerPageOptions ? props.rowsPerPageOptions[0] : 5);
	const [ orderBy, setOrderBy ] = React.useState("");
	const [ rows, setRows ] = React.useState([] as Array<IFMTableRowObj<T, M>>);
	const [ page, setPage ] = React.useState(0);
	const [ headCells, setHeadCells ] = React.useState([] as Array<IHeadCell<T>>);
	const [ mobileAutoSlice, setMobileAutoSlice ] = useState(0);
	const [ container, setContainer ] = useState(0);
	const scrollObject = useScrollElement();
	const maxSlice = headCells ? Object.keys(headCells).length - 2 : 2;

	function setSlice () {
		if (mobileScheme?.mode === "auto" && scrollObject.scroll > 0) {
			setMobileAutoSlice(prev => prev < maxSlice ? prev + 1 : prev);
		}
	}

	useEffect(() => {
		setSlice();
	}, [ scrollObject.scroll, headCells ]);

	useEffect(() => {
		if (container < scrollObject.containerWidth) {
			setMobileAutoSlice(0);
		}
		setContainer(scrollObject.containerWidth);
	}, [ scrollObject.containerWidth ]);

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: any
	) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleChangeRowsPerPage = (value:string) => {
		setRowsPerPage(parseInt(value, 10));
		setPage(0);
		if (id) {
			const preData = getLocalStorageObj("FMTablePre" + id);
			if (preData) removeLocalStorageItem("FMTablePre" + id);
			const newPreData = {
				page: 0,
				rowsPerPage: parseInt(value, 10)
			};
			setLocalStorageObj("FMTablePre" + id, newPreData);
		}
	};

	const handleChangePage = (newPage: number) => {
		setPage(newPage);
		if (id) {
			const preData = getLocalStorageObj("FMTablePre" + id);
			if (preData) removeLocalStorageItem("FMTablePre" + id);
			const newPreData = {
				page: newPage,
				rowsPerPage
			};
			setLocalStorageObj("FMTablePre" + id, newPreData);
		}
	};

	const setDefaultPage = () => {
		if (defaultPage && defaultPage <= rows.length / rowsPerPage) setPage(defaultPage);
	};

	useEffect(() => {
		if (id) {
			const preData: IFMTablePreData = getLocalStorageObj("FMTablePre" + id);
			if (preData && preData.page) setPage(preData.page); else setDefaultPage();
			if (preData && preData.rowsPerPage) setRowsPerPage(preData.rowsPerPage);
		} else {
			setDefaultPage();
		}
	}, [ rows ]);

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	const theme: IFMTableTheme = useFMTableTheme();

	return (
		<Box sx={{ width: "100%" }} ref={scrollObject.containerRef}>
			<FMTableHeader<T, M>
				{ ...props }
				rows={rows}
				headCells={headCells}
				setOrder={setOrder}
				setRowsPerPage={setRowsPerPage}
				setOrderBy={setOrderBy}
				setRows={setRows}
				setPage={setPage}
				setHeadCells={setHeadCells}
				rowsPerPage={rowsPerPage}
				handleChangeRowsPerPage={handleChangeRowsPerPage}
			/>
			<TableContainer sx={{ overflow: "visible" }}>
				<Table
					sx={{
						...tableSx,
						borderColor: theme.TableBorderColor
					}}
					aria-labelledby="tableTitle"
					size={"small"}
					ref={scrollObject.ref}
				>
					{headCells && <FMTableHead
						headCells={headCells}
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
						rowCount={rows.length}
						mobileScheme={mobileScheme}
						mobileAutoSlice={mobileAutoSlice}
						breakpoint={container}
					/>}
					{data && data?.length
						? rows.length
							? <FMTableBody<T, M>
								rows={rows}
								order={order}
								orderBy={orderBy}
								page={page}
								componentData={componentData}
								rowsPerPage={rowsPerPage}
								mobileScheme={mobileScheme}
								mobileAutoSlice={mobileAutoSlice}
								breakpoint={container}
								rowWrapper={rowWrapper}
							/>
							: <FMTableRowNoFound
								colSpan={Object.keys(headCells).length - mobileAutoSlice}
							/>
						: data
							? <FMTableRowNoData
								colSpan={Object.keys(headCells).length - mobileAutoSlice}
							/>
							: <FMTableRowLoading
								colSpan={Object.keys(headCells).length - mobileAutoSlice}
							/>
					}
				</Table>
			</TableContainer>
			{ emptyRows > 0 && <Box style={{ height: 40 * emptyRows }}></Box> }
			{ rows.length > 0 && !disableFooter && <FMTableFooter
				{ ...props }
				rowsPerPage={rowsPerPage}
				rowCount={rows.length}
				tableRowCount={data?.length}
				onChangePage={handleChangePage}
				currentPage={page}
			/> }
		</Box>
	);
};

const tableSx = {
	minWidth: 300,
	width: "100%",
	border: "1px solid",
	marginBottom: "30px"
};
