import * as React from "react";
import { Box, Button, CircularProgress, SxProps, Theme, Typography } from "@mui/material";
import { Row } from "../UI/Row";

export interface ButtonWithLoaderProps {
	isFetch: boolean;
	text: string;
	contentAlign?: "start" | "center" | "space-between" | "space-around" | "space-evenly";
	color: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
	minHeight?: string;
	icon?: JSX.Element;
	sx?: SxProps<Theme>;
	sxButton?: SxProps<Theme>;
	iconStart?: JSX.Element,
	type?: "submit" | "button";
	disabled?: boolean;
	onClick?: () => void | Promise<void>;
}

export function ButtonWithLoader (props: ButtonWithLoaderProps): JSX.Element {
	const {
		isFetch,
		text,
		color = "primary",
		minHeight = "40px",
		contentAlign = "center",
		icon = <></>,
		iconStart = <></>,
		sx = {},
		sxButton = {},
		type = "submit",
		onClick,
		disabled
	} = props;

	return (
		<Box sx={{ position: "relative", ...sx }}>
			<Button
				variant="contained"
				disabled={isFetch || disabled}
				color={color}
				type={type}
				onClick={onClick}
				disableElevation
				sx={{
					padding: "10px",
					width: "150px",
					textTransform: "none",
					minHeight,
					...sxButton
				}}
			>
				<Row justifyContent={contentAlign} alignItems="center">
					{iconStart}
					<Typography>{text}</Typography>
					{icon}
				</Row>
			</Button>
			{isFetch && (
				<CircularProgress
					size={24}
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						marginTop: "-12px",
						marginLeft: "-12px"
					}}
				/>
			)}
		</Box>
	);
}
