import * as React from "react";
import { Box, Grid, SxProps, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { ISxAdaptive, useAdaptive } from "../../../../utils/hooks/themeBreakpointsHooks";
import { ReactComponent as CloseIcon } from "../../../../images/CloseIconDark.svg";
import { ModalPortal } from "../../../ModalPortal";
import { Center } from "../../Center";
import { CleanButton } from "../../CleanButton";

export interface IDefaultModalProps {
	isOpen: boolean;
	onClose: () => void;
	title?: string;
	children?: JSX.Element | JSX.Element[];
}

const Wrapper = styled(Box)({
	width: "100%",
	maxWidth: "708px",
	background: "#fff",
	borderRadius: "4px",
	border: "1px solid #707070"
});

const wrapperAdaptiveStyles: ISxAdaptive = {
	xs: {
		margin: "0 18px"
	},
	sm: {
		margin: "0"
	}
};

export function DefaultModal (props: IDefaultModalProps) {
	const { isOpen, onClose, title, children } = props;

	const wrapperAdaptive = useAdaptive(wrapperAdaptiveStyles);

	return isOpen
		? (
			<ModalPortal>
				<Center>
					<Wrapper sx={wrapperAdaptive}>
						<Grid
							container
							flexDirection="row"
							justifyContent="space-between"
							alignItems="center"
							sx={{
								paddingBottom: "21px",
								borderBottom: "1px solid #E2E7E8",
								padding: "21px 28px"
							}}
						>
							<Grid item>
								<Typography
									variant="subtitle1"
									color="#5C7080"
									fontWeight="bold"
								>
									{title}
								</Typography>
							</Grid>
							<Grid item>
								<CleanButton onClick={onClose}>
									<CloseIcon />
								</CleanButton>
							</Grid>
						</Grid>

						{children}
					</Wrapper>
				</Center>
			</ModalPortal>
		)
		: (<></>);
}

export interface IModalBlockProps {
	sx?: SxProps;
	children?: JSX.Element | JSX.Element[];
}

export function ModalBlock (props: IModalBlockProps) {
	return (
		<Box
			sx={{
				borderBottom: "1px solid #E2E7E8",
				padding: "21px 28px",
				...props.sx
			}}
		>
			{ props.children }
		</Box>
	);
}
