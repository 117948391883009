/*
    Created on : 24.06.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { StoreInterface } from "../../../interfaces/StoreInterfaces";
import { Box, Button, Typography } from "@mui/material";
import ClassicIcon from "./ClassicIcon";
import ProIcon from "./ProIcon";
import CentsIcon from "./CentsIcon";
import ZeroSpreadIcon from "./ZeroSpreadIcon";
import { useAdaptive } from "../../../utils/hooks/themeBreakpointsHooks";

interface TypesAccountInterface {
	text: string,
	iconColor: string
	icon: any
}

function AccountTypes () {
	const langMyAccounts:any = useSelector((state:StoreInterface) => state.language.languageMyAccounts);
	const init:any = useSelector((state:StoreInterface) => state.init.init);

	const typesAccount:TypesAccountInterface[] = [
		{ text: langMyAccounts.classic, iconColor: "#5C7080", icon: <ClassicIcon/> },
		{ text: langMyAccounts.pro, iconColor: "#2A88C9", icon: <ProIcon/> },
		{ text: langMyAccounts.cents, iconColor: "#28A64A", icon: <CentsIcon/> },
		{ text: langMyAccounts.zero_spread, iconColor: "#ED1F24", icon: <ZeroSpreadIcon/> }
	];

	const TypeIcon = (iconColor:string, icon:any) => {
		return (
			<Box sx={{ ...TypeIconSX, backgroundColor: iconColor }}>
				{icon}
			</Box>
		);
	};

	const renderAccountTypes = () => {
		return typesAccount.map((typeInfo:TypesAccountInterface, i:number) => {
			return (
				<Box key={"itemAccountTypes" + i} sx={typeSX}>
					{ TypeIcon(typeInfo.iconColor, typeInfo.icon) }
					<Typography component="h3"sx={{ fontSize: "18px", color: "#5C7080" }}>
						{langMyAccounts.forexMart} {typeInfo.text}
					</Typography>
					<Button
						variant="contained"
						href={init.links.link_account_type}
						target="_self"
						sx={ButtonSX}
					>
						{langMyAccounts.learn_more}
					</Button>
				</Box>
			);
		});
	};

	const boxSX = useAdaptive({
		xs: {
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			paddingTop: "49px",
			paddingBottom: "10px",
			"&>div": {
				marginX: 0,
				mb: "7px",
				maxWidth: "300px"
			}
		},
		sm: {
			flexDirection: "row",
			flexWrap: "wrap",
			"&>div": {
				width: "calc(50% - 5px)",
				marginX: "5px",
				maxWidth: "100%"
			},
			"&>div:nth-of-type(2)": {
				mr: 0
			},
			"&>div:nth-of-type(3)": {
				ml: 0
			}
		},
		md: {
			flexWrap: "nowrap",
			"&>div": {
				width: "100%",
				marginX: "15px"
			},
			"&>div:nth-of-type(2)": {
				mr: "15px"
			},
			"&>div:nth-of-type(3)": {
				ml: "15px"
			}
		}
	});

	return (
		<Box className="flexRow" sx={boxSX}>
			{renderAccountTypes()}
		</Box>
	);
}
export default withRouter(AccountTypes);

const TypeIconSX = {
	width: "72px",
	height: "72px",
	borderRadius: "50%",
	display: "flex",
	justifyContent: "center",
	alignItems: "center"
};

const typeSX = {
	borderRadius: "4px",
	backgroundColor: "#E2E7E8",
	padding: "20px 14px 19px",
	width: "100%",
	minHeight: "210px",
	height: "100%",
	marginX: "15px",
	borderTop: "3px solid #707070",
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center"
};

const ButtonSX = {
	width: "100%",
	backgroundColor: "#5C7080",
	color: "#fff",
	fontSize: "14px",
	fontWeight: 400,
	textTransform: "none",
	"&:hover": {
		backgroundColor: "#505f6a",
		color: "#fff"
	}
};
