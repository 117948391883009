/* eslint-disable camelcase */
import * as React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Copy } from "../../../../components/UI/Copy";
import { FieldWrapper as FieldWrapperUI } from "../../../../components/UI/FieldWrapper";
import { useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { useIsRtl } from "../../../../utils/hooks/useIsRtl";
import { MYRIcons } from "./images";

export function LocalDepositorMalaysia () {
	const {
		trd_39,
		trd_42,
		trd_259
	} = useAppSelector((state) => state.language.languageTrading);
	const { perdet_08 } = useAppSelector((state) => state.language.languageMyAccounts);
	const isRtl = useIsRtl();
	const FieldWrapper = React.useMemo(() => FieldWrapperUI.default, []);

	return (
		<>
			<Grid
				container
				flexDirection={isRtl ? "row-reverse" : "row"}
				sx={{ marginBottom: "16px" }}
			>
				<Box sx={{ marginRight: "4px" }}><MYRIcons.AM /></Box>
				<Box sx={{ marginRight: "4px" }}><MYRIcons.CIMB /></Box>
				<Box sx={{ marginRight: "4px" }}><MYRIcons.MAY /></Box>
				<Box sx={{ marginRight: "4px" }}><MYRIcons.PUBLIC /></Box>
				<Box sx={{ marginRight: "4px" }}><MYRIcons.RHB /></Box>
			</Grid>
			<Typography
				color="#5C7080"
				textAlign={isRtl ? "right" : "left"}
				className="light"
				sx={{ marginBottom: "15px" }}
			>
				{trd_39}
			</Typography>
			<Typography
				color="#5C7080"
				textAlign={isRtl ? "right" : "left"}
				className="bold"
				sx={{ marginBottom: "15px" }}
			>
				{trd_42}
			</Typography>
			<FieldWrapper label={perdet_08}>
				<Copy
					value="deposit-malaysia@forexmart.com"
				/>
			</FieldWrapper>
			<FieldWrapper label={trd_259}>
				<Copy
					value="+60 3-8656 7623"
				/>
			</FieldWrapper>
			<FieldWrapper label="Telegram">
				<Copy
					value="+60 1-9333 4112"
				/>
			</FieldWrapper>
			<FieldWrapper label="WhatsApp">
				<Copy
					value="+60 1-933 34112"
				/>
			</FieldWrapper>
		</>
	);
}
