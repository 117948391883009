import * as React from "react";
import { Collapse } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import FMTableCollapsibleColumns from "./FMTableCollapsibleColumns";
import { IFMTableMobileScheme } from "./mobileFMTable";
import { IFMTableRowObj } from "./formatDataFMTable";
import FMTableCellComponent from "./FMTableCellComponent";

interface IFMTableCollapsibleRow<T, M> {
	index: number
	open: number []
	row: IFMTableRowObj<T, M>
	page: number
	componentData?: M
	mobileScheme?: IFMTableMobileScheme
	mobileAutoSlice?: number
	breakpoint: number
}

export default function FMTableCollapsibleRow<T, M> (props: IFMTableCollapsibleRow<T, M>) {
	const { index, open, row, mobileScheme, mobileAutoSlice, breakpoint, page, componentData } = props;
	const rowProps = row[Object.keys(row)[0]];

	return (
		<TableRow>
			<TableCell
				style={{
					padding: 0, margin: 0, border: "none"
				}}
				colSpan={Object.keys(row).length}
			>
				<Collapse in={!!open[index]} timeout={"auto"} unmountOnExit>
					{mobileScheme?.mode
						? <FMTableCollapsibleColumns<T, M>
							row={row}
							mobileScheme={mobileScheme}
							mobileAutoSlice={mobileAutoSlice}
							breakpoint={breakpoint}
							page={page}
							componentData={componentData}
						/>
						: null
					}
					<FMTableCellComponent<T, M>
						comp={rowProps.collapsibleComponent}
						data={{ ...rowProps.rowItem, page, componentData }}
					/>
				</Collapse>
			</TableCell>
		</TableRow>
	);
};
