import React, { FC } from "react";
import { Box, SxProps } from "@mui/material";
import { Select, SelectProps } from "../../../components/Form/Select";
import { Option } from "../../../components/Form/Option";
import PartnershipLabel from "./PartnershipLabel";
import { useAdaptive } from "../../../utils/hooks/themeBreakpointsHooks";

interface IProps {
	options: string[]
}

const xsWrapperStyles: SxProps = {
	minWidth: "120px",
	width: "100%"
};

const smWrapperStyles: SxProps = {
	maxWidth: "252px"
};

const PartnershipSelect: FC<SelectProps & IProps> = ({ label, options, ...props }) => {
	const adaptiveWrapperStyles = useAdaptive({
		xs: xsWrapperStyles,
		sm: smWrapperStyles
	});
	return (
		<Box sx={adaptiveWrapperStyles}>
			<PartnershipLabel>{label}</PartnershipLabel>
			<Select {... props} label={""}>
				{options.map((option, index) => (
					<Option key={index} value={option}>{option}</Option>
				))}
			</Select>
		</Box>
	);
};

export default PartnershipSelect;
