import * as React from "react";
import { IFMTableColumnCellAlign } from "./formatDataFMTable";
export type IFMTableCellComponentDopProps<M> = {
	index?: number
	align?: IFMTableColumnCellAlign,
	toggleCollapsible?: any
	open?: number
	searchSort?: string | number | boolean
	label?: string
	page?: number
	componentData?: M
};

export type IFMTableComponentProps<T, M> = T & IFMTableCellComponentDopProps<M>;

export type IFMTableCellComponentType<T, M> = ((props: IFMTableComponentProps<T, M>) => JSX.Element | Element);

interface ICellComponentProps<T, M> {
	comp?: IFMTableCellComponentType<T, M>
	pr?: IFMTableCellComponentDopProps<M>
	data: T
};

function TableCell <T, M> (props: { prop: IFMTableComponentProps<T, M>, component: IFMTableCellComponentType<T, M> }): JSX.Element {
	return <>{props?.component(props.prop)}</>;
}

export default function FMTableCellComponent<T, M> (props:ICellComponentProps<T, M>) {
	const cProps = { ...props.pr, ...props.data };
	if (props.comp) return <TableCell<typeof props.data, M> prop={cProps} component={props.comp} />;
	return <></>;
};
