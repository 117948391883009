/*
    Created on : 24.11.2021, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { Box } from "@mui/material";
export default function SquareSpinner ({
	size = 40,
	color = [ "#2988CA", "#2988CA", "#2988CA", "#2988CA" ]
}: { size?: number, color?: string[] }) {
	const boxSX = SquareSpinnerSX(size, color);
	return (
		<Box sx={{ display: "flex", align: "center", justifyContent: "center", padding: size / 2 + "px" }}>
			<Box sx={boxSX} />
		</Box>
	);
};

const SquareSpinnerSX = (size: number, color: string[]) => {
	return {
		width: size + "px",
		display: "block",
		height: size + "px",
		animation: "3s flip ease-in-out infinite",
		"@keyframes flip": {
			"0%": { transform: "perspective(120px) rotateX(0deg) rotateY(0deg)", backgroundColor: color[0] },
			"12%": { backgroundColor: color[0] },
			"13%": { backgroundColor: color[1] },
			"25%": { transform: "perspective(120px) rotateX(-180deg) rotateY(0deg)", backgroundColor: color[1] },
			"37%": { backgroundColor: color[1] },
			"38%": { backgroundColor: color[2] },
			"50%": { transform: "perspective(120px) rotateX(-180deg) rotateY(-180deg)", backgroundColor: color[2] },
			"62%": { backgroundColor: color[2] },
			"63%": { backgroundColor: color[3] },
			"75%": { transform: "perspective(120px) rotateX(0deg) rotateY(-180deg)", backgroundColor: color[3] },
			"87%": { backgroundColor: color[3] },
			"88%": { backgroundColor: color[0] },
			"100%": { transform: "perspective(120px) rotateX(0deg) rotateY(-360deg)", backgroundColor: color[0] }
		}
	};
};
