import axios from "axios";
const Url = window.location.protocol + "//" + window.location.host + "/app/lang/";
// const Url = "https://my.forexmart.com/app/lang/";

const API_LANG = axios.create({
	baseURL: Url,
	params: {
		v: "8"
	},
	responseType: "json"
});

API_LANG.interceptors.request.use((config) => {
	config.headers = config.headers || {};
	config.params = config.params || {};
	return config;
});

export default API_LANG;
