import * as React from "react";
import { Alert, Grid, Snackbar, SxProps } from "@mui/material";
import { IFMTableComponentProps } from "../../../../components/FMTable/FMTableCellComponent";
import { IRebateSystemProject, RebateSystemProjectStatus } from "../../../../interfaces/RebateSystemInterfaces";
import { ButtonWithLoader } from "../../../../components/ButtonWithLoader";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import API from "../../../../utils/API";
import { TResponse } from "../../../../utils/types";
import { useErrorHandling } from "../../../../utils/hooks/useErrorHandling";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { removeRebateSystemProject, updateRebateSystemProject } from "../../../../store/actions/actionTypes";
import { useStorage } from "../../../../components/Storage";
import { useAdaptive } from "../../../../utils/hooks/themeBreakpointsHooks";
import { mdJustifyContentStyles, xsJustifyContentStyles } from "./commonStyles";

const xsUpdateButtonStyles: SxProps = { maxWidth: "120px" };
const smUpdateButtonStyles: SxProps = { maxWidth: "150px" };

export function UpdateActionCell (props: IFMTableComponentProps<IRebateSystemProject, {}>) {
	const { ProjectName, RebateValue, Status } = props;
	const [ isFetchUpdate, setIsFetchUpdate ] = React.useState(false);
	const [ isFetchRemove, setIsFetchRemove ] = React.useState(false);
	const { isOpen, errorMsg, hideError, showError } = useErrorHandling();
	const { data } = useStorage();
	const rebateSystemProjects = useAppSelector((state) => state.rebateSystem.projects.list);
	const { languageTrading, languagePartnership } = useAppSelector((state) => state.language);
	const dispatch = useAppDispatch();

	async function deactivateProjects () {
		const activeProjects = rebateSystemProjects?.filter((project) => project.Status === RebateSystemProjectStatus.ACTIVATED && project.ProjectName !== ProjectName);
		if (activeProjects?.length) {
			for (const project of activeProjects) {
				const formData = new FormData();
				formData.append("projectName", project.ProjectName);
				formData.append("nextRebateValue", `${project.RebateValue * 100}`);
				formData.append("status", convertStatus(RebateSystemProjectStatus.DEACTIVATED));

				const response = await API.post<TResponse<null>>("rebateSystemUpdateProject", formData);

				if (!response.data.success) {
					throw new Error(languageTrading.trd_242);
				}

				dispatch(updateRebateSystemProject({
					name: project.ProjectName,
					rebateValue: project.RebateValue,
					status: RebateSystemProjectStatus.DEACTIVATED
				}));
			}
		}
	}

	async function updateProject () {
		setIsFetchUpdate(true);
		try {
			if (data.rebateValue === "") {
				throw new Error(languagePartnership.empty_rebate_value);
			}

			const rebateValueIsNotChanged = data.rebateValue === (RebateValue * 100).toFixed(0) || !data?.rebateValue;
			const statusIsNotChanged = !data?.status || data.status === Status;

			if (rebateValueIsNotChanged && statusIsNotChanged) {
				throw new Error(languagePartnership.same_rebate_value);
			}

			if (data.status === RebateSystemProjectStatus.ACTIVATED) {
				await deactivateProjects();
			}

			const formData = new FormData();
			formData.append("projectName", ProjectName);
			formData.append("nextRebateValue", `${data.rebateValue || RebateValue * 100}`);
			formData.append("status", convertStatus(data?.status || Status));

			const response = await API.post<TResponse<null>>("rebateSystemUpdateProject", formData);

			if (!response.data.success) {
				throw new Error(response.data.error);
			}

			dispatch(updateRebateSystemProject({
				name: ProjectName,
				rebateValue: data.rebateValue / 100 || RebateValue,
				status: data?.status || Status
			}));
		} catch (e) {
			if (e instanceof Error) {
				showError(e.message);
			}
		} finally {
			setIsFetchUpdate(false);
		}
	}

	async function removeProject () {
		setIsFetchRemove(true);
		try {
			const formData = new FormData();
			formData.append("projectName", ProjectName);
			formData.append("prevRebateValue", `${RebateValue * 100}`);

			const response = await API.post<TResponse<null>>("rebateSystemRemoveProject", formData);

			if (!response.data.success) {
				throw new Error(response.data.error);
			}

			dispatch(removeRebateSystemProject({ name: ProjectName }));
		} catch (e) {
			if (e instanceof Error) {
				showError(e.message);
			}
		}	finally {
			setIsFetchRemove(false);
		}
	};

	const adaptiveStyles = useAdaptive({
		xs: xsJustifyContentStyles,
		md: mdJustifyContentStyles
	});

	const adaptiveUpdateButtonStyles = useAdaptive({
		xs: xsUpdateButtonStyles,
		sm: smUpdateButtonStyles
	});

	return (
		<>
			<Grid
				container
				gap={1}
				sx={adaptiveStyles}
				wrap="nowrap"
				flexDirection="row"
			>
				<Grid item>
					<ButtonWithLoader
						isFetch={isFetchUpdate}
						onClick={updateProject}
						text={languageTrading.update}
						color="info"
						sxButton={adaptiveUpdateButtonStyles}
					/>
				</Grid>
				<Grid item>
					<ButtonWithLoader
						isFetch={isFetchRemove}
						onClick={removeProject}
						text=""
						iconStart={<DeleteForeverIcon />}
						color="error"
						sx={{ maxWidth: "64px" }}
						sxButton={{ padding: 0, maxWidth: "40px" }}
					/>
				</Grid>
			</Grid>
			<Snackbar open={isOpen} autoHideDuration={4000} onClose={hideError}>
				<Alert variant="filled" color="error">
					{errorMsg}
				</Alert>
			</Snackbar>
		</>
	);
}

function convertStatus (status: RebateSystemProjectStatus) {
	switch (status) {
		case RebateSystemProjectStatus.ACTIVATED:
			return "1";
		case RebateSystemProjectStatus.DEACTIVATED:
			return "2";
		default:
			return "2";
	}
}
