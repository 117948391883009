import * as React from "react";
import styled from "styled-components";
import { Box, Grid, Link, Skeleton, Typography } from "@mui/material";
import { useAppSelector } from "../../../../utils/hooks/reduxHooks";

export interface ICryptoCurrencyStatus {
	isLoad?: boolean;
	logo?: string;
	inputAddress?: string;
	orderId?: string;
	createDate?: string;
	status?: number;
	onRefresh?: () => void;
}

export function CryptoCurrencyStatus (props: ICryptoCurrencyStatus) {
	const {
		isLoad = false,
		logo,
		inputAddress,
		orderId,
		createDate,
		status,
		onRefresh = () => {}
	} = props;

	const language = useAppSelector((state) => state.language);

	return (
		<Box>
			<Grid
				container
				flexDirection="column"
				sx={{
					paddingTop: "32px",
					paddingBottom: "32px"
				}}
			>
				<Grid
					item
					sx={{
						display: "flex",
						justifyContent: "center",
						width: "100%",
						marginBottom: "32px"
					}}
				>
					<Image
						src={logo}
						maxWidth={200}
					/>
				</Grid>
				{
					inputAddress
						? (
							<Typography textAlign="left">{language.languageFinance.payment_address_01}: {inputAddress}</Typography>
						)
						: (
							<Typography textAlign="left">
								{language.languageFinance.payment_address_01}:
								<Skeleton variant="rectangular" width={300} height={14} sx={{ display: "inline-block", verticalAlign: "middle", marginLeft: "10px" }}/>
							</Typography>
						)
				}
				<Grid item sx={{ paddingTop: "32px", paddingBottom: "32px", minHeight: "364px" }}>
					{
						inputAddress
							? (
								<Image
									src={`https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${inputAddress}&format=png`}
									alt="QR-code"
									maxWidth={300}
								/>
							)
							: (
								<Skeleton variant="rectangular" width={300} height={300} sx={{ margin: "0 auto" }} />
							)
					}
				</Grid>
				<Grid
					item
					sx={{
						display: "flex",
						borderTop: "1px solid rgba(0, 0, 0, 0.2)",
						padding: "10px"
					}}
				>
					<Typography sx={{ minWidth: "200px" }} textAlign="left">
						{language.languageMyAccounts.perdet_03}
					</Typography>
					{
						inputAddress
							? (
								<Typography>{inputAddress}</Typography>
							)
							: (
								<Skeleton variant="rectangular" width={300} height={14} />
							)
					}
				</Grid>
				<Grid
					item
					sx={{
						display: "flex",
						borderTop: "1px solid rgba(0, 0, 0, 0.2)",
						padding: "10px"
					}}
				>
					<Typography sx={{ minWidth: "200px" }} textAlign="left">
						{language.languageFinance.order_id_01}
					</Typography>
					{
						orderId
							? (
								<Typography>{orderId}</Typography>
							)
							: (
								<Skeleton variant="rectangular" width={300} height={14} />
							)
					}
				</Grid>
				<Grid
					item
					sx={{
						display: "flex",
						borderTop: "1px solid rgba(0, 0, 0, 0.2)",
						padding: "10px"
					}}
				>
					<Typography sx={{ minWidth: "200px" }} textAlign="left">
						{language.languageFinance.payment_date_01}
					</Typography>
					{
						createDate
							? (
								<Typography>{createDate}</Typography>
							)
							: (
								<Skeleton variant="rectangular" width={300} height={14} />
							)
					}
				</Grid>
				<Grid
					item
					sx={{
						display: "flex",
						borderTop: "1px solid rgba(0, 0, 0, 0.2)",
						padding: "10px"
					}}
				>
					<Typography sx={{ minWidth: "200px" }} textAlign="left">
						{language.languageFinance.payment_status_01}
					</Typography>
					<Typography>
						{
							(status && status >= 0) || !isLoad
								? (
									status === 1
										? "Done!"
										: (
											<>
												{language.languageFinance.wait_01}
												<Link href="#" onClick={onRefresh}> (Refresh)</Link>
											</>
										)
								)
								: (
									<Skeleton variant="rectangular" width={300} height={14} />
								)
						}
					</Typography>
				</Grid>
			</Grid>
		</Box>
	);
}

const Image = styled.img<{ maxWidth: number, src: any, alt?: string }>`
  max-width: ${({ maxWidth }) => maxWidth}px;
`;
