import * as React from "react";
import {
	TWithdrawMobileMoneyFormValues,
	WithdrawMobileMoneyForm
} from "../../../../../components/Forms/WithdrawMobileMoneyForm";
import { MobileMoneyNames } from "../../../../../store/reducers/withdrawMethodsInfoReducer";
import API from "../../../../../utils/API";
import { AxiosResponse } from "axios";
import { MobileMoneyFormError } from "../../../../../utils/errors/MobileMoneyFormError";

export function Mpesa () {
	async function submit (values: TWithdrawMobileMoneyFormValues) {
		const formData = new FormData();

		formData.append("amount_withdraw", values.amount);
		formData.append("bank_account_number", values.bankAccountNumber);
		formData.append("bank_account_name", values.bankAccountName);
		formData.append("bank_name", values.bankName);
		formData.append("bank_branch", values.branch);
		formData.append("bank_province", values.province);
		formData.append("bank_city", values.city);
		formData.append("mobile_opt", MobileMoneyNames.MPESA);
		formData.append("bank_code", values.bankCode);
		formData.append("bank_currency", values.bankCurrency);

		const response = await API.post<unknown, AxiosResponse<{
			success: boolean,
			error: string,
			formError: { [key: string]: string }
		}>>(`/withdrawMobileMoney/${MobileMoneyNames.MPESA}`, formData);

		if (!response.data.success) {
			const firstKey = Object.keys(response.data.formError)[0];
			throw new MobileMoneyFormError(response.data.formError[firstKey] ?? response.data.error);
		}
	}

	return (
		<WithdrawMobileMoneyForm submit={submit} bankType="MPESA" />
	);
}
