import * as Yup from "yup";
import { AGREE_CHECKBOX } from "../../../../components/Form/BonusSelector";

type DebitErrors = {
	bonus_err_01: string;
	account_number_err_01: string;
	currency_err_01: string;
	banks_err_01: string;
	account_number_err_02: string;
	account_number_err_03: string;
	card_number_err_01: string;
	card_number_err_02: string;
	full_name_err_01: string;
	email_err_01: string;
	number_err_01: string;
	card_image_err_01: string;
	deposit_err_04: string;
	deposit_err_03: string;
}

export const debitValidationSchema = (language: DebitErrors) => Yup.object().shape({
	accountNumber: Yup.string().required(language.account_number_err_01),
	amount: Yup
		.number()
		.typeError(language.deposit_err_04),
	bonuses: Yup.object().test(
		"is-empty",
		language.bonus_err_01,
		(bonuses) => !!Object.keys(bonuses).length && bonuses.hasOwnProperty(AGREE_CHECKBOX.code)
	),
	banks: Yup
		.string()
		.required(language.banks_err_01),
	bankAccountNumber: Yup
		.number()
		.required(language.account_number_err_02)
		.typeError(language.account_number_err_03),
	idCardNumber: Yup
		.number()
		.required(language.card_number_err_01)
		.typeError(language.card_number_err_02),
	fullName: Yup
		.string()
		.required(language.full_name_err_01),
	email: Yup
		.string()
		.required(language.email_err_01),
	number: Yup
		.string()
		.required(language.number_err_01),
	cardImage: Yup
		.mixed()
		.required(language.card_image_err_01)
});
