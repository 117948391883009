import React from "react";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../interfaces/StoreInterfaces";
import { Link } from "react-router-dom";
import AppBarVerifiedButton from "./AppBarVerifiedButton";

export default function AppBarVerifiedLinkButton ({ setAccordion }:any) {
	const init:any = useSelector((state:StoreInterface) => state.init.init);
	return (
		<>
			{init.accStatus && init.accStatus.accountStatusData && init.accStatus.accountStatusData.accountstatus
				? <>
					{parseInt(init.accStatus.accountStatusData.accountstatus) !== 1
						? <Link
							onClick={() => setAccordion(false)}
							to="/app/profile/upload-document"
							style={{ overflow: "hidden" }}
						>
							<AppBarVerifiedButton/>
						</Link>
						: <AppBarVerifiedButton/>
					}
				</>
				: null
			}
		</>
	);
};
