import React from "react";

export default function ProIcon () {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="28.25" height="25.424" viewBox="0 0 28.25 25.424">
			<g transform="translate(-25.583 -25.974)">
				<path d="M49.025,31.016a2.8,2.8,0,1,0,1.909-4.889h-.054a2.8,2.8,0,0,0-2.5,4.046l-2.644,2.216a2.8,2.8,0,0,0-4.359.154l-3.028-1.951c0-.051,0-.1,0-.155a2.8,2.8,0,0,0-5.6-.005,2.811,2.811,0,0,0,.312,1.286l-3.145,3.074a2.805,2.805,0,1,0,.854.65l2.953-2.886a2.795,2.795,0,0,0,4.336-.883l2.845,1.834a2.8,2.8,0,1,0,5.359-.18Zm1.858-3.828a1.737,1.737,0,1,1-1.737,1.737A1.739,1.739,0,0,1,50.883,27.188ZM28.688,39.04A1.737,1.737,0,1,1,30.425,37.3,1.739,1.739,0,0,1,28.688,39.04Zm6.867-6.866a1.737,1.737,0,1,1,1.737-1.737A1.739,1.739,0,0,1,35.555,32.174Zm8.062,3.78a1.737,1.737,0,1,1,1.738-1.738A1.739,1.739,0,0,1,43.617,35.954Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.305"/>
				<path d="M38.273,37.331H34.367a.531.531,0,0,0-.531.531V50.715a.531.531,0,0,0,.531.531h3.906a.531.531,0,0,0,.531-.531V37.862A.531.531,0,0,0,38.273,37.331Zm-.531,12.853H34.9V38.392h2.845Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.305"/>
				<path d="M31.028,43.191H27.121a.531.531,0,0,0-.531.531v6.993a.531.531,0,0,0,.531.531h3.906a.531.531,0,0,0,.531-.531V43.722A.531.531,0,0,0,31.028,43.191Zm-.594,6.993H27.652V44.252h2.782Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.305"/>
				<path d="M45.519,40.986H41.612a.531.531,0,0,0-.531.531v9.2a.531.531,0,0,0,.531.531h3.907a.531.531,0,0,0,.53-.531v-9.2A.531.531,0,0,0,45.519,40.986Zm-.531,9.2H42.143V42.047h2.845Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.305"/>
				<path d="M52.827,37.331H48.921a.531.531,0,0,0-.531.531V50.715a.531.531,0,0,0,.531.531h3.906a.531.531,0,0,0,.531-.531V37.862A.531.531,0,0,0,52.827,37.331Zm-.593,12.853H49.452V38.392h2.782Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.305"/>
			</g>
		</svg>

	);
};
