/*
Created on : 09.05.2022, 06:18:59
Author     : Andy Kar
*/
import * as React from "react";
import { Box, List, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../interfaces/StoreInterfaces";
import { useState } from "react";
import AccountUserIcon from "../../Icons/myAccountsMenuIcons/AccountUserIcon";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AppBarMyAccountMenuInner from "./AppBarMyAccountMenuInner";
import AppBarVerifiedLinkButton from "./AppBarVerifiedLinkButton";

export default function AppBarMyAccount ({ setAccordion } :any) {
	const [ open, setOpen ] = useState(false);
	const userName:string = useSelector((state:StoreInterface) => state.session.userData.full_name) || "User Name";
	return (
		<Box
			sx={{ ...boxSX }}
			onMouseOver={ () => open ? null : setOpen(true)}
			onMouseEnter={ () => setOpen(true) }
			onMouseLeave={ () => setOpen(false) }
		>
			<Box sx={{ ...buttonSX }} className={"flexRow"}>
				<Box className={"flexRow"} sx={{ paddingX: "13px" }}>
					<AccountUserIcon/>
					<Typography component={"span"} sx={{ ...userNameSX }}>{userName}</Typography>
				</Box>
				<AppBarVerifiedLinkButton setAccordion={setAccordion}/>
				<Box sx={{ paddingX: "10px" }}><KeyboardArrowDownIcon /></Box>
			</Box>
			{open &&
				<List sx={{ ...listSX }}>
					<AppBarMyAccountMenuInner
						setOpen={setOpen}
						setAccordion={setAccordion}
					/>
				</List>
			}
		</Box>
	);
};

const buttonSX = {
	minWidth: "313px",
	maxWidth: "100%",
	width: "max-content",
	color: "#fff",
	height: "100%",
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	paddingX: "5px"
};

const boxSX = {
	position: "relative",
	"& .MuiList-root": {
		padding: 0
	}
};

const listSX = {
	position: "absolute",
	top: "100%",
	width: "100%",
	minWidth: "max-content",
	background: "#fff",
	color: "#5C7080",
	zIndex: "9999"
};

const userNameSX = {
	fontSize: "16px",
	lineHeight: "24px",
	fontWeight: "bold",
	color: "white"
};
