import React, { FC } from "react";
import { Box, Typography } from "@mui/material";
// import { Row } from "../../../components/UI/Row";

interface IProps {
	color: string;
	title: string;
	icon: any;
	number: number;
}

const CommissionCard: FC<IProps> = ({ color, number, title, icon }) => {
	return (
		<Box bgcolor={color} className={"commission-card"}>
			<Typography color={"#fff"} textAlign={"center"}>{title}</Typography>
			<Box className={"flexRow"} sx={{ gap: "14px" }} justifyContent={"center"} alignItems={"center"}>
				{icon}
				<Typography color={"#fff"} fontWeight={"bold"} fontSize={"50px"} lineHeight={"68px"}>
					{number}
				</Typography>
			</Box>
		</Box>
	);
};

export default CommissionCard;
