import * as React from "react";
import { Box } from "@mui/material";
import { useIsMobile } from "../../../../utils/hooks/useIsMobile";

export interface ILineProps {
	color?: string;
	canHide?: boolean;
}

export function Line (props: ILineProps) {
	const { color = "#707070", canHide = false } = props;
	const isMobile = useIsMobile();

	return (
		<Box
			component="span"
			sx={{
				position: "absolute",
				width: "100%",
				right: 0,
				left: 0,
				bottom: 0,
				height: "2px",
				backgroundColor: color,
				visibility: isMobile && canHide ? "hidden" : "visible"
			}}
		>
		</Box>
	);
}
