import * as React from "react";
import { Box, SxProps } from "@mui/material";

export interface IDataTableViewProps {
	children?: JSX.Element | JSX.Element[];
	sx?: SxProps;
}

export function DataTableView (props: IDataTableViewProps) {
	const { children, sx } = props;

	return (
		<Box sx={{
			border: "1px solid #e2e7e8",
			...sx
		}}>
			{children}
		</Box>
	);
}

export function DataTableRow (props: IDataTableViewProps & { hideBorderBottom?: boolean }) {
	const { children, sx, hideBorderBottom = false } = props;

	return (
		<Box sx={{
			borderBottom: hideBorderBottom ? "0" : "1px solid #e2e7e8",
			display: "flex",
			flexDirection: "row",
			...sx
		}}>
			{children}
		</Box>
	);
}

export function DataTableColumn (props: IDataTableViewProps & { hideBorderRight?: boolean }) {
	const { children, sx, hideBorderRight = false } = props;

	return (
		<Box sx={{
			borderRight: hideBorderRight ? "0" : "1px solid #e2e7e8",
			width: "100%",
			padding: "15px 22px 10px",
			...sx
		}}>
			{children}
		</Box>
	);
}
