/*
    Created on : 21.02.2022, 06:18:59
    Author     : Andy Kar
*/

import React from "react";
import "./AccountsTradeStatusCell.scss";
// import classNames from "classnames";
// import { CellText } from "../../../pages/MyAccounts/CellText/CellText";
// import { styled } from "@mui/material/styles";
import { Row } from "../../UI/Row";
import { Box } from "@mui/material";

// const Dot = styled("div")<{inactive: any}>(({ inactive }) => ({
// 	background: inactive ? "#EC2327" : "#29A643",
// 	width: "9px",
// 	height: "9px",
// 	borderRadius: "9px"
// }));

function AccountsTradeStatusCell (props: any) {
	return (
		<div className="accounts-verification-cell">
			{/* <span */}
			{/*	className={classNames({ inactive: (props?.inactive === 1) })} */}
			{/* > */}
			{/*	{props?.trade_status} */}
			{/* </span> */}
			{/* <CellText> */}
			<Row justifyContent={"center"} alignItems={"center"} sx={{ gap: "4px" }}>
				<Box sx={{
					background: props?.inactive === 1 ? "#EC2327" : "#29A643",
					width: "9px",
					height: "9px",
					borderRadius: "9px"
				}} />
				{props?.trade_status}
			</Row>
			{/* </CellText> */}
		</div>
	);
}

export default AccountsTradeStatusCell;
