import React from "react";

const BonusIcon = (props:any) => {
	let width = 16;
	let fill = "#417cbf";
	if (props.width) {
		width = props.width;
	}
	if (props.fill) {
		fill = props.fill;
	}

	return (
		<svg width={width} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 16">
			<defs>
				<clipPath id="clipPath">
					<rect id="Прямоугольник_80" data-name="Прямоугольник 80" width="16" height="16" transform="translate(20 22)" fill="#fff"/>
				</clipPath>
			</defs>
			<g id="Группа_масок_28" data-name="Группа масок 28" transform="translate(-20 -22)" clipPath="url(#clipPath)">
				<g id="icon-fm-bonuses" transform="translate(14.598 16.926)">
					<path id="Контур_169" data-name="Контур 169" d="M21.064,5.412a1.093,1.093,0,0,0-1.535,0l-13.8,13.8a1.093,1.093,0,0,0,0,1.535,1.07,1.07,0,0,0,.757.317,1.053,1.053,0,0,0,.757-.317L21.054,6.936A1.054,1.054,0,0,0,21.064,5.412Z" fill={fill}/>
					<path id="Контур_170" data-name="Контур 170" d="M8.931,12.113A3.519,3.519,0,1,0,5.412,8.6,3.532,3.532,0,0,0,8.931,12.113Zm0-4.859a1.34,1.34,0,1,1-1.34,1.34A1.345,1.345,0,0,1,8.931,7.253Z" fill={fill}/>
					<path id="Контур_171" data-name="Контур 171" d="M17.872,14.036a3.519,3.519,0,1,0,3.519,3.519A3.526,3.526,0,0,0,17.872,14.036Zm0,4.859a1.34,1.34,0,1,1,1.34-1.34A1.345,1.345,0,0,1,17.872,18.9Z" fill={fill}/>
				</g>
			</g>
		</svg>
	);
};

export default BonusIcon;
