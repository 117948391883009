import { IRebateSystemPersonalProject, IRebateSystemProject, IStatisticsItem, RebateSystemProjectStatus, RebateSystemProjectType } from "../../interfaces/RebateSystemInterfaces";
import { ActionType } from "../../utils/reudxHelpers";
import {
	REBATE_SYSTEM_ADD_PERSONAL_PROJECT,
	REBATE_SYSTEM_ADD_PROJECT,
	REBATE_SYSTEM_REMOVE_PERSONAL_PROJECT,
	REBATE_SYSTEM_REMOVE_PROJECT,
	REBATE_SYSTEM_UPDATE_PERSONAL_PROJECT,
	REBATE_SYSTEM_UPDATE_PROJECT,
	SET_REBATE_SYSTEM_PERSONAL_PROJECTS,
	SET_REBATE_SYSTEM_PERSONAL_PROJECTS_IS_LOAD,
	SET_REBATE_SYSTEM_PERSONAL_PROJECTS_PAGE,
	SET_REBATE_SYSTEM_PROJECTS,
	SET_REBATE_SYSTEM_PROJECTS_IS_LOAD,
	SET_REBATE_SYSTEM_PROJECTS_PAGE,
	SET_REBATE_SYSTEM_REFERRALS_LIST,
	SET_REBATE_SYSTEM_STATISTICS,
	SET_REBATE_SYSTEM_STATISTICS_FETCH
} from "../actions/actionTypes";

interface IRebateSystemState {
	projects: {
		list?: IRebateSystemProject[],
		count: number,
		isLoad: boolean,
		page: number,
	},
	personalProjects: {
		list?: IRebateSystemPersonalProject[],
		count: number,
		isLoad: boolean,
		page: number,
	},
	referrals: number[],
	statistics: {
		isFetch: boolean,
		data?: IStatisticsItem[],
	}
}

const initialState: IRebateSystemState = {
	projects: {
		count: 0,
		isLoad: true,
		page: 1
	},
	personalProjects: {
		count: 0,
		isLoad: true,
		page: 1
	},
	referrals: [],
	statistics: {
		isFetch: false
	}
};

type Actions = ActionType<"SET_REBATE_SYSTEM_PROJECTS", { list?: IRebateSystemProject[], count: number }>
| ActionType<"SET_REBATE_SYSTEM_REFERRALS_LIST", number[]>
| ActionType<"SET_REBATE_SYSTEM_PERSONAL_PROJECTS", { list?: IRebateSystemPersonalProject[], count: number }>
| ActionType<"REBATE_SYSTEM_UPDATE_PROJECT", { name: string, rebateValue: number, status: RebateSystemProjectStatus }>
| ActionType<"REBATE_SYSTEM_REMOVE_PROJECT", { name: string }>
| ActionType<"REBATE_SYSTEM_UPDATE_PERSONAL_PROJECT", { client: number, rebateValue: number }>
| ActionType<"REBATE_SYSTEM_REMOVE_PERSONAL_PROJECT", { client: number }>
| ActionType<"SET_REBATE_SYSTEM_STATISTICS", IStatisticsItem[]>
| ActionType<"SET_REBATE_SYSTEM_STATISTICS_FETCH", boolean>
| ActionType<"SET_REBATE_SYSTEM_PROJECTS_IS_LOAD", { isLoad: boolean }>
| ActionType<"REBATE_SYSTEM_ADD_PROJECT", { name: string, rebateValue: string }>
| ActionType<"SET_REBATE_SYSTEM_PERSONAL_PROJECTS_IS_LOAD", { isLoad: boolean }>
| ActionType<"REBATE_SYSTEM_ADD_PERSONAL_PROJECT", { client: string, rebateValue: number }>
| ActionType<"SET_REBATE_SYSTEM_PROJECTS_PAGE", number>
| ActionType<"SET_REBATE_SYSTEM_PERSONAL_PROJECTS_PAGE", number>;

export function rebateSystemReducer (state = initialState, action: Actions): IRebateSystemState {
	switch (action.type) {
		case SET_REBATE_SYSTEM_PROJECTS_PAGE:
			return {
				...state,
				projects: {
					...state.projects,
					page: action.payload
				}
			};
		case SET_REBATE_SYSTEM_PERSONAL_PROJECTS_PAGE:
			return {
				...state,
				personalProjects: {
					...state.personalProjects,
					page: action.payload
				}
			};
		case REBATE_SYSTEM_ADD_PERSONAL_PROJECT:
			return {
				...state,
				personalProjects: {
					...state.personalProjects,
					list: [
						{
							client: +action.payload.client,
							rebateValue: action.payload.rebateValue
						},
						...(state.personalProjects.list || [])
					],
					count: state.personalProjects.count + 1
				}
			};
		case SET_REBATE_SYSTEM_PERSONAL_PROJECTS_IS_LOAD:
			return {
				...state,
				personalProjects: {
					...state.personalProjects,
					isLoad: action.payload.isLoad
				}
			};
		case REBATE_SYSTEM_ADD_PROJECT:
			return {
				...state,
				projects: {
					...state.projects,
					list: [
						{
							ProjectName: action.payload.name,
							RebateValue: +action.payload.rebateValue / 100,
							Status: RebateSystemProjectStatus.DEACTIVATED,
							Type: RebateSystemProjectType.DEFAULT,
							Client: 0
						},
						...(state.projects.list || [])
					],
					count: state.projects.count + 1
				}
			};
		case SET_REBATE_SYSTEM_PROJECTS_IS_LOAD:
			return {
				...state,
				projects: {
					...state.projects,
					isLoad: action.payload.isLoad
				}
			};
		case SET_REBATE_SYSTEM_PROJECTS:
			return {
				...state,
				projects: {
					...state.projects,
					list: (state.projects.list || []).concat(action.payload.list || []),
					count: state.projects.count + (action.payload.list?.length || 0)
				}
			};
		case SET_REBATE_SYSTEM_REFERRALS_LIST:
			return {
				...state,
				referrals: action.payload
			};
		case SET_REBATE_SYSTEM_PERSONAL_PROJECTS:
			return {
				...state,
				personalProjects: {
					...state.personalProjects,
					list: (state.personalProjects.list || []).concat(action.payload.list || []),
					count: state.personalProjects.count + (action.payload.list?.length || 0)
				}
			};
		case REBATE_SYSTEM_UPDATE_PROJECT:
			return {
				...state,
				projects: {
					...state.projects,
					list: state.projects.list?.map((project) => {
						if (action.payload.name === project.ProjectName) {
							return {
								...project,
								RebateValue: action.payload.rebateValue,
								Status: action.payload.status
							};
						}
						return project;
					}) || []
				}
			};
		case REBATE_SYSTEM_REMOVE_PROJECT:
			return {
				...state,
				projects: {
					...state.projects,
					list: state.projects.list?.filter((project) => project.ProjectName !== action.payload.name) || [],
					count: state.projects.count - 1
				}
			};
		case REBATE_SYSTEM_UPDATE_PERSONAL_PROJECT:
			return {
				...state,
				personalProjects: {
					...state.personalProjects,
					list: state.personalProjects.list?.map((project) => {
						if (action.payload.client === project.client) {
							return {
								...project,
								rebateValue: action.payload.rebateValue
							};
						}
						return project;
					}) || []
				}
			};

		case REBATE_SYSTEM_REMOVE_PERSONAL_PROJECT:
			return {
				...state,
				personalProjects: {
					...state.personalProjects,
					list: state.personalProjects.list?.filter((project) => project.client !== action.payload.client) || [],
					count: state.personalProjects.count - 1
				}
			};
		case SET_REBATE_SYSTEM_STATISTICS:
			return {
				...state,
				statistics: {
					...state.statistics,
					data: action.payload.map((item) => {
						return {
							...item,
							x: new Date(+`${item.x}000`).toLocaleDateString()
						};
					})
				}
			};
		case SET_REBATE_SYSTEM_STATISTICS_FETCH:
			return {
				...state,
				statistics: {
					...state.statistics,
					isFetch: action.payload
				}
			};
		default:
			return state;
	}
}
