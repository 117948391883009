import * as React from "react";
import { Box, Button, Grid } from "@mui/material";
import { ReactComponent as AddMoreIcon } from "../../../images/AddIcon.svg";
import { UploadForm } from "./components/UploadForm";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../interfaces/StoreInterfaces";
import { useEffect, useState } from "react";

type IDoc = {
	doc_type: string
	file_name?: string
	decline_explained?: string
}

export function Step3 () {
	const [ documents, setDocuments ] = useState([ { doc_type: "2" } ] as Array<IDoc>);
	const userDocs = useSelector((state:StoreInterface) => state.user.userDocs);
	const langMyAccounts:any = useSelector((state:StoreInterface) => state.language.languageMyAccounts);

	const docs = userDocs.level_two_additional;
	console.log("docs", docs);
	console.log("documents", documents);

	useEffect(() => {
		if (docs) setDocuments(docs);
	}, [ docs ]);

	const addMore = React.useCallback(() => {
		setDocuments(prevState => [
			...prevState,
			{ doc_type: (prevState.length + 2).toString() }

		]);
	}, []);

	return (
		<Grid
			container
			flexDirection="column"
			sx={{
				maxWidth: "497px",
				margin: "0 auto"
			}}
		>
			{
				documents.map((doc: any, index: number) => {
					return (
						<Grid item
							key={`upload-form-${index}`}
							sx={{ marginBottom: "28px" }}
						>
							<UploadForm
								tips={`Tip ${index + 1}`}
								label={langMyAccounts.extra_17}
								type={doc.doc_type.toString()}
								name="doc_type"
								level="2"
								fileHashName={doc.file_name}
								decline={doc.decline_explained}
							/>
						</Grid>
					);
				})
			}
			<Grid item sx={{ width: "100%" }}>
				<Button
					color="primary"
					variant="contained"
					fullWidth
					onClick={addMore}
					sx={{
						textTransform: "none",
						height: "45px",
						letterSpacing: 0
					}}
				>
					<Grid container flexDirection="row" alignItems="center" justifyContent="center">
						<Grid item>
							<Box sx={{ marginRight: "20px" }}>
								<AddMoreIcon />
							</Box>
						</Grid>
						<Grid item>{langMyAccounts.accver_add_more}</Grid>
					</Grid>
				</Button>
			</Grid>
		</Grid>
	);
}
