/*
Created on : 07.08.2022, 06:18:59
Author     : Andy Kar
*/
import React from "react";
import { useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { SessionUserDataInterface, StoreInterface } from "../../../interfaces/StoreInterfaces";
import languageLink from "../../../utils/languageLink";
import { Box, Button } from "@mui/material";
import ForexmartLogoInt from "../../Icons/ForexmartLogoInt";

function AppBarAuthButtonsMobile (props:any) {
	const lang:any = useSelector((state:StoreInterface) => state.language.language);
	const init:any = useSelector((state:StoreInterface) => state.init.init);
	const session:SessionUserDataInterface = useSelector((state:StoreInterface) => state.session.userData);
	const path = props.location.pathname;

	const webTradeLink = ():string => {
		switch (init.html_url) {
			case "en":
			case "":
				return "https://webterminal.forexmart.com/";
			case "ru":
				return "https://webterminal.forexmart.com/";
			default:
				return "https://webterminal.forexmart.com/";
		}
	};

	return (
		<Box
			onClick={() => props.setOpen(false)}
			sx={{
				display: "flex",
				alignItems: "center",
				flexDirection: "column",
				marginBottom: 0,
				padding: "15px",
				"& .ForexmartLogoInt": {
					maxWidth: "100%",
					marginBottom: "30px"
				},
				"& a": {
					textDecoration: "none",
					width: "100%"
				}
			}}
		>
			<ForexmartLogoInt/>
			<Button
				href={webTradeLink()}
				target="_blank"
				rel="noreferrer"
				variant="outlined"
				color="primary"
				sx={buttonSX}
				onClick={props.toggle ? props.toggle : null}
			>
				{lang.xnv_webtrader}
			</Button>
			{!path.includes("client/signin") &&
				<Link to="/app/client/signin">
					<Button

						variant="outlined"
						color="success"
						sx={buttonSX}
						onClick={props.toggle ? props.toggle : null}
					>
						{lang.xnv_CL}
					</Button>
				</Link>
			}
			{!path.includes("partner/signin") &&
				<Link to="/app/partner/signin">
					<Button
						variant="outlined"
						color="success"
						sx={buttonSX}
						onClick={props.toggle ? props.toggle : null}
					>
						{lang.xnv_PL}
					</Button>
				</Link>
			}
			<Button
				href={init.links.link_register && session.site_lang ? languageLink(init.links.link_register, session.site_lang) : ""}
				target="_blank"
				rel="noreferrer"
				variant="contained"
				color="primary"
				sx={{
					...buttonSX,
					"&:hover": {
						color: "#fff"
					}
				}}
				onClick={props.toggle ? props.toggle : null}
			>
				{lang.xnv_R}
			</Button>
		</Box>
	);
}
export default withRouter(AppBarAuthButtonsMobile);

const buttonSX = {
	width: "100%",
	marginBottom: "20px",
	borderRadius: 0,
	textTransform: "capitalize",
	paddingTop: "6px"
};
