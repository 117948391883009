import * as React from "react";
import { useAppSelector } from "../../../utils/hooks/reduxHooks";
import { useErrorHandling } from "../../../utils/hooks/useErrorHandling";
import { getWithdrawalValue } from "../../../utils/getReceivedValue";
import { Form } from "../../Form";
import { Input } from "../../Form/Input";
import { InputRepeater } from "../../Form/InputRepeater";
import { FormBottomWrapper } from "../../Form/FormBottomWrapper";
import { ButtonWithLoader } from "../../ButtonWithLoader";
import { Alert, Box, Snackbar } from "@mui/material";
import { WaningNote } from "../../UI/Note";
import { bankTransferValidationSchema } from "./validation";
import API from "../../../utils/API";
import { AxiosResponse } from "axios";
import { useRedirectFormControls } from "../../../utils/hooks/useEmptyForm";
import { getEmptyForm } from "../EmptyForm/getEmptyForm";

const REDIRECT_URL = "https://www.onlinenaira.com/process.htm";

export const RedirectForm = getEmptyForm(REDIRECT_URL);

const USDNGN = 775.73;

export interface OnlineNairaFormProps {
	url: string;
	icon: string;
}
export function OnlineNairaForm (props: OnlineNairaFormProps) {
	const [ isFetch, setIsFetch ] = React.useState(false);
	const language = useAppSelector((state) => state.language);
	const accountNumber = useAppSelector((state) => `${state.session.userData.account_number ?? ""}`);
	const email = useAppSelector((state) => state.user.user.email);
	const [ firstName, lastName ] = useAppSelector((state) => {
		const fullNameArray = state.user.user.full_name?.split(" ");
		if (!fullNameArray) return [ state.user.user.full_name ?? "", "" ];

		return fullNameArray;
	});
	const currency = useAppSelector((state) => state.session.userData.currency ?? "USD");
	const { isOpen, errorMsg, hideError, showError } = useErrorHandling();
	const {
		ref,
		params,
		setParams
	} = useRedirectFormControls();
	const getWithdrawalValueCB = React.useCallback((values: any) => getWithdrawalValue(values, currency), [ currency ]);

	async function submit (
		values: { amount: string, firstName: string, lastName: string, email: string}
	) {
		setIsFetch(true);
		try {
			const formData = new FormData();
			formData.append("amount", values.amount);
			formData.append("first_name", values.firstName);
			formData.append("last_name", values.lastName);
			formData.append("email", values.email);

			const { data } = await API.post<unknown, AxiosResponse<any>>(props.url, formData);

			setParams(data.params.fields);
		} catch (e) {
			showError("Something went wrong!");
		} finally {
			setIsFetch(false);
		}
	}

	function calcInNGN (values: [string]) {
		const [ amount ] = values;
		return amount ? (parseInt(amount) * USDNGN).toFixed(2) + " NGN" : "0 NGN";
	}

	return (
		<>
			<Form
				onSubmit={submit}
				validationSchema={bankTransferValidationSchema()}
				defaultValues={{
					accountNumber,
					amount: "",
					firstName,
					lastName,
					email
				}}
			>
				<Box sx={{
					paddingBottom: "16px",
					height: "100px",
					display: "flex",
					alignItems: "center",
					justifyContent: "center"
				}}>
					<img src={props.icon} height="45px" alt="Online Naira"/>
				</Box>
				<Input
					name="accountNumber"
					label={language.languageCopytrade.cpy_mon_accnum}
					variant="rounded"
				/>
				<Input
					name="amount"
					label={language.languageFinance.s_03}
					variant="rounded"
				/>
				<InputRepeater
					fields="amount"
					label={""}
					onChange={calcInNGN}
				/>
				<Input
					name="firstName"
					label={language.languageMyAccounts.fn_01}
					variant="rounded"
				/>
				<Input
					name="lastName"
					label={language.languageMyAccounts.ln_01}
					variant="rounded"
				/>
				<Input
					name="email"
					label={"Email"}
					variant="rounded"
				/>
				<FormBottomWrapper
					forFields={[ "amount", "currency" ]}
					onChange={getWithdrawalValueCB}
					label={language.languageMyAccounts.form_message_01}
				>
					<ButtonWithLoader
						isFetch={isFetch}
						color={"primary"}
						text={language.languageMyAccounts.chapas_04}
					/>
				</FormBottomWrapper>
				<Box sx={{ marginTop: "6px" }}>
					<WaningNote
						message="Deposit are usually credited to the account within 30 minutes. If your deposit has not been placed into your Trading Account within 1 hour of your transaction submission, please check for any email messages from us."
					/>
				</Box>
			</Form>
			<Snackbar open={isOpen} autoHideDuration={4000} onClose={hideError}>
				<Alert variant="filled" color="error">
					{errorMsg}
				</Alert>
			</Snackbar>
			<RedirectForm refValue={ref} params={params} />
		</>
	);
}
