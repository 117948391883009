import {
	SET_INIT_INFO, SET_INIT_LINK_INFO
} from "../actions/actionTypes";

const initial = {
	init: {
		isChinaIP: false,
		country_code_CN: false,
		html_url_zh: false,
		companyName: "Tradomart SV Ltd.",
		prohibition_2: false,
		has30DollarPermission: false,
		isAccountFromEUCountry: false,
		isCashback: false,
		html_url: "en",
		uri_segment_1: "",
		WhitelistPIPCandCC: true,
		Office_and_Vpn_InviteFriend: false,
		Office: false,
		isEuropeanIP: false,
		smart_doller_button_active: false,
		forexmart_smart_click: false,
		isReferralsOfAccountIDR: false,
		isReferralsOfAccountMYR: false,
		isIndonesianCountry: false,
		isPayomaPayMentAvailable: false,
		IsCountryPaycoCard: false,
		isVietnamCountry: false,
		isThailandCountry: false,
		isEUClient: false,
		isMexicanCountry: false,
		isBrazilianCountry: false,
		ajax_url: "",
		isMalaysianCountry: false,
		user_curent_formated_balance: "0.00",
		ms_notif: { totalNotification: "0" },
		accStatus: { accountStatusData: {} },
		contact_emails: { id: "1", email: "info@forexmart.com" },
		links: {
			link_logout: "https://my.forexmart.com/Signout",
			link_my_account: "https://my.forexmart.com/my-account",
			link_my_trading: "https://my.forexmart.com/my-trading",
			link_deposit: "https://my.forexmart.com/deposit",
			link_bonus_bonuses: "https://my.forexmart.com/bonus/bonuses",
			link_credit_bonus: "https://my.forexmart.com/Credit_Bonus",
			link_partnership_commission: "https://my.forexmart.com/partnership/commission",
			link_rebate_system: "https://my.forexmart.com/rebate-system",
			link_invite_by_email: "https://my.forexmart.com/invite-friend/invite-by-email",
			link_my_mail: "https://my.forexmart.com/mail-support/my-mail",
			link_promotional_materials: "https://my.forexmart.com/promotional-materials",
			link_cashback: "https://my.forexmart.com/cashback",
			link_withdraw: "https://my.forexmart.com/withdraw",
			link_copytrade: "https://my.forexmart.com/copytrade",
			link_smartdollar: "https://my.forexmart.com/smartdollar",
			link_download_mt4: "https://download.mql5.com/cdn/web/instant.trading.eu/mt4/forexmart4setup.exe",
			link_profile_edit: "https://my.forexmart.com/profile/edit",
			link_metatrader4: "https://www.forexmart.com/metatrader4",
			link_account_verification: "https://www.forexmart.com/account-verification",
			link_deposit_withdraw_page: "https://www.forexmart.com/deposit-withdraw-page",
			link_about_us: "https://www.forexmart.com/about-us",
			link_call_back: "https://www.forexmart.com/call-back",
			link_legal_documentation: "https://www.forexmart.com/legal-documentation",
			link_search: "https://www.forexmart.com/search",
			link_privacy_policy: "https://my.forexmart.com/assets/pdf/Privacy Policy.pdf",
			link_risk_disclosure: "https://my.forexmart.com/assets/pdf/Risk Disclosure.pdf",
			link_terms_and_conditions_ch: "https://my.forexmart.com/assets/pdf/Terms and Conditions for ChineseV2.pdf",
			link_terms_and_conditions: "https://my.forexmart.com/assets/pdf/Terms and Conditions.pdf",
			link_domain_facebook: "https://www.facebook.com/forexmartofficial",
			link_domain_twitter: "https://twitter.com/ForexMartPage",
			link_upload_doc: "https://my.forexmart.com/profile/upload-documents",
			web_trader_icon: "https://my.forexmart.com/assets/images/webtrader-icon.png",
			chat_widget_img_v2: "https://my.forexmart.com/assets/images/chat-widget-img_v2.png",
			main_site_user_images: "https://my.forexmart.com/assets/user_images/",
			main_site_user_docs: "https://my.forexmart.com/assets/user_docs/"
		},
		require_data: {
			account_fund_info: {
				balance: 0,
				withrawableRealFund: 0,
				withrawableBonusFund: 0,
				bonusFund: 0
			}
		}

	}
};

export default function initReducer (state = initial, action:{type:string, payload:any}) {
	switch (action.type) {
		case SET_INIT_INFO:
			return {
				...state,
				init: {
					links: { ...state.init.links },
					...action.payload
				}
			};
		case SET_INIT_LINK_INFO:
			return {
				...state,
				init: {
					...state.init,
					links: action.payload
				}
			};

		default:
			return state;
	}
}
