import { CurrencyType } from "../utils/currency";
import { AccountsInterface } from "./myAccountsInterfaces";
import {
	IPartnershipClicksInterface,
	IPartnershipCommissionInterface,
	IPartnershipInterface,
	IPartnershipReferralsInterface,
	IPartnershipReferralsRegistrationData,
	IPartnershipSecurityCodeInterface
} from "../saga/partnershipSaga";
import { ITrade } from "../store/reducers/tradesReducer";
import { IBalanceOperation, IPendingTransaction } from "../store/reducers/financeReducer";
import { IUserSupportMail } from "../store/reducers/userMailsReducer";
import { IBanners, IBannersShow } from "../store/reducers/bannersReducer";
import { ILanguage } from "./LanguageInterface";
import { IInit } from "./InitInterface";
import { ISmartDollarsInfo } from "../saga/smartDollarsSaga";

export enum AccountType {
	FOLLOWER = "0",
	TRADER = "1",
	DEACTIVATED = "2",
	NOT_REGISTERED = "3",
}

export interface SessionUserDataInterface{
	__ci_last_regenerate: number
	socail_login_config_data?: boolean
	FBRLH_state?: string
	site_lang?: string
	account_number?: number
	session_id?: string
	session_expiration?: number
	con_session_id?: number
	con_account_number?: number
	full_name?: string // header
	user_id?: number
	username?: string
	email?: string
	logged_in?: boolean
	logged?: boolean
	status?: number
	administration?: boolean
	login_type?: string|number
	country?: string
	mt_account_set_id?: number
	currency?: CurrencyType,
	accountstatus?: number
	readOnly?: boolean
	image?: string // header
	goToPayment?: string
	cpa?: boolean
	oauth_id?: any // header
	partner_type?:string
	redirect?: string
	first_login?: string|null
	euid?: string|null
	eacn?: string|null
	neededTFA?: boolean;
}
export interface userBonusInterface {
	code: string;
	display: string;
	name: string;
	percentValue: number;
	terms_link: string;
	usage_logic: string
	value: number
}

export interface UserBonusesDataInterface {
	userBonuses: {
		bonuses: userBonusInterface[],
		nodepositbonus: string;
	}
}

export interface UserWithdrawMethodsDataInterface {
	withdrawMethods: {}
}

export interface UserPaymentMethodsDataInterface {
	paymentMethods: {}
}

export interface BonusInterface {
	msSetId: string;
	RegDate: string;
	country: string;
	bonusCode: "twpb" | "tpb" | "fpb" | "hpb" | "none";
	bonus: number;
}

export interface UserDataInterface{
	user_id?: string|null
	username?: string|null
	email?: string|null
	activated?: string|null
	banned?: string|null
	ban_reason?: string|null
	new_email?: string|null
	last_ip?: string|null
	last_login?: string|null
	created?: string|null
	modified?: string|null
	type?: string|null
	administration?: string|null
	affiliate_code?: string|null
	login_type?: string|null
	accountstatus?: string|null
	nodepositbonus?: string|null
	thirtydollarbonus?: string|null
	createdforadvertising?: string|null
	verified?: string|null
	open_ndba_counter?: string|null
	ndba_acquired?: string|null
	ndb_bonus?: string|null
	test?: string|null
	test_1?: string|null
	admin_userid_ndbopened?: string|null
	ndb_reopened?: string|null
	ndb_bonus_api?: string|null
	recent_fileupload?: string|null
	partner_agreement?: string|null
	first_login_hash?: string|null
	first_login_stat?: string|null
	auto_verified?: string|null
	users_corporate_acc_status?: string|null
	publisher?: string|null
	auto_created_account?: string|null
	registration_location?: string|null
	ndb_cabinet_crediting?: string|null
	micro?: string|null
	ndb_bonus_ccy?: string|null
	ndb_logs?: string|null
	comment?: string|null
	ndbtag?: string|null
	ndbcreditingvisit?: string|null
	ndb_ticket?: string|null
	updated_ticket_accounts?: string|null
	removeagent_log?: string|null
	pac_ctr?: string|null
	is_bonuscontestmfprize?: string|null
	is_showfxbonus?: string|null
	is_mt4_active?: string|null
	re_add_agent_tag?: string|null
	auto_subscribe?: string|null
	domain?: string|null
	account_link?: string|null
	account_type_status?: string|null
	phone_verified_1?: string|null
	phone_verified_2?: string|null
	smart_dollar_status?: string|null
	smart_dollar_level?: string|null
	partner_credit_bonus?: string|null
	auto_reg?: string|null
	users_login_oauth_id?: string|null
	login_oauth_provider?: string|null
	account_status?: string|null
	social_user_id?: string|null
	internal_registration?: string|null
	rollover_status?: string|null
	rollover_date_modified?: string|null
	accountstatus_update_date?: string|null
	accountstatus_update_location?: string|null
	accountstatus_update_by_user_id?: string|null
	reg_step?: string|null
	user_profiles_id?: string|null
	full_name?: string|null
	street?: string|null
	city?: string|null
	state?: string|null
	country?: string|null
	zip?: string|null
	dob?: string|null
	passport?: string|null
	user_profiles_contact_id?: string|null
	image?: string|null
	preferred_time?: string|null
	skype?: string|null
	fb?: string|null
	social_media_type?: string|null
	admin_created?: string|null
	dob_back?: string|null
	age?: string|null
	gender?: string|null
	tin?: string|null
	passport_number?: string|null
	issuing_authority?: string|null
	user_profiles_cpy_avatar?: string|null
	user_profiles_login_oauth_id?: string|null
	updated_date?: string|null
	contacts_contact_id?: string|null
	phone1?: string|null
	phone2?: string|null
	email1?: string|null
	email2?: string|null
	email3?: string|null
	preferred_contact_id?: string|null
	sms?: string|null
	telephone?: string|null
	mt_accounts_id?: string|null
	mt_account_set_id?: string|null
	leverage?: string|null
	mt_accounts_set_amount?: string|null
	mt_currency_base?: string|null
	mt_trading_group?: string|null
	mt_status?:string|null
	mt_comment?: string|null
	mt_passport_id?: string|null
	registration_time?: string|null
	registration_ip?: string|null
	last_login_ip?: string|null
	mt_type?: string|null
	swap_free?: string|null
	account_number?: string|null
	group?: string|null
	group_code?: string|null
	auto_leverage?: string|null
	auto_leverage_change?: string|null
	registration_leverage?: string|null
	client_autolevchange_disable?: string|null
	amount_conv_api?: string|null
	active?: string|null
	inactive_email_error?:string|null
	is_ndb_acquire_from_another_account?: string|null
	has_activeaccount?: string|null
	restored_inactive_account?: string|null
	mini_bonus_credit?: string|null
	is_standard_account?: string|null
	is_groupupdated?: string|null
	is_notactive_notemailed?: string|null
	updated_creditedbonus?: string|null
	is_enabled?: string|null
	annually_credited_minibonus?: string|null
	update_minicreditedbonuse_db?: string|null
	annual_minibonus_tag?: string|null
	inactive_date?: string|null
	inactive_counter?: string|null
	remove_account?: string|null
	new_account_minibonus?: string|null
	new_account_ready?: string|null
	old_account_created_newaccount?: string|null
	ready_nth_newaccount?: string|null
	ndbmini_uniquetag1st?: string|null
	ndbmini_uniquetagnth?: string|null
	mt_accounts_corporate_acc_status?: string|null
	is_minibonus_emailsent?: string|null
	open_trading?: string|null
	registry_method?: string|null
	auto_leverage_change_deposit?: string|null
	auto_leverage_change_admin?: string|null
	removeagent?: string|null
	grouptagupdate?: string|null
	check_inactivity?: string|null
	ndb_date?: string|null
	fixed_group?: string|null
	credit_prize_ndb_status?: string|null
	updated_creditprize?: string|null
	no_ndb_api?: string|null
	all_ndb_tickets?: string|null
	ndb_counter_tag?: string|null
	leverage_update_ndb?: string|null
	agent_ndbtag?: string|null
	score_max_leverage?: string|null
	total_score?: string|null
	block_account?: string|null
	total_points?: string|null
	mt_accounts_cpy_avatar?: string|null
	mt_accounts_comments?: string|null
	partnership_id?: string|null
	partner_id?: string|null
	reference_num?: string|null
	target_country?: string|null
	message?: string|null
	websites?: string|null
	type_of_partnership?: string|null
	dateregistered?: string|null
	status_type?: string|null
	company_name?: string|null
	registration_number?: string|null
	date_of_incorporation?: string|null
	phone_number?: string|null
	currency?: string|null
	partnership_amount?: string|null
	partnership_comments?: string|null
	reference_subnum?: string|null
	prog_comment?: string|null
	referral_affiliate_code?: string|null
	account_type?: AccountType | string |null
	account_type_name?: string|null
	fm_account_type_name?: string|null
	"forexcopy_rollover_status"?: string|null
	"commissionData"?: {
		"DisplayValue?": string|null
		"ReqResult"?: number|null
		"Result"?: {
			"KeyValueOfintstring"?: {
				"Key"?: number|null
				"Value"?: string|null
			} []|null
		}
	}
	special10Bonus?: boolean
	account_info?:{}
	bonus?: BonusInterface
	bonus10?: boolean;
}

export interface TradersItemInterface{
	"AccountType": string
	"ActiveFollowers": number
	"Balance": number
	"Currency": string | null
	"CurrentTrades": number
	"DailyEquity": number
	"Equity": number
	"Leverage": number
	"ProjectName": string
	"RegisteredDate": number
	"SimpleRating": number
	"SubscriptionStatus": number
	"TotalLoss": number
	"TotalProfit": number
	"TotalProfitInUsd": number
	"TotalTrades": number
	"UserId": number
	"Avatar"?: string | null
	"UserLink"?: string | null
	"RegisteredDays"?: number | null | undefined
	// "Account"?: any | null
	// "Page"?: any | null
}
export interface TradersInterface{
	"counts": number
	"start": number
	"len": number
	"search": string
	"sort": number
	"traders": TradersItemInterface []
}

export interface SubscriptionTradersInterface{
	"traderCount"?: number|null
	"traderList"?: any|null
}

export interface StoreInterface {
	session: {
		userData: SessionUserDataInterface
		loaded: boolean
	}
	user: {
		user: UserDataInterface
		userDocs: any
		userCorpDocs: any
		userDocUploading: boolean
	}
	copytrade: {
		loadingMonitoring: boolean
		tradersMonitoring: TradersInterface
		tradersMonitoringPage: number
		tradersLength: number
		tradersAll: TradersItemInterface[]
		tradersAllLength: number
		loadingSubscriptions: boolean
		tradersSubscriptions: [],
		tradersSubscriptionsTotalLength: number,
	}
	init:{
		init: IInit
	}
	language:ILanguage
	feedback:{
		feedback: {}
		notification: {}
		loading: boolean
	}
	auth:{
		auth: {}
		logged: boolean
		loading: boolean
	}
	myAccounts:{
		myAccounts: AccountsInterface
		myAccountsLoading: boolean
		personalDetailsLoading: boolean
		personalDetailsResponse: {}
		verifyResponse: {}
	}
	account: {
		funds: {
			balance: number;
			bonusFund: number,
			equity: number;
			margin: number;
			realFund: number;
			status: string;
			withrawableBonusFund: number;
			withrawableRealFund: number;
		}
		info: {};
		loading: boolean,
		trading: {
			accountTotalLots: number
		}
	}
	partnership: {
		info: IPartnershipInterface
		commission: IPartnershipCommissionInterface
		clicks: IPartnershipClicksInterface
		referrals: IPartnershipReferralsInterface
		referralsRegistration: IPartnershipReferralsRegistrationData
		security: IPartnershipSecurityCodeInterface
		loading: boolean
		commissionLoading: boolean
		clicksLoading: boolean
		referralsRegistrationLoading: boolean
		securityCodeLoading: boolean
	}
	trades: {
		loadingTrades: boolean
		tradesLength: number
		tradesAll: Array<ITrade>
		tradesAllLength: number
		tradesOpened: Array<ITrade>
	}
	finance: {
		loadingPendingTransactions: boolean
		pendingTransactions: Array<IPendingTransaction>
		loadingBalanceOperations: boolean
		balanceOperations: Array<IBalanceOperation>
	}
	userMails: {
		userMails: Array<IUserSupportMail>
	}
	banners: {
		loadingBanners: boolean
		banners: IBanners
		bannersShow: IBannersShow
	}
	smartDollars: {
		smart_status: boolean,
		smart_info: ISmartDollarsInfo
	}
}
