import * as React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
	buttonStyles: {
		border: "none",
		backgroundColor: "transparent"
	}
}));

export interface ICleanButtonProps extends React.HTMLAttributes<HTMLButtonElement> {}

export function CleanButton (props: ICleanButtonProps) {
	const styles = useStyles();

	return (
		<button
			className={styles.buttonStyles}
			{...props}
		>
			{props.children}
		</button>
	);
}
