import * as React from "react";
import * as Yup from "yup";
import { Box, Paper, Typography } from "@mui/material";
import { useAdaptive } from "../../../utils/hooks/themeBreakpointsHooks";
import TFAImage from "../../../images/mobile-ver.png";
import { Form } from "../../../components/Form";
import { Input } from "../../../components/Form/Input";
import { ButtonWithLoader } from "../../../components/ButtonWithLoader";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks/reduxHooks";
import { verifyTFA } from "../../../store/actions/actionTypes";

const validationSchema = Yup.object().shape({});

export function TFAVerify () {
	const isFetch = useAppSelector(state => state.auth.loading);

	const dispatch = useAppDispatch();

	const adaptivePaperStyles = useAdaptive(paperStyles);
	const adaptivePaperWrapperStyles = useAdaptive(paperWrapperStyles);
	const adaptiveItemBoxStyles = useAdaptive(itemBoxStyles);

	function submit (values: { code: string }) {
		dispatch(verifyTFA(values));
	}

	return (
		<Box sx={adaptivePaperWrapperStyles}>
			<Paper
				variant="outlined"
				square
				sx={adaptivePaperStyles}
			>
				<Typography variant="h2" textAlign="center" sx={{ width: "100%" }}>
					2-Step Verification
				</Typography>
				<Box sx={adaptiveItemBoxStyles}>
					<img src={TFAImage} width="100px" />
				</Box>
				<Typography>
					Enter the verification code generated by your mobile application
				</Typography>
				<Form
					defaultValues={{ code: "" }}
					validationSchema={validationSchema}
					onSubmit={submit}
					fieldWrapperVariant="for-auth"
				>
					<Box sx={{ padding: "10px 0" }}>
						<Input label="Enter code" name="code" />
					</Box>
					<ButtonWithLoader
						color="primary"
						isFetch={isFetch}
						sx={{ width: "100%" }}
						sxButton={{ width: "100%" }}
						text="Verify"
					/>
				</Form>
			</Paper>
		</Box>
	);
}

const paperStyles = {
	mobile: {
		padding: "25px 20px",
		maxWidth: "400px",
		width: "100%",
		margin: "0 auto"
	},
	tablet: {
		padding: "39px 41px 34px"
	}
};

const paperWrapperStyles = {
	mobile: {
		width: "100%",
		padding: "25px 20px"

	},
	tablet: {
		padding: "60px 41px 34px"
	}
};

const itemBoxStyles = {
	mobile: {
		padding: "10px 20px"

	},
	tablet: {
		padding: "20px 10px 15px"
	}
};
