/*
    Created on : 04.02.2022, 06:18:59
    Author     : Andy Kar
*/
import React, { useState } from "react";
import "./FormHorizontalGroupAvatar.scss";
import { StoreInterface, UserDataInterface } from "../../../../interfaces/StoreInterfaces";
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_AVATAR, REMOVE_AVATAR } from "../../../../store/actions/actionTypes";
import Button from "@mui/material/Button";
import { ISxAdaptive, useAdaptive } from "../../../../utils/hooks/themeBreakpointsHooks";
import { Box, SxProps, Theme } from "@mui/material";

const adaptiveButtonStyles: ISxAdaptive = {
	xs: {
		width: "100%",
		maxWidth: "150px"
	},
	sm: {
		maxWidth: "350px"
	},
	md: {
		maxWidth: "100%"
	}
};

export interface FormHorizontalGroupInterface {
	sx?: SxProps<Theme>
	label_text?: string
	required?: boolean
	type?: string
	options?: {} | null
	name?: string
	placeholder?: string
	defaultValue?:any
	error?: string
	tooltip_message?: string

}

function FormHorizontalGroupAvatar (props: FormHorizontalGroupInterface) {
	const { sx } = props;
	const user:UserDataInterface = useSelector((state:StoreInterface) => state.user.user);
	const init:any = useSelector((state:StoreInterface) => state.init.init);
	const langMyAccounts:any = useSelector((state:StoreInterface) => state.language.languageMyAccounts);

	const adaptiveButton = useAdaptive(adaptiveButtonStyles);

	let avatar:string = "";
	if (user.image) {
		avatar = init.links.main_site_user_images + user.image;
	}

	const dispatch = useDispatch();
	const removeAvatar = () => {
		dispatch({ type: REMOVE_AVATAR });
	};

	const [ uploadFileName, setUploadFileName ] = useState("");

	const changeAvatar = () => {
		const formName:any = "avatarForm";
		const avatarForm: HTMLFormElement | null = document.forms[formName];
		if (avatarForm && fileInputRef.current && fileInputRef.current.files && fileInputRef.current.files[0].name) {
			const profileAvatar = fileInputRef.current.files[0].name;
			setUploadFileName(profileAvatar);
			const changeAvatarData = new FormData(avatarForm);
			changeAvatarData.append("profile_avatar", profileAvatar);
			dispatch({ type: CHANGE_AVATAR, changeAvatarData });
		}
	};

	const fileInputRef = React.createRef<HTMLInputElement>();

	return (
		<Box className="form-group" sx={sx} >
			<div className="form-horizontal-left-column" style={{ justifyContent: "center" }}>
				<div className="avatar-holder">
					<input type="hidden" value={init.links.images_folder_uri + "avatar.png"} />
					{avatar
						? <img src={avatar} alt="avatar"/>
						: <svg
							version="1.1"
							xmlns="http://www.w3.org/2000/svg"
							xmlnsXlink="http://www.w3.org/1999/xlink"
							width="100"
							stroke="black"
							strokeWidth="30"
							viewBox="0 0 600 600"
							fill="none"
						>
							<circle cx="300" cy="300" r="265" />
							<circle cx="300" cy="230" r="115" />
							<path d="M106.81863443903,481.4 a205,205 1 0,1 386.36273112194,0" strokeLinecap="butt" />
						</svg>
					}
					<Button
						type="button"
						onClick={removeAvatar}
						className="btn-remove-avatar d-none"
						sx={adaptiveButton}
					>
						{langMyAccounts.perdet_00}
					</Button>
				</div>
			</div>
			<Button
				variant={"contained"}
				onClick={() => fileInputRef.current?.click()}
				sx={adaptiveButton}
			>
					Browse
			</Button>
			<div className="form-horizontal-right-column flexRow d-none">
				<div className="file-upload-wrap">
					<div>
						<i className="fa fa-download"></i>
						{langMyAccounts.perdet_01 + "..."}
					</div>
					<form name="avatarForm">
						<label className="file-upload-btn-wrap flexRow">
							<span className="file-upload-btn">{langMyAccounts.boo_str_01}</span>
							<span className="file-upload-name">{uploadFileName || langMyAccounts.boo_str_02}</span>
							<input
								type="file"
								name="avatar"
								multiple
								ref={fileInputRef}
								style={{ display: "none" }}
								onChange={changeAvatar}
							/>
						</label>
					</form>
				</div>
			</div>
		</Box>
	);
}

export default FormHorizontalGroupAvatar;
