/* eslint-disable camelcase */
import * as React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Copy } from "../../../../components/UI/Copy";
import { FieldWrapper as FieldWrapperUI } from "../../../../components/UI/FieldWrapper";
import { useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { useIsRtl } from "../../../../utils/hooks/useIsRtl";
import { IDRIcons } from "./images";

export function LocalDepositorIndonesia () {
	const { trd_43, trd_49 } = useAppSelector((state) => state.language.languageTrading);
	const isRtl = useIsRtl();
	const FieldWrapper = React.useMemo(() => FieldWrapperUI.default, []);

	return (
		<>
			<Grid
				container
				flexDirection={isRtl ? "row-reverse" : "row"}
				sx={{ marginBottom: "16px", paddingTop: "20px" }}
			>
				<Box sx={{ marginRight: "4px" }}><IDRIcons.BCA /></Box>
				<Box sx={{ marginRight: "4px" }}><IDRIcons.BNI /></Box>
				<Box sx={{ marginRight: "4px" }}><IDRIcons.BRI /></Box>
				<Box sx={{ marginRight: "4px" }}><IDRIcons.DANAMON /></Box>
				<Box sx={{ marginRight: "4px" }}><IDRIcons.MANDIRI /></Box>
			</Grid>
			<Typography
				color="#5C7080"
				textAlign={isRtl ? "right" : "left"}
				className="light"
				sx={{ marginBottom: "15px" }}
			>
				ForexMart accepts deposit via the biggest banks of Indonesia.

			</Typography>
			<Typography
				color="#5C7080"
				textAlign={isRtl ? "right" : "left"}
				className="light"
				sx={{ marginBottom: "15px" }}
			>
				To proceed with the deposit transaction with bonus, you may contact our local service. All deposits are to be transacted at Central Bank current rate.
			</Typography>
			<Typography
				color="#5C7080"
				textAlign={isRtl ? "right" : "left"}
				className="light"
				sx={{ marginBottom: "15px" }}
			>
				{trd_49}
			</Typography>
			<FieldWrapper label="Email">
				<Copy
					value="deposit-indonesia@forexmart.com"
				/>
			</FieldWrapper>
			<Typography
				color="#5C7080"
				textAlign={isRtl ? "right" : "left"}
				className="bold"
				sx={{ marginBottom: "15px" }}
			>
				{trd_43} 1
			</Typography>
			<FieldWrapper label="Email">
				<Copy
					value="forexmartbdg@gmail.com"
				/>
			</FieldWrapper>
			<FieldWrapper label="WhatsApp">
				<Copy
					value="+62 858 7149 6934"
				/>
			</FieldWrapper>

			<Typography
				color="#5C7080"
				textAlign={isRtl ? "right" : "left"}
				className="bold"
				sx={{ marginBottom: "15px" }}
			>
				{trd_43} 2
			</Typography>
			<FieldWrapper label="Email">
				<Copy
					value="copycashin@gmail.com"
				/>
			</FieldWrapper>
			<FieldWrapper label="WhatsApp">
				<Copy
					value="+62 857 1133 3746"
				/>
			</FieldWrapper>

			<Typography
				color="#5C7080"
				textAlign={isRtl ? "right" : "left"}
				className="bold"
				sx={{ marginBottom: "15px" }}
			>
				{trd_43} 3
			</Typography>
			<FieldWrapper label="Email">
				<Copy
					value=" cs.ngepips@gmail.com"
				/>
			</FieldWrapper>
			<FieldWrapper label="WhatsApp">
				<Copy
					value="+62 819 1522 6122"
				/>
			</FieldWrapper>
		</>
	);
}
