import * as React from "react";
import { Form } from "../../Form";
import { Input } from "../../Form/Input";
import { getWithdrawalValue } from "../../../utils/getReceivedValue";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks/reduxHooks";
import { zotaPayWithdrawValidationSchema } from "./validation";
import { FormBottomWrapper } from "../../Form/FormBottomWrapper";
import { ButtonWithLoader } from "../../ButtonWithLoader";
import { Alert, Box, Skeleton, Snackbar } from "@mui/material";
import { WaningNote } from "../../UI/Note";
import API from "../../../utils/API";
import { AxiosResponse } from "axios";
import { useErrorHandling } from "../../../utils/hooks/useErrorHandling";
import { WalletCurrency } from "../../../types/WalletCurrency";
import {
	fetchIdrZotaPayInfo,
	fetchMyrZotaPayInfo,
	fetchThbZotaPayInfo,
	fetchVndZotaPayInfo
} from "../../../store/actions/actionTypes";
import { CurrencyType } from "../../../utils/currency";
import { ActionType } from "../../../utils/reudxHelpers";

type ZotapayActions = {[k in WalletCurrency]?: ActionType<string, unknown>};

const actions: ZotapayActions = {
	[WalletCurrency.MYR]: fetchMyrZotaPayInfo({}),
	[WalletCurrency.THB]: fetchThbZotaPayInfo({}),
	[WalletCurrency.IDR]: fetchIdrZotaPayInfo({}),
	[WalletCurrency.VND]: fetchVndZotaPayInfo({})
};

export interface WithdrawZotapayFormProps {
	walletCurrency: WalletCurrency;
}
export function WithdrawZotaPay (props: WithdrawZotapayFormProps) {
	const { walletCurrency } = props;
	const [ isFetch, setIsFetch ] = React.useState(false);
	const [ success, setSuccess ] = React.useState(false);
	const language = useAppSelector((state) => state.language);
	const accountNumber = useAppSelector((state) => `${state.session.userData.account_number ?? ""}`);
	const currency = useAppSelector((state) => state.session.userData.currency ?? "USD");
	const {
		fee,
		addon,
		feeDetails,
		loading: infoLoading,
		complete
	} = useAppSelector((state) => state.withdrawMethodsInfo.zotaPay[walletCurrency]);
	const dispatch = useAppDispatch();
	const { isOpen, errorMsg, hideError, showError } = useErrorHandling();

	React.useEffect(() => {
		const action = actions[walletCurrency];
		if (action && !complete) {
			dispatch(action);
		}
	}, []);

	function getWithdrawalValueCB (values: [string, CurrencyType]) {
		const [ amount = "0", currencyType ] = values;
		const totalAmount = +amount + +amount * fee + addon;

		return getWithdrawalValue(
			[
				totalAmount.toString(),
				currencyType
			],
			currency,
			3
		);
	}

	async function submit (values: {
		amount: string,
		bankAccountNumber: string,
		bankAccountName: string,
		bankName: string,
		branch: string,
		province: string,
		city: string,
	}) {
		try {
			setIsFetch(true);
			const formData = new FormData();
			formData.append("amount_withdraw", values.amount);
			formData.append("bank_account_number", values.bankAccountNumber);
			formData.append("bank_account_name", values.bankAccountName);
			formData.append("bank_name", values.bankName);
			formData.append("bank_branch", values.branch);
			formData.append("bank_province", values.province);
			formData.append("bank_city", values.city);
			formData.append("wallet_currency", walletCurrency);

			const { data } = await API.post<unknown, AxiosResponse<{ success: boolean, error: string }>>("withdrawZotapay", formData);
			if (data.success) {
				setSuccess(true);
			} else {
				showError(data.error || "Something went wrong");
			}
		} catch (e) {
			// handle error
		} finally {
			setIsFetch(false);
		}
	}

	return (
		<Form
			onSubmit={submit}
			validationSchema={zotaPayWithdrawValidationSchema(language.languageFinance)}
			defaultValues={{
				accountNumber,
				amount: "",
				bankAccountName: "",
				bankName: "",
				bankAccountNumber: "",
				branch: "",
				province: "",
				city: ""
			}}
		>
			<Input
				name="accountNumber"
				label={language.languageCopytrade.cpy_mon_accnum}
				variant="rounded"
			/>
			<Input
				name="amount"
				label={language.languageFinance.trd_230}
				variant="rounded"
			/>
			<Input
				name="bankAccountName"
				label={language.languageFinance.with_04}
				variant="rounded"
			/>
			<Input
				name="bankName"
				label={language.languageFinance.with_03}
				variant="rounded"
			/>
			<Input
				name="bankAccountNumber"
				label={language.languageFinance.wunip_06}
				variant="rounded"
			/>
			<Input
				name="branch"
				label={language.languageFinance.with_02}
				variant="rounded"
			/>
			<Input
				name="province"
				label={language.languageFinance.with_06}
				variant="rounded"
			/>
			<Input
				name="city"
				label={language.languageFinance.with_05}
				variant="rounded"
			/>
			<FormBottomWrapper
				forFields={[ "amount", "currency" ]}
				onChange={getWithdrawalValueCB}
				label={language.languageMyAccounts.form_message_01}
			>
				<ButtonWithLoader
					isFetch={isFetch}
					color={"primary"}
					text={language.languageMyAccounts.chapas_04}
				/>
			</FormBottomWrapper>
			<Box sx={{ marginTop: "6px" }}>
				<WaningNote
					message="Please fill all fields"
				/>
			</Box>
			<Box sx={{ marginTop: "6px" }}>
				{
					infoLoading
						? (
							<Skeleton width={"220px"} height={"26px"} />
						)
						: (
							<WaningNote
								message={`Fee is ${feeDetails}`}
							/>
						)
				}
			</Box>
			<Snackbar open={isOpen} autoHideDuration={4000} onClose={hideError}>
				<Alert variant="filled" color="error">
					{errorMsg}
				</Alert>
			</Snackbar>
			<Snackbar
				open={success}
				autoHideDuration={4000}
				onClose={() => setSuccess(false)}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
			>
				<Alert variant="filled" color="success">
					Success
				</Alert>
			</Snackbar>
		</Form>
	);
}
