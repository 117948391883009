import { Box, SxProps, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import { Controller } from "react-hook-form";
import { FormContext } from "../Form";

export interface IRadioProps {
	label: string;
	name: string;
	value: string | ((value: string | number) => boolean);
}

const useStyles = makeStyles({
	radio: {
		accentColor: "#707070",
		marginRight: "8px"
	}
});

const boxStyles: SxProps = {
	display: "flex",
	flexDirection: "row"
};

export function Radio (props: IRadioProps) {
	const { label, name, value } = props;
	const { register, control } = React.useContext(FormContext);
	const styles = useStyles();

	return typeof value === "string"
		? (
			<Box sx={boxStyles}>
				<input {...register(name)} className={styles.radio} type="radio" value={value} />
				<Typography>
					{label}
				</Typography>
			</Box>
		)
		: (<Controller
			name={name}
			control={control}
			render={({ field: { value: inputValue } }) => {
				const radioValue = React.useMemo(() => {
					if (value(inputValue)) {
						return inputValue;
					}
					return "";
				}, []);

				return (
					<Box sx={boxStyles}>
						<input {...register(name)} className={styles.radio} type="radio" value={radioValue} />
						<Typography>
							{label}
						</Typography>
					</Box>
				);
			}}
		/>);
}
