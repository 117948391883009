import * as React from "react";
import { Box, Typography } from "@mui/material";
import { ICheckboxProps } from "../types";

export interface IRoundedCheckBoxProps extends ICheckboxProps {}

export function RoundedCheckBox (props: IRoundedCheckBoxProps) {
	const { label, name, onChange, value } = props;
	const [ isChecked, setIsChecked ] = React.useState(false);
	const ref = React.useRef<HTMLInputElement | null>(null);

	const _onChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		onChange(e.target.checked);
		setIsChecked(e.target.checked);
	}, [ isChecked, onChange, setIsChecked ]);

	const change = React.useCallback(() => {
		if (ref.current) {
			ref.current.click();
		}
	}, [ ref ]);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				cursor: "pointer"
			}}

			onClick={change}
		>
			<Box sx={{
				width: "14px",
				height: "14px",
				borderRadius: "50%",
				border: "1px solid #707070",
				padding: "2px",
				marginRight: "8px",
				background: "#fff"
			}}>
				<Box sx={{ width: "100%", height: "100%", background: isChecked ? "#707070" : "transparent", borderRadius: "50%" }}></Box>
			</Box>
			<Typography lineHeight="1.1875rem" color="#707070">
				{label}
			</Typography>
			<input ref={ref} name={name} checked={value} type="radio" hidden onChange={_onChange}/>
		</Box>
	);
}
