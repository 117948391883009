import * as React from "react";
import { Form } from "../../../../components/Form";
import { cardWithdrawValidationSchema } from "./validation";
import { Input } from "../../../../components/Form/Input";
import { InputRepeater } from "../../../../components/Form/InputRepeater";
import { CARD_WITHDRAW_FEE_FIXED, CARD_WITHDRAW_FEE_INTEREST } from "../constants";
import { useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { ExpiryDate } from "../../../../components/Form/ExpiryDate";
import { Option } from "../../../../components/Form/Option";
import { Select } from "../../../../components/Form/Select";
import { DatePicker } from "../../../../components/Form/DatePicker";
import { ButtonWithLoader } from "../../../../components/ButtonWithLoader";
import { FormBottomWrapper } from "../../../../components/Form/FormBottomWrapper";

const yearsElement = Array(10).fill(0).map((_, index) => (
	<Option key={index} value={new Date().getFullYear() + index + 1}>
		{new Date().getFullYear() + index + 1}
	</Option>
));

/**
 * TODO: Add translations for fields labels
 * TODO: Implement submit method
 * TODO: Get fee info from server
 */
export function Card () {
	const [ isFetch ] = React.useState(false);
	const {
		mt_accounts_set_amount: amount,
		account_number: accountNumber
	} = useAppSelector((state) => state.user.user);
	const language = useAppSelector((state) => state.language);

	async function submit () {
		// * Implement me
	}

	function calcFee (values: [string]) {
		const [ withdrawAmount ] = values;

		return (isNaN(parseInt(withdrawAmount)) || +withdrawAmount < 10)
			? "0 USD"
			: `${(+withdrawAmount * CARD_WITHDRAW_FEE_INTEREST + CARD_WITHDRAW_FEE_FIXED).toFixed(3)} USD`;
	}

	function calcNewBalance (values: [string]) {
		const [ withdrawAmount ] = values;

		return (isNaN(parseInt(withdrawAmount)) || +withdrawAmount < 10)
			? `${amount} USD`
			: `${(amount - +withdrawAmount * CARD_WITHDRAW_FEE_INTEREST - CARD_WITHDRAW_FEE_FIXED).toFixed(3)} USD`;
	}

	function getWithdrawalValue (values: [string]) {
		const [ withdrawAmount ] = values;

		return (isNaN(parseInt(withdrawAmount)) || +withdrawAmount < 10)
			? `${amount} USD`
			: `${(amount + +withdrawAmount * CARD_WITHDRAW_FEE_INTEREST + CARD_WITHDRAW_FEE_FIXED).toFixed(3)} USD`;
	}

	return (
		<Form
			onSubmit={submit}
			validationSchema={cardWithdrawValidationSchema()}
			defaultValues={{
				accountNumber,
				amount,
				cardNumber: "",
				cardholdersName: "",
				expiryDate: {},
				currency: "USD",
				passportNumber: "",
				passportCountry: "",
				passportSeries: "",
				passportIssuer: "",
				passportIssuedAt: {},
				passportExpirationDate: {}
			}}
		>
			<Input
				name="accountNumber"
				label={"Account Number"}
			/>
			<Input
				name="amount"
				label={"Amount to withdraw"}
				disabled={+amount <= 10}
			/>
			<InputRepeater
				fields="amount"
				label={"Fee"}
				onChange={calcFee}
			/>
			<InputRepeater
				fields="amount"
				label={"New balance"}
				onChange={calcNewBalance}
			/>
			<Input
				name="cardNumber"
				label={"Card Number"}
			/>
			<Input
				name="cardholdersName"
				label={"Cardholder's name"}
			/>
			<ExpiryDate
				name="expiryDate"
				label={"Card expiry date"}
			>
				{yearsElement}
			</ExpiryDate>
			<Select
				name="currency"
				label={"Transfer Currency"}
			>
				<Option value="USD">USD</Option>
			</Select>
			<Input
				name="passportNumber"
				label={"Passport Number"}
			/>
			<Input
				name="passportCountry"
				label={"Passport Country"}
			/>
			<Input
				name="passportSeries"
				label={"Passport Series"}
			/>
			<Input
				name="passportIssuer"
				label={"Passport Issuer"}
			/>
			<DatePicker
				name="passportIssuedAt"
				label={"Passport Issued At"}
			/>
			<DatePicker
				name="passportExpirationDate"
				label={"Passport Expiration Date"}
			/>
			<FormBottomWrapper
				forFields={[ "amount" ]}
				onChange={getWithdrawalValue}
				label={language.languageMyAccounts.form_message_01}
			>
				<ButtonWithLoader
					isFetch={isFetch}
					color={"primary"}
					text={language.languageMyAccounts.chapas_04}
				/>
			</FormBottomWrapper>
		</Form>
	);
}
