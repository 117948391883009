export enum StatusCode {
	PENDING = 1,
	APPROVED = 2,
	CANCELED_BY_FOLLOWER = 4,
	CANCELED_BY_TRADER = 5
}

export interface ISubsData {
	id: number;
	account: {
		number: number,
		balance: string | null,
	},
	creationTime: string | null;
	status: StatusCode;
	pendingCommission: number;
	activity: {},
	actions: {}
}

export interface IPastSubsData {
	accountNumber: number;
	creationTime: string | null;
	changeTime: string | null;
	status: string | null;
}

export interface IFollowersData {
	id: number;
	account: {
		number: number,
		balance: string | null,
	},
	creationTime: string | null;
	changeTime: string | null;
	status: StatusCode;
	actions: {},
}
