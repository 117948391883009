import * as React from "react";
import { Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { useIsRtl } from "../../../utils/hooks/useIsRtl";

interface AccordionSummaryLinkProps extends AccordionSummaryProps {
	to?: string
	open?: boolean
	dir?: string
}

const AccordionSummary = styled((props: AccordionSummaryLinkProps) => (
	<>
		{props.to
			? <Link
				to={"/app/" + props.to}
				className={"flexRow"}
				style={{
					width: "100%",
					height: "60px",
					padding: props.open ? props.dir === "ltr" ? "0px 20px 0px 25px" : "0 25px 0 20px" : "0 23px",
					background: props.open ? "none" : "#e2e7e8"
				}}
			>
				<MuiAccordionSummary
					expandIcon={props.open ? <ExpandMoreIcon/> : null}
					{...props}
					sx={{
						justifyContent: "flex-start",
						overflow: "hidden"
					}}
				/>
			</Link>
			: <Box
				className={"flexRow"}
				sx={{
					width: "100%",
					height: "60px",
					padding: props.dir === "ltr" ? "0px 20px 0px 25px" : "0 25px 0 20px"
				}}
			>
				<MuiAccordionSummary
					expandIcon={<ExpandMoreIcon/>}
					{...props}
				/>
			</Box>
		}
	</>
))(() => ({
	width: "100%",
	padding: 0,
	margin: 0,
	"& .MuiAccordionSummary-content": {
		padding: 0,
		margin: 0,
		color: "#2988CA"
	},
	"&.Mui-expanded .MuiTypography-root": {
		color: "#2988CA"
	},
	"& .MuiAccordionSummary-content svg": {
		transition: "all 200ms ease"
	}
}));

export default function SidebarAccordionLinkSummary (props:any) {
	const isRtl = useIsRtl();
	return (
		<AccordionSummary
			{...props}
			sx={{
				flexDirection: !isRtl ? "row" : "row-reverse",
				"& .MuiAccordionSummary-content": {
					flexDirection: !isRtl ? "row" : "row-reverse"
				}
			}}
		>
			{props.icon}
			<Typography
				sx={{
					padding: "0 12px"
				}}
			><b>{props.text}</b></Typography>
		</AccordionSummary>
	);
}
