import * as Yup from "yup";

export const changePasswordValidation = Yup.object().shape({
	old_password: Yup.string().required("Field is required"),
	new_password: Yup.string().required("Field is required").min(7, "This value must be minimum of 7 characters."),
	confirm_password: Yup.string().required("Field is required").min(7, "This value must be minimum of 7 characters.").oneOf([ Yup.ref("new_password"), null ], "Passwords don't match!")
}).required();

export const checkPasswordValidation = Yup.object().shape({
	old_password: Yup.string().required("Field is required")
}).required();
