/*
    Created on : 12.10.2022, 06:18:59
    Author     : Andy Kar
*/

import { CommissionSettings, Conditions, OpenTradesHistoryRow, TPaginationData, Trader, YourFollower } from "../../interfaces/CopytradeInterfaces";
import { AccountType } from "../../interfaces/StoreInterfaces";
import { Details } from "../../interfaces/myAccountsInterfaces";
import { IRebateSystemPersonalProject, IRebateSystemProject, IStatisticsItem, RebateSystemProjectStatus } from "../../interfaces/RebateSystemInterfaces";
import { ActionCreator, createAction } from "../../utils/reudxHelpers";
import { TPaginationParams } from "../../utils/types";
import { CryptoCurrencyInfoType } from "../../pages/Finance/Deposit/types";
import { WithdrawMethodInfo } from "../reducers/withdrawMethodsInfoReducer";

export const FETCH_INIT_INFO:string = "FETCH_INIT_INFO";
export const SET_INIT_INFO:string = "SET_INIT_INFO";
export const FETCH_INIT_LINK_INFO:string = "FETCH_INIT_LINK_INFO";
export const SET_INIT_LINK_INFO:string = "SET_INIT_LINK_INFO";

export const FETCH_SMART_DOLLARS_INFO:string = "FETCH_SMART_DOLLARS_INFO";
export const SET_SMART_DOLLARS_INFO:string = "SET_SMART_DOLLARS_INFO";
export const ACTIVATE_SMART_DOLLARS:string = "ACTIVATE_SMART_DOLLARS";
export const SET_ACTIVATE_SMART_DOLLARS:string = "SET_ACTIVATE_SMART_DOLLARS";
export const REQUEST_SMART_DOLLARS:string = "REQUEST_SMART_DOLLARS";
export const SET_REQUEST_SMART_DOLLARS_INFO:string = "SET_REQUEST_SMART_DOLLARS_INFO";

export const FETCH_MY_ACHIEVEMENTS_INFO:string = "FETCH_MY_ACHIEVEMENTS_INFO";
export const SET_MY_ACHIEVEMENTS_INFO:string = "SET_MY_ACHIEVEMENTS_INFO";
export const REQUEST_MY_ACHIEVEMENTS:string = "REQUEST_MY_ACHIEVEMENTS";

export const FETCH_USER_INFO:string = "FETCH_USER_INFO";
export const SET_USER_INFO:string = "SET_USER_INFO";
export const FETCH_USER_PAYMENT_METHODS_INFO:string = "FETCH_USER_PAYMENT_METHODS_INFO";
export const SET_USER_PAYMENT_METHODS_INFO:string = "SET_USER_PAYMENT_METHODS_INFO";
export const FETCH_USER_WITHDRAW_METHODS_INFO:string = "FETCH_USER_WITHDRAW_METHODS_INFO";
export const SET_USER_WITHDRAW_METHODS_INFO:string = "SET_USER_WITHDRAW_METHODS_INFO";
export const FETCH_USER_BONUSES_INFO:string = "FETCH_USER_BONUSES_INFO";
export const SET_USER_BONUSES_INFO:string = "SET_USER_BONUSES_INFO";
export const FETCH_USER_DOCS_INFO:string = "FETCH_USER_DOCS_INFO";
export const SET_USER_DOCS_INFO:string = "SET_USER_DOCS_INFO";
export const FETCH_USER_CORP_DOCS_INFO:string = "FETCH_USER_CORP_DOCS_INFO";
export const SET_USER_CORP_DOCS_INFO:string = "SET_USER_CORP_DOCS_INFO";
export const UPLOAD_USER_DOC:string = "UPLOAD_USER_DOC";
export const SET_USER_DOC_UPLOADING:string = "SET_USER_DOC_UPLOADING";

export const SET_MONITORING_LOADING:string = "SET_MONITORING_LOADING";
export const FETCH_MONITORING_TRADERS:string = "FETCH_MONITORING_TRADERS";
export const SET_MONITORING_TRADERS:string = "SET_MONITORING_TRADERS";
export const SET_PAGE_MONITORING_TRADERS:string = "SET_PAGE_MONITORING_TRADERS";
export const SET_LENGTH_MONITORING_TRADERS:string = "SET_LENGTH_MONITORING_TRADERS";
export const FETCH_PART_TRADERS:string = "FETCH_PART_TRADERS";
export const SET_PART_TRADERS:string = "SET_PART_TRADERS";
export const SET_LENGTH_TRADERS:string = "SET_LENGTH_TRADERS";

export const SET_TRADES_LOADING:string = "SET_TRADES_LOADING";
export const FETCH_OPENED_TRADES:string = "FETCH_TRADES";
export const SET_OPENED_TRADES:string = "SET_TRADES";
export const FETCH_PART_TRADES:string = "FETCH_PART_TRADES";
export const SET_PART_TRADES:string = "SET_PART_TRADES";
export const SET_LENGTH_TRADES:string = "SET_LENGTH_TRADES";
export const SET_LENGTH_LOADED_TRADES:string = "SET_LENGTH_LOADED_TRADES";

export const FETCH_BANNERS:string = "FETCH_BANNERS";
export const SET_BANNERS:string = "SET_BANNERS";
export const FETCH_BANNERS_SHOW:string = "FETCH_BANNERS_SHOW";
export const SET_BANNERS_SHOW:string = "SET_BANNERS_SHOW";

export const SET_SUBSCRIPTIONS_LOADING:string = "SET_SUBSCRIPTIONS_LOADING";
export const FETCH_SUBSCRIPTIONS_TRADERS:string = "FETCH_SUBSCRIPTIONS_TRADERS";
export const SET_SUBSCRIPTIONS_TRADERS:string = "SET_SUBSCRIPTIONS_TRADERS";
export const SET_PAGE_SUBSCRIPTIONS_TRADERS:string = "SET_PAGE_SUBSCRIPTIONS_TRADERS";
export const SET_LENGTH_SUBSCRIPTIONS_TRADERS:string = "SET_LENGTH_SUBSCRIPTIONS_TRADERS";

export const FETCH_SESSION_INFO:string = "FETCH_SESSION_INFO";
export const SET_SESSION_INFO:string = "SET_SESSION_INFO";
export const SET_SESSION_LANG:string = "SET_SESSION_LANG";
export const SET_SESSION_LOADED:string = "SET_SESSION_LOADED";

export const FETCH_LANGUAGE_INFO:string = "FETCH_LANGUAGE_INFO";
export const SET_LANGUAGE_INFO:string = "SET_LANGUAGE_INFO";

export const FETCH_LANGUAGE_COPYTRADE:string = "FETCH_LANGUAGE_COPYTRADE";
export const SET_LANGUAGE_COPYTRADE:string = "SET_LANGUAGE_COPYTRADE";

export const FETCH_LANGUAGE_MY_ACCOUNTS:string = "FETCH_LANGUAGE_MY_ACCOUNTS";
export const SET_LANGUAGE_MY_ACCOUNTS:string = "SET_LANGUAGE_MY_ACCOUNTS";
// export const FETCH_LANGUAGE_MY_TRADING:string = "FETCH_LANGUAGE_MY_TRADING";
export const SET_LANGUAGE_MY_TRADING:string = "SET_LANGUAGE_MY_TRADING";
export const FETCH_LANGUAGE_FINANCE:string = "FETCH_LANGUAGE_FINANCE";
export const SET_LANGUAGE_FINANCE:string = "SET_LANGUAGE_FINANCE";
export const FETCH_LANGUAGE_BONUS:string = "FETCH_LANGUAGE_BONUS";
export const SET_LANGUAGE_BONUS:string = "SET_LANGUAGE_BONUS";
export const FETCH_LANGUAGE_PARTNERSHIP:string = "FETCH_LANGUAGE_PARTNERSHIP";
export const SET_LANGUAGE_PARTNERSHIP:string = "SET_LANGUAGE_PARTNERSHIP";
export const FETCH_LANGUAGE_SMART_DOLLAR:string = "FETCH_LANGUAGE_SMART_DOLLAR";
export const SET_LANGUAGE_SMART_DOLLAR:string = "SET_LANGUAGE_SMART_DOLLAR";

export const FETCH_LANGUAGE_DATATABLE:string = "FETCH_LANGUAGE_DATATABLE";
export const SET_LANGUAGE_DATATABLE:string = "SET_LANGUAGE_DATATABLE";
export const FETCH_LANGUAGE_TRADING:string = "FETCH_LANGUAGE_TRADING";
export const SET_LANGUAGE_TRADING:string = "SET_LANGUAGE_TRADING";
export const FETCH_LANGUAGE_TRADES:string = "FETCH_LANGUAGE_TRADES";
export const SET_LANGUAGE_TRADES:string = "SET_LANGUAGE_TRADES";
export const FETCH_LANGUAGE_MAIL_SUPPORT:string = "FETCH_LANGUAGE_MAIL_SUPPORT";
export const SET_LANGUAGE_MAIL_SUPPORT:string = "SET_LANGUAGE_MAIL_SUPPORT";
export const FETCH_LANGUAGE_BANNERS:string = "FETCH_LANGUAGE_BANNERS";
export const SET_LANGUAGE_BANNERS:string = "SET_LANGUAGE_BANNERS";
export const CHANGE_LANGUAGE:string = "CHANGE_LANGUAGE";

export const FETCH_NOTIFICATION_INFO:string = "FETCH_NOTIFICATION_INFO";
export const SET_NOTIFICATION_INFO:string = "SET_NOTIFICATION_INFO";
export const UPDATE_NOTIFICATION:string = "UPDATE_NOTIFICATION";

export const SEND_FEEDBACK_INFO:string = "SEND_FEEDBACK_INFO";
export const SET_FEEDBACK_REQUEST_INFO:string = "SET_FEEDBACK_REQUEST_INFO";
export const SET_FEEDBACK_LOADING:string = "SET_FEEDBACK_LOADING";

export const SEND_FORGOT_PASS_INFO:string = "SEND_FORGOT_PASS_INFO";
export const SEND_RESET_PASS_INFO:string = "SEND_RESET_PASS_INFO";

export const SEND_AUTH_INFO:string = "SEND_AUTH_INFO";
export const SEND_PARTNER_AUTH_INFO:string = "SEND_PARTNER_AUTH_INFO";
export const SET_AUTH_REQUEST_INFO:string = "SET_AUTH_REQUEST_INFO";
export const SET_AUTH_LOADING:string = "SET_AUTH_LOADING";
export const SEND_LOGOUT:string = "SEND_LOGOUT";
export const SET_LOGOUT_REQUEST_INFO:string = "SET_LOGOUT_REQUEST_INFO";

export const SEND_PERSONAL_DETAILS:string = "SEND_PERSONAL_DETAILS";
export const SET_PERSONAL_DETAILS_LOADING:string = "SET_PERSONAL_DETAILS_LOADING";
export const SET_PERSONAL_DETAILS_RESPONSE:string = "SET_PERSONAL_DETAILS_RESPONSE";
export const SEND_PHONE_VALIDATE_DATA:string = "SEND_PHONE_VALIDATE_DATA";
export const SET_PHONE_VALIDATE_RESPONSE:string = "SET_PHONE_VALIDATE_RESPONSE";
export const SEND_PHONE_VERIFY_CODE:string = "SEND_PHONE_VERIFY_CODE";
export const SET_PHONE_VERIFY_CODE_RESPONSE:string = "SET_PHONE_VERIFY_CODE_RESPONSE";
export const REMOVE_AVATAR:string = "REMOVE_AVATAR";
export const CHANGE_AVATAR:string = "CHANGE_AVATAR";
export const SET_CHANGED_AVATAR:string = "SET_CHANGED_AVATAR";
export const FETCH_MY_ACCOUNTS:string = "FETCH_MY_ACCOUNTS";
export const SET_MY_ACCOUNTS_LOADING:string = "SET_MY_ACCOUNTS_LOADING";
export const SET_MY_ACCOUNTS_DATA:string = "SET_MY_ACCOUNTS_DATA";
export const SEND_UPDATE_LEVERAGE_INFO:string = "SEND_UPDATE_LEVERAGE_INFO";
export const SET_UPDATE_LEVERAGE_LOADING:string = "SET_UPDATE_LEVERAGE_LOADING";
export const SET_UPDATE_LEVERAGE_RESPONSE:string = "SET_UPDATE_LEVERAGE_RESPONSE";
export const SEND_SWAP_INFO:string = "SEND_SWAP_INFO";
export const SET_SWAP_LOADING:string = "SET_SWAP_LOADING";
export const SET_SWAP_RESPONSE:string = "SET_SWAP_RESPONSE";
export const SEND_SEND_REPORTS_INFO:string = "SEND_SEND_REPORTS_INFO";
export const SET_SEND_REPORTS_LOADING:string = "SET_SEND_REPORTS_LOADING";
export const SET_SEND_REPORTS_RESPONSE:string = "SET_SEND_REPORTS_RESPONSE";
export const SEND_SWITCH_ACCOUNT_INFO:string = "SEND_SWITCH_ACCOUNT_INFO";
export const SEND_REMOVE_ACCOUNT_INFO:string = "SEND_REMOVE_ACCOUNT_INFO";

export const SET_ACCOUNT_FUNDS_DATA:string = "SET_ACCOUNT_FUNDS_DATA";
export const FETCH_ACCOUNT_FUNDS_DATA:string = "FETCH_ACCOUNT_FUNDS_DATA";
export const SET_ACCOUNT_TRADING_DATA:string = "SET_ACCOUNT_TRADING_DATA";
export const FETCH_ACCOUNT_TRADING_DATA:string = "FETCH_ACCOUNT_TRADING_DATA";
export const SET_ACCOUNT_LOADING:string = "SET_ACCOUNT_LOADING";
export const SET_ACCOUNT_INFO:string = "SET_ACCOUNT_INFO";

export const SET_PARTNERSHIP_CLICKS_DATA:string = "SET_PARTNERSHIP_CLICKS_DATA";
export const FETCH_PARTNERSHIP_CLICKS_DATA:string = "FETCH_PARTNERSHIP_CLICKS_DATA";
export const SET_PARTNERSHIP_CLICKS_LOADING:string = "SET_PARTNERSHIP_CLICKS_LOADING";
export const SET_PARTNERSHIP_COMMISSION_DATA:string = "SET_PARTNERSHIP_COMMISSION_DATA";
export const FETCH_PARTNERSHIP_COMMISSION_DATA:string = "FETCH_PARTNERSHIP_COMMISSION_DATA";
export const SET_PARTNERSHIP_COMMISSION_LOADING:string = "SET_PARTNERSHIP_COMMISSION_LOADING";
export const SET_PARTNERSHIP_DATA:string = "SET_PARTNERSHIP_DATA";
export const FETCH_PARTNERSHIP_DATA:string = "FETCH_PARTNERSHIP_DATA";
export const SET_PARTNERSHIP_LOADING:string = "SET_PARTNERSHIP_LOADING";
export const SET_PARTNERSHIP_FILTERED_CLICKS_DATA:string = "SET_PARTNERSHIP_FILTERED_CLICKS_DATA";
export const SET_PARTNERSHIP_REFERRALS_DATA:string = "SET_PARTNERSHIP_REFERRALS_DATA";
export const FETCH_PARTNERSHIP_REFERRALS_DATA:string = "FETCH_PARTNERSHIP_REFERRALS_DATA";
export const SET_PARTNERSHIP_REFERRALS_LOADING:string = "SET_PARTNERSHIP_REFERRALS_LOADING";
export const SET_PARTNERSHIP_REFERRALS_REGISTRATION_DATA:string = "SET_PARTNERSHIP_REFERRALS_REGISTRATION_DATA";
export const FETCH_PARTNERSHIP_REFERRALS_REGISTRATION_DATA:string = "FETCH_PARTNERSHIP_REFERRALS_REGISTRATION_DATA";
export const SET_PARTNERSHIP_REFERRALS_REGISTRATION_LOADING:string = "SET_PARTNERSHIP_REFERRALS_REGISTRATION_LOADING";
export const SET_PARTNERSHIP_SECURITY_CODE_DATA:string = "SET_PARTNERSHIP_SECURITY_CODE_DATA";
export const FETCH_PARTNERSHIP_SECURITY_CODE_DATA:string = "FETCH_PARTNERSHIP_SECURITY_CODE_DATA";
export const SET_PARTNERSHIP_SECURITY_CODE_LOADING:string = "SET_PARTNERSHIP_SECURITY_CODE_LOADING";

export const FETCH_PANDING_TRANSACTIONS:string = "FETCH_PANDING_TRANSACTIONS";
export const SET_PANDING_TRANSACTIONS:string = "SET_PANDING_TRANSACTIONS";
export const FETCH_BALANCE_OPERATIONS:string = "FETCH_BALANCE_OPERATIONS";
export const SET_BALANCE_OPERATIONS:string = "SET_BALANCE_OPERATIONS";

export const SET_LAYOUT_TITLE:string = "SET_LAYOUT_TITLE";
export const SET_PAGE_TITLE:string = "SET_PAGE_TITLE";

export const FETCH_USER_MAILS:string = "FETCH_USER_MAILS";
export const SET_USER_MAILS:string = "SET_USER_MAILS";

export const GET_PROFILE_INFORMATION = "GET_PROFILE_INFORMATION";
export const SET_PROFILE_INFORMATION: string = "SET_PROFILE_INFORMATION";

export const SET_CPY_AVATAR = "SET_CPY_AVATAR";
export const createSetCpyAvatarAction = createAction<{ mt_accounts_cpy_avatar: string }>(SET_CPY_AVATAR);

export const UPDATE_PROFILE_DETAILS = "UPDATE_PROFILE_DETAILS";
export const createUpdateProfileDetailsAction = createAction<Details>(UPDATE_PROFILE_DETAILS);

export const GET_MY_PROJECT = "GET_MY_PROJECT";
export const SET_MY_PROJECT = "SET_MY_PROJECT";

export const UNSUBSCRIBE_YOUR_FOLLOWER = "UNSUBSCRIBE_YOUR_FOLLOWER";
export const APPROVE_YOUR_FOLLOWER_SUBSCRIPTION = "APPROVE_YOUR_FOLLOWER_SUBSCRIPTION";
export const GET_MY_SUBSCRIPTION_FOLLOWERS = "GET_MY_SUBSCRIPTION_FOLLOWERS";
export const SET_MY_SUBSCRIPTIONS_YOUR_FOLLOWERS_FETCH = "SET_MY_SUBSCRIPTIONS_YOUR_FOLLOWERS_FETCH";
export const SET_MY_SUBSCRIPTION_FOLLOWERS_PAGINATION = "SET_MY_SUBSCRIPTION_FOLLOWERS_PAGINATION";
export const SET_MY_SUBSCRIPTION_YOUR_FOLLOWERS = "SET_MY_SUBSCRIPTION_YOUR_FOLLOWERS";
export const unsubscribeYourFollower = createAction<number>(UNSUBSCRIBE_YOUR_FOLLOWER);
export const approveYourFollowerSubscription = createAction<number>(APPROVE_YOUR_FOLLOWER_SUBSCRIPTION);
export const setYourFollowersFetch = createAction<boolean>(SET_MY_SUBSCRIPTIONS_YOUR_FOLLOWERS_FETCH);
export const getMySubscriptionsFollowers = createAction<TPaginationData>(GET_MY_SUBSCRIPTION_FOLLOWERS);
export const setFollowersPagination = createAction<TPaginationData>(SET_MY_SUBSCRIPTION_FOLLOWERS_PAGINATION);
export const setFollowersData = createAction<YourFollower[]>(SET_MY_SUBSCRIPTION_YOUR_FOLLOWERS);

export const GET_COMMISSION_SETTINGS = "GET_COMMISSION_SETTINGS";
export const SET_COMMISSION_SETTINGS = "SET_COMMISSION_SETTINGS";
export const CLEAN_MY_SUBSCRIPTION_TRADERS = "CLEAN_MY_SUBSCRIPTION_TRADERS";
export const UNSUBSCRIBE_TRADER_BY_CONNECTION_ID = "UNSUBSCRIBE_TRADER_BY_CONNECTION_ID";
export const GET_MY_SUBSCRIPTION_TRADERS = "GET_MY_SUBSCRIPTION_TRADERS";
export const SET_MY_SUBSCRIPTION_TRADERS_FETCH = "SET_MY_SUBSCRIPTION_TRADERS_FETCH";
export const SET_MY_SUBSCRIPTION_TRADER_PAGINATION = "SET_MY_SUBSCRIPTION_TRADER_PAGINATION";
export const SET_MY_SUBSCRIPTION_TRADERS = "SET_MY_SUBSCRIPTION_TRADERS";
export const getCommissionSettings = createAction<{ connectionId: number }>(GET_COMMISSION_SETTINGS);
export const setCommissionSettings = createAction<{ connectionId: number, commissionSettings: CommissionSettings}>(SET_COMMISSION_SETTINGS);
export const cleanMySubscriptionTraders = createAction(CLEAN_MY_SUBSCRIPTION_TRADERS);
export const unsubscribeTraderByConnectionId = createAction<number>(UNSUBSCRIBE_TRADER_BY_CONNECTION_ID);
export const setTradersFetch = createAction<boolean>(SET_MY_SUBSCRIPTION_TRADERS_FETCH);
export const getMySubscriptionsTraders = createAction<TPaginationData>(GET_MY_SUBSCRIPTION_TRADERS);
export const setTraderPagination = createAction<TPaginationData>(SET_MY_SUBSCRIPTION_TRADER_PAGINATION);
export const setTradersData = createAction<Trader[]>(SET_MY_SUBSCRIPTION_TRADERS);

export const GET_MY_SUBSCRIPTION_PAST_SUBSCRIPTIONS = "GET_MY_SUBSCRIPTION_PAST_SUBSCRIPTIONS";
export const SET_MY_SUBSCRIPTION_PAST_SUBSCRIPTIONS_FETCH = "SET_MY_SUBSCRIPTION_PAST_SUBSCRIPTIONS_FETCH";
export const SET_MY_SUBSCRIPTION_PAST_SUBSCRIPTIONS_PAGINATION = "SET_MY_SUBSCRIPTION_PAST_SUBSCRIPTIONS_PAGINATION";
export const SET_MY_SUBSCRIPTION_PAST_SUBSCRIPTIONS = "SET_MY_SUBSCRIPTION_PAST_SUBSCRIPTIONS";
export const setPastSubscriptionsFetch = createAction<boolean>(SET_MY_SUBSCRIPTION_PAST_SUBSCRIPTIONS_FETCH);
export const getMySubscriptionsPastSubscriptions = createAction<TPaginationData>(GET_MY_SUBSCRIPTION_PAST_SUBSCRIPTIONS);
export const setPastSubscriptionsPagination = createAction<TPaginationData>(SET_MY_SUBSCRIPTION_PAST_SUBSCRIPTIONS_PAGINATION);
export const setPastSubscriptionsData = createAction<any[]>(SET_MY_SUBSCRIPTION_PAST_SUBSCRIPTIONS);

export const GET_MY_SUBSCRIPTIONS_CONDITIONS = "GET_MY_SUBSCRIPTIONS_CONDITIONS";
export const SET_MY_SUBSCRIPTIONS_CONDITIONS = "SET_MY_SUBSCRIPTIONS_CONDITIONS";
export const getMySubscriptionsConditions = createAction<{ connectionId: number }>(GET_MY_SUBSCRIPTIONS_CONDITIONS);
export const setMySubscriptionsConditions = createAction<{connectionId: number, data: Conditions}>(SET_MY_SUBSCRIPTIONS_CONDITIONS);

export const SHOW_SETTINGS = "EXPANDED_ROW/SHOW_SETTINGS";
export const showSettings = createAction(SHOW_SETTINGS);

export const SHOW_TRADES_HISTORY = "EXPANDED_ROW/SHOW_TRADES_HISTORY";
export const showTradesHistory = createAction(SHOW_TRADES_HISTORY);

export const GET_OPEN_TRADES_HISTORY = "GET_OPEN_TRADES_HISTORY";
export const COMPLETE_OPEN_TRADES_HISTORY = "COMPLETE_OPEN_TRADES_HISTORY";
export const SET_OPEN_TRADES_PAGINATION = "SET_OPEN_TRADES_PAGINATION";
export const getOpenTradesHistory = createAction<{ connectionId: number, start: number, limit: number }>(GET_OPEN_TRADES_HISTORY);
export const completeOpenTradesHistory = createAction<{ connectionId: number, data: OpenTradesHistoryRow[] }>(COMPLETE_OPEN_TRADES_HISTORY);
export const setOpenTradesPagination = createAction<{ connectionId: number, data: TPaginationData }>(SET_OPEN_TRADES_PAGINATION);

export const GET_CLOSE_TRADES_HISTORY = "GET_CLOSE_TRADES_HISTORY";
export const COMPLETE_CLOSE_TRADES_HISTORY = "COMPLETE_CLOSE_TRADES_HISTORY";
export const SET_CLOSE_TRADES_PAGINATION = "SET_CLOSE_TRADES_PAGINATION";
export const UPDATE_TRADER_SETTINGS = "UPDATE_TRADER_SETTINGS";
export const updateTraderSettings = createAction<{ connectionId: number, conditions: Conditions }>(UPDATE_TRADER_SETTINGS);
export const getCloseTradesHistory = createAction<{ connectionId: number, start: number, limit: number }>(GET_CLOSE_TRADES_HISTORY);
export const completeCloseTradesHistory = createAction<{ connectionId: number, data: OpenTradesHistoryRow[] }>(COMPLETE_CLOSE_TRADES_HISTORY);
export const setCloseTradesPagination = createAction<{ connectionId: number, data: TPaginationData }>(SET_CLOSE_TRADES_PAGINATION);

export const SET_ACCOUNT_TYPE = "SET_ACCOUNT_TYPE";
export const setAccountType = createAction<AccountType>(SET_ACCOUNT_TYPE);

export const UPDATE_TRADER_SUBSCRIPTION_STATUS = "UPDATE_TRADER_SUBSCRIPTION_STATUS";
export enum SubscriptionStatus {
	SUBSCRIBED = 1,
	UNSUBSCRIBED = 0,
}
export const updateTraderSubscriptionStatus = createAction<{ userId: number, status?: SubscriptionStatus }>(UPDATE_TRADER_SUBSCRIPTION_STATUS);

// rebate system action for getting data
export const GET_REBATE_SYSTEM_PROJECTS = "GET_REBATE_SYSTEM_PROJECTS";
export const SET_REBATE_SYSTEM_PROJECTS = "SET_REBATE_SYSTEM_PROJECTS";
export const GET_REBATE_SYSTEM_REFERRALS_LIST = "GET_REBATE_SYSTEM_REFERRALS_LIST";
export const SET_REBATE_SYSTEM_REFERRALS_LIST = "SET_REBATE_SYSTEM_REFERRALS_LIST";
export const GET_REBATE_SYSTEM_PERSONAL_PROJECTS = "GET_REBATE_SYSTEM_PERSONAL_PROJECTS";
export const SET_REBATE_SYSTEM_PERSONAL_PROJECTS = "SET_REBATE_SYSTEM_PERSONAL_PROJECTS";
export const SET_REBATE_SYSTEM_PROJECTS_IS_LOAD = "SET_REBATE_SYSTEM_PROJECTS_IS_LOAD";
export const SET_REBATE_SYSTEM_PERSONAL_PROJECTS_IS_LOAD = "SET_REBATE_SYSTEM_PERSONAL_PROJECTS_IS_LOAD";
export const SET_REBATE_SYSTEM_PROJECTS_PAGE = "SET_REBATE_SYSTEM_PROJECTS_PAGE";
export const SET_REBATE_SYSTEM_PERSONAL_PROJECTS_PAGE = "SET_REBATE_SYSTEM_PERSONAL_PROJECTS_PAGE";
export const getRebateSystemProjects = createAction<TPaginationParams & { actionAfterGettingProjects:() => void }>(GET_REBATE_SYSTEM_PROJECTS);
export const setRebateSystemProjects = createAction<{ list?: IRebateSystemProject[], count: number }>(SET_REBATE_SYSTEM_PROJECTS);
export const getRebateSystemReferrals = createAction(GET_REBATE_SYSTEM_REFERRALS_LIST);
export const setRebateSystemReferrals = createAction<number[]>(SET_REBATE_SYSTEM_REFERRALS_LIST);
export const getRebateSystemPersonalProjects = createAction<TPaginationParams & { actionAfterGettingProjects:() => void }>(GET_REBATE_SYSTEM_PERSONAL_PROJECTS);
export const setRebateSystemPersonalProjects = createAction<{ list?: IRebateSystemPersonalProject[], count: number }>(SET_REBATE_SYSTEM_PERSONAL_PROJECTS);
export const setRebateSystemProjectsIsLoad = createAction<{ isLoad: boolean }>(SET_REBATE_SYSTEM_PROJECTS_IS_LOAD);
export const setRebateSystemPersonalProjectsIsLoad = createAction<{ isLoad: boolean }>(SET_REBATE_SYSTEM_PERSONAL_PROJECTS_IS_LOAD);
export const setRebateSystemProjectsPage = createAction<number>(SET_REBATE_SYSTEM_PROJECTS_PAGE);
export const setRebateSystemPersonalProjectsPage = createAction<number>(SET_REBATE_SYSTEM_PERSONAL_PROJECTS_PAGE);

// rebate system action for changing data
export const REBATE_SYSTEM_ADD_PROJECT = "REBATE_SYSTEM_ADD_PROJECT";
export const REBATE_SYSTEM_ADD_PERSONAL_PROJECT = "REBATE_SYSTEM_ADD_PERSONAL_PROJECT";
export const REBATE_SYSTEM_UPDATE_PROJECT = "REBATE_SYSTEM_UPDATE_PROJECT";
export const REBATE_SYSTEM_REMOVE_PROJECT = "REBATE_SYSTEM_REMOVE_PROJECT";
export const REBATE_SYSTEM_UPDATE_PERSONAL_PROJECT = "REBATE_SYSTEM_UPDATE_PERSONAL_PROJECT";
export const REBATE_SYSTEM_REMOVE_PERSONAL_PROJECT = "REBATE_SYSTEM_REMOVE_PERSONAL_PROJECT";
export const addRebateSystemPersonalProject = createAction<{ client: string, rebateValue: string }>(REBATE_SYSTEM_ADD_PERSONAL_PROJECT);
export const addRebateSystemProject = createAction<{name: string, rebateValue: number }>(REBATE_SYSTEM_ADD_PROJECT);
export const updateRebateSystemProject = createAction<{ name: string, rebateValue: number, status: RebateSystemProjectStatus }>(REBATE_SYSTEM_UPDATE_PROJECT);
export const removeRebateSystemProject = createAction<{ name: string }>(REBATE_SYSTEM_REMOVE_PROJECT);
export const updateRebateSystemPersonalProject = createAction<{ client: number, rebateValue: number }>(REBATE_SYSTEM_UPDATE_PERSONAL_PROJECT);
export const removeRebateSystemPersonalProject = createAction<{ client: number }>(REBATE_SYSTEM_REMOVE_PERSONAL_PROJECT);

// rebate system statistics
export const GET_REBATE_SYSTEM_STATISTICS = "GET_REBATE_SYSTEM_STATISTICS";
export const SET_REBATE_SYSTEM_STATISTICS = "SET_REBATE_SYSTEM_STATISTICS";
export const SET_REBATE_SYSTEM_STATISTICS_FETCH = "SET_REBATE_SYSTEM_STATISTICS_FETCH";
export const getRebateSystemStatistics = createAction<{ client: number, from: string, to: string}>(GET_REBATE_SYSTEM_STATISTICS);
export const setRebateSystemStatistics = createAction<IStatisticsItem[]>(SET_REBATE_SYSTEM_STATISTICS);
export const setRebateSystemStatisticsFetch = createAction<boolean>(SET_REBATE_SYSTEM_STATISTICS_FETCH);

export const GET_TWO_FACTOR_AUTH_INFO = "GET_TWO_FACTOR_AUTH_INFO";
export const SET_TWO_FACTOR_AUTH_INFO = "SET_TWO_FACTOR_AUTH_INFO";
export const SET_TWO_FACTOR_AUTH_INFO_LOADING = "SET_TWO_FACTOR_AUTH_INFO_LOADING";
export const SET_REDIRECT_TO_TFA = "SET_REDIRECT_TO_TFA";
export const VERIFY_TFA = "VERIFY_TFA";
export const SET_TFA_ENABLED = "SET_TFA_ENABLED";
export const getTwoFactorAuthInfo = createAction<{}>(GET_TWO_FACTOR_AUTH_INFO);
export const setTwoFactorAuthInfo = createAction<{ secret: string, QRCode: string }>(SET_TWO_FACTOR_AUTH_INFO);
export const setTwoFactorAuthInfoLoading = createAction<boolean>(SET_TWO_FACTOR_AUTH_INFO_LOADING);
export const setRedirectToTFA = createAction<boolean>(SET_REDIRECT_TO_TFA);
export const verifyTFA = createAction<{ code: string }>(VERIFY_TFA);
export const setTfaEnabled = createAction<boolean | undefined>(SET_TFA_ENABLED);

// Crypto currency deposit status
export const SET_BITCOIN_STATUS_INFO = "SET_BITCOIN_STATUS_INFO";
export const SET_BITCOIN_CASH_STATUS_INFO = "SET_BITCOIN_CASH_STATUS_INFO";
export const SET_TETHER_STATUS_INFO = "SET_TETHER_STATUS_INFO";
export const UPDATE_BITCOIN_STATUS = "UPDATE_BITCOIN_STATUS";
export const UPDATE_BITCOIN_CASH_STATUS = "UPDATE_BITCOIN_CASH_STATUS";
export const UPDATE_TETHER_STATUS = "UPDATE_TETHER_STATUS";
export const GET_CRYPTOCURRENCY_STATUS = "GET_CRYPTOCURRENCY_STATUS";
export const setBitcoinStatusInfo = createAction<CryptoCurrencyInfoType & { amount: string }>(SET_BITCOIN_STATUS_INFO);
export const setBitcoinCashStatusInfo = createAction<CryptoCurrencyInfoType & { amount: string }>(SET_BITCOIN_CASH_STATUS_INFO);
export const setTetherStatusInfo = createAction<{address: string, orderId: string, createDate: string, amount: string}>(SET_TETHER_STATUS_INFO);
export const updateBitcoinStatus = createAction<{ status: number }>(UPDATE_BITCOIN_STATUS);
export const updateBitcoinCashStatus = createAction<{ status: number }>(UPDATE_BITCOIN_CASH_STATUS);
export const updateTetherStatus = createAction<{ status: number }>(UPDATE_TETHER_STATUS);
export const getCryptocurrencyStatus = createAction<{ orderId: number, currency: "btc" | "bch" }>(GET_CRYPTOCURRENCY_STATUS);

// bonuses
export const GET_BONUSES_BY_DATE = "GET_BONUSES_BY_DATE";
export const SET_BONUSES_BY_DATE = "SET_BONUSES_BY_DATE";
export const SET_BONUS_LOADING = "SET_BONUS_LOADING";
export const getBonusesByDate = createAction<{ from?: "string", to?: "string" }>(GET_BONUSES_BY_DATE);
export const setBonusesByDate = createAction<{ data:({ stamp: string, amount: number})[] }>(SET_BONUSES_BY_DATE);
export const setBonusLoading = createAction<boolean>(SET_BONUS_LOADING);

// getting withdrawal methods info
export const {
	SET_BITCOIN_INFO,
	SET_BITCOIN_INFO_LOADING,
	FETCH_BITCOIN_INFO,
	setBitcoinInfo,
	setBitcoinInfoLoading,
	fetchBitcoinInfo
} = createGettingWithdrawMethodsInfoActions("BITCOIN", "Bitcoin");

export const {
	SET_BITCOIN_CASH_INFO,
	SET_BITCOIN_CASH_INFO_LOADING,
	FETCH_BITCOIN_CASH_INFO,
	setBitcoinCashInfo,
	setBitcoinCashInfoLoading,
	fetchBitcoinCashInfo
} = createGettingWithdrawMethodsInfoActions("BITCOIN_CASH", "BitcoinCash");

export const {
	SET_MYR_PAY_TRUST_INFO,
	SET_MYR_PAY_TRUST_INFO_LOADING,
	FETCH_MYR_PAY_TRUST_INFO,
	setMyrPayTrustInfo,
	setMyrPayTrustInfoLoading,
	fetchMyrPayTrustInfo
} = createGettingWithdrawMethodsInfoActions("MYR_PAY_TRUST", "MyrPayTrust");

export const {
	SET_THB_PAY_TRUST_INFO,
	SET_THB_PAY_TRUST_INFO_LOADING,
	FETCH_THB_PAY_TRUST_INFO,
	setThbPayTrustInfo,
	setThbPayTrustInfoLoading,
	fetchThbPayTrustInfo
} = createGettingWithdrawMethodsInfoActions("THB_PAY_TRUST", "ThbPayTrust");

export const {
	SET_IDR_PAY_TRUST_INFO,
	SET_IDR_PAY_TRUST_INFO_LOADING,
	FETCH_IDR_PAY_TRUST_INFO,
	setIdrPayTrustInfo,
	setIdrPayTrustInfoLoading,
	fetchIdrPayTrustInfo
} = createGettingWithdrawMethodsInfoActions("IDR_PAY_TRUST", "IdrPayTrust");

export const {
	SET_MYR_ZOTA_PAY_INFO,
	SET_MYR_ZOTA_PAY_INFO_LOADING,
	FETCH_MYR_ZOTA_PAY_INFO,
	setMyrZotaPayInfo,
	setMyrZotaPayInfoLoading,
	fetchMyrZotaPayInfo
} = createGettingWithdrawMethodsInfoActions("MYR_ZOTA_PAY", "MyrZotaPay");

export const {
	SET_THB_ZOTA_PAY_INFO,
	SET_THB_ZOTA_PAY_INFO_LOADING,
	FETCH_THB_ZOTA_PAY_INFO,
	setThbZotaPayInfo,
	setThbZotaPayInfoLoading,
	fetchThbZotaPayInfo
} = createGettingWithdrawMethodsInfoActions("THB_ZOTA_PAY", "ThbZotaPay");

export const {
	SET_IDR_ZOTA_PAY_INFO,
	SET_IDR_ZOTA_PAY_INFO_LOADING,
	FETCH_IDR_ZOTA_PAY_INFO,
	setIdrZotaPayInfo,
	setIdrZotaPayInfoLoading,
	fetchIdrZotaPayInfo
} = createGettingWithdrawMethodsInfoActions("IDR_ZOTA_PAY", "IdrZotaPay");

export const {
	SET_ASIAN_BANKS_INFO,
	SET_ASIAN_BANKS_INFO_LOADING,
	FETCH_ASIAN_BANKS_INFO,
	setAsianBanksInfo,
	setAsianBanksInfoLoading,
	fetchAsianBanksInfo
} = createGettingWithdrawMethodsInfoActions("ASIAN_BANKS", "AsianBanks");

export const {
	SET_MOBILE_MONEY_TIGO_INFO,
	SET_MOBILE_MONEY_TIGO_INFO_LOADING,
	FETCH_MOBILE_MONEY_TIGO_INFO,
	setMobileMoneyTigoInfo,
	setMobileMoneyTigoInfoLoading,
	fetchMobileMoneyTigoInfo
} = createGettingWithdrawMethodsInfoActions("MOBILE_MONEY_TIGO", "MobileMoneyTigo");

export const {
	SET_MOBILE_MONEY_AIRTEL_INFO,
	SET_MOBILE_MONEY_AIRTEL_INFO_LOADING,
	FETCH_MOBILE_MONEY_AIRTEL_INFO,
	setMobileMoneyAirtelInfo,
	setMobileMoneyAirtelInfoLoading,
	fetchMobileMoneyAirtelInfo
} = createGettingWithdrawMethodsInfoActions("MOBILE_MONEY_AIRTEL", "MobileMoneyAirtel");

export const {
	SET_MOBILE_MONEY_MTN_INFO,
	SET_MOBILE_MONEY_MTN_INFO_LOADING,
	FETCH_MOBILE_MONEY_MTN_INFO,
	setMobileMoneyMtnInfo,
	setMobileMoneyMtnInfoLoading,
	fetchMobileMoneyMtnInfo
} = createGettingWithdrawMethodsInfoActions("MOBILE_MONEY_MTN", "MobileMoneyMtn");

export const {
	SET_MOBILE_MONEY_VODACOM_INFO,
	SET_MOBILE_MONEY_VODACOM_INFO_LOADING,
	FETCH_MOBILE_MONEY_VODACOM_INFO,
	setMobileMoneyVodacomInfo,
	setMobileMoneyVodacomInfoLoading,
	fetchMobileMoneyVodacomInfo
} = createGettingWithdrawMethodsInfoActions("MOBILE_MONEY_VODACOM", "MobileMoneyVodacom");

export const {
	SET_MOBILE_MONEY_VODAFONE_INFO,
	SET_MOBILE_MONEY_VODAFONE_INFO_LOADING,
	FETCH_MOBILE_MONEY_VODAFONE_INFO,
	setMobileMoneyVodafoneInfo,
	setMobileMoneyVodafoneInfoLoading,
	fetchMobileMoneyVodafoneInfo
} = createGettingWithdrawMethodsInfoActions("MOBILE_MONEY_VODAFONE", "MobileMoneyVodafone");

export const {
	SET_MOBILE_MONEY_MPESA_INFO,
	SET_MOBILE_MONEY_MPESA_INFO_LOADING,
	FETCH_MOBILE_MONEY_MPESA_INFO,
	setMobileMoneyMpesaInfo,
	setMobileMoneyMpesaInfoLoading,
	fetchMobileMoneyMpesaInfo
} = createGettingWithdrawMethodsInfoActions("MOBILE_MONEY_MPESA", "MobileMoneyMpesa");

export const {
	SET_MOBILE_MONEY_ZAMTEL_INFO,
	SET_MOBILE_MONEY_ZAMTEL_INFO_LOADING,
	FETCH_MOBILE_MONEY_ZAMTEL_INFO,
	setMobileMoneyZamtelInfo,
	setMobileMoneyZamtelInfoLoading,
	fetchMobileMoneyZamtelInfo
} = createGettingWithdrawMethodsInfoActions("MOBILE_MONEY_ZAMTEL", "MobileMoneyZamtel");

export const {
	SET_MOBILE_MONEY_HALOPESA_INFO,
	SET_MOBILE_MONEY_HALOPESA_INFO_LOADING,
	FETCH_MOBILE_MONEY_HALOPESA_INFO,
	setMobileMoneyHalopesaInfo,
	setMobileMoneyHalopesaInfoLoading,
	fetchMobileMoneyHalopesaInfo
} = createGettingWithdrawMethodsInfoActions("MOBILE_MONEY_HALOPESA", "MobileMoneyHalopesa");

export const {
	SET_MOBILE_MONEY_ORANGE_INFO,
	SET_MOBILE_MONEY_ORANGE_INFO_LOADING,
	FETCH_MOBILE_MONEY_ORANGE_INFO,
	setMobileMoneyOrangeInfo,
	setMobileMoneyOrangeInfoLoading,
	fetchMobileMoneyOrangeInfo
} = createGettingWithdrawMethodsInfoActions("MOBILE_MONEY_ORANGE", "MobileMoneyOrange");

export const {
	SET_MOBILE_MONEY_EU_MOBILE_INFO,
	SET_MOBILE_MONEY_EU_MOBILE_INFO_LOADING,
	FETCH_MOBILE_MONEY_EU_MOBILE_INFO,
	setMobileMoneyEuMobileInfo,
	setMobileMoneyEuMobileInfoLoading,
	fetchMobileMoneyEuMobileInfo
} = createGettingWithdrawMethodsInfoActions("MOBILE_MONEY_EU_MOBILE", "MobileMoneyEuMobile");

export const {
	SET_BRAZIL_PIX_INFO,
	SET_BRAZIL_PIX_INFO_LOADING,
	FETCH_BRAZIL_PIX_INFO,
	setBrazilPixInfo,
	setBrazilPixInfoLoading,
	fetchBrazilPixInfo
} = createGettingWithdrawMethodsInfoActions("BRAZIL_PIX", "BrazilPix");

export const {
	SET_ONLINE_NAIRA_INFO,
	SET_ONLINE_NAIRA_INFO_LOADING,
	FETCH_ONLINE_NAIRA_INFO,
	setOnlineNairaInfo,
	setOnlineNairaInfoLoading,
	fetchOnlineNairaInfo
} = createGettingWithdrawMethodsInfoActions("ONLINE_NAIRA", "OnlineNaira");

export const {
	SET_CHINA_UNION_PAY_INFO,
	SET_CHINA_UNION_PAY_INFO_LOADING,
	FETCH_CHINA_UNION_PAY_INFO,
	setChinaUnionPayInfo,
	setChinaUnionPayInfoLoading,
	fetchChinaUnionPayInfo
} = createGettingWithdrawMethodsInfoActions("CHINA_UNION_PAY", "ChinaUnionPay");

export const {
	SET_FASAPAY_INFO,
	SET_FASAPAY_INFO_LOADING,
	FETCH_FASAPAY_INFO,
	setFasapayInfo,
	setFasapayInfoLoading,
	fetchFasapayInfo
} = createGettingWithdrawMethodsInfoActions("FASAPAY", "Fasapay");

export const {
	SET_LOCAL_BANK_INFO,
	SET_LOCAL_BANK_INFO_LOADING,
	FETCH_LOCAL_BANK_INFO,
	setLocalBankInfo,
	setLocalBankInfoLoading,
	fetchLocalBankInfo
} = createGettingWithdrawMethodsInfoActions("LOCAL_BANK", "LocalBank");

export const {
	SET_LOCAL_BANK_TRANSFER_MEXICO_INFO,
	SET_LOCAL_BANK_TRANSFER_MEXICO_INFO_LOADING,
	FETCH_LOCAL_BANK_TRANSFER_MEXICO_INFO,
	setLocalBankTransferMexicoInfo,
	setLocalBankTransferMexicoInfoLoading,
	fetchLocalBankTransferMexicoInfo
} = createGettingWithdrawMethodsInfoActions("LOCAL_BANK_TRANSFER_MEXICO", "LocalBankTransferMexico");

export const {
	SET_NETELLER_INFO,
	SET_NETELLER_INFO_LOADING,
	FETCH_NETELLER_INFO,
	setNetellerInfo,
	setNetellerInfoLoading,
	fetchNetellerInfo
} = createGettingWithdrawMethodsInfoActions("NETELLER", "Neteller");

export const {
	SET_PAYCO_INFO,
	SET_PAYCO_INFO_LOADING,
	FETCH_PAYCO_INFO,
	setPaycoInfo,
	setPaycoInfoLoading,
	fetchPaycoInfo
} = createGettingWithdrawMethodsInfoActions("PAYCO", "Payco");

export const {
	SET_SKRILL_INFO,
	SET_SKRILL_INFO_LOADING,
	FETCH_SKRILL_INFO,
	setSkrillInfo,
	setSkrillInfoLoading,
	fetchSkrillInfo
} = createGettingWithdrawMethodsInfoActions("SKRILL", "Skrill");

export const {
	SET_THUNDER_X_PAY_INFO,
	SET_THUNDER_X_PAY_INFO_LOADING,
	FETCH_THUNDER_X_PAY_INFO,
	setThunderXPayInfo,
	setThunderXPayInfoLoading,
	fetchThunderXPayInfo
} = createGettingWithdrawMethodsInfoActions("THUNDER_X_PAY", "ThunderXPay");

export const {
	SET_VND_ZOTA_PAY_INFO,
	SET_VND_ZOTA_PAY_INFO_LOADING,
	FETCH_VND_ZOTA_PAY_INFO,
	setVndZotaPayInfo,
	setVndZotaPayInfoLoading,
	fetchVndZotaPayInfo
} = createGettingWithdrawMethodsInfoActions("VND_ZOTA_PAY", "VndZotaPay");

export const {
	SET_VND_PAY_TRUST_INFO,
	SET_VND_PAY_TRUST_INFO_LOADING,
	FETCH_VND_PAY_TRUST_INFO,
	setVndPayTrustInfo,
	setVndPayTrustInfoLoading,
	fetchVndPayTrustInfo
} = createGettingWithdrawMethodsInfoActions("VND_PAY_TRUST", "VndPayTrust");

export const {
	SET_VN_PAY_INFO,
	SET_VN_PAY_INFO_LOADING,
	FETCH_VN_PAY_INFO,
	setVnPayInfo,
	setVnPayInfoLoading,
	fetchVnPayInfo
} = createGettingWithdrawMethodsInfoActions("VN_PAY", "VnPay");

export const {
	SET_TETHER_INFO,
	SET_TETHER_INFO_LOADING,
	FETCH_TETHER_INFO,
	setTetherInfo,
	setTetherInfoLoading,
	fetchTetherInfo
} = createGettingWithdrawMethodsInfoActions("TETHER", "Tether");

type TGettingWithdrowMethidsInfoActionDynamicReturns<T extends string, V extends string> = {
	[p in `SET_${T}_INFO`]: `SET_${T}_INFO`;
} & {
	[p in `SET_${T}_INFO_LOADING`]: `SET_${T}_INFO_LOADING`
} & {
	[p in `FETCH_${T}_INFO`]: `FETCH_${T}_INFO`
} & {
	[p in `set${V}Info`]: ActionCreator<WithdrawMethodInfo>
} & {
	[p in `set${V}InfoLoading`]: ActionCreator<boolean>
} & {
	[p in `fetch${V}Info`]: ActionCreator<{}>
}

function createGettingWithdrawMethodsInfoActions <T extends string, V extends string> (actionName: T, actionCreatorName: V): TGettingWithdrowMethidsInfoActionDynamicReturns<T, V> {
	return {
		[`SET_${actionName}_INFO`]: `SET_${actionName}_INFO`,
		[`SET_${actionName}_INFO_LOADING`]: `SET_${actionName}_INFO_LOADING`,
		[`FETCH_${actionName}_INFO`]: `FETCH_${actionName}_INFO`,
		[`set${actionCreatorName}Info`]: createAction<WithdrawMethodInfo>(`SET_${actionName}_INFO`),
		[`set${actionCreatorName}InfoLoading`]: createAction<boolean>(`SET_${actionName}_INFO_LOADING`),
		[`fetch${actionCreatorName}Info`]: createAction(`FETCH_${actionName}_INFO`)
	} as TGettingWithdrowMethidsInfoActionDynamicReturns<T, V>;
};
