import React from "react";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { CSVLink } from "react-csv";
import { IFMTableRowObj, IHeadCell } from "./formatDataFMTable";
// import { LabelKeyObject } from "react-csv/components/CommonPropTypes";

const buttonSX = {
	marginX: "2.5px",
	textTransform: "capitalize",
	width: "max-content",
	minHeight: "42px",
	paddingY: 0,
	mb: "15px"
};

interface IFMTablePeriodActions<T, M> {
	rows: Array<IFMTableRowObj<T, M>>
	headCells: Array<IHeadCell<T>>
	withApplyButton: boolean
	withChart: boolean
	withExport: boolean
	toggleShowChart: () => void
}

export default function FMTablePeriodActions<T, M> (props: IFMTablePeriodActions<T, M>) {
	const { withApplyButton, withChart, toggleShowChart, withExport, rows, headCells } = props;

	const getHeaders = () => {
		return headCells.map((item) => {
			return {
				label: item.label?.replace(/['"]+/g, ""),
				key: item.id
			} as any;
		});
	};
	const headers = getHeaders() || [];
	return (
		<Box className="flexRow">
			{withApplyButton && <Button
				type="submit"
				variant="contained"
				color="success"
				onClick={() => {}}
				sx={buttonSX}
			>Apply </Button>}
			{withExport && <Button
				type="button"
				variant="contained"
				color="secondary"
				onClick={() => {}}
				sx={buttonSX}
			>
				<CSVLink
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						width: "100%",
						height: "100%"
					}}
					filename={"table.csv"}
					data={rows}
					headers={headers}
					separator=";"
				>
					<Typography sx={{ color: "#fff" }}>Export</Typography>
				</CSVLink>
			</Button>}
			{withChart && <Button
				type="button"
				variant="contained"
				color="primary"
				onClick={toggleShowChart}
				sx={buttonSX}
			>Show chart</Button>}
		</Box>
	);
};
