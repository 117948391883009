/*
    Created on : 13.10.2021, 06:18:59
    Author     : Andy Kar
*/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./mainStyles/Main.scss";
import { SessionUserDataInterface, StoreInterface } from "../../interfaces/StoreInterfaces";
import {
	FETCH_LANGUAGE_BONUS,
	FETCH_LANGUAGE_COPYTRADE,
	FETCH_LANGUAGE_FINANCE,
	FETCH_LANGUAGE_MY_ACCOUNTS,
	FETCH_LANGUAGE_TRADING,
	FETCH_LANGUAGE_PARTNERSHIP,
	// FETCH_NOTIFICATION_INFO,
	FETCH_USER_INFO,
	FETCH_INIT_INFO,
	FETCH_ACCOUNT_FUNDS_DATA,
	FETCH_LANGUAGE_DATATABLE,
	FETCH_ACCOUNT_TRADING_DATA,
	FETCH_LANGUAGE_TRADES,
	FETCH_LANGUAGE_MAIL_SUPPORT,
	FETCH_USER_DOCS_INFO,
	FETCH_USER_CORP_DOCS_INFO,
	FETCH_LANGUAGE_BANNERS, FETCH_SMART_DOLLARS_INFO, FETCH_MY_ACHIEVEMENTS_INFO
} from "../../store/actions/actionTypes";
import { getLocalStorageItem } from "../../utils/LocalStorage";
import { useIsRtl } from "../../utils/hooks/useIsRtl";
import Layout from "./Layout";
import { useHistory } from "react-router-dom";
import API from "../../utils/API";
import { FMAlert } from "../../components/Form/Swal/FMAlert";
import { useAppSelector } from "../../utils/hooks/reduxHooks";
import { IInitLanguage } from "../../interfaces/LanguageInterface";
import axios from "axios";

export default function MainLayout () {
	const session: SessionUserDataInterface = useSelector((state:StoreInterface) => state.session.userData);
	const siteLang: string|undefined = useSelector((state:StoreInterface) => state.session.userData.site_lang);
	const languageInit: IInitLanguage = useAppSelector(state => state.language.language);
	const sessionId = getLocalStorageItem("ci_session");
	const auth: any = useSelector((state:StoreInterface) => state.auth.auth);

	const dispatch = useDispatch();
	useEffect(() => {
		const ac = new AbortController();
		if (session?.account_number && sessionId && !session?.neededTFA) {
			dispatch({ type: FETCH_USER_INFO });
			dispatch({ type: FETCH_USER_DOCS_INFO });
			dispatch({ type: FETCH_USER_CORP_DOCS_INFO });
			dispatch({ type: FETCH_INIT_INFO });
			dispatch({ type: FETCH_ACCOUNT_FUNDS_DATA });
			dispatch({ type: FETCH_ACCOUNT_TRADING_DATA });
			dispatch({ type: FETCH_SMART_DOLLARS_INFO });
			dispatch({ type: FETCH_MY_ACHIEVEMENTS_INFO });
		}
		return () => ac.abort(); // Abort both fetches on unmount
	}, [ session.account_number, session?.neededTFA ]);

	useEffect(() => {
		const ac = new AbortController();
		dispatch({ type: FETCH_LANGUAGE_MY_ACCOUNTS, language: siteLang });
		dispatch({ type: FETCH_LANGUAGE_TRADING, language: siteLang });
		dispatch({ type: FETCH_LANGUAGE_TRADES, language: siteLang });
		dispatch({ type: FETCH_LANGUAGE_FINANCE, language: siteLang });
		dispatch({ type: FETCH_LANGUAGE_BONUS, language: siteLang });
		dispatch({ type: FETCH_LANGUAGE_PARTNERSHIP, language: siteLang });
		dispatch({ type: FETCH_LANGUAGE_COPYTRADE, language: siteLang });
		dispatch({ type: FETCH_LANGUAGE_DATATABLE, language: siteLang });
		dispatch({ type: FETCH_LANGUAGE_MAIL_SUPPORT, language: siteLang });
		dispatch({ type: FETCH_LANGUAGE_BANNERS, language: siteLang });
		return () => ac.abort(); // Abort both fetches on unmount
	}, [ siteLang ]);

	const isRtl = useIsRtl();
	const direction = isRtl ? " rtl" : " ltr";

	const [ logout, setLogout ] = useState(false);
	const [ login, setLogin ] = useState(session.logged);

	const history = useHistory();

	function logoutTimer () {
		FMAlert.fire({
			text: languageInit.session_expired,
			showConfirmButton: true
		}).then((result) => {
			if (result.isConfirmed) {
				setLogin(false);
				setLogout(false);
			}
		});
	}

	useEffect(() => {
		const ac = new AbortController();
		if (logout) logoutTimer();
		return () => ac.abort(); // Abort both fetches on unmount
	}, [ logout ]);

	useEffect(() => {
		const ac = new AbortController();
		if (session.logged) setLogin(true);
		return () => ac.abort(); // Abort both fetches on unmount
	}, [ session ]);

	useEffect(() => {
		const ac = new AbortController();
		API.interceptors.response.use((response: any) => {
			if (response?.data?.ci_session && !response.data.ci_session.logged && !history.location.pathname.includes("/signin") && !history.location.pathname.includes("/verify-tfa")) {
				setLogout(true);
			} else if (response?.data?.ci_session && response.data.ci_session.logged) {
				if (logout) setLogout(false);
				if (!login) setLogin(true);
			}
			return response;
		}, (error) => {
			FMAlert.fire({
				text: error,
				confirmButtonText: "Try again",
				showConfirmButton: true,
				showCancelButton: true
			}).then((result) => {
				console.log(error.config);
				if (result.isConfirmed) {
					axios(error.config);
				} else {
					return Promise.reject(error);
				}
			});
		});
		return () => ac.abort(); // Abort both fetches on unmount
	}, []);

	return (
		<div className={"App" + direction}>
			<Layout
				logged={login}
				redirect={session.redirect}
				logout={auth.logout}
			/>
		</div>
	);
};
