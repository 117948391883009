import * as React from "react";
import { useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { Form } from "../../../Form/Form";
import { changePasswordValidation, checkPasswordValidation } from "./validation";
import { Input } from "../../../Form/Input";
import { DefaultModal, ModalBlock } from "../../../UI/Modals";
import { ModalButtons } from "../components/ModalButtons";
import { ResponseGenerator } from "../../../../interfaces/mainInterfaces";
import API from "../../../../utils/API";
import { FMAlert } from "../../../Form/Swal/FMAlert";
import { useState } from "react";
import { Typography } from "@mui/material";

export interface IChangePasswordProps {
	isOpen: boolean;
	onClose: (value: boolean) => void;
}

interface passwordChangeData {
	old_password: string
	new_password: string
	confirm_password: string
}
interface passwordCheckData {
	old_password: string
}

interface IMessage {
	text: string
	color: string
}

export function ChangePassword (props: IChangePasswordProps) {
	const { isOpen, onClose: setIsOpen } = props;
	const myAccountLang = useAppSelector((state) => state.language.languageMyAccounts);
	const [ isLoad, setIsLoad ] = React.useState(false);
	const [ , setPassResponse ] = useState({});
	const [ formKey, setFormKey ] = useState("");
	const [ passChecked, setPassChecked ] = useState(false);
	const [ passChanged, setPassChanged ] = useState(false);
	const [ message, setMessage ] = useState({} as IMessage);

	const onClose = React.useCallback(() => {
		setIsOpen(false);
		setPassChecked(false);
		setPassChanged(false);
		setMessage({} as IMessage);
	}, []);

	const onSubmit = React.useCallback((data: passwordChangeData) => {
		FMAlert.fire({
			title: myAccountLang.change_pass_03,
			text: myAccountLang.change_pass_01,
			confirmButtonText: myAccountLang.change_pass_05,
			showCancelButton: true,
			cancelButtonText: myAccountLang.change_pass_04,
			cancelButtonColor: "#d9534f",
			confirmButtonColor: "#5cb85c"
		}).then((result) => {
			if (result.isConfirmed) {
				const passwordChangeData = new FormData();
				passwordChangeData.append("old_password", data.old_password);
				passwordChangeData.append("new_password", data.new_password);
				passwordChangeData.append("confirm_password", data.confirm_password);
				passwordChangeData.append("form_key", formKey);
				return sendPasswordChangeInfo(passwordChangeData);
			}
		});
	}, []);

	const checkPassword = React.useCallback((data: passwordCheckData) => {
		const passwordCheckData = new FormData();
		passwordCheckData.append("old_password", data.old_password);
		return sendPasswordCheckInfo(passwordCheckData);
	}, []);

	async function sendPasswordChangeInfo (passwordChangeData: {}) {
		const response: ResponseGenerator = await API.post("changePassword", passwordChangeData);
		setPassResponse(response.data || {});
		setIsLoad(false);
		if (response.data && response.data.success) {
			setMessage({ text: myAccountLang.chapas_00, color: "green" });
			setPassChanged(true);
		}
		if (response.data && formKey && !response.data.success && (response.data.error || response.data.tank_error || response.data.max_pass_msg_error)) {
			setMessage({ text: response.data.max_pass_msg_error ? myAccountLang.max_pass_msg : response.data.error.old_password || response.data.tank_error, color: "red" });
		}
		if (response.data && response.data.form && response.data.form.form_key) {
			setFormKey(response.data.form.form_key);
		}
	}

	async function sendPasswordCheckInfo (passwordCheckData: {}) {
		const response: ResponseGenerator = await API.post("checkPassword", passwordCheckData);
		setPassResponse(response.data || {});
		setIsLoad(false);
		if (response.data && response.data.success) {
			setMessage({ text: "", color: "green" });
			setPassChecked(true);
		}
		if (response.data && !response.data.success && response.data.error) {
			setMessage({ text: response.data.error ? response.data.error : "Error", color: "red" });
		}
		if (response.data && response.data.form && response.data.form.form_key) {
			setFormKey(response.data.form.form_key);
		}
	}

	return (
		<DefaultModal
			isOpen={isOpen}
			title={myAccountLang.mfn_02}
			onClose={onClose}
		>
			<Form
				defaultValues={{
					old_password: "",
					new_password: "",
					confirm_password: ""
				}}
				validationSchema={passChecked ? changePasswordValidation : checkPasswordValidation}
				onSubmit={passChecked ? onSubmit : checkPassword}
				fieldWrapperVariant="with-tip"
			>
				<ModalBlock>
					<Input name="old_password" label="" type={passChecked ? "hidden" : "password"} placeholder={myAccountLang.current_password} variant="rounded"/>
					<Input name="new_password" label="" type={!passChecked ? "hidden" : "password"} placeholder={myAccountLang.new_password} variant="rounded"/>
					<Input name="confirm_password" label="" type={!passChecked ? "hidden" : "password"} placeholder={myAccountLang.repeat_new_password} variant="rounded"/>
					<>{message.text ? <Typography color={message.color}>{message.text}</Typography> : null}</>
				</ModalBlock>
				{!passChanged ? <ModalButtons isLoad={isLoad} onClose={onClose}/> : null}

			</Form>
		</DefaultModal>
	);
}
