/* eslint-disable camelcase */
import { Link } from "@mui/material";
import * as React from "react";
import { Input } from "../../../../components/Form/Input";
import { DefaultForm } from "../../../../components/Forms/DepositForm/DefaultForm";
import { useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { getInfo } from "./api";
import { netellerValidationSchema } from "./validation";

export function Neteller () {
	const [ isFetch, setIsFetch ] = React.useState(false);
	const lang = useAppSelector((state) => state.language.languageFinance);
	const currency = useAppSelector((state) => state.user.user.mt_currency_base);
	const { n_04, n_22_0 } = lang;

	const onSubmit = React.useCallback(async (values: any) => {
		try {
			setIsFetch(true);
			const { amount, netellerAccountId } = values;
			const info = await getInfo({
				amount,
				account: netellerAccountId,
				currency
			});
			window.open(info.redirect, "_self");
		} catch (e) {
			// handle some error
		} finally {
			setIsFetch(false);
		}
	}, []);

	return (
		<DefaultForm
			onSubmit={onSubmit}
			isFetch={isFetch}
			validationSchema={netellerValidationSchema(lang)}
			defaultValues={{
				netellerAccountId: ""
			}}
			notes={
				<>
					{n_22_0}
					<Link> finance@forexmart.com</Link>
					.
				</>
			}
		>
			<Input
				name="netellerAccountId"
				label={n_04}
				variant="rounded"
			/>
		</DefaultForm>
	);
}
