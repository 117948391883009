import * as React from "react";
import { Form } from "../../../../components/Form";
import { Input } from "../../../../components/Form/Input";
import { InputRepeater } from "../../../../components/Form/InputRepeater";
import { CurrenciesSymbols, CurrencyType } from "../../../../utils/currency";
import { getWithdrawalValue } from "../../../../utils/getReceivedValue";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { skrillWithdrawValidationSchema } from "./validation";
import { FormBottomWrapper } from "../../../../components/Form/FormBottomWrapper";
import { ButtonWithLoader } from "../../../../components/ButtonWithLoader";
import API from "../../../../utils/API";
import { AxiosResponse } from "axios";
import { Alert, Box, Skeleton, Snackbar } from "@mui/material";
import { fetchSkrillInfo } from "../../../../store/actions/actionTypes";
import { WaningNote } from "../../../../components/UI/Note";

export function Skrill () {
	const [ isFetch, setIsFetch ] = React.useState(false);
	const [ success, setSuccess ] = React.useState(false);
	const language = useAppSelector((state) => state.language);
	const accountNumber = useAppSelector((state) => `${state.session.userData.account_number ?? ""}`);
	const currency = useAppSelector((state) => state.session.userData.currency ?? "USD");
	const [ errorMsg, setErrorMsg ] = React.useState("");
	const [ isOpen, setIsOpen ] = React.useState<boolean>(false);
	const {
		fee,
		addon,
		feeDetails,
		loading: infoLoading,
		complete
	} = useAppSelector((state) => state.withdrawMethodsInfo.skrill);
	const dispatch = useAppDispatch();

	React.useEffect(() => {
		if (!complete) {
			dispatch(fetchSkrillInfo({}));
		}
	}, []);

	const getWithdrawalValueCB = React.useCallback((values: any) => getWithdrawalValue(values, currency), [ currency ]);

	const calcFee = React.useCallback((values: [string, CurrencyType]) => {
		const [ amount, currencyType ] = values;
		const _currency = CurrenciesSymbols[currencyType] || CurrenciesSymbols[currency];
		return isNaN(+amount) ? `0 ${_currency}` : `${(+amount * fee + addon).toFixed(2)}${_currency}`;
	}, []);

	const hideErrorMsg = React.useCallback(() => {
		setIsOpen(false);
		setErrorMsg("");
	}, [ setErrorMsg ]);

	const submit = React.useCallback(async (values: any) => {
		try {
			setIsFetch(true);
			const formData = new FormData();
			formData.append("amount_withdraw", values.amount);
			formData.append("skrill_account", values.account);

			const { data } = await API.post<unknown, AxiosResponse<{ success: boolean, errorMsg: string }>>("withdrawSkrill", formData);

			if (data.success) {
				setSuccess(true);
			} else {
				setErrorMsg(data.errorMsg);
				setIsOpen(true);
			}
		} catch (e) {
			// handle error
		} finally {
			setIsFetch(false);
		}
	}, []);

	return (
		<>
			<Form
				onSubmit={submit}
				validationSchema={skrillWithdrawValidationSchema(language.languageFinance)}
				defaultValues={{
					accountNumber,
					amount: "",
					account: ""
				}}
			>
				<Input
					name="accountNumber"
					label={language.languageCopytrade.cpy_mon_accnum}
					variant="rounded"
				/>
				<Input
					name="amount"
					label={language.languageFinance.trd_230}
					variant="rounded"
				/>
				<InputRepeater
					fields="amount,currency"
					label={language.languageFinance["with-p-04"]}
					onChange={calcFee}
				/>
				<Input
					name="account"
					label={language.languageFinance.wskrill_05}
					variant="rounded"
				/>
				<FormBottomWrapper
					forFields={[ "amount", "currency" ]}
					onChange={getWithdrawalValueCB}
					label={language.languageMyAccounts.form_message_01}
				>
					<ButtonWithLoader
						isFetch={isFetch}
						color={"primary"}
						text={language.languageMyAccounts.chapas_04}
					/>
				</FormBottomWrapper>
				<Box sx={{ marginTop: "6px" }}>
					{
						infoLoading
							? (
								<Skeleton width={"220px"} height={"26px"} />
							)
							: feeDetails
								? (
									<WaningNote
										message={`Fee is ${feeDetails}`}
									/>
								)
								: <></>
					}
				</Box>
			</Form>
			<Snackbar open={isOpen} autoHideDuration={4000} onClose={hideErrorMsg}>
				<Alert variant="filled" color="error">
					{errorMsg}
				</Alert>
			</Snackbar>
			<Snackbar
				open={success}
				autoHideDuration={4000}
				onClose={() => setSuccess(false)}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
			>
				<Alert variant="filled" color="success">
					Success
				</Alert>
			</Snackbar>
		</>
	);
}
