import React from "react";

export default function ZeroSpreadIcon () {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="25.796" height="19.882" viewBox="0 0 25.796 19.882">
			<g transform="translate(-26.731 -29.588)">
				<path d="M43.378,41.68a2.425,2.425,0,0,0,1.758-.717,2.387,2.387,0,0,0,0-3.449,2.538,2.538,0,0,0-3.524,0,2.4,2.4,0,0,0,0,3.449A2.449,2.449,0,0,0,43.378,41.68Zm.848-1.614a1.152,1.152,0,0,1-.848.347,1.172,1.172,0,0,1-.855-.347,1.1,1.1,0,0,1-.349-.823,1.116,1.116,0,0,1,.348-.828,1.182,1.182,0,0,1,.856-.343,1.16,1.16,0,0,1,.849.342,1.108,1.108,0,0,1,.355.829A1.092,1.092,0,0,1,44.227,40.066Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.305"/>
				<path d="M49.543,42.819a2.458,2.458,0,0,0-1.765.711,2.4,2.4,0,0,0,0,3.448,2.522,2.522,0,0,0,3.523,0,2.387,2.387,0,0,0,0-3.449A2.438,2.438,0,0,0,49.543,42.819Zm.848,3.262a1.153,1.153,0,0,1-.848.347,1.173,1.173,0,0,1-.855-.347,1.1,1.1,0,0,1-.348-.823,1.115,1.115,0,0,1,.347-.828,1.183,1.183,0,0,1,.856-.343,1.162,1.162,0,0,1,.849.341,1.108,1.108,0,0,1,.355.829A1.093,1.093,0,0,1,50.391,46.081Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.305"/>
				<path d="M51.392,36.545,40.613,47.062l.915.893L52.308,37.437Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.305"/>
				<path d="M32.414,29.741A5.519,5.519,0,0,0,28.3,31.193a5.579,5.579,0,0,0-1.417,4.053v8.566a5.6,5.6,0,0,0,1.41,4.046,5.5,5.5,0,0,0,4.121,1.459,5.519,5.519,0,0,0,4.114-1.452,5.579,5.579,0,0,0,1.417-4.053V35.246a5.591,5.591,0,0,0-1.411-4.06A5.531,5.531,0,0,0,32.414,29.741Zm-2.582,5.505a2.91,2.91,0,0,1,.621-2,2.532,2.532,0,0,1,1.96-.682,2.532,2.532,0,0,1,1.96.682,2.909,2.909,0,0,1,.622,2v8.566a2.889,2.889,0,0,1-.628,2,2.536,2.536,0,0,1-1.955.682,2.536,2.536,0,0,1-1.954-.682,2.889,2.889,0,0,1-.628-2V35.246Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.305"/>
			</g>
		</svg>
	);
};
