/*
    Created on : 19.12.2021, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import FooterAuthSocial from "./footerAuthComponents/FooterAuthSocial/FooterAuthSocial";
import FooterAuthLinks from "./footerAuthComponents/FooterAuthLinks/FooterAuthLinks";
import "./FooterAuth.scss";
import FooterAuthDesk from "./footerAuthComponents/FooterAuthDesk/FooterAuthDesk";

function FooterAuth () {
	return (
		<footer className="footer-auth">
			<div className="footer-auth-first-row-wrap">
				<div className="container">
					<div className="footer-auth-first-row flexRow">
						<FooterAuthLinks/>
						<FooterAuthSocial/>
					</div>
				</div>
			</div>
			<FooterAuthDesk/>
		</footer>
	);
}
export default FooterAuth;
