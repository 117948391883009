import * as React from "react";
import { Grid, Typography } from "@mui/material";
import { SuccessNote } from "../../../components/UI/Note/SuccessNote";
import { UploadForm } from "./components/UploadForm";
import { useIsMobile } from "../../../utils/hooks/useIsMobile";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../interfaces/StoreInterfaces";

export function Step2 () {
	const isMobile = useIsMobile();
	const userDocs = useSelector((state:StoreInterface) => state.user.userDocs);
	const langMyAccounts:any = useSelector((state:StoreInterface) => state.language.languageMyAccounts);

	let fileHashName = "";
	if (userDocs.level_two_first) fileHashName = userDocs.level_two_first?.file_name;
	let decline = "";
	if (userDocs.level_two_first?.decline_explained) decline = userDocs.level_two_first?.decline_explained;

	return (
		<Grid
			container
			flexDirection="row"
		>
			<Grid item sx={{ width: isMobile ? "100%" : "50%" }}>
				<Typography
					sx={{ maxWidth: "440px", letterSpacing: "0", lineHeight: "24px", marginBottom: "15px" }}
					textAlign="left"
				>
					{langMyAccounts.pro_up_doc_25}
				</Typography>
				<Typography
					sx={{ maxWidth: "430px", letterSpacing: "0", lineHeight: "24px", marginBottom: "30px" }}
					textAlign="left"
				>
					{langMyAccounts.pro_up_doc_26}
				</Typography>
				<SuccessNote message={langMyAccounts.pro_up_doc_27} sx={{ marginBottom: "15px" }} />
				<SuccessNote message={langMyAccounts.pro_up_doc_28} sx={{ marginBottom: "15px" }} />
				<SuccessNote message={langMyAccounts.pro_up_doc_29} sx={{ marginBottom: "15px" }} />
			</Grid>
			<Grid item sx={{ width: isMobile ? "100%" : "50%" }}>
				<UploadForm
					tips="Tip 2"
					label={langMyAccounts.accver_17}
					type="1"
					name="doc_type"
					level="2"
					fileHashName={fileHashName}
					decline={decline}
				/>
			</Grid>
		</Grid>
	);
}
