import React from "react";
import * as Yup from "yup";
import DefaultLogo from "./img/default.png";
import Title from "../../components/Typography/Title";
import CustomButton from "../../components/CustomButton";
import { Row } from "../../components/UI/Row";
import { Alert, Box, Snackbar, SxProps } from "@mui/material";
import { useIsRtl } from "../../utils/hooks/useIsRtl";
import { useIsMobile } from "../../utils/hooks/useIsMobile";
import { useModalControls } from "../../utils/hooks/useModalControl";
import { DefaultModal } from "../../components/UI/Modals";
import { useAppDispatch, useAppSelector } from "../../utils/hooks/reduxHooks";
import { Form } from "../../components/Form";
import { Input } from "../../components/Form/Input";
import { ButtonWithLoader } from "../../components/ButtonWithLoader";
import API from "../../utils/API";
import { TResponse } from "../../utils/types";
import { addRebateSystemProject } from "../../store/actions/actionTypes";
import { useAdaptive } from "../../utils/hooks/themeBreakpointsHooks";

const xsTitleStyles: SxProps = {
	fontSize: "14px"
};

const smTitleStyles: SxProps = {
	fontSize: "18px"
};

const CommonRebate: React.FC = () => {
	const isRtl = useIsRtl();
	const isMobile = useIsMobile();
	const { languageTrading } = useAppSelector((state) => state.language);
	const { isOpen, openModal, closeModal } = useModalControls();
	const adaptiveTitleStyles = useAdaptive({
		xs: xsTitleStyles,
		sm: smTitleStyles
	});

	return (
		<>
			<Row sx={{ gap: "30px", flexWrap: isMobile ? "wrap" : "nowrap" }}>
				<img src={DefaultLogo} alt={""}/>
				<div>
					<Title sx={adaptiveTitleStyles}>Rebate System Let's make our partnership more effective and rewarding. ForexMart is much delighted to unveil our Rebate System, another great opportunity to earn higher commission</Title>
					<Title mt={"15px"} sx={adaptiveTitleStyles}>Under this system, we pay a portion of the spread and give it to partners. In exchange, affiliates pay off part of the commission to clients. Partners can return a rebate of up to 0,8 pips to the client, which can be given daily, weekly, or monthly.</Title>
					<Title mt={"15px"} sx={adaptiveTitleStyles}>Affiliates can create as many rebate projects as they can. Each project can be activated, deactivated, modified, or removed by logging into the Partner Cabinet.</Title>
					<Box textAlign={isRtl ? "right" : "left"}>
						<CustomButton
							sx={isMobile ? { width: "100%", marginTop: "15px", marginBottom: "27px" } : { marginTop: "27px", right: 0 }}
							onClick={openModal}
							color="success"
							text={languageTrading.reb_txt_8}
						/>
					</Box>
				</div>
			</Row>
			<CreateProjectForm isOpen={isOpen} closeModal={closeModal} />
		</>
	);
};

const createProjectValidation = (language: { size_error: string, max_rebate: string }) => Yup.object().shape({
	projectName: Yup.string().max(16, language.size_error),
	rebateValue: Yup.number().max(80, `${language.max_rebate} 80%`)
});

function CreateProjectForm (props: { closeModal: () => void, isOpen: boolean }) {
	const { isOpen, closeModal } = props;
	const dispatch = useAppDispatch();
	const [ isFetch ] = React.useState(false);
	const { languageTrading, languageMyAccounts, languagePartnership } = useAppSelector((state) => state.language);
	const [ isOpenError, setIsOpenError ] = React.useState(false);
	const [ errorMsg, setErrorMsg ] = React.useState("");

	const submit = React.useCallback(async (values: any) => {
		try {
			const formData = new FormData();
			formData.append("projectName", values.projectName);
			formData.append("rebateValue", values.rebateValue);

			const { data } = await API.post<TResponse<null>>("rebateSystemAddProject", formData);

			if (!data.success) {
				setErrorMsg(data.error);
				setIsOpenError(true);
			} else {
				dispatch(addRebateSystemProject({ name: values.projectName, rebateValue: values.rebateValue }));
				closeModal();
			}
		} catch (e) {
			//
		}
	}, []);

	const hideErrorMsg = React.useCallback(() => {
		setIsOpenError(false);
		setErrorMsg("");
	}, [ setErrorMsg, setIsOpenError ]);

	return (
		<>
			<DefaultModal
				isOpen={isOpen}
				onClose={closeModal}
				title={languageTrading.reb_txt_8}
			>
				<Box sx={{ padding: "30px" }}>
					<Form
						defaultValues={{
							projectName: "",
							rebateValue: ""
						}}
						onSubmit={submit}
						validationSchema={createProjectValidation(languagePartnership)}
						fieldWrapperVariant="with-tip"
					>
						<Box sx={{ marginBottom: "16px" }}>
							<Input name="projectName" label={languageTrading.reb_txt_9} variant="rounded" />
						</Box>
						<Box sx={{ marginBottom: "24px" }}>
							<Input name="rebateValue" label={languageTrading.reb_txt_10} variant="rounded" />
						</Box>
						<ButtonWithLoader
							color="success"
							isFetch={isFetch}
							text={languageMyAccounts.perdet_11}
						/>
					</Form>
				</Box>
			</DefaultModal>
			<Snackbar open={isOpenError} autoHideDuration={4000} onClose={hideErrorMsg} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
				<Alert variant="filled" color="error">
					{errorMsg}
				</Alert>
			</Snackbar>
		</>
	);
}

export default CommonRebate;
