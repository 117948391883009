import {
	SET_USER_MAILS
} from "../actions/actionTypes";

const initial = {
	userMails: []
};

export interface IUserSupportMailFile {
	id: string
	image_hashed_name: string
	image_name: string
	mail_thread_id: string
	size: string
}

export interface IUserSupportMail {
	"id": string
	"userid": string
	"contact_emails_id": string
	"subject": string
	"date_created": string
	"status": string
	"mail_status": string
	"mail_support_id": string
	"sender": string
	"message": string
	"user_type": string
	"date_updated": string
	"thread_id": string
	"thread_status": string
	"images"?: Array<IUserSupportMailFile>
	"mail_notif": string
}

export default function userMailsReducer (state = initial, action:{type:string, payload:any}) {
	switch (action.type) {
		case SET_USER_MAILS:
			return {
				...state,
				userMails: action.payload
			};
		default:
			return state;
	}
}
