/* eslint-disable camelcase */
import * as React from "react";
import * as Yup from "yup";
import { Box, Grid, SxProps } from "@mui/material";
import { Form } from "../../../../../components/Form";
import { Row } from "../../../../../components/UI/Row";
import { Input } from "../../../../../components/Form/Input";
import { useAppDispatch, useAppSelector } from "../../../../../utils/hooks/reduxHooks";
import { ButtonWithLoader } from "../../../../../components/ButtonWithLoader";
import API from "../../../../../utils/API";
import { createUpdateProfileDetailsAction } from "../../../../../store/actions/actionTypes";
import { useAdaptive } from "../../../../../utils/hooks/themeBreakpointsHooks";

const validation = Yup.object().shape({});

const xsColumnStyles: SxProps = {
	width: "100%",
	margin: 0
};

const xsRightColumnStyles: SxProps = {
	...xsColumnStyles,
	paddingTop: "20px"
};

const smColumnStyles: SxProps = {
	width: "calc(50% - 15px)"
};

const smLeftColumnStyles: SxProps = {
	...smColumnStyles,
	marginRight: "15px"
};

const smRightColumnStyles: SxProps = {
	...smColumnStyles,
	marginLeft: "15px",
	paddingTop: 0
};

export function CopyingTerms () {
	const languageCopytrade = useAppSelector(state => state.language.languageCopytrade);
	const languageTrading = useAppSelector(state => state.language.languageTrading);
	const languageInit = useAppSelector(state => state.language.language);
	const {
		conditions_values_2 = languageCopytrade.not_use,
		conditions_values_3 = languageCopytrade.not_use,
		conditions_values_1 = languageCopytrade.not_use,
		conditions_values_10 = languageCopytrade.not_use,
		conditions_values_4 = languageCopytrade.not_use
	} = useAppSelector(state => state.copytrade.profile.details || {});
	const dispatch = useAppDispatch();
	const [ isFetch, setIsFetch ] = React.useState(false);

	const submit = React.useCallback(async (values: any) => {
		setIsFetch(true);
		try {
			const formData = new FormData();
			formData.append("commission_payer", values.commission_payer);
			formData.append("conditions_values_2", values.conditions_values_2);
			formData.append("conditions_values_3", values.conditions_values_3);
			formData.append("conditions_values_1", values.conditions_values_1);
			formData.append("conditions_values_10", values.conditions_values_10);
			formData.append("conditions_values_4", values.conditions_values_4);

			await API.post("copytradeUpdateCopyingTerms", formData);

			dispatch(createUpdateProfileDetailsAction(values));
		} catch (e) {
			//
		} finally {
			setIsFetch(false);
		}
	}, []);

	const adaptiveLeftColumnStyles = useAdaptive({
		xs: xsColumnStyles,
		sm: smLeftColumnStyles
	});

	const adaptiveRightColumnStyles = useAdaptive({
		xs: xsRightColumnStyles,
		sm: smRightColumnStyles
	});

	return (
		<Form
			validationSchema={validation}
			defaultValues={{
				commission_payer: "Follower",
				conditions_values_2,
				conditions_values_3,
				conditions_values_1,
				conditions_values_10,
				conditions_values_4
			}}
			onSubmit={submit}
			fieldWrapperVariant="with-tip"
		>
			<Box
				sx={{
					border: "1px solid #e2e7e8",
					borderTop: 0,
					padding: "23px"
				}}
			>
				<Row wrap="wrap">
					<Grid
						container
						flexDirection="column"
						sx={adaptiveLeftColumnStyles}
					>
						<Grid item sx={{ marginBottom: "20px" }}>
							<Input name="commission_payer" label={languageCopytrade.trd_188} variant="rounded" />
						</Grid>
						<Grid item sx={{ marginBottom: "20px" }}>
							<Input name="conditions_values_2" label={languageCopytrade.terms_01} variant="rounded" />
						</Grid>
						<Grid item>
							<Input name="conditions_values_3" label={languageCopytrade.trd_116} variant="rounded" />
						</Grid>
					</Grid>
					<Grid
						container
						flexDirection="column"
						sx={adaptiveRightColumnStyles}
					>
						<Grid item sx={{ marginBottom: "20px" }}>
							<Input name="conditions_values_1" label={languageTrading.trd_141} variant="rounded" />
						</Grid>
						<Grid item sx={{ marginBottom: "20px" }}>
							<Input name="conditions_values_10" label={languageCopytrade.trd_303} variant="rounded" />
						</Grid>
						<Grid item>
							<Input name="conditions_values_4" label={languageCopytrade.trd_117} variant="rounded" />
						</Grid>
					</Grid>
				</Row>
			</Box>
			<ButtonWithLoader
				sxButton={{
					maxWidth: "240px",
					width: "100%"
				}}
				sx={{
					marginTop: "30px",
					maxWidth: "240px"
				}}
				color="success"
				isFetch={isFetch}
				text={languageInit.cs_06}
			/>
		</Form>
	);
}
