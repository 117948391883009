import { Grid, Typography } from "@mui/material";
import * as React from "react";
import { INoteProps } from "../types";
import { useIsRtl } from "../../../../utils/hooks/useIsRtl";
import { ReactComponent as NoteIcon } from "./SuccessNoteIcon.svg";

export interface ISuccessNoteProps extends INoteProps {
	width?: string;
	height?: string;
	color?: string;
}

export function SuccessNote (props: ISuccessNoteProps) {
	const { message, sx, width = "21.46px", height = "16px", color = "#000" } = props;
	const isRtl = useIsRtl();

	return (
		<Grid
			container
			flexWrap="nowrap"
			flexDirection={isRtl ? "row-reverse" : "row"}
			alignItems="center"
			sx={sx}
		>
			<Grid
				item
				sx={{ [isRtl ? "marginLeft" : "marginRight"]: "8px" }}
			>
				<NoteIcon width={width} height={height} />
			</Grid>
			<Typography
				align={isRtl ? "right" : "left"}
				sx={{ letterSpacing: 0, lineHeight: "19px" }}
				color={color}
			>
				{message}
			</Typography>
		</Grid>
	);
}
