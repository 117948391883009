import React from "react";

export default function MailSupportIcon (props:any) {
	let width = 16;
	let fill = "#417cbf";
	if (props.width) {
		width = props.width;
	}
	if (props.fill) {
		fill = props.fill;
	}

	return (
		<svg width={width} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 16">
			<defs>
				<clipPath id="clip-path-MailSupportIcon">
					<rect width={width} height={width} transform="translate(20 22)" fill="#fff"/>
				</clipPath>
			</defs>
			<g transform="translate(-20 -22)" clipPath="url(#clip-path-MailSupportIcon)">
				<g id="icon-fm-email" transform="translate(18.49 20.177)">
					<path d="M1.51,15.818v-12h16v12l-16-.007Zm7.927-4.04L7.2,9.867c-1.4,1.706-3.1,3.77-3.967,4.828H15.657L11.675,9.867ZM2.662,13.506,6.374,9.152,2.662,5.995Zm.649-8.562,6.126,5.229,6.1-5.229Zm9.2,4.215,3.712,4.354V6Z" fill={fill}/>
				</g>
			</g>
		</svg>
	);
};
