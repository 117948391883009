import { Alert, Box, Grid, Snackbar } from "@mui/material";
import * as React from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { ButtonWithLoader } from "../../../components/ButtonWithLoader";
import PageTitle from "../../../components/Dashboard/dashboardComponents/PageTitle";
import { Form } from "../../../components/Form";
import { Input } from "../../../components/Form/Input";
import { Option } from "../../../components/Form/Option";
import { Select } from "../../../components/Form/Select";
import { AccountType } from "../../../interfaces/StoreInterfaces";
import { setAccountType } from "../../../store/actions/actionTypes";
import API from "../../../utils/API";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks/reduxHooks";

const validationSchema = Yup.object().shape({});

enum Lang {
	EN = "en",
	RU = "ru",
}

export function RegisterTrader () {
	const lang = useAppSelector((state) => state.language.language);
	const [ isFetch, setIsFetch ] = React.useState(false);
	const [ isOpen, setIsOpen ] = React.useState(false);
	const [ errorMsg, setErrorMsg ] = React.useState("");
	const history = useHistory();
	const languageCopytrade = useAppSelector((state) => state.language.languageCopytrade);
	const languageMyAccounts = useAppSelector((state) => state.language.languageMyAccounts);
	const dispatch = useAppDispatch();

	const submit = React.useCallback(async (values: any) => {
		setIsFetch(true);
		try {
			const formData = new FormData();
			formData.append("project_name", values.projectName);
			formData.append("notify_lang", values.language);
			formData.append("commission_payer", values.whoPaysCommission);
			formData.append("conditions_values_1", values.commissionPerTrade);
			formData.append("conditions_values_2", values.profitableTrades);
			formData.append("conditions_values_10", values.allTrades);
			formData.append("conditions_values_3", values.profitShare);
			formData.append("conditions_values_4", values.dailyCommission);

			const response = await API.post<{ success: boolean, error: string }>("copytradeRegisterTrader", formData);

			if (response.data.success) {
				dispatch(setAccountType(AccountType.TRADER));
				history.push("/app/copytrade/copytrading");
			} else {
				throw new Error(response.data.error);
			}
		} catch (e) {
			if (e instanceof Error) {
				setErrorMsg(e.message);
				setIsOpen(true);
			}
		} finally {
			setIsFetch(false);
		}
	}, []);

	const hideErrorMsg = React.useCallback(() => {
		setIsOpen(false);
		setErrorMsg("");
	}, [ setErrorMsg, setIsOpen ]);

	return (
		<>
			<PageTitle
				layoutTitle={lang.sb_li_12}
				pageTitle={languageCopytrade.reg_trader_05}
			/>
			<Form
				defaultValues={{
					projectName: "",
					language: Lang.EN,
					whoPaysCommission: "Investor",
					commissionPerTrade: "",
					profitableTrades: "",
					allTrades: "",
					profitShare: "Do not use",
					dailyCommission: ""
				}}
				validationSchema={validationSchema}
				onSubmit={submit}
				fieldWrapperVariant="with-tip"
			>
				<Grid container flexDirection="row" spacing={2}>
					<Grid item xs={6} sx={{ marginBottom: "30px" }}>
						<Input name="projectName" label={languageCopytrade.reg_trader_07} variant="rounded" />
					</Grid>
					<Grid item xs={6} sx={{ marginBottom: "30px" }}>
						<Select name="language" label={languageCopytrade.reg_trader_08}>
							<Option value={Lang.EN}>{languageCopytrade.pamm_en}</Option>
							<Option value={Lang.RU}>{languageCopytrade.pamm_ru}</Option>
						</Select>
					</Grid>
				</Grid>
				<Grid container flexDirection="row" spacing={2}>
					<Grid item xs={6} sx={{ marginBottom: "15px" }}>
						<Input name="whoPaysCommission" label={languageCopytrade.reg_trader_12} variant="rounded" />
					</Grid>
					<Grid item xs={6} sx={{ marginBottom: "15px" }}>
						<Input name="commissionPerTrade" label={languageCopytrade.reg_trader_14} variant="rounded" />
					</Grid>
				</Grid>
				<Grid container flexDirection="row" spacing={2}>
					<Grid item xs={6} sx={{ marginBottom: "15px" }}>
						<Input name="profitableTrades" label={languageCopytrade.reg_trader_17} variant="rounded" />
					</Grid>
					<Grid item xs={6} sx={{ marginBottom: "15px" }}>
						<Input name="allTrades" label={languageCopytrade.reg_trader_19} variant="rounded" />
					</Grid>
				</Grid>
				<Grid container flexDirection="row" spacing={2}>
					<Grid item xs={6} sx={{ marginBottom: "30px" }}>
						<Input name="profitShare" label={languageCopytrade.reg_trader_22} variant="rounded" />
					</Grid>
					<Grid item xs={6} sx={{ marginBottom: "30px" }}>
						<Input name="dailyCommission" label={languageCopytrade.reg_trader_26} variant="rounded" />
					</Grid>
				</Grid>
				<Box sx={{ maxWidth: "150px" }}>
					<ButtonWithLoader
						color="success"
						text={languageMyAccounts.perdet_11}
						isFetch={isFetch}
					/>
				</Box>
			</Form>
			<Snackbar open={isOpen} autoHideDuration={4000} onClose={hideErrorMsg} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
				<Alert variant="filled" color="error">
					{errorMsg}
				</Alert>
			</Snackbar>
		</>
	);
}
