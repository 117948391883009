import * as React from "react";
import styled from "styled-components";
import { Default, DefaultProps } from "../Default";

export function Rounded (props: DefaultProps) {
	return <RoundedUI {...props} />;
}

const RoundedUI = styled(Default)`
	border-radius: 4px !important;
`;
