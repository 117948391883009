const languageName = (language: string = ""): { name: string, shortName: string } => {
	switch (language) {
		case "english":
			return { name: "english", shortName: "en" };
		case "arabic":
			return { name: "arabic", shortName: "sa" };
		case "french":
			return { name: "français", shortName: "fr" };
		case "german":
			return { name: "german", shortName: "de" };
		case "indonesian":
			return { name: "indonesian", shortName: "id" };
		case "portuguese":
			return { name: "português", shortName: "pt" };
		case "russuan":
			return { name: "русский", shortName: "ru" };
		case "bulgarian":
			return { name: "bulgarian", shortName: "bg" };
		case "malay":
			return { name: "malay", shortName: "ma" };
		case "polish":
			return { name: "polish", shortName: "pl" };
		case "urdu":
			return { name: "urdu", shortName: "pk" };
		case "greek":
			return { name: "greek", shortName: "gr" };
		case "czech":
			return { name: "czech", shortName: "cz" };
		case "chinese":
			return { name: "chinese", shortName: "cn" };
		case "bangladesh":
			return { name: "bangladesh", shortName: "bd" };
		case "vietnam":
			return { name: "vietnam", shortName: "vn" };
		case "spanish":
			return { name: "español", shortName: "es" };
		default:
			return { name: "english", shortName: "en" };
	}
};

export default languageName;
