/*
    Created on : 20.01.2022, 06:18:59
    Author     : Andy Kar
*/
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import {
	FETCH_LANGUAGE_MY_ACCOUNTS, FETCH_MY_ACCOUNTS
} from "../../store/actions/actionTypes";
import {
	StoreInterface
} from "../../interfaces/StoreInterfaces";
import "./LayoutMyAccounts.scss";
import PagePersonalDetails from "../../pages/MyAccounts/PagePersonalDetails/PagePersonalDetails";
import PageChangePassword from "../../pages/MyAccounts/PageChangePassword/PageChangePassword";
import { PageAccountVerification } from "../../pages/MyAccounts/PageAccountVerification";
import PageSecurityInfo from "../../pages/MyAccounts/PageSecurityInfo/PageSecurityInfo";
import PageCompanyDetails from "../../pages/MyAccounts/PageCompanyDetails/PageCompanyDetails";
import PageUploadDocumentsForCorporateAccount
	from "../../pages/MyAccounts/PageUploadDocumentsForCorporateAccount/PageUploadDocumentsForCorporateAccount";
import PagePersonalDetailsEdit from "../../pages/MyAccounts/PagePersonalDetailsEdit/PagePersonalDetailsEdit";
import PageRegistration from "../../pages/MyAccounts/PageRegistration/PageRegistration";
import AccountTypes from "../components/AccountTypes/AccountTypes";

const LayoutMyAccounts = (props:any) => {
	const siteLang:string|undefined = useSelector((state:StoreInterface) => state.session.userData.site_lang);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch({ type: FETCH_LANGUAGE_MY_ACCOUNTS, language: siteLang });
	}, [ siteLang ]);

	useEffect(() => {
		dispatch({ type: FETCH_MY_ACCOUNTS, myAccountsData: {} });
	}, []);

	return (
		<div className="layout layout-my-account">
			<Switch>
				<Route exact path="/app/my-account" render={() => <PagePersonalDetails {...props} />} />
				<Route exact path="/app/registration" render={() => <PageRegistration {...props} />} />
				<Route exact path="/app/profile/edit" render={() => <PagePersonalDetailsEdit {...props} />} />
				<Route exact path="/app/profile/change-password" render={() => <PageChangePassword {...props} />} />
				<Route exact path="/app/profile/upload-document" render={() => <PageAccountVerification {...props} />} />
				<Route exact path="/app/profile/two-factor-authentication" render={() => <PageSecurityInfo {...props} />} />
				<Route exact path="/app/profile/company-details" render={() => <PageCompanyDetails {...props} />} />
				<Route exact path="/app/profile/upload-documents-for-corporate-account" render={() => <PageUploadDocumentsForCorporateAccount {...props} />} />
				<Redirect exact from="/app/profile" to="/app/my-account" />
			</Switch>
			<AccountTypes/>
		</div>
	);
};

export default withRouter(LayoutMyAccounts);
