/*
    Created on : 14.09.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import AccountTypes from "../components/AccountTypes/AccountTypes";
import PageRebateDefault from "../../pages/RebateSystem/PageRebateDefault/PageRebateDefault";
import PageRebatePersonal from "../../pages/RebateSystem/PageRebatePersonal/PageRebatePersonal";
import PageRebateStatistics from "../../pages/RebateSystem/PageRebateStatistics/PageRebateStatistics";
import { useAppDispatch } from "../../utils/hooks/reduxHooks";
import { getRebateSystemReferrals } from "../../store/actions/actionTypes";

const LayoutRebateSystem = (props:any) => {
	const dispatch = useAppDispatch();

	React.useEffect(() => {
		dispatch(getRebateSystemReferrals({}));
	}, []);

	return (
		<>
			<Switch>
				<Route exact path="/app/rebate/default" render={() => <PageRebateDefault {...props} />} />
				<Route exact path="/app/rebate/personal" render={() => <PageRebatePersonal {...props} />} />
				<Route exact path="/app/rebate/statistics" render={() => <PageRebateStatistics {...props} />} />
				<Redirect exact from="/app/rebate" to="/app/rebate/default" />
			</Switch>
			<AccountTypes/>
		</>
	);
};

export default withRouter(LayoutRebateSystem);
