import * as React from "react";
import { Box, Typography } from "@mui/material";

export interface IBlockWithTriangleProps {
	text: string;
	bg: string;
	color: string,
}

export function BlockWithTriangle (props: IBlockWithTriangleProps) {
	const { text, color, bg } = props;
	return (
		<Box sx={{
			position: "relative",
			minHeight: "58px",
			minWidth: "175px",
			width: "fit-content",
			padding: "10px 15px 10px 29px",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			backgroundColor: bg,
			marginRight: "15px",
			marginBottom: "15px",
			"&:before": {
				content: "\"\"",
				position: "absolute",
				right: "-15px",
				bottom: 0,
				width: 0,
				height: 0,
				borderTop: "29px solid transparent",
				borderBottom: "29px solid transparent",
				borderLeft: `15px solid ${bg}`
			},
			"&:after": {
				content: "\"\"",
				position: "absolute",
				left: 0,
				bottom: 0,
				width: 0,
				height: 0,
				borderLeft: "15px solid white",
				borderTop: "29px solid transparent",
				borderBottom: "29px solid transparent"
			}
		}}>
			<Typography color={color} fontSize="1.125rem" fontWeight={600}>
				{text.toUpperCase()}
			</Typography>
		</Box>
	);
}
