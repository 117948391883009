/*
    Created on : 11.11.2022, 06:18:59
    Author     : Andy Kar
*/

import { takeEvery, put, call } from "redux-saga/effects";
import {
	FETCH_OPENED_TRADES,
	FETCH_PART_TRADES,
	SET_LENGTH_LOADED_TRADES,
	SET_LENGTH_TRADES,
	SET_OPENED_TRADES,
	SET_PART_TRADES
} from "../store/actions/actionTypes";
import { ResponseGenerator } from "../interfaces/mainInterfaces";
import API from "../utils/API";
import { ITrade } from "../store/reducers/tradesReducer";

export interface ITrades {
	"recordsTotal": number
	"recordsFiltered": number
	"trades": Array<ITrade>
};

/**
 Get open trades data from server and set it to store
 **/

export function * tradesWatcher () {
	yield takeEvery(FETCH_OPENED_TRADES, tradesWorker);
};

export function * tradesWorker () {
	const data:ITrades = yield call(getTradesInfo);
	if (Object.keys(data).length) {
		yield put({ type: SET_OPENED_TRADES, payload: data.trades });
	}
};

async function getTradesInfo () {
	const postData = new FormData();
	postData.append("start", "0");
	postData.append("length", "1000");
	// postData.append("sort", mData.sort ? mData.sort.toString() : "2");

	const response:ResponseGenerator = await API.post("myTrading", postData);
	return response?.data || {};
};

/**
 Get part of all trades data from server and set it to store
 **/

export function * tradesHistoryWatcher () {
	yield takeEvery(FETCH_PART_TRADES, tradesHistoryWorker);
};

export function * tradesHistoryWorker (action:{type:string, mData: any}) {
	const data:ITrades = yield call(getTradesHistoryInfo, action.mData);
	if (Object.keys(data).length) {
		yield put({ type: SET_PART_TRADES, payload: data.trades });
		yield put({ type: SET_LENGTH_LOADED_TRADES, payload: data.trades?.length || 0 });
		yield put({ type: SET_LENGTH_TRADES, payload: data.recordsFiltered });
	}
};

async function getTradesHistoryInfo (mData:{start?: number, length?: number, sort?: string, search?: string}) {
	const postData = new FormData();
	postData.append("start", mData.start ? mData.start.toString() : "0");
	postData.append("length", mData.length ? mData.length.toString() : "10");
	// postData.append("sort", mData.sort ? mData.sort.toString() : "2");

	const response:ResponseGenerator = await API.post("myTradingHistory", postData);
	return response?.data || {};
};
