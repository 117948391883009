/*
    Created on : 14.11.2022, 06:18:59
    Author     : Andy Kar
*/
import React, { SetStateAction, useContext, useState } from "react";
import PageTitle from "../../../components/Dashboard/dashboardComponents/PageTitle";
import { InputRow } from "../../../components/Forms/PersonalDetailsForm/PersonalDetailsForm";
import { ButtonWithLoader } from "../../../components/ButtonWithLoader";
import { FaLocationArrow } from "react-icons/fa";
import { styled } from "@mui/system";
import { useIsMobile } from "../../../utils/hooks/useIsMobile";
import Box from "@mui/material/Box";
import Rte from "../../../components/UI/Rte/Rte";
import * as yup from "yup";
import RichTextEditor, { EditorValue } from "react-rte";
import { Form, FormContext } from "../../../components/Form";
import { Input } from "../../../components/Form/Input";
import { Select } from "../../../components/Form/Select";
import { useDispatch, useSelector } from "react-redux";
import { StoreInterface } from "../../../interfaces/StoreInterfaces";
import { ResponseGenerator } from "../../../interfaces/mainInterfaces";
import API from "../../../utils/API";
import { FETCH_USER_MAILS } from "../../../store/actions/actionTypes";
import { Text } from "../../../components/UI/Text";
import AttachFiles from "./components/AttachFiles";
import { FMAlert } from "../../../components/Form/Swal/FMAlert";
const SendIcon = styled(FaLocationArrow)`margin-right: 4px`;

export type IDepartment = {
	id: string
	email: string
}

export default function PageMailSupportCompose () {
	const isMobile = useIsMobile();
	const [ value, setValue ] = useState(RichTextEditor.createEmptyValue());
	const [ empty, setEmpty ] = useState(false);
	const [ isFetch, setIsFetch ] = React.useState(false);
	const [ files, setFiles ] = useState([] as Array<File>);

	const lang:any = useSelector((state:StoreInterface) => state.language.languageMailSupport);
	const init: any = useSelector((state:StoreInterface) => state.init.init);

	const departments:Array<IDepartment> = init.contact_emails;
	const filesRef = React.useRef<HTMLInputElement | null>(null);
	const maxFiles: number = 3;

	const dispatch = useDispatch();

	const validationScheme = yup.object().shape({
		subject: yup.string().required("Field is required"),
		department: yup.string().required("Field is required")
	}).required();

	const { reset } = useContext(FormContext);

	const sendData = React.useCallback(async (data: any) => {
		setIsFetch(true);
		try {
			const formData = new FormData();
			formData.append("subject", data.subject);
			formData.append("email", data.department);

			if (files.length) {
				files.map(file => {
					formData.append("files[]", file);
					console.log(file);
					return null;
				});
			}

			const messageHtml = value.toString("html").replace(/"/g, "'");

			if (messageHtml.length < 12) {
				setEmpty(true);
				return null;
			}

			formData.append("textContent", messageHtml);

			const response:ResponseGenerator = await API.post("mailCompose", formData);

			if (response.data?.error) {
				await FMAlert.fire({
					html: response.data.error.textContent,
					color: "#ED1F24"
				});
			} else if (response.data?.success) {
				setFiles([] as Array<File>);
				dispatch({ type: FETCH_USER_MAILS });
				setValue(RichTextEditor.createEmptyValue());
				setEmpty(false);
				await FMAlert.fire({
					html: lang.ms_02 + "<br>" + lang.ms_03,
					color: "#28A64A"
				});

				reset({
					subject: "",
					department: ""
				});
			}
		} catch (e) {
			console.log(e);
			await FMAlert.fire({
				html: lang.ms_10 + " " + lang.ms_31, // + e,
				color: "#ED1F24"
			});
		} finally {
			setIsFetch(false);
		}
	}, [ value, files ]);

	const onChange = (value:SetStateAction<EditorValue>) => {
		setEmpty(false);
		setValue(value);
	};

	const renderOptions = () => {
		return departments?.map((item:IDepartment, index:number) => {
			return <option key={index} value={item.id}>{item.email}</option>;
		});
	};

	return (
		<div className="page-rebate-default">
			<PageTitle
				layoutTitle={lang.ms_01}
				pageTitle={lang.ms_08}
			/>
			<Box sx={{ position: "relative" }}>
				<Form
					defaultValues={[ "", "", [] ]}
					onSubmit={sendData}
					validationSchema={validationScheme}
					fieldWrapperVariant="for-auth"
				>
					<Box className={"flexRow"} sx={{
						"&>div": {
							marginX: "5px"
						},
						"& input": {
							borderRadius: "4px"
						}
					}}>
						<Input
							type="text"
							name="subject"
							label={lang.ms_11}
						/>
						{departments && departments.length && <Select
							name="department"
							label={lang.ms_05}
						>
							<option key={100}></option>
							{renderOptions()}
						</Select>}
					</Box>
					<Box sx={{ position: "relative" }}>
						<Rte value={value} setValue={onChange} error={empty} />
						{empty && <Text
							color="#ff0000"
							fontSize={12}
							sx={{
								position: "absolute",
								bottom: 0,
								width: "100%",
								textAlign: "right",
								padding: "5px 10px"
							}}
						>
							{lang.ms_33}
						</Text>}
					</Box>
					<InputRow sx={{ justifyContent: isMobile ? "flex-start" : "flex-end", gap: isMobile ? "15px" : "24px" }}>
						<AttachFiles
							name={"files[]"}
							maxFiles={maxFiles}
							inputRef={filesRef}
							files={files}
							setFiles={setFiles}
						/>
						<ButtonWithLoader isFetch={isFetch} color={"primary"} text={lang.ms_04} iconStart={<SendIcon/>} type="submit"/>
					</InputRow>
				</Form>
			</Box>
		</div>
	);
};
