import React from "react";

const FinanceIcon = (props:any) => {
	let width = 16;
	let fill = "#417cbf";
	if (props.width) {
		width = props.width;
	}
	if (props.fill) {
		fill = props.fill;
	}

	return (
		<svg width={width} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 16">
			<defs>
				<clipPath id="clipPath">
					<rect id="Прямоугольник_80" data-name="Прямоугольник 80" width="16" height="16" transform="translate(20 22)" fill="#fff"/>
				</clipPath>
			</defs>
			<g id="Группа_масок_27" data-name="Группа масок 27" transform="translate(-20 -22)" clipPath="url(#clipPath)">
				<g id="icon-fm-wallet" transform="translate(18.549 20.681)">
					<path id="Контур_167" data-name="Контур 167" d="M16.822,4.65H15.186l-1-2.91a.608.608,0,0,0-.326-.363.623.623,0,0,0-.489-.015L5.05,4.65H2.073a.626.626,0,0,0-.622.622V16.7a.626.626,0,0,0,.622.622H16.829a.626.626,0,0,0,.622-.622V5.272A.623.623,0,0,0,16.822,4.65Zm-8.374,0,4.783-1.888.644,1.888Zm4.213,4.1H16.2v4.465H12.661a2.236,2.236,0,0,1,0-4.465ZM9.3,10.98a3.467,3.467,0,0,0,3.361,3.48H16.2v1.607H2.7V5.894H16.2V7.5H12.661A3.467,3.467,0,0,0,9.3,10.98Z" fill={fill}/>
					<path id="Контур_168" data-name="Контур 168" d="M13.016,12.083a1.1,1.1,0,1,0-1.1-1.1A1.106,1.106,0,0,0,13.016,12.083Z" fill={fill}/>
				</g>
			</g>
		</svg>
	);
};

export default FinanceIcon;
