import * as React from "react";
import { DepositPayTrust } from "../../../../components/Forms/DepositPayTrustForm";
import { calcInMYR } from "../../../../utils/calcInCurrencies";

export function PayTrust () {
	async function submit () {
		throw new Error("Implement me [Deposit>LocalBankTransferVietnam>PayTrust]");
	}

	return (
		<>
			<DepositPayTrust
				submit={submit}
				calcInCurrency={calcInMYR}
			/>
		</>
	);
}
