import { Portal } from "@mui/material";
import * as React from "react";
import { Redirect, Route } from "react-router-dom";
import { FormNavigation } from "../../../../components/FormNavigation";
import { PayTrust } from "./PayTrust";
import { ZotaPay } from "./ZotaPay";

export interface LocalBankTransferIndonesiaProps {
	tabsMenuContainer: React.MutableRefObject<HTMLElement | null>
}

export function LocalBankTransferIndonesia (props: LocalBankTransferIndonesiaProps) {
	const { tabsMenuContainer } = props;

	return (
		<>
			<Portal container={tabsMenuContainer.current}>
				<FormNavigation
					paths={[
						{
							to: "/app/withdraw/idr-local-bank-transfer/pay-trust",
							title: "PayTrust",
							styles: {
								marginRight: "15px"
							}
						},
						{
							to: "/app/withdraw/idr-local-bank-transfer/zota-pay",
							title: "ZotaPay"
						}
					]}
				/>
			</Portal>

			<Route exact path="/app/withdraw/idr-local-bank-transfer">
				<Redirect to="/app/withdraw/idr-local-bank-transfer/pay-trust" />
			</Route>
			<Route
				path="/app/withdraw/idr-local-bank-transfer/pay-trust"
			>
				<PayTrust/>
			</Route>
			<Route
				path="/app/withdraw/idr-local-bank-transfer/zota-pay"
			>
				<ZotaPay />
			</Route>
		</>
	);
}
