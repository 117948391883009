import { SxProps } from "@mui/material";

export const wrapperStyles: SxProps = {
	padding: "10px"
};

export const xsJustifyContentStyles: SxProps = {
	...wrapperStyles,
	display: "flex",
	justifyContent: "flex-start"
};

export const mdJustifyContentStyles: SxProps = {
	...wrapperStyles,
	display: "flex",
	justifyContent: "center"
};
