/*
    Created on : 12.10.2022, 06:18:59
    Author     : Andy Kar
*/

import { all } from "redux-saga/effects";
import { sessionWatcher } from "./sessionSaga";
import {
	changeLanguageWatcher,
	languageBannerWatcher,
	languageBonusWatcher,
	languageCopytradeWatcher,
	languageDatatableWatcher,
	languageFinanceWatcher,
	languageMailSupportTradesWatcher,
	languageMyAccountsWatcher,
	languagePartnershipWatcher,
	languageSmartDollarWatcher,
	languageTradesWatcher,
	languageTradingWatcher,
	languageWatcher
} from "./languageSaga";
import { initLinksWatcher, initWatcher } from "./initSaga";
import { feedbackWatcher, notificationWatcher, updateNotificationWatcher } from "./feedbackSaga";
import {
	getCloseTradesHistoryWatcher,
	getCommissionSettingsWatcher,
	getConditionWatcher,
	getMyProjectWatcher,
	getOpenTradesHistoryWatcher,
	getPastSubscriptionsWatcher,
	getProfileInformationWatcher,
	getTradersWatcher,
	getYourFollowersWatcher,
	subscriptionsWatcher,
	tradersWatcher
} from "./copytradingSaga";
import {
	uploadUserDocWatcher,
	userBonusesWatcher,
	userCorpDocsWatcher,
	userDocsWatcher,
	userPaymentMethodsWatcher,
	userWatcher,
	userWithdrawMethodsWatcher
} from "./userSaga";
import {
	authPartnerWatcher,
	authWatcher,
	forgotPassWatcher,
	getTwoFactorAuthWatcher,
	logoutWatcher,
	resetPassWatcher,
	verifyTFAWatcher
} from "./authSaga";
import {
	changeAvatarWatcher,
	myAccountsWatcher,
	personalDetailsEditWatcher,
	phoneValidateWatcher,
	phoneVerifyCodeWatcher,
	removeAccountWatcher,
	removeAvatarWatcher,
	switchAccountWatcher,
	updateLeverageWatcher,
	updateSendReportsWatcher,
	updateSwapWatcher
} from "./myAccountsSaga";
import { accountFundsWatcher, accountTradingWatcher } from "./accountSaga";
import {
	partnershipClicksWatcher,
	partnershipCommissionWatcher,
	partnershipFilteredClicksWatcher,
	partnershipReferralsRegistrationWatcher,
	partnershipReferralsWatcher,
	partnershipSecurityCodeWatcher,
	partnershipWatcher
} from "./partnershipSaga";
import { tradesHistoryWatcher, tradesWatcher } from "./tradesSaga";
import { balanceOperationsWatcher, getBonusesByDateWatcher, pendingTransactionWatcher } from "./financeSaga";
import { getPersonalProjectsWatcher, getProjectsWatcher, getReferralsWatcher, getStatisticsWatcher } from "./rebateSaga";
import { userMailsWatcher } from "./mailSupportSaga";
import { bannersShowWatcher, bannersWatcher } from "./bannersSaga";
import {
	fetchAsianBanksInfoWatcher,
	fetchBitcoinCashInfoWatcher,
	fetchBitcoinInfoWatcher,
	fetchBrazilPixInfoWatcher,
	fetchIdrPayTrustInfoWatcher,
	fetchIdrZotaPayInfoWatcher,
	fetchMobileMoneyAirtelInfo,
	fetchMobileMoneyEuMobileInfo,
	fetchMobileMoneyHalopesaInfo,
	fetchMobileMoneyMpesaInfo,
	fetchMobileMoneyMtnInfo,
	fetchMobileMoneyOrangeInfo,
	fetchMobileMoneyTigoInfo,
	fetchMobileMoneyVodacomInfo,
	fetchMobileMoneyVodafoneInfo,
	fetchMobileMoneyZamtelInfo,
	fetchOnlineNairaInfoWatcher,
	fetchMyrPayTrustInfoWatcher,
	fetchMyrZotaPayInfoWatcher,
	fetchThbPayTrustInfoWatcher,
	fetchThbZotaPayInfoWatcher,
	fetchChinaUnionPayInfoWatcher,
	fetchFasapayInfoWatcher,
	fetchLocalBankInfoWatcher,
	fetchLocalBankTransferMexicoInfoWatcher,
	fetchNetellerInfoWatcher,
	fetchPaycoInfoWatcher,
	fetchSkrillInfoWatcher,
	fetchThunderXPayInfoWatcher,
	fetchVndZotaPayInfoWatcher,
	fetchVndPayTrustInfoWatcher,
	fetchVnPayInfoWatcher,
	fetchTetherInfoWatcher
} from "./withdrawMethodsInfoSaga";
import { activateSmartDollarsWatcher, requestSmartDollarsWatcher, smartDollarsWatcher } from "./smartDollarsSaga";
import { myAchievementsWatcher, requestMyAchievementsWatcher } from "./myAchievementsSaga";

export function * rootWatcher () {
	yield all([
		initWatcher(),
		userWatcher(),
		userPaymentMethodsWatcher(),
		userWithdrawMethodsWatcher(),
		userBonusesWatcher(),
		userDocsWatcher(),
		userCorpDocsWatcher(),
		uploadUserDocWatcher(),
		initLinksWatcher(),
		sessionWatcher(),
		authWatcher(),
		authPartnerWatcher(),
		forgotPassWatcher(),
		resetPassWatcher(),
		logoutWatcher(),
		languageWatcher(),
		languageCopytradeWatcher(),
		languageMyAccountsWatcher(),
		languageFinanceWatcher(),
		languageBonusWatcher(),
		languagePartnershipWatcher(),
		languageSmartDollarWatcher(),
		languageDatatableWatcher(),
		languageTradingWatcher(),
		languageTradesWatcher(),
		languageMailSupportTradesWatcher(),
		languageBannerWatcher(),
		changeLanguageWatcher(),
		notificationWatcher(),
		updateNotificationWatcher(),
		feedbackWatcher(),
		tradersWatcher(),
		tradesWatcher(),
		tradesHistoryWatcher(),
		subscriptionsWatcher(),
		personalDetailsEditWatcher(),
		phoneValidateWatcher(),
		phoneVerifyCodeWatcher(),
		changeAvatarWatcher(),
		removeAvatarWatcher(),
		myAccountsWatcher(),
		updateLeverageWatcher(),
		updateSwapWatcher(),
		updateSendReportsWatcher(),
		switchAccountWatcher(),
		removeAccountWatcher(),
		accountFundsWatcher(),
		accountTradingWatcher(),
		partnershipWatcher(),
		partnershipCommissionWatcher(),
		partnershipClicksWatcher(),
		partnershipFilteredClicksWatcher(),
		partnershipReferralsWatcher(),
		partnershipReferralsRegistrationWatcher(),
		partnershipSecurityCodeWatcher(),
		getProfileInformationWatcher(),
		pendingTransactionWatcher(),
		balanceOperationsWatcher(),
		getMyProjectWatcher(),
		getYourFollowersWatcher(),
		getTradersWatcher(),
		getPastSubscriptionsWatcher(),
		getConditionWatcher(),
		getOpenTradesHistoryWatcher(),
		getCloseTradesHistoryWatcher(),
		getCommissionSettingsWatcher(),
		getProjectsWatcher(),
		getReferralsWatcher(),
		getPersonalProjectsWatcher(),
		userMailsWatcher(),
		bannersWatcher(),
		bannersShowWatcher(),
		getStatisticsWatcher(),
		getTwoFactorAuthWatcher(),
		verifyTFAWatcher(),
		getBonusesByDateWatcher(),
		fetchBitcoinInfoWatcher(),
		fetchBitcoinCashInfoWatcher(),
		fetchMyrPayTrustInfoWatcher(),
		fetchMyrZotaPayInfoWatcher(),
		fetchAsianBanksInfoWatcher(),
		fetchThbPayTrustInfoWatcher(),
		fetchThbZotaPayInfoWatcher(),
		fetchIdrPayTrustInfoWatcher(),
		fetchIdrZotaPayInfoWatcher(),
		fetchMobileMoneyTigoInfo(),
		fetchMobileMoneyAirtelInfo(),
		fetchMobileMoneyMtnInfo(),
		fetchMobileMoneyVodacomInfo(),
		fetchMobileMoneyVodafoneInfo(),
		fetchMobileMoneyMpesaInfo(),
		fetchMobileMoneyZamtelInfo(),
		fetchMobileMoneyHalopesaInfo(),
		fetchMobileMoneyOrangeInfo(),
		fetchMobileMoneyEuMobileInfo(),
		fetchBrazilPixInfoWatcher(),
		fetchOnlineNairaInfoWatcher(),
		fetchIdrZotaPayInfoWatcher(),
		smartDollarsWatcher(),
		activateSmartDollarsWatcher(),
		requestSmartDollarsWatcher(),
		myAchievementsWatcher(),
		requestMyAchievementsWatcher(),
		fetchChinaUnionPayInfoWatcher(),
		fetchFasapayInfoWatcher(),
		fetchLocalBankInfoWatcher(),
		fetchLocalBankTransferMexicoInfoWatcher(),
		fetchNetellerInfoWatcher(),
		fetchPaycoInfoWatcher(),
		fetchSkrillInfoWatcher(),
		fetchThunderXPayInfoWatcher(),
		fetchVndZotaPayInfoWatcher(),
		fetchVndPayTrustInfoWatcher(),
		fetchVnPayInfoWatcher(),
		fetchTetherInfoWatcher()
	]);
}
