/*
    Created on : 12.11.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { IFMTableComponentProps } from "../../../../components/FMTable/FMTableCellComponent";
import { IBannerListItem } from "../../../../store/reducers/bannersReducer";
import { Box } from "@mui/material";
import Banner from "./Banner";

export default function BannersRow (props: IFMTableComponentProps<IBannerListItem, { affiliateCode: string | undefined }>) {
	const { banners, componentData } = props;
	return <Box sx={{
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "center",
		p: "15px"
	}}>
		{banners.map((banner, index) => <Banner
			key={(index + 1) + "-" + banner.id}
			banner={banner}
			affiliateCode={componentData?.affiliateCode}
		/>)}
	</Box>;
}
