import * as Yup from "yup";

type BitcoinErrors = {
	deposit_err_05: string;
	deposit_err_01: string;
	deposit_err_03: string;
	deposit_err_04: string;
}

export const bitcoinValidationSchema = (language: BitcoinErrors) => Yup.object().shape({
	currency: Yup.string().required(),
	amount: Yup
		.number()
		.min(5, language.deposit_err_05)
		.max(100000000, language.deposit_err_01)
		.required(language.deposit_err_03)
		.typeError(language.deposit_err_04)
});
