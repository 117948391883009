/* eslint-disable camelcase */
import * as React from "react";
import * as Yup from "yup";
import { Box, Grid, SxProps } from "@mui/material";
import { Form } from "../../../../../components/Form";
import { Input } from "../../../../../components/Form/Input";
import { useAppDispatch, useAppSelector } from "../../../../../utils/hooks/reduxHooks";
import { MultipleFields } from "./MultipleFields";
import { ButtonWithLoader } from "../../../../../components/ButtonWithLoader";
import API from "../../../../../utils/API";
import { createUpdateProfileDetailsAction } from "../../../../../store/actions/actionTypes";
import { useAdaptive } from "../../../../../utils/hooks/themeBreakpointsHooks";

const validation = Yup.object().shape({});

const xsInputStyles: SxProps = {
	width: "100%",
	padding: 0
};

const xsRightInputStyles: SxProps = {
	...xsInputStyles,
	paddingTop: "20px"
};

const smInputStyles: SxProps = {
	width: "50%"
};

const smLeftInputStyles: SxProps = {
	...smInputStyles,
	paddingRight: "16px"
};

const smRightInputStyles: SxProps = {
	...smInputStyles,
	paddingLeft: "16px",
	paddingTop: 0
};

export function AccountInformation () {
	const languageCopytrade = useAppSelector(state => state.language.languageCopytrade);
	const languageInit = useAppSelector(state => state.language.language);
	const accountNumber = useAppSelector(state => state.user.user.account_number);
	const {
		ProjectName,
		desc_4,
		desc_1,
		desc_3,
		desc_2
	} = useAppSelector(state => state.copytrade?.profile?.details || {});
	const dispatch = useAppDispatch();

	const [ isFetch, setIsFetch ] = React.useState(false);

	const submit = React.useCallback(async (values: any) => {
		setIsFetch(true);
		try {
			const formData = new FormData();
			formData.append("project_name", values.projectName);
			formData.append("desc_en", values.descEn);
			formData.append("desc_ru", values.descRu);
			formData.append("desc_pl", values.descPl);
			formData.append("desc_jp", values.descJp);

			await API.post("copytradeUpdateAccountInformation", formData);

			dispatch(createUpdateProfileDetailsAction({
				desc_1: values.descRu,
				desc_2: values.descJp,
				desc_3: values.descPl,
				desc_4: values.descEn
			}));
		} catch (e) {
			//
		} finally {
			setIsFetch(false);
		}
	}, []);

	const defaultValues = React.useMemo(() => ({
		accountNumber,
		projectName: ProjectName,
		descEn: desc_4,
		descRu: desc_1,
		descPl: desc_3,
		descJp: desc_2
	}), [ accountNumber, ProjectName, desc_1, desc_2, desc_3, desc_4 ]);

	const adaptiveLeftInputStyles = useAdaptive({
		xs: xsInputStyles,
		sm: smLeftInputStyles
	});

	const adaptiveRightInputStyles = useAdaptive({
		xs: xsRightInputStyles,
		sm: smRightInputStyles
	});

	return (
		<>
			<Form
				defaultValues={defaultValues}
				onSubmit={submit}
				validationSchema={validation}
				fieldWrapperVariant="with-tip"
			>
				<Box
					sx={{
						border: "1px solid #e2e7e8",
						borderTop: 0,
						padding: "23px"
					}}
				>
					<Grid
						container
						flexDirection="row"
						sx={{ marginBottom: "19px" }}
					>
						<Grid item sx={adaptiveLeftInputStyles}>
							<Input name="accountNumber" label={languageCopytrade.copytrade_33} variant="rounded" />
						</Grid>
						<Grid item sx={adaptiveRightInputStyles}>
							<Input name="projectName" label={languageCopytrade.reb_txt_9} variant="rounded" />
						</Grid>
					</Grid>
					<MultipleFields label={languageCopytrade.descriptions} />
				</Box>
				<ButtonWithLoader
					sxButton={{
						maxWidth: "240px",
						width: "100%"
					}}
					sx={{
						marginTop: "30px",
						maxWidth: "240px"
					}}
					color="success"
					isFetch={isFetch}
					text={languageInit.cs_06}
				/>
			</Form>
		</>
	);
}
