/*
    Created on : 20.01.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../interfaces/StoreInterfaces";
import PageTitle from "../../../components/Dashboard/dashboardComponents/PageTitle";
import PersonalDetails from "./components/PersonalDetails/PersonalDetails";
import AccountsList from "./components/AccountsList/AccountsList";

export default function PagePersonalDetails () {
	const langMyAccounts:any = useSelector((state:StoreInterface) => state.language.languageMyAccounts);

	return (
		<div className="page-personal-details">
			<PageTitle pageTitle={langMyAccounts.mya_01} />
			<PersonalDetails/>
			<AccountsList/>
		</div>
	);
}
