/*
    Created on : 24.01.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import PageTitle from "../../../components/Dashboard/dashboardComponents/PageTitle";
import PromotionCommon from "../PromotionCommon";
import FMTableColumn from "../../../components/FMTable/FMTableColumn";
import FMTable from "../../../components/FMTable/FMTable";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../interfaces/StoreInterfaces";
import { PagePromotionalMaterials } from "../../../layouts/LayoutPromotionalMaterials/LayoutPromotionalMaterials";
import LogoCell from "./components/LogoCell";
import { ILogo, ILogos } from "../../../store/reducers/bannersReducer";
import LogoActionCell from "./components/LogoActionCell";

export type ILogoCompData = {
	url_images: string
	url_pdf: string
}

export default function PagePromotionalMaterialsLogo (props: PagePromotionalMaterials) {
	const { affiliateCode, setAffiliateCode, affiliateCodes } = props;
	const lang:any = useSelector((state:StoreInterface) => state.language.languageBanners);
	const logosData:ILogos = useSelector((state:StoreInterface) => state.banners.banners.logos);
	const logosTableData:Array<ILogo> = logosData.logos;

	const componentData: ILogoCompData = {
		url_images: logosData.url_images,
		url_pdf: logosData.url_pdf
	};

	return (
		<div className="page-rebate-personal">
			<PageTitle
				layoutTitle={lang.b_t_td_8} // "Promotional Materials"
				pageTitle={lang.ext_Fxpp}
			/>

			<PromotionCommon
				affiliateCode={affiliateCode}
				setAffiliateCode={setAffiliateCode}
				affiliateCodes={affiliateCodes}
			/>

			<FMTable
				id="PromotionalMaterialsLogo"
				rowsPerPageOptions={[ 10, 20 ]}
				data={logosTableData}
				theme={"CopyTradingTheme"}
				componentData={componentData}
				mobileScheme={{
					mode: "auto",
					auto: { collapsibleColumns: [ 2 ] }
				}}
			>
				<FMTableColumn id={"Logo"} label={lang.ext_logo} component={LogoCell} />
				<FMTableColumn id={"DownloadAs"} label={lang.ext_downas} component={LogoActionCell}/>
			</FMTable>
		</div>
	);
};
