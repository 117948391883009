import * as React from "react";
import PageTitle from "../../../components/Dashboard/dashboardComponents/PageTitle";
import { SimpleTabs } from "../../../components/UI/Tabs";
import { useAppSelector } from "../../../utils/hooks/reduxHooks";
import { PostSubscriptions } from "./PostSubscriptions";
import { TradersYouSubscribedTo } from "./TradersYouSubscribedTo";
import { YourFollowers } from "./YourFollowers";

export function MySubscriptions () {
	const langCopytrade = useAppSelector((state) => state.language.languageCopytrade);
	const langTrading = useAppSelector((state) => state.language.languageTrading);

	return (
		<>
			<PageTitle
				pageTitle={langCopytrade.reg_trader_02}
				layoutTitle={langCopytrade.reg_trader_01}
			/>
			<SimpleTabs
				fontSize="14px"
				labels={[
					langTrading.trd_101,
					langTrading.trd_109,
					langCopytrade.trd_295
				]}
			>
				<YourFollowers />
				<TradersYouSubscribedTo />
				<PostSubscriptions />
			</SimpleTabs>
		</>
	);
}
