/* eslint-disable camelcase */
import * as React from "react";
import { Alert, Box, Snackbar } from "@mui/material";
import { StatusCode } from "../types";
import { ReactComponent as NoCallIcon } from "../../../../images/NoCallIcon.svg";
import { ReactComponent as SuccessIcon } from "../../../../images/SuccessIcon.svg";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { IFMTableComponentProps } from "../../../../components/FMTable/FMTableCellComponent";
import { Trader } from "../../../../interfaces/CopytradeInterfaces";
import API from "../../../../utils/API";
import { approveYourFollowerSubscription, SubscriptionStatus, unsubscribeTraderByConnectionId, unsubscribeYourFollower, updateTraderSubscriptionStatus } from "../../../../store/actions/actionTypes";
import { ButtonWithLoader } from "../../../../components/ButtonWithLoader";

interface IActionProps {
	connectionId: number;
	follower: number;
	trader: number;
	isTraderTable?: boolean
}

const status: Record<StatusCode, (props: IActionProps) => JSX.Element> = {
	[StatusCode.PENDING]: ApproveCancelAction,
	[StatusCode.APPROVED]: UnsubscribeAction,
	[StatusCode.CANCELED_BY_FOLLOWER]: () => <></>,
	[StatusCode.CANCELED_BY_TRADER]: () => <></>
};

export function ActionsCell (props: IFMTableComponentProps<Trader, {}>) {
	const { ConnectionId, StatusId, Follower, Trader } = props;
	const Action = React.useMemo(() => status[StatusId], [ StatusId ]);

	return (
		<Box sx={{ padding: "10px 0" }}>
			<Action
				connectionId={ConnectionId}
				follower={Follower}
				trader={Trader}
			/>
		</Box>

	);
};

export function UnsubscribeActionCell (props: IFMTableComponentProps<Trader, {}>) {
	const { ConnectionId, Follower, Trader } = props;

	return (
		<Box sx={{ padding: "10px 0" }}>
			<UnsubscribeAction
				connectionId={ConnectionId}
				follower={Follower}
				trader={Trader}
				isTraderTable
			/>
		</Box>
	);
}

function UnsubscribeAction (props: IActionProps) {
	const { connectionId, follower, trader, isTraderTable } = props;
	const { languageMyAccounts, languageTrading } = useAppSelector((state) => state.language);
	const { account_number } = useAppSelector(state => state.user.user);
	const dispatch = useAppDispatch();
	const [ isFetch, setIsFetch ] = React.useState(false);
	const [ isOpen, setIsOpen ] = React.useState(false);
	const [ errorMsg, setErrorMsg ] = React.useState("");

	const hideErrorMsg = React.useCallback(() => {
		setIsOpen(false);
		setErrorMsg("");
	}, [ setErrorMsg, setIsOpen ]);

	const unsubscribe = React.useCallback(async () => {
		setIsFetch(true);
		try {
			const formData = new FormData();
			formData.append("connection", `${connectionId}`);
			formData.append("status", `${+(account_number || 0) === follower ? StatusCode.CANCELED_BY_FOLLOWER : StatusCode.CANCELED_BY_TRADER}`);
			formData.append("follower", `${follower}`);
			formData.append("trader", `${trader}`);
			const response = await API.post<{ success: boolean }>("copytradeUnsubscribeAccount", formData);
			if (response.data.success) {
				dispatch(isTraderTable ? unsubscribeTraderByConnectionId(connectionId) : unsubscribeYourFollower(connectionId));
				dispatch(updateTraderSubscriptionStatus({ userId: trader, status: SubscriptionStatus.UNSUBSCRIBED }));
			}
		} catch (e) {
			setErrorMsg(languageTrading.trd_242);
			setIsOpen(true);
		} finally {
			setIsFetch(false);
		}
	}, [ connectionId, follower, trader ]);

	return (
		<>
			<ButtonWithLoader
				color="error"
				iconStart={<NoCallIcon />}
				onClick={unsubscribe}
				text={languageMyAccounts.btn_unsubscribe}
				type="button"
				isFetch={isFetch}
			/>
			<Snackbar open={isOpen} autoHideDuration={4000} onClose={hideErrorMsg}>
				<Alert variant="filled" color="error">
					{errorMsg}
				</Alert>
			</Snackbar>
		</>
	);
}

function ApproveCancelAction (props: IActionProps) {
	const { connectionId, follower, trader } = props;
	const { languageTrading } = useAppSelector(state => state.language);
	const { account_number } = useAppSelector(state => state.user.user);
	const dispatch = useAppDispatch();
	const [ isFetchCancel, setIsFetchCancel ] = React.useState(false);
	const [ isFetchApprove, setIsFetchApprove ] = React.useState(false);
	const [ isOpen, setIsOpen ] = React.useState(false);
	const [ errorMsg, setErrorMsg ] = React.useState("");

	const hideErrorMsg = React.useCallback(() => {
		setIsOpen(false);
		setErrorMsg("");
	}, [ setErrorMsg, setIsOpen ]);

	const unsubscribe = React.useCallback(async () => {
		setIsFetchCancel(true);
		try {
			const formData = new FormData();
			formData.append("connection", `${connectionId}`);
			formData.append("status", `${+(account_number || 0) === follower ? StatusCode.CANCELED_BY_FOLLOWER : StatusCode.CANCELED_BY_TRADER}`);
			formData.append("follower", `${follower}`);
			formData.append("trader", `${trader}`);
			await API.post("copytradeUnsubscribeAccount", formData);

			dispatch(unsubscribeYourFollower(connectionId));
		} catch (e) {
			setErrorMsg(languageTrading.trd_242);
			setIsOpen(true);
		} finally {
			setIsFetchCancel(false);
		}
	}, [ connectionId, follower, trader ]);

	const approve = React.useCallback(async () => {
		setIsFetchApprove(true);
		try {
			const formData = new FormData();
			formData.append("connection", `${connectionId}`);
			formData.append("status", `${StatusCode.APPROVED}`);
			await API.post("copytradeUpdateFollowerStatus", formData);

			dispatch(approveYourFollowerSubscription(connectionId));
		} catch (e) {
			setErrorMsg(languageTrading.trd_242);
			setIsOpen(true);
		} finally {
			setIsFetchApprove(false);
		}
	}, [ connectionId ]);

	return (
		<>
			<Box sx={{ display: "flex", flexDirection: "row" }}>
				<ButtonWithLoader
					color="success"
					iconStart={<SuccessIcon />}
					sxButton={{ maxWidth: "100px" }}
					text={languageTrading.trd_282}
					onClick={approve}
					isFetch={isFetchApprove}
					type="button"
				/>
				<ButtonWithLoader
					sx={{ marginLeft: "5px" }}
					sxButton={{ maxWidth: "100px" }}
					color="error"
					iconStart={<SuccessIcon />}
					text={languageTrading.btn_cancel}
					onClick={unsubscribe}
					isFetch={isFetchCancel}
					type="button"
				/>
			</Box>
			<Snackbar open={isOpen} autoHideDuration={4000} onClose={hideErrorMsg}>
				<Alert variant="filled" color="error">
					{errorMsg}
				</Alert>
			</Snackbar>
		</>
	);
}
