import * as React from "react";
import { IFMTableComponentProps } from "../../../../components/FMTable/FMTableCellComponent";
import { StoreInterface } from "../../../../interfaces/StoreInterfaces";
import { useSelector } from "react-redux";
import { IBalanceOperation } from "../../../../store/reducers/financeReducer";
import { Button } from "@mui/material";

export function BalanceOperationActionCell (props: IFMTableComponentProps<IBalanceOperation, {}>) {
	const { toggleCollapsible } = props;
	const langFinance:any = useSelector((state:StoreInterface) => state.language.languageFinance);

	return <Button
		variant="text"
		onClick={toggleCollapsible}
		sx={{
			padding: 0,
			width: "100%"
		}}
	>
		{langFinance.View_Details}
	</Button>;
}
