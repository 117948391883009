import * as React from "react";
import { Box, Typography, SxProps } from "@mui/material";
import { Row } from "../../../../../components/UI/Row";
import { useAdaptive } from "../../../../../utils/hooks/themeBreakpointsHooks";

const xsWrapperStyles: SxProps = {
	minWidth: "calc(50% - 4.5px)"
};

const smWrapperStyles: SxProps = {
	minWidth: "content-fit"
};

export const LangDescSelectTab: React.FC<{
	icon: any,
	text: string,
	active: number,
	index: number,
	setActive: (a: number) => void
}> = ({
	icon,
	text,
	active,
	index,
	setActive
}) => {
	const isActive = active === index;
	const adaptiveWrapperStyles = useAdaptive({
		xs: xsWrapperStyles,
		sm: smWrapperStyles
	});

	return (
		<Box sx={adaptiveWrapperStyles}>
			<Box p={"6px 8px"} sx={{ background: isActive ? "#2988CA" : "#E2E7E8", cursor: "pointer" }} borderRadius={"4px"} onClick={() => setActive(index)}>
				<Row alignItems={"center"}>
					{icon}
					<Typography color={isActive ? "#fff" : "#5C7080"} ml={"9px"}>{text}</Typography>
				</Row>
			</Box>
		</Box>
	);
};
