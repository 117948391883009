import { Box } from "@mui/material";
import * as React from "react";
import { ButtonWithLoader } from "../../../../../components/ButtonWithLoader";
import { FormContext } from "../../../../../components/Form";
import { createSetCpyAvatarAction } from "../../../../../store/actions/actionTypes";
import API from "../../../../../utils/API";
import { useAppDispatch, useAppSelector } from "../../../../../utils/hooks/reduxHooks";

export interface IRemoveAvatarProps {
	name: string;
}

export function RemoveAvatar (props: IRemoveAvatarProps) {
	const { name } = props;
	const { setValue } = React.useContext(FormContext);
	const [ isFetch, setIsFetch ] = React.useState(false);
	const languageMyAccounts = useAppSelector(state => state.language.languageMyAccounts);
	const dispatch = useAppDispatch();

	const remove = React.useCallback(async () => {
		setIsFetch(true);
		try {
			await API.post("copytradeRemoveAvatar");
			setValue(name, { path: "", file: null, selectedPhoto: "" });
			dispatch(createSetCpyAvatarAction({ mt_accounts_cpy_avatar: "" }));
		} catch (e) {
			//
		} finally {
			setIsFetch(false);
		}
	}, []);

	return (
		<Box
			sx={{
				paddingTop: "20px",
				paddingBottom: "36px"
			}}
		>
			<ButtonWithLoader
				color="secondary"
				type="button"
				onClick={remove}
				text={languageMyAccounts.perdet_00}
				isFetch={isFetch}
				sxButton={{
					maxWidth: "400px",
					width: "100%"
				}}
			/>
		</Box>
	);
}
