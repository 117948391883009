/*
Created on : 09.05.2022, 06:18:59
Author     : Andy Kar
*/
import * as React from "react";
import MyAccountIcon from "../../Icons/myAccountsMenuIcons/MyAccountIcon";
import { useDispatch, useSelector } from "react-redux";
import { StoreInterface } from "../../../interfaces/StoreInterfaces";
import ChangPasswordIcon from "../../Icons/myAccountsMenuIcons/ChangPasswordIcon";
import AccountVerificationIcon from "../../Icons/myAccountsMenuIcons/AccountVerificationIcon";
import SecurityInformationIcon from "../../Icons/myAccountsMenuIcons/SecurityInformationIcon";
import { SEND_LOGOUT } from "../../../store/actions/actionTypes";
import LogOutIcon from "../../Icons/myAccountsMenuIcons/LogOutIcon";
import AppBatAccountMenuItem from "./AppBarMyAccountMenuItem";

const AppBarMyAccountMenuInner = ({ setOpen, setAccordion }: any) => {
	const lang:any = useSelector((state:StoreInterface) => state.session.userData.site_lang);
	const langMyAccounts:any = useSelector((state:StoreInterface) => state.language.languageMyAccounts);
	const userAccountInfo:any = useSelector((state:StoreInterface) => state.user.user);

	const dispatch = useDispatch();
	const logout = () => {
		dispatch({ type: SEND_LOGOUT });
	};

	return (
		<>
			<AppBatAccountMenuItem
				setOpen={setOpen}
				link={"my-account"}
				icon={<MyAccountIcon />}
				text={langMyAccounts.mya_01}
				setAccordion={setAccordion}
			/>
			<AppBatAccountMenuItem
				setOpen={setOpen}
				link={"profile/edit"}
				icon={<MyAccountIcon />}
				text={langMyAccounts.mfn_01}
				setAccordion={setAccordion}
			/>
			{(userAccountInfo.account_type_status && userAccountInfo.account_type_status.corporate_acc_status && userAccountInfo.account_type_status.corporate_acc_status === 2) &&
				<AppBatAccountMenuItem
					setOpen={setOpen}
					link={"profile/company-details"}
					icon={<MyAccountIcon/>}
					text={langMyAccounts.cmp_dtls_01}
					setAccordion={setAccordion}
				/>
			}
			<AppBatAccountMenuItem
				setOpen={setOpen}
				link={"profile/edit#change-password"}
				icon={<ChangPasswordIcon />}
				text={langMyAccounts.mfn_02}
				setAccordion={setAccordion}
			/>
			<AppBatAccountMenuItem
				setOpen={setOpen}
				link={"profile/upload-document"}
				icon={<AccountVerificationIcon />}
				text={langMyAccounts.mfn_03}
				setAccordion={setAccordion}
			/>
			{(userAccountInfo.account_type_status && userAccountInfo.account_type_status.corporate_acc_status && userAccountInfo.account_type_status.corporate_acc_status !== 0) &&
				<AppBatAccountMenuItem
					setOpen={setOpen}
					link={"profile/upload-documents-for-corporate-account"}
					icon={<AccountVerificationIcon/>}
					text={langMyAccounts.mfn_05}
					setAccordion={setAccordion}
				/>
			}
			<AppBatAccountMenuItem
				setOpen={setOpen}
				link={"profile/two-factor-authentication"}
				icon={<SecurityInformationIcon />}
				text={langMyAccounts.mfn_08}
				setAccordion={setAccordion}
			/>
			<AppBatAccountMenuItem
				setAccordion={logout}
				setOpen={setOpen}
				icon={<LogOutIcon />}
				text={lang && lang.xnv_LO ? lang.xnv_LO : "Log out"}
			/>
		</>
	);
};

export default AppBarMyAccountMenuInner;
