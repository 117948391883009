/* eslint-disable camelcase */
import * as React from "react";
import { Box, Typography } from "@mui/material";
import { ContentWrapper } from "../../../../components/UI/ContentWrapper";
import { useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { DataTableColumn, DataTableRow, DataTableView } from "../../../../components/UI/DataTableView";

export function MyProjectCopyingTerms () {
	const { languageTrading, languageCopytrade } = useAppSelector((state) => state.language);
	const {
		conditions_values_2 = languageCopytrade.not_use,
		conditions_values_3 = languageCopytrade.not_use,
		conditions_values_1 = languageCopytrade.not_use,
		conditions_values_10 = languageCopytrade.not_use,
		conditions_values_4 = languageCopytrade.not_use
	} = useAppSelector(state => state.copytrade.profile.details || {});

	return (
		<ContentWrapper
			title={languageTrading.trd_140}
		>
			<Box sx={{ padding: "10px" }}>
				<DataTableView>
					<DataTableRow>
						<DataTableColumn>
							<Typography color="#5c7080">{languageTrading.trd_112}</Typography>
						</DataTableColumn>
						<DataTableColumn hideBorderRight>
							<Typography fontWeight="bold" color="#5c7080" sx={{ textTransform: "uppercase" }}>
								{conditions_values_1}
							</Typography>
						</DataTableColumn>
					</DataTableRow>
					<DataTableRow>
						<DataTableColumn>
							<Typography color="#5c7080">{languageTrading.trd_114}</Typography>
						</DataTableColumn>
						<DataTableColumn hideBorderRight>
							<Typography fontWeight="bold" color="#5c7080" sx={{ textTransform: "uppercase" }}>
								{conditions_values_2}
							</Typography>
						</DataTableColumn>
					</DataTableRow>
					<DataTableRow>
						<DataTableColumn>
							<Typography color="#5c7080">{languageTrading.trd_115}</Typography>
						</DataTableColumn>
						<DataTableColumn hideBorderRight>
							<Typography fontWeight="bold" color="#5c7080" sx={{ textTransform: "uppercase" }}>
								{conditions_values_10}
							</Typography>
						</DataTableColumn>
					</DataTableRow>
					<DataTableRow>
						<DataTableColumn>
							<Typography color="#5c7080">{languageTrading.trd_116}</Typography>
						</DataTableColumn>
						<DataTableColumn hideBorderRight>
							<Typography fontWeight="bold" color="#5c7080" sx={{ textTransform: "uppercase" }}>
								{conditions_values_3}
							</Typography>
						</DataTableColumn>
					</DataTableRow>
					<DataTableRow>
						<DataTableColumn>
							<Typography color="#5c7080">{languageTrading.trd_117}</Typography>
						</DataTableColumn>
						<DataTableColumn hideBorderRight>
							<Typography fontWeight="bold" color="#5c7080" sx={{ textTransform: "uppercase" }}>
								{conditions_values_4}
							</Typography>
						</DataTableColumn>
					</DataTableRow>
				</DataTableView>
			</Box>
		</ContentWrapper>
	);
}
