import { call, put, takeEvery } from "redux-saga/effects";
import { ActionType } from "../utils/reudxHelpers";
import API from "../utils/API";
import { PaginatedResponseData, TPaginationParams, TResponse } from "../utils/types";
import { IRebateSystemPersonalProject, IRebateSystemProject, IStatisticsItem } from "../interfaces/RebateSystemInterfaces";
import {
	GET_REBATE_SYSTEM_PERSONAL_PROJECTS,
	GET_REBATE_SYSTEM_PROJECTS,
	GET_REBATE_SYSTEM_REFERRALS_LIST,
	GET_REBATE_SYSTEM_STATISTICS,
	setRebateSystemPersonalProjects,
	setRebateSystemPersonalProjectsIsLoad,
	setRebateSystemProjects,
	setRebateSystemProjectsIsLoad,
	setRebateSystemReferrals,
	setRebateSystemStatistics,
	setRebateSystemStatisticsFetch
} from "../store/actions/actionTypes";

/**
 * Get rebate system projects
 */
export function * getProjectsWatcher () {
	yield takeEvery(GET_REBATE_SYSTEM_PROJECTS, getProjectsWorker);
}

export function * getProjectsWorker (action: ActionType<string, TPaginationParams & { actionAfterGettingProjects: () => void }>) {
	try {
		const data: TResponse<PaginatedResponseData<IRebateSystemProject>["data"]> = yield call(getProjects, action.payload);

		if (!data.data.list) {
			yield put(setRebateSystemProjectsIsLoad({ isLoad: false }));
			return;
		}

		if (data.success) {
			action.payload.actionAfterGettingProjects();
			yield put(setRebateSystemProjects(data.data));
		}
	} catch (e) {
		console.error(e);
	}
}

async function getProjects (paginationParams: TPaginationParams): Promise<TResponse<PaginatedResponseData<IRebateSystemProject>["data"]>> {
	const { data } = await API.get<TResponse<PaginatedResponseData<IRebateSystemProject>["data"]>>("rebateSystemGetProject", {
		params: { page: paginationParams.page, limit: paginationParams.limit }
	});

	return data;
}

/**
 * Get rebate system referrals
 */
export function * getReferralsWatcher () {
	yield takeEvery(GET_REBATE_SYSTEM_REFERRALS_LIST, getReferralsWorker);
}

export function * getReferralsWorker () {
	try {
		const data: TResponse<number[]> = yield call(getReferrals);

		if (data.success) {
			yield put(setRebateSystemReferrals(data.data));
		}
	} catch (e) {
		//
	}
}

async function getReferrals (): Promise<TResponse<number[]>> {
	const { data } = await API.get<TResponse<number[]>>("rebateSystemGetReferralsList");

	return data;
}

/**
 * Get rebate system personal projects
 */
export function * getPersonalProjectsWatcher () {
	yield takeEvery(GET_REBATE_SYSTEM_PERSONAL_PROJECTS, getPersonalProjectsWorker);
}

export function * getPersonalProjectsWorker (action: ActionType<string, TPaginationParams & { actionAfterGettingProjects: () => void }>) {
	try {
		const data: TResponse<PaginatedResponseData<IRebateSystemPersonalProject>["data"]> = yield call(getPersonalProjects, action.payload);

		if (!data.data.list) {
			yield put(setRebateSystemPersonalProjectsIsLoad({ isLoad: false }));
			return;
		}

		if (data.success) {
			action.payload.actionAfterGettingProjects();
			yield put(setRebateSystemPersonalProjects(data.data));
		}
	} catch (e) {
		//
	}
}

async function getPersonalProjects (paginationParams: TPaginationParams): Promise<TResponse<PaginatedResponseData<IRebateSystemPersonalProject>>> {
	const { data } = await API.get<TResponse<PaginatedResponseData<IRebateSystemPersonalProject>>>("rebateSystemGetPersonalProjects", {
		params: { page: paginationParams.page, limit: paginationParams.limit }
	});

	return data;
}

/**
 * Get rebate system statistics data for chart
 */
export function * getStatisticsWatcher () {
	yield takeEvery(GET_REBATE_SYSTEM_STATISTICS, getStatisticsWorker);
}

export function * getStatisticsWorker (action: ActionType<string, { client: number, from: string, to: string}>) {
	yield put(setRebateSystemStatisticsFetch(true));
	try {
		const data: TResponse<IStatisticsItem[]> = yield call(getStatisticsProjects, action.payload);

		if (data.success) {
			yield put(setRebateSystemStatistics(data.data));
		}
	} catch (e) {
		//
	} finally {
		yield put(setRebateSystemStatisticsFetch(false));
	}
}

async function getStatisticsProjects (payload: { client: number, from: string, to: string}): Promise<TResponse<IStatisticsItem[]>> {
	const { data } = await API.get<TResponse<IStatisticsItem[]>>("rebateSystemGetStatistics", {
		params: { ...payload }
	});

	return data;
}
