import * as React from "react";
import { Form } from "../../../../components/Form";
import { Input } from "../../../../components/Form/Input";
import { InputRepeater } from "../../../../components/Form/InputRepeater";
import { CurrenciesSymbols, CurrencyType } from "../../../../utils/currency";
import { getWithdrawalValue } from "../../../../utils/getReceivedValue";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { bitcoinWithdrawValidationSchema } from "./validation";
import { FormBottomWrapper } from "../../../../components/Form/FormBottomWrapper";
import { ButtonWithLoader } from "../../../../components/ButtonWithLoader";
import { Alert, Box, Skeleton, Snackbar } from "@mui/material";
import { WaningNote } from "../../../../components/UI/Note";
import API from "../../../../utils/API";
import { AxiosResponse } from "axios";
import { fetchBitcoinInfo } from "../../../../store/actions/actionTypes";

export function Bitcoin () {
	const [ isFetch, setIsFetch ] = React.useState(false);
	const [ success, setSuccess ] = React.useState(false);
	const language = useAppSelector((state) => state.language);
	const accountNumber = useAppSelector((state) => `${state.session.userData.account_number ?? ""}`);
	const currency = useAppSelector((state) => state.session.userData.currency ?? "USD");
	const {
		fee,
		addon,
		feeDetails,
		loading: infoLoading,
		complete
	} = useAppSelector((state) => state.withdrawMethodsInfo.bitcoin);
	const dispatch = useAppDispatch();
	const [ errorMsg, setErrorMsg ] = React.useState("");
	const [ isOpen, setIsOpen ] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (!complete) {
			dispatch(fetchBitcoinInfo({}));
		}
	}, []);

	const getWithdrawalValueCB = React.useCallback((values: any) => getWithdrawalValue(values, currency), [ currency ]);

	function calcFee (values: [string, CurrencyType]) {
		const [ amount = "", currencyType ] = values;
		return `${+amount * fee + addon}${CurrenciesSymbols[currencyType] || CurrenciesSymbols[currency]}`;
	}

	const hideErrorMsg = React.useCallback(() => {
		setIsOpen(false);
		setErrorMsg("");
	}, [ setErrorMsg ]);

	const submit = React.useCallback(async (values: any) => {
		try {
			setIsFetch(true);
			const formData = new FormData();
			formData.append("amount_withdraw", values.amount);
			formData.append("bitcoin_address", values.address);
			const { data } = await API.post<unknown, AxiosResponse<{ success: boolean, errorMsg: string }>>("withdrawBitcoin", formData);
			if (data.success) {
				setSuccess(true);
			} else {
				setErrorMsg(data.errorMsg || "Something went wrong");
				setIsOpen(true);
			}
		} catch (e) {
			// handle error
		} finally {
			setIsFetch(false);
		}
	}, []);

	return (
		<Form
			onSubmit={submit}
			validationSchema={bitcoinWithdrawValidationSchema(language.languageFinance)}
			defaultValues={{
				accountNumber,
				amount: "",
				address: ""
			}}
		>
			<Input
				name="accountNumber"
				label={language.languageCopytrade.cpy_mon_accnum}
				variant="rounded"
			/>
			<Input
				name="amount"
				label={language.languageFinance.trd_230}
				variant="rounded"
			/>
			<InputRepeater
				fields="amount,currency"
				label={language.languageFinance["with-p-04"]}
				onChange={calcFee}
			/>
			<Input
				name="address"
				label={language.languageFinance.trd_271}
				variant="rounded"
			/>
			<FormBottomWrapper
				forFields={[ "amount", "currency" ]}
				onChange={getWithdrawalValueCB}
				label={language.languageMyAccounts.form_message_01}
			>
				<ButtonWithLoader
					isFetch={isFetch}
					color={"primary"}
					text={language.languageMyAccounts.chapas_04}
				/>
			</FormBottomWrapper>
			<Box sx={{ marginTop: "6px" }}>
				{
					infoLoading
						? (
							<Skeleton width={"220px"} height={"26px"} />
						)
						: (
							<WaningNote
								message={`Fee is ${feeDetails}`}
							/>
						)
				}
			</Box>
			<Snackbar open={isOpen} autoHideDuration={4000} onClose={hideErrorMsg}>
				<Alert variant="filled" color="error">
					{errorMsg}
				</Alert>
			</Snackbar>
			<Snackbar
				open={success}
				autoHideDuration={4000}
				onClose={() => setSuccess(false)}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
			>
				<Alert variant="filled" color="success">
					Success
				</Alert>
			</Snackbar>
		</Form>
	);
}
