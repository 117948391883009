import * as React from "react";
import { Box, Typography } from "@mui/material";
import { Row } from "../../Row";
import { DefaultWrapperProps } from "../Default";

export interface IInlineBoldWrapperProps extends DefaultWrapperProps {}

export function InlineBoldWrapper (props: IInlineBoldWrapperProps) {
	const { children, label } = props;
	return (
		<Row sx={{ justifyContent: "space-between", alignItems: "center" }}>
			{
				label
					? (
						<Typography
							sx={{ minWidth: "160px" }}
							fontWeight="bold"
							color="#5c7080"
							textAlign="left"
						>
							{label}
						</Typography>
					)
					: <></>
			}
			<Box sx={{ width: "100%" }}>
				{children}
			</Box>
		</Row>
	);
}
