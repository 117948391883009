import * as React from "react";
import { IFMTable } from "./IFMTable";
import FMTableMain from "./FMTableMain";
import { FMTableTheme, FMTableThemeContext, IFMTableTheme, IFMTableThemeName } from "./FMTableThemes";

export default function FMTable<T, M> (props: IFMTable<T, M>) {
	const { theme = "AccountTheme" as IFMTableThemeName } = props;
	const tableTheme: IFMTableTheme = props.theme ? FMTableTheme[theme as IFMTableThemeName] : FMTableTheme.AccountTheme;
	return (
		<FMTableThemeContext.Provider value={tableTheme}>
			<FMTableMain<T, M> { ...props } />
		</FMTableThemeContext.Provider>
	);
};
