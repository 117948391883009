/*
    Created on : 12.11.2022, 06:18:59
    Author     : Andy Kar
*/
/* eslint-disable camelcase */
import React from "react";
import { IUserSupportMailFile } from "../../../../store/reducers/userMailsReducer";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { AiOutlineFileImage, AiOutlineFilePdf } from "react-icons/ai";
import ListItemText from "@mui/material/ListItemText";
import { FMAlert } from "../../../../components/Form/Swal/FMAlert";

export default function SupportMailFileList ({ images }: { images: Array<IUserSupportMailFile> }) {
	const viewImage = async (image: IUserSupportMailFile) => {
		await FMAlert.fire({
			imageUrl: "https://my.forexmart.com/assets/user_images/" + image.image_hashed_name,
			imageAlt: image.image_name,
			showCloseButton: true,
			showConfirmButton: false
		});
	};

	return (
		<>
			{images && images?.map((image, i) =>
				<ListItem key={i} className="flexRow">
					<ListItemIcon>
						{!image.image_name.includes(".pdf")
							? <AiOutlineFileImage color="#2988CA" fontSize={26}/>
							: <AiOutlineFilePdf color="#2988CA" fontSize={26}/>
						}
					</ListItemIcon>
					{!image.image_name.includes(".pdf")
						? <ListItemText onClick={() => viewImage(image)} primary={image.image_name} secondary={image.size} sx={{ cursor: "pointer" }}/>
						: <a href={"https://my.forexmart.com/assets/user_docs/" + image.image_hashed_name} style={{ color: "inherit" }} target="_blank" rel="noreferrer">
							<ListItemText primary={image.image_name} secondary={image.size}/>
						</a>
					}
				</ListItem>
			)}
		</>
	);
}
