import * as React from "react";
import { DepositZotapayForm, TDepositZotapayFormValues } from "../../../../components/Forms/DepositZotapayForm";
import { calcInMYR } from "../../../../utils/calcInCurrencies";
import { ZotapayFormError } from "../../../../utils/errors/ZotapayFormError";
import API from "../../../../utils/API";
import { AxiosResponse } from "axios";

export function ZotaPay () {
	async function submit (values: TDepositZotapayFormValues) {
		const formData = new FormData();

		formData.append("amount", values.amount);
		formData.append("address", values.address);
		formData.append("city", values.city);
		formData.append("zip", values.zip);
		formData.append("phone", values.phone);
		formData.append("first_name", values.firstName);
		formData.append("last_name", values.lastName);
		formData.append("currency", "USD");
		formData.append("banks", values.banks);

		const { data } = await API.post<unknown, AxiosResponse<any>>("depositZotapay", formData);
		console.log(data);

		throw new ZotapayFormError("Implement me [Deposit>LocalBankTransferMalaysia>ZotaPay]");
	}

	return (
		<DepositZotapayForm
			submit={submit}
			calcInCurrency={calcInMYR}
		/>
	);
}
