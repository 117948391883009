import styled from "styled-components";

export const FinanceSubPageWrapper = styled.div`
  border: 1px solid #2988CA;
  padding: 24px 18px;
  min-height: 100%;

  @media screen and (max-width: 1023px) {
    max-width: 430px;
    border: none;
  }
`;
