import React from "react";

const FacebookIcon = (props:any) => {
	let fill = "#38529A";
	if (props.fill) {
		fill = props.fill;
	}

	return (
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 48 48" style={{ width: "100%" }} xmlSpace="preserve">
			<g>
				<path fill={fill} d="M15.009,26.273h4.245v17.475c0,0.343,0.28,0.624,0.625,0.624h7.198
                    c0.345,0,0.623-0.281,0.623-0.624V26.356h4.881c0.315,0,0.584-0.239,0.622-0.554l0.739-6.435c0.02-0.176-0.036-0.353-0.154-0.487
                    c-0.118-0.132-0.287-0.209-0.465-0.209h-5.623v-4.033c0-1.214,0.655-1.832,1.948-1.832c0.182,0,3.675,0,3.675,0
                    c0.344,0,0.624-0.279,0.624-0.625V6.277c0-0.345-0.28-0.625-0.624-0.625h-5.064c-0.037-0.001-0.116-0.004-0.234-0.004
                    c-0.878,0-3.932,0.173-6.345,2.393c-2.674,2.459-2.303,5.406-2.213,5.915v4.716h-4.456c-0.345,0-0.624,0.28-0.624,0.625v6.349
                    C14.385,25.993,14.664,26.273,15.009,26.273z"/>
			</g>
		</svg>
	);
};

export default FacebookIcon;
