/*
    Created on : 30.10.2021, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import "./ModalPopup.css";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

interface ModalPopupInterface{
	buttonClass?: string
	buttonText: string
	buttonIconClass?: string
	buttonIcon?: any
	modalTitle?: string
	children?: any
	modalFooter?:boolean
	class?:string
	modalOpen: boolean
	toggle: any
}

function ModalPopup (props:ModalPopupInterface) {
	return (
		<>
			<span className={props.buttonClass} onClick={props.toggle}>
				{props.buttonIcon
					? <span className={props.buttonIconClass}>{props.buttonIcon}</span>
					: null
				}
				<span className="btn-txt">{props.buttonText}</span>
			</span>
			<Modal isOpen={props.modalOpen} toggle={props.toggle} className={"modal-window-popup " + props.class}>
				{props.modalTitle
					? <ModalHeader toggle={props.toggle}> {props.modalTitle}</ModalHeader>
					: <span className="close-icon" onClick={props.toggle}>
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" clipRule="evenodd" d="M4.24696 19.7586C4.40709 19.9199 4.61785 20.0001 4.82861 20.0001C5.03917 20.0001 5.24993 19.9189 5.41126 19.7586L12.006 13.1638L18.6013 19.7586C18.7617 19.9199 18.9722 20.0001 19.183 20.0001C19.3925 20.0001 19.6031 19.9189 19.7644 19.7586C20.0859 19.4371 20.0859 18.9159 19.7644 18.5945L13.1699 11.9999L19.7644 5.4054C20.0859 5.08374 20.0859 4.56256 19.7644 4.2411C19.443 3.91963 18.9218 3.91963 18.6003 4.2411L12.0058 10.8357L5.41126 4.2411C5.0896 3.91963 4.56842 3.91963 4.24696 4.2411C3.92549 4.56256 3.92549 5.08374 4.24696 5.4054L10.8418 11.9997L4.24696 18.5945C3.92549 18.9159 3.92549 19.4371 4.24696 19.7586Z" />
						</svg>
					</span>
				}
				<ModalBody>
					{props.children}
					{/* {React.Children.map(props.children, child => { */}
					{/*    return React.cloneElement(child, {props.toggleModal: {props.toggle}}) */}
					{/* })} */}
				</ModalBody>
				{props.modalFooter
					? <ModalFooter>
						<Button color="primary" onClick={props.toggle} >Do Something</Button>{" "}
						<Button onClick={props.toggle}>Cancel</Button>
					</ModalFooter>
					: null
				}
			</Modal>
		</>
	);
}

export default ModalPopup;
