import React from "react";

export default function CentsIcon () {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="21.964" height="24.499" viewBox="0 0 21.964 24.499">
			<g transform="translate(-28.082 -27.139)">
				<path d="M33.328,49.25H31.473a1.117,1.117,0,0,0,0,2.235h1.856a1.117,1.117,0,0,0,0-2.235Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.305"/>
				<path d="M41.052,49.25H39.2a1.117,1.117,0,1,0,0,2.235h1.856a1.117,1.117,0,0,0,0-2.235Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.305"/>
				<path d="M48.776,49.25H46.92a1.117,1.117,0,0,0,0,2.235h1.856a1.117,1.117,0,1,0,0-2.235Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.305"/>
				<path d="M33.328,45.632H31.473a1.117,1.117,0,0,0,0,2.235h1.856a1.117,1.117,0,0,0,0-2.235Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.305"/>
				<path d="M33.328,42.013H31.473a1.117,1.117,0,0,0,0,2.235h1.856a1.117,1.117,0,0,0,0-2.235Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.305"/>
				<path d="M41.052,45.632H39.2a1.117,1.117,0,1,0,0,2.235h1.856a1.117,1.117,0,0,0,0-2.235Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.305"/>
				<path d="M41.052,42.013H39.2a1.117,1.117,0,1,0,0,2.235h1.856a1.117,1.117,0,0,0,0-2.235Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.305"/>
				<path d="M48.776,45.632H46.92a1.117,1.117,0,0,0,0,2.235h1.856a1.117,1.117,0,1,0,0-2.235Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.305"/>
				<path d="M48.776,42.013H46.92a1.117,1.117,0,0,0,0,2.235h1.856a1.117,1.117,0,1,0,0-2.235Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.305"/>
				<path d="M41.052,38.395H39.2a1.118,1.118,0,1,0,0,2.235h1.856a1.118,1.118,0,0,0,0-2.235Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.305"/>
				<path d="M41.052,34.776H39.2a1.117,1.117,0,1,0,0,2.235h1.856a1.117,1.117,0,0,0,0-2.235Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.305"/>
				<path d="M48.776,38.395H46.92a1.118,1.118,0,0,0,0,2.235h1.856a1.118,1.118,0,0,0,0-2.235Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.305"/>
				<path d="M48.776,34.776H46.92a1.117,1.117,0,0,0,0,2.235h1.856a1.117,1.117,0,1,0,0-2.235Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.305"/>
				<path d="M48.776,31.158H46.92a1.118,1.118,0,0,0,0,2.235h1.856a1.118,1.118,0,0,0,0-2.235Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.305"/>
				<path d="M46.92,29.775h1.856a1.118,1.118,0,0,0,0-2.235H46.92a1.118,1.118,0,0,0,0,2.235Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.305"/>
				<path d="M31.351,37.388a.676.676,0,1,0,1.352,0v-.894H33.6a.676.676,0,1,0,0-1.352h-1.57a2.465,2.465,0,0,1,0-4.929H33.6a.676.676,0,1,0,0-1.352H32.7v-.894a.676.676,0,1,0-1.352,0v.96a3.816,3.816,0,0,0,0,7.5Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.305"/>
			</g>
		</svg>
	);
};
