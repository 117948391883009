import * as React from "react";
import Box from "@mui/material/Box";
import FooterDepositOption from "./dashboardComponents/FooterDepositOption";
import FooterNeedHelp from "./dashboardComponents/FooterNeedHelp";
import FooterLinks from "./dashboardComponents/FooterLinks";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../interfaces/StoreInterfaces";
import FooterDesk from "./dashboardComponents/FooterDesk";

export default function DashboardFooter () {
	const init:any = useSelector((state:StoreInterface) => state.init.init);
	const currentYear = new Date().getFullYear();
	return (
		<Box
			component="footer"
			sx={{
				backgroundColor: "#2988CA",
				color: "#fff",
				padding: "22px 34px 29px",
				borderRadius: "4px"
			}}
		>
			<Box
				className={"flexRow"}
				sx={{
					justifyContent: "space-between",
					["@media (max-width:1289px)"]: { // eslint-disable-line no-useless-computed-key
						flexWrap: "wrap"
					}
				}}
			>
				<FooterDepositOption/>
				<FooterNeedHelp/>
			</Box>
			<FooterLinks/>
			<FooterDesk/>
			<Typography className={"flexRow"} sx={{ fontSize: "13px" }}>© 2015-{currentYear}&nbsp;
				<b>{init.companyName}</b>
			</Typography>
		</Box>
	);
};
