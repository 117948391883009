/*
    Created on : 05.02.2022, 06:18:59
    Author     : Andy Kar
*/

import { takeEvery, put, call } from "redux-saga/effects";
import {
	FETCH_ACCOUNT_FUNDS_DATA, FETCH_ACCOUNT_TRADING_DATA, SET_ACCOUNT_FUNDS_DATA,
	SET_ACCOUNT_LOADING, SET_ACCOUNT_TRADING_DATA
} from "../store/actions/actionTypes";
import { ResponseGenerator } from "../interfaces/mainInterfaces";
import API from "../utils/API";

/**
 Get Account Funds data from server and set it to store
 **/
export function * accountFundsWatcher () {
	yield takeEvery(FETCH_ACCOUNT_FUNDS_DATA, accountFundsWorker);
}
export function * accountFundsWorker () {
	yield put({ type: SET_ACCOUNT_LOADING, payload: true });
	const data:{} = yield call(getAccountFundsInfo);
	yield put({ type: SET_ACCOUNT_LOADING, payload: false });
	if (Object.keys(data).length) {
		yield put({ type: SET_ACCOUNT_FUNDS_DATA, payload: data });
	}
}
async function getAccountFundsInfo () {
	const response:ResponseGenerator = await API.get("getAccountFunds");
	// console.log("getAccountFundsInfo", response);
	return response?.data || {};
}
/**
 Get Account Trading data from server and set it to store
 **/
export function * accountTradingWatcher () {
	yield takeEvery(FETCH_ACCOUNT_TRADING_DATA, accountTradingWorker);
}
export function * accountTradingWorker () {
	yield put({ type: SET_ACCOUNT_LOADING, payload: true });
	const data:{} = yield call(getAccountTradingInfo);
	yield put({ type: SET_ACCOUNT_LOADING, payload: false });
	if (Object.keys(data).length) {
		yield put({ type: SET_ACCOUNT_TRADING_DATA, payload: data });
	}
}
async function getAccountTradingInfo () {
	const response:ResponseGenerator = await API.get("getAccountTradingInfo");
	// console.log("getAccountTradingInfo", response);
	return response?.data || {};
}
