/*
    Created on : 01.02.2022, 06:18:59
    Author     : Andy Kar
*/
import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./PersonalDetailsForm.scss";
import { SEND_PERSONAL_DETAILS } from "../../../store/actions/actionTypes";
import ReactHtmlParser from "react-html-parser";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import FormHorizontalGroup from "../formsComponents/FormHorizontalGroup/FormHorizontalGroup";
import FormHorizontalGroupPhoneWithVerification
	from "../formsComponents/FormHorizontalGroupPhoneWithVerification/FormHorizontalGroupPhoneWithVerification";
import FormHorizontalGroupDate from "../formsComponents/FormHorizontalGroupDate/FormHorizontalGroupDate";
import CountriesSelectOptions from "../formsComponents/CountriesSelectOptions/CountriesSelectOptions";
import FormHorizontalGroupAvatar from "../formsComponents/FormHorizontalGroupAvatar/FormHorizontalGroupAvatar";
import ScrollToTop from "../../Helper/ScrollToTop/ScrollToTop";
import { Box, ButtonBaseProps, SxProps, Theme } from "@mui/material";
import Button from "@mui/material/Button";
import ChangPasswordIcon from "../../Icons/myAccountsMenuIcons/ChangPasswordIcon";
import SecurityInformationIcon from "../../Icons/myAccountsMenuIcons/SecurityInformationIcon";
import AccountVerificationIcon from "../../Icons/myAccountsMenuIcons/AccountVerificationIcon";
import PageTitle from "../../Dashboard/dashboardComponents/PageTitle";
import { Row } from "../../UI/Row";
import { useIsRtl } from "../../../utils/hooks/useIsRtl";
import { ChangePassword } from "./ChangePassword";
import { ISxAdaptive, useAdaptive } from "../../../utils/hooks/themeBreakpointsHooks";
import { useAppSelector } from "../../../utils/hooks/reduxHooks";
import { ReactComponent as SaveIcon } from "../../../images/SaveIcon.svg";
import { SmsSecurity } from "./SmsSecurity";
import { IChildren } from "../../../types/common";

const adaptiveWrapperStyles: ISxAdaptive = {
	xs: {
		flexDirection: "column"
	},
	md: {
		flexDirection: "row"
	}
};

const adaptiveLeftSideStyled: ISxAdaptive = {
	xs: {
		width: "100%"
	},
	md: {
		width: "25%"
	}
};

const desktopSubmitButtonStyles: ISxAdaptive = {
	xs: {
		display: "none"
	},
	md: {
		display: "block"
	}
};

const mobileSubmitButtonStyles: ISxAdaptive = {
	xs: {
		display: "flex",
		maxWidth: "100px"
	},
	md: {
		display: "none"
	}
};

const adaptiveRowStyles: ISxAdaptive = {
	xs: {
		flexWrap: "wrap"
	},
	md: {
		flexWrap: "nowrap"
	}
};

const adaptiveFormHorizontalGroupStyles: ISxAdaptive = {
	xs: {
		marginBottom: "0"
	},
	md: {
		marginBottom: "20px"
	}
};

interface personalDetailsErrorInterface{
	name?: string
	address?: string
	city?: string
	state?: string
	zip_code?: string
	telephone?: string
	email?: string
	email2?: string
	email3?: string
	country?: string
	dob?: string
	email_1?: string
	email_2?: string
	email_3?: string
	skype?: string
}

const OutlinedButton: FC<ButtonBaseProps & {icon: any}> = ({ children, icon, ...props }) => {
	const isRtl = useIsRtl();
	// @ts-ignore
	return <Button sx={{ justifyContent: isRtl ? "flex-end" : "flex-start", marginBottom: "15px", textAlign: isRtl ? "right" : "left", textTransform: "none" }} variant="outlined" {...props}>
		<Box className={"flexRow"} alignItems={"center"} justifyContent={"flex-start"} gap={"9px"}>
			{icon}
			{children}
		</Box>
	</Button>;
};

export const InputRow: FC<{children?: IChildren, sx?: SxProps<Theme>}> = ({ children, sx }) => {
	return <Box gap={"24px"} className={"flexRow"} sx={sx}>{children}</Box>;
};

function PersonalDetailsForm () {
	const langMyAccounts = useAppSelector((state) => state.language.languageMyAccounts);
	const user = useAppSelector((state) => state.user.user);
	const personalDetailsResponse = useAppSelector((state) => state.myAccounts.personalDetailsResponse);
	const langTrading = useAppSelector((state) => state.language.languageTrading);

	const formError:personalDetailsErrorInterface | undefined = personalDetailsResponse.formError;

	const [ isOpenChangePassword, setIsOpenChangePassword ] = React.useState(false);
	const [ isOpenSmsSecurity, setIsOpenSmsSecurity ] = React.useState(false);
	const message:string | undefined = personalDetailsResponse.error;
	const telVerifyCountry = [ "BY", "RU", "UA", "MD", "AM", "AZ", "GE", "KZ", "GB", "BD" ];

	const history = useHistory();
	const location = useLocation();
	console.log("location", location);

	useEffect(() => {
		if (location.hash === "#change-password") {
			setIsOpenChangePassword(true);
		}
	}, [ location.hash, null ]);

	useEffect(() => {
		if (location.hash === "#change-password" && !isOpenChangePassword) {
			history.push("/app/profile/edit");
		}
	}, [ isOpenChangePassword ]);

	const adaptiveWrapper = useAdaptive(adaptiveWrapperStyles);
	const adaptiveLeftSide = useAdaptive(adaptiveLeftSideStyled);
	const desktopSubmitButton = useAdaptive(desktopSubmitButtonStyles);
	const mobileSubmitButton = useAdaptive(mobileSubmitButtonStyles);
	const adaptiveRow = useAdaptive(adaptiveRowStyles);
	const adaptiveFormHorizontalGroup = useAdaptive(adaptiveFormHorizontalGroupStyles);

	const [ userCountry, setUserCountry ] = useState("");

	useEffect(() => {
		if (user.country) {
			setUserCountry(user.country);
		}
	}, [ user.country ]);

	const changeCountry = (countryCode:string) => {
		setUserCountry(countryCode);
	};

	const dispatch = useDispatch();
	const sendPersonalDetails = () => {
		const formName:any = "personalDetailsForm";
		const personalDetailsForm: HTMLFormElement | null = document.forms[formName];
		if (personalDetailsForm) {
			const personalDetailsData = new FormData(personalDetailsForm);
			dispatch({ type: SEND_PERSONAL_DETAILS, personalDetailsData });
		}
	};

	return (
		<>
			<ChangePassword isOpen={isOpenChangePassword} onClose={setIsOpenChangePassword} />
			<SmsSecurity isOpen={isOpenSmsSecurity} onClose={() => setIsOpenSmsSecurity(false)}/>
			<Row>
				<PageTitle pageTitle={langMyAccounts.mfn_01}/>
				<div className="form-group flexRow">
					<div className="form-horizontal-right-column flexRow">
						{/* <Button */}
						{/*	to='/app/my-account' */}
						{/*	className="submit-btn" */}
						{/* > */}
						{/*	{langMyAccounts.reg_wr_09} */}
						{/* </Button> */}
						<Button
							type="button"
							variant={"contained"}
							color={"success"}
							className="submit-btn"
							onClick={sendPersonalDetails}
							sx={desktopSubmitButton}
						>
							{langMyAccounts.perdet_11}
						</Button>
					</div>
				</div>
			</Row>
			<Box gap={"20px"} className="personal-details-edit-form-wrap flexRow" sx={adaptiveWrapper}>
				<Box display={"flex"} flexDirection={"column"} sx={adaptiveLeftSide}>
					<div className="personal-details-alert">
						{message
							? <ScrollToTop><div className="alert alert-success">{ReactHtmlParser(message)}</div></ScrollToTop>
							: null
						}
					</div>

					<Box
						className="personal-details-edit-form"
						display={"flex"} justifyContent={"center"}
						alignItems={"center"}
						flexDirection={"column"}
						padding={"19px 14px"}
						mb={"15px"}
						sx={{ background: "#5C7080" }}
					>
						<FormHorizontalGroupAvatar />
					</Box>

					<OutlinedButton
						color={"secondary"}
						icon={<ChangPasswordIcon fill={"#5C7080"}/>}
						onClick={() => setIsOpenChangePassword(true)}
					>
						{langMyAccounts.mfn_02}
					</OutlinedButton>
					<OutlinedButton
						color={"success"}
						icon={<AccountVerificationIcon fill={"#29A643"}/>}
						onClick={() => history.push("/app/profile/upload-document")}
					>
						{langMyAccounts.mfn_03}
					</OutlinedButton>
					<OutlinedButton
						icon={<SecurityInformationIcon fill={"#2988CA"}/>}
						onClick={() => history.push("/app/profile/two-factor-authentication")}
					>
						{langMyAccounts.mfn_08}
					</OutlinedButton>
					<OutlinedButton
						color={"secondary"}
						icon={<ChangPasswordIcon fill={"#5C7080"}/>}
						onClick={() => setIsOpenSmsSecurity(true)}
					>
						{langMyAccounts.mfn_006}
					</OutlinedButton>
				</Box>

				<form className="personal-details-edit-form" name="personalDetailsForm">
					<FormHorizontalGroup
						label_text={langMyAccounts.perdet_02}
						required={true}
						type={"text"}
						name={"name"}
						placeholder={langMyAccounts.perdet_02}
						defaultValue={user.full_name}
						error={formError && formError.name ? formError.name : ""}
						tooltip_message={langMyAccounts.tooltip_01}
					/>
					<FormHorizontalGroup
						label_text={langMyAccounts.perdet_03}
						required={true}
						type={"text"}
						name={"address"}
						placeholder={langMyAccounts.perdet_03}
						defaultValue={user.street}
						error={formError && formError.address ? formError.address : ""}
						tooltip_message={langMyAccounts.tooltip_02}
					/>
					<InputRow sx={adaptiveRow}>
						<FormHorizontalGroup
							sx={adaptiveFormHorizontalGroup}
							label_text={langMyAccounts.perdet_04}
							required={true}
							type={"text"}
							name={"city"}
							placeholder={langMyAccounts.perdet_04}
							defaultValue={user.city}
							error={formError && formError.city ? formError.city : ""}
							tooltip_message={langMyAccounts.tooltip_03}
						/>
						<FormHorizontalGroup
							label_text={langMyAccounts.perdet_05}
							required={true}
							type={"text"}
							name={"state"}
							placeholder={langMyAccounts.perdet_05}
							defaultValue={user.state}
							error={formError && formError.state ? formError.state : ""}
							tooltip_message={langMyAccounts.tooltip_04}
						/>
					</InputRow>
					<InputRow sx={adaptiveRow}>
						<FormHorizontalGroup
							sx={adaptiveFormHorizontalGroup}
							label_text={langMyAccounts.perdet_06}
							required={true}
							type={"text"}
							name={"zip_code"}
							placeholder={langMyAccounts.perdet_06}
							defaultValue={user.zip}
							error={formError && formError.zip_code ? formError.zip_code : ""}
							tooltip_message={langMyAccounts.tooltip_05}
						/>
						<CountriesSelectOptions
							label_text={langMyAccounts.perdet_10}
							name={"country"}
							required={true}
							defaultValue={user.country || ""}
							error={formError && formError.country ? formError.country : ""}
							changeCountry={(countryCode:string) => changeCountry(countryCode)}
						/>
					</InputRow>
					<InputRow sx={adaptiveRow}>
						<FormHorizontalGroupDate
							sx={adaptiveFormHorizontalGroup}
							label_text={langMyAccounts.perdet_12}
							required={true}
							name={"dob"}
							defaultValue={user.dob}
							error={formError && formError.dob ? formError.dob : ""}
						/>
						<FormHorizontalGroup
							label_text={"Skype"}
							required={false}
							type={"text"}
							name={"skype"}
							placeholder={"Skype"}
							defaultValue={user.skype || ""}
							error={formError && formError.skype ? formError.skype : ""}
						/>
					</InputRow>
					<InputRow sx={adaptiveRow}>
						{telVerifyCountry.includes(userCountry)
							? <>
								<FormHorizontalGroupPhoneWithVerification
									sx={adaptiveFormHorizontalGroup}
									label_text={langMyAccounts.perdet_07 + "(1)"}
									required={true}
									type={"text"}
									name={"telephone_1"}
									verified={!!user.phone_verified_1}
									verify_name={"sms_code_1"}
									verify_placeholder={"Please, enter SMS code"}
									placeholder={langMyAccounts.perdet_07}
									verify_btn_txt={langMyAccounts.reg_wr_05}
									send_verify_btn_txt={langMyAccounts.reg_wr_06}
									defaultValue={user.phone1}
									error={formError && formError.telephone ? formError.telephone : ""}
									tooltip_message={langMyAccounts.tooltip_06}
								/>
								<FormHorizontalGroupPhoneWithVerification
									label_text={langMyAccounts.perdet_07 + "(2)"}
									required={false}
									type={"text"}
									name={"telephone_2"}
									verified={!!user.phone_verified_2}
									verify_name={"sms_code_2"}
									verify_placeholder={"Please, enter SMS code"}
									placeholder={langMyAccounts.second_phone}
									send_verify_btn_txt={langMyAccounts.reg_wr_06}
									verify_btn_txt={langMyAccounts.reg_wr_05}
									defaultValue={user.phone2}
									error={formError && formError.telephone ? formError.telephone : ""}
									tooltip_message={langMyAccounts.tooltip_06}
								/>
							</>
							: <>
								<FormHorizontalGroup
									sx={adaptiveFormHorizontalGroup}
									label_text={langMyAccounts.perdet_07 + "(1)"}
									required={true}
									type={"text"}
									name={"telephone_1"}
									placeholder={langMyAccounts.perdet_07}
									defaultValue={user.phone1}
									error={formError && formError.telephone ? formError.telephone : ""}
									tooltip_message={langMyAccounts.tooltip_06}
								/>
								<FormHorizontalGroup
									label_text={langMyAccounts.perdet_07 + "(2)"}
									required={false}
									type={"text"}
									name={"telephone_2"}
									placeholder={langMyAccounts.second_phone}
									defaultValue={user.phone2}
									error={formError && formError.telephone ? formError.telephone : ""}
									tooltip_message={langMyAccounts.tooltip_06}
								/>
							</>
						}
					</InputRow>
					<input type="hidden" id="user-email" name="user-email" value={user.email || ""} />
					<FormHorizontalGroup
						label_text={langMyAccounts.perdet_08 + "(1)"}
						required={true}
						type={"text"}
						name={"email_1"}
						placeholder={"First email"}
						defaultValue={user.email1 || user.email || ""}
						error={formError && formError.email_1 ? formError.email_1 : ""}
						tooltip_message={langMyAccounts.tooltip_07}
					/>
					<FormHorizontalGroup
						label_text={langMyAccounts.perdet_08 + "(2)"}
						required={false}
						type={"text"}
						name={"email_2"}
						placeholder={langMyAccounts.second_email}
						defaultValue={user.email2 || ""}
						error={formError && formError.email_2 ? formError.email_2 : ""}
						tooltip_message={langMyAccounts.tooltip_07}
					/>
					<FormHorizontalGroup
						label_text={langMyAccounts.perdet_08 + "(3)"}
						required={false}
						type={"text"}
						name={"email_3"}
						placeholder={langMyAccounts.third_email}
						defaultValue={user.email3 || ""}
						error={formError && formError.email_3 ? formError.email_3 : ""}
						tooltip_message={langMyAccounts.tooltip_07}
					/>
					<FormHorizontalGroup
						label_text={langMyAccounts.perdet_09}
						required={false}
						type={"text"}
						name={"preferred_time"}
						placeholder={langMyAccounts.perdet_09}
						defaultValue={user.preferred_time || ""}
					/>
				</form>
				<Button
					type="button"
					variant={"contained"}
					color={"success"}
					startIcon={<SaveIcon />}
					onClick={sendPersonalDetails}
					sx={mobileSubmitButton}
				>
					{langTrading.reb_txt_17}
				</Button>
			</Box>
		</>
	);
}
export default withRouter(PersonalDetailsForm);
