import * as React from "react";
import { Grid, Typography } from "@mui/material";
import { Link, NavLinkProps, useLocation } from "react-router-dom";
import { ColumnItem } from "../ColumnItem";
import styled from "styled-components";
import { useIsRtl } from "../../../../utils/hooks/useIsRtl";
import { useIsMobile } from "../../../../utils/hooks/useIsMobile";

export interface PaymentNetworkLinkProps extends NavLinkProps {
	icon: string;
	title: string;
	description?: string;
	activeForPaths?: string[];
}

export function PaymentNetworkLink (props: PaymentNetworkLinkProps) {
	const location = useLocation();
	const { icon, to, title, description, activeForPaths } = props;
	const isRtl = useIsRtl();
	const isMobile = useIsMobile();

	const isActive = React.useMemo(() => {
		const include = activeForPaths
			? activeForPaths?.includes(location.pathname)
			: false;
		return location.pathname.includes(to as string) || include;
	}, [ location.pathname, to ]);

	return (
		<Link to={to}>
			<Wrapper isActive={isActive}>
				<Grid
					container
					sx={{ flexWrap: "nowrap" }}
					flexDirection={isRtl ? "row-reverse" : "row"}
				>
					<Grid
						item
						sx={{
							marginRight: isMobile ? 0 : 1,
							marginLeft: isMobile ? 0 : 1,
							background: "#fff",
							borderRadius: "4px"
						}}
					>
						<img src={icon} width="54px" height="auto" alt={title}/>
					</Grid>
					{
						isMobile
							? (
								<></>
							)
							: (
								<ColumnItem>
									<Typography
										alignItems="center"
										justifyContent={isRtl ? "right" : "left"}
										color={isActive ? "#fff" : "inherit"}
										sx={{
											marginBottom: 0.25,
											display: "flex",
											height: 24
										}}
										textAlign={isRtl ? "right" : "left"}
									>
										<b>{title}</b>
									</Typography>
									{
										description
											? (
												<Typography
													textAlign={isRtl ? "right" : "left"}
													fontSize={10}
													color={isActive ? "#fff" : "inherit"}
												>
													{description}
												</Typography>
											)
											: (
												<></>
											)
									}
								</ColumnItem>
							)
					}
				</Grid>
			</Wrapper>
		</Link>
	);
}

const Wrapper = styled.div<{ isActive: boolean, children?: any }>`
	background-color: ${({ isActive }) => isActive ? "#2988CA" : "transparent"};
	padding: 5px 8px;
	width: 250px;
	min-height: 56px;
	display: flex;
	align-items: center;
	color: #5C7080;
	transition: 0.05s ease-in-out;

	&:hover {
		background-color: #2988CA;
		color: #fff;
	}

	@media screen and (max-width: 1180px) {
		width: auto;
		max-width: 577px;
		min-height: auto;
		padding: 2px;
	}
`;
