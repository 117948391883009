/*
    Created on : 14.09.2022, 06:18:59
    Author     : Andy Kar
*/
import React, { useEffect } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import AccountTypes from "../components/AccountTypes/AccountTypes";
import PageMailSupportCompose from "../../pages/MailSupport/PageMailSupportCompose/PageMailSupportCompose";
import PageMailSupportMyEmail from "../../pages/MailSupport/PageMailSupportMyEmail/PageMailSupportMyEmail";
import { useDispatch, useSelector } from "react-redux";
import { FETCH_USER_MAILS } from "../../store/actions/actionTypes";
import { StoreInterface } from "../../interfaces/StoreInterfaces";
import { IUserSupportMail } from "../../store/reducers/userMailsReducer";

const LayoutMailSupport = (props:any) => {
	const userMails: Array<IUserSupportMail> = useSelector((state:StoreInterface) => state.userMails.userMails);
	const dispatch = useDispatch();
	useEffect(() => {
		if (!userMails.length) {
			dispatch({ type: FETCH_USER_MAILS });
		}
	}, []);

	return (
		<>
			<Switch>
				<Route exact path="/app/mail/compose" render={() => <PageMailSupportCompose {...props} />} />
				<Route exact path="/app/mail/my-email" render={() => <PageMailSupportMyEmail {...props} />} />
				<Redirect exact from="/app/rebate" to="/app/mail/compose" />
			</Switch>
			<AccountTypes/>
		</>
	);
};

export default withRouter(LayoutMailSupport);
