import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FooterFeedbackForm from "./FooterFeedbackForm";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../interfaces/StoreInterfaces";
import { Typography } from "@mui/material";
import FooterFeedbackIcon from "../../Icons/FooterFeedbackIcon";

export default function FooterFeedback () {
	const lang: any = useSelector((state: StoreInterface) => state.language.language);
	const [ open, setOpen ] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<Button
				variant="contained"
				onClick={handleClickOpen}
				startIcon={<FooterFeedbackIcon/>}
				color="success"
				className={"flexRow"}
				sx={{
					minWidth: "130px",
					justifyContent: "flex-start",
					marginX: "5px"
				}}
			>
				<Typography
					component="span"
					sx={{ textTransform: "none" }}
				>
					{lang.botnav_11}
				</Typography>
			</Button>
			<Dialog
				open={open}
				onClose={handleClose}
				sx={{
					"&.MuiDialog-root": {
						zIndex: "1400"
					}
				}}
			>
				<DialogContent>
					<FooterFeedbackForm
						handleClose={handleClose}
					/>
				</DialogContent>
			</Dialog>
		</>
	);
}
