import * as React from "react";
import { Typography, Box } from "@mui/material";
import { useAppSelector } from "../../../utils/hooks/reduxHooks";
import PageTitle from "../../../components/Dashboard/dashboardComponents/PageTitle";

export function PageCopytradeAgreement () {
	const { languageCopytrade, language } = useAppSelector((state) => state.language);

	return (
		<>
			<PageTitle
				layoutTitle={language.sb_li_12}
				pageTitle={languageCopytrade.cpy_trd_agrmnt1}
			/>
			<Box sx={{
				textAlign: "left",
				"& ol": {
					listStyleType: "decimal",
					listStylePosition: "initial",
					padding: "0 0 0 20px"
				},
				"& ol li": {
					listStyleType: "decimal",
					display: "list-item"
				}
			}}>
				<p>{languageCopytrade.cpy_trd_agrmnt2}</p>
				<ol>
					<li>{languageCopytrade.cpy_trd_agrmnt3}
						<p>{languageCopytrade.cpy_trd_agrmnt4}</p>
						<p>{languageCopytrade.cpy_trd_agrmnt5}</p>
						<p>{languageCopytrade.cpy_trd_agrmnt6}</p>
						<p>{languageCopytrade.cpy_trd_agrmnt7}</p>
					</li>
					<li>{languageCopytrade.cpy_trd_agrmnt8}
						<ol>
							<li>{languageCopytrade.cpy_trd_agrmnt9}
								<p>{languageCopytrade.cpy_trd_agrmnt10}</p>
								<ol>
									<li><p>{languageCopytrade.cpy_trd_agrmnt11}</p></li>
									<li><p>{languageCopytrade.cpy_trd_agrmnt12}</p></li>
									<li><p>{languageCopytrade.cpy_trd_agrmnt13}</p></li>
									<li><p>{languageCopytrade.cpy_trd_agrmnt14}</p></li>
									<li><p>{languageCopytrade.cpy_trd_agrmnt15}</p></li>
									<li><p>{languageCopytrade.cpy_trd_agrmnt16}</p></li>
									<li><p>{languageCopytrade.cpy_trd_agrmnt17}</p></li>
									<li><p>{languageCopytrade.cpy_trd_agrmnt18}</p></li>
									<li><p>{languageCopytrade.cpy_trd_agrmnt19}</p></li>
								</ol>
							</li>
							<li><p>{languageCopytrade.cpy_trd_agrmnt20}</p></li>
							<li><p>{languageCopytrade.cpy_trd_agrmnt21}</p></li>
						</ol>
					</li>
					<li><p>{languageCopytrade.cpy_trd_agrmnt22}</p>
						<p>{languageCopytrade.cpy_trd_agrmnt23}</p>
						<ol>
							<li>{languageCopytrade.cpy_trd_agrmnt24}
								<p>{languageCopytrade.cpy_trd_agrmnt25}</p>
								<p>{languageCopytrade.cpy_trd_agrmnt26}</p>
								<p>{languageCopytrade.cpy_trd_agrmnt27}</p>
								<p>{languageCopytrade.cpy_trd_agrmnt28}</p>
								<p>{languageCopytrade.cpy_trd_agrmnt29}</p>
							</li>
							<li>{languageCopytrade.cpy_trd_agrmnt30}
								<p>{languageCopytrade.cpy_trd_agrmnt31}</p>
							</li>
							<li>{languageCopytrade.cpy_trd_agrmnt32}
								<p>{languageCopytrade.cpy_trd_agrmnt33}</p>
							</li>
							<li>{languageCopytrade.cpy_trd_agrmnt34}
								<p>{languageCopytrade.cpy_trd_agrmnt35}</p>
							</li>
							<li>{languageCopytrade.cpy_trd_agrmnt36}
								<p>{languageCopytrade.cpy_trd_agrmnt37}</p>
								<p>{languageCopytrade.cpy_trd_agrmnt38}</p>
							</li>
							<li><p>{languageCopytrade.cpy_trd_agrmnt39}</p></li>
							<li><p>{languageCopytrade.cpy_trd_agrmnt40}</p></li>
							<li><p>{languageCopytrade.cpy_trd_agrmnt41}</p></li>
							<li><p>{languageCopytrade.cpy_trd_agrmnt42}</p></li>
						</ol>
					</li>
					<li><p>{languageCopytrade.cpy_trd_agrmnt43}</p>
						<p>{languageCopytrade.cpy_trd_agrmnt44}</p>
						<ol>
							<li>{languageCopytrade.cpy_trd_agrmnt45}
								<p>{languageCopytrade.cpy_trd_agrmnt46}</p>
							</li>
							<li>{languageCopytrade.cpy_trd_agrmnt47}
								<p>{languageCopytrade.cpy_trd_agrmnt48}</p>
							</li>
							<li>{languageCopytrade.cpy_trd_agrmnt49}
								<p>{languageCopytrade.cpy_trd_agrmnt50}</p>
							</li>
							<li>{languageCopytrade.cpy_trd_agrmnt51}
								<p>{languageCopytrade.cpy_trd_agrmnt52}</p>
							</li>
							<li>{languageCopytrade.cpy_trd_agrmnt53}
								<p>{languageCopytrade.cpy_trd_agrmnt54}</p>
							</li>
							<li>{languageCopytrade.cpy_trd_agrmnt55}
								<p>{languageCopytrade.cpy_trd_agrmnt56}</p>
							</li>
							<li>{languageCopytrade.cpy_trd_agrmnt57}
								<p>{languageCopytrade.cpy_trd_agrmnt58}</p>
							</li>
							<li>{languageCopytrade.cpy_trd_agrmnt59}
								<p>{languageCopytrade.cpy_trd_agrmnt60}</p>
								<ol>
									<li><p>{languageCopytrade.cpy_trd_agrmnt61}</p></li>
									<li><p>{languageCopytrade.cpy_trd_agrmnt62}</p></li>
									<li><p>{languageCopytrade.cpy_trd_agrmnt63}</p></li>
								</ol>
							</li>
						</ol>
					</li>
					<li><p>{languageCopytrade.cpy_trd_agrmnt64}</p>
						<p>{languageCopytrade.cpy_trd_agrmnt65}</p>
						<ol>
							<li>{languageCopytrade.cpy_trd_agrmnt66}
								<p>{languageCopytrade.cpy_trd_agrmnt67}</p>
							</li>
							<li>{languageCopytrade.cpy_trd_agrmnt68}
								<p>{languageCopytrade.cpy_trd_agrmnt69}</p>
							</li>
							<li>{languageCopytrade.cpy_trd_agrmnt70}
								<p>{languageCopytrade.cpy_trd_agrmnt71}</p>
							</li>
							<li>{languageCopytrade.cpy_trd_agrmnt72}
								<p>{languageCopytrade.cpy_trd_agrmnt73}</p>
							</li>
							<li>{languageCopytrade.cpy_trd_agrmnt74}
								<p>{languageCopytrade.cpy_trd_agrmnt75}</p>
							</li>
							<li>{languageCopytrade.cpy_trd_agrmnt76}
								<p>{languageCopytrade.cpy_trd_agrmnt77}</p>
							</li>
							<li>{languageCopytrade.cpy_trd_agrmnt78}
								<p>{languageCopytrade.cpy_trd_agrmnt79}</p>
								<ol>
									<li><p>{languageCopytrade.cpy_trd_agrmnt80}</p></li>
									<li><p>{languageCopytrade.cpy_trd_agrmnt81}</p></li>
									<li><p>{languageCopytrade.cpy_trd_agrmnt82}</p></li>
									<li><p>{languageCopytrade.cpy_trd_agrmnt83}</p></li>
								</ol>
								<p>{languageCopytrade.cpy_trd_agrmnt84}</p>
							</li>
							<li>{languageCopytrade.cpy_trd_agrmnt85}
								<p>{languageCopytrade.cpy_trd_agrmnt86}</p>
							</li>
						</ol>
					</li>
					<li><p>{languageCopytrade.cpy_trd_agrmnt87}</p>
						<ol>
							<li><p>{languageCopytrade.cpy_trd_agrmnt88}</p></li>
							<li><p>{languageCopytrade.cpy_trd_agrmnt89}</p>
								<ol>
									<li><p>{languageCopytrade.cpy_trd_agrmnt90}</p></li>
									<li><p>{languageCopytrade.cpy_trd_agrmnt91}</p></li>
									<li><p>{languageCopytrade.cpy_trd_agrmnt92}</p></li>
									<li><p>{languageCopytrade.cpy_trd_agrmnt93}</p></li>
									<li><p>{languageCopytrade.cpy_trd_agrmnt94}</p></li>
									<li><p>{languageCopytrade.cpy_trd_agrmnt95}</p></li>
									<li><p>{languageCopytrade.cpy_trd_agrmnt96}</p></li>
									<li><p>{languageCopytrade.cpy_trd_agrmnt97}</p></li>
									<li><p>{languageCopytrade.cpy_trd_agrmnt98}</p></li>
									<li><p>{languageCopytrade.cpy_trd_agrmnt99}</p></li>
									<li><p>{languageCopytrade.cpy_trd_agrmnt100}</p></li>
									<li><p>{languageCopytrade.cpy_trd_agrmnt101}</p></li>
									<li><p>{languageCopytrade.cpy_trd_agrmnt102}</p></li>
									<li><p>{languageCopytrade.cpy_trd_agrmnt103}</p></li>
									<li><p>{languageCopytrade.cpy_trd_agrmnt104}</p></li>
									<li><p>{languageCopytrade.cpy_trd_agrmnt105}</p></li>
								</ol>
							</li>
							<li><p>{languageCopytrade.cpy_trd_agrmnt106}</p>
								<ol>
									<li>{languageCopytrade.cpy_trd_agrmnt107}
										<p>{languageCopytrade.cpy_trd_agrmnt108}</p>
									</li>
									<li>{languageCopytrade.cpy_trd_agrmnt109}
										<p>{languageCopytrade.cpy_trd_agrmnt110}</p>
									</li>
									<li>
										<p>{languageCopytrade.cpy_trd_agrmnt110_1}</p>
									</li>
								</ol>
							</li>
							<li>{languageCopytrade.cpy_trd_agrmnt111}
								<p>{languageCopytrade.cpy_trd_agrmnt112}</p>
							</li>
							<li>{languageCopytrade.cpy_trd_agrmnt113}
								<p>{languageCopytrade.cpy_trd_agrmnt114}</p>
							</li>
							<li>{languageCopytrade.cpy_trd_agrmnt115}
								<p>{languageCopytrade.cpy_trd_agrmnt116}</p>
							</li>
							<li>{languageCopytrade.cpy_trd_agrmnt117}
								<p>{languageCopytrade.cpy_trd_agrmnt118}</p>
							</li>
							<li>{languageCopytrade.cpy_trd_agrmnt119}
								<p>{languageCopytrade.cpy_trd_agrmnt120}</p>
							</li>
							<li>{languageCopytrade.cpy_trd_agrmnt121}
								<p>{languageCopytrade.cpy_trd_agrmnt122}</p>
							</li>
							<li>{languageCopytrade.cpy_trd_agrmnt123}
								<p>{languageCopytrade.cpy_trd_agrmnt124}</p>
							</li>
							<li>{languageCopytrade.cpy_trd_agrmnt125}
								<p>{languageCopytrade.cpy_trd_agrmnt126}</p>
							</li>
							<li>{languageCopytrade.cpy_trd_agrmnt127}
								<p>{languageCopytrade.cpy_trd_agrmnt128}</p>
								<ol>
									<li>{languageCopytrade.cpy_trd_agrmnt129}</li>
									<li>{languageCopytrade.cpy_trd_agrmnt130}</li>
									<li>{languageCopytrade.cpy_trd_agrmnt131}</li>
								</ol>
								<Typography sx={{ marginLeft: "40px" }}>{languageCopytrade.cpy_trd_agrmnt132}</Typography>
							</li>
							<li>{languageCopytrade.cpy_trd_agrmnt133}
								<ol>
									<li><p>{languageCopytrade.cpy_trd_agrmnt134}</p></li>
									<li><p>{languageCopytrade.cpy_trd_agrmnt135}</p></li>
								</ol>
							</li>
							<li><p>{languageCopytrade.cpy_trd_agrmnt136}</p></li>
							<li><p>{languageCopytrade.cpy_trd_agrmnt137}</p></li>
						</ol>
					</li>
					<li><p>{languageCopytrade.cpy_trd_agrmnt138}</p>
						<ol>
							<li><p>{languageCopytrade.cpy_trd_agrmnt139}</p></li>
							<li><p>{languageCopytrade.cpy_trd_agrmnt140}</p></li>
							<li><p>{languageCopytrade.cpy_trd_agrmnt141}</p></li>
							<li><p>{languageCopytrade.cpy_trd_agrmnt142}</p></li>
							<li><p>{languageCopytrade.cpy_trd_agrmnt143}</p></li>
						</ol>
					</li>
					<li><p>{languageCopytrade.cpy_trd_agrmnt144}</p>
						<ol>
							<li><p>{languageCopytrade.cpy_trd_agrmnt145}</p></li>
							<li><p>{languageCopytrade.cpy_trd_agrmnt146}</p></li>
							<li><p>{languageCopytrade.cpy_trd_agrmnt147}</p></li>
						</ol>
					</li>
					<li><p>{languageCopytrade.cpy_trd_agrmnt148}</p>
						<ol>
							<li><p>{languageCopytrade.cpy_trd_agrmnt149}</p> </li>
							<li><p>{languageCopytrade.cpy_trd_agrmnt150} </p></li>
							<li><p>{languageCopytrade.cpy_trd_agrmnt151}</p></li>
						</ol>
					</li>
				</ol>
			</Box>
		</>
	);
}
