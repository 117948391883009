import * as React from "react";
import { Form } from "../../../../components/Form";
import { Input } from "../../../../components/Form/Input";
import { getWithdrawalValue } from "../../../../utils/getReceivedValue";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { vnPayWithdrawValidationSchema } from "./validation";
import { FormBottomWrapper } from "../../../../components/Form/FormBottomWrapper";
import { ButtonWithLoader } from "../../../../components/ButtonWithLoader";
import { Alert, Box, Skeleton, Snackbar } from "@mui/material";
import { WaningNote } from "../../../../components/UI/Note";
import { useErrorHandling } from "../../../../utils/hooks/useErrorHandling";
import { fetchVnPayInfo } from "../../../../store/actions/actionTypes";
import API from "../../../../utils/API";
import { AxiosResponse } from "axios";

export function VNPay () {
	const [ success, setSuccess ] = React.useState(false);
	const [ isFetch, setIsFetch ] = React.useState(false);
	const language = useAppSelector((state) => state.language);
	const accountNumber = useAppSelector((state) => `${state.session.userData.account_number ?? ""}`);
	const currency = useAppSelector((state) => state.session.userData.currency ?? "USD");
	const { isOpen, errorMsg, hideError, showError } = useErrorHandling();
	const {
		complete,
		feeDetails,
		loading: infoLoading
	} = useAppSelector((state) => state.withdrawMethodsInfo.vnPay);
	const dispatch = useAppDispatch();

	React.useEffect(() => {
		if (!complete) {
			dispatch(fetchVnPayInfo({}));
		}
	}, []);

	const getWithdrawalValueCB = React.useCallback((values: any) => getWithdrawalValue(values, currency), [ currency ]);

	async function submit (
		values: {
			accountNumber: string,
			amount: string,
			vndAsiaAccount: string,
			bankAccountName: string,
			bankName: string,
			bankBranch: string,
			bankProvince: string,
			bankCity: string
		}
	) {
		setIsFetch(true);
		try {
			const formData = new FormData();

			formData.append("amount_withdraw", values.amount);
			formData.append("vndasia_account", values.vndAsiaAccount);
			formData.append("bank_account_name", values.bankAccountName);
			formData.append("bank_name", values.bankName);
			formData.append("bank_branch", values.bankBranch);
			formData.append("bank_province", values.bankProvince);
			formData.append("bank_city", values.bankCity);

			const response = await API.post<unknown, AxiosResponse<{
				success: boolean,
				error: string,
				formError: { [key: string]: string }
			}>>("withdrawVNPay", formData);

			if (!response.data.success) {
				const firstKey = Object.keys(response.data.formError)[0];
				showError(response.data.formError[firstKey] ?? response.data.error);
				return;
			}

			setSuccess(true);
		} catch (e) {
			// handle error
		} finally {
			setIsFetch(false);
		}
	}

	return (
		<Form
			onSubmit={submit}
			validationSchema={vnPayWithdrawValidationSchema()}
			defaultValues={{
				accountNumber,
				amount: "",
				vndAsiaAccount: "",
				bankAccountName: "",
				bankName: "",
				bankBranch: "",
				bankProvince: "",
				bankCity: ""
			}}
		>
			<Input
				name="accountNumber"
				label={language.languageCopytrade.cpy_mon_accnum}
				variant="rounded"
			/>
			<Input
				name="amount"
				label={language.languageFinance.trd_230}
				variant="rounded"
			/>
			<Input
				name="vndAsiaAccount"
				label={"Bank Account Number"}
				variant="rounded"
			/>
			<Input
				name="bankAccountName"
				label={"Bank account name"}
				variant="rounded"
			/>
			<Input
				name="bankName"
				label={"Bank name"}
				variant="rounded"
			/>
			<Input
				name="bankBranch"
				label={"Branch"}
				variant="rounded"
			/>
			<Input
				name="bankProvince"
				label={"Province"}
				variant="rounded"
			/>
			<Input
				name="bankCity"
				label={"Bank city"}
				variant="rounded"
			/>
			<FormBottomWrapper
				forFields={[ "amount", "currency" ]}
				onChange={getWithdrawalValueCB}
				label={language.languageMyAccounts.form_message_01}
			>
				<ButtonWithLoader
					isFetch={isFetch}
					color={"primary"}
					text={language.languageMyAccounts.chapas_04}
				/>
			</FormBottomWrapper>
			<Box sx={{ marginTop: "6px" }}>
				<WaningNote
					message="Please fill all fields"
				/>
			</Box>
			<Box sx={{ marginTop: "6px" }}>
				{
					infoLoading
						? (
							<Skeleton width={"220px"} height={"26px"} />
						)
						: (
							<WaningNote
								message={`Fee is ${feeDetails}`}
							/>
						)
				}
			</Box>
			<Snackbar open={isOpen} autoHideDuration={4000} onClose={hideError}>
				<Alert variant="filled" color="error">
					{errorMsg}
				</Alert>
			</Snackbar>
			<Snackbar
				open={success}
				autoHideDuration={4000}
				onClose={() => setSuccess(false)}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
			>
				<Alert variant="filled" color="success">
					Success
				</Alert>
			</Snackbar>
		</Form>
	);
}
