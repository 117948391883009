/*
    Created on : 24.01.2022, 06:18:59
    Author     : Andy Kar
*/
import React, { useMemo } from "react";
import "./PageBonuses.scss";
import PageTitle from "../../../components/Dashboard/dashboardComponents/PageTitle";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../interfaces/StoreInterfaces";
import { Box, Checkbox, Typography } from "@mui/material";
import { ReactComponent as InfoIcon } from "../../../components/UI/FieldWrapper/WrapperWithTip/icons/InfoIcon.svg";
import CustomButton from "../../../components/CustomButton";
import { BonusAgreeLink } from "../../../components/UI/BonusAgreeLink";
import ReactHtmlParser from "react-html-parser";
import { useIsRtl } from "../../../utils/hooks/useIsRtl";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

export default function PageBonuses () {
	const lang:any = useSelector((state:StoreInterface) => state.language.language);
	const langBonus:any = useSelector((state:StoreInterface) => state.language.languageBonus);
	const bonus = useSelector((state: StoreInterface) => state.user.user.bonus);
	const isRtl = useIsRtl();
	const history = useHistory();
	console.log("bonus", bonus);

	const bonusTranslate = useMemo(() => {
		switch (bonus?.bonusCode) {
			case "twpb": return langBonus.bon_17;
			case "tpb": return langBonus.bon_00;

			case "hpb":
			case "fpb":
			case "none":
			default:
				return "";
		}
	}, [ bonus, bonus?.bonusCode, langBonus.bon_17, langBonus.bon_00 ]);

	return (
		<div className="page-bonuses">
			<PageTitle
				layoutTitle={lang.sb_li_3}
				pageTitle={langBonus.bon_tab_00}
			/>

			<Typography component="h3" lineHeight={"22px"} className={"page-bonus__title"} textAlign={isRtl ? "right" : "left"} fontSize={18} >
				{ReactHtmlParser(langBonus.description)}
			</Typography>

			<div className="flexRow page-bonus__mt15 page-bonus__info-wrapper">
				<Box className={classNames("page-bonus__arrow", { rtl: isRtl })}>
					<p className={"page-bonus__arrow-text"}>{bonusTranslate}</p>
					<div className="page-bonus__arrow-triangle">
					</div>
				</Box>

				<div className={"page-bonus__info" + (isRtl ? "-rtl" : "")}>
					<div className="flexRow page-bonus__info-line">
						<InfoIcon/>
						<Typography color="secondary" fontWeight={"bold"} letterSpacing={1}>{langBonus.bon_03}</Typography>
					</div>

					<Typography component="h3" lineHeight={"24px"} className={"page-bonus__title  page-bonus__mt15"} textAlign={isRtl ? "right" : "left"} fontSize={14}>
						{ReactHtmlParser(langBonus.bon_04)}
					</Typography>

					<Box className="flexRow page-bonus__info-line page-bonus__mt15" gap={0} ml={"-10px"}>
						<Checkbox />
						<BonusAgreeLink bonus={bonus?.bonusCode ?? "twpb"}/>
					</Box>
				</div>

				<div style={{ marginTop: "12px" }}>
					<CustomButton onClick={() => history.push("/app/deposit/debit")} text={langBonus.bon_13} color={"success"}></CustomButton>
				</div>
			</div>
		</div>
	);
};
