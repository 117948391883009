export type Data = {
	[key: string]: string | number | null;
}

export function getPaymentNetworkRequest (
	data: Data,
	linkKey?: string,
	include = (key: string, data: Data) => !!data[key]
): string {
	const link = linkKey ?? "link";
	const keys = Object.keys(data).filter((key) => key !== link);
	const requestUrl = `${data[link]}?`;

	const params = keys.reduce((prev, key) => {
		if (include(key, data)) return `${prev}&${key}=${data[key]}`;
		return prev;
	}, "");

	return `${requestUrl}${params}`;
}
