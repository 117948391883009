import * as React from "react";
import { IFMTableComponentProps } from "../../../../components/FMTable/FMTableCellComponent";
import { IRebateSystemPersonalProject } from "../../../../interfaces/RebateSystemInterfaces";
import { Input as InputModule } from "../../../../components/UI/Input";
import { Box } from "@mui/material";
import { useInputStorage } from "../../../../components/Storage";

const Input = InputModule.rounded;

export function ChangeRebateValueCell (props: IFMTableComponentProps<IRebateSystemPersonalProject & {index: number, blank: boolean}, {}>) {
	const { blank, rebateValue } = props;
	const { value, handleChange } = useInputStorage("rebateValue", blank ? 0 : rebateValue);

	return (
		<Box m={"9px 0"}>
			<Input
				name="rebateValue"
				value={value}
				onChange={handleChange}
				iserror={false}
			/>
		</Box>
	);
}
