/*
    Created on : 02.11.2022, 06:18:59
    Author     : Andy Kar
*/
import {
	SET_ACCOUNT_FUNDS_DATA,
	SET_ACCOUNT_INFO, SET_ACCOUNT_LOADING, SET_ACCOUNT_TRADING_DATA
} from "../actions/actionTypes";

const initial = {
	info: {},
	funds: {},
	trading: {},
	loading: false
};

export default function accountReducer (state = initial, action:{type:string, payload:any}) {
	switch (action.type) {
		case SET_ACCOUNT_INFO:
			return {
				...state,
				info: action.payload
			};
		case SET_ACCOUNT_LOADING:
			return {
				...state,
				loading: action.payload
			};
		case SET_ACCOUNT_FUNDS_DATA:
			return {
				...state,
				funds: action.payload
			};
		case SET_ACCOUNT_TRADING_DATA:
			return {
				...state,
				trading: action.payload
			};
		default:
			return state;
	}
}
