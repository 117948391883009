import * as React from "react";
import { Box } from "@mui/material";
import ReactHtmlParser from "react-html-parser";

export function BalanceOperationDetailsRow (props: any) {
	const { action } = props;
	return <Box
		sx={{
			padding: "5px 15px"
		}}
	>{ReactHtmlParser(action)}</Box>;
}
