/* eslint-disable camelcase */
import * as React from "react";
import { Grid, Typography } from "@mui/material";
import { Copy } from "../../../../components/UI/Copy";
import { FieldWrapper as FieldWrapperUI } from "../../../../components/UI/FieldWrapper";
import { useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { useIsRtl } from "../../../../utils/hooks/useIsRtl";
import TanzaniaDepositorsLogos from "./images/TanzaniaDepositors.jpg";

export function LocalDepositorTanzania () {
	const {
		trd_43,
		trd_191,
		trd_192
	} = useAppSelector((state) => state.language.languageTrading);
	const { perdet_08 } = useAppSelector((state) => state.language.languageMyAccounts);
	const isRtl = useIsRtl();
	const FieldWrapper = React.useMemo(() => FieldWrapperUI.default, []);

	return (
		<>
			<Grid
				container
				flexDirection={isRtl ? "row-reverse" : "row"}
				sx={{ marginBottom: "16px", paddingTop: "20px" }}
			>
				<img
					src={TanzaniaDepositorsLogos}
					width="100%"
					alt="TanzaniaDepositorsLogos"
				/>
			</Grid>
			<Typography
				color="#5C7080"
				textAlign={isRtl ? "right" : "left"}
				className="light"
				sx={{ marginBottom: "15px" }}
			>
                ForexMart accepts deposit via the biggest banks of Tanzania.
			</Typography>
			<Typography
				color="#5C7080"
				textAlign={isRtl ? "right" : "left"}
				className="light"
				sx={{ marginBottom: "15px" }}
			>
            To proceed with the deposit transaction with bonus, you may contact our local service. All deposits are to be transacted at Central Bank current rate.
			</Typography>
			<Typography
				color="#5C7080"
				textAlign={isRtl ? "right" : "left"}
				className="bold"
				sx={{ marginBottom: "15px" }}
			>
				{trd_43}
			</Typography>
			<FieldWrapper label={perdet_08}>
				<Copy
					value="mlforexafrica@gmail.com"
				/>
			</FieldWrapper>
			<FieldWrapper label={trd_192}>
				<Copy
					value="https://t.me/Mlforexafrica"
				/>
			</FieldWrapper>
			<FieldWrapper label={trd_191}>
				<Copy
					value="+255628805446"
				/>
			</FieldWrapper>
		</>
	);
}
