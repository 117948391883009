/*
    Created on : 27.10.2021, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import FacebookIcon from "../../Icons/socialIcons/FacebookIcon";
import TwitterIcon from "../../Icons/socialIcons/TwitterIcon";
import LinkedinIcon from "../../Icons/socialIcons/LinkedinIcon";
import GoogleIcon from "../../Icons/socialIcons/GoogleIcon";
import { Box } from "@mui/material";

function SocialBoxIconlinks () {
	return (
		<Box
			className="flexRow socialIcons"
			sx={{
				justifyContent: "space-between",
				width: "100%",
				marginTop: "8px",
				"& a": {
					marginBottom: "8px",
					maxWidth: "100%"
				},
				"& a svg": {
					maxWidth: "100%"
				}
			}}
		>
			<a href="https://www.facebook.com/forexmartru" target="_blank" rel="noreferrer">
				<FacebookIcon/>
			</a>
			<a href="https://twitter.com/ForexMartRu" target="_blank" rel="noreferrer">
				<TwitterIcon/>
			</a>
			<a href="#" target="_blank" rel="noreferrer">
				<GoogleIcon/>
			</a>
			<a href="https://www.linkedin.com/" target="_blank" rel="noreferrer">
				<LinkedinIcon/>
			</a>
		</Box>
	);
}

export default SocialBoxIconlinks;
