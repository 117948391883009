import { Airtel } from "./Airtel";
import { MTN } from "./MTN";
import { Vodafone } from "./Vodafone";
import { Vodacom } from "./Vodacom";
import { EU } from "./EU";
import { Orange } from "./Orange";
import { Zamtel } from "./Zamtel";
import { Tigo } from "./Tigo";
import { Mpesa } from "./Mpesa";

export const MobileMoney = {
	Airtel,
	MTN,
	Vodafone,
	Vodacom,
	EU,
	Orange,
	Zamtel,
	Tigo,
	Mpesa
};
