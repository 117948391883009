/*
    Created on : 21.10.2021, 06:18:59
    Author     : Andy Kar
*/

import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import LayoutCopytrade from "./layouts/LayoutCopytrade/LayoutCopytrade";
import LayoutMyAccounts from "./layouts/LayoutMyAccounts/LayoutMyAccounts";
import LayoutMyTrading from "./layouts/LayoutMyTrading/LayoutMyTrading";
import LayoutFinance from "./layouts/LayoutFinance/LayoutFinance";
import LayoutBonus from "./layouts/LayoutBonus/LayoutBonus";
import LayoutPartnership from "./layouts/LayoutPartnership/LayoutPartnership";
import LayoutSmartDollar from "./layouts/LayoutSmartDollar/LayoutSmartDollar";
import LayoutRebateSystem from "./layouts/LayoutRebateSystem/LayoutRebateSystem";
import LayoutMailSupport from "./layouts/LayoutMailSupport/LayoutMailSupport";
import LayoutPromotionalMaterials from "./layouts/LayoutPromotionalMaterials/LayoutPromotionalMaterials";
import LayoutLoyaltyPrograms from "./layouts/LayoutLoyaltyPrograms/LoyaltyPrograms";
import { BitcoinDepositStatus } from "./pages/Finance/Deposit/CryptoCurrencyStatus/BitcoinDepositStatus";
import { BitcoinCashDepositStatus } from "./pages/Finance/Deposit/CryptoCurrencyStatus/BitcoinCashDepositStatus";
import { TetherDepositStatus } from "./pages/Finance/Deposit/CryptoCurrencyStatus/TetherDepositStatus";

export default function MainSwitch ({ redirect }: { redirect?: string }) {
	return (
		<Switch>
			<Route path="/app/smartdollar" render={() => <LayoutSmartDollar/>}/>
			<Route path="/app/partnership" render={() => <LayoutPartnership/>}/>
			<Route path="/app/graph" render={() => <LayoutPartnership/>}/>
			<Route path="/app/bonus" render={() => <LayoutBonus/>}/>
			<Route path="/app/deposit" render={() => <LayoutFinance/>}/>
			<Route path="/app/withdraw" render={() => <LayoutFinance/>}/>
			<Route path="/app/transfer" render={() => <LayoutFinance/>}/>
			<Route path="/app/bitcoin-deposit-status" render={() => <BitcoinDepositStatus/>}/>
			<Route path="/app/tether-deposit-status" render={() => <TetherDepositStatus />} />
			<Route path="/app/bitcoin-cash-deposit-status" render={() => <BitcoinCashDepositStatus />}/>
			<Route path="/app/transaction-history" render={() => <LayoutFinance/>}/>
			<Route path="/app/copytrade" render={() => <LayoutCopytrade/>}/>
			<Route path="/app/my-account/total-lots" render={() => <LayoutMyTrading/>}/>
			<Route path="/app/my-trading" render={() => <LayoutMyTrading/>}/>
			<Route path="/app/my-account/" render={() => <LayoutMyAccounts/>}/>
			<Route path="/app/registration" render={() => <LayoutMyAccounts/>}/>
			<Route path="/app/profile" render={() => <LayoutMyAccounts/>}/>
			<Route path="/app/rebate" render={() => <LayoutRebateSystem/>}/>
			<Route path="/app/mail" render={() => <LayoutMailSupport/>}/>
			<Route path="/app/promotional-materials" render={() => <LayoutPromotionalMaterials/>}/>
			<Route path="/app/loyalty-programs" render={() => <LayoutLoyaltyPrograms/>}/>
			{redirect
				? <Redirect from="*" to={"/app/" + redirect}/>
				: <Redirect from="*" to="/app/my-trading/"/>
			}
		</Switch>
	);
};
