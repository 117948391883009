import * as React from "react";
import { Grid } from "@mui/material";
import { FinanceSubPageWrapper } from "./FinanceSubPageWrapper";
import { Props } from "./types";
import { useIsMobile } from "../../../../utils/hooks/useIsMobile";

export function Pages (props: Props) {
	const { children } = props;
	const isMobile = useIsMobile(1024);

	return (
		<Grid
			item
			sx={{
				width: "100%",
				maxWidth: isMobile ? "410px" : "577px",
				margin: isMobile ? "0 auto" : "0"
			}}
		>
			<FinanceSubPageWrapper>
				{children}
			</FinanceSubPageWrapper>
		</Grid>
	);
}
