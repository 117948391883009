/*
    Created on : 12.10.2021, 06:18:59
    Author     : Andy Kar
*/

import {
	SET_BANNERS, SET_BANNERS_SHOW
} from "../actions/actionTypes";

export type ICategorySize = {
	id: string
	size: string
	width: string
	height: string
	url: string
	datecreated: string
	banner_name: string
	category_name: string
};

export type IAffiliateCode = {
	affiliate_code: string
};

export type IBannerShowItem = {
	id: string
	width: string
	height: string
	url: string
	imageData?: string
	src?: string
	href: string
	href2: string
};

export type IBannerListItem = {
	id: string
	num: number
	count: number
	banners: Array<IBannerShowItem>
	type: string
	size: string
};

export interface ILogo {
	svg: string
	png: string
	pdf: string
};

export interface ILogos {
	url_images: string
	url_pdf: string
	logos: Array<ILogo>
};

export interface IBanners {
	categorySizes: Array<ICategorySize>
	btn_show: string
	affiliate_code: Array<IAffiliateCode>
	logos: ILogos
	bannerList: Array<IBannerListItem>
	active_tab: string
	metadata_description: string
	metadata_keyword: string
};

export type IBannersShow = {
	lang: string
	folder: string
	affiliate_code: string
	size: string
	btn_downld: string
	btn_showcode: string
	banners: Array<IBannerShowItem>
};

const initial = {
	loadingBanners: false,
	banners: {
		categorySizes: [],
		logos: {},
		banners: [],
		btn_show: "Show these banners",
		affiliate_code: [],
		bannerList: [],
		active_tab: "promotional-materials",
		metadata_description: "Integrate our eye-catching, sophisticated banner on your website, blog, or personal site to advertise our offerings.",
		metadata_keyword: "ForexMart Banners"
	},
	bannersShow: {
		lang: "EN",
		folder: "banners",
		affiliate_code: "",
		size: "130x600",
		btn_downld: "Download file",
		btn_showcode: "Show Code",
		banners: []
	}
};

export default function bannersReducer (state = initial, action:{type:string, payload: any}) {
	switch (action.type) {
		case SET_BANNERS:
			return {
				...state,
				banners: action.payload
			};
		case SET_BANNERS_SHOW:
			return {
				...state,
				bannersShow: action.payload
			};

		default:
			return state;
	}
}
