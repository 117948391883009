/*
    Created on : 27.10.2021, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../interfaces/StoreInterfaces";
import { Box, Typography } from "@mui/material";
import { useIsRtl } from "../../../utils/hooks/useIsRtl";
import ReactHtmlParser from "react-html-parser";

export default function FooterDesk () {
	const lang:any = useSelector((state:StoreInterface) => state.language.language);
	const init:any = useSelector((state:StoreInterface) => state.init.init);
	const isRtl = useIsRtl();
	return (
		<Box
			sx={{
				marginTop: "10px",
				"& .MuiTypography-root": {
					marginBottom: "15px",
					fontSize: "13px",
					width: "100%",
					textAlign: isRtl ? "right" : "left"
				}
			}}
		>
			<Typography>{init.companyName} {lang.new_with_foo_04}</Typography>
			<Typography>
				{lang.new_with_foo_02} <b>{init.companyName}</b> {lang.new_with_foo_04}
			</Typography>
			<Typography>
				{lang.new_with_foo_05} <b>{init.companyName}</b> {lang.new_with_foo_06}
			</Typography>
			<Typography>{ReactHtmlParser(lang.gen_rw)} {lang.footer_gen_risk}</Typography>
		</Box>
	);
}
