import * as React from "react";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { getBonusesByDate } from "../../../../store/actions/actionTypes";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis, Tooltip } from "recharts";

export function Chart () {
	const bonuses = useAppSelector(state => state.bonus.bonuses);
	const dispatch = useAppDispatch();

	React.useEffect(() => {
		if (!Array.isArray(bonuses)) {
			dispatch(getBonusesByDate({}));
		}
	}, []);

	return (
		<ResponsiveContainer height={244}>
			<LineChart data={bonuses}>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="stamp" />
				<YAxis orientation="right" />
				<Line key="client" dataKey="amount" name="Amount" />
				<Tooltip />
			</LineChart>
		</ResponsiveContainer>
	);
}
