/* eslint-disable camelcase */
import * as React from "react";
import { Skeleton, Typography, Box } from "@mui/material";
import { ContentWrapper } from "../../../../components/UI/ContentWrapper";
import { useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { DataTableColumn, DataTableRow, DataTableView } from "../../../../components/UI/DataTableView";

export function MyProjectTradersInformation () {
	const { languageTrading, languageCopytrade } = useAppSelector((state) => state.language);
	const lastUpdate = useAppSelector((state) => state.copytrade.myProject.fc_details?.LastUpdate);
	const {
		parameters_account: accountNumber,
		fc_details = {},
		bal_details = {},
		project
	} = useAppSelector((state) => state.copytrade.myProject);
	const {
		account_type,
		AccountName,
		Icq,
		Phone,
		Email,
		Skype,
		ActiveFollowers,
		Currency
	} = fc_details;

	const {
		LastBalance,
		LastEquity,
		OpenTrades,
		CloseTrades,
		BalancePerDay,
		TotalBalanceIncrease,
		EquityPerDay,
		TotalEquityIncrease
	} = bal_details;

	return (
		<ContentWrapper
			title={languageTrading.trd_147}
			secondTitle={
				lastUpdate
					? (
						<Typography color="#ffffff">
							{lastUpdate}
						</Typography>
					)
					: (
						<Skeleton sx={{ bgcolor: "#cdcdcd" }} width="140px" height="21px" />
					)
			}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					padding: "10px",
					gap: "30px"
				}}
			>
				<DataTableView sx={{ width: "100%" }}>
					<DataTableRow
						sx={{
							borderBottomWidth: "2px",
							borderBottomColor: "#29a643"
						}}
					>
						<DataTableColumn hideBorderRight>
							<Typography color="#29a643" textAlign="center">
								{languageTrading.trd_148}
							</Typography>
						</DataTableColumn>
					</DataTableRow>
					<StyledRow title="Broker" value="ForexMart" />
					<StyledRow title={languageCopytrade.copytrade_33} value={accountNumber || ""} />
					<StyledRow title={languageCopytrade.copytrade_35} value={account_type || ""} />
					<StyledRow title={languageCopytrade.copytrade_37} value={project || ""} />
					<StyledRow title={languageTrading.trd_190} value={AccountName || ""} />
					<StyledRow title={languageCopytrade.copytrade_40} value={Icq || ""} />
					<StyledRow title={languageCopytrade.copytrade_42} value={Phone || ""} />
					<StyledRow title={languageCopytrade.copytrade_44} value={Email || ""} />
					<StyledRow title={languageCopytrade.copytrade_46} value={Skype || ""} />
				</DataTableView>

				<DataTableView sx={{ width: "100%" }}>
					<DataTableRow
						sx={{
							borderBottomWidth: "2px",
							borderBottomColor: "#29a643"
						}}
					>
						<DataTableColumn hideBorderRight>
							<Typography color="#29a643" textAlign="center">
								{languageTrading.trd_149}
							</Typography>
						</DataTableColumn>
					</DataTableRow>
					<StyledRow title={languageCopytrade.copytrade_32} value={ActiveFollowers || ""} />
					<StyledRow title={languageCopytrade.copytrade_34} value={(LastBalance && Currency) ? `${LastBalance} ${Currency}` : ""} />
					<StyledRow title={languageCopytrade.copytrade_36} value={(LastEquity && Currency) ? `${LastEquity} ${Currency}` : ""} />
					<StyledRow title={languageCopytrade.copytrade_38} value={OpenTrades ? `${OpenTrades}` : ""} />
					<StyledRow title={languageCopytrade.copytrade_39} value={CloseTrades ? `${CloseTrades}` : ""} />
					<StyledRow title={languageCopytrade.copytrade_41} value={(BalancePerDay && Currency) ? `${BalancePerDay} ${Currency}` : ""} />
					<StyledRow title={languageCopytrade.copytrade_43} value={TotalBalanceIncrease && Currency ? `${TotalBalanceIncrease} ${Currency}` : ""} />
					<StyledRow title={languageCopytrade.copytrade_45} value={(EquityPerDay && Currency) ? `${EquityPerDay} ${Currency}` : ""} />
					<StyledRow title={languageCopytrade.copytrade_47} value={(TotalEquityIncrease && Currency) ? `${TotalEquityIncrease} ${Currency}` : ""} />
				</DataTableView>
			</Box>
		</ContentWrapper>
	);
}

function StyledRow (props: { title: string, value: string }) {
	return (
		<DataTableRow>
			<DataTableColumn sx={{ minWidth: "215px" }}>
				<Typography color="#5c7080">
					{props.title}
				</Typography>
			</DataTableColumn>
			<DataTableColumn>
				<Typography fontWeight="bold" color="#5c7080">
					{props.value}
				</Typography>
			</DataTableColumn>
		</DataTableRow>
	);
}
