/*
    Created on : 12.10.2021, 06:18:59
    Author     : Andy Kar
*/

import {
	SET_BALANCE_OPERATIONS, SET_PANDING_TRANSACTIONS
} from "../actions/actionTypes";

export type IPendingTransaction = {
	num: number
	id: number
	fundType: string
	operationType: string
	amount: string
	paymentSystem: string
	date: number | string
	ticket: string
	isBlock: number | boolean
};

export type IBalanceOperation = {
	num: number
	id: number
	fundType: string
	fundStatus: string
	amount: string
	comment: string
	date: number | string
	action: string
};

const initial = {
	loadingPendingTransactions: false,
	pendingTransactions: [] as Array<IPendingTransaction>,
	loadingBalanceOperations: false,
	balanceOperations: [] as Array<IBalanceOperation>
};

export default function financeReducer (state = initial, action:{type:string, payload: any}): typeof initial {
	switch (action.type) {
		case SET_PANDING_TRANSACTIONS:
			return {
				...state,
				pendingTransactions: action.payload
			};
		case SET_BALANCE_OPERATIONS:
			return {
				...state,
				balanceOperations: action.payload
			};
		default:
			return state;
	}
}
