import * as React from "react";
import { Select } from "../../../../components/Form/Select";
import { Option } from "../../../../components/Form/Option";
import { DefaultForm } from "../../../../components/Forms/DepositForm/DefaultForm";
import { paycoValidationSchema } from "./validation";
import { useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { getInfo } from "./api";
import { getPaymentNetworkRequest } from "../../../../utils/getPaymentNetworkRequest";

export function Payco () {
	const [ isFetch, setIsFetch ] = React.useState(false);
	const language = useAppSelector((state) => state.language);
	const currency = useAppSelector((state) => state.session.userData.currency ?? "USD");

	const onSubmit = React.useCallback(async (values: any) => {
		try {
			setIsFetch(true);
			const { amount, currency } = values;
			const info = await getInfo(amount, currency);
			const requestLink = getPaymentNetworkRequest(info, "urlaction");
			window.open(requestLink, "_self");
		} catch (e) {
			// handle some error
		} finally {
			setIsFetch(false);
		}
	}, []);

	return (
		<DefaultForm
			defaultValues={{
				currency
			}}
			onSubmit={onSubmit}
			validationSchema={paycoValidationSchema}
			notes={language.languageFinance.dpc_06}
			isFetch={isFetch}
		>
			<Select
				name="currency"
				label={language.languageMyAccounts.mya_20}
			>
				<Option value="USD">USD</Option>
				<Option value="EUR">EUR</Option>
				<Option value="RUB">RUB</Option>
			</Select>
		</DefaultForm>
	);
}
