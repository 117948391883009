export type ActionType<T, P> = {
	type: T,
	payload: P,
};

export type ActionCreator<P> = (payload: P) => ActionType<string, P>;

export function createAction<P> (actionType: string): ActionCreator<P> {
	return function action (payload: P): ActionType<typeof actionType, P> {
		return {
			type: actionType,
			payload
		};
	};
}
