import React, { useMemo } from "react";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import dayjs from "dayjs";

interface IFMTableChart {
	data: [ number, number ][];
	valueName: string;
}

export default function FMTableChart (props: IFMTableChart) {
	const { data, valueName } = props;
	const parsedData = useMemo(() => (
		data.map(item => ({ date: dayjs(item[0]).format("MM/DD/YYYY"), [valueName]: item[1] })).reverse()
	), [ data, valueName ]);
	return (
		<ResponsiveContainer height={300}>
			<LineChart
				data={parsedData}
				margin={{ top: 5, bottom: 5, right: 0, left: 0 }}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="date" />
				<YAxis orientation={"right"} dataKey={valueName} />
				<Tooltip labelFormatter={() => ""} />
				<Line type="monotone" dataKey={valueName} stroke="#2988CA" activeDot={{ r: 8 }} />
				<Line type="monotone" dataKey="date" stroke="#5C7080" />
			</LineChart>
		</ResponsiveContainer>
	);
};
