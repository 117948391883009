const USDZMW = 19.28;
const USDPHP = 54.69;
const USDIDR = 15125.98;
const USDMYR = 4.66;

export function calcInZMW (amount: number) {
	if (isNaN(amount)) {
		return "0 ZMW";
	}

	return (amount * USDZMW).toFixed(2) + " ZMW";
}

export function calcInPHP (amount: number) {
	if (isNaN(amount)) {
		return "0 PHP";
	}

	return (amount * USDPHP).toFixed(2) + " PHP";
}

export function calcInIDR (amount: number) {
	if (isNaN(amount)) {
		return "0 IDR";
	}

	return (amount * USDIDR).toFixed(2) + " IDR";
}

export function calcInMYR (amount: number) {
	if (isNaN(amount)) {
		return "0 MYR";
	}

	return (amount * USDMYR).toFixed(2) + " MYR";
}
