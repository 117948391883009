import React from "react";

const BgFlag = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
			<g fillRule="evenodd" strokeWidth="1pt">
				<path fill="#de2110" d="M0 319.997h640V480H0z"/>
				<path fill="#fff" d="M0 0h640v160.003H0z"/>
				<path fill="#319400" d="M0 160.003h640v160.003H0z"/>
			</g>
		</svg>
	);
};

export default BgFlag;
