/*
    Created on : 24.01.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import PageTitle from "../../../components/Dashboard/dashboardComponents/PageTitle";
import CommonRebate from "../CommonRebate";
import FMTableColumn from "../../../components/FMTable/FMTableColumn";
import FMTable from "../../../components/FMTable/FMTable";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks/reduxHooks";
import { getRebateSystemProjects, setRebateSystemProjectsPage } from "../../../store/actions/actionTypes";
import { UpdateActionCell } from "./cells/UpdateActionCell";
import { ChangeRebateValueCell } from "./cells/ChangeRebateValueCell";
import { ChangeStatusCell } from "./cells/ChangeStatusCell";
import { ProjectNameCell } from "./cells/ProjectNameCell";
import { Storage } from "../../../components/Storage";

const MAX_LIMIT = 100;

export default function PageRebateDefault () {
	const { list, isLoad, page } = useAppSelector(state => state.rebateSystem.projects);
	const dispatch = useAppDispatch();
	const { languageTrading } = useAppSelector(state => state.language);

	const processedData = React.useMemo(() => list?.map((project, index) => ({ Index: index + 1, ...project })), [ list ]);

	function increasePage () {
		dispatch(setRebateSystemProjectsPage(page + 1));
	}

	function fetchPostSubscriptionsByParts () {
		if (isLoad) {
			dispatch(getRebateSystemProjects(
				{
					page,
					limit: MAX_LIMIT,
					actionAfterGettingProjects: increasePage
				})
			);
		}
	}

	React.useEffect(() => {
		fetchPostSubscriptionsByParts();
	}, [ page ]);

	return (
		<div className="page-rebate-default">
			<PageTitle
				layoutTitle={"Rebate System"}
				pageTitle={"Default"}
			/>

			<CommonRebate />

			<FMTable
				data={processedData}
				theme={"CopyTradingTheme"}
				id="RebateSystemDefaultTable"
				rowWrapper={Storage}
				mobileScheme={{
					mode: "points",
					600: {
						collapsibleColumns: [ 3, 4, 5 ]
					},
					840: {
						collapsibleColumns: [ 4, 5 ]
					},
					950: {
						collapsibleColumns: [ 5 ]
					}
				}}
			>
				<FMTableColumn id="Index" label="#" />
				<FMTableColumn id="ProjectName" label={languageTrading.reb_txt_9} component={ProjectNameCell} />
				<FMTableColumn id="RebateValue" label={languageTrading.reb_txt_10} component={ChangeRebateValueCell} />
				<FMTableColumn id="Status" label={languageTrading.trd_106} component={ChangeStatusCell} />
				<FMTableColumn id="Action" label={languageTrading.reb_txt_13} component={UpdateActionCell} />
			</FMTable>

		</div>
	);
};
