/*
    Created on : 05.02.2022, 06:18:59
    Author     : Andy Kar
*/

import { takeEvery, put, call } from "redux-saga/effects";
import {
	CHANGE_AVATAR,
	FETCH_MY_ACCOUNTS,
	REMOVE_AVATAR,
	SEND_PERSONAL_DETAILS,
	SEND_PHONE_VALIDATE_DATA,
	SEND_PHONE_VERIFY_CODE, SEND_REMOVE_ACCOUNT_INFO,
	SEND_SEND_REPORTS_INFO,
	SEND_SWAP_INFO, SEND_SWITCH_ACCOUNT_INFO,
	SEND_UPDATE_LEVERAGE_INFO,
	SET_CHANGED_AVATAR,
	SET_MY_ACCOUNTS_DATA,
	SET_MY_ACCOUNTS_LOADING,
	SET_PERSONAL_DETAILS_LOADING,
	SET_PERSONAL_DETAILS_RESPONSE,
	SET_PHONE_VALIDATE_RESPONSE,
	SET_PHONE_VERIFY_CODE_RESPONSE,
	SET_SEND_REPORTS_RESPONSE,
	SET_SWAP_RESPONSE,
	SET_UPDATE_LEVERAGE_RESPONSE
} from "../store/actions/actionTypes";
import { UserDataInterface } from "../interfaces/StoreInterfaces";
import { ResponseGenerator } from "../interfaces/mainInterfaces";
import API from "../utils/API";

/**
 Get My Accounts data from server and set it to store
 **/
export function * myAccountsWatcher () {
	yield takeEvery(FETCH_MY_ACCOUNTS, myAccountsWorker);
}
export function * myAccountsWorker (action:{type:string, myAccountsData: any}) {
	yield put({ type: SET_MY_ACCOUNTS_LOADING, payload: true });
	const data:{} = yield call(getMyAccountsInfo, action.myAccountsData);
	yield put({ type: SET_MY_ACCOUNTS_LOADING, payload: false });
	if (Object.keys(data).length) {
		yield put({ type: SET_MY_ACCOUNTS_DATA, payload: data });
	}
}
async function getMyAccountsInfo (myAccountsData:{}) {
	const response:ResponseGenerator = await API.post("myAccounts", myAccountsData);
	// console.log("getMyAccountsInfo", response);
	return response?.data || {};
}

/**
 Personal Details Edit data from server and set it to store
 **/
export function * personalDetailsEditWatcher () {
	yield takeEvery(SEND_PERSONAL_DETAILS, personalDetailsEditWorker);
}
export function * personalDetailsEditWorker (action:{type:string, personalDetailsData: any}) {
	yield put({ type: SET_PERSONAL_DETAILS_LOADING, payload: true });
	const data:{} = yield call(sendPersonalDetailsInfo, action.personalDetailsData);
	yield put({ type: SET_PERSONAL_DETAILS_LOADING, payload: false });
	if (Object.keys(data).length) {
		yield put({ type: SET_PERSONAL_DETAILS_RESPONSE, payload: data });
	}
}
async function sendPersonalDetailsInfo (personalDetailsData:{}) {
	const response:ResponseGenerator = await API.post("updateProfile", personalDetailsData);
	return response?.data || {};
}

/**
 Change Avatar and set it to store
 **/
interface avatarChangeResponseInterface {
	success?: boolean
	error?: string
	src?: string
	image?: string
}
export function * changeAvatarWatcher () {
	yield takeEvery(CHANGE_AVATAR, changeAvatarWorker);
}
export function * changeAvatarWorker (action:{type:string, changeAvatarData: any}) {
	// yield put({type: SET_PERSONAL_DETAILS_LOADING, payload: true})
	const data:avatarChangeResponseInterface = yield call(sendChangeAvatarData, action.changeAvatarData);
	// yield put({type: SET_PERSONAL_DETAILS_LOADING, payload: false})
	if (Object.keys(data).length && data.success && !data.error && data.image) {
		const userData: UserDataInterface = {};
		userData.image = data.image;
		yield put({ type: SET_CHANGED_AVATAR, payload: userData });
	}
}
async function sendChangeAvatarData (changeAvatarData:any) {
	const response:ResponseGenerator = await API.post("uploadAvatar", changeAvatarData);
	return response?.data || {};
}

/**
 Remove Avatar and set it to store
 **/
interface avatarRemoveResponseInterface {
	success?: boolean
	error?: string
}
export function * removeAvatarWatcher () {
	yield takeEvery(REMOVE_AVATAR, removeAvatarWorker);
}
export function * removeAvatarWorker (
	// action:{type:string, changeAvatarData: any}
) {
	// yield put({type: SET_PERSONAL_DETAILS_LOADING, payload: true})
	const data:avatarRemoveResponseInterface = yield call(sendRemoveAvatarData);
	// yield put({type: SET_PERSONAL_DETAILS_LOADING, payload: false})
	if (Object.keys(data).length && data.success && !data.error) {
		const userData: UserDataInterface = {};
		userData.image = null;
		yield put({ type: SET_CHANGED_AVATAR, payload: userData });
	}
}
async function sendRemoveAvatarData () {
	const response:ResponseGenerator = await API.post("removeAvatar");
	return response?.data || {};
}

/**
 Validate phone by sms code data from server and set it to store
 **/
export function * phoneValidateWatcher () {
	yield takeEvery(SEND_PHONE_VALIDATE_DATA, phoneValidateWorker);
}
export function * phoneValidateWorker (action:{type:string, phoneValidateData: any}) {
	// yield put({type: SET_PERSONAL_DETAILS_LOADING, payload: true})
	const data:{} = yield call(sendPhoneValidateData, action.phoneValidateData);
	// yield put({type: SET_PERSONAL_DETAILS_LOADING, payload: false})
	if (Object.keys(data).length) {
		yield put({ type: SET_PHONE_VALIDATE_RESPONSE, payload: data });
	}
}
async function sendPhoneValidateData (phoneValidateData:{}) {
	const response:ResponseGenerator = await API.post("sendSMS", phoneValidateData);
	return response?.data || {};
}
export function * phoneVerifyCodeWatcher () {
	yield takeEvery(SEND_PHONE_VERIFY_CODE, phoneVerifyCodeWorker);
}
export function * phoneVerifyCodeWorker (action:{type:string, phoneVerifyCodeData: any}) {
	// yield put({type: SET_PERSONAL_DETAILS_LOADING, payload: true})
	const data:{} = yield call(sendPhoneVerifyCodeData, action.phoneVerifyCodeData);
	// yield put({type: SET_PERSONAL_DETAILS_LOADING, payload: false})
	if (Object.keys(data).length) {
		yield put({ type: SET_PHONE_VERIFY_CODE_RESPONSE, payload: data });
	}
}
async function sendPhoneVerifyCodeData (phoneVerifyCodeData:{}) {
	const response:ResponseGenerator = await API.post("updateProfile", phoneVerifyCodeData);
	return response?.data || {};
}

/**
 Update Leverage data and set it to store
 **/
export function * updateLeverageWatcher () {
	yield takeEvery(SEND_UPDATE_LEVERAGE_INFO, updateLeverageWorker);
}
export function * updateLeverageWorker (action:{type:string, leverageData: any}) {
	// yield put({type: SET_UPDATE_LEVERAGE_LOADING, payload: true})
	const data:{} = yield call(sendUpdateLeverageInfo, action.leverageData);
	// yield put({type: SET_UPDATE_LEVERAGE_LOADING, payload: false})
	if (Object.keys(data).length) {
		yield put({ type: SET_UPDATE_LEVERAGE_RESPONSE, payload: data });
	}
}
async function sendUpdateLeverageInfo (leverageData:{}) {
	const response:ResponseGenerator = await API.post("updateLeverage", leverageData);
	console.log("sendUpdateLeverageInfo", response);
	return response?.data || {};
}

/**
 Update Swap data and set it to store
 **/
export function * updateSwapWatcher () {
	yield takeEvery(SEND_SWAP_INFO, updateSwapWorker);
}
export function * updateSwapWorker (action:{type:string, swapData: any}) {
	// yield put({type: SET_SWAP_LOADING, payload: true})
	const data:{} = yield call(sendUpdateSwapInfo, action.swapData);
	// yield put({type: SET_SWAP_LOADING, payload: false})
	if (Object.keys(data).length) {
		yield put({ type: SET_SWAP_RESPONSE, payload: data });
	}
}
async function sendUpdateSwapInfo (swapData:{}) {
	const response:ResponseGenerator = await API.post("updateSwap", swapData);
	console.log("sendUpdateSwapInfo", response);
	return response?.data || {};
}
/**
 Update SendReports data and set it to store
 **/
export function * updateSendReportsWatcher () {
	yield takeEvery(SEND_SEND_REPORTS_INFO, updateSendReportsWorker);
}
export function * updateSendReportsWorker (action:{type:string, sendReportsData: any}) {
	// yield put({type: SET_SEND_REPORTS_LOADING, payload: true})
	const data:{} = yield call(sendUpdateSendReportsInfo, action.sendReportsData);
	// yield put({type: SET_SEND_REPORTS_LOADING, payload: false})
	if (Object.keys(data).length) {
		yield put({ type: SET_SEND_REPORTS_RESPONSE, payload: data });
	}
}
async function sendUpdateSendReportsInfo (sendReportsData:{}) {
	const response:ResponseGenerator = await API.post("updateReportStatus", sendReportsData);
	console.log("sendUpdateSendReportsInfo", response);
	return response?.data || {};
}
/**
 Switch Account data and set it to store
 **/
interface SwitchAccountRequestInterface {
	api_session?: {
		account_number?: number
		con_account_number?: number
		con_session_id?: string|null
		session_expiration?: number
		session_id?: string
	}
	username?:string
}
export function * switchAccountWatcher () {
	yield takeEvery(SEND_SWITCH_ACCOUNT_INFO, switchAccountWorker);
}
export function * switchAccountWorker (action:{type:string, switchAccountData: any}) {
	console.log("switchAccountWorker");
	const data:SwitchAccountRequestInterface = yield call(sendSwitchAccountInfo, action.switchAccountData);
	if (Object.keys(data).length) {
		// yield put({ type: FETCH_SESSION_INFO });
		yield put({ type: FETCH_MY_ACCOUNTS, myAccountsData: {} });
	}
}
async function sendSwitchAccountInfo (switchAccountData:{}) {
	const response:ResponseGenerator = await API.post("switchAccount", switchAccountData);
	console.log("sendSwitchAccountInfo", response);
	return response?.data || {};
}
/**
 Remove Account data and set it to store
 **/
export function * removeAccountWatcher () {
	yield takeEvery(SEND_REMOVE_ACCOUNT_INFO, removeAccountWorker);
}
export function * removeAccountWorker (action:{type:string, removeAccountData: any}) {
	const data:{} = yield call(sendRemoveAccountInfo, action.removeAccountData);
	if (Object.keys(data).length) {
		yield put({ type: FETCH_MY_ACCOUNTS, myAccountsData: {} });
	}
}
async function sendRemoveAccountInfo (removeAccountData:{}) {
	const response:ResponseGenerator = await API.post("removeAccount", removeAccountData);
	console.log("sendRemoveAccountInfo", response);
	return response?.data || {};
}
