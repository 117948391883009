export const CurrenciesSymbols = {
	USD: "$",
	EUR: "€",
	RUB: "₽",
	IDR: "Rp",
	LAK: "₭",
	MMK: "K",
	KHR: "៛",
	ZAR: "R",
	THB: "฿",
	PHP: "₱",
	TZS: "TSh"
};

export type CurrencyType = keyof typeof CurrenciesSymbols;
