import React from "react";
import { Form } from "../../components/Form";
import { Box } from "@mui/material";
import * as yup from "yup";
import { TSetPeriod } from "./FMTablePeriodButtons";
import { ISetState } from "../../types/common";
import FMTablePeriodSelect from "./FMTablePeriodSelect";
import FMTablePeriodActions from "./FMTablePeriodActions";
import { useAdaptive } from "../../utils/hooks/themeBreakpointsHooks";
import { IFMTableRowObj, IHeadCell } from "./formatDataFMTable";

const schema = yup.object().shape({
	period: yup.string(),
	dateFrom: yup.string(),
	dateTo: yup.string()
}).required();

interface IFMTablePeriod<T, M> {
	rows: Array<IFMTableRowObj<T, M>>
	headCells : Array<IHeadCell<T>>
	children?: React.ReactNode
	period: number
	setPeriod: TSetPeriod
	periodsNames: string[]
	dateFrom: any
	dateTo: any
	setDateFrom: ISetState
	setDateTo: ISetState
	withApplyButton: boolean
	withChart: boolean
	withExport: boolean
	toggleShowChart: () => void
}

export default function FMTablePeriod<T, M> (props: IFMTablePeriod<T, M>) {
	const { children, period, withApplyButton, withChart, withExport } = props;

	const now = new Date();
	const defaultValues = {
		period,
		dateFrom: now.setDate(now.getDate() - 1),
		dateTo: new Date()
	};

	const boxSX = useAdaptive({
		xs: {
			display: "block",
			"&>div:first-of-type": {
				display: "block"
			},
			"&>div>div": {
				display: "block",
				width: "100%",
				maxWidth: "100%",
				marginX: 0
			},
			"&>div>button": {
				flex: "1",
				mt: "2px",
				marginX: "7px"
			}
		},
		sm: {
			"&>div:first-of-type": {
				display: "flex"
			},
			"&>div>div": {
				marginX: "7px"
			}
		},
		md: {
			display: "flex",
			"&>div": {
				marginX: "7px",
				alignItems: "flex-end"
			},
			"&>div>button": {
				minWidth: "max-content",
				marginX: "4px"
			}
		}
	});

	return (
		<Box>
			<Form
				defaultValues={defaultValues}
				onSubmit={() => null}
				fieldWrapperVariant="for-table-date"
				validationSchema={schema}
			>
				<Box className="flexRow" sx={boxSX}>
					<FMTablePeriodSelect { ...props }/>
					{(withApplyButton || withChart || withExport) && <FMTablePeriodActions { ...props }/>}
				</Box>
			</Form>
			{children}
		</Box>
	);
};
