import * as React from "react";
import { Box, Grid, Skeleton, styled, Typography, SxProps } from "@mui/material";
import { ModalPortal } from "../../../../components/ModalPortal";
import { CleanButton } from "../../../../components/UI/CleanButton";
import { ReactComponent as CloseIcon } from "../../../../images/CloseIcon.svg";
import { useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { useAdaptive } from "../../../../utils/hooks/themeBreakpointsHooks";

export interface ISettingsModalProps {
	isOpen: boolean;
	onClose: (isOpen: boolean) => void;
	connectionId: number;
}

const Row = styled(Box)({
	display: "flex",
	justifyContent: "space-between",
	borderBottom: "1px solid #ddd",
	padding: "5px 15px",
	"&:nth-of-type(odd)": {
		backgroundColor: "#f9f9f9"
	}
});

const xsWrapperStyles: SxProps = {
	padding: "10px 10px 30px",
	maxWidth: "800px",
	width: "100%",
	background: "#fff"
};
const smWrapperStyles: SxProps = {
	padding: "18px 35px 28px"
};

export function SettingsModal (props: ISettingsModalProps) {
	const { isOpen, onClose, connectionId } = props;
	const langCopytrade = useAppSelector((state) => state.language.languageCopytrade);
	const commissionSettings = useAppSelector((state) => state.copytrade.mySubscriptions.traders.data?.find((trader) => trader.ConnectionId === connectionId)?.commissionSettings);

	const adaptiveWrapperStyles = useAdaptive({
		xs: xsWrapperStyles,
		sm: smWrapperStyles
	});

	return isOpen
		? (
			<ModalPortal>
				<Box
					sx={{
						width: "100%",
						height: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						padding: "16px"
					}}
				>
					<Grid
						container
						sx={adaptiveWrapperStyles}
						flexDirection="column"
					>
						<Grid item>
							<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
								<CleanButton
									onClick={() => onClose(false)}
								>
									<CloseIcon />
								</CleanButton>
							</Box>
						</Grid>
						<Grid item>
							<Row alignItems="center">
								<Typography>{langCopytrade.reg_trader_14}</Typography>
								{
									commissionSettings
										? (
											<Typography>{ commissionSettings.td_7 || "Not Used" }</Typography>
										)
										: (
											<Skeleton variant="rectangular" width={80} height={16} />
										)
								}
							</Row>
							<Row alignItems="center">
								<Typography>{langCopytrade.reg_trader_17}</Typography>
								{
									commissionSettings
										? (
											<Typography>{ commissionSettings.td_2 || "Not Used" }</Typography>
										)
										: (
											<Skeleton variant="rectangular" width={80} height={16} />
										)
								}
							</Row>
							<Row alignItems="center">
								<Typography>{langCopytrade.reg_trader_19}</Typography>
								{
									commissionSettings
										? (
											<Typography>{ commissionSettings.td_10 || "Not Used" }</Typography>
										)
										: (
											<Skeleton variant="rectangular" width={80} height={16} />
										)
								}
							</Row>
							<Row alignItems="center">
								<Typography>{langCopytrade.reg_trader_23}</Typography>
								{
									commissionSettings
										? (
											<Typography>{ commissionSettings.td_3 || "Not Used" }</Typography>
										)
										: (
											<Skeleton variant="rectangular" width={80} height={16} />
										)
								}
							</Row>
							<Row alignItems="center">
								<Typography>{langCopytrade.reg_trader_26}</Typography>
								{
									commissionSettings
										? (
											<Typography>{ commissionSettings.td_4 || "Not Used" }</Typography>
										)
										: (
											<Skeleton variant="rectangular" width={80} height={16} />
										)
								}
							</Row>
						</Grid>
					</Grid>
				</Box>
			</ModalPortal>
		)
		: (
			<></>
		);
}
