/*
    Created on : 20.01.2022, 06:18:59
    Author     : Andy Kar
*/
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import {
	FETCH_OPENED_TRADES, FETCH_PART_TRADES
} from "../../store/actions/actionTypes";
import {
	StoreInterface
} from "../../interfaces/StoreInterfaces";
import PageCurrentTrades from "../../pages/MyTrading/PageCurrentTrades/PageCurrentTrades";
import PageHistoryOfTrades from "../../pages/MyTrading/PageHistoryOfTrades/PageHistoryOfTrades";
import PageTotalLots from "../../pages/MyTrading/PageTotalLots/PageTotalLots";
import AccountTypes from "../components/AccountTypes/AccountTypes";

const LayoutMyTrading = (props:any) => {
	const tradesLength:number = useSelector((state:StoreInterface) => state.trades.tradesLength);
	const tradesAllLength:number = useSelector((state:StoreInterface) => state.trades.tradesAllLength);

	const dispatch = useDispatch();
	const fetchTradesByParts = () => {
		if (!tradesAllLength || tradesAllLength < tradesLength) {
			const length = tradesLength / 3 > 3000 ? Math.ceil(tradesLength / 3) : 3000;
			const mData = { start: tradesAllLength, length };
			dispatch({ type: FETCH_PART_TRADES, mData });
		}
	};

	useEffect(() => {
		fetchTradesByParts();
	}, [ tradesAllLength ]);

	useEffect(() => {
		dispatch({ type: FETCH_OPENED_TRADES });
	}, []);

	return (
		<div className="layout layout-my-trading">
			<Switch>
				<Route exact path="/app/my-trading/current-trades" render={() => <PageCurrentTrades {...props} />} />
				<Route exact path="/app/my-trading/history-of-trades" render={() => <PageHistoryOfTrades {...props} />} />
				<Route exact path="/app/my-account/total-lots" render={() => <PageTotalLots {...props} />} />
				<Redirect exact from="/app/my-trading" to="/app/my-trading/current-trades" />
			</Switch>
			<AccountTypes/>
		</div>
	);
};

export default withRouter(LayoutMyTrading);
