import * as React from "react";
import { Grid } from "@mui/material";
import { useIsRtl } from "../../../../utils/hooks/useIsRtl";
import { QuickNavLink } from "./QuickNavLink";
import "./quickNav.scss";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../../interfaces/StoreInterfaces";

export function QuickNav () {
	const isRtl = useIsRtl();
	const language = useSelector((state: StoreInterface) => state.language);

	return (
		<Grid
			container
			className="quick-nav"
			flexDirection={isRtl ? "row-reverse" : "row"}
		>
			<QuickNavLink
				title={language.languageFinance.finav_01}
				to="/app/deposit"
			/>
			<QuickNavLink
				title={language.languageFinance.finav_02}
				to="/app/withdraw"
			/>
			<QuickNavLink
				title={language.languageFinance.qnav_01}
				to="/app/wallet"
			/>
			<QuickNavLink
				title={language.languageMyAccounts.hot_23}
				to="/app/transfer"
			/>
			<QuickNavLink
				title={language.languageFinance.qnav_02}
				to="/app/transaction-history"
			/>
		</Grid>
	);
}
