/*
    Created on : 04.02.2022, 06:18:59
    Author     : Andy Kar
*/
import React, { useEffect, useState } from "react";
import "./FormHorizontalGroupPhoneWithVerification.scss";
import FormTooltip from "../FormTooltip/FormTooltip";
import { useDispatch, useSelector } from "react-redux";
import {
	SEND_PHONE_VALIDATE_DATA,
	SEND_PHONE_VERIFY_CODE
} from "../../../../store/actions/actionTypes";
import { StoreInterface } from "../../../../interfaces/StoreInterfaces";
import { Row } from "../../../UI/Row";
import Typography from "@mui/material/Typography";
import { Box, SxProps, Theme } from "@mui/material";

interface FormHorizontalGroupPhoneWithVerificationInterface {
	label_text?: string
	required?: boolean
	type?: string
	name?: string
	placeholder?: string
	defaultValue?:any
	error?: string
	tooltip_message?: string
	verified?: boolean
	verify_btn_txt?: string
	send_verify_btn_txt?: string
	verify_name?: string
	verify_placeholder?: string
	sx?: SxProps<Theme>
}

function FormHorizontalGroupPhoneWithVerification (props:FormHorizontalGroupPhoneWithVerificationInterface) {
	const langMyAccounts:any = useSelector((state:StoreInterface) => state.language.languageMyAccounts);
	const verifyResponse:any = useSelector((state:StoreInterface) => state.myAccounts.verifyResponse);

	const [ openVerify, setOpenVerify ] = useState(false);
	const [ verifyCode, setVerifyCode ] = useState("");
	const [ verifyPhone, setVerifyPhone ] = useState("");

	useEffect(() => {
		setVerifyPhone(props.defaultValue);
	}, [ props.defaultValue ]);

	const dispatch = useDispatch();
	const sendValidateData = () => {
		const phoneValidateData = new FormData();
		if (verifyPhone) {
			phoneValidateData.append("tel", verifyPhone);
			dispatch({ type: SEND_PHONE_VALIDATE_DATA, phoneValidateData });
			if (Object.keys(verifyResponse).length && !verifyResponse.error) {
				setOpenVerify(true);
			}
		}
	};
	const sendVerifyCode = () => {
		const phoneVerifyCodeData = new FormData();
		if (props.verify_name && verifyCode) {
			phoneVerifyCodeData.append(props.verify_name, verifyCode);
			dispatch({ type: SEND_PHONE_VERIFY_CODE, phoneVerifyCodeData });
		}
	};

	return (
		<>
			<Box className="form-group flexRow" sx={props.sx}>
				<Box width={"100%"}>
					<Row sx={{ justifyContent: "space-between" }}>
						<Typography fontWeight={"bold"} color={"#5C7080"} className="form-horizontal-left-column flexRow">
							{props.label_text}
							{props.required
								? <cite className="form-horizontal-req">*</cite>
								: null
							}
						</Typography>
						<FormTooltip
							message={props.tooltip_message}
						/>
					</Row>
					<div className="form-horizontal-right-column flexRow">
						<div className="verify-input-wrap flexRow">
							<input
								type={props.type || "text"}
								name={props.name}
								className="form-horizontal-input verify-input form-control"
								placeholder={props.placeholder || ""}
								value={verifyPhone || ""}
								onChange={(e) => { setVerifyPhone(e.target.value); }}
							/>
							{!props.verified}
							<div
								className="verify-btn"
								onClick={sendValidateData}
							>
								{props.verify_btn_txt}
							</div>
						</div>
						<span
							className="name-error error"
							style={{ display: "none" }}
						>
							{props.error}
						</span>
					</div>

				</Box>
			</Box>

			{openVerify
				? <div className="form-group flexRow">
					<label className="form-horizontal-left-column flexRow"></label>
					<div className="form-horizontal-right-column flexRow">
						<div className="verify-input-wrap flexRow">
							<input
								type={props.type || "text"}
								name={props.verify_name}
								className="form-horizontal-input form-control verify-input sms-input"
								placeholder={props.verify_placeholder || ""}
								value={verifyCode || ""}
								onChange={(e) => setVerifyCode(e.target.value)}
							/>
							<div
								className="send-verify-btn verify-btn"
								onClick={sendVerifyCode}
							>
								{props.send_verify_btn_txt}
							</div>
							{Object.keys(verifyResponse).length
								? <div className="verify-message">
									{verifyResponse.validated
										? <span className="verify-message-success">{langMyAccounts.reg_wr_07}</span>
										: <span className="verify-message-error">{langMyAccounts.reg_wr_08}</span>
									}
								</div>
								: null
							}
						</div>
					</div>
				</div>
				: null
			}
		</>
	);
}

export default FormHorizontalGroupPhoneWithVerification;
