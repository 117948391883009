import * as React from "react";
import { Box } from "@mui/material";
import { Redirect, Route } from "react-router-dom";

import {
	Alipay,
	Bitcoin,
	BitcoinCash,
	BrazilPix,
	Card,
	ChinaUnionPay,
	Fasapay,
	LocalBank,
	LocalBankTransferIndonesia,
	LocalBankTransferMalaysia,
	LocalDepositorIndonesia,
	LocalDepositorMalaysia,
	LocalDepositorTanzania,
	LocalBankTransferVietnam,
	Neteller,
	Payco,
	PerfectMoney,
	Skrill,
	Tether,
	LocalBankTransferThailand,
	OnlineNaira,
	LocalBankTransferMexico,
	ThunderXPay,
	MobileMoney
} from "../../../pages/Finance/Withdraw";
import {
	Finance,
	InfoBar,
	PaymentNetworkLink,
	QuickNav
} from "../common";
import { useAppSelector } from "../../../utils/hooks/reduxHooks";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../interfaces/StoreInterfaces";
import PageTitle from "../../../components/Dashboard/dashboardComponents/PageTitle";
import LoadingSpinner from "../../../components/UI/LoadingSpinner/LoadingSpinner";
import { TPaymentMethod } from "../../../types/common";
import { withDevelopMode } from "../../../utils/withDevelopMode";
import { WITHDRAW_METHODS } from "../../../pages/Finance/Withdraw/constants";

export function WithdrawLayout () {
	const {
		Office,
		isIndonesianCountry,
		isMalaysianCountry
	} = useAppSelector((state) => state.init.init);
	const container = React.useRef(null);

	const language:any = useSelector((state: StoreInterface) => state.language);
	const langFinance:any = useSelector((state:StoreInterface) => state.language.languageFinance);
	const withdrawMethods = useAppSelector(state => withDevelopMode(state.user.userWithdrawMethods, WITHDRAW_METHODS));

	return (
		<Finance.Wrapper>
			<PageTitle
				layoutTitle={language.language.sb_li_2}
				pageTitle={langFinance.finav_02}
			/>

			{
				withdrawMethods.length === 0
					? (
						<Box sx={{ transform: "translateY(400%)", width: "100%" }}>
							<LoadingSpinner />
						</Box>
					)
					: (
						<>
							<Finance.Links>
								{
									withdrawMethods.map((withdrawMethod: TPaymentMethod) => (
										<PaymentNetworkLink
											key={withdrawMethod.id}
											icon={withdrawMethod.imgBase64}
											title={withdrawMethod.payment_method_name}
											to={`/app/withdraw/${withdrawMethod.payment_method_link}`}
										/>
									))
								}
							</Finance.Links>

							<Finance.Pages>
								<Box sx={{ marginBottom: "20px" }}>
									<QuickNav />
								</Box>

								<Box sx={{ marginBottom: "20px" }}>
									<InfoBar />
								</Box>

								<div ref={container}></div>
								<Route exact path="/app/withdraw">
									<Redirect to={`/app/withdraw/${withdrawMethods[0].payment_method_link}`} />
								</Route>
								<Route
									path="/app/withdraw/alipay"
									component={Alipay}
								/>
								<Route
									path="/app/withdraw/cryptocurrency"
									component={Bitcoin}
								/>
								<Route
									path="/app/withdraw/bitcoin-cash"
									component={BitcoinCash}
								/>
								<Route
									path="/app/withdraw/china-union-pay"
									component={ChinaUnionPay}
								/>
								<Route
									path="/app/withdraw/fasapay"
									component={Fasapay}
								/>
								<Route
									path="/app/withdraw/local-bank"
									component={LocalBank}
								/>
								{
									isIndonesianCountry || Office
										? (
											<>
												<Route
													path="/app/withdraw/idr-local-bank-transfer"
												>
													<LocalBankTransferIndonesia tabsMenuContainer={container} />
												</Route>
												<Route
													path="/app/withdraw/local-depositor-indonesia"
													component={LocalDepositorIndonesia}
												/>
											</>
										)
										: (
											<></>
										)
								}
								{
									isMalaysianCountry || Office
										? (
											<>
												<Route
													path="/app/withdraw/myr-local-bank-transfer"
												>
													<LocalBankTransferMalaysia tabsMenuContainer={container}/>
												</Route>
												<Route
													path="/app/withdraw/local-depositor-myr"
													component={LocalDepositorMalaysia}
												/>
											</>
										)
										: (<></>)
								}
								<Route
									path="/app/withdraw/neteller"
									component={Neteller}
								/>
								<Route
									path="/app/withdraw/payco"
									component={Payco}
								/>
								<Route
									path="/app/withdraw/skrill"
									component={Skrill}
								/>
								<Route
									path="/app/withdraw/card"
									component={Card}
								/>
								<Route
									path="/app/withdraw/crypt-api"
									component={Tether}
								/>
								<Route
									path="/app/withdraw/perfect-money"
									component={PerfectMoney}
								/>
								<Route
									path="/app/withdraw/brazil-pix"
									component={BrazilPix}
								/>
								<Route
									path="/app/withdraw/local-depositor-tz"
									component={LocalDepositorTanzania}
								/>
								<Route
									path="/app/withdraw/mobile-money/airtel"
									component={MobileMoney.Airtel}
								/>
								<Route
									path="/app/withdraw/mobile-money/tigo"
									component={MobileMoney.Tigo}
								/>
								<Route
									path="/app/withdraw/mobile-money/vodacom"
									component={MobileMoney.Vodacom}
								/>
								<Route
									path="/app/withdraw/mobile-money/mtn"
									component={MobileMoney.MTN}
								/>
								<Route
									path="/app/withdraw/mobile-money/eu-mobile"
									component={MobileMoney.EU}
								/>
								<Route
									path="/app/withdraw/mobile-money/orange"
									component={MobileMoney.Orange}
								/>
								<Route
									path="/app/withdraw/mobile-money/mpesa"
									component={MobileMoney.Mpesa}
								/>
								<Route
									path="/app/withdraw/mobile-money/zamtel"
									component={MobileMoney.Zamtel}
								/>
								<Route
									path="/app/withdraw/mobile-money/vodafone"
									component={MobileMoney.Vodafone}
								/>
								<Route
									path="/app/withdraw/online-naira"
									component={OnlineNaira}
								/>
								<Route
									path="/app/withdraw/vnd-local-bank-transfer"
								>
									<LocalBankTransferVietnam tabsMenuContainer={container} />
								</Route>
								<Route
									path="/app/withdraw/thb-local-bank-transfer"
								>
									<LocalBankTransferThailand tabsMenuContainer={container}/>
								</Route>
								<Route
									path="/app/withdraw/bank-transfer-mxn"
									component={LocalBankTransferMexico}
								/>
								<Route
									path="/app/withdraw/thunderXPay"
									component={ThunderXPay}
								/>
							</Finance.Pages>
						</>
					)
			}
		</Finance.Wrapper>
	);
}
