import React from "react";

const CnFlag = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="-5 -5 12.8 9.6">
			<defs>
				<path id="b" fill="#ffde00" d="M-.588.81L0-1 .588.81-.952-.31H.952z"/>
				<path d="M-5-15.4h26.667v20H-5z"/>
			</defs>
			<g transform="translate(1 0.5)">
				<g transform="matrix(.48 0 0 .48 -2.6 2.39)" >
					<path fill="#de2910" d="M-5-15.4h30v20H-5z"/>
					<use height="20" width="30" transform="matrix(3 0 0 3 0 -10.4)" xlinkHref="#b"/>
					<use height="20" width="30" transform="rotate(-120.93 -1.29 -8.12)" xlinkHref="#b"/>
					<use height="20" width="30" transform="rotate(-98.11 -1.45 -8.74)" xlinkHref="#b"/>
					<use height="20" width="30" transform="rotate(-74.04 -2.07 -8.84)" xlinkHref="#b"/>
					<use height="20" width="30" transform="rotate(-51.32 -4.16 -8.4)" xlinkHref="#b"/>
				</g>
			</g>
		</svg>
	);
};

export default CnFlag;
