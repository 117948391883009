import * as React from "react";
import { Grid, Typography } from "@mui/material";
import { useIsRtl } from "../../../../utils/hooks/useIsRtl";
import { useIsMobile } from "../../../../utils/hooks/useIsMobile";

export interface DefaultWrapperProps {
	label: string;
	tips?: string;
	children: React.ReactNode;
}

export function DefaultWrapper (props: DefaultWrapperProps) {
	const { label, children } = props;
	const isRtl = useIsRtl();
	const isMobile = useIsMobile();

	return (
		<Grid
			container
			justifyContent="space-between"
			alignItems={isMobile ? "left" : "center"}
			flexDirection={isMobile ? "column" : isRtl ? "row-reverse" : "row"}
			flexWrap="nowrap"
			sx={{
				position: "relative",
				marginBottom: isMobile ? "8px" : "30px",
				minHeight: "44px"
			}}
		>
			<Grid item sx={{ maxWidth: isMobile ? "200px" : "180px" }}>
				<Typography
					fontWeight="light"
					fontSize={18}
					textAlign={isRtl ? "right" : "left"}
					color="#5C7080"
				>
					{label}
				</Typography>
			</Grid>
			<Grid
				display="flex"
				sx={{
					alignItems: "flex-start",
					width: "100%",
					maxWidth: isMobile ? "100%" : "348px",
					position: "relative"
				}}
				item
			>
				{children}
			</Grid>
		</Grid>
	);
}
