import React from "react";

const ChangPasswordIcon = (props: any) => {
	let width = 16;
	if (props.width) {
		width = props.width;
	}
	return (
		<svg width={width} height={width} fill={props.fill} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 16">
			<defs>
				<clipPath id="icon-profile-lock-clip-path">
					<rect width={width} height={width} transform="translate(27 18)" fill="#e4e4e4"/>
				</clipPath>
			</defs>
			<g transform="translate(-27 -18)" clipPath="url(#icon-profile-lock-clip-path)">
				<g id="icon-profile-lock" transform="translate(25.052 15.999)">
					<path d="M15.358,7.414h-.416V7.3c0-.045,0-.09,0-.137s0-.1,0-.156a4.99,4.99,0,1,0-9.98,0V7.12h-.01v.294H4.54A1.386,1.386,0,0,0,3.155,8.8v7.818A1.386,1.386,0,0,0,4.54,18H15.358a1.381,1.381,0,0,0,1.383-1.383V8.8A1.385,1.385,0,0,0,15.358,7.414Zm-8.823-.54h.009a3.428,3.428,0,0,1,6.855.12c0,.106-.008.2-.015.282v.138H6.535Zm8.649,8.844a.72.72,0,0,1-.72.72H5.434a.721.721,0,0,1-.721-.72V9.707a.721.721,0,0,1,.721-.72h9.03a.72.72,0,0,1,.72.72v6.011Z" />
					<path d="M10.094,11.05h-.3a.631.631,0,0,0-.63.63v2.076a.631.631,0,0,0,.63.63h.3a.631.631,0,0,0,.63-.63V11.68A.631.631,0,0,0,10.094,11.05Z" />
				</g>
			</g>
		</svg>
	);
};

export default ChangPasswordIcon;
