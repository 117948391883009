import React from "react";
import { FlagIconInterface } from "./FlagIconInterface";
import EnFlag from "./EnFlag";
import SaFlag from "./SaFlag";
import FrFlag from "./FrFlag";
import DeFlag from "./DeFlag";
import IdFlag from "./IdFlag";
import PtFlag from "./PtFlag";
import RuFlag from "./RuFlag";
import BgFlag from "./BgFlag";
import MyFlag from "./MaFlag";
import PlFlag from "./PlFlag";
import PkFlag from "./PkFlag";
import GrFlag from "./GrFlag";
import CzFlag from "./CzFlag";
import CnFlag from "./CnFlag";
import BdFlag from "./BdFlag";
import VnFlag from "./VnFlag";
import FlagIcon from "./FlagIcon";
interface LangFlagIconInterface extends FlagIconInterface {
	lang?: string
}
const langIcon = (lang:string|undefined) => {
	switch (lang) {
		case "english":
			return <EnFlag />;
		case "arabic":
			return <SaFlag />;
		case "french":
			return <FrFlag />;
		case "german":
			return <DeFlag />;
		case "indonesian":
			return <IdFlag />;
		case "portuguese":
			return <PtFlag />;
		case "russuan":
			return <RuFlag />;
		case "bulgarian":
			return <BgFlag />;
		case "malay":
			return <MyFlag />;
		case "polish":
			return <PlFlag />;
		case "urdu":
			return <PkFlag />;
		case "greek":
			return <GrFlag />;
		case "czech":
			return <CzFlag />;
		case "chinese":
			return <CnFlag />;
		case "bangladesh":
			return <BdFlag />;
		case "vietnam":
			return <VnFlag />;
		default:
			return <EnFlag />;
	}
};
const LangFlagIcon = (props:LangFlagIconInterface) => {
	const width = props.width ? props.width : "26px";
	const height = props.height ? props.height : width;
	const radius = props.radius ? props.radius : "50%";

	return (
		<FlagIcon
			width={width}
			height={height}
			radius={radius}
			icon={langIcon(props.lang)}
		/>
	);
};

export default LangFlagIcon;
