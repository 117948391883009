import * as React from "react";
import { useAppSelector } from "../../../utils/hooks/reduxHooks";
import PageTitle from "../../../components/Dashboard/dashboardComponents/PageTitle";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { Step3 } from "./Step3";
import { NumberedTabs } from "../../../components/UI/Tabs";
import { BackButton } from "../../../components/BackButton";

export function PageAccountVerification () {
	const langMyAccounts = useAppSelector((state) => state.language.languageMyAccounts);

	return (
		<>
			<PageTitle pageTitle={langMyAccounts.mfn_03}/>
			<BackButton />
			<NumberedTabs
				labels={[
					"Upload ID",
					"Upload Proof of Billing",
					"Additional Documents"
				]}
			>
				<Step1 />
				<Step2 />
				<Step3 />
			</NumberedTabs>
		</>
	);
}
