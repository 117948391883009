import * as React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import FooterFeedback from "./FooterFeedback";
import languageLink from "../../../utils/languageLink";
import { useSelector } from "react-redux";
import { SessionUserDataInterface, StoreInterface } from "../../../interfaces/StoreInterfaces";
import Button from "@mui/material/Button";
import FooterCallbackIcon from "../../Icons/FooterCallbackIcon";

export default function FooterNeedHelp () {
	const lang:any = useSelector((state:StoreInterface) => state.language.language);
	const init:any = useSelector((state:StoreInterface) => state.init.init);
	const session:SessionUserDataInterface = useSelector((state:StoreInterface) => state.session.userData);

	const toCallback = () => {
		if (init.links.link_call_back) {
			const callbackLink = languageLink(init.links.link_call_back, session.site_lang ? session.site_lang : "english");
			if (callbackLink) window.open(callbackLink);
		}
		return null;
	};

	return (
		<Box
			sx={{
				flexWrap: "wrap",
				maxWidth: "100%",
				marginBottom: "35px",
				flex: "0 1 317px"
			}}
		>
			<Typography
				component="h3"
				className="flexRow"
				sx={{
					width: "100%",
					fontWeight: "bold",
					marginBottom: "16px"
				}}
			>
				Need Help?
			</Typography>
			<Box className={"flexRow"}>
				<FooterFeedback/>
				<Button
					variant="contained"
					startIcon={<FooterCallbackIcon/>}
					color="success"
					className={"flexRow"}
					onClick={toCallback}
					sx={{
						minWidth: "130px",
						justifyContent: "flex-start",
						marginX: "5px"
					}}
				>
					<Typography
						component="span"
						sx={{ textTransform: "none" }}
					>
						{lang.botnav_18}
					</Typography>
				</Button>
				{/* </a> */}
			</Box>
		</Box>
	);
}
