import { takeEvery, put } from "redux-saga/effects";
import {
	FETCH_INIT_INFO, FETCH_INIT_LINK_INFO,
	SET_INIT_INFO, SET_INIT_LINK_INFO
} from "../store/actions/actionTypes";
import API from "../utils/API";
import { IReactApiInterface } from "../interfaces/ReactApiInitInterface";
import { IInit, IInitlinks } from "../interfaces/InitInterface";

/**
 Get init main variables from server and set it to store
**/
interface InitResponseData extends IReactApiInterface {
	initData: IInit
}

export function * initWatcher () {
	yield takeEvery(FETCH_INIT_INFO, initWorker);
}
export function * initWorker () {
	const data: InitResponseData = yield getInitInfo();
	if (data.initData && Object.keys(data.initData)) yield put({ type: SET_INIT_INFO, payload: data.initData });
}
async function getInitInfo () {
	const response = await API.get("init");
	return response?.data || {};
}
/**
 Get init links variables from server and set it to store
**/

interface InitLinksResponseData extends IReactApiInterface {
	initLinksData: IInitlinks
}

export function * initLinksWatcher () {
	yield takeEvery(FETCH_INIT_LINK_INFO, initLinkWorker);
}
export function * initLinkWorker () {
	const data: InitLinksResponseData = yield getInitLinkInfo();
	if (data.initLinksData && Object.keys(data.initLinksData).length) { yield put({ type: SET_INIT_LINK_INFO, payload: data.initLinksData }); }
}
async function getInitLinkInfo () {
	const response:any = await API.get("initLinks");
	return response?.data || {};
}
