/* eslint-disable camelcase */
import * as React from "react";
import { Form } from "../../Form";
import { Input } from "../../Form/Input";
import { getWithdrawalValue } from "../../../utils/getReceivedValue";
import { useAppSelector } from "../../../utils/hooks/reduxHooks";
import { zotaPayDepositValidationSchema } from "./validation";
import { FormBottomWrapper } from "../../Form/FormBottomWrapper";
import { ButtonWithLoader } from "../../ButtonWithLoader";
import { Alert, Box, Snackbar } from "@mui/material";
import { WaningNote } from "../../UI/Note";
import { useErrorHandling } from "../../../utils/hooks/useErrorHandling";
import { InputRepeater } from "../../Form/InputRepeater";
import { Option } from "../../Form/Option";
import { Select } from "../../Form/Select";
import { FinanceFormProps } from "../types";
import { ZotapayFormError } from "../../../utils/errors/ZotapayFormError";

export type TDepositZotapayFormValues = {
	amount: string,
	phone: string,
	firstName: string,
	lastName: string,
	address: string,
	city: string,
	zip: string
	banks: string;
}

export interface DepositZotapayFormProps extends FinanceFormProps<TDepositZotapayFormValues> {}
export function DepositZotapayForm (props: DepositZotapayFormProps) {
	const {
		submit: _submit,
		calcInCurrency = () => ""
	} = props;
	const [ isFetch, setIsFetch ] = React.useState(false);
	const language = useAppSelector((state) => state.language);
	const accountNumber = useAppSelector((state) => `${state.session.userData.account_number ?? ""}`);
	const currency = useAppSelector((state) => state.session.userData.currency ?? "USD");
	const phone = useAppSelector((state) => state.user.user.phone1 ?? "");
	const [ firstName, lastName ] = useAppSelector((state) => {
		const fullNameArray = state.user.user.full_name?.split(" ");
		if (!fullNameArray) return [ state.user.user.full_name ?? "", "" ];

		return fullNameArray;
	});
	const { street = "", city = "", zip = "" } = useAppSelector((state) => state.user.user);
	const { isOpen, errorMsg, hideError, showError } = useErrorHandling();

	const getWithdrawalValueCB = React.useCallback((values: any) => getWithdrawalValue(values, currency), [ currency ]);
	async function submit (values: TDepositZotapayFormValues) {
		try {
			setIsFetch(true);

			await _submit(values);
		} catch (e) {
			if (e instanceof ZotapayFormError) {
				showError(e.message);
			} else {
				showError("Something went wrong");
			}
		} finally {
			setIsFetch(false);
		}
	}

	function _calcInCurrency (values: [string]) {
		const [ amount ] = values;
		return calcInCurrency(parseInt(amount));
	}

	return (
		<Form
			onSubmit={submit}
			validationSchema={zotaPayDepositValidationSchema()}
			defaultValues={{
				accountNumber,
				amount: "",
				phone,
				banks: "BCA",
				firstName,
				lastName,
				address: street,
				city,
				zip
			}}
		>
			<Input
				name="accountNumber"
				label={language.languageCopytrade.cpy_mon_accnum}
				variant="rounded"
			/>
			<Input
				name="amount"
				label={language.languageFinance.trd_230}
				variant="rounded"
			/>
			<InputRepeater
				fields="amount"
				label={""}
				onChange={_calcInCurrency}
			/>
			<Select
				name="banks"
				label={language.languageFinance.d_01}
			>
				<Option value="BCA">KlikBCA</Option>
				<Option value="BNI">BNI Bank</Option>
				<Option value="BRI">BRI Bank</Option>
				<Option value="CIMBN">CIMB Niaga</Option>
				<Option value="MDR">Bank Mandiri</Option>
				<Option value="PMTB">Bank Permata</Option>
			</Select>
			<Input
				name="firstName"
				label={language.languageMyAccounts.fn_01}
				variant="rounded"
			/>
			<Input
				name="lastName"
				label={language.languageMyAccounts.ln_01}
				variant="rounded"
			/>
			<Input
				name="phone"
				label={language.languageFinance.int_reg_45}
				variant="rounded"
			/>
			<Input
				name="address"
				label={language.languageMyAccounts.perdet_03}
				variant="rounded"
			/>
			<Input
				name="city"
				label={"City"}
				variant="rounded"
			/>
			<Input
				name="zip"
				label={language.languageMyAccounts.perdet_06}
				variant="rounded"
			/>
			<FormBottomWrapper
				forFields={[ "amount", "currency" ]}
				onChange={getWithdrawalValueCB}
				label={language.languageMyAccounts.form_message_01}
			>
				<ButtonWithLoader
					isFetch={isFetch}
					color={"primary"}
					text={language.languageMyAccounts.chapas_04}
				/>
			</FormBottomWrapper>
			<Box sx={{ marginTop: "6px" }}>
				<WaningNote
					message="Please fill all fields"
				/>
			</Box>
			<Snackbar open={isOpen} autoHideDuration={4000} onClose={hideError}>
				<Alert variant="filled" color="error">
					{errorMsg}
				</Alert>
			</Snackbar>
		</Form>
	);
}
