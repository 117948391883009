/*
    Created on : 12.11.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { IFMTableComponentProps } from "../../../../components/FMTable/FMTableCellComponent";
import { IUserSupportMail } from "../../../../store/reducers/userMailsReducer";
import { Typography } from "@mui/material";

export default function SubjectCell (props: IFMTableComponentProps<IUserSupportMail, {}>) {
	const { toggleCollapsible, subject } = props;
	return <Typography sx={subjectSx} onClick={toggleCollapsible}>{subject}</Typography>;
}

const subjectSx = {
	color: "#2988CA",
	textDecoration: "underline",
	cursor: "pointer"
};
