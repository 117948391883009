/*
    Created on : 10.08.2021, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import FooterAuth from "../../components/FooterAuth/FooterAuth";
import DashboardAuthAppBar from "./DashboardAuthAppBar";
import DashboardAuthContent from "./DashboardAuthContent";
import { IChildren } from "../../types/common";
import DashboardPage from "./DashboardPage";

export default function DashboardAuth ({ children }: { children: IChildren }) {
	return (
		<>
			<DashboardAuthAppBar/>
			<DashboardAuthContent>
				<DashboardPage>
					{children}
				</DashboardPage>
			</DashboardAuthContent>
			<FooterAuth/>
		</>
	);
};
