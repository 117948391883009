import * as React from "react";
import { Box, Portal } from "@mui/material";
import { FormNavigation, Path } from "../../../../components/FormNavigation";
import { Redirect, Route } from "react-router-dom";
import { BankTransfer } from "./BankTransfer";
import { Card } from "./Card";
import { Barter } from "./Barter";
import { Paga } from "./Paga";
import { PayAttitude } from "./PayAttitude";
import { USSD } from "./USSD";

const pathsConfig: Path[] = [
	{
		title: "Bank Transfer",
		to: "/app/deposit/ngn-local-bank-transfer/bank-transfer",
		styles: {
			width: "50%",
			paddingRight: "8px",
			paddingBottom: "16px"
		}
	},
	{
		title: "Card (e-Wallet)",
		to: "/app/deposit/ngn-local-bank-transfer/card",
		styles: {
			width: "50%",
			paddingLeft: "8px",
			paddingBottom: "16px"
		}
	},
	{
		title: "Barter",
		to: "/app/deposit/ngn-local-bank-transfer/barter",
		styles: {
			width: "50%",
			paddingRight: "8px",
			paddingBottom: "16px"
		}
	},
	{
		title: "Paga",
		to: "/app/deposit/ngn-local-bank-transfer/paga",
		styles: {
			width: "50%",
			paddingLeft: "8px",
			paddingBottom: "16px"
		}
	},
	{
		title: "PayAttitude",
		to: "/app/deposit/ngn-local-bank-transfer/pay-attitude",
		styles: {
			width: "50%",
			paddingRight: "8px"
		}
	},
	{
		title: "USSD",
		to: "/app/deposit/ngn-local-bank-transfer/ussd",
		styles: {
			width: "50%",
			paddingLeft: "8px"
		}
	}
];

export interface OnlineNairaProps {
	tabsMenuContainer: React.MutableRefObject<HTMLElement | null>
}

export function OnlineNaira (props: OnlineNairaProps) {
	const { tabsMenuContainer } = props;

	return (
		<>
			<Portal container={tabsMenuContainer.current}>
				<Box sx={{ paddingTop: "20px" }}>
					<FormNavigation
						wrap="wrap"
						paths={pathsConfig}
					/>
				</Box>
			</Portal>
			<Route exact path="/app/deposit/ngn-local-bank-transfer">
				<Redirect to="/app/deposit/ngn-local-bank-transfer/bank-transfer" />
			</Route>
			<Route path="/app/deposit/ngn-local-bank-transfer/bank-transfer">
				<BankTransfer />
			</Route>
			<Route path="/app/deposit/ngn-local-bank-transfer/card">
				<Card />
			</Route>
			<Route path="/app/deposit/ngn-local-bank-transfer/barter">
				<Barter />
			</Route>
			<Route path="/app/deposit/ngn-local-bank-transfer/paga">
				<Paga />
			</Route>
			<Route path="/app/deposit/ngn-local-bank-transfer/pay-attitude">
				<PayAttitude />
			</Route>
			<Route path="/app/deposit/ngn-local-bank-transfer/ussd">
				<USSD />
			</Route>
		</>
	);
}
