import * as React from "react";
import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import { useIsRtl } from "../../../utils/hooks/useIsRtl";
import { useAppSelector } from "../../../utils/hooks/reduxHooks";
import { BonusAgreeLink } from "../../UI/BonusAgreeLink";
import { FormContext } from "../Form";
import { Controller } from "react-hook-form";
import { FieldWrapper as FieldWrapperUI } from "../../UI/FieldWrapper";

export const ADDITIONAL_BONUS = {
	value: 0.1,
	code: "BONUS/ADDITIONAL"
};

export const AGREE_CHECKBOX = {
	value: 0,
	code: "BONUS/AGREEMENT"
};

export interface BonusSelectorUIProps {
	value: {
		[code: string]: number,
	};
	onChange: (value: { [code: string]: number }) => void;
}

export interface BonusSelectorProps {
	name: string;
	label: string;
	tips?: string;
}

export function BonusSelector (props: BonusSelectorProps): JSX.Element {
	const { name, label, tips } = props;
	const { control, fieldWrapperVariant } = React.useContext(FormContext);
	const FieldWrapper = React.useMemo(() => FieldWrapperUI[fieldWrapperVariant], [ fieldWrapperVariant ]);
	const isRtl = useIsRtl();

	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { value, onChange }, fieldState: { error } }) => (
				<FieldWrapper	label={label} tips={tips}>
					<BonusSelectorUI
						value={value}
						onChange={onChange}
					/>
					<Typography
						color="#ff0000"
						fontSize={12}
						sx={{
							position: "absolute",
							top: "100%",
							width: "100%",
							textAlign: isRtl ? "right" : "left"
						}}
					>
						{error?.message}
					</Typography>
				</FieldWrapper>
			)}
		/>
	);
}

export function BonusSelectorUI (props: BonusSelectorUIProps): JSX.Element {
	const { value, onChange } = props;
	const [ selectedValues, setSelectedValues ] = React.useState(value);
	const { bonus, bonus10 } = useAppSelector((state) => state.user.user);
	const isRtl = useIsRtl();

	React.useEffect(() => {
		onChange(selectedValues);
	}, [ selectedValues ]);

	const handleCheck = React.useCallback(({ value, code }: { value: number, code: string }) => {
		if (selectedValues.hasOwnProperty(code)) {
			const prevValues = { ...selectedValues };

			delete prevValues[code];
			if (code === AGREE_CHECKBOX.code) delete prevValues[ADDITIONAL_BONUS.code];

			setSelectedValues(bonus?.bonusCode === code ? {} : prevValues);
		} else {
			setSelectedValues({
				...selectedValues,
				[code]: value
			});
		}
	}, [ selectedValues ]);

	if (!bonus) return <></>;

	return (
		<Grid
			container
			flexDirection="column"
		>
			<Grid
				item
				sx={{
					display: "flex"
				}}
				flexDirection={ isRtl ? "row-reverse" : "row" }
			>
				<FormControlLabel
					label={`${bonus.bonus * 100}%`}
					labelPlacement={isRtl ? "start" : "end" }
					className="light"
					control={
						<Checkbox
							checked={selectedValues.hasOwnProperty(bonus.bonusCode)}
							onChange={() => handleCheck({ code: bonus.bonusCode, value: bonus.bonus })}
						/>
					}
				/>
				{
					bonus10 && selectedValues.hasOwnProperty(bonus.bonusCode) && selectedValues.hasOwnProperty(AGREE_CHECKBOX.code)
						? (
							<FormControlLabel
								label="10%"
								className="light"
								labelPlacement={isRtl ? "start" : "end"}
								control={
									<Checkbox
										checked={selectedValues.hasOwnProperty(ADDITIONAL_BONUS.code)}
										onChange={() => handleCheck(ADDITIONAL_BONUS)}
									/>
								}
							/>
						)
						: (<></>)
				}
			</Grid>
			{
				selectedValues.hasOwnProperty(bonus.bonusCode)
					? (
						<Grid
							item
							sx={{ display: "flex" }}
							alignItems={isRtl ? "end" : "start"}
							justifyContent={isRtl ? "end" : "start"}
						>
							<FormControlLabel
								label={<BonusAgreeLink bonus={bonus.bonusCode}/>}
								className="light"
								labelPlacement={isRtl ? "start" : "end"}
								control={
									<Checkbox
										checked={selectedValues.hasOwnProperty(AGREE_CHECKBOX.code)}
										onChange={() => handleCheck(AGREE_CHECKBOX)}
									/>
								}
							/>
						</Grid>
					)
					: (<></>)
			}
		</Grid>
	);
}
