/*
    Created on : 04.11.2021, 06:18:59
    Author     : Andy Kar
*/
import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./Search.scss";
import { Input, Button } from "reactstrap";
import { StoreInterface } from "../../../../interfaces/StoreInterfaces";
import classNames from "classnames";
import { IInitLanguage } from "../../../../interfaces/LanguageInterface";

export default function Search (props: any) {
	const lang:IInitLanguage = useSelector((state:StoreInterface) => state.language.language);
	const init:any = useSelector((state:StoreInterface) => state.init.init);

	const [ searchText, setSearchText ] = useState("");

	const onKeyPressHandler = (e:any) => {
		if (e.charCode === 13) {
			searchOnSite();
		}
	};

	const searchOnSite = () => {
		if (searchText.trim()) {
			const href = init.links.link_search + "?q=" + searchText.trim();
			window.location.href = href;
		}
	};

	return (
		<div className="header-search flexRow">
			<Input
				id="search_text"
				placeholder={lang.search}
				value={searchText}
				onChange={e => setSearchText(e.target.value)}
				type="text"
				onKeyPress={e => onKeyPressHandler(e)}
				className={classNames("flexRow", { close: !props.open })}
			/>
			<Button
				id="search_btn"
				className="header-search-btn btn"
				color="none"
				type="button"
				onClick={props.open ? searchOnSite : props.setOpen}
			>
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
					<path id="Icon_awesome-search" data-name="Icon awesome-search" d="M15.782,13.833l-3.116-3.115a.75.75,0,0,0-.531-.219h-.509A6.5,6.5,0,1,0,10.5,11.624v.509a.749.749,0,0,0,.219.531l3.116,3.115a.747.747,0,0,0,1.059,0l.884-.884a.753.753,0,0,0,0-1.062ZM6.5,10.5a4,4,0,1,1,4-4A4,4,0,0,1,6.5,10.5Z" fill="#fff"/>
				</svg>
			</Button>
		</div>
	);
}
