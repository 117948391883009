import * as React from "react";
import { Form } from "../../../../components/Form";
import { Input } from "../../../../components/Form/Input";
import { InputRepeater } from "../../../../components/Form/InputRepeater";
import { CurrenciesSymbols, CurrencyType } from "../../../../utils/currency";
import { getWithdrawalValue } from "../../../../utils/getReceivedValue";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { localBankTransferMexicoWithdrawValidationSchema } from "./validation";
import { FormBottomWrapper } from "../../../../components/Form/FormBottomWrapper";
import { ButtonWithLoader } from "../../../../components/ButtonWithLoader";
import { Alert, Box, Skeleton, Snackbar } from "@mui/material";
import API from "../../../../utils/API";
import { useErrorHandling } from "../../../../utils/hooks/useErrorHandling";
import { AxiosResponse } from "axios";
import { fetchLocalBankTransferMexicoInfo } from "../../../../store/actions/actionTypes";
import { WaningNote } from "../../../../components/UI/Note";

export function LocalBankTransferMexico () {
	const [ success, setSuccess ] = React.useState(false);
	const [ isFetch, setIsFetch ] = React.useState(false);
	const language = useAppSelector((state) => state.language);
	const accountNumber = useAppSelector((state) => `${state.session.userData.account_number ?? ""}`);
	const currency = useAppSelector((state) => state.session.userData.currency ?? "USD");

	const { isOpen, showError, hideError, errorMsg } = useErrorHandling();
	const {
		fee,
		addon,
		feeDetails,
		loading: infoLoading,
		complete
	} = useAppSelector((state) => state.withdrawMethodsInfo.localBankTransferMexico);
	const dispatch = useAppDispatch();

	React.useEffect(() => {
		if (!complete) {
			dispatch(fetchLocalBankTransferMexicoInfo({}));
		}
	}, []);

	const getWithdrawalValueCB = React.useCallback((values: any) => getWithdrawalValue(values, currency), [ currency ]);

	const calcFee = React.useCallback((values: [string, CurrencyType]) => {
		const [ amount, currencyType ] = values;
		return `${(+amount * fee + addon).toFixed(2)}${CurrenciesSymbols[currencyType] || CurrenciesSymbols[currency]}`;
	}, []);

	async function submit (values: {
		accountNumber: string,
		bankAccountNumber: string,
		amount: string,
		bankAccountName: string,
		bankName: string,
		branch: string,
		province: string,
		city: string
	}) {
		setIsFetch(true);
		try {
			const formData = new FormData();
			formData.append("amount_withdraw", values.amount);
			formData.append("bank_accountnumber", values.bankAccountNumber);
			formData.append("bank_account_name", values.bankAccountName);
			formData.append("bank_name", values.bankName);
			formData.append("bank_branch", values.branch);
			formData.append("bank_province", values.province);
			formData.append("bank_city", values.city);

			const response = await API.post<unknown, AxiosResponse<{ success: boolean, errorMsg: string}>>("withdrawLocalBankMxn", formData);

			if (!response.data.success) {
				showError(response.data.errorMsg ?? "Something went wrong!");
			} else {
				setSuccess(true);
			}
		} catch (e) {
			// handle error
		} finally {
			setIsFetch(false);
		}
	}

	return (
		<>
			<Form
				onSubmit={submit}
				validationSchema={localBankTransferMexicoWithdrawValidationSchema(language.languageFinance)}
				defaultValues={{
					accountNumber,
					bankAccountNumber: "",
					amount: "",
					bankAccountName: "",
					bankName: "",
					branch: "",
					province: "",
					city: ""
				}}
			>
				<Input
					name="accountNumber"
					label={language.languageCopytrade.cpy_mon_accnum}
					variant="rounded"
				/>
				<Input
					name="amount"
					label={language.languageFinance.trd_230}
				/>
				<InputRepeater
					fields="amount,currency"
					label={language.languageFinance["with-p-04"]}
					onChange={calcFee}
				/>
				<Input
					name="bankAccountNumber"
					label={language.languageFinance.with_01}
				/>
				<Input
					name="bankAccountName"
					label={language.languageFinance.with_04}
					variant="rounded"
				/>
				<Input
					name="bankName"
					label={language.languageFinance.with_03}
					variant="rounded"
				/>
				<Input
					name="branch"
					label={language.languageFinance.with_02}
					variant="rounded"
				/>
				<Input
					name="province"
					label={language.languageFinance.with_06}
					variant="rounded"
				/>
				<Input
					name="city"
					label={language.languageFinance.with_05}
					variant="rounded"
				/>
				<FormBottomWrapper
					forFields={[ "amount", "currency" ]}
					onChange={getWithdrawalValueCB}
					label={language.languageMyAccounts.form_message_01}
				>
					<ButtonWithLoader
						isFetch={isFetch}
						color={"primary"}
						text={language.languageMyAccounts.chapas_04}
					/>
				</FormBottomWrapper>
				<Box sx={{ marginTop: "6px" }}>
					{
						infoLoading
							? (
								<Skeleton width={"220px"} height={"26px"} />
							)
							: feeDetails
								? (
									<WaningNote
										message={`Fee is ${feeDetails}`}
									/>
								)
								: <></>
					}
				</Box>
			</Form>
			<Snackbar
				open={success}
				autoHideDuration={4000}
				onClose={() => setSuccess(false)}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
			>
				<Alert variant="filled" color="success">
					Success
				</Alert>
			</Snackbar>
			<Snackbar open={isOpen} autoHideDuration={4000} onClose={hideError}>
				<Alert variant="filled" color="error">
					{errorMsg}
				</Alert>
			</Snackbar>
		</>
	);
}
