/*
    Created on : 11.11.2022, 06:18:59
    Author     : Andy Kar
*/

import { takeEvery, put, call } from "redux-saga/effects";
import {
	completeCloseTradesHistory,
	completeOpenTradesHistory,
	// FETCH_MONITORING_TRADERS,
	FETCH_PART_TRADERS, FETCH_SUBSCRIPTIONS_TRADERS,
	GET_CLOSE_TRADES_HISTORY,
	GET_COMMISSION_SETTINGS,
	GET_MY_PROJECT,
	GET_MY_SUBSCRIPTIONS_CONDITIONS,
	GET_MY_SUBSCRIPTION_FOLLOWERS,
	GET_MY_SUBSCRIPTION_PAST_SUBSCRIPTIONS,
	GET_MY_SUBSCRIPTION_TRADERS,
	GET_OPEN_TRADES_HISTORY,
	GET_PROFILE_INFORMATION,
	setCloseTradesPagination,
	setCommissionSettings,
	setFollowersData,
	setFollowersPagination,
	setMySubscriptionsConditions,
	setOpenTradesPagination,
	setPastSubscriptionsData,
	setPastSubscriptionsFetch,
	setPastSubscriptionsPagination,
	setTraderPagination,
	setTradersData,
	setTradersFetch,
	setYourFollowersFetch,
	SET_LENGTH_MONITORING_TRADERS,
	SET_LENGTH_SUBSCRIPTIONS_TRADERS,
	SET_LENGTH_TRADERS,
	SET_MY_PROJECT,
	SET_PART_TRADERS, SET_PROFILE_INFORMATION, SET_SUBSCRIPTIONS_LOADING, SET_SUBSCRIPTIONS_TRADERS
} from "../store/actions/actionTypes";
import { SubscriptionTradersInterface, TradersInterface } from "../interfaces/StoreInterfaces";
import { ResponseGenerator } from "../interfaces/mainInterfaces";
import API from "../utils/API";
import { MyProjectData, Profile } from "../interfaces/myAccountsInterfaces";
import { CloseTradesHistoryRow, CommissionSettings, Conditions, OpenTradesHistoryRow, PastSubscription, TPaginationData, Trader, YourFollower } from "../interfaces/CopytradeInterfaces";
import { ActionType } from "../utils/reudxHelpers";
import { PaginatedResponseData } from "../utils/types";

/**
 Get part of all traders data from server and set it to store
 **/
export function * tradersWatcher () {
	yield takeEvery(FETCH_PART_TRADERS, tradersWorker);
}
export function * tradersWorker (action:{type:string, mData: any}) {
	const data:TradersInterface = yield call(getMonitoringInfo, action.mData);
	if (Object.keys(data).length) {
		yield put({ type: SET_PART_TRADERS, payload: data.traders });
		yield put({ type: SET_LENGTH_TRADERS, payload: data.traders.length });
		yield put({ type: SET_LENGTH_MONITORING_TRADERS, payload: data.counts });
	}
}

async function getMonitoringInfo (mData:{start?: number, length?: number, sort?: string, search?: string}) {
	const postData = new FormData();
	postData.append("start", mData.start ? mData.start.toString() : "0");
	postData.append("length", mData.length ? mData.length.toString() : "4");
	postData.append("sort", mData.sort ? mData.sort.toString() : "2");
	if (mData.search) { postData.append("search", mData.search); }

	const response:ResponseGenerator = await API.post("copytradeMonitoring", postData);
	return response?.data || {};
}

/**
 Get Subscriptions traders data from server and set it to store
 **/
export function * subscriptionsWatcher () {
	yield takeEvery(FETCH_SUBSCRIPTIONS_TRADERS, subscriptionWorker);
}
export function * subscriptionWorker (action:{type:string, sData: any}) {
	yield put({ type: SET_SUBSCRIPTIONS_LOADING, payload: true });
	const data:SubscriptionTradersInterface = yield call(getSubscriptionInfo, action.sData);
	yield put({ type: SET_SUBSCRIPTIONS_LOADING, payload: false });
	if (Object.keys(data).length) {
		yield put({ type: SET_SUBSCRIPTIONS_TRADERS, payload: data.traderList });
		yield put({ type: SET_LENGTH_SUBSCRIPTIONS_TRADERS, payload: data.traderCount });
	}
}
async function getSubscriptionInfo (sData:{start?: number, length?: number}) {
	const postData = new FormData();
	postData.append("start", sData.start ? sData.start.toString() : "0");
	postData.append("length", sData.length ? sData.length.toString() : "4");
	const response:ResponseGenerator = await API.post("copytradeSubscriptions", postData);
	return response?.data || {};
}

/**
 * Get profile information and set it in store
 */
export function * getProfileInformationWatcher () {
	yield takeEvery(GET_PROFILE_INFORMATION, getProfileInformationWorker);
}

export function * getProfileInformationWorker () {
	try {
		const response: { data: Profile } = yield call(getProfileInformation);

		yield put({ type: SET_PROFILE_INFORMATION, payload: response });
	} catch (e) {
		console.error(e);
	}
}

async function getProfileInformation (): Promise<Profile> {
	const { data } = await API.get<{ data: Profile }>("copytradeGetProfile");

	return data.data;
}

/**
 * Get my projects information and set it in store
 */
export function * getMyProjectWatcher () {
	yield takeEvery(GET_MY_PROJECT, getMyProjectWorker);
}

export function * getMyProjectWorker () {
	try {
		const response: MyProjectData = yield call(getMyProject);

		yield put({ type: SET_MY_PROJECT, payload: response });
	} catch (e) {
		console.error(e);
	}
}

async function getMyProject () {
	const { data } = await API.get<{ data: MyProjectData }>("copytradeGetMyProject");

	return data.data;
}

/**
 * Get your followers and set it in store
 */
export function * getYourFollowersWatcher () {
	yield takeEvery(GET_MY_SUBSCRIPTION_FOLLOWERS, getYourFollowersWorker);
}

export function * getYourFollowersWorker (action: ActionType<string, TPaginationData>) {
	yield put(setYourFollowersFetch(true));
	try {
		const { page, limit } = action.payload;
		const data: PaginatedResponseData<YourFollower>["data"] = yield call(getYourFollowers, page, limit);

		yield put(setFollowersPagination({ ...action.payload, page: 1, count: data.count || 0 }));
		yield put(setFollowersData(data.list || []));
	} catch (e) {
		//
	} finally {
		yield put(setYourFollowersFetch(false));
	}
}

async function getYourFollowers (page = 1, limit = 5): Promise<PaginatedResponseData<YourFollower>["data"]> {
	const { data } = await API.get<PaginatedResponseData<YourFollower>>("copytradeGetFollowers", {
		params: {
			page,
			limit
		}
	});

	return data.data;
}

/**
 * Get traders and set it in store
 */
export function * getTradersWatcher () {
	yield takeEvery(GET_MY_SUBSCRIPTION_TRADERS, getTradersWorker);
}

export function * getTradersWorker (action: ActionType<string, TPaginationData>) {
	yield put(setTradersFetch(true));
	try {
		const { page, limit } = action.payload;
		const data: PaginatedResponseData<Trader>["data"] = yield call(getTraders, page, limit);
		yield put(setTraderPagination({ limit: action.payload.limit, page: 1, count: data.count || 0 }));
		yield put(setTradersData(data.list || []));
	} catch (e) {
		//
	} finally {
		yield put(setTradersFetch(false));
	}
}

async function getTraders (page = 1, limit = 5): Promise<PaginatedResponseData<Trader>["data"]> {
	const { data } = await API.get<PaginatedResponseData<Trader>>("copytradeGetTradersList", {
		params: {
			page,
			limit
		}
	});

	return data.data;
}

/**
 * Get past subscriptions and set it in store
 */
export function * getPastSubscriptionsWatcher () {
	yield takeEvery(GET_MY_SUBSCRIPTION_PAST_SUBSCRIPTIONS, getPastSubscriptionsWorker);
}

export function * getPastSubscriptionsWorker (action: ActionType<string, TPaginationData>) {
	yield put(setPastSubscriptionsFetch(true));
	try {
		const { page, limit } = action.payload;
		const data: PaginatedResponseData<PastSubscription>["data"] = yield call(getPastSubscriptions, page, limit);

		yield put(setPastSubscriptionsPagination({ ...action.payload, page: 1, count: data.count || 0 }));
		yield put(setPastSubscriptionsData(data.list || []));
	} catch (e) {
		//
	} finally {
		yield put(setPastSubscriptionsFetch(false));
	}
}

async function getPastSubscriptions (page = 1, limit = 5): Promise<PaginatedResponseData<PastSubscription>["data"]> {
	const { data } = await API.get<PaginatedResponseData<PastSubscription>>("copytradeGetPastSubscriptions", {
		params: {
			page,
			limit
		}
	});

	return data.data;
}

/**
 * Get conditions of subscribed trader
 */

export function * getConditionWatcher () {
	yield takeEvery(GET_MY_SUBSCRIPTIONS_CONDITIONS, getConditionWorker);
}

export function * getConditionWorker (action: ActionType<string, { connectionId: number }>) {
	try {
		const { connectionId } = action.payload;
		const data: Conditions = yield call(getCondition, connectionId);

		yield put(setMySubscriptionsConditions({ connectionId, data }));
	} catch (e) {
		//
	}
}

async function getCondition (connectionId: number): Promise<Conditions> {
	const formData = new FormData();
	formData.append("connectionId", `${connectionId}`);

	const { data } = await API.post<{ data: Conditions }>("copytradeGetConditions", formData);

	return data.data;
}

/**
 * Get open trades history of subscribed trader
 */

export function * getOpenTradesHistoryWatcher () {
	yield takeEvery(GET_OPEN_TRADES_HISTORY, getOpenTradesHistoryWorker);
}

export function * getOpenTradesHistoryWorker (action: ActionType<string, { connectionId: number, start: number, limit: number }>) {
	try {
		const { connectionId, start, limit } = action.payload;
		const data: { data: OpenTradesHistoryRow[], count: number } = yield call(getOpenTradesHistory, connectionId, start, limit);

		yield put(completeOpenTradesHistory({ connectionId, data: data.data }));
		yield put(setOpenTradesPagination({ connectionId, data: { page: 1, limit, count: data.count } }));
	} catch (e) {
		console.error(e);
	}
}

async function getOpenTradesHistory (connectionId: number, start = 1, limit = 10): Promise<{ data: OpenTradesHistoryRow[], count: number }> {
	const { data } = await API.get<{ data: OpenTradesHistoryRow[], count: number }>("copytradeGetOpenTradesByConnection", {
		params: {
			connectionId,
			start,
			limit
		}
	});

	return data;
}

/**
 * Get close trades history of subscribed trader
 */

export function * getCloseTradesHistoryWatcher () {
	yield takeEvery(GET_CLOSE_TRADES_HISTORY, getCloseTradesHistoryWorker);
}

export function * getCloseTradesHistoryWorker (action: ActionType<string, { connectionId: number, start: number, limit: number }>) {
	try {
		const { connectionId, start, limit } = action.payload;
		const data: { data: CloseTradesHistoryRow[], count: number } = yield call(getCloseTradesHistory, connectionId, start, limit);

		yield put(completeCloseTradesHistory({ connectionId, data: data.data }));
		yield put(setCloseTradesPagination({ connectionId, data: { page: 1, limit, count: data.count } }));
	} catch (e) {
		//
	}
}

async function getCloseTradesHistory (connectionId: number, start = 1, limit = 10): Promise<{ data: CloseTradesHistoryRow[], count: number }> {
	const { data } = await API.get<{ data: CloseTradesHistoryRow[], count: number }>("copytradeGetCloseTradesByConnection", {
		params: {
			connectionId,
			start,
			limit
		}
	});

	return data;
}

/**
 * Get commission settings for traders you subscribed to
 */

export function * getCommissionSettingsWatcher () {
	yield takeEvery(GET_COMMISSION_SETTINGS, getCommissionSettingsWorker);
}

export function * getCommissionSettingsWorker (action: ActionType<string, { connectionId: number }>) {
	try {
		const { connectionId } = action.payload;
		const data: { data: CommissionSettings, success: boolean } = yield call(getCommissionSettings, connectionId);

		if (data.success) {
			yield put(setCommissionSettings({ connectionId, commissionSettings: data.data }));
		}
	} catch (e) {
		//
	}
}

async function getCommissionSettings (connectionId: number): Promise<{ data: CommissionSettings, success: boolean }> {
	const { data } = await API.get<{ data: CommissionSettings, success: boolean }>("copytradeGetCommissionSettings", {
		params: { connectionId }
	});

	return data;
}
