import React from "react";

const FacebookIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
			<path d="M13,0A13,13,0,1,0,26,13,13,13,0,0,0,13,0Zm2.834,13H14.015v6.6H11.287V13H9.925V10.725h1.363V9.359c0-1.856.769-2.959,2.955-2.959h1.82V8.675H14.925c-.851,0-.908.319-.908.911l0,1.139h2.061L15.834,13Z" fill="#fff"/>
		</svg>
	);
};

export default FacebookIcon;
