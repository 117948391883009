/*
    Created on : 22.11.2023, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { withRouter } from "react-router-dom";
import PageMyAchievements from "../../pages/MyAchievements/PageMyAchievements/PageMyAchievements";

const LayoutMyAchievements = () => {
	return (
		<div className="layout layout-smart-dollar">
			<PageMyAchievements/>
		</div>
	);
};

export default withRouter(LayoutMyAchievements);
