import * as React from "react";
import { Form } from "../../../../components/Form";
import { Input } from "../../../../components/Form/Input";
import { InputRepeater } from "../../../../components/Form/InputRepeater";
import { CurrenciesSymbols, CurrencyType } from "../../../../utils/currency";
import { getWithdrawalValue } from "../../../../utils/getReceivedValue";
import { useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { ALIPAY_PAY_FEE } from "../constants";
import { alipayWithdrawValidationSchema } from "./validation";
import { FormBottomWrapper } from "../../../../components/Form/FormBottomWrapper";
import { ButtonWithLoader } from "../../../../components/ButtonWithLoader";
import { Alert, Box, Snackbar } from "@mui/material";
import { WaningNote } from "../../../../components/UI/Note";
import API from "../../../../utils/API";
import { AxiosResponse } from "axios";

export function Alipay () {
	const [ isFetch, setIsFetch ] = React.useState(false);
	const language = useAppSelector((state) => state.language);
	const accountNumber = useAppSelector((state) => `${state.session.userData.account_number ?? ""}`);
	const currency = useAppSelector((state) => state.session.userData.currency ?? "USD");
	const [ errorMsg, setErrorMsg ] = React.useState("");
	const [ isOpen, setIsOpen ] = React.useState<boolean>(false);

	const getWithdrawalValueCB = React.useCallback((values: any) => getWithdrawalValue(values, currency), [ currency ]);

	const calcFee = React.useCallback((values: [string, CurrencyType]) => {
		const [ amount, currencyType ] = values;
		return `${(+amount * ALIPAY_PAY_FEE).toFixed(2)}${CurrenciesSymbols[currencyType] || CurrenciesSymbols[currency]}`;
	}, []);

	const hideErrorMsg = React.useCallback(() => {
		setIsOpen(false);
		setErrorMsg("");
	}, [ setErrorMsg ]);

	const submit = React.useCallback(async (values: any) => {
		setIsFetch(true);
		try {
			const formData = new FormData();
			formData.append("amount_withdraw", values.amount);
			formData.append("aliPay_account", values.accountNumber);
			formData.append("bank_account_name", values.bankAccountName);
			formData.append("bank_name", values.bankName);
			formData.append("bank_branch", values.branch);
			formData.append("bank_province", values.province);
			formData.append("bank_city", values.bank_city);
			const { data } = await API.post<unknown, AxiosResponse<{ success: boolean, errorMsg: string }>>("withdrawAlipay", formData);
			if (!data.success) {
				setErrorMsg(data.errorMsg);
				setIsOpen(true);
			}
		} catch (e) {
			// handle error
		} finally {
			setIsFetch(false);
		}
	}, []);

	return (
		<Form
			onSubmit={submit}
			validationSchema={alipayWithdrawValidationSchema(language.languageFinance)}
			defaultValues={{
				accountNumber,
				amount: "",
				bankAccountName: "",
				bankName: "",
				bankAccountNumber: "",
				branch: "",
				province: "",
				city: ""
			}}
		>
			<Input
				name="accountNumber"
				label={language.languageCopytrade.cpy_mon_accnum}
				variant="rounded"
			/>
			<Input
				name="amount"
				label={language.languageFinance.trd_230}
				variant="rounded"
			/>
			<InputRepeater
				fields="amount,currency"
				label={language.languageFinance["with-p-04"]}
				onChange={calcFee}
			/>
			<Input
				name="bankAccountName"
				label={language.languageFinance.with_04}
				variant="rounded"
			/>
			<Input
				name="bankName"
				label={language.languageFinance.with_03}
				variant="rounded"
			/>
			<Input
				name="bankAccountNumber"
				label={language.languageFinance.wunip_06}
				variant="rounded"
			/>
			<Input
				name="branch"
				label={language.languageFinance.with_02}
				variant="rounded"
			/>
			<Input
				name="province"
				label={language.languageFinance.with_06}
				variant="rounded"
			/>
			<Input
				name="city"
				label={language.languageFinance.with_05}
				variant="rounded"
			/>
			<FormBottomWrapper
				forFields={[ "amount", "currency" ]}
				onChange={getWithdrawalValueCB}
				label={language.languageMyAccounts.form_message_01}
			>
				<ButtonWithLoader
					isFetch={isFetch}
					color={"primary"}
					text={language.languageMyAccounts.chapas_04}
				/>
			</FormBottomWrapper>
			<Box sx={{ marginTop: "6px" }}>
				<WaningNote
					message={language.languageFinance["with-p-01"]}
				/>
			</Box>
			<Snackbar open={isOpen} autoHideDuration={4000} onClose={hideErrorMsg}>
				<Alert variant="filled" color="error">
					{errorMsg}
				</Alert>
			</Snackbar>
		</Form>
	);
}
