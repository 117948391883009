import * as Yup from "yup";

export type CommonErrors = {
	account_number_01: string;
	field_err_01: string;
	deposit_err_06: string;
}

export type LocalBankTransferErrors = CommonErrors & {
	account_number_02: string;
	account_number_err_03: string;
}

export function getLocalBankTransferValidationSchema (language: LocalBankTransferErrors) {
	return Yup.object().shape({
		accountNumber: Yup.string().required(language.account_number_01),
		amount: Yup
			.number()
			.typeError(language.deposit_err_06),
		bankAccountName: Yup.string().required(language.field_err_01),
		bankName: Yup.string().required(language.field_err_01),
		bankAccountNumber: Yup
			.number()
			.required(language.account_number_01)
			.typeError(language.account_number_err_03),
		branch: Yup.string().required(language.field_err_01),
		province: Yup.string().required(language.field_err_01),
		city: Yup.string().required(language.field_err_01)
	});
}

export function getCommonValidationSchema (language: CommonErrors, accountFieldName = "account") {
	return Yup.object().shape({
		accountNumber: Yup.string().required(language.account_number_01),
		amount: Yup
			.number()
			.typeError(language.deposit_err_06),
		[accountFieldName]: Yup.string().required(language.field_err_01)
	});
}
