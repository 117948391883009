import * as MUI from "@mui/material";
import { mainTheme } from "../../../../theme/main.theme";
import { styled } from "@mui/material/styles";

export const Tab = styled(MUI.Tab)(({ theme }) => (theme.unstable_sx({
	textTransform: "none",
	justifyContent: "flex-start",
	color: mainTheme.palette.default.main,
	fontSize: "18px",
	fontWeight: 400,
	"&.Mui-selected": {
		color: mainTheme.palette.success.main
	}

})));
