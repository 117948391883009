import * as React from "react";

export type IFMTableThemeName = "AccountTheme" | "TradingTheme" | "FinanceTheme" | "BonusTheme" | "CopyTradingTheme" | "PartnershipTheme" | "BonusStatisticsTheme" | "ChartBonusStatisticsTheme" | "AccountSummaryTheme";
export type IFMTableTheme = {
	withPeriodSelect?: boolean
	TableHeadFontWeight: string
	TableHeadBgColor?: string
	TableHeadBorderColor?: string
	TableRowBgColor?: string
	TableHeadColor?: string
	TableCellBorderColor?: string
	TableBorderColor: string
	TableCellHeight?: string
};

export type IFMTableThemes = {
	[K in IFMTableThemeName]: IFMTableTheme
};

export const FMTableTheme: IFMTableThemes = {
	AccountTheme: {
		withPeriodSelect: true,
		TableHeadFontWeight: "normal",
		TableHeadColor: "#fff",
		TableHeadBgColor: "#5C7080",
		TableHeadBorderColor: "#475A69",
		TableRowBgColor: "transparent",
		TableBorderColor: "#5C7080",
		TableCellBorderColor: "transparent",
		TableCellHeight: "40px"
	},
	TradingTheme: {
		withPeriodSelect: false,
		TableHeadFontWeight: "normal",
		TableHeadColor: "#fff",
		TableHeadBgColor: "#5C7080",
		TableHeadBorderColor: "#475A69",
		TableRowBgColor: "#F6F8F9",
		TableBorderColor: "#5C7080",
		TableCellBorderColor: "transparent",
		TableCellHeight: "40px"
	},
	FinanceTheme: {
		withPeriodSelect: false,
		TableHeadFontWeight: "normal",
		TableHeadColor: "#fff",
		TableHeadBgColor: "#5C7080",
		TableHeadBorderColor: "#475A69",
		TableRowBgColor: "#F6F8F9",
		TableBorderColor: "#5C7080",
		TableCellBorderColor: "transparent",
		TableCellHeight: "50px"
	},
	BonusTheme: {
		withPeriodSelect: true,
		TableHeadFontWeight: "normal",
		TableHeadColor: "#fff",
		TableHeadBgColor: "#5C7080",
		TableHeadBorderColor: "#475A69",
		TableRowBgColor: "transparent",
		TableBorderColor: "#5C7080",
		TableCellBorderColor: "transparent",
		TableCellHeight: "40px"
	},
	BonusStatisticsTheme: {
		withPeriodSelect: false,
		TableHeadFontWeight: "normal",
		TableHeadColor: "#fff",
		TableHeadBgColor: "#5C7080",
		TableHeadBorderColor: "#5C7080",
		TableRowBgColor: "transparent",
		TableBorderColor: "#5C7080",
		TableCellBorderColor: "#e2e2e2",
		TableCellHeight: "auto"
	},
	ChartBonusStatisticsTheme: {
		withPeriodSelect: false,
		TableHeadFontWeight: "normal",
		TableHeadColor: "#fff",
		TableHeadBgColor: "#5C7080",
		TableHeadBorderColor: "#5C7080",
		TableRowBgColor: "transparent",
		TableBorderColor: "#5C7080",
		TableCellBorderColor: "transparent",
		TableCellHeight: "auto"
	},
	CopyTradingTheme: {
		withPeriodSelect: true,
		TableHeadFontWeight: "normal",
		TableHeadColor: "#fff",
		TableHeadBgColor: "#5C7080",
		TableHeadBorderColor: "#475A69",
		TableRowBgColor: "#F6F8F9",
		TableBorderColor: "#5C7080",
		TableCellBorderColor: "#fff",
		TableCellHeight: "40px"
	},
	PartnershipTheme: {
		withPeriodSelect: true,
		TableHeadFontWeight: "bold",
		TableHeadColor: "#5C7080",
		TableHeadBgColor: "transparent",
		TableHeadBorderColor: "#E2E7E8",
		TableRowBgColor: "#F6F8F9",
		TableBorderColor: "#E2E7E8",
		TableCellBorderColor: "#E2E7E8",
		TableCellHeight: "52px"
	},
	AccountSummaryTheme: {
		withPeriodSelect: false,
		TableHeadFontWeight: "normal",
		TableHeadColor: "#5C7080",
		TableHeadBgColor: "transparent",
		TableHeadBorderColor: "#E2E7E8",
		TableRowBgColor: "transparent",
		TableBorderColor: "#e2e2e2",
		TableCellBorderColor: "#e2e2e2",
		TableCellHeight: "52px"
	}
};

export const FMTableThemeContext = React.createContext<IFMTableTheme>(FMTableTheme.AccountTheme);

export function useFMTableTheme (): IFMTableTheme {
	return React.useContext<IFMTableTheme>(FMTableThemeContext);
};
