/*
    Created on : 12.11.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { IFMTableComponentProps } from "../../../../components/FMTable/FMTableCellComponent";
import CustomButton from "../../../../components/CustomButton";
import { IBanners } from "../../../../store/reducers/bannersReducer";

export default function BannersActionCell (props: IFMTableComponentProps<IBanners, {}>) {
	const { toggleCollapsible } = props;
	return <CustomButton sx={{ margin: "5px 0" }} onClick={toggleCollapsible} text={"Show"} />;
}
