import React from "react";
import { ReactComponent as CheckIcon } from "../images/check.svg";
import Typography from "@mui/material/Typography/Typography";
import { Box } from "@mui/material";
import { IChildren } from "../../../types/common";

const SecurityCodeField = ({ children }: { children: IChildren }) => {
	return (
		<Box alignItems={"center"} className={"flexRow"} gap={"19px"} mb={"15px"}>
			<Box width={"36px"} height={"36px"}>
				<CheckIcon />
			</Box>
			<Typography fontWeight={"lighter"}>{children}</Typography>
		</Box>
	);
};

export default SecurityCodeField;
