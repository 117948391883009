import { ReactComponent as AM } from "./cambank.svg";
import { ReactComponent as CIMB } from "./ccimb.svg";
import { ReactComponent as MAY } from "./cmaybank.svg";
import { ReactComponent as PUBLIC } from "./cpb.svg";
import { ReactComponent as RHB } from "./crhb.svg";

export const MYRIcons = {
	AM,
	CIMB,
	MAY,
	PUBLIC,
	RHB
};
