/*
    Created on : 12.11.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { IFMTableComponentProps } from "../../../../components/FMTable/FMTableCellComponent";
import { IUserSupportMail } from "../../../../store/reducers/userMailsReducer";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../../interfaces/StoreInterfaces";

export default function StatusCell (props: IFMTableComponentProps<IUserSupportMail, {}>) {
	const { status } = props;
	const lang:any = useSelector((state:StoreInterface) => state.language.languageMailSupport);
	const statusText = status === "1" ? lang.ms_15 : lang.ms_16;
	return <Typography sx={subjectSx}>{statusText}</Typography>;
}

const subjectSx = {
	color: "#2988CA"
};
