import * as React from "react";
import Box from "@mui/material/Box";
import DashboardFooter from "./DashboardFooter";
import DashboardPage from "./DashboardPage";
import { IChildren } from "../../types/common";
import { Container } from "@mui/material";

export default function DashboardContent ({ children }: { children: IChildren}) {
	return (
		<Container
			sx={{
				mt: 4,
				mb: 4,
				marginX: 0,
				width: "calc(100% - 30px)"
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					height: "max-content",
					minHeight: "calc(100vh - 128px)"
				}}
			>
				{children && <>
					<DashboardPage>
						{children}
					</DashboardPage>
					<DashboardFooter/>
				</>}
			</Box>
		</Container>
	);
};
