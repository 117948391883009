/*
    Created on : 21.10.2021, 06:18:59
    Author     : Andy Kar
*/

import React, { useEffect } from "react";
import "./App.css";
import MainLayout from "./layouts/Main/Main";
import { BrowserRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SessionUserDataInterface, StoreInterface } from "./interfaces/StoreInterfaces";
import {
	FETCH_INIT_LINK_INFO,
	FETCH_LANGUAGE_INFO, FETCH_LANGUAGE_TRADING,
	FETCH_SESSION_INFO
} from "./store/actions/actionTypes";
import LoadingSpinner from "./components/UI/LoadingSpinner/LoadingSpinner";
import ScrollToTop from "./components/Helper/ScrollToTop/ScrollToTop";
import { clearLocalStorage } from "./utils/LocalStorage";
import { mainTheme } from "./theme/main.theme";
import ThemeProvider from "@mui/material/styles/ThemeProvider";

export default function App () {
	const siteLang: string|undefined = useSelector((state:StoreInterface) => state.session.userData.site_lang);
	const session: SessionUserDataInterface = useSelector((state:StoreInterface) => state.session.userData);
	const sessionLoaded: boolean = useSelector((state:StoreInterface) => state.session.loaded);

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch({ type: FETCH_SESSION_INFO });
		dispatch({ type: FETCH_INIT_LINK_INFO });
		return () => { clearLocalStorage(); };
	}, []);

	useEffect(() => {
		dispatch({ type: FETCH_LANGUAGE_INFO, language: siteLang });
		dispatch({ type: FETCH_LANGUAGE_TRADING, language: siteLang });
	}, [ siteLang, dispatch ]);

	return (
		<BrowserRouter>
			{!Object.keys(session).length || sessionLoaded
				? <div className="session-loaded-spinner-wrap">
					<LoadingSpinner percent={null}/>
				</div>
				: <ScrollToTop>
					<ThemeProvider theme={mainTheme}>
						<MainLayout/>
					</ThemeProvider>
				</ScrollToTop>
			}
		</BrowserRouter>
	);
};
