/*
Created on : 09.05.2022, 06:18:59
Author     : Andy Kar
*/
import * as React from "react";
import { Box, List, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../interfaces/StoreInterfaces";
import { useState } from "react";
import AccountUserIcon from "../../Icons/myAccountsMenuIcons/AccountUserIcon";
import AppBarVerifiedButton from "./AppBarVerifiedButton";
import AppBarMyAccountMenuInner from "./AppBarMyAccountMenuInner";
import { useIsRtl } from "../../../utils/hooks/useIsRtl";

export default function AppBarMyAccountMobile ({ setAccordion }:any) {
	const [ open, setOpen ] = useState(false);
	const userName:string = useSelector((state:StoreInterface) => state.session.userData.full_name) || "User Name";
	const isRtl = useIsRtl();
	const listSX = {
		position: "absolute",
		top: "100%",
		width: "calc(100% + 25px)",
		right: isRtl ? (open ? "calc(100% + 30px)" : 0) : "auto",
		left: isRtl ? "auto" : (open ? 0 : "calc(100% + 30px)"),
		minWidth: "max-content",
		background: "#fff",
		color: "#5C7080",
		zIndex: "9999",
		transition: "all 300ms ease-in-out"
	};

	return (
		<Box sx={boxSX}>
			<Box sx={buttonSX} className={"flexRow"} onClick={ () => setOpen((prev) => !prev)}>
				<Box className={"flexRow"} sx={{ paddingX: "13px", alignItems: "center", padding: 0 }}>
					<AccountUserIcon/>
					<Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
						<Typography component={"span"} sx={userNameSX}>{userName}</Typography>
						<AppBarVerifiedButton />
					</Box>
				</Box>
			</Box>
			<List sx={listSX}>
				<AppBarMyAccountMenuInner
					setOpen={setOpen}
					setAccordion={setAccordion}
				/>
			</List>
		</Box>
	);
};

const buttonSX = {
	maxWidth: "100%",
	width: "max-content",
	color: "#fff",
	height: "100%",
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	paddingX: "5px"
};

const boxSX = {
	position: "relative",
	"& .MuiList-root": {
		padding: 0
	}
};

const userNameSX = {
	fontSize: "14px",
	lineHeight: "24px",
	fontWeight: "bold",
	color: "white"
};
