/*
    Created on : 11.11.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../../interfaces/StoreInterfaces";
import FMTable from "../../../../components/FMTable/FMTable";
import FMTableColumn from "../../../../components/FMTable/FMTableColumn";
import { ITrade } from "../../../../store/reducers/tradesReducer";

export default function TradesTable () {
	const langTrades:any = useSelector((state:StoreInterface) => state.language.languageTrades);
	const trades: Array<ITrade> = useSelector((state:StoreInterface) => state.trades.tradesOpened);

	return (
		<FMTable
			data={trades}
			mobileScheme={{ mode: "auto" }}
			theme="TradingTheme"
		>
			<FMTableColumn id="tradeNumber" label="#" />
			<FMTableColumn id="ticket" label={langTrades.curtra_04} />
			<FMTableColumn id="type" label={langTrades.curtra_05} />
			<FMTableColumn id="volume" label={langTrades.curtra_06} />
			<FMTableColumn id="symbol" label={langTrades.curtra_07} />
			<FMTableColumn id="openPrice" label={langTrades.curtra_08} />
			<FMTableColumn id="openTime" label={langTrades.curtra_16} />
			<FMTableColumn id="sl" label={langTrades.curtra_09} />
			<FMTableColumn id="tp" label={langTrades.curtra_10} />
			<FMTableColumn id="swap" label={langTrades.curtra_swap} />
		</FMTable>
	);
};
