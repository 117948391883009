import React from "react";

export default function PencilEditIcon () {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 16 16">
			<defs>
				<clipPath id="change-detail-clip-path">
					<rect width="16" height="16" transform="translate(546 145)" fill="#fff" stroke="#707070" strokeWidth="1"/>
				</clipPath>
			</defs>
			<g transform="translate(-546 -145)" clipPath="url(#change-detail-clip-path)">
				<g id="edit_black_48dp" transform="translate(546 145)">
					<path d="M0,0H16V16H0Z" fill="none"/>
					<path d="M2,11.5V14H4.5l7.373-7.373-2.5-2.5ZM13.807,4.693a.664.664,0,0,0,0-.94l-1.56-1.56a.664.664,0,0,0-.94,0l-1.22,1.22,2.5,2.5,1.22-1.22Z" fill="#2988ca"/>
				</g>
			</g>
		</svg>
	);
};
