import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import { Button } from "@mui/material";
import { useIsRtl } from "../../../utils/hooks/useIsRtl";

const SidebarContainedButton = (props: any) => {
	const isRtl = useIsRtl();
	return (
		<Link
			to={"/app/" + props.to}
			className={"flexRow"}
			onClick={() => props.setAccordion(props.accordionTo)}
			style={{
				padding: props.open ? !isRtl ? "0px 12px 0px 9px" : "0px 9px 0px 12px" : "0 12px",
				marginBottom: "15px",
				overflow: "hidden"
			}}
		>
			<Button
				variant="contained"
				startIcon={!isRtl ? props.icon ? props.icon : null : null}
				endIcon={isRtl ? props.icon ? props.icon : null : null}
				color={"primary"}
				sx={{
					height: "46px",
					minWidth: 0,
					borderRadius: 0,
					width: "100%",
					justifyContent: isRtl ? "flex-end" : "flex-start",
					padding: props.open ? "0 15px 0 25px" : "0 16px",
					textTransform: "none",
					transition: "padding 200ms ease",
					fontWeight: 400,
					"& span": {
						marginLeft: props.open ? isRtl ? "11px" : 0 : isRtl ? "30px" : 0,
						marginRight: props.open ? !isRtl ? "11px" : 0 : !isRtl ? "30px" : 0,
						transition: "margin-right 50ms ease"
					},
					"&:hover": {
						boxShadow: "none"
					}
				}}
			>
				{props.text}
			</Button>
		</Link>
	);
};

export default withRouter(SidebarContainedButton);
