import * as React from "react";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { SessionUserDataInterface, StoreInterface } from "../../../interfaces/StoreInterfaces";
import languageLink from "../../../utils/languageLink";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import CircleIcon from "@mui/icons-material/Circle";

export default function FooterLinks () {
	const lang:any = useSelector((state:StoreInterface) => state.language.language);
	const init:any = useSelector((state:StoreInterface) => state.init.init);
	const session:SessionUserDataInterface = useSelector((state:StoreInterface) => state.session.userData);
	const toLink = (link:string, lang:boolean = false) => {
		if (link) {
			const linkHref = lang ? languageLink(link, session.site_lang ? session.site_lang : "english") : link;
			if (linkHref) window.open(linkHref);
		}
		return null;
	};
	const boxSX = {
		flexWrap: "wrap",
		alignItems: "center",
		"& .MuiTypography-root": {
			margin: "0 20px 21px",
			color: "#fff",
			textDecoration: "underline"
		},
		"& .MuiSvgIcon-root": {
			fontSize: "7px",
			marginBottom: "21px"
		}
	};

	return (
		<Box>
			<Box
				className={"flexRow"}
				sx={{ ...boxSX }}
			>
				<Typography onClick={() => toLink(init.links.link_about_us, true)}>{lang.botnav_00}</Typography>
				<CircleIcon/>
				{/* <Link to={"/app/"}><Typography>{lang.botnav_01}</Typography></Link> */}
				{/* <CircleIcon/> */}
				<Link to={"/app/profile/upload-document"}><Typography>{lang.botnav_03}</Typography></Link>
				<CircleIcon/>
				<Link to={"/app/my-account"}><Typography>{lang.botnav_05}</Typography></Link>
				<CircleIcon/>
				<Link to={"/app/profile/edit"}><Typography>{lang.botnav_06}</Typography></Link>
				<CircleIcon/>
				<Link to={"/app/deposit/debit"}><Typography>{lang.botnav_07}</Typography></Link>
				<CircleIcon/>
				<Link to={"/app/withdraw"}><Typography>{lang.botnav_08}</Typography></Link>
				<CircleIcon/>
				<Typography onClick={() => toLink(init.links.link_metatrader4)}>{lang.botnav_09}</Typography>
			</Box>
			<Box
				className={"flexRow"}
				sx={{ ...boxSX }}
			>
				{init.isChinaIP || init.country_code_CN || session.site_lang === "chinese"
					? <Typography onClick={() => toLink(init.links.link_terms_and_conditions_ch)}>{lang.botnav_17}</Typography>
					: <Typography onClick={() => toLink(init.links.link_terms_and_conditions)}>{lang.botnav_17}</Typography>
				}
				<CircleIcon/>
				<Typography onClick={() => toLink(init.links.link_risk_disclosure)}>{lang.botnav_16}</Typography>
				<CircleIcon/>
				<Typography onClick={() => toLink(init.links.link_privacy_policy)}>{lang.botnav_15}</Typography>
				<CircleIcon/>
				<Typography onClick={() => toLink(init.links.link_legal_documentation, true)}>{lang.botnav_14}</Typography>
			</Box>
		</Box>
	);
}
