import * as React from "react";
import { Box, SxProps, Typography } from "@mui/material";

export interface IContentWrapperProps {
	children?: JSX.Element | JSX.Element[];
	title: string;
	secondTitle?: string | JSX.Element;
	titleSx?: SxProps;
	contentSx?: SxProps;
	wrapperSx?: SxProps;
}

export function ContentWrapper (props: IContentWrapperProps) {
	const { title, secondTitle, children, titleSx = {}, contentSx = {}, wrapperSx = {} } = props;
	return (
		<Box
			sx={{
				textAlign: "left",
				...wrapperSx
			}}
		>
			<Box
				sx={{
					padding: "20px 23px",
					background: "#5c7080",
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "center",
					...titleSx
				}}
			>
				<Typography color="#ffffff">{title}</Typography>
				{
					secondTitle || (<></>)
				}
			</Box>
			<Box
				sx={{
					border: "1px solid #5c7080",
					...contentSx
				}}
			>
				{children}
			</Box>
		</Box>
	);
}
