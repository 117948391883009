import { SHOW_SETTINGS, SHOW_TRADES_HISTORY } from "../actions/actionTypes";

interface IExpandedRowReducerState {
	traderTable: {
		settings: boolean;
		tradesHistory: boolean;
	}
}

const initialState: IExpandedRowReducerState = {
	traderTable: {
		settings: false,
		tradesHistory: false
	}
};

export function expandedRowReducer (state = initialState, action: { type: string, payload: any }): IExpandedRowReducerState {
	switch (action.type) {
		case SHOW_SETTINGS:
			return {
				...state,
				traderTable: {
					settings: true,
					tradesHistory: false
				}
			};
		case SHOW_TRADES_HISTORY:
			return {
				...state,
				traderTable: {
					settings: false,
					tradesHistory: true
				}
			};
		default:
			return state;
	}
}
