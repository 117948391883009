import * as React from "react";
import { useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { useErrorHandling } from "../../../../utils/hooks/useErrorHandling";
import { getWithdrawalValue } from "../../../../utils/getReceivedValue";
import { Form } from "../../../../components/Form";
import { Input } from "../../../../components/Form/Input";
import { InputRepeater } from "../../../../components/Form/InputRepeater";
import { FormBottomWrapper } from "../../../../components/Form/FormBottomWrapper";
import { ButtonWithLoader } from "../../../../components/ButtonWithLoader";
import { Alert, Box, Snackbar } from "@mui/material";
import { FileSelect } from "../../../../components/Form/FileSelect";
import { Option } from "../../../../components/Form/Option";
import { Select } from "../../../../components/Form/Select";
import { payPortDepositValodationSchema } from "./validation";
import API from "../../../../utils/API";
import { AxiosResponse } from "axios";

const USDRUB = 90.7;

export function PayPort () {
	const [ isFetch, setIsFetch ] = React.useState(false);
	const language = useAppSelector((state) => state.language);
	const accountNumber = useAppSelector((state) => `${state.session.userData.account_number ?? ""}`);
	const currency = useAppSelector((state) => state.session.userData.currency ?? "USD");
	const { isOpen, errorMsg, hideError, showError } = useErrorHandling();

	const getWithdrawalValueCB = React.useCallback((values: any) => getWithdrawalValue(values, currency), [ currency ]);

	async function submit (
		values: { amount: string, banks: string, cardFront: Blob, cardBack: Blob, cardNumber: 0 }
	) {
		setIsFetch(true);
		try {
			const formData = new FormData();
			formData.append("amount", values.amount);
			formData.append("front_side", values.cardFront);
			formData.append("back_side", values.cardBack);
			formData.append("card_number_new", `${values.cardNumber}`);

			const { data } = await API.post<unknown, AxiosResponse<{ redirect: string }>>("depositPayport", formData);

			window.open(data.redirect, "_target");
		} catch (e) {
			if (e instanceof Error) {
				showError(e.message);
			}
		} finally {
			setIsFetch(false);
		}
	}

	function calcInRUB (values: [string]) {
		const [ amount ] = values;
		return amount ? (parseInt(amount) * USDRUB).toFixed(2) + " RUB" : "0 RUB";
	}

	return (
		<Box sx={{ paddingTop: "20px" }}>
			<Form
				onSubmit={submit}
				validationSchema={payPortDepositValodationSchema()}
				defaultValues={{
					accountNumber,
					amount: "",
					banks: "TINKOFF",
					cardFront: {},
					cardBack: {},
					cardNumber: 0

				}}
			>
				<Input
					name="accountNumber"
					label={language.languageCopytrade.cpy_mon_accnum}
					variant="rounded"
				/>
				<Input
					name="amount"
					label={language.languageFinance.s_03}
					variant="rounded"
				/>
				<InputRepeater
					fields="amount"
					label={""}
					onChange={calcInRUB}
				/>
				<Select
					name="banks"
					label={language.languageFinance.d_01}
				>
					<Option value="TINKOFF">TINKOFF BANK</Option>
					<Option value="SBERBANK">SBERBANK</Option>
					<Option value="RAIFFEISEN">RAIFFEISEN</Option>
					<Option value="BANK">ANY RUSSIAN BANK</Option>
				</Select>
				<Input
					name="cardNumber"
					label={language.languageFinance.wdcc_05}
					variant="rounded"
				/>
				<FileSelect
					name="cardFront"
					label="Color photo of front side of the card"
					accept=".jpeg,.png,.jpg"
					emptyLabel="No file chosen"
				/>
				<FileSelect
					name="cardBack"
					label="Color photo of back side of the card"
					accept=".jpeg,.png,.jpg"
					emptyLabel="No file chosen"
				/>
				<FormBottomWrapper
					forFields={[ "amount", "currency" ]}
					onChange={getWithdrawalValueCB}
					label={language.languageMyAccounts.form_message_01}
				>
					<ButtonWithLoader
						isFetch={isFetch}
						color={"primary"}
						text={language.languageMyAccounts.chapas_04}
					/>
				</FormBottomWrapper>
			</Form>
			<Snackbar open={isOpen} autoHideDuration={4000} onClose={hideError}>
				<Alert variant="filled" color="error">
					{errorMsg}
				</Alert>
			</Snackbar>
		</Box>
	);
}
