/*
Created on : 09.05.2022, 06:18:59
Author     : Andy Kar
*/
import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import LangFlagIcon from "../../Icons/flagIcons/LangFlagIcon";
import AppBarAuthDropdownButton from "./AppBarAuthDropdownButton";
import languageName from "../../../utils/languageName";
import { StoreInterface } from "../../../interfaces/StoreInterfaces";
import { CHANGE_LANGUAGE } from "../../../store/actions/actionTypes";
import siteLanguagesList from "../../../utils/siteLanguagesList";

export default function AppBarAuthLang () {
	const lang = useSelector((state:StoreInterface) => state.session.userData.site_lang);
	const dispatch = useDispatch();
	const setLang = (langCode:string):boolean => {
		dispatch({ type: CHANGE_LANGUAGE, language: langCode });
		return true;
	};

	const menuInner = (handleClose:any) => {
		return siteLanguagesList().map((language, index) => {
			return (
				<MenuItem key={index} onClick={() => { setLang(language); handleClose(); }} disableRipple>
					<LangFlagIcon lang={language} />
					<Typography component={"span"} sx={{ padding: "0 9px", textTransform: "capitalize" }}>{languageName(language).name}</Typography>
				</MenuItem>
			);
		});
	};

	const paperProps = {
		width: "700px",
		maxWidth: "100%",
		padding: "30px 30px 10px 30px"
	};

	return (
		<AppBarAuthDropdownButton
			menuId="language"
			menuInner={menuInner}
			paperProps={paperProps}
		>
			<Typography component={"span"} sx={{ padding: "0 5px" }}>{languageName(lang).shortName}</Typography>
			<LangFlagIcon lang={lang} />
		</AppBarAuthDropdownButton>
	);
}
