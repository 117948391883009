import * as React from "react";
import PerfectMoneyLogo from "../../../../images/perfect-money.png";
import { Box, Typography } from "@mui/material";

/*
	TODO: Add translations for text
 */
export function PerfectMoney () {
	return (
		<>
			<Box sx={{ textAlign: "left" }}>
				<a
					href="https://forexmart.premium-deposits.com/"
					target="_blank"
					rel="noreferrer"
				>
					<img src={PerfectMoneyLogo} alt="Perfect Money Logo"/>
				</a>
			</Box>
			<Typography
				variant="subtitle1"
				paddingTop="20px"
				textAlign="left"
				lineHeight="24px"
				color="#5C7080"
			>
				The payments are operated by the Exchanger provider. Extra fees may be applied.
			</Typography>
			<a
				href="https://forexmart.premium-deposits.com/"
				target="_blank"
				rel="noreferrer"
			>
				<Typography
					paddingTop="20px"
					variant="subtitle1"
					textAlign="left"
					color="#2988CA"
					sx={{ textDecoration: "underline" }}
				>
					Premium Deposits
				</Typography>
			</a>
		</>
	);
}
