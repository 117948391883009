import React from "react";
import AccountTypes from "../../../layouts/components/AccountTypes/AccountTypes";
import PageTitle from "../../../components/Dashboard/dashboardComponents/PageTitle";
import { SimpleTabs } from "../../../components/UI/Tabs";
import { useAppSelector } from "../../../utils/hooks/reduxHooks";
import { PendingTransaction } from "./PendingTransaction";
import { BalanceOperation } from "./BalanceOperaion";

export default function PageTransactionHistory () {
	const lang = useAppSelector((state) => state.language.language);
	const langFinance = useAppSelector((state) => state.language.languageFinance);

	return (
		<>
			<PageTitle
				layoutTitle={lang.sb_li_2}
				pageTitle={langFinance.finav_04}
			/>
			<SimpleTabs
				labels={[
					"Pending Transaction",
					"Balance Operation"
				]}
			>
				<PendingTransaction />
				<BalanceOperation />
			</SimpleTabs>
			<AccountTypes/>
		</>
	);
};
