/* eslint-disable camelcase */
import * as React from "react";
import { Form } from "../../../../components/Form";
import { Input } from "../../../../components/Form/Input";
import { getWithdrawalValue } from "../../../../utils/getReceivedValue";
import { useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { vnPayDepositValidationSchema } from "./validation";
import { FormBottomWrapper } from "../../../../components/Form/FormBottomWrapper";
import { ButtonWithLoader } from "../../../../components/ButtonWithLoader";
import { Alert, Box, Snackbar } from "@mui/material";
import { WaningNote } from "../../../../components/UI/Note";
import { useErrorHandling } from "../../../../utils/hooks/useErrorHandling";
import { InputRepeater } from "../../../../components/Form/InputRepeater";
import { Option } from "../../../../components/Form/Option";
import { Select } from "../../../../components/Form/Select";
import API from "../../../../utils/API";
import { AxiosResponse } from "axios";

const USD_VND = 23640;
export function VNPay () {
	const [ isFetch, setIsFetch ] = React.useState(false);
	const language = useAppSelector((state) => state.language);
	const accountNumber = useAppSelector((state) => `${state.session.userData.account_number ?? ""}`);
	const currency = useAppSelector((state) => state.session.userData.currency ?? "USD");
	const phone = useAppSelector((state) => state.user.user.phone1);
	const [ firstName, lastName ] = useAppSelector((state) => state.user.user.full_name?.split(" "));
	const { isOpen, errorMsg, hideError, showError } = useErrorHandling();

	const getWithdrawalValueCB = React.useCallback((values: any) => getWithdrawalValue(values, currency), [ currency ]);

	// TO-DO: Implement API call to deposit with vietnam vn pay
	async function submit (values: {
		amount: string,
		phone: string,
		firstName: string,
		lastName: string,
		payType: string
	}) {
		try {
			setIsFetch(true);
			showError(JSON.stringify(values, null, 3));
			const formData = new FormData();

			formData.append("amount", values.amount);
			formData.append("first_name", values.firstName);
			formData.append("last_name", values.lastName);
			formData.append("phone_number", values.phone);
			formData.append("amount1", values.amount);

			const { data } = await API.post<unknown, AxiosResponse<unknown>>("depositVnPay", formData);
			console.log(data);

			showError("Fix me");
		} catch (e) {
			// handle error
		} finally {
			setIsFetch(false);
		}
	}

	function calcInMYR (values: [string]) {
		const [ amount ] = values;
		return amount ? (parseInt(amount) * USD_VND).toFixed(2) + " VND" : "0 VND";
	}

	return (
		<Form
			onSubmit={submit}
			validationSchema={vnPayDepositValidationSchema()}
			defaultValues={{
				accountNumber,
				amount: "",
				phone,
				firstName,
				lastName,
				payType: ""
			}}
		>
			<Input
				name="accountNumber"
				label={language.languageCopytrade.cpy_mon_accnum}
				variant="rounded"
			/>
			<Input
				name="amount"
				label={language.languageFinance.trd_230}
				variant="rounded"
			/>
			<InputRepeater
				fields="amount"
				label={""}
				onChange={calcInMYR}
			/>
			<Select
				name="payType"
				label={language.languageFinance.d_01}
			>
				<Option value="QRPAY">QRPAY</Option>
				<Option value="BANKTRANSFER">BANKTRANSFER</Option>
			</Select>
			<Input
				name="firstName"
				label={language.languageMyAccounts.fn_01}
				variant="rounded"
			/>
			<Input
				name="lastName"
				label={language.languageMyAccounts.ln_01}
				variant="rounded"
			/>
			<Input
				name="phone"
				label={language.languageFinance.int_reg_45}
				variant="rounded"
			/>
			<FormBottomWrapper
				forFields={[ "amount", "currency" ]}
				onChange={getWithdrawalValueCB}
				label={language.languageMyAccounts.form_message_01}
			>
				<ButtonWithLoader
					isFetch={isFetch}
					color={"primary"}
					text={language.languageMyAccounts.chapas_04}
				/>
			</FormBottomWrapper>
			<Box sx={{ marginTop: "6px" }}>
				<WaningNote
					message="Please fill all fields"
				/>
			</Box>
			<Snackbar open={isOpen} autoHideDuration={4000} onClose={hideError}>
				<Alert variant="filled" color="error">
					{errorMsg}
				</Alert>
			</Snackbar>
		</Form>
	);
}
