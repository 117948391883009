/*
    Created on : 11.11.2022, 06:18:59
    Author     : Andy Kar
*/
import { takeEvery, put, call } from "redux-saga/effects";
import { ResponseGenerator } from "../interfaces/mainInterfaces";
import API from "../utils/API";
import {
	FETCH_BALANCE_OPERATIONS,
	FETCH_PANDING_TRANSACTIONS,
	GET_BONUSES_BY_DATE,
	SET_BALANCE_OPERATIONS,
	SET_PANDING_TRANSACTIONS,
	setBonusLoading,
	setBonusesByDate
} from "../store/actions/actionTypes";
import { IBalanceOperation, IPendingTransaction } from "../store/reducers/financeReducer";
import { ActionType } from "../utils/reudxHelpers";
import { TResponse } from "../utils/types";

/**
 Get pending Transaction data from server and set it to store
 **/

export interface IPendingTransactionsData {
	recordsTotal: number
	recordsFiltered: number
	records: Array<IPendingTransaction>
};

export function * pendingTransactionWatcher () {
	yield takeEvery(FETCH_PANDING_TRANSACTIONS, pendingTransactionWorker);
};

export function * pendingTransactionWorker () {
	const data:IPendingTransactionsData = yield call(getPendingTransactionInfo);
	if (Object.keys(data).length) {
		yield put({ type: SET_PANDING_TRANSACTIONS, payload: data.records });
	}
};

async function getPendingTransactionInfo () {
	const postData = new FormData();
	postData.append("start", "0");
	postData.append("length", "1000");

	const response:ResponseGenerator = await API.post("financePendingTransaction", postData);
	return response?.data || {};
};
/**
 Get Balance Operation data from server and set it to store
 **/

export interface IBalanceOperationsData {
	recordsTotal: number
	recordsFiltered: number
	records: Array<IBalanceOperation>
};

export function * balanceOperationsWatcher () {
	yield takeEvery(FETCH_BALANCE_OPERATIONS, balanceOperationsWorker);
};

export function * balanceOperationsWorker () {
	const data:IBalanceOperationsData = yield call(getBalanceOperationsInfo);
	if (Object.keys(data).length) {
		yield put({ type: SET_BALANCE_OPERATIONS, payload: data.records });
	}
};

async function getBalanceOperationsInfo () {
	const postData = new FormData();
	postData.append("start", "0");
	postData.append("length", "1000");

	const response:ResponseGenerator = await API.post("financeBalanceOperation", postData);
	return response?.data || {};
};

/**
 Get Bonuses by date range
 **/
export function * getBonusesByDateWatcher () {
	yield takeEvery(GET_BONUSES_BY_DATE, getBonusesByDateWorker);
}

export function * getBonusesByDateWorker (action: ActionType<"GET_BONUSES_BY_DATE", { from?: "string", to?: "string" }>) {
	yield put(setBonusLoading(true));
	try {
		const response:TResponse<({ stamp: string, amount: number })[]> = yield call(getBonusesByDate, action.payload.from, action.payload.to);

		if (response.success) {
			yield put(setBonusesByDate({ data: response.data }));
		}
	} catch (e) {
		//
	} finally {
		yield put(setBonusLoading(false));
	}
}

async function getBonusesByDate (from: string = "", to: string = "") {
	const formData = new FormData();
	formData.append("from", from);
	formData.append("to", to);

	const { data } = await API.post<TResponse<({ stamp: string, amount: number })[]>>("/getBonusStatisticsByDate", formData);

	return data;
}
