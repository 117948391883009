/*
    Created on : 11.08.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import "./mainStyles/Main.scss";
import DashboardMobile from "../../components/Dashboard/DashboardMobile";
import Dashboard from "../../components/Dashboard/Dashboard";
import DashboardAuth from "../../components/Dashboard/DashboardAuth";
import DashboardAuthMobile from "../../components/Dashboard/DashboardAuthMobile";
import MainSwitch from "../../MainSwitch";
import AuthSwitch from "../../AuthSwitch";
import { useDevice } from "../../utils/hooks/useIsMobile";
import { useAppSelector } from "../../utils/hooks/reduxHooks";

interface ILayout {
	logged?: boolean
	redirect?: string
	logout: boolean
}

export default function Layout ({ logged, redirect, logout }: ILayout) {
	const device = useDevice().slice(-1)[0];
	const redirectToTFA = useAppSelector((state) => state.auth.redirectToTFA);

	const getLayout = () => {
		switch (device) {
			case "desktop":
			case "laptop":
				return (logged && !redirectToTFA)
					? <Dashboard><MainSwitch redirect={redirect}/></Dashboard>
					: <DashboardAuth><AuthSwitch logout={logout}/></DashboardAuth>;
			case "tablet":
			case "mobile":
				return (logged && !redirectToTFA)
					? <DashboardMobile><MainSwitch redirect={redirect}/></DashboardMobile>
					: <DashboardAuthMobile><AuthSwitch logout={logout}/></DashboardAuthMobile>;
			default:
				return null;
		}
	};

	return getLayout();
};
