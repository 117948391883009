/* eslint-disable camelcase */
import * as React from "react";
import PageTitle from "../../components/Dashboard/dashboardComponents/PageTitle";
import { BlockWithTriangle } from "../../components/UI/BlockWithTriangle";
import { Row } from "../../components/UI/Row";
import { useAppDispatch, useAppSelector } from "../../utils/hooks/reduxHooks";
import { Alert, Box, Skeleton, Snackbar, Typography } from "@mui/material";
import UserIcon from "../../components/Icons/UserIcon";
import { ButtonWithLoader } from "../../components/ButtonWithLoader";
import { REQUEST_SMART_DOLLARS } from "../../store/actions/actionTypes";
import { useErrorHandling } from "../../utils/hooks/useErrorHandling";
import FMTable from "../../components/FMTable/FMTable";
import FMTableColumn from "../../components/FMTable/FMTableColumn";
import { ISmartDollarsWithdrawInfo } from "../../saga/smartDollarsSaga";

export function InfoSmartDollars () {
	const { image } = useAppSelector((state) => state.session.userData);
	const [ isFetch, setIsFetch ] = React.useState(false);
	const { links } = useAppSelector((state) => state.init.init);
	const {
		totalLots,
		curLevel,
		curLevelPip,
		cashFund
	} = useAppSelector((state) => state.smartDollars.smart_info);
	const {
		cached_info
	} = useAppSelector((state) => state.smartDollars.request_info ?? {});
	const dispatch = useAppDispatch();
	const { isOpen, errorMsg, hideError, showError } = useErrorHandling();
	const data = useAppSelector((state) => state.smartDollars.smart_withdraw as ISmartDollarsWithdrawInfo[]);

	const totalAmount = data.map(({ Amount }) => Amount)
		.reduce((prev, curr) => prev + curr, 0)
		.toFixed(2);

	React.useEffect(() => {
		if (cached_info?.success) {
			if (cached_info.success === "RET_NOT_ENOUGH_FUND") {
				showError("Not enough fund!");
			}

			setIsFetch(false);
		}
	}, [ cached_info?.success ]);

	function activate () {
		setIsFetch(true);
		dispatch({ type: REQUEST_SMART_DOLLARS, payload: {} });
	}

	return (
		<>
			<PageTitle
				layoutTitle={"Smart Dollars"}
				pageTitle={"Info"}
			/>
			<Row
				sx={{ marginBottom: "20px" }}
				wrap="wrap"
			>
				<BlockWithTriangle text={"Regular"} color="#ffffff" bg="#a7cfe7"/>
				<BlockWithTriangle text={"Pro"} color="#ffffff" bg="#82c3e9"/>
				<BlockWithTriangle text={"Expert"} color="#ffffff" bg="#4aace5"/>
				<BlockWithTriangle text={"Vip"} color="#ffffff" bg="#0894dd"/>
			</Row>
			<Row
				alignItems="start"
				justifyContent="space-between"
				wrap="wrap"
				sx={{
					padding: "10px 20px",
					margin: "0 auto",
					border: "1px solid #2A88C9",
					marginBottom: "28px",
					borderRadius: "4px"
				}}
			>
				<Row alignItems="center" sx={{ width: "fit-content" }}>
					<Box sx={{ marginRight: "10px" }}>
						{
							image
								? (
									<img src={links.main_site_user_images + image} width={70} alt="avatar" />
								)
								: (<UserIcon width={60} />)
						}
					</Box>
					<Box sx={{ textAlign: "left" }}>
						{
							totalLots
								? (
									<Typography variant="h5">
										Total lots: {totalLots}
									</Typography>
								)
								: (
									<Skeleton width={174} height={32} />
								)
						}
						{
							curLevel && curLevelPip
								? (
									<Typography variant="subtitle2">
										{curLevel} {curLevelPip} pips
									</Typography>
								)
								: (
									<Skeleton width={174} height={25} />
								)
						}

					</Box>
				</Row>
				<Row alignItems="center" sx={{ width: "fit-content" }}>
					<Typography
						variant="h5"
						color="secondary"
					>
						Current level is
					</Typography>
					{
						curLevel
							? (
								<Typography
									variant="h5"
									color="primary"
									fontWeight="600"
									ml="10px"
								>
									{curLevel}
								</Typography>
							)
							: (
								<Skeleton width={37} height={32} sx={{ marginLeft: "10px" }} />
							)
					}
				</Row>
				<Box sx={{ width: "fit-content" }}>
					{
						!isNaN(cashFund)
							? (
								<Typography variant="h6">
									{`$ ${(cashFund).toFixed(2)}`}
								</Typography>
							)
							: (
								<Skeleton />
							)
					}

					<ButtonWithLoader
						isFetch={isFetch}
						text={"Request"}
						color="primary"
						disabled={isNaN(cashFund) || cashFund === 0}
						onClick={activate}
						sxButton={{
							padding: "5px, 15px"
						}}
					/>
				</Box>
			</Row>
			<FMTable
				theme="AccountSummaryTheme"
				data={data}
				withSearch={false}
				innerComponent={
					<Typography
						variant="h5"
						align="left"
						pl="16px"
					>
						Total amount: {totalAmount}
					</Typography>
				}
			>
				<FMTableColumn
					id="Amount"
					label={"Amount"}
				/>
				<FMTableColumn<ISmartDollarsWithdrawInfo, {}>
					id="DateAdded"
					label={"Date of withdrawal"}
					component={({ DateAdded }) => <Typography>{new Date(+DateAdded * 1000).toLocaleDateString("en-GB")}</Typography>}
				/>
			</FMTable>
			<Snackbar open={isOpen} autoHideDuration={4000} onClose={hideError}>
				<Alert variant="filled" color="error">
					{errorMsg}
				</Alert>
			</Snackbar>
		</>
	);
}
