import * as React from "react";
import PageTitle from "../../components/Dashboard/dashboardComponents/PageTitle";
import { ContentWrapper } from "../../components/UI/ContentWrapper";
import { Box, Link, Typography } from "@mui/material";
import { Row } from "../../components/UI/Row";
import { ButtonWithLoader } from "../../components/ButtonWithLoader";
import { useAppSelector } from "../../utils/hooks/reduxHooks";

export function ActivateSmartDollars () {
	const closedLots = useAppSelector((state) => state.smartDollars.smart_info.totalLots);
	const allowedTotalTrades = 2;

	return (
		<>
			<PageTitle
				layoutTitle={"Smart Dollars"}
				pageTitle={"Activate"}
			/>
			<Row
				justifyContent="center"
				sx={{
					padding: "10px 20px",
					maxWidth: "400px",
					margin: "0 auto",
					border: "1px solid #2A88C9",
					marginBottom: "28px",
					borderRadius: "4px"
				}}
			>
				<Typography fontSize="1rem">
					Smart Dollars by
				</Typography>
				<Link href="https://www.forexmart.com" fontWeight="600" ml="4px" fontSize="1rem">
					ForexMart
				</Link>
			</Row>
			<ContentWrapper
				titleSx={{
					borderTopLeftRadius: "4px",
					borderTopRightRadius: "4px",
					"& p": {
						width: "100%",
						textAlign: "center",
						fontSize: "0.675rem"
					}
				}}
				contentSx={{
					borderBottomLeftRadius: "4px",
					borderBottomRightRadius: "4px"
				}}
				title={`Trade ${allowedTotalTrades} lots to start getting ForexMart Smart Dollars to your account`}
				wrapperSx={{
					margin: "0 auto",
					marginBottom: "24px",
					maxWidth: "450px"
				}}
			>
				<Box>
					<Row wrap="wrap">
						<Row
							alignItems="center"
							justifyContent="center"
							sx={{ padding: "10px", minWidth: "247px" }}
						>
							<Typography fontWeight="600" color="secondary" fontSize="1rem">
								You have closed
							</Typography>
							<Typography
								variant="h5"
								component={"span"}
								ml="5px"
								mr="5px"
								color="primary"
								fontWeight="700"
								textAlign="center"
							>
								{closedLots}
							</Typography>
							<Typography fontWeight="600" color="secondary" fontSize="1rem">
								lots
							</Typography>
						</Row>
						<Row
							alignItems="center"
							justifyContent="center"
							sx={{ padding: "10px", minWidth: "247px" }}
						>
							<Typography fontWeight="600" color="secondary" fontSize="1rem">
								You are only
							</Typography>
							<Typography
								variant="h5"
								component={"span"}
								ml="5px"
								mr="5px"
								color="primary"
								fontWeight="700"
								textAlign="center"
							>
								{Math.max(0, allowedTotalTrades - closedLots)}
							</Typography>
							<Typography fontWeight="600" color="secondary" fontSize="1rem">
								lots left to trade
							</Typography>
						</Row>
					</Row>
				</Box>
			</ContentWrapper>
			<Row alignItems="center" justifyContent="center" wrap="wrap" sx={{ marginBottom: "24px" }}>
				<ButtonWithLoader
					isFetch={false}
					disabled={closedLots < allowedTotalTrades}
					text={"Activate"}
					color="success"
				/>
			</Row>
			<Row justifyContent="center">
				<Typography ml="10px">
					By clicking ACTIVATE button I agree with the
				</Typography>
				<Link ml="10px" color="#ED1F24" href="https://my.forexmart.com/assets/pdf/Loyalty%20Program%20Smart%20Dollars.pdf">
					Terms and Conditions
				</Link>
			</Row>
		</>
	);
}
