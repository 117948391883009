import * as React from "react";
import { Default, DefaultProps } from "./Default";
import { Rounded } from "./Rounded";

export type InputVariant = "default" | "rounded";

type InputModuleI = {
	[key in InputVariant]: React.FC<DefaultProps>
}

export const Input: InputModuleI = {
	rounded: Rounded,
	default: Default
};
