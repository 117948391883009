/*
    Created on : 02.11.2022, 06:18:59
    Author     : Andy Kar
*/
import { takeEvery, put, call } from "redux-saga/effects";
import {
	FETCH_NOTIFICATION_INFO, SET_NOTIFICATION_INFO,
	SEND_FEEDBACK_INFO, SET_FEEDBACK_REQUEST_INFO, UPDATE_NOTIFICATION, SET_FEEDBACK_LOADING
} from "../store/actions/actionTypes";
import API from "../utils/API";

/**
 Get notification data from server and set it to store
 **/
export function * notificationWatcher () {
	yield takeEvery(FETCH_NOTIFICATION_INFO, notificationWorker);
}
export function * notificationWorker () {
	const data: {} = yield getNotificationInfo();
	if (data) { yield put({ type: SET_NOTIFICATION_INFO, payload: data }); }
}
async function getNotificationInfo () {
	const response = await API.get("notification");
	return response?.data || {};
}
/**
 Update Notification, reload notification data and set it to store
 **/
export function * updateNotificationWatcher () {
	yield takeEvery(UPDATE_NOTIFICATION, updateNotificationWorker);
}
export function * updateNotificationWorker (action:{type:string, notificationData: any}) {
	const data: {success:boolean} = yield call(updateNotification, action.notificationData);
	if (data.success) { yield put({ type: FETCH_NOTIFICATION_INFO }); }
}
async function updateNotification (notificationData: any) {
	const response = await API.post("updateNotification", notificationData);
	return response?.data || {};
}

/**
 Send feedback data to server and set request to store
 **/
export function * feedbackWatcher () {
	yield takeEvery(SEND_FEEDBACK_INFO, feedbackWorker);
}
export function * feedbackWorker (action:{type:string, feedbackData: any}) {
	yield put({ type: SET_FEEDBACK_LOADING, payload: true });
	const data: {} = yield call(sendFeedbackInfo, action.feedbackData);
	yield put({ type: SET_FEEDBACK_LOADING, payload: false });
	if (Object.keys(data).length) { yield put({ type: SET_FEEDBACK_REQUEST_INFO, payload: data }); }
}
async function sendFeedbackInfo (feedbackData: any) {
	const response = await API.post("feedback", feedbackData);
	return response?.data || {};
}
