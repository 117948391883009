/*
    Created on : 30.12.2021, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../../interfaces/StoreInterfaces";
import { Box, Button } from "@mui/material";
import GoogleIcon from "./GoogleIcon";
import { useIsRtl } from "../../../../utils/hooks/useIsRtl";
import FacebookIcon from "./FacebookIcon";
import { useAdaptive } from "../../../../utils/hooks/themeBreakpointsHooks";
import { SignInType } from "./AuthForm";

export default function SocialSignIn ({ type }: { type: SignInType }) {
	const init:any = useSelector((state:StoreInterface) => state.init.init);
	const isRtl = useIsRtl();
	const socialButtonSX = useAdaptive(aSX);

	return (
		<Box className="flexRow" sx={socialBoxSX}>
			<Button
				startIcon={!isRtl ? <GoogleIcon/> : null}
				endIcon={isRtl ? <GoogleIcon/> : null}
				sx={{ ...socialButtonSX, ...socialButtonOutlinedSX }}
				variant="outlined"
				color="secondary"
				href={init.links["google_login_url" + type] ? init.links["google_login_url" + type] : "#"}
			>
				Sign in with Google
			</Button>
			<Button
				startIcon={!isRtl ? <FacebookIcon/> : null}
				endIcon={isRtl ? <FacebookIcon/> : null}
				sx={{ ...socialButtonSX, ...socialButtonContainedSX }}
				variant="contained"
				color="primary"
				href={init.links["fb_login_url" + type] ? init.links["fb_login_url" + type] : "#"}
			>
				Sign in with Facebook
			</Button>
		</Box>
	);
};

const aSX = {
	mobile: {
		flex: 1,
		minWidth: "100%",
		height: "50px",
		marginBottom: "20px",
		borderRadius: 0,
		textTransform: "none",
		marginX: 0,
		"& .MuiButton-startIcon": {
			marginRight: "9px"
		}
	},
	tablet: {
		marginX: "5px",
		minWidth: "max-content"
	}
};

const socialBoxSX = {
	width: "100%",
	margin: "22px 0 0",
	flexWrap: "wrap",
	justifyContent: "center"
};

const socialButtonOutlinedSX = {
	color: "#333333",
	borderColor: "rgba(0, 0, 0, 0.16)",
	"&:hover": {
		color: "inherit",
		backgroundColor: "white"
	}
};
const socialButtonContainedSX = {
	marginLeft: "4px",
	"&:hover": {
		color: "white"
	}
};
