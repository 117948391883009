/*
    Created on : 01.02.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { Box } from "@mui/material";
import NewAccountButtons from "./NewAccountButtons";
import UserDetails from "./UserDetails";
import { ISxAdaptive, useAdaptive } from "../../../../../utils/hooks/themeBreakpointsHooks";

const boxAdaptiveStyles: ISxAdaptive = {
	xs: {
		justifyContent: "center",
		flexWrap: "wrap"
	},
	sm: {
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: "22px"
	}
};

export default function PersonalDetails () {
	const boxAdaptive = useAdaptive(boxAdaptiveStyles);

	return (
		<Box className="flexRow" sx={boxAdaptive}>
			<UserDetails/>
			<NewAccountButtons/>
		</Box>
	);
};
