/*
    Created on : 20.12.2021, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { useSelector } from "react-redux";
import { SessionUserDataInterface, StoreInterface } from "../../../../interfaces/StoreInterfaces";
import "./FooterAuthLinks.scss";
import languageLink from "../../../../utils/languageLink";

function FooterAuthLinks () {
	const lang:any = useSelector((state:StoreInterface) => state.language.language);
	const init:any = useSelector((state:StoreInterface) => state.init.init);
	const session:SessionUserDataInterface = useSelector((state:StoreInterface) => state.session.userData);

	return (
		<div className="footer-auth-links flexRow">
			<div className="auth-links-logo-wrap">
				<img className="auth-links-logo" alt="Logo" src="https://www.forexmart.com/assets/images/forexmartlogo_new.png" />
			</div>
			<div className="auth-links-wrap">
				<p className="quick-links-title">{lang.btm_nav_frx_txt1}</p>
				<ul className="quick-links-wrap">
					<li>— <a className="quick-link" href={init.links.link_news && session.site_lang ? languageLink(init.links.link_news, session.site_lang) : ""}>{lang.bn_li_nw}</a></li>
					<li>— <a className="quick-link" href={init.links.link_legal_documentation && session.site_lang ? languageLink(init.links.link_legal_documentation, session.site_lang) : ""}> {lang.bn_li_ld}</a></li>
					<li>— <a className="quick-link" href={init.links.link_risk_disclosure}>{lang.bn_li_rd}</a></li>
					<li>— <a className="quick-link" href={init.links.link_terms_and_conditions}>{lang.bn_li_tnc}</a></li>
					<li>— <a className="quick-link" href={init.links.link_privacy_policy}>{lang.bn_li_pp}</a></li>
				</ul>
			</div>
		</div>

	);
}

export default FooterAuthLinks;
