/*
    Created on : 03.01.2022, 06:18:59
    Author     : Andy Kar
*/

import { takeEvery, put, call } from "redux-saga/effects";
import {
	FETCH_SESSION_INFO,
	GET_TWO_FACTOR_AUTH_INFO,
	SEND_AUTH_INFO,
	SEND_FORGOT_PASS_INFO,
	SEND_LOGOUT,
	SEND_PARTNER_AUTH_INFO,
	SEND_RESET_PASS_INFO,
	setRedirectToTFA,
	setTwoFactorAuthInfo,
	setTwoFactorAuthInfoLoading,
	SET_AUTH_LOADING,
	SET_AUTH_REQUEST_INFO, SET_FEEDBACK_REQUEST_INFO, SET_INIT_INFO,
	SET_SESSION_INFO, SET_USER_INFO, VERIFY_TFA
} from "../store/actions/actionTypes";
import API from "../utils/API";
import { clearLocalStorage, setLocalStorageItem } from "../utils/LocalStorage";
import { ActionType } from "../utils/reudxHelpers";
import { ClientType, TResponse } from "../utils/types";

/**
 Send auth data to the server
 **/
export interface authDataInterface {
	username: string
	password: string
}

type authDataType = {
	logged?: {
		local?: {
			euid: string
			eacn: string
			ci_session: string
		} | undefined
		goToPayment?: any
		session?: any
		redirect?: any
		eu_signin?: any
		eu_redirect?: any
		redirectToTFA: boolean,
	}
	errors?: any
}

export function * verifyTFAWatcher () {
	yield takeEvery(VERIFY_TFA, verifyTFAWorker);
}

export function * verifyTFAWorker (action: ActionType<"VERIFY_TFA", { code: string }>) {
	yield put({ type: SET_AUTH_LOADING, payload: true });

	try {
		const { success }: TResponse<{ client: ClientType }> = yield call(verifyTFA, action.payload.code);

		if (success) {
			yield put({ type: FETCH_SESSION_INFO });
			yield put(setRedirectToTFA(false));
		}
	} catch (e) {
		//
	} finally {
		yield put({ type: SET_AUTH_LOADING, payload: false });
	}
}

export async function verifyTFA (code: string): Promise<TResponse<{ client: ClientType }>> {
	const formData = new FormData();
	formData.append("code", code);

	const { data } = await API.post<TResponse<{ client: ClientType }>>("verifyTwoFactorAuthCode", formData);

	return data;
}

export function * authWatcher () {
	yield takeEvery(SEND_AUTH_INFO, authWorker);
}
export function * authWorker (action:{type:string, authData: authDataInterface}) {
	yield put({ type: SET_AUTH_LOADING, payload: true });
	const data:authDataType = yield call(sendAuthInfo, action.authData);
	yield put({ type: SET_AUTH_LOADING, payload: false });
	if (Object.keys(data).length) {
		if (data.logged?.redirectToTFA) {
			yield put(setRedirectToTFA(data.logged.redirectToTFA));
		}
		yield put({ type: SET_AUTH_REQUEST_INFO, payload: data });
		if (data.errors && Object.keys(data.errors).length === 0 && data.logged && data.logged.session) {
			console.log("AUTH DATA", data);
			if (data.logged.local && data.logged.local.ci_session) {
				yield setLocalStorageItem("ci_session", data.logged.local.ci_session ? data.logged.local.ci_session : "");
			}
			if (data.logged.local && data.logged.local.euid) {
				yield setLocalStorageItem("euid", data.logged.local.euid ? data.logged.local.euid : "");
			}
			if (data.logged.local && data.logged.local.eacn) {
				yield setLocalStorageItem("eacn", data.logged.local.eacn ? data.logged.local.eacn : "");
			}
			yield put({ type: SET_SESSION_INFO, payload: data.logged.session.userdata });
			// yield put({ type: FETCH_SESSION_INFO });
		}
	}
}
async function sendAuthInfo (authData:authDataInterface) {
	const authFormData = new FormData();
	authFormData.append("username", authData.username);
	authFormData.append("password", authData.password);
	const response = await API.post("clientSignIn", authFormData);
	return response?.data || {};
}
export function * authPartnerWatcher () {
	yield takeEvery(SEND_PARTNER_AUTH_INFO, authPartnerWorker);
}
export function * authPartnerWorker (action:{type:string, authData: authDataInterface}) {
	yield put({ type: SET_AUTH_LOADING, payload: true });
	const data:authDataType = yield call(sendPartnerAuthInfo, action.authData);
	yield put({ type: SET_AUTH_LOADING, payload: false });
	if (Object.keys(data).length) {
		yield put({ type: SET_AUTH_REQUEST_INFO, payload: data });
		if (data.errors && Object.keys(data.errors).length === 0 && data.logged && data.logged.session) {
			console.log("AUTH PARTNER DATA", data);
			if (data.logged.local && data.logged.local.ci_session) {
				yield setLocalStorageItem("ci_session", data.logged.local.ci_session ? data.logged.local.ci_session : "");
			}
			if (data.logged.local && data.logged.local.euid) {
				yield setLocalStorageItem("euid", data.logged.local.euid ? data.logged.local.euid : "");
			}
			if (data.logged.local && data.logged.local.eacn) {
				yield setLocalStorageItem("eacn", data.logged.local.eacn ? data.logged.local.eacn : "");
			}
			yield put({ type: SET_SESSION_INFO, payload: data.logged.session.userdata });
			// yield put({ type: FETCH_SESSION_INFO });
		}
	}
}
async function sendPartnerAuthInfo (authData: authDataInterface) {
	const authFormData = new FormData();
	authFormData.append("username", authData.username);
	authFormData.append("password", authData.password);
	const response = await API.post("partnerSignIn", authFormData);
	return response?.data || {};
}
/**
 ForgotPass data to the server
 **/
export function * forgotPassWatcher () {
	yield takeEvery(SEND_FORGOT_PASS_INFO, forgotPassWorker);
}
export function * forgotPassWorker (action:{type:string, forgotPassData: any}) {
	yield put({ type: SET_AUTH_LOADING, payload: true });
	const data:{} = yield call(sendForgotPassInfo, action.forgotPassData);
	yield put({ type: SET_AUTH_LOADING, payload: false });
	if (Object.keys(data).length) {
		yield put({ type: SET_AUTH_REQUEST_INFO, payload: data });
		console.log("forgotPass data", data);
	}
}
async function sendForgotPassInfo (forgotPassData: any) {
	const response = await API.post("forgotPass", forgotPassData);
	return response?.data || {};
}

/**
 Reset Pass data to the server
 **/
export function * resetPassWatcher () {
	yield takeEvery(SEND_RESET_PASS_INFO, resetPassWorker);
}
export function * resetPassWorker (action:{type:string, resetPassData: any}) {
	yield put({ type: SET_AUTH_LOADING, payload: true });
	const data:{} = yield call(sendResetPassInfo, action.resetPassData);
	yield put({ type: SET_AUTH_LOADING, payload: false });
	if (Object.keys(data).length) {
		yield put({ type: SET_AUTH_REQUEST_INFO, payload: data });
	}
}
async function sendResetPassInfo (resetPassData: any) {
	const response = await API.post("resetPassword", resetPassData);
	console.log("resetPass data", response);
	return response?.data || {};
}

/**
 Signout data to the server
 **/
type logoutDataType = {
	logout?: string|boolean
}
export function * logoutWatcher () {
	yield takeEvery(SEND_LOGOUT, logoutWorker);
}
export function * logoutWorker () {
	yield put({ type: SET_AUTH_LOADING, payload: true });
	const data:logoutDataType = yield call(sendLogout);
	yield put({ type: SET_AUTH_LOADING, payload: false });
	if (Object.keys(data).length && data.logout) {
		yield put({ type: SET_AUTH_REQUEST_INFO, payload: data });
		yield put({ type: FETCH_SESSION_INFO });
		yield clearLocalStorage();
		yield put({ type: SET_INIT_INFO, payload: {} });
		yield put({ type: SET_FEEDBACK_REQUEST_INFO, payload: {} });
		yield put({ type: SET_USER_INFO, payload: {} });
	}
}
async function sendLogout () {
	const response = await API.post("signout");
	console.log("signout", response);
	return response?.data || {};
}

/**
 * Get two factor auth info
 */

export function * getTwoFactorAuthWatcher () {
	yield takeEvery(GET_TWO_FACTOR_AUTH_INFO, getTwoFactorAuthWorker);
}

export function * getTwoFactorAuthWorker () {
	yield put(setTwoFactorAuthInfoLoading(true));
	try {
		const response: TResponse<{ secret: string, QRCode: string }> = yield call(getTwoFactorInfo);

		if (response.success) {
			yield put(setTwoFactorAuthInfo(response.data));
		}
	} catch (e) {
		console.error(e);
	} finally {
		yield put(setTwoFactorAuthInfoLoading(false));
	}
}

export async function getTwoFactorInfo (): Promise<TResponse<{ secret: string, QRCode: string }>> {
	const response = await API.get<TResponse<{ secret: string, QRCode: string }>>("getTwoFactorAuthInfo");

	return response.data;
}
