/*
    Created on : 05.12.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { IFMTableComponentProps } from "../../../../components/FMTable/FMTableCellComponent";
import { ILogo } from "../../../../store/reducers/bannersReducer";
import { ILogoCompData } from "../PagePromotionalMaterialsLogo";
import { Box, Button } from "@mui/material";
import { StoreInterface } from "../../../../interfaces/StoreInterfaces";
import { useSelector } from "react-redux";

export default function LogoActionCell (props: IFMTableComponentProps<ILogo, ILogoCompData>) {
	const { componentData, pdf, png } = props;
	const lang:any = useSelector((state:StoreInterface) => state.language.languageBanners);
	console.log("LogoCell", props);
	return <Box sx={{
		p: "5px 10px"
		// "& a": {
		// 	margin: "10px 3px"
		// }
	}}>
		<a href={componentData?.url_images + png} target="_blank" rel="noreferrer">
			<Button
				variant="contained"
				color="success"
				sx={{ margin: "5px", width: "75px" }}
			>
				{lang.ext_png}
			</Button>
		</a>
		<a href={componentData?.url_pdf + pdf} target="_blank" rel="noreferrer">
			<Button
				variant="contained"
				color="success"
				sx={{ margin: "5px", width: "75px" }}
			>
				{lang.ext_pdf}
			</Button>
		</a>
	</Box>;
}
