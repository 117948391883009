import React from "react";
import RichTextEditor, { EditorValue, ToolbarConfig } from "react-rte";
import { ISetState } from "../../../types/common";
import { Box } from "@mui/material";

interface IRte {
	value: EditorValue
	setValue: ISetState
	error: boolean
}

export default function Rte ({ value, setValue, error }: IRte) {
	const rteSx = {
		mb: "24px",
		"& .RichTextEditor__root___2QXK-": {
			height: "339px",
			fontFamily: "Open Sans",
			borderRadius: "4px",
			border: "1px solid",
			borderColor: error ? "#ff0000" : "#BBBBBB"
		},
		"& .EditorToolbar__root___3_Aqz": {
			textAlign: "left",
			borderBottom: "1px solid #D9D9D9",
			margin: 0,
			padding: "5px 5px 0",
			background: "#F1F1F1",
			borderRadius: "4px 4px 0 0"
		},
		"& .Dropdown__value___34Py9": {
			background: "none #fdfdfd",
			borderColor: "#D9D9D9"
		},
		"& .Button__root___1gz0c": {
			background: "none #fdfdfd",
			borderColor: "#D9D9D9"
		}
	};

	return (
		<Box sx={rteSx}>
			<RichTextEditor
				toolbarConfig={toolbarConfig}
				value={value}
				onChange={setValue}
			/>
		</Box>
	);
};

const toolbarConfig: ToolbarConfig = {
	BLOCK_ALIGNMENT_BUTTONS: [],
	// Optionally specify the groups to display (displayed in the order listed).
	display: [ "INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS", "LINK_BUTTONS", "BLOCK_TYPE_DROPDOWN", "HISTORY_BUTTONS" ],
	INLINE_STYLE_BUTTONS: [
		{ label: "Bold", style: "BOLD", className: "custom-css-class" },
		{ label: "Italic", style: "ITALIC" },
		{ label: "Underline", style: "UNDERLINE" }
	],
	BLOCK_TYPE_DROPDOWN: [
		{ label: "Normal", style: "unstyled" },
		{ label: "Heading Large", style: "header-one" },
		{ label: "Heading Medium", style: "header-two" },
		{ label: "Heading Small", style: "header-three" }
	],
	BLOCK_TYPE_BUTTONS: [
		{ label: "UL", style: "unordered-list-item" },
		{ label: "OL", style: "ordered-list-item" }
	]
};
