import * as React from "react";
import { Box } from "@mui/material";
import SecondaryText from "../../pages/Partnership/components/SecondaryText";
import { useAppSelector } from "../../utils/hooks/reduxHooks";
import FMTableAffiliateCodeSelect from "./FMTableAffiliateCodeSelect";

export interface IFMTableAffiliateCodeSelect {
	codes: {affiliate_code: string}[]
}

export default function FMTableAffiliateCode (props:IFMTableAffiliateCodeSelect) {
	const { codes } = props;
	const langPartnership:any = useAppSelector((state) => state.language.languagePartnership);

	return (
		<>
			{ codes &&
				<Box mb="30px">
					<SecondaryText>{ langPartnership.cli_00 }</SecondaryText>
					<FMTableAffiliateCodeSelect
						label={langPartnership.cli_01}
						extOnChange={() => {}}
						extOptions={codes}
					/>
				</Box>
			}
		</>
	);
};
