import React from "react";
import { useSelector } from "react-redux";
import { SessionUserDataInterface, StoreInterface } from "../../../interfaces/StoreInterfaces";
import { Box } from "@mui/material";
import UserIcon from "../UserIcon";

const AccountUserIcon = () => {
	const session:SessionUserDataInterface = useSelector((state:StoreInterface) => state.session.userData);
	const init:any = useSelector((state:StoreInterface) => state.init.init);

	return (
		<Box
			sx={{
				paddingX: "9px",
				position: "relative",
				width: "44px",
				height: "26px"
			}}
		>
			<Box
				sx={{
					width: "26px",
					height: "26px",
					borderRadius: "50%",
					overflow: "hidden",
					"& > img": {
						display: "block",
						width: "auto",
						height: "100%"
					},
					"& svg": {
						maxWidth: "100%",
						maxHeight: "100%"
					}
				}}
			>
				{session.image
					? <img src={init.links.main_site_user_images + session.image} alt={"avatar"}/>
					: <UserIcon width={26}/>
				}
			</Box>
		</Box>
	);
};

export default AccountUserIcon;
