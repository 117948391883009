import * as React from "react";
import { IFMTableComponentProps } from "../../../../components/FMTable/FMTableCellComponent";
import { IRebateSystemProject } from "../../../../interfaces/RebateSystemInterfaces";
import { Input as InputModule } from "../../../../components/UI/Input";
import { useInputStorage } from "../../../../components/Storage";
import { Box } from "@mui/material";
import { wrapperStyles } from "./commonStyles";

const Input = InputModule.rounded;

export function ChangeRebateValueCell (
	props: IFMTableComponentProps<IRebateSystemProject, {}>
) {
	const { RebateValue } = props;
	const { value, handleChange } = useInputStorage("rebateValue", (RebateValue * 100).toFixed(0));

	return (
		<Box sx={wrapperStyles}>
			<Input
				name="rebateValue"
				value={value}
				onChange={handleChange}
				iserror={false}
			/>
		</Box>
	);
}
