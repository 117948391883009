import * as React from "react";
import styled from "styled-components";
import { useIsRtl } from "../../../../utils/hooks/useIsRtl";

export interface DefaultProps extends React.InputHTMLAttributes<HTMLInputElement> {
	iserror: boolean;
}

export function Default (props: DefaultProps) {
	const isRtl = useIsRtl();

	return <OutlinedUI
		{...props}
		isRtl={isRtl}
	/>;
}

const OutlinedUI = styled.input<{
	readonly iserror: boolean,
	readonly isRtl: boolean
}>`
  width: 100%;
  padding: 10px;
  border: 1px solid;
	border-color: ${({ iserror }) => iserror ? "#ff0000" : "#7A8B98"};
	text-align: ${({ isRtl }) => isRtl ? "right" : "left"};
	outline: none;

  &:focus {
    color: #7A8B98;
  }
`;
