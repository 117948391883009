import * as React from "react";
import { Grid, Typography, Tooltip, Box } from "@mui/material";
import { Row } from "../../Row";
import { DefaultWrapperProps } from "../Default";
import { ReactComponent as InfoIcon } from "./icons/InfoIcon.svg";

export function WrapperWithTip (props: DefaultWrapperProps) {
	const { label, children, tips } = props;

	return (
		<Grid container sx={{ position: "relative" }}>
			<Row justifyContent="space-between" sx={{ marginBottom: "9px" }}>
				<Typography
					fontWeight="bold"
					color="#5C7080"
				>
					{label}
				</Typography>
				{
					tips
						? (
							<Tooltip title={tips} placement="left-start">
								<Box>
									<InfoIcon />
								</Box>
							</Tooltip>
						)
						: (
							<></>
						)
				}
			</Row>
			{children}
		</Grid>
	);
}
