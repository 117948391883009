export enum RebateSystemProjectStatus {
	DEACTIVATED = "DEACTIVATED",
	ACTIVATED = "ACTIVATED",
}

export enum RebateSystemProjectType {
	DEFAULT = "DEFAULT",
	PERSONAL = "PERSONAL",
}

export interface IRebateSystemProject {
	ProjectName: string,
	RebateValue: number,
	Status: RebateSystemProjectStatus,
	Type: RebateSystemProjectType,
	Client: number,
}

export interface IRebateSystemPersonalProject {
	client: number,
	rebateValue: number,
}

type Timestamp = number | Date | string;
type Amount = number;

export interface IStatisticsItem {
	x: Timestamp;
	y: Amount;
	client: number;
}
