import * as React from "react";
import { Textarea as TextareaUI, TextareaVariant } from "../../UI/Textarea";
import { FieldWrapper as FieldWrapperUI } from "../../UI/FieldWrapper";
import { Text } from "../../UI/Text";
import { FormContext } from "../Form";
import { Controller } from "react-hook-form";

export interface TextareaProps {
	name: string;
	label?: string;
	tips?: string;
	placeholder?: string;
	variant?: TextareaVariant;
	minWidth?: string;
	minHeight?: string;
}

export function Textarea (props: TextareaProps) {
	const {
		name,
		label = "",
		variant = "default",
		tips,
		placeholder = "",
		minWidth,
		minHeight
	} = props;
	const { control, fieldWrapperVariant } = React.useContext(FormContext);
	const FieldWrapper = React.useMemo(() => FieldWrapperUI[fieldWrapperVariant], [ fieldWrapperVariant ]);
	const Textarea = React.useMemo(() => TextareaUI[variant], [ variant ]);

	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { value, onChange }, fieldState: { error } }) => (
				<FieldWrapper label={label} tips={tips}>
					<Textarea
						value={value}
						iserror={!!error}
						onChange={onChange}
						placeholder={placeholder}
						minWidth={minWidth}
						minHeight={minHeight}
					>
						{value}
					</Textarea>
					<Text
						color="#ff0000"
						fontSize={12}
						sx={{
							position: "absolute",
							top: "100%",
							width: "100%"
						}}
					>
						{error?.message}
					</Text>
				</FieldWrapper>
			)}
		/>
	);
}
