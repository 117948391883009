/*
Created on : 09.05.2022, 06:18:59
Author     : Andy Kar
*/
import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import { Box, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

const itemIconSX = {
	width: "32px",
	display: "flex",
	justifyContent: "center"
};

const MenuItemIcon = ({ icon }:any) => (<Box sx={itemIconSX}>{icon} </Box>);

export default function AppBatAccountMenuItem ({ setAccordion, setOpen, link, icon, text }:any) {
	const item = () => {
		const location = useLocation();
		const path = location.pathname;
		const active = link ? path.includes("/app/" + link) : false;

		const textSX = {
			fontSize: "14px",
			minWidth: "max-content",
			color: active ? "#2988CA" : "inherit"
		};

		const menuItemSX = {
			height: "52px",
			display: "flex",
			minWidth: "max-content",
			color: "#5C7080",
			justifyContent: "flex-start",
			alignItems: "center",
			backgroundColor: active ? "#EAF3F7" : "transparent",
			"&:hover": {
				backgroundColor: active ? "#EAF3F7" : "#2988CA",
				color: "#fff"
			},
			"& svg g": {
				fill: "#3d7cbf"
			},
			"&:hover svg g": {
				fill: active ? "#3d7cbf" : "#fff"
			}
		};

		return (
			<MenuItem
				className={"flexRow"}
				sx={menuItemSX}
				onClick={() => {
					setAccordion(false);
					setOpen((prev: boolean) => !prev);
				}}
				disableRipple
			>
				<MenuItemIcon icon={icon}/>
				<Typography component={"span"} sx={textSX}>
					{text}
				</Typography>
			</MenuItem>
		);
	};

	if (link) return <Link to={"/app/" + link}>{item()}</Link>;
	return item();
};
