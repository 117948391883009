import * as React from "react";
import { Box, InputBase, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { useIsRtl } from "../../utils/hooks/useIsRtl";
import { useAppSelector } from "../../utils/hooks/reduxHooks";

export interface IFMTableSelect {
	label?: string
	extValue?: string
	extOnChange: (value: string) => void
	extOptions: {affiliate_code: string}[]
}

const BootstrapInput = styled(InputBase)(() => ({
	"& .MuiInputBase-input": {
		borderRadius: 4,
		position: "relative",
		border: "1px solid #5C7080",
		fontSize: 14,
		padding: "10px 26px 10px 12px",
		"&:focus": {
			backgroundColor: "#fff"
		}
	}
}));

export default function FMTableAffiliateCodeSelect (props:IFMTableSelect) {
	const {
		label,
		extValue,
		extOnChange,
		extOptions
	} = props;

	const isRtl = useIsRtl();
	const handleChange = (event: SelectChangeEvent) => {
		extOnChange(event.target.value as string);
	};

	const linkRegister = useAppSelector(state => state.init.init.links.link_register);

	const renderOptions = () => {
		return extOptions.map((value: {affiliate_code: string}, index: number) => {
			return (
				<MenuItem
					key={index}
					value={value.affiliate_code}
					sx={{
						textAlign: "center",
						fontSize: "14px",
						cursor: "pointer",
						backgroundColor: "#fff"
					}}
				>
					{linkRegister + "?id=" + value.affiliate_code}
				</MenuItem>
			);
		});
	};

	return (
		<Box>
			<Typography
				sx={{
					width: "100%",
					fontWeight: "bold",
					textAlign: isRtl ? "right" : "left",
					lineHeight: "19px",
					color: "#5C7080",
					mb: "8px"
				}}
			>
				{label}
			</Typography>
			<Select
				className="flexRow"
				sx={{
					width: "100%",
					"& .MuiSelect-select": {
						color: "#5C7080",
						textAlign: isRtl ? "right" : "left"
					}
				}}
				input={<BootstrapInput/>}
				defaultValue={extOptions[0].affiliate_code}
				value={extValue}
				onChange={handleChange}
			>
				{renderOptions()}
			</Select>
		</Box>
	);
};
