import * as React from "react";
import styled from "styled-components";
import { useIsRtl } from "../../../../utils/hooks/useIsRtl";

export interface DefaultTextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
	minWidth?: string;
	minHeight?: string;
	iserror?: boolean;
}

export function DefaultTextarea (props: DefaultTextareaProps) {
	const { iserror, minWidth = "100%", minHeight = "100px" } = props;
	const isRtl = useIsRtl();

	return <TextareaUI
		{...props}
		isRtl={isRtl}
		iserror={iserror}
		minWidth={minWidth}
		minHeight={minHeight}
	>
		{props.value}
	</TextareaUI>;
}

const TextareaUI = styled.textarea<{ iserror?: boolean, isRtl: boolean, minWidth?: string; minHeight?: string, children?: any }>`
	resize: none;
	width: 100%;
	min-width: ${({ minWidth }) => minWidth};
	min-height: ${({ minHeight }) => minHeight};
  padding: 10px;
  border: 1px solid;
	border-color: ${({ iserror }) => iserror ? "#ff0000" : "#7A8B98"};
	text-align: ${({ isRtl }) => isRtl ? "right" : "left"};
	outline: none;

  &:focus {
    color: #7A8B98;
  }
`;
