import { Snackbar, SnackbarOrigin } from "@mui/material";
import * as React from "react";
import { useIsRtl } from "../../../utils/hooks/useIsRtl";
import { Input as InputUI } from "../Input";

export interface CopyProps {
	value: string;
}

export function Copy (props: CopyProps) {
	const { value } = props;
	const [ stateValue, setStateValue ] = React.useState(value);
	const [ open, setOpen ] = React.useState(false);
	const isRtl = useIsRtl();
	const Input = React.useMemo(() => InputUI.rounded, []);

	const copyToClipboard = React.useCallback(async () => {
		try {
			window.navigator.clipboard.writeText(stateValue);
			setOpen(true);
		} catch (e) {
			//
		}
	}, [ stateValue ]);

	const handleClose = React.useCallback((event: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === "clickaway") return;

		setOpen(false);
	}, [ setOpen ]);

	const snackbarOrigin: SnackbarOrigin = React.useMemo(() => {
		return {
			horizontal: isRtl ? "left" : "right",
			vertical: "bottom"
		};
	}, [ isRtl ]);

	return (
		<>
			<Input
				iserror={false}
				value={stateValue}
				onChange={() => setStateValue(stateValue)}
				onFocus={(e) => e.target.blur()}
				onClick={copyToClipboard}
			/>
			<Snackbar
				open={open}
				autoHideDuration={1000}
				onClose={handleClose}
				message="Copied!"
				anchorOrigin={snackbarOrigin}
			/>
		</>
	);
}
