import React, { FC } from "react";
import * as Yup from "yup";
import PartnershipSelect from "./PartnershipSelect";
import PartnershipLabel from "./PartnershipLabel";
import { DatePicker } from "../../../components/Form/DatePicker";
import { Row } from "../../../components/UI/Row";
import CustomButton from "../../../components/CustomButton";
import { Form, FormContext } from "../../../components/Form";
import { Box, ButtonProps, SxProps } from "@mui/material";
import { useIsRtl } from "../../../utils/hooks/useIsRtl";
import classNames from "classnames";
import getDateWithOffset from "../../../utils/getDateWithOffset";
import memoize from "lodash/memoize";
import { IChildren, IMaybe } from "../../../types/common";
import { useAdaptive } from "../../../utils/hooks/themeBreakpointsHooks";

interface ButtonSchema {
	title: string;
	onClick?: () => void;
	color: ButtonProps["color"];
	type?: "submit" | "button" | "reset"
}

interface InputSchema {
	label: string;
	// name: string;
	type: "select" | "date",
	options?: string[]
}

interface ButtonSchemaMaybe {
	title?: string;
	onClick?: () => void;
	color?: ButtonProps["color"];
	type?: "submit" | "button" | "reset"
}

interface InputSchemaMaybe {
	label?: string;
	// name: string;
	type?: "select" | "date",
	options?: string[]
}

export interface PeriodFormSchema<Buttons = ButtonSchema, Input = InputSchema> {
	buttons: { [k: string]: Buttons };
	inputs: { [k: string]: Input }
}

export const DefaultPeriodSchema: PeriodFormSchema = {
	buttons: {
		apply: {
			title: "Apply",
			onClick: () => {},
			color: "success",
			type: "submit"
		},
		chart: {
			title: "Show chart",
			onClick: () => {},
			color: "primary"
		}
	},
	inputs: {
		period: {
			type: "select",
			label: "Choose Period:",
			options: [ "Day", "Week", "Month" ]
		},
		dateFrom: {
			label: "Date from",
			type: "date"
		},
		dateTo: {
			type: "date",
			label: "Date to"
		}
	}
};

export const composeWithDefaultPeriodSchema = memoize((schema: IMaybe<PeriodFormSchema<ButtonSchemaMaybe, InputSchemaMaybe>>): PeriodFormSchema => {
	function composeDeep (key: keyof PeriodFormSchema) {
		for (const subKey in schema[key]) {
			if (subKey in DefaultPeriodSchema[key]) {
				// @ts-ignore
				DefaultPeriodSchema[key][subKey] = {
					...DefaultPeriodSchema[key][subKey],
					...schema[key]![subKey]
				};

				delete schema[key]![subKey];
			}
		}
	}

	composeDeep("buttons");
	composeDeep("inputs");

	return {
		// @ts-ignore
		inputs: {
			...DefaultPeriodSchema.inputs,
			...schema.inputs
		},
		// @ts-ignore
		buttons: {
			...DefaultPeriodSchema.buttons,
			...schema.buttons
		}
	};
});

// composeWithDefaultPeriodSchema({ inputs: { name: { type: "date" } } });

interface IPeriodFormComponent {
	children?: IChildren
	onSubmit?: (values: any) => void
	schema?: PeriodFormSchema
	showChart?: () => void
	defaultValues?: { [p: string]: any }
}

const defaultPeriodFormValues = {
	dateFrom: getDateWithOffset(1), // yesterday
	dateTo: Date.now()
};

const withForm = (Component: FC<IPeriodFormComponent>) => ({ defaultValues = defaultPeriodFormValues, onSubmit = () => {}, ...props }: IPeriodFormComponent & {children?: any}) => {
	return <Form defaultValues={defaultValues} onSubmit={onSubmit} validationSchema={Yup.object()}><Component {...props} defaultValues={defaultValues}></Component></Form>;
};

const xsMainWrapperStyles: SxProps = {
	display: "flex",
	flexDirection: "column",
	marginBottom: "30px",
	gap: "20px"
};

const smMainWrapperStyles: SxProps = {
	flexDirection: "row",
	alignItems: "center",
	marginBottom: "0px"
};

const xsInputWrapperStyles: SxProps = {
	width: "100%"
};

const smInputWrapperStyles: SxProps = {
	maxWidth: "252px"
};

const xsButtonWrapperStyles: SxProps = {
	width: "100%",
	gap: "10px"
};

const smButtonWrapperStyles: SxProps = {
	maxWidth: "250px"
};

const PeriodForm: FC<IPeriodFormComponent> = ({ children, schema = DefaultPeriodSchema, defaultValues = defaultPeriodFormValues }) => {
	const { buttons, inputs } = schema;

	const buttonKeys = Object.keys(buttons);

	const Wrapper = buttonKeys.length > 1 ? Row : Box; // React.Fragment;
	const isRtl = useIsRtl();

	const { setValue } = React.useContext(FormContext);

	function onPeriodChange (e: React.ChangeEvent<HTMLSelectElement>) {
		setValue("client", e.target.value);
	}

	React.useEffect(() => {
		setValue("dateFrom", defaultValues.dateFrom);
		setValue("dateTo", defaultValues.dateTo);
	}, []);

	const adaptiveMainWrapperStyles = useAdaptive({
		xs: xsMainWrapperStyles,
		sm: smMainWrapperStyles
	});

	const adaptiveInputWrapperStyles = useAdaptive({
		xs: xsInputWrapperStyles,
		sm: smInputWrapperStyles
	});

	const adaptiveButtonWrapperStyles = useAdaptive({
		xs: xsButtonWrapperStyles,
		sm: smButtonWrapperStyles
	});

	return (
		<>
			<Box
				sx={adaptiveMainWrapperStyles}
				className={classNames("commission-row", { rtl: isRtl })}
			>

				{Object.values(inputs).map(({ label, type, options }, index) => {
					const name = Object.keys(inputs)[index];
					return type === "select"
						? <PartnershipSelect
							key={name}
							{...{ name, label, type }}
							onChange={onPeriodChange}
							options={options!}
						/>
						: <Box key={name} sx={adaptiveInputWrapperStyles}>
							<PartnershipLabel>{label}</PartnershipLabel>
							<DatePicker
								name={name}
								label=""

							/>
						</Box>;
				})
				}

				<Wrapper sx={adaptiveButtonWrapperStyles}>
					{Object.values(buttons).map(({ title, onClick, color, type }, index) => {
						const name = buttonKeys[index];
						return <CustomButton
							key={name}
							text={title}
							onClick={onClick ?? (() => {})}
							color={color!}
							sx={{ width: "100%" }}
							type={type ?? "button"}
						/>;
					})}
				</Wrapper>
			</Box>

			{children}
		</>
	);
};

export default withForm(PeriodForm);
