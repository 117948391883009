import React from "react";

const CopyTradingIcon = (props:any) => {
	let width = 16;
	let fill = "#417cbf";
	if (props.width) {
		width = props.width;
	}
	if (props.fill) {
		fill = props.fill;
	}

	return (
		<svg width={width} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 16">
			<defs>
				<clipPath id="clipPath">
					<rect id="Прямоугольник_80" data-name="Прямоугольник 80" width="16" height="16" transform="translate(20 22)" fill="#fff"/>
				</clipPath>
			</defs>
			<g id="Группа_масок_30" data-name="Группа масок 30" transform="translate(-20 -22)" clipPath="url(#clipPath)">
				<g id="icon-fm-copytrading" transform="translate(19.692 21.801)">
					<path id="Контур_172" data-name="Контур 172" d="M7.756,2.973a1.5,1.5,0,0,0,.552.1h0A1.439,1.439,0,0,0,9.5,2.453a1.455,1.455,0,0,0,.148-1.368A1.336,1.336,0,0,0,8.86.308a1.5,1.5,0,0,0-.552-.1A1.439,1.439,0,0,0,7.12.828,1.455,1.455,0,0,0,6.973,2.2,1.372,1.372,0,0,0,7.756,2.973Zm-.372-1.74A.921.921,0,0,1,7.9.713a1.129,1.129,0,0,1,.4-.077,1,1,0,0,1,.828.443,1.036,1.036,0,0,1,.1.97.921.921,0,0,1-.52.52,1.129,1.129,0,0,1-.4.077A1,1,0,0,1,7.48,2.2,1.036,1.036,0,0,1,7.384,1.233Z" fill={fill}/>
					<path id="Контур_173" data-name="Контур 173" d="M6.369,6.048H7.961c.006-.006.006-.006.006-.013L7.5,3.146a.006.006,0,0,0-.006-.006H7.435A1.159,1.159,0,0,0,6.279,4.3V5.958A.088.088,0,0,0,6.369,6.048Z" fill={fill}/>
					<path id="Контур_174" data-name="Контур 174" d="M9.117,3.14h0c-.006.006-.013.006-.013.013L8.642,6.035c.006.006.006.006.006.013h1.605a.085.085,0,0,0,.083-.083V4.3A1.159,1.159,0,0,0,9.181,3.14Z" fill={fill}/>
					<path id="Контур_175" data-name="Контур 175" d="M8.591,3.429c.006-.006,0-.013-.006-.013a1.3,1.3,0,0,1-.276.032,1.129,1.129,0,0,1-.3-.039c-.006,0-.013.006-.006.013L8.18,3.6V3.6l-.1,2.144v.006l.218.218c.006.006.006.006.013,0l.218-.218V5.746L8.424,3.6V3.6Z" fill={fill}/>
					<path id="Контур_176" data-name="Контур 176" d="M1.778,13.111a1.5,1.5,0,0,0,.552.1h0a1.439,1.439,0,0,0,1.188-.623,1.455,1.455,0,0,0,.148-1.368,1.336,1.336,0,0,0-.783-.777,1.5,1.5,0,0,0-.552-.1,1.439,1.439,0,0,0-1.188.623A1.455,1.455,0,0,0,1,12.334,1.372,1.372,0,0,0,1.778,13.111Zm-.366-1.734a.921.921,0,0,1,.52-.52,1.129,1.129,0,0,1,.4-.077,1,1,0,0,1,.828.443,1.036,1.036,0,0,1,.1.97.921.921,0,0,1-.52.52,1.129,1.129,0,0,1-.4.077,1,1,0,0,1-.828-.443A1.02,1.02,0,0,1,1.413,11.377Z" fill={fill}/>
					<path id="Контур_177" data-name="Контур 177" d="M1.522,13.284H1.464A1.159,1.159,0,0,0,.308,14.44V16.1a.085.085,0,0,0,.083.083H1.984c.006-.006.006-.006.006-.013l-.469-2.889Z" fill={fill}/>
					<path id="Контур_178" data-name="Контур 178" d="M3.2,13.284H3.14c-.006.006-.013.006-.013.013L2.665,16.18c.006.006.006.006.006.013H4.276a.085.085,0,0,0,.083-.083V14.446A1.148,1.148,0,0,0,3.2,13.284Z" fill={fill}/>
					<path id="Контур_179" data-name="Контур 179" d="M2.613,13.554a1.3,1.3,0,0,1-.276.032,1.129,1.129,0,0,1-.3-.039c-.006,0-.013.006-.006.013l.173.173v.006l-.1,2.144v.006l.218.218c.006.006.006.006.013,0l.218-.218v-.006l-.1-2.144v-.006l.167-.167C2.626,13.567,2.62,13.554,2.613,13.554Z" fill={fill}/>
					<path id="Контур_180" data-name="Контур 180" d="M13.1,10.966a1.455,1.455,0,0,0-.148,1.368,1.372,1.372,0,0,0,.777.783,1.5,1.5,0,0,0,.552.1h0a1.439,1.439,0,0,0,1.188-.623,1.455,1.455,0,0,0,.148-1.368,1.336,1.336,0,0,0-.783-.777,1.5,1.5,0,0,0-.552-.1A1.4,1.4,0,0,0,13.1,10.966Zm2.01.25a1.036,1.036,0,0,1,.1.97.921.921,0,0,1-.52.52,1.129,1.129,0,0,1-.4.077,1,1,0,0,1-.828-.443,1.036,1.036,0,0,1-.1-.97.921.921,0,0,1,.52-.52,1.129,1.129,0,0,1,.4-.077A1,1,0,0,1,15.108,11.217Z" fill={fill}/>
					<path id="Контур_181" data-name="Контур 181" d="M13.464,13.284h-.058A1.159,1.159,0,0,0,12.25,14.44V16.1a.085.085,0,0,0,.083.083h1.6c.006-.006.006-.006.006-.013l-.469-2.889Z" fill={fill}/>
					<path id="Контур_182" data-name="Контур 182" d="M15.152,13.284h-.064c-.006.006-.013.006-.013.013l-.462,2.883c.006.006.006.006.006.013h1.605a.085.085,0,0,0,.083-.083V14.446A1.156,1.156,0,0,0,15.152,13.284Z" fill={fill}/>
					<path id="Контур_183" data-name="Контур 183" d="M14.555,13.554a1.3,1.3,0,0,1-.276.032,1.129,1.129,0,0,1-.3-.039c-.006,0-.013.006-.006.013l.173.173v.006l-.1,2.144v.006l.218.218c.006.006.006.006.013,0l.218-.218v-.006l-.1-2.144v-.006l.167-.167C14.568,13.567,14.568,13.554,14.555,13.554Z" fill={fill}/>
					<path id="Контур_184" data-name="Контур 184" d="M2.761,8.687H13.849V9.868h.867V8.25a.43.43,0,0,0-.43-.43H8.719V6.632H7.852V7.82H2.331a.43.43,0,0,0-.43.43V9.868h.86V8.687Z" fill={fill}/>
				</g>
			</g>
		</svg>
	);
};

export default CopyTradingIcon;
