import * as React from "react";
import { Form } from "../../../../components/Form";
import { Input } from "../../../../components/Form/Input";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { localBankWithdrawValidationSchema } from "./validation";
import { FormBottomWrapper } from "../../../../components/Form/FormBottomWrapper";
import { getWithdrawalValue } from "../../../../utils/getReceivedValue";
import { ButtonWithLoader } from "../../../../components/ButtonWithLoader";
import { InputRepeater } from "../../../../components/Form/InputRepeater";
import { CurrenciesSymbols, CurrencyType } from "../../../../utils/currency";
import { Alert, Box, Skeleton, Snackbar } from "@mui/material";
import { WithdrawalTypes } from "../../../../types/WithdrawalTypes";
import API from "../../../../utils/API";
import { AxiosResponse } from "axios";
import { useErrorHandling } from "../../../../utils/hooks/useErrorHandling";
import { fetchLocalBankInfo } from "../../../../store/actions/actionTypes";
import { WaningNote } from "../../../../components/UI/Note";

export function LocalBank () {
	const [ success, setSuccess ] = React.useState(false);
	const [ isFetch, setIsFetch ] = React.useState(false);
	const language = useAppSelector((state) => state.language);
	const accountNumber = useAppSelector((state) => `${state.session.userData.account_number ?? ""}`);
	const currency = useAppSelector((state) => state.session.userData.currency ?? "USD");
	const { isOpen, errorMsg, hideError, showError } = useErrorHandling();
	const {
		fee,
		addon,
		feeDetails,
		loading: infoLoading,
		complete
	} = useAppSelector((state) => state.withdrawMethodsInfo.localBank);
	const dispatch = useAppDispatch();

	React.useEffect(() => {
		if (!complete) {
			dispatch(fetchLocalBankInfo({}));
		}
	}, []);

	const getWithdrawalValueCB = React.useCallback((values: any) => getWithdrawalValue(values, currency), [ currency ]);

	const calcFee = React.useCallback((values: [string, CurrencyType]) => {
		const [ amount, currencyType ] = values;
		return `${(+amount * fee + addon).toFixed(2)}${CurrenciesSymbols[currencyType] || CurrenciesSymbols[currency]}`;
	}, []);

	async function submit (values: {
		bankAccountNumber: string,
		amount: string,
	}) {
		try {
			setIsFetch(true);
			const formData = new FormData();
			formData.append("bank_account_number", values.bankAccountNumber);
			formData.append("withdrawal_type", WithdrawalTypes.THUNDER_XPAY);
			formData.append("withdraw_amount", values.amount);

			const { data } = await API.post<unknown, AxiosResponse<{ success: boolean, error: string }>>("withdrawLocalBank", formData);
			if (!data.success) {
				showError(data.error || "Something went wrong");
			} else {
				setSuccess(true);
			}
		} catch (e) {
			// handle error
		} finally {
			setIsFetch(false);
		}
	}

	return (
		<Form
			onSubmit={submit}
			validationSchema={localBankWithdrawValidationSchema(language.languageFinance)}
			defaultValues={{
				accountNumber,
				amount: "",
				bankAccountNumber: ""
			}}
		>
			<Input
				name="accountNumber"
				label={language.languageCopytrade.cpy_mon_accnum}
				variant="rounded"
			/>
			<Input
				name="amount"
				label={language.languageFinance.trd_230}
				variant="rounded"
			/>
			<InputRepeater
				fields="amount,currency"
				label={language.languageFinance["with-p-04"]}
				onChange={calcFee}
			/>
			<Input
				name="bankAccountNumber"
				label={language.languageFinance.wunip_06}
				variant="rounded"
			/>
			<FormBottomWrapper
				forFields={[ "amount", "currency" ]}
				onChange={getWithdrawalValueCB}
				label={language.languageMyAccounts.form_message_01}
			>
				<ButtonWithLoader
					isFetch={isFetch}
					color={"primary"}
					text={language.languageMyAccounts.chapas_04}
				/>
			</FormBottomWrapper>
			<Box sx={{ marginTop: "6px" }}>
				{
					infoLoading
						? (
							<Skeleton width={"220px"} height={"26px"} />
						)
						: feeDetails
							? (
								<WaningNote
									message={`Fee is ${feeDetails}`}
								/>
							)
							: <></>
				}
			</Box>
			<Snackbar
				open={success}
				autoHideDuration={4000}
				onClose={() => setSuccess(false)}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
			>
				<Alert variant="filled" color="success">
					Success
				</Alert>
			</Snackbar>
			<Snackbar open={isOpen} autoHideDuration={4000} onClose={hideError}>
				<Alert variant="filled" color="error">
					{errorMsg}
				</Alert>
			</Snackbar>
		</Form>
	);
}
