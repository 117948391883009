import React from "react";

export default function ClassicIcon () {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="21.098" height="26.58" viewBox="0 0 21.098 26.58">
			<g transform="translate(-27.463 -25.904)">
				<path d="M32.125,35.391H30.839V31.93H29.547v3.461H28.262a.6.6,0,0,0-.646.646v12.51a.6.6,0,0,0,.646.646h1.285v3.138h1.292V49.194h1.286a.648.648,0,0,0,.646-.646V36.037A.647.647,0,0,0,32.125,35.391ZM31.479,47.9H28.907V36.683h2.572Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.305"/>
				<path d="M39.944,29.044H38.658V26.057H37.366v2.987H36.081a.647.647,0,0,0-.646.646v12.6a.647.647,0,0,0,.646.646h1.286v3.149h1.292V42.939h1.286a.72.72,0,0,0,.646-.646V29.69A.72.72,0,0,0,39.944,29.044Zm-.646,12.6H36.727V30.336H39.3Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.305"/>
				<path d="M47.763,35.391H46.478V31.93H45.185v3.461H43.9a.6.6,0,0,0-.646.646v12.51a.6.6,0,0,0,.646.646h1.286v3.138h1.292V49.194h1.286a.647.647,0,0,0,.646-.646V36.037A.647.647,0,0,0,47.763,35.391ZM47.117,47.9H44.545V36.683h2.572Z" fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.305"/>
			</g>
		</svg>
	);
};
