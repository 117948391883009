import * as React from "react";
import TableBody from "@mui/material/TableBody";
import { useState, useEffect } from "react";
import { getComparator, IOrder, stableSort } from "./sortFMTable";
import FMTableCollapsibleRow from "./FMTableCollapsibleRow";
import FMTableRow from "./FMTableRow";
import { IFMTableMobileScheme } from "./mobileFMTable";
import { IFMTableRowObj } from "./formatDataFMTable";

interface IFMTableBody<T, M> {
	rows: Array<IFMTableRowObj<T, M>>
	order: IOrder
	orderBy: keyof T | string
	page: number
	componentData?: M
	rowsPerPage: number
	mobileScheme?: IFMTableMobileScheme
	mobileAutoSlice?: number
	breakpoint: number,
	rowWrapper?: React.FC,
}

export default function FMTableBody<T, M> (props: IFMTableBody<T, M>) {
	const { rows, order, orderBy, page, componentData, rowsPerPage, mobileScheme, mobileAutoSlice, breakpoint, rowWrapper = React.Fragment } = props;
	const RowWrapper = React.useMemo(() => rowWrapper, [ rowWrapper ]);
	const [ open, setOpen ] = useState(Array(rowsPerPage).fill(0));
	const toggleRowSetOpen = (rowIndex: number) => {
		const rowOpen = open[rowIndex];
		const openArray = Array(rowsPerPage).fill(0);
		if (rowOpen) {
			setOpen(openArray);
		} else {
			openArray[rowIndex] = 1;
			setOpen(openArray);
		};
	};

	const closeRowOpen = () => {
		const openArray = Array(rowsPerPage).fill(0);
		setOpen(openArray);
	};

	useEffect(() => {
		closeRowOpen();
	}, [ order, orderBy ]);

	/* if you don't need to support IE11, you can replace the `stableSort`
			 call with: rows.slice().sort(getComparator(order, orderBy)) */
	const sortRows = stableSort<IFMTableRowObj<T, M>>(rows as any, getComparator(order, orderBy));
	const sortRowsSliced = sortRows
		.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
	const newRows = sortRowsSliced;

	return (
		<TableBody>
			{ newRows.map((row: IFMTableRowObj<T, M>, index) => {
				return (
					<React.Fragment key={"rowWrap-" + index}>
						<RowWrapper>
							<FMTableRow
								key={"row-" + index}
								row={row}
								page={page}
								componentData={componentData}
								index={index}
								toggle={toggleRowSetOpen}
								open={open}
								mobileScheme={mobileScheme}
								mobileAutoSlice={mobileAutoSlice}
								breakpoint={breakpoint}
							/>
							{ row[Object.keys(row)[0]].collapsibleComponent || mobileScheme?.mode
								? <FMTableCollapsibleRow
									key={"subRow" + index}
									index={index}
									open={open}
									row={row}
									page={page}
									componentData={componentData}
									mobileScheme={mobileScheme}
									mobileAutoSlice={mobileAutoSlice}
									breakpoint={breakpoint}
								/>
								: null
							}
						</RowWrapper>
					</React.Fragment>
				);
			})}
		</TableBody>
	);
};
