/*
    Created on : 20.01.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import "./PageRegistration.scss";

function PageRegistration () {
	return (
		<div className="page-registration">
			<h1>Page Registration</h1>
		</div>
	);
}

export default PageRegistration;
