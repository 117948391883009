import * as React from "react";
import { Box, Portal } from "@mui/material";
import { Redirect, Route } from "react-router-dom";
import { FormNavigation } from "../../../../components/FormNavigation";
import { PayTrust } from "./PayTrust";
import { ZotaPay } from "./ZotaPay";
import { AsianBank } from "./AsianBank";

export interface LocalBankTransferInMalaysiaProps {
	tabsMenuContainer: React.MutableRefObject<HTMLElement | null>
}
export function LocalBankTransferInMalaysia (props: LocalBankTransferInMalaysiaProps) {
	const { tabsMenuContainer } = props;

	return (
		<>
			<Portal container={tabsMenuContainer.current}>
				<Box sx={{ paddingTop: "20px" }}>
					<FormNavigation
						paths={[
							{
								to: "/app/deposit/myr-local-bank-transfer/pay-trust",
								title: "PayTrust",
								styles: {
									marginRight: "15px"
								}
							},
							{
								to: "/app/deposit/myr-local-bank-transfer/zota-pay",
								title: "ZotaPay",
								styles: {
									marginRight: "15px"
								}
							},
							{
								to: "/app/deposit/myr-local-bank-transfer/asian-bank",
								title: "Asian Bank"
							}
						]}
					/>
				</Box>
			</Portal>

			<Route exact path="/app/deposit/myr-local-bank-transfer">
				<Redirect to="/app/deposit/myr-local-bank-transfer/pay-trust" />
			</Route>
			<Route
				path="/app/deposit/myr-local-bank-transfer/pay-trust"
			>
				<PayTrust />
			</Route>
			<Route
				path="/app/deposit/myr-local-bank-transfer/zota-pay"
			>
				<ZotaPay />
			</Route>
			<Route
				path="/app/deposit/myr-local-bank-transfer/asian-bank"
			>
				<AsianBank />
			</Route>
		</>
	);
}
