import * as React from "react";
import styled from "styled-components";
import { useIsRtl } from "../../../utils/hooks/useIsRtl";

export interface OptionProps {
	value: string | number;
	children?: React.ReactNode;
}

export function Option (props: OptionProps): JSX.Element {
	const { value, children } = props;
	const isRtl = useIsRtl();

	return (
		<OptionUI
			isRtl={isRtl}
			value={value}
		>
			{children}
		</OptionUI>
	);
}

export const OptionUI = styled.option<{ isRtl: boolean, children?: any, value?: any, label?: string }>`
  text-align: ${({ isRtl }) => isRtl ? "right" : "left"};
`;
