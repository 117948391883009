import { EmptyForm } from "./EmptyForm";

export function getEmptyForm (action: string, method: "POST" | "GET" = "POST") {
	return function (props: {refValue: React.MutableRefObject<HTMLFormElement | null>, params: { [key: string]: string | number }}) {
		return (
			// eslint-disable-next-line react/react-in-jsx-scope
			<EmptyForm
				refValue={props.refValue}
				action={action}
				method={method}
				params={props.params}
			/>
		);
	};
}
