import * as React from "react";
import { Alert, Collapse, Link, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../../interfaces/StoreInterfaces";
import { FMAlert } from "../../../../components/Form/Swal/FMAlert";

export type ICollapseDocAlert = {
	fileHashName?: string
	decline?: string
}

export function CollapseDocAlert (props: ICollapseDocAlert) {
	const { fileHashName, decline } = props;
	const init:any = useSelector((state:StoreInterface) => state.init.init);
	const lang:any = useSelector((state:StoreInterface) => state.language.languageMyAccounts);
	const userDocsUrl = init.links.main_site_user_docs;
	const docHref = userDocsUrl + fileHashName;

	async function declinedInfo () {
		await FMAlert.fire({
			html: decline,
			color: "#ED1F24"
		});
	};

	return (
		<Collapse in={!!fileHashName} sx={{ width: "100%" }}>
			<Alert
				icon={false}
				sx={{
					border: "1px solid #8AC296",
					color: "#707070",
					width: "100%",
					padding: "8px 16px",
					marginBottom: "16px"
				}}
			>
				<Typography textAlign="left">
					The document has already been uploaded.
					{decline && <span onClick={declinedInfo} style={{ color: "#ED1F24", cursor: "pointer" }}> [{lang["txt-declined"]}]</span>}
					<Link href={docHref} target="_blank"> {lang.pro_up_doc_4}</Link>
				</Typography>

				<Typography textAlign="left">
					Click choose file to upload again.
				</Typography>
			</Alert>
		</Collapse>
	);
}
