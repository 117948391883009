import { CryptoCurrencyInfoType } from "../../pages/Finance/Deposit/types";
import { ActionType } from "../../utils/reudxHelpers";
import {
	SET_BITCOIN_CASH_STATUS_INFO,
	SET_BITCOIN_STATUS_INFO,
	SET_TETHER_STATUS_INFO,
	UPDATE_BITCOIN_CASH_STATUS,
	UPDATE_BITCOIN_STATUS, UPDATE_TETHER_STATUS
} from "../actions/actionTypes";

const initialState = {
	bitcoin: {
		amount: "",
		orderId: "",
		createDate: "",
		status: -1,
		qrSrc: "",
		address: ""
	},
	bitcoinCash: {
		amount: "",
		orderId: "",
		createDate: "",
		status: -1,
		qrSrc: "",
		address: ""
	},
	tether: {
		amount: "",
		orderId: "",
		createDate: "",
		status: -1,
		qrSrc: "",
		address: ""
	}
};

type Actions = ActionType<"SET_BITCOIN_STATUS_INFO", CryptoCurrencyInfoType & { amount: string }>
| ActionType<"SET_BITCOIN_CASH_STATUS_INFO", CryptoCurrencyInfoType & { amount: string }>
| ActionType<"SET_TETHER_STATUS_INFO", {address: string, orderId: string, createDate: string, amount: string}>
| ActionType<"UPDATE_BITCOIN_STATUS", { status: number }>
| ActionType<"UPDATE_BITCOIN_CASH_STATUS", { status: number }>
| ActionType<"UPDATE_TETHER_STATUS", { status: number }>;

export function cryptoCurrencyStatusReducer (state = initialState, action: Actions): typeof initialState {
	switch (action.type) {
		case SET_BITCOIN_STATUS_INFO:
			return {
				...state,
				bitcoin: {
					amount: action.payload.amount,
					orderId: action.payload["cryptocurrency-status"].invData.order_id,
					createDate: action.payload["cryptocurrency-status"].invData.create_date,
					status: action.payload["cryptocurrency-status"].invData.status,
					qrSrc: action.payload.qr_src,
					address: action.payload["cryptocurrency-status"].invData.input_address
				}
			};
		case SET_BITCOIN_CASH_STATUS_INFO:
			return {
				...state,
				bitcoinCash: {
					amount: action.payload.amount,
					orderId: action.payload["cryptocurrency-status"].invData.order_id,
					createDate: action.payload["cryptocurrency-status"].invData.create_date,
					status: action.payload["cryptocurrency-status"].invData.status,
					qrSrc: action.payload.qr_src,
					address: action.payload["cryptocurrency-status"].invData.input_address
				}
			};
		case SET_TETHER_STATUS_INFO:
			return {
				...state,
				tether: {
					...state.tether,
					amount: action.payload.amount,
					address: action.payload.address,
					orderId: action.payload.orderId,
					createDate: action.payload.createDate,
					qrSrc: `https://apirone.com/api/v1/qr?message=Crypt-API%${action.payload.address}&format=png`
				}
			};
		case UPDATE_BITCOIN_STATUS:
			return {
				...state,
				bitcoin: {
					...state.bitcoin,
					status: action.payload.status
				}
			};
		case UPDATE_BITCOIN_CASH_STATUS:
			return {
				...state,
				bitcoinCash: {
					...state.bitcoinCash,
					status: action.payload.status
				}
			};
		case UPDATE_TETHER_STATUS:
			return {
				...state,
				tether: {
					...state.tether,
					status: action.payload.status
				}
			};
		default:
			return state;
	}
}
