import * as React from "react";
import { Typography } from "@mui/material";
import { useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { IFMTableComponentProps } from "../../../../components/FMTable/FMTableCellComponent";
import { Trader } from "../../../../interfaces/CopytradeInterfaces";

export function AccountProjectNameCellFollower (props: IFMTableComponentProps<Trader, {}>) {
	const { Follower, StartBalance } = props;
	const langCopytrade = useAppSelector((state) => state.language.languageCopytrade);

	return (
		<>
			<Typography>{Follower}</Typography>
			<Typography>{`${langCopytrade.copytrade_34} ${StartBalance}`}</Typography>
		</>
	);
};

export function AccountProjectNameCellTrader (props: IFMTableComponentProps<Trader, {}>) {
	const { Trader, StartBalance } = props;
	const langCopytrade = useAppSelector((state) => state.language.languageCopytrade);

	return (
		<>
			<Typography>{Trader}</Typography>
			<Typography>{`${langCopytrade.copytrade_34} ${StartBalance}`}</Typography>
		</>
	);
};
