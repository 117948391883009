import React from "react";
import { Box } from "@mui/material";
import * as yup from "yup";
import { Form } from "../../../../components/Form";
import FMTablePeriodSelect from "../../../../components/FMTable/FMTablePeriodSelect";
import { useAdaptive } from "../../../../utils/hooks/themeBreakpointsHooks";
import { ISetState } from "../../../../types/common";
import { TSetPeriod } from "../../../../components/FMTable/FMTablePeriodButtons";

const schema = yup.object().shape({
	period: yup.string(),
	dateFrom: yup.string(),
	dateTo: yup.string()
}).required();

interface IStatisticChartsPeriod {
	period: number
	setPeriod: TSetPeriod
	periodsNames: string[]
	dateFrom: any
	dateTo: any
	setDateFrom: ISetState
	setDateTo: ISetState
}

export default function StatisticChartsPeriod (props: IStatisticChartsPeriod) {
	const { period } = props;

	const now = new Date();
	const defaultValues = {
		period,
		dateFrom: now.setDate(now.getDate() - 1),
		dateTo: new Date()
	};

	const boxSX = useAdaptive({
		xs: {
			display: "block",
			"&>div:first-of-type": {
				display: "block"
			},
			"&>div>div": {
				display: "block",
				width: "100%",
				maxWidth: "100%",
				marginX: 0
			},
			"&>div>button": {
				flex: "1",
				mt: "2px",
				marginX: "7px"
			}
		},
		sm: {
			"&>div:first-of-type": {
				display: "flex"
			},
			"&>div>div": {
				marginX: "7px"
			}
		},
		md: {
			display: "flex",
			"&>div": {
				marginX: "7px",
				alignItems: "flex-end"
			},
			"&>div>button": {
				minWidth: "max-content",
				marginX: "4px"
			}
		}
	});

	return (
		<Form
			defaultValues={defaultValues}
			onSubmit={() => null}
			fieldWrapperVariant="for-table-date"
			validationSchema={schema}
		>
			<Box className="flexRow" sx={boxSX}>
				<FMTablePeriodSelect { ...props }/>
			</Box>
		</Form>
	);
};
