import { Box } from "@mui/material";
import * as React from "react";
import { Controller } from "react-hook-form";
import { FormContext } from "../Form";
import { TCheckboxVariant, Checkbox as CheckboxModule } from "../../UI/CheckBox";
import { Text } from "../../UI/Text";

export interface ICheckboxProps {
	name: string;
	label: string;
	variant?: TCheckboxVariant;
}

export function Checkbox (props: ICheckboxProps) {
	const { name, variant = "rounded", label } = props;
	const { control } = React.useContext(FormContext);

	const CheckboxComponent = React.useMemo(() => CheckboxModule[variant], [ variant ]);

	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { value, onChange }, fieldState: { error } }) => (
				<Box sx={{ position: "relative" }}>
					<CheckboxComponent
						label={label}
						name={name}
						onChange={onChange}
						value={value}
					/>
					<Text
						color="#ff0000"
						fontSize={12}
						sx={{
							position: "absolute",
							top: "100%",
							width: "100%"
						}}
					>
						{error?.message}
					</Text>
				</Box>
			)}
		/>
	);
}
