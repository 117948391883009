/*
    Created on : 08.02.2022, 06:18:59
    Author     : Andy Kar
*/

import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { subYears } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

function CustomDatePicker (props:any) {
	const [ startDate, setStartDate ] = useState(subYears(new Date(), 18));

	const handleChangeDate = (date:any) => {
		setStartDate(date);
		// const dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
		// const mm = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
		// const dateShort = dd + '-' + mm + '-' + date.getFullYear()
		// console.log(dateShort)
		// props.fetchCurrencies(dateShort)
	};

	useEffect(() => {
		if (props.dateStart) {
			handleChangeDate(new Date(props.dateStart));
		}
	}, [ props.dateStart ]);

	const availableDate = (
		// date:any
	) => {
		return true;
		// const available = props.available
		// const dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
		// const mm = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
		// const dateShort = dd + '/' + mm + '/' + date.getFullYear()
		//
		// if( available.includes(dateShort) ){ return true }
		// return false
	};

	const ref = React.createRef<HTMLButtonElement>();

	const DeliveryDateInput = React.forwardRef<HTMLButtonElement>((props: any, _ref) => {
		const day = props.value.split("-")[2];
		const month = props.value.split("-")[1];
		const year = props.value.split("-")[0];
		// const weekday = props.value.split(' ')[3]

		const currentDate = year + "-" + month + "-" + day;

		return (
			<button
				type="button"
				onClick={ props.onClick }
				ref={_ref}
				className="date-btn form-control form-horizontal-input"
				style={{ position: "relative" }}
			>
				<input
					name={props.name}
					className="form-control"
					defaultValue={currentDate}
				/>

				<svg className="delivery-date-icon" width="18" height="20" viewBox="0 0 18 20" xmlns="http://www.w3.org/2000/svg">
					<path d="M14 11H9V16H14V11ZM13 0V2H5V0H3V2H2C0.89 2 0.00999999 2.9 0.00999999 4L0 18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2H15V0H13ZM16 18H2V7H16V18Z" fill="#5C7080"/>
				</svg>
			</button>
		);
	});

	return (
		<DatePicker
			showMonthDropdown
			showYearDropdown
			dropdownMode="select"
			selected={ startDate }
			onChange={handleChangeDate}
			// locale="ru"
			dateFormat="yyyy-MM-dd"
			minDate={props.minDate ? new Date(props.minDate) : subYears(new Date(), 100)}
			maxDate={props.maxDate ? new Date(props.maxDate) : subYears(new Date(), 18)}
			filterDate={ availableDate }
			name={props.input_name}
			customInput={<DeliveryDateInput ref={ref} />}
			popperClassName="some-custom-class"
			dayClassName={(date) => availableDate() && date !== startDate ? "available" : null}
			popperPlacement={"top-start"}
			shouldCloseOnSelect={true}
			className={props.className}
		/>
	);
}

export default CustomDatePicker;
