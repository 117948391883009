/*
    Created on : 14.09.2022, 06:18:59
    Author     : Andy Kar
*/
import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import AccountTypes from "../components/AccountTypes/AccountTypes";
import PagePromotionalMaterialsBanners
	from "../../pages/PromotionalMaterials/PagePromotionalMaterialsBanners/PagePromotionalMaterialsBanners";
import PagePromotionalMaterialsLogo
	from "../../pages/PromotionalMaterials/PagePromotionalMaterialsLogo/PagePromotionalMaterialsLogo";
import { useDispatch, useSelector } from "react-redux";
import { StoreInterface } from "../../interfaces/StoreInterfaces";
import { FETCH_BANNERS } from "../../store/actions/actionTypes";
import { IAffiliateCode } from "../../store/reducers/bannersReducer";
import { ISetState } from "../../types/common";

export type PagePromotionalMaterials = {
	affiliateCode: string
	setAffiliateCode: ISetState
	affiliateCodes: Array<IAffiliateCode>
}

const LayoutPromotionalMaterials = (props:any) => {
	const bannersTableDataLength:number = useSelector((state:StoreInterface) => state.banners.banners.bannerList.length);
	const affiliateCodes: Array<IAffiliateCode> = useSelector((state:StoreInterface) => state.banners.banners.affiliate_code);
	const [ affiliateCode, setAffiliateCode ] = useState("");

	useEffect(() => {
		if (affiliateCodes?.length) setAffiliateCode(affiliateCodes[0].affiliate_code);
	}, [ affiliateCodes ]);

	const dispatch = useDispatch();
	useEffect(() => {
		if (!bannersTableDataLength) dispatch({ type: FETCH_BANNERS });
	}, [ bannersTableDataLength ]);

	return (
		<>
			<Switch>
				<Route exact path="/app/promotional-materials/banners" render={() => <PagePromotionalMaterialsBanners
					{...props}
					affiliateCode={affiliateCode}
					setAffiliateCode={setAffiliateCode}
					affiliateCodes={affiliateCodes}
				/>} />
				<Route exact path="/app/promotional-materials/forexmart-logo" render={() => <PagePromotionalMaterialsLogo
					{...props}
					affiliateCode={affiliateCode}
					setAffiliateCode={setAffiliateCode}
					affiliateCodes={affiliateCodes}
				/>} />
				<Redirect exact from="/app/rebate" to="/app/promotional-materials/banners" />
			</Switch>
			<AccountTypes/>
		</>
	);
};

export default withRouter(LayoutPromotionalMaterials);
