/*
    Created on : 10.08.2021, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import authClientDesktopBackgroundImg from "../../images/bg-client-signin.jpg";
import { Box } from "@mui/material";
import { IChildren } from "../../types/common";

export default function DashboardAuthContent ({ children }: { children: IChildren}) {
	const bgImg = authClientDesktopBackgroundImg;
	const authDesktopStyle = {
		backgroundImage: bgImg ? "url(" + bgImg + ")" : "none",
		backgroundSize: "cover",
		backgroundRepeat: "none",
		backgroundPosition: "center center",
		backgroundAttachment: "fixed",
		color: "white",
		flex: 1
	};
	return (
		<Box id="content" sx={authDesktopStyle}>
			<Box id="main-content" className="main-content">
				{children}
			</Box>
		</Box>
	);
};
