/*
    Created on : 20.01.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../interfaces/StoreInterfaces";
import PageTitle from "../../../components/Dashboard/dashboardComponents/PageTitle";
import { Typography } from "@mui/material";
import { useIsRtl } from "../../../utils/hooks/useIsRtl";
import TradesTable from "./components/TradesTable";

export default function PageCurrentTrades () {
	const lang:any = useSelector((state:StoreInterface) => state.language.language);
	const langTrades:any = useSelector((state:StoreInterface) => state.language.languageTrades);
	const langMyAccounts:any = useSelector((state:StoreInterface) => state.language.languageMyAccounts);
	const isRtl = useIsRtl();
	return (
		<div className="page-current-trades">
			<PageTitle
				layoutTitle={lang.curtra_03}
				pageTitle={langMyAccounts.man_01}
			/>
			<Typography
				sx={{
					fontSize: "18px",
					fontWeight: 200,
					mb: "22px",
					lineHeight: "22px",
					textAlign: !isRtl ? "left" : "right"
				}}
			>{langTrades.curtra_curtrd_010}</Typography>
			<TradesTable/>
		</div>
	);
};
