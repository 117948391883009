import * as React from "react";
import DashboardSidebarMenu from "./dashboardComponents/DashboardSidebarMenu";
import { useIsRtl } from "../../utils/hooks/useIsRtl";
import { Box, Drawer } from "@mui/material";

export default function DashboardSidebarMobile (props: any) {
	const { open, setOpen } = props;
	const isRtl = useIsRtl();
	const anchor = isRtl ? "right" : "left";
	return (
		<>
			<Drawer
				anchor={anchor}
				open={open}
				onClose={() => setOpen(!open)}
				sx={{
					"& .MuiPaper-root": {
						backgroundColor: "#E2E7E8"
					},
					"& a": {
						textDecoration: "none"
					}
				}}
			>
				<Box>
					<DashboardSidebarMenu {... props} />
				</Box>
			</Drawer>
		</>
	);
}
