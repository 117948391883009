import * as React from "react";
import { Box, Portal } from "@mui/material";
import { FormNavigation } from "../../../../components/FormNavigation";
import { Redirect, Route } from "react-router-dom";
import { ZotaPay } from "./ZotaPay";

export interface LocalBankTransferThailandProps {
	tabsMenuContainer: React.MutableRefObject<HTMLElement | null>
}
export function LocalBankTransferThailand (props: LocalBankTransferThailandProps) {
	const { tabsMenuContainer } = props;
	return (
		<>
			<Portal container={tabsMenuContainer.current}>
				<Box sx={{ paddingTop: "20px" }}>
					<FormNavigation
						paths={[
							{
								to: "/app/deposit/thb-local-bank-transfer/zota-pay",
								title: "ZotaPay"
							}
						]}
					/>
				</Box>
			</Portal>
			<Route exact path="/app/deposit/thb-local-bank-transfer">
				<Redirect to="/app/deposit/thb-local-bank-transfer/zota-pay" />
			</Route>
			<Route
				path="/app/deposit/thb-local-bank-transfer/zota-pay"
			>
				<ZotaPay />
			</Route>
		</>
	);
}
