import * as React from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import { FieldWrapper as FieldWrapperUI } from "../../UI/FieldWrapper";
import { FormContext } from "../Form";
import { Text } from "../../UI/Text";

export interface SelectProps {
	name: string;
	label: string;
	tips?: string;
	children?: React.ReactNode;
	onChange?: React.ChangeEventHandler<HTMLSelectElement>
	extValue?: string
}

export function Select (props: SelectProps): JSX.Element {
	const { name, label, children, onChange: onChangeNative, extValue, tips } = props;
	const { control, fieldWrapperVariant } = React.useContext(FormContext);
	const FieldWrapper = React.useMemo(() => FieldWrapperUI[fieldWrapperVariant], [ fieldWrapperVariant ]);

	return (
		<Controller
			control={control}
			name={name}
			render={
				({ field: { value, onChange }, fieldState: { error } }) => (
					<FieldWrapper label={label} tips={tips}>
						<SelectUI
							value={extValue || value}
							iserror={!!error}
							onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
								if (onChangeNative) {
									onChangeNative(e);
								}
								onChange(e);
							}}
						>
							{children}
						</SelectUI>
						<Text
							color="#ff0000"
							fontSize={12}
							sx={{
								position: "absolute",
								top: "100%",
								width: "100%"
							}}
						>
							{error?.message}
						</Text>
					</FieldWrapper>
				)
			}
		/>
	);
}

export const SelectUI = styled.select<{
	readonly iserror?: boolean,
	children?: any,
	defaultValue?: any,
	onChange?: any,
	value?: any
}>`
  width: 100%;
  padding: 10px;
  border: 1px solid;
  border-color: ${({ iserror }) => iserror ? "#ff0000" : "#7A8B98"};
  border-radius: 4px !important;
  appearance:none;
  color: #92979D;
  background: #fff;

  &:focus {
    color: #7A8B98;
  }`;
