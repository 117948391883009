import { call, put, takeEvery } from "redux-saga/effects";
import * as actions from "../store/actions/actionTypes";
import API from "../utils/API";
import { AxiosResponse } from "axios";
import { MobileMoneyNames, TMobileMoneyNames } from "../store/reducers/withdrawMethodsInfoReducer";

const {
	FETCH_ASIAN_BANKS_INFO,
	FETCH_BITCOIN_CASH_INFO,
	FETCH_BITCOIN_INFO,
	FETCH_IDR_PAY_TRUST_INFO,
	FETCH_IDR_ZOTA_PAY_INFO,
	FETCH_MYR_PAY_TRUST_INFO,
	FETCH_MYR_ZOTA_PAY_INFO,
	FETCH_THB_PAY_TRUST_INFO,
	FETCH_THB_ZOTA_PAY_INFO,
	FETCH_BRAZIL_PIX_INFO,
	FETCH_ONLINE_NAIRA_INFO,
	FETCH_CHINA_UNION_PAY_INFO,
	FETCH_FASAPAY_INFO,
	FETCH_LOCAL_BANK_INFO,
	FETCH_LOCAL_BANK_TRANSFER_MEXICO_INFO,
	FETCH_NETELLER_INFO,
	FETCH_PAYCO_INFO,
	FETCH_SKRILL_INFO,
	FETCH_THUNDER_X_PAY_INFO,
	FETCH_VND_ZOTA_PAY_INFO,
	FETCH_VND_PAY_TRUST_INFO,
	FETCH_VN_PAY_INFO,
	FETCH_TETHER_INFO,
	setTetherInfo,
	setTetherInfoLoading,
	setVnPayInfo,
	setVnPayInfoLoading,
	setVndPayTrustInfo,
	setVndPayTrustInfoLoading,
	setVndZotaPayInfo,
	setVndZotaPayInfoLoading,
	setThunderXPayInfo,
	setThunderXPayInfoLoading,
	setSkrillInfo,
	setSkrillInfoLoading,
	setPaycoInfo,
	setPaycoInfoLoading,
	setNetellerInfo,
	setNetellerInfoLoading,
	setLocalBankTransferMexicoInfo,
	setLocalBankTransferMexicoInfoLoading,
	setLocalBankInfo,
	setLocalBankInfoLoading,
	setFasapayInfo,
	setFasapayInfoLoading,
	setChinaUnionPayInfo,
	setChinaUnionPayInfoLoading,
	setOnlineNairaInfo,
	setOnlineNairaInfoLoading,
	setBrazilPixInfo,
	setBrazilPixInfoLoading,
	setAsianBanksInfo,
	setAsianBanksInfoLoading,
	setBitcoinCashInfo,
	setBitcoinCashInfoLoading,
	setBitcoinInfo,
	setBitcoinInfoLoading,
	setIdrPayTrustInfo,
	setIdrPayTrustInfoLoading,
	setIdrZotaPayInfo,
	setIdrZotaPayInfoLoading,
	setMyrPayTrustInfo,
	setMyrPayTrustInfoLoading,
	setMyrZotaPayInfo,
	setMyrZotaPayInfoLoading,
	setThbPayTrustInfo,
	setThbPayTrustInfoLoading,
	setThbZotaPayInfo,
	setThbZotaPayInfoLoading
} = actions;

type MethodInfo = { fee: string, fee_details: string, add_on: string };

export function * fetchBitcoinInfoWatcher () {
	yield takeEvery(FETCH_BITCOIN_INFO, fetchBitcoinInfoWorker);
}

export function * fetchBitcoinInfoWorker () {
	yield put(setBitcoinInfoLoading(true));
	try {
		const response: MethodInfo = yield call(fetchBitcoinInfo);

		yield put(setBitcoinInfo({
			fee: +response.fee,
			addon: +response.add_on,
			feeDetails: response.fee_details
		}));
	} catch (e) {
		//
	} finally {
		yield put(setBitcoinInfoLoading(false));
	}
}

async function fetchBitcoinInfo () {
	const response = await API.get<unknown, AxiosResponse<MethodInfo>>("/withdrawBitcoin");
	return response.data;
}

export function * fetchBitcoinCashInfoWatcher () {
	yield takeEvery(FETCH_BITCOIN_CASH_INFO, fetchBitcoinCashInfoWorker);
}

export function * fetchBitcoinCashInfoWorker () {
	yield put(setBitcoinCashInfoLoading(true));
	try {
		const response: MethodInfo = yield call(fetchBitcoinCashInfo);

		yield put(setBitcoinCashInfo({
			fee: +response.fee,
			addon: +response.add_on,
			feeDetails: response.fee_details
		}));
	} catch (e) {
		//
	} finally {
		yield put(setBitcoinCashInfoLoading(false));
	}
}

async function fetchBitcoinCashInfo () {
	const response = await API.get<unknown, AxiosResponse<MethodInfo>>("/withdrawBitcoinCash");
	return response.data;
}

export function * fetchMyrPayTrustInfoWatcher () {
	yield takeEvery(FETCH_MYR_PAY_TRUST_INFO, fetchMyrPayTrustInfoWorker);
}

export function * fetchMyrPayTrustInfoWorker () {
	yield put(setMyrPayTrustInfoLoading(true));
	try {
		const response: MethodInfo = yield call(fetchMyrPayTrustInfo);

		yield put(setMyrPayTrustInfo({
			fee: +response.fee,
			addon: +response.add_on,
			feeDetails: response.fee_details
		}));
	} catch (e) {
		//
	} finally {
		yield put(setMyrPayTrustInfoLoading(false));
	}
}

async function fetchMyrPayTrustInfo () {
	const response = await API.get<unknown, AxiosResponse<MethodInfo>>("/withdrawPaytrustMalaysia");
	return response.data;
}

export function * fetchThbPayTrustInfoWatcher () {
	yield takeEvery(FETCH_THB_PAY_TRUST_INFO, fetchThbPayTrustInfoWorker);
}

export function * fetchThbPayTrustInfoWorker () {
	yield put(setThbPayTrustInfoLoading(true));
	try {
		const response: MethodInfo = yield call(fetchThbPayTrustInfo);

		yield put(setThbPayTrustInfo({
			fee: +response.fee,
			addon: +response.add_on,
			feeDetails: response.fee_details
		}));
	} catch (e) {
		//
	} finally {
		yield put(setThbPayTrustInfoLoading(false));
	}
}

async function fetchThbPayTrustInfo () {
	const response = await API.get<unknown, AxiosResponse<MethodInfo>>("/withdrawPaytrustThailand");
	return response.data;
}

export function * fetchIdrPayTrustInfoWatcher () {
	yield takeEvery(FETCH_IDR_PAY_TRUST_INFO, fetchIdrPayTrustInfoWorker);
}

export function * fetchIdrPayTrustInfoWorker () {
	yield put(setIdrPayTrustInfoLoading(true));
	try {
		const response: MethodInfo = yield call(fetchIdrPayTrustInfo);

		yield put(setIdrPayTrustInfo({
			fee: +response.fee,
			addon: +response.add_on,
			feeDetails: response.fee_details
		}));
	} catch (e) {
		//
	} finally {
		yield put(setIdrPayTrustInfoLoading(false));
	}
}

async function fetchIdrPayTrustInfo () {
	const response = await API.get<unknown, AxiosResponse<MethodInfo>>("/withdrawPaytrustIndonesia");
	return response.data;
}

export function * fetchVndPayTrustInfoWatcher () {
	yield takeEvery(FETCH_VND_PAY_TRUST_INFO, fetchVndPayTrustInfoWorker);
}

export function * fetchVndPayTrustInfoWorker () {
	yield put(setVndPayTrustInfoLoading(true));
	try {
		const response: MethodInfo = yield call(fetchVndPayTrustInfo);

		yield put(setVndPayTrustInfo({
			fee: +response.fee,
			addon: +response.add_on,
			feeDetails: response.fee_details
		}));
	} catch (e) {
		//
	} finally {
		yield put(setVndPayTrustInfoLoading(false));
	}
}

async function fetchVndPayTrustInfo () {
	const response = await API.get<unknown, AxiosResponse<MethodInfo>>("/withdrawPaytrustVietnam");
	return response.data;
}

export function * fetchMyrZotaPayInfoWatcher () {
	yield takeEvery(FETCH_MYR_ZOTA_PAY_INFO, fetchMyrZotaPayInfoWorker);
}

export function * fetchMyrZotaPayInfoWorker () {
	yield put(setMyrZotaPayInfoLoading(true));
	try {
		const response: MethodInfo = yield call(fetchMyrZotaPayInfo);

		yield put(setMyrZotaPayInfo({
			fee: +response.fee,
			addon: +response.add_on,
			feeDetails: response.fee_details
		}));
	} catch (e) {
		//
	} finally {
		yield put(setMyrZotaPayInfoLoading(false));
	}
}

async function fetchMyrZotaPayInfo () {
	const response = await API.get<unknown, AxiosResponse<MethodInfo>>("/withdrawZotapayMalaysia");
	return response.data;
}

export function * fetchThbZotaPayInfoWatcher () {
	yield takeEvery(FETCH_THB_ZOTA_PAY_INFO, fetchThbZotaPayInfoWorker);
}

export function * fetchThbZotaPayInfoWorker () {
	yield put(setThbZotaPayInfoLoading(true));
	try {
		const response: MethodInfo = yield call(fetchThbZotaPayInfo);

		yield put(setThbZotaPayInfo({
			fee: +response.fee,
			addon: +response.add_on,
			feeDetails: response.fee_details
		}));
	} catch (e) {
		//
	} finally {
		yield put(setThbZotaPayInfoLoading(false));
	}
}

async function fetchThbZotaPayInfo () {
	const response = await API.get<unknown, AxiosResponse<MethodInfo>>("/withdrawZotapayThailand");
	return response.data;
}

export function * fetchIdrZotaPayInfoWatcher () {
	yield takeEvery(FETCH_IDR_ZOTA_PAY_INFO, fetchIdrZotaPayInfoWorker);
}

export function * fetchIdrZotaPayInfoWorker () {
	yield put(setIdrZotaPayInfoLoading(true));
	try {
		const response: MethodInfo = yield call(fetchIdrZotaPayInfo);

		yield put(setIdrZotaPayInfo({
			fee: +response.fee,
			addon: +response.add_on,
			feeDetails: response.fee_details
		}));
	} catch (e) {
		//
	} finally {
		yield put(setIdrZotaPayInfoLoading(false));
	}
}

async function fetchIdrZotaPayInfo () {
	const response = await API.get<unknown, AxiosResponse<MethodInfo>>("/withdrawZotapayIndonesia");
	return response.data;
}

export function * fetchVndZotaPayInfoWatcher () {
	yield takeEvery(FETCH_VND_ZOTA_PAY_INFO, fetchVndZotaPayInfoWorker);
}

export function * fetchVndZotaPayInfoWorker () {
	yield put(setVndZotaPayInfoLoading(true));
	try {
		const response: MethodInfo = yield call(fetchVndZotaPayInfo);

		yield put(setVndZotaPayInfo({
			fee: +response.fee,
			addon: +response.add_on,
			feeDetails: response.fee_details
		}));
	} catch (e) {
		//
	} finally {
		yield put(setVndZotaPayInfoLoading(false));
	}
}

async function fetchVndZotaPayInfo () {
	const response = await API.get<unknown, AxiosResponse<MethodInfo>>("/withdrawZotapayVietnam");
	return response.data;
}

export function * fetchAsianBanksInfoWatcher () {
	yield takeEvery(FETCH_ASIAN_BANKS_INFO, fetchAsianBanksInfoWorker);
}

export function * fetchAsianBanksInfoWorker () {
	yield put(setAsianBanksInfoLoading(true));
	try {
		const response: MethodInfo = yield call(fetchAsianBanksInfo);

		yield put(setAsianBanksInfo({
			fee: +response.fee,
			addon: +response.add_on,
			feeDetails: response.fee_details
		}));
	} catch (e) {
		//
	} finally {
		yield put(setAsianBanksInfoLoading(false));
	}
}

async function fetchAsianBanksInfo () {
	const response = await API.get<unknown, AxiosResponse<MethodInfo>>("/withdrawAsianBanks");
	return response.data;
}

export function * fetchBrazilPixInfoWatcher () {
	yield takeEvery(FETCH_BRAZIL_PIX_INFO, fetchBrazilInfoWorker);
}

export function * fetchBrazilInfoWorker () {
	yield put(setBrazilPixInfoLoading(true));
	try {
		const response: MethodInfo = yield call(fetchBrazilPixInfo);

		yield put(setBrazilPixInfo({
			fee: +response.fee,
			addon: +response.add_on,
			feeDetails: response.fee_details
		}));
	} catch (e) {
		//
	} finally {
		yield put(setBrazilPixInfoLoading(false));
	}
}

async function fetchBrazilPixInfo () {
	const response = await API.get<unknown, AxiosResponse<MethodInfo>>("/withdrawBrazilPix");
	return response.data;
}

export function * fetchOnlineNairaInfoWatcher () {
	yield takeEvery(FETCH_ONLINE_NAIRA_INFO, fetchOnlineNairaWorker);
}

export function * fetchOnlineNairaWorker () {
	yield put(setOnlineNairaInfoLoading(true));
	try {
		const response: { fixed_fee_rate: number } = yield call(fetchOnlineNairaInfo);

		yield put(setOnlineNairaInfo({
			fee: 0,
			addon: +response.fixed_fee_rate,
			feeDetails: ""
		}));
	} catch (e) {
		//
	} finally {
		yield put(setOnlineNairaInfoLoading(false));
	}
}

async function fetchOnlineNairaInfo () {
	const response = await API.get<unknown, AxiosResponse<{ fixed_fee_rate: number }>>("/withdrawOnlineNaira");
	return response.data;
}

export function * fetchChinaUnionPayInfoWatcher () {
	yield takeEvery(FETCH_CHINA_UNION_PAY_INFO, fetchChinaUnionPayWorker);
}

export function * fetchChinaUnionPayWorker () {
	yield put(setChinaUnionPayInfoLoading(true));
	try {
		const response: {
			fee: string,
			addon: string,
			feeDetails: string
		} = yield call(fetchChinaUnionPayInfo);

		yield put(setChinaUnionPayInfo({
			fee: +(response.fee ?? 0),
			addon: +(response.addon ?? 0),
			feeDetails: response.feeDetails ?? ""
		}));
	} catch (e) {
		//
	} finally {
		yield put(setChinaUnionPayInfoLoading(false));
	}
}

async function fetchChinaUnionPayInfo () {
	const response = await API.get<unknown, AxiosResponse<{
		fee: string,
		addon: string,
		feeDetails: string
	}>>("/withdrawChinaUnionPay");
	return response.data;
}

export function * fetchFasapayInfoWatcher () {
	yield takeEvery(FETCH_FASAPAY_INFO, fetchFasapayPayWorker);
}

export function * fetchFasapayPayWorker () {
	yield put(setFasapayInfoLoading(true));
	try {
		const response: {
			fee: string,
			addon: string,
			feeDetails: string
		} = yield call(fetchFasapayInfo);

		yield put(setFasapayInfo({
			fee: +(response.fee ?? 0),
			addon: +(response.addon ?? 0),
			feeDetails: response.feeDetails ?? ""
		}));
	} catch (e) {
		//
	} finally {
		yield put(setFasapayInfoLoading(false));
	}
}

async function fetchFasapayInfo () {
	const response = await API.get<unknown, AxiosResponse<{
		fee: string,
		addon: string,
		feeDetails: string
	}>>("/withdrawFasapay");
	return response.data;
}

export function * fetchLocalBankInfoWatcher () {
	yield takeEvery(FETCH_LOCAL_BANK_INFO, fetchLocalBankWorker);
}

export function * fetchLocalBankWorker () {
	yield put(setLocalBankInfoLoading(true));
	try {
		const response: {
			fee: string,
			addon: string,
			feeDetails: string
		} = yield call(fetchLocalBankInfo);

		yield put(setLocalBankInfo({
			fee: +(response.fee ?? 0),
			addon: +(response.addon ?? 0),
			feeDetails: response.feeDetails ?? ""
		}));
	} catch (e) {
		//
	} finally {
		yield put(setLocalBankInfoLoading(false));
	}
}

async function fetchLocalBankInfo () {
	const response = await API.get<unknown, AxiosResponse<{
		fee: string,
		addon: string,
		feeDetails: string
	}>>("/withdrawLocalBank");
	return response.data;
}

export function * fetchLocalBankTransferMexicoInfoWatcher () {
	yield takeEvery(FETCH_LOCAL_BANK_TRANSFER_MEXICO_INFO, fetchLocalBankTransferMexicoWorker);
}

export function * fetchLocalBankTransferMexicoWorker () {
	yield put(setLocalBankTransferMexicoInfoLoading(true));
	try {
		const response: {
			fee: string,
			add_on: string,
			fee_details: string
		} = yield call(fetchLocalBankTransferMexicoInfo);

		yield put(setLocalBankTransferMexicoInfo({
			fee: +(response.fee ?? 0),
			addon: +(response.add_on ?? 0),
			feeDetails: response.fee_details ?? ""
		}));
	} catch (e) {
		//
	} finally {
		yield put(setLocalBankTransferMexicoInfoLoading(false));
	}
}

async function fetchLocalBankTransferMexicoInfo () {
	const response = await API.get<unknown, AxiosResponse<{
		fee: string,
		add_on: string,
		fee_details: string
	}>>("/withdrawLocalBankMxn");
	return response.data;
}

export function * fetchNetellerInfoWatcher () {
	yield takeEvery(FETCH_NETELLER_INFO, fetchNetellerWorker);
}

export function * fetchNetellerWorker () {
	yield put(setNetellerInfoLoading(true));
	try {
		const response: {
			fee: string,
			add_on: string,
			fee_details: string
		} = yield call(fetchNetellerInfo);

		yield put(setNetellerInfo({
			fee: +(response.fee ?? 0),
			addon: +(response.add_on ?? 0),
			feeDetails: response.fee_details ?? ""
		}));
	} catch (e) {
		//
	} finally {
		yield put(setNetellerInfoLoading(false));
	}
}

async function fetchNetellerInfo () {
	const response = await API.get<unknown, AxiosResponse<{
		fee: string,
		add_on: string,
		fee_details: string
	}>>("/withdrawNeteller");
	return response.data;
}

export function * fetchPaycoInfoWatcher () {
	yield takeEvery(FETCH_PAYCO_INFO, fetchPaycoWorker);
}

export function * fetchPaycoWorker () {
	yield put(setPaycoInfoLoading(true));
	try {
		const response: {
			fee: string,
			add_on: string,
			fee_details: string
		} = yield call(fetchPaycoInfo);

		yield put(setPaycoInfo({
			fee: +(response.fee ?? 0),
			addon: +(response.add_on ?? 0),
			feeDetails: response.fee_details ?? ""
		}));
	} catch (e) {
		//
	} finally {
		yield put(setPaycoInfoLoading(false));
	}
}

async function fetchPaycoInfo () {
	const response = await API.get<unknown, AxiosResponse<{
		fee: string,
		add_on: string,
		fee_details: string
	}>>("/withdrawPayco");
	return response.data;
}

export function * fetchSkrillInfoWatcher () {
	yield takeEvery(FETCH_SKRILL_INFO, fetchSkrillWorker);
}

export function * fetchSkrillWorker () {
	yield put(setSkrillInfoLoading(true));
	try {
		const response: {
			fee: string,
			add_on: string,
			fee_details: string
		} = yield call(fetchSkrillInfo);

		yield put(setSkrillInfo({
			fee: +(response.fee ?? 0),
			addon: +(response.add_on ?? 0),
			feeDetails: response.fee_details ?? ""
		}));
	} catch (e) {
		//
	} finally {
		yield put(setSkrillInfoLoading(false));
	}
}

async function fetchSkrillInfo () {
	const response = await API.get<unknown, AxiosResponse<{
		fee: string,
		add_on: string,
		fee_details: string
	}>>("/withdrawSkrill");
	return response.data;
}

export function * fetchThunderXPayInfoWatcher () {
	yield takeEvery(FETCH_THUNDER_X_PAY_INFO, fetchThunderXPayWorker);
}

export function * fetchThunderXPayWorker () {
	yield put(setThunderXPayInfoLoading(true));
	try {
		const response: {
			fee: string,
			add_on: string,
			fee_details: string
		} = yield call(fetchThunderXPayInfo);

		yield put(setThunderXPayInfo({
			fee: +(response.fee ?? 0),
			addon: +(response.add_on ?? 0),
			feeDetails: response.fee_details ?? ""
		}));
	} catch (e) {
		//
	} finally {
		yield put(setThunderXPayInfoLoading(false));
	}
}

async function fetchThunderXPayInfo () {
	return await fetchLocalBankInfo();
}

export function * fetchVnPayInfoWatcher () {
	yield takeEvery(FETCH_VN_PAY_INFO, fetchVnPayWorker);
}

export function * fetchVnPayWorker () {
	yield put(setVnPayInfoLoading(true));
	try {
		const response: {
			fee: string,
			add_on: string,
			fee_details: string
		} = yield call(fetchVnPayInfo);

		yield put(setVnPayInfo({
			fee: +(response.fee ?? 0),
			addon: +(response.add_on ?? 0),
			feeDetails: response.fee_details ?? ""
		}));
	} catch (e) {
		//
	} finally {
		yield put(setVnPayInfoLoading(false));
	}
}

async function fetchVnPayInfo () {
	const response = await API.get<unknown, AxiosResponse<{
		fee: string,
		add_on: string,
		fee_details: string
	}>>("/withdrawVNPay");
	return response.data;
}

export function * fetchTetherInfoWatcher () {
	yield takeEvery(FETCH_TETHER_INFO, fetchTetherWorker);
}

export function * fetchTetherWorker () {
	yield put(setTetherInfoLoading(true));
	try {
		const response: {
			fee: string,
			add_on: string,
			fee_details: string,
			metadata_description: string,
		} = yield call(fetchTetherInfo);

		yield put(setTetherInfo({
			fee: +(response.fee ?? 0),
			addon: +(response.add_on ?? 0),
			feeDetails: response.fee_details ?? "",
			metadata_description: response.metadata_description
		}));
	} catch (e) {
		//
	} finally {
		yield put(setTetherInfoLoading(false));
	}
}

async function fetchTetherInfo () {
	const response = await API.get<unknown, AxiosResponse<{
		fee: string,
		add_on: string,
		fee_details: string,
		metadata_description: string,
	}>>("/withdrawTether");
	return response.data;
}

export const fetchMobileMoneyTigoInfo = createMobileMoneyMethod("TIGO");
export const fetchMobileMoneyAirtelInfo = createMobileMoneyMethod("AIRTEL");
export const fetchMobileMoneyMtnInfo = createMobileMoneyMethod("MTN");
export const fetchMobileMoneyVodacomInfo = createMobileMoneyMethod("VODACOM");
export const fetchMobileMoneyVodafoneInfo = createMobileMoneyMethod("VODAFONE");
export const fetchMobileMoneyMpesaInfo = createMobileMoneyMethod("MPESA");
export const fetchMobileMoneyZamtelInfo = createMobileMoneyMethod("ZAMTEL");
export const fetchMobileMoneyHalopesaInfo = createMobileMoneyMethod("HALOPESA");
export const fetchMobileMoneyOrangeInfo = createMobileMoneyMethod("ORANGE");
export const fetchMobileMoneyEuMobileInfo = createMobileMoneyMethod("EU_MOBILE");

type TActionCreatorsNames = "Tigo"
| "Airtel"
| "Mtn"
| "Vodacom"
| "Vodafone"
| "Mpesa"
| "Zamtel"
| "Halopesa"
| "Orange"
| "EuMobile";
function createMobileMoneyMethod (mobileMoneyType: keyof TMobileMoneyNames) {
	const actionCreatorName: TActionCreatorsNames = mobileMoneyType.split("_").reduce((prev, curr) => prev + curr[0].toUpperCase() + curr.slice(1).toLowerCase(), "") as TActionCreatorsNames;
	function * fetchMobileMoneyWatcher () {
		yield takeEvery(actions[`FETCH_MOBILE_MONEY_${mobileMoneyType}_INFO`], fetchMobileMoneyInfoWorker);
	}

	function * fetchMobileMoneyInfoWorker () {
		console.log(actions, `setMobileMoney${actionCreatorName}InfoLoading`);
		yield put(actions[`setMobileMoney${actionCreatorName}InfoLoading`](true));
		try {
			const response: MethodInfo & { bank_code: string, bank_name: string } = yield call(fetchMobileMoneyInfo, mobileMoneyType);

			yield put(actions[`setMobileMoney${actionCreatorName}Info`]({
				fee: +response.fee,
				addon: +response.add_on,
				feeDetails: response.fee_details,
				bankCode: response.bank_code,
				bankName: response.bank_name
			}));
		} catch (e) {
			//
		} finally {
			yield put(actions[`setMobileMoney${actionCreatorName}InfoLoading`](false));
		}
	}

	async function fetchMobileMoneyInfo (mobileMoneyType: keyof TMobileMoneyNames) {
		const response = await API.get<unknown, AxiosResponse<MethodInfo & { bank_code: string, bank_name: string }>>(`/withdrawMobileMoney/${MobileMoneyNames[mobileMoneyType]}`);
		return response.data;
	}

	return fetchMobileMoneyWatcher;
}
