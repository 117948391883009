import * as React from "react";
import { Avatar } from "@mui/material";
import { useIsMobile } from "../../../../utils/hooks/useIsMobile";

export interface IIconProps {
	value: string | number;
	selected?: boolean;
}

export function NumberLabel (props: IIconProps) {
	const { selected, value } = props;
	const isMobile = useIsMobile();

	return (
		<Avatar
			sx={({ palette, typography }) => ({
				backgroundColor: selected ? palette.success?.main : "#707070",
				width: isMobile ? "36px" : "48px",
				height: isMobile ? "36px" : "48px",
				fontWeight: typography.fontWeightMedium,
				marginRight: "25px"
			})}
		>
			{value}
		</Avatar>
	);
}
