import { Alert, Box, Grid, Snackbar } from "@mui/material";
import * as React from "react";
import { ButtonWithLoader } from "../../../../components/ButtonWithLoader";
import { IFMTableComponentProps } from "../../../../components/FMTable/FMTableCellComponent";
import { useStorage } from "../../../../components/Storage";
import { IRebateSystemPersonalProject } from "../../../../interfaces/RebateSystemInterfaces";
import { addRebateSystemPersonalProject, removeRebateSystemPersonalProject, updateRebateSystemPersonalProject } from "../../../../store/actions/actionTypes";
import API from "../../../../utils/API";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { useAdaptive } from "../../../../utils/hooks/themeBreakpointsHooks";
import { useErrorHandling } from "../../../../utils/hooks/useErrorHandling";
import { TResponse } from "../../../../utils/types";

export function ActionCell (props: IFMTableComponentProps<IRebateSystemPersonalProject & {index: number, blank: boolean}, {}>) {
	const { client, rebateValue, blank } = props;
	const { data } = useStorage();
	const [ removeIsFetch, setRemoveIsFetch ] = React.useState(false);
	const [ updateIsFetch, setUpdateIsFetch ] = React.useState(false);
	const { isOpen, errorMsg, hideError, showError } = useErrorHandling();
	const { languagePartnership } = useAppSelector(state => state.language);
	const dispatch = useAppDispatch();

	async function updatePersonalProject () {
		setUpdateIsFetch(true);
		try {
			const updatedRebateValue = data?.rebateValue;
			if (updatedRebateValue === "") {
				throw new Error(languagePartnership.empty_rebate_value);
			}

			if (!updatedRebateValue || updatedRebateValue === rebateValue) {
				throw new Error(languagePartnership.same_rebate_value);
			}

			const formData = new FormData();
			formData.append("client", `${client}`);
			formData.append("rebateValue", updatedRebateValue);

			const response = await API.post<TResponse<null>>("rebateSystemUpdatePersonalProject", formData);

			if (response.data.error) {
				throw new Error(response.data.error);
			}

			dispatch(updateRebateSystemPersonalProject({ client, rebateValue: updatedRebateValue }));
		} catch (e) {
			if (e instanceof Error) {
				showError(e.message);
			}
		} finally {
			setUpdateIsFetch(false);
		}
	}

	async function removePersonalProject () {
		setRemoveIsFetch(true);
		try {
			const formData = new FormData();
			formData.append("client", `${client}`);
			formData.append("oldRebateValue", `${rebateValue}`);

			const response = await API.post<TResponse<null>>("rebateSystemRemovePersonalProject", formData);

			if (response.data.error) {
				throw new Error(response.data.error);
			}

			dispatch(removeRebateSystemPersonalProject({ client }));
		} catch (e) {
			if (e instanceof Error) {
				showError(e.message);
			}
		} finally {
			setRemoveIsFetch(false);
		}
	};

	const adaptiveButtonStyle = useAdaptive({
		xs: {
			maxWidth: "95px"
		},
		sm: {
			maxWidth: "150px"
		}
	});

	return <Box pb="9px" pt="9px">
		{blank
			? <AddPersonalProject/>
			: (
				<>
					<Grid container gap={1}>
						<Grid item>
							<ButtonWithLoader
								isFetch={updateIsFetch}
								onClick={updatePersonalProject}
								text="Update"
								color="success"
								sxButton={adaptiveButtonStyle}
							/>
						</Grid>
						<Grid item>
							<ButtonWithLoader
								isFetch={removeIsFetch}
								onClick={removePersonalProject}
								text="Remove"
								color="error"
								sxButton={adaptiveButtonStyle}
							/>
						</Grid>
					</Grid>
					<Snackbar open={isOpen} autoHideDuration={4000} onClose={hideError} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
						<Alert variant="filled" color="error">
							{errorMsg}
						</Alert>
					</Snackbar>
				</>
			)
		}
	</Box>;
}

function AddPersonalProject () {
	const [ isFetch, setIsFetch ] = React.useState(false);
	const { data } = useStorage();
	const { isOpen, errorMsg, hideError, showError } = useErrorHandling();
	const { languagePartnership } = useAppSelector(state => state.language);
	const dispatch = useAppDispatch();

	async function addPersonalProject () {
		setIsFetch(true);
		try {
			const accountNumber = data?.accountNumber;
			if (!accountNumber) {
				throw new Error(languagePartnership.empty_client_select);
			}

			const newRebateValue = data?.rebateValue;
			if (!newRebateValue) {
				throw new Error(languagePartnership.empty_rebate_value);
			}

			const formData = new FormData();
			formData.append("client", accountNumber);
			formData.append("rebateValue", newRebateValue);

			const response = await API.post<TResponse<null>>("rebateSystemAddPersonalProject", formData);

			if (response.data.error) {
				throw new Error(response.data.error);
			}

			dispatch(addRebateSystemPersonalProject({ client: accountNumber, rebateValue: newRebateValue }));
		} catch (e) {
			if (e instanceof Error) {
				showError(e.message);
			}
		} finally {
			setIsFetch(false);
		}
	}

	return (
		<>
			<ButtonWithLoader
				isFetch={isFetch}
				onClick={addPersonalProject}
				text="Add"
				color="success"
			/>
			<Snackbar open={isOpen} autoHideDuration={4000} onClose={hideError} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
				<Alert variant="filled" color="error">
					{errorMsg}
				</Alert>
			</Snackbar>
		</>
	);
}
