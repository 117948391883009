import { ActionType } from "../../utils/reudxHelpers";
import {
	SET_ASIAN_BANKS_INFO,
	SET_ASIAN_BANKS_INFO_LOADING,
	SET_BITCOIN_CASH_INFO,
	SET_BITCOIN_CASH_INFO_LOADING,
	SET_BITCOIN_INFO,
	SET_BITCOIN_INFO_LOADING,
	SET_BRAZIL_PIX_INFO,
	SET_BRAZIL_PIX_INFO_LOADING,
	SET_CHINA_UNION_PAY_INFO,
	SET_CHINA_UNION_PAY_INFO_LOADING,
	SET_FASAPAY_INFO,
	SET_FASAPAY_INFO_LOADING,
	SET_IDR_PAY_TRUST_INFO,
	SET_IDR_PAY_TRUST_INFO_LOADING,
	SET_IDR_ZOTA_PAY_INFO,
	SET_IDR_ZOTA_PAY_INFO_LOADING,
	SET_LOCAL_BANK_INFO,
	SET_LOCAL_BANK_INFO_LOADING,
	SET_LOCAL_BANK_TRANSFER_MEXICO_INFO,
	SET_LOCAL_BANK_TRANSFER_MEXICO_INFO_LOADING,
	SET_MOBILE_MONEY_AIRTEL_INFO,
	SET_MOBILE_MONEY_AIRTEL_INFO_LOADING,
	SET_MOBILE_MONEY_EU_MOBILE_INFO,
	SET_MOBILE_MONEY_EU_MOBILE_INFO_LOADING,
	SET_MOBILE_MONEY_HALOPESA_INFO,
	SET_MOBILE_MONEY_HALOPESA_INFO_LOADING,
	SET_MOBILE_MONEY_MPESA_INFO,
	SET_MOBILE_MONEY_MPESA_INFO_LOADING,
	SET_MOBILE_MONEY_MTN_INFO,
	SET_MOBILE_MONEY_MTN_INFO_LOADING,
	SET_MOBILE_MONEY_ORANGE_INFO,
	SET_MOBILE_MONEY_ORANGE_INFO_LOADING,
	SET_MOBILE_MONEY_TIGO_INFO,
	SET_MOBILE_MONEY_TIGO_INFO_LOADING,
	SET_MOBILE_MONEY_VODACOM_INFO,
	SET_MOBILE_MONEY_VODACOM_INFO_LOADING,
	SET_MOBILE_MONEY_VODAFONE_INFO,
	SET_MOBILE_MONEY_VODAFONE_INFO_LOADING,
	SET_MOBILE_MONEY_ZAMTEL_INFO,
	SET_MOBILE_MONEY_ZAMTEL_INFO_LOADING,
	SET_MYR_PAY_TRUST_INFO,
	SET_MYR_PAY_TRUST_INFO_LOADING,
	SET_MYR_ZOTA_PAY_INFO,
	SET_MYR_ZOTA_PAY_INFO_LOADING,
	SET_NETELLER_INFO,
	SET_NETELLER_INFO_LOADING,
	SET_ONLINE_NAIRA_INFO,
	SET_ONLINE_NAIRA_INFO_LOADING,
	SET_PAYCO_INFO,
	SET_PAYCO_INFO_LOADING,
	SET_SKRILL_INFO,
	SET_SKRILL_INFO_LOADING,
	SET_TETHER_INFO,
	SET_TETHER_INFO_LOADING,
	SET_THB_PAY_TRUST_INFO,
	SET_THB_PAY_TRUST_INFO_LOADING,
	SET_THB_ZOTA_PAY_INFO,
	SET_THB_ZOTA_PAY_INFO_LOADING,
	SET_THUNDER_X_PAY_INFO,
	SET_THUNDER_X_PAY_INFO_LOADING,
	SET_VN_PAY_INFO,
	SET_VN_PAY_INFO_LOADING,
	SET_VND_PAY_TRUST_INFO,
	SET_VND_PAY_TRUST_INFO_LOADING,
	SET_VND_ZOTA_PAY_INFO,
	SET_VND_ZOTA_PAY_INFO_LOADING
} from "../actions/actionTypes";
import { WalletCurrency } from "../../types/WalletCurrency";

export type WithdrawMethodInfo = {
	fee: number;
	addon: number;
	feeDetails: string;
	bankCode?: string;
	bankName?: string;
	complete?: boolean;
	bankList?: { [key: string]: string },
	metadata_description?: string
};

export const MobileMoneyNames = {
	TIGO: "TIGO",
	AIRTEL: "AIRTEL",
	MTN: "MTN",
	VODAFONE: "VODAFONE",
	MPESA: "MPESA",
	ZAMTEL: "ZAMTEL",
	VODACOM: "VODACOM",
	HALOPESA: "HALOPESA",
	ORANGE: "ORANGE",
	EU_MOBILE: "EU-MOBILE"
} as const;

export type TMobileMoneyNames = typeof MobileMoneyNames;

type TWithdrawMethodsInfoState = {
	bitcoin: WithdrawMethodInfo & { loading: boolean };
	bitcoinCash: WithdrawMethodInfo & { loading: boolean };
	myrPayTrust: WithdrawMethodInfo & { loading: boolean };
	thbPayTrust: WithdrawMethodInfo & { loading: boolean };
	idrPayTrust: WithdrawMethodInfo & { loading: boolean };
	vndPayTrust: WithdrawMethodInfo & { loading: boolean };
	asianBank: WithdrawMethodInfo & { loading: boolean };
	brazilPix: WithdrawMethodInfo & { loading: boolean };
	onlineNaira: WithdrawMethodInfo & { loading: boolean };
	chinaUnionPay: WithdrawMethodInfo & { loading: boolean };
	fasaPay: WithdrawMethodInfo & { loading: boolean };
	localBank: WithdrawMethodInfo & { loading: boolean };
	localBankTransferMexico: WithdrawMethodInfo & { loading: boolean };
	neteller: WithdrawMethodInfo & { loading: boolean };
	payco: WithdrawMethodInfo & { loading: boolean };
	skrill: WithdrawMethodInfo & { loading: boolean };
	thunderXPay: WithdrawMethodInfo & { loading: boolean };
	vnPay: WithdrawMethodInfo & { loading: boolean };
	tether: WithdrawMethodInfo & { loading: boolean };
	zotaPay: {
		[WalletCurrency.MYR]: WithdrawMethodInfo & { loading: boolean },
		[WalletCurrency.THB]: WithdrawMethodInfo & { loading: boolean },
		[WalletCurrency.VND]: WithdrawMethodInfo & { loading: boolean },
		[WalletCurrency.IDR]: WithdrawMethodInfo & { loading: boolean }
	},
	mobileMoney: {
		[key in keyof TMobileMoneyNames]: WithdrawMethodInfo & { loading: boolean }
	}
}

const initialState: TWithdrawMethodsInfoState = {
	bitcoin: createInitMethodInfoValue(),
	bitcoinCash: createInitMethodInfoValue(),
	myrPayTrust: createInitMethodInfoValue(),
	thbPayTrust: createInitMethodInfoValue(),
	idrPayTrust: createInitMethodInfoValue(),
	vndPayTrust: createInitMethodInfoValue(),
	asianBank: createInitMethodInfoValue(),
	zotaPay: {
		MYR: createInitMethodInfoValue(),
		THB: createInitMethodInfoValue(),
		VND: createInitMethodInfoValue(),
		IDR: createInitMethodInfoValue()
	},
	mobileMoney: {
		TIGO: createInitMethodInfoValue(),
		AIRTEL: createInitMethodInfoValue(),
		MTN: createInitMethodInfoValue(),
		VODACOM: createInitMethodInfoValue(),
		VODAFONE: createInitMethodInfoValue(),
		MPESA: createInitMethodInfoValue(),
		ZAMTEL: createInitMethodInfoValue(),
		HALOPESA: createInitMethodInfoValue(),
		ORANGE: createInitMethodInfoValue(),
		EU_MOBILE: createInitMethodInfoValue()
	},
	brazilPix: createInitMethodInfoValue(),
	onlineNaira: createInitMethodInfoValue(),
	chinaUnionPay: createInitMethodInfoValue(),
	fasaPay: createInitMethodInfoValue(),
	localBank: createInitMethodInfoValue(),
	localBankTransferMexico: createInitMethodInfoValue(),
	neteller: createInitMethodInfoValue(),
	payco: createInitMethodInfoValue(),
	skrill: createInitMethodInfoValue(),
	thunderXPay: createInitMethodInfoValue(),
	vnPay: createInitMethodInfoValue(),
	tether: createInitMethodInfoValue()
};

type Actions = ActionType<"SET_BITCOIN_INFO", WithdrawMethodInfo>
| ActionType<"SET_BITCOIN_INFO_LOADING", boolean>
| ActionType<"SET_BITCOIN_CASH_INFO", WithdrawMethodInfo>
| ActionType<"SET_BITCOIN_CASH_INFO_LOADING", boolean>
| ActionType<"SET_MYR_PAY_TRUST_INFO", WithdrawMethodInfo>
| ActionType<"SET_MYR_PAY_TRUST_INFO_LOADING", boolean>
| ActionType<"SET_THB_PAY_TRUST_INFO", WithdrawMethodInfo>
| ActionType<"SET_THB_PAY_TRUST_INFO_LOADING", boolean>
| ActionType<"SET_IDR_PAY_TRUST_INFO", WithdrawMethodInfo>
| ActionType<"SET_IDR_PAY_TRUST_INFO_LOADING", boolean>
| ActionType<"SET_VND_PAY_TRUST_INFO", WithdrawMethodInfo>
| ActionType<"SET_VND_PAY_TRUST_INFO_LOADING", boolean>
| ActionType<"SET_MYR_ZOTA_PAY_INFO", WithdrawMethodInfo>
| ActionType<"SET_MYR_ZOTA_PAY_INFO_LOADING", boolean>
| ActionType<"SET_THB_ZOTA_PAY_INFO", WithdrawMethodInfo>
| ActionType<"SET_THB_ZOTA_PAY_INFO_LOADING", boolean>
| ActionType<"SET_IDR_ZOTA_PAY_INFO", WithdrawMethodInfo>
| ActionType<"SET_IDR_ZOTA_PAY_INFO_LOADING", boolean>
| ActionType<"SET_VND_ZOTA_PAY_INFO", WithdrawMethodInfo>
| ActionType<"SET_VND_ZOTA_PAY_INFO_LOADING", boolean>
| ActionType<"SET_ASIAN_BANKS_INFO", WithdrawMethodInfo>
| ActionType<"SET_ASIAN_BANKS_INFO_LOADING", boolean>
| ActionType<"SET_MOBILE_MONEY_TIGO_INFO", WithdrawMethodInfo>
| ActionType<"SET_MOBILE_MONEY_TIGO_INFO_LOADING", boolean>
| ActionType<"SET_MOBILE_MONEY_AIRTEL_INFO", WithdrawMethodInfo>
| ActionType<"SET_MOBILE_MONEY_AIRTEL_INFO_LOADING", boolean>
| ActionType<"SET_MOBILE_MONEY_MTN_INFO", WithdrawMethodInfo>
| ActionType<"SET_MOBILE_MONEY_MTN_INFO_LOADING", boolean>
| ActionType<"SET_MOBILE_MONEY_VODACOM_INFO", WithdrawMethodInfo>
| ActionType<"SET_MOBILE_MONEY_VODACOM_INFO_LOADING", boolean>
| ActionType<"SET_MOBILE_MONEY_VODAFONE_INFO", WithdrawMethodInfo>
| ActionType<"SET_MOBILE_MONEY_VODAFONE_INFO_LOADING", boolean>
| ActionType<"SET_MOBILE_MONEY_MPESA_INFO", WithdrawMethodInfo>
| ActionType<"SET_MOBILE_MONEY_MPESA_INFO_LOADING", boolean>
| ActionType<"SET_MOBILE_MONEY_ZAMTEL_INFO", WithdrawMethodInfo>
| ActionType<"SET_MOBILE_MONEY_ZAMTEL_INFO_LOADING", boolean>
| ActionType<"SET_MOBILE_MONEY_HALOPESA_INFO", WithdrawMethodInfo>
| ActionType<"SET_MOBILE_MONEY_HALOPESA_INFO_LOADING", boolean>
| ActionType<"SET_MOBILE_MONEY_ORANGE_INFO", WithdrawMethodInfo>
| ActionType<"SET_MOBILE_MONEY_ORANGE_INFO_LOADING", boolean>
| ActionType<"SET_MOBILE_MONEY_EU_MOBILE_INFO", WithdrawMethodInfo>
| ActionType<"SET_MOBILE_MONEY_EU_MOBILE_INFO_LOADING", boolean>
| ActionType<"SET_BRAZIL_PIX_INFO", WithdrawMethodInfo>
| ActionType<"SET_BRAZIL_PIX_INFO_LOADING", boolean>
| ActionType<"SET_ONLINE_NAIRA_INFO", WithdrawMethodInfo>
| ActionType<"SET_ONLINE_NAIRA_INFO_LOADING", boolean>
| ActionType<"SET_CHINA_UNION_PAY_INFO", WithdrawMethodInfo>
| ActionType<"SET_CHINA_UNION_PAY_INFO_LOADING", boolean>
| ActionType<"SET_FASAPAY_INFO", WithdrawMethodInfo>
| ActionType<"SET_FASAPAY_INFO_LOADING", boolean>
| ActionType<"SET_LOCAL_BANK_INFO", WithdrawMethodInfo>
| ActionType<"SET_LOCAL_BANK_INFO_LOADING", boolean>
| ActionType<"SET_LOCAL_BANK_TRANSFER_MEXICO_INFO", WithdrawMethodInfo>
| ActionType<"SET_LOCAL_BANK_TRANSFER_MEXICO_INFO_LOADING", boolean>
| ActionType<"SET_NETELLER_INFO", WithdrawMethodInfo>
| ActionType<"SET_NETELLER_INFO_LOADING", boolean>
| ActionType<"SET_PAYCO_INFO", WithdrawMethodInfo>
| ActionType<"SET_PAYCO_INFO_LOADING", boolean>
| ActionType<"SET_SKRILL_INFO", WithdrawMethodInfo>
| ActionType<"SET_SKRILL_INFO_LOADING", boolean>
| ActionType<"SET_THUNDER_X_PAY_INFO", WithdrawMethodInfo>
| ActionType<"SET_THUNDER_X_PAY_INFO_LOADING", boolean>
| ActionType<"SET_VN_PAY_INFO", WithdrawMethodInfo>
| ActionType<"SET_VN_PAY_INFO_LOADING", boolean>
| ActionType<"SET_TETHER_INFO", WithdrawMethodInfo>
| ActionType<"SET_TETHER_INFO_LOADING", boolean>;

export function withdrawMethodsInfoReducer (state = initialState, action: Actions) {
	switch (action.type) {
		case SET_BITCOIN_INFO:
			return changeFeeData(state, "bitcoin", action.payload);
		case SET_BITCOIN_INFO_LOADING:
			return changeFeeLoadingData(state, "bitcoin", action.payload);
		case SET_BITCOIN_CASH_INFO:
			return changeFeeData(state, "bitcoinCash", action.payload);
		case SET_BITCOIN_CASH_INFO_LOADING:
			return changeFeeLoadingData(state, "bitcoinCash", action.payload);
		case SET_MYR_PAY_TRUST_INFO:
			return changeFeeData(state, "myrPayTrust", action.payload);
		case SET_MYR_PAY_TRUST_INFO_LOADING:
			return changeFeeLoadingData(state, "myrPayTrust", action.payload);
		case SET_THB_PAY_TRUST_INFO:
			return changeFeeData(state, "thbPayTrust", action.payload);
		case SET_THB_PAY_TRUST_INFO_LOADING:
			return changeFeeLoadingData(state, "thbPayTrust", action.payload);
		case SET_IDR_PAY_TRUST_INFO:
			return changeFeeData(state, "idrPayTrust", action.payload);
		case SET_IDR_PAY_TRUST_INFO_LOADING:
			return changeFeeLoadingData(state, "idrPayTrust", action.payload);
		case SET_VND_PAY_TRUST_INFO:
			return changeFeeData(state, "vndPayTrust", action.payload);
		case SET_VND_PAY_TRUST_INFO_LOADING:
			return changeFeeLoadingData(state, "vndPayTrust", action.payload);
		case SET_MYR_ZOTA_PAY_INFO:
			return changeFeeDataOfZotaPay(state, WalletCurrency.MYR, action.payload);
		case SET_MYR_ZOTA_PAY_INFO_LOADING:
			return changeFeeDataOfZotaPayLoading(state, WalletCurrency.MYR, action.payload);
		case SET_THB_ZOTA_PAY_INFO:
			return changeFeeDataOfZotaPay(state, WalletCurrency.THB, action.payload);
		case SET_THB_ZOTA_PAY_INFO_LOADING:
			return changeFeeDataOfZotaPayLoading(state, WalletCurrency.THB, action.payload);
		case SET_IDR_ZOTA_PAY_INFO:
			return changeFeeDataOfZotaPay(state, WalletCurrency.IDR, action.payload);
		case SET_IDR_ZOTA_PAY_INFO_LOADING:
			return changeFeeDataOfZotaPayLoading(state, WalletCurrency.IDR, action.payload);
		case SET_VND_ZOTA_PAY_INFO:
			return changeFeeDataOfZotaPay(state, WalletCurrency.VND, action.payload);
		case SET_VND_ZOTA_PAY_INFO_LOADING:
			return changeFeeDataOfZotaPayLoading(state, WalletCurrency.VND, action.payload);
		case SET_ASIAN_BANKS_INFO:
			return changeFeeData(state, "asianBank", action.payload);
		case SET_ASIAN_BANKS_INFO_LOADING:
			return changeFeeLoadingData(state, "asianBank", action.payload);
		case SET_MOBILE_MONEY_TIGO_INFO:
			return changeFeeDataOfMobileMoney(state, "TIGO", action.payload);
		case SET_MOBILE_MONEY_TIGO_INFO_LOADING:
			return changeFeeDataOfMobileMoneyLoading(state, "TIGO", action.payload);
		case SET_MOBILE_MONEY_AIRTEL_INFO:
			return changeFeeDataOfMobileMoney(state, "AIRTEL", action.payload);
		case SET_MOBILE_MONEY_AIRTEL_INFO_LOADING:
			return changeFeeDataOfMobileMoneyLoading(state, "AIRTEL", action.payload);
		case SET_MOBILE_MONEY_MTN_INFO:
			return changeFeeDataOfMobileMoney(state, "MTN", action.payload);
		case SET_MOBILE_MONEY_MTN_INFO_LOADING:
			return changeFeeDataOfMobileMoneyLoading(state, "MTN", action.payload);
		case SET_MOBILE_MONEY_VODACOM_INFO:
			return changeFeeDataOfMobileMoney(state, "VODACOM", action.payload);
		case SET_MOBILE_MONEY_VODACOM_INFO_LOADING:
			return changeFeeDataOfMobileMoneyLoading(state, "VODACOM", action.payload);
		case SET_MOBILE_MONEY_VODAFONE_INFO:
			return changeFeeDataOfMobileMoney(state, "VODAFONE", action.payload);
		case SET_MOBILE_MONEY_VODAFONE_INFO_LOADING:
			return changeFeeDataOfMobileMoneyLoading(state, "VODAFONE", action.payload);
		case SET_MOBILE_MONEY_MPESA_INFO:
			return changeFeeDataOfMobileMoney(state, "MPESA", action.payload);
		case SET_MOBILE_MONEY_MPESA_INFO_LOADING:
			return changeFeeDataOfMobileMoneyLoading(state, "MPESA", action.payload);
		case SET_MOBILE_MONEY_ZAMTEL_INFO:
			return changeFeeDataOfMobileMoney(state, "ZAMTEL", action.payload);
		case SET_MOBILE_MONEY_ZAMTEL_INFO_LOADING:
			return changeFeeDataOfMobileMoneyLoading(state, "ZAMTEL", action.payload);
		case SET_MOBILE_MONEY_HALOPESA_INFO:
			return changeFeeDataOfMobileMoney(state, "HALOPESA", action.payload);
		case SET_MOBILE_MONEY_HALOPESA_INFO_LOADING:
			return changeFeeDataOfMobileMoneyLoading(state, "HALOPESA", action.payload);
		case SET_MOBILE_MONEY_ORANGE_INFO:
			return changeFeeDataOfMobileMoney(state, "ORANGE", action.payload);
		case SET_MOBILE_MONEY_ORANGE_INFO_LOADING:
			return changeFeeDataOfMobileMoneyLoading(state, "ORANGE", action.payload);
		case SET_MOBILE_MONEY_EU_MOBILE_INFO:
			return changeFeeDataOfMobileMoney(state, "EU_MOBILE", action.payload);
		case SET_MOBILE_MONEY_EU_MOBILE_INFO_LOADING:
			return changeFeeDataOfMobileMoneyLoading(state, "EU_MOBILE", action.payload);
		case SET_BRAZIL_PIX_INFO:
			return changeFeeData(state, "brazilPix", action.payload);
		case SET_BRAZIL_PIX_INFO_LOADING:
			return changeFeeLoadingData(state, "brazilPix", action.payload);
		case SET_ONLINE_NAIRA_INFO:
			return changeFeeData(state, "onlineNaira", action.payload);
		case SET_ONLINE_NAIRA_INFO_LOADING:
			return changeFeeLoadingData(state, "onlineNaira", action.payload);
		case SET_CHINA_UNION_PAY_INFO:
			return changeFeeData(state, "chinaUnionPay", action.payload);
		case SET_CHINA_UNION_PAY_INFO_LOADING:
			return changeFeeLoadingData(state, "chinaUnionPay", action.payload);
		case SET_FASAPAY_INFO:
			return changeFeeData(state, "fasaPay", action.payload);
		case SET_FASAPAY_INFO_LOADING:
			return changeFeeLoadingData(state, "fasaPay", action.payload);
		case SET_LOCAL_BANK_INFO:
			return changeFeeData(state, "localBank", action.payload);
		case SET_LOCAL_BANK_INFO_LOADING:
			return changeFeeLoadingData(state, "localBank", action.payload);
		case SET_LOCAL_BANK_TRANSFER_MEXICO_INFO:
			return changeFeeData(state, "localBankTransferMexico", action.payload);
		case SET_LOCAL_BANK_TRANSFER_MEXICO_INFO_LOADING:
			return changeFeeLoadingData(state, "localBankTransferMexico", action.payload);
		case SET_NETELLER_INFO:
			return changeFeeData(state, "neteller", action.payload);
		case SET_NETELLER_INFO_LOADING:
			return changeFeeLoadingData(state, "neteller", action.payload);
		case SET_PAYCO_INFO:
			return changeFeeData(state, "payco", action.payload);
		case SET_PAYCO_INFO_LOADING:
			return changeFeeLoadingData(state, "payco", action.payload);
		case SET_SKRILL_INFO:
			return changeFeeData(state, "skrill", action.payload);
		case SET_SKRILL_INFO_LOADING:
			return changeFeeLoadingData(state, "skrill", action.payload);
		case SET_THUNDER_X_PAY_INFO:
			return changeFeeData(state, "thunderXPay", action.payload);
		case SET_THUNDER_X_PAY_INFO_LOADING:
			return changeFeeLoadingData(state, "thunderXPay", action.payload);
		case SET_VN_PAY_INFO:
			return changeFeeData(state, "vnPay", action.payload);
		case SET_VN_PAY_INFO_LOADING:
			return changeFeeLoadingData(state, "vnPay", action.payload);
		case SET_TETHER_INFO:
			return changeFeeData(state, "tether", action.payload);
		case SET_TETHER_INFO_LOADING:
			return changeFeeLoadingData(state, "tether", action.payload);
		default:
			return state;
	}
}

function changeFeeDataOfZotaPay (state: TWithdrawMethodsInfoState, key: WalletCurrency, payload: WithdrawMethodInfo): TWithdrawMethodsInfoState {
	return {
		...state,
		zotaPay: {
			...state.zotaPay,
			[key]: {
				...state.zotaPay[key],
				fee: payload.fee,
				addon: payload.addon,
				feeDetails: payload.feeDetails,
				complete: true
			}
		}
	};
}

function changeFeeDataOfZotaPayLoading (state: TWithdrawMethodsInfoState, key: WalletCurrency, payload: boolean): TWithdrawMethodsInfoState {
	return {
		...state,
		zotaPay: {
			...state.zotaPay,
			[key]: {
				...state.zotaPay[key],
				loading: payload
			}
		}
	};
}

function changeFeeDataOfMobileMoney (state: TWithdrawMethodsInfoState, key: keyof TMobileMoneyNames, payload: WithdrawMethodInfo): TWithdrawMethodsInfoState {
	return {
		...state,
		mobileMoney: {
			...state.mobileMoney,
			[key]: {
				...state.mobileMoney[key],
				fee: payload.fee,
				addon: payload.addon,
				feeDetails: payload.feeDetails,
				bankCode: payload.bankCode,
				complete: true,
				bankName: payload.bankName
			}
		}
	};
}

function changeFeeDataOfMobileMoneyLoading (state: TWithdrawMethodsInfoState, key: keyof TMobileMoneyNames, payload: boolean): TWithdrawMethodsInfoState {
	return {
		...state,
		mobileMoney: {
			...state.mobileMoney,
			[key]: {
				...state.mobileMoney[key],
				loading: payload
			}
		}
	};
}

function changeFeeData (state: TWithdrawMethodsInfoState, name: keyof TWithdrawMethodsInfoState, payload: WithdrawMethodInfo): TWithdrawMethodsInfoState {
	return {
		...state,
		[name]: {
			...state[name],
			fee: payload.fee,
			addon: payload.addon,
			feeDetails: payload.feeDetails,
			complete: true,
			metadata_description: payload.metadata_description
		}
	};
}

function changeFeeLoadingData (state: TWithdrawMethodsInfoState, name: keyof TWithdrawMethodsInfoState, payload: boolean): TWithdrawMethodsInfoState {
	return {
		...state,
		[name]: {
			...state[name],
			loading: payload
		}
	};
}

function createInitMethodInfoValue () {
	return {
		loading: true,
		fee: 0,
		addon: 0,
		feeDetails: "",
		complete: false
	};
}
