import * as React from "react";
import * as Yup from "yup";
import { useForm, Control, UseFormRegister } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldWrapperVariant } from "../UI/FieldWrapper";

export const FormContext = React.createContext<{
	control: Control | undefined,
	fieldWrapperVariant: FieldWrapperVariant;
	errors: { [x: string]: any } | undefined;
	setValue:(...values: any[]) => any;
	getValues: (payload?: string | string[]) => Object;
	register: UseFormRegister<any>;
	reset: (values?: any, options?: Record<string, boolean>) => void
}>({
			control: undefined,
			errors: undefined,
			fieldWrapperVariant: "default",
			setValue: () => {},
			getValues: () => ({}),
			register: {} as any,
			reset: () => {}
		});

export interface FormProps {
	defaultValues: { [name: string]: any };
	onSubmit: (values: { [name: string]: any } | any) => void | Promise<void> | any;
	children: React.ReactNode;
	validationSchema: Yup.AnyObjectSchema;
	fieldWrapperVariant?: FieldWrapperVariant;
}

export function Form (props: FormProps): JSX.Element {
	const {
		defaultValues,
		children,
		onSubmit,
		validationSchema,
		fieldWrapperVariant = "default"
	} = props;
	const { control, handleSubmit, formState: { errors }, setValue, getValues, register, reset } = useForm({
		defaultValues,
		resolver: yupResolver(validationSchema)
	});

	return (
		<FormContext.Provider value={{ control, fieldWrapperVariant, errors, setValue, getValues, register, reset }}>
			<form onSubmit={handleSubmit(onSubmit)}>
				{ children }
			</form>
		</FormContext.Provider>
	);
}
