import dayjs from "dayjs";
import { IFMTableRowObj } from "./formatDataFMTable";

interface ISearchFMTable<T, M> {
	allRowsData: Array<IFMTableRowObj<T, M>>
	filter: string
	dateFrom: number
	dateTo: number
}

export function searchFMTableRows<T, M> (props: ISearchFMTable<T, M>) {
	const { allRowsData, filter, dateFrom, dateTo } = props;

	const filteredRows = () => {
		return allRowsData.filter((rowObj:IFMTableRowObj<T, M>) => {
			const rowArray:any[] = [ null ];
			let searchExist: boolean = true;
			let dateInRange: boolean = true;

			if (filter) {
				searchExist = false;
				Object.keys(rowObj).map((key: keyof T | string, index: number) => {
					if (typeof rowObj[key] === "object" && rowObj[key].searchSort) {
						rowArray[index] = rowObj[key].searchSort;
					}
					return null;
				});
				const rowString = rowArray.join(" ");
				searchExist = rowString.toLowerCase().includes(filter.toLowerCase());
			}
			if ((dateFrom || dateTo) && (rowObj.date?.searchSort || rowObj.Date?.searchSort)) {
				const unixDate = dayjs(rowObj.date?.searchSort || rowObj.Date?.searchSort).valueOf();
				dateInRange = unixDate >= dateFrom && unixDate <= dateTo;
			}
			return searchExist && dateInRange;
		});
	};

	return (filter || dateFrom || dateTo) ? filteredRows() : allRowsData;
};

interface ISearchFMTableCart {
	allChartData: [number, number][] | null | undefined
	dateFrom: number
	dateTo: number
}

export function searchFMTableChart (props: ISearchFMTableCart) {
	const { allChartData, dateFrom, dateTo } = props;
	const filteredData = () => {
		return allChartData?.filter((item: [number, number]) => {
			let dateInRange: boolean = true;
			if ((dateFrom || dateTo) && item[0]) {
				const unixDate = dayjs(item[0]).valueOf();
				dateInRange = unixDate >= dateFrom && unixDate <= dateTo;
			}
			return dateInRange;
		});
	};

	return (dateFrom || dateTo) ? filteredData() : allChartData;
};
