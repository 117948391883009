/*
    Created on : 09.02.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import "./FormHorizontalGroupDate.scss";
import FormTooltip from "../FormTooltip/FormTooltip";
import CustomDatePicker from "../CustomDatePicker/CustomDatePicker";
import { Box, SxProps, Theme, Typography } from "@mui/material";

interface FormHorizontalGroupInterface {
	label_text?: string
	required?: boolean
	type?: string
	name?: string
	placeholder?: string
	defaultValue?:any
	error?: string
	tooltip_message?: string
	sx?: SxProps<Theme>
}

function FormHorizontalGroupDate (props:FormHorizontalGroupInterface) {
	return (
		<Box className="form-group" sx={props.sx}>
			<div className="form-horizontal-left-column flexRow">
				<Typography fontWeight={"bold"} color={"#5C7080"} className="form-horizontal-left-column flexRow">
					{props.label_text}
					{props.required
						? <cite className="form-horizontal-req">*</cite>
						: null
					}
				</Typography>
			</div>
			<div className="form-horizontal-right-column flexRow">

				<div className="data-picker-wrap">
					<CustomDatePicker
						input_name={props.name}
						dateStart={props.defaultValue}
						classsName={"form-control form-horizontal-input"}
						// available={props.currencies.dates}
						// minDate={convertDate(props.currencies.minDate)}
						// maxDate={convertDate(props.currencies.maxDate)}
						// fetchCurrencies={props.fetchCurrencies}
					/>
				</div>
				<span
					className="name-error error"
					style={{ display: "none" }}
				>
					{props.error}
				</span>
			</div>
			<FormTooltip
				message={props.tooltip_message}
			/>

		</Box>
	);
}

export default FormHorizontalGroupDate;
