import * as Yup from "yup";
import { AGREE_CHECKBOX } from "../../Form/BonusSelector";

type DefaultErrors = {
	account_number_err_01: string;
	deposit_err_01: string;
	deposit_err_02: string;
	deposit_err_03: string;
	deposit_err_04: string;
	bonus_err_01: string;
}

export function defaultValidationSchema (language: DefaultErrors): Yup.AnyObjectSchema {
	return Yup.object().shape({
		accountNumber: Yup.string().required(language.account_number_err_01),
		amount: Yup
			.number()
			.max(100000000, language.deposit_err_01)
			.min(15, language.deposit_err_02)
			.required(language.deposit_err_03)
			.typeError(language.deposit_err_04),
		bonuses: Yup.object().test(
			"is-empty",
			language.bonus_err_01,
			(bonuses) => !!Object.keys(bonuses).length && bonuses.hasOwnProperty(AGREE_CHECKBOX.code)
		)
	});
}
