import React from "react";

const MyTradingIcon = (props:any) => {
	let width = 16;
	let fill = "#fff";
	if (props.width) {
		width = props.width;
	}
	if (props.fill) {
		fill = props.fill;
	}

	return (
		<svg width={width} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 16">
			<defs>
				<clipPath id="clipPath">
					<rect id="Прямоугольник_80" data-name="Прямоугольник 80" width={width} transform="translate(20 22)" fill={fill}/>
				</clipPath>
			</defs>
			<g id="Группа_масок_26" data-name="Группа масок 26" transform="translate(-20 -22)" clipPath="url(#clipPath)">
				<g id="icon-fm-trading" transform="translate(19.071 21.087)">
					<rect id="Прямоугольник_81" data-name="Прямоугольник 81" width="1.207" height="6.365" transform="translate(12.989 7.579)" fill="none"/>
					<rect id="Прямоугольник_82" data-name="Прямоугольник 82" width="1.207" height="6.365" transform="translate(3.661 7.579)" fill="none"/>
					<rect id="Прямоугольник_83" data-name="Прямоугольник 83" width="1.207" height="6.42" transform="translate(8.325 3.793)" fill="none"/>
					<g id="Сгруппировать_120" data-name="Сгруппировать 120">
						<path id="Контур_164" data-name="Контур 164" d="M5.417,6.481h-.6V4.506a.442.442,0,0,0-.01-.089H3.724a.529.529,0,0,0-.008.089V6.481h-.6a.518.518,0,0,0-.549.549v7.462a.518.518,0,0,0,.549.549h.6v1.872h1.1V15.041h.6a.55.55,0,0,0,.549-.549V7.03A.55.55,0,0,0,5.417,6.481Zm-.549,7.462H3.661V7.579H4.868Z" fill="#417cbf"/>
						<path id="Контур_165" data-name="Контур 165" d="M10.081,2.7h-.6V.913H8.38V2.7h-.6a.55.55,0,0,0-.549.549v7.517a.55.55,0,0,0,.549.549h.6v1.879h1.1V11.31h.6a.59.59,0,0,0,.549-.549V3.244A.59.59,0,0,0,10.081,2.7Zm-.549,7.517H8.325V3.793H9.532Z" fill="#417cbf"/>
						<path id="Контур_166" data-name="Контур 166" d="M14.745,6.481h-.6V4.506a.442.442,0,0,0-.01-.089H13.052a.529.529,0,0,0-.008.089V6.481h-.6a.518.518,0,0,0-.549.549v7.462a.518.518,0,0,0,.549.549h.6v1.872h1.1V15.041h.6a.55.55,0,0,0,.549-.549V7.03A.55.55,0,0,0,14.745,6.481ZM14.2,13.944H12.989V7.579H14.2Z" fill="#417cbf"/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default MyTradingIcon;
