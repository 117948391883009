/*
    Created on : 20.01.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import "./PageReferrals.scss";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../interfaces/StoreInterfaces";
import PageTitle from "../../../components/Dashboard/dashboardComponents/PageTitle";
import Title from "../../../components/Typography/Title";
import SecondaryText from "../components/SecondaryText";
import FMTable from "../../../components/FMTable/FMTable";
import FMTableColumn from "../../../components/FMTable/FMTableColumn";

export interface IPartnershipReferralsData {
	account: number | string
	date: string
	status: number
	total: number
};

export default function PageReferrals () {
	const lang:any = useSelector((state:StoreInterface) => state.language.language);
	const langPartnership:any = useSelector((state:StoreInterface) => state.language.languagePartnership);
	const referralsData: IPartnershipReferralsData[] = useSelector((state:StoreInterface) => state.partnership.referrals.data);

	return (
		<div className="page-partnership-referrals">
			<PageTitle
				layoutTitle={lang.sb_li_4}
				pageTitle={langPartnership.parnav_06}
			/>
			<Title mb={"25px"}>{langPartnership.com_01}</Title>
			<FMTable
				data={referralsData}
				rowsPerPageOptions={[ 5, 10, 25, 50, 100 ]}
				withPeriodFilter={true}
				withPeriodButtons={true}
				withChart={true}
				withExport={true}
				chartScheme={{ xColumnId: "date", yColumnId: "total" }}
				innerComponent={<SecondaryText>Trail the number of clicks on your affiliate link in real-time to optimize your marketing activities</SecondaryText>}
				mobileScheme={{ mode: "auto" }}
				theme="PartnershipTheme"
			>
				<FMTableColumn id="account" label="Acc number / Acc type" />
				<FMTableColumn id="date" label="Registration date" />
				<FMTableColumn id="status" label="Verified Status" />
				<FMTableColumn id="total" label="Total" />
			</FMTable>
		</div>
	);
};

// const testDate = [
// 	{ account: 12345878, date: "2021-01-01 18:23:53", status: 1, total: 333 },
// 	{ account: 12345878, date: "2021-02-01 18:23:53", status: 1, total: 332 },
// 	{ account: 12345878, date: "2021-03-01 18:23:53", status: 1, total: 331 },
// 	{ account: 12345878, date: "2021-04-01 18:23:53", status: 1, total: 330 },
// 	{ account: 12345878, date: "2021-05-01 18:23:53", status: 1, total: 222 },
// 	{ account: 12345878, date: "2021-06-01 18:23:53", status: 1, total: 221 },
// 	{ account: 12345878, date: "2021-07-01 18:23:53", status: 1, total: 220 },
// 	{ account: 12345878, date: "2021-08-01 18:23:53", status: 1, total: 111 },
// 	{ account: 12345878, date: "2021-09-01 18:23:53", status: 1, total: 110 },
// 	{ account: 12345878, date: "2021-10-01 18:23:53", status: 1, total: 100 },
// 	{ account: 12345878, date: "2021-11-01 18:23:53", status: 1, total: 99 },
// 	{ account: 12345878, date: "2021-12-01 18:23:53", status: 1, total: 98 },
// 	{ account: 12345878, date: "2022-01-01 18:23:53", status: 1, total: 97 },
// 	{ account: 12345878, date: "2022-02-01 18:23:53", status: 1, total: 98 },
// 	{ account: 12345878, date: "2022-03-01 18:23:53", status: 1, total: 95 },
// 	{ account: 12345878, date: "2022-04-01 18:23:53", status: 1, total: 94 },
// 	{ account: 12345878, date: "2022-05-01 18:23:53", status: 1, total: 93 },
// 	{ account: 12345878, date: "2022-06-01 18:23:53", status: 1, total: 92 },
// 	{ account: 12345878, date: "2022-07-01 18:23:53", status: 1, total: 91 }
// ];
