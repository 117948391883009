import * as React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useIsRtl } from "../../../utils/hooks/useIsRtl";
import { DefaultWrapperProps } from "./Default";

export function WrapperForTableDate (props: DefaultWrapperProps) {
	const { label, children } = props;
	const isRtl = useIsRtl();

	return (
		<Grid
			container
			justifyContent="space-between"
			alignItems="center"
			flexDirection={isRtl ? "row-reverse" : "row"}
			flexWrap="wrap"
			sx={{
				position: "relative",
				marginX: "15px",
				maxWidth: "250px"
			}}
		>
			<Typography
				sx={{
					width: "100%",
					lineHeight: "19px",
					marginBottom: "8px",
					color: "#5C7080",
					fontWeight: "bold"
				}}
				fontSize={15}
				textAlign={isRtl ? "right" : "left"}
			>
				{label}
			</Typography>
			<Box
				sx={{
					position: "relative",
					width: "100%"
				}}
			>
				{children}
			</Box>
		</Grid>
	);
}
