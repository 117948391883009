import { Link, Typography } from "@mui/material";
import * as React from "react";
import { useAppSelector } from "../../../utils/hooks/reduxHooks";
import { useIsRtl } from "../../../utils/hooks/useIsRtl";

const _links = {
	twpb: {
		value: "20",
		href: "https://www.forexmart.com/twenty-percent-bonus-agreement"
	},
	tpb: {
		value: "30",
		href: "https://www.forexmart.com/thirty-percent-bonus-agreement"
	},
	fpb: {
		value: "50",
		href: ""
	},
	hpb: {
		value: "100",
		href: ""
	},
	none: {
		value: "0",
		href: ""
	}
};

type Links = typeof _links

export interface AgreeLinkProps {
	bonus: keyof Links
}

export function BonusAgreeLink (props: AgreeLinkProps): JSX.Element {
	const { bonus } = props;
	const language = useAppSelector((state) => state.language.languageFinance);
	const isRtl = useIsRtl();

	const linksFromServer = useAppSelector((state) => state.init.init.links.link_bonus_agreement);
	const links: Links = linksFromServer ?? _links;
	console.log("Links", links);
	return (
		<Typography fontWeight={"lighter"} color={"#5C7080"}>
			{
				isRtl
					? (
						<>
							<Link fontWeight={"bold"} href={links[bonus]?.href}>Bonus Agreement %{links[bonus]?.value} </Link>
							{language.i_agree_1}
						</>
					)
					: (
						<>
							{language.i_agree_1}
							<Link fontWeight={"bold"} href={links[bonus]?.href}> {links[bonus]?.value}% Bonus Agreement</Link>
						</>
					)
			}
		</Typography>
	);
}
