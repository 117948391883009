import React from "react";

export function useRedirectFormControls () {
	const ref = React.useRef<HTMLFormElement | null>(null);
	const [ params, setParams ] = React.useState<{ [key: string]: string | number }>({});

	React.useEffect(function () {
		if (ref.current && Object.keys(params).length) {
			ref.current?.submit();
		}
	}, [ params ]);

	return {
		ref,
		params,
		setParams
	};
}
