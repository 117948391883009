import * as Yup from "yup";

export const zotaPayDepositValidationSchema = () => Yup.object().shape({
	accountNumber: Yup.string().required(),
	amount: Yup.string().required(),
	banks: Yup.string().required(),
	phone: Yup.string().required(),
	firstName: Yup.string().required(),
	lastName: Yup.string().required(),
	address: Yup.string().required(),
	city: Yup.string().required(),
	zip: Yup.string().required()
});
