import axios from "axios";
import { getLocalStorageItem } from "./LocalStorage";

const Url = "https://my.forexmart.com/react/";
// const Url = window.location.protocol + "//" + window.location.host + "/react/";
// export const Url = window.location.host.includes("localhost") ? "https://my.forexmart.com/react/" : "https://my.forexmart.local-dev/react/";

const API = axios.create({
	baseURL: Url,
	params: {
		v: "2"
	},
	headers: {
		// 'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, access-control-allow-origin, Content-Type, Access-Control-Allow-Headers, Access-Control-Allow-Credentials, Authorization, X-Requested-With',
		// 'Access-Control-Allow-Origin': 'http://localhost:3000',
		// Authorization: `Bearer ${api_token}`
	},
	withCredentials: !window.location.host.includes("localhost"),
	responseType: "json"

});

API.interceptors.request.use((config) => {
	config.headers = config.headers || {};
	config.params = config.params || {};
	config.params.euid = getLocalStorageItem("euid") ? getLocalStorageItem("euid") : null;
	config.params.eacn = getLocalStorageItem("eacn") ? getLocalStorageItem("eacn") : null;
	config.params.ci_session = getLocalStorageItem("ci_session") ? getLocalStorageItem("ci_session") : null;
	return config;
});

export default API;
