import * as React from "react";
import Box from "@mui/material/Box";
import { useIsRtl } from "../../utils/hooks/useIsRtl";
import DashboardSidebarMobile from "./DashboardSidebarMobile";
import DashboardAppBarMobile from "./DashboardAppBarMobile";
import DashboardMobileContent from "./DashboardMobileContent";

const drawerWidth: number = 276;
export default function DashboardMobile (props: any) {
	const [ open, setOpen ] = React.useState(false);
	const [ accordion, setAccordion ] = React.useState<string | false>(false);
	const isRtl = useIsRtl();

	return (
		<Box sx={{ display: "flex" }} className={"flexRow"}>
			<Box component="main"
				sx={{
					display: "flex",
					flexDirection: "column",
					position: "relative",
					flexGrow: 1,
					overflow: "hidden",
					maxWidth: "100%",
					minHeight: "max-content",
					alignItems: isRtl ? "flex-end" : "flex-start",
					width: `calc(100% - ${drawerWidth}px)`
				}}
			>
				<DashboardAppBarMobile
					setOpen={setOpen}
					setAccordion={setAccordion}
				/>
				<DashboardMobileContent { ...props }/>
			</Box>
			{/* <DashboardSocialBox/> */}
			{/* <DashboardLveAgent/> */}
			<DashboardSidebarMobile
				open={open}
				setOpen={setOpen}
				accordion={accordion}
				setAccordion={setAccordion}
			/>
		</Box>
	);
}
