import React from "react";

const LinkedinIcon = (props:any) => {
	let fill = "#0073B1";
	if (props.fill) {
		fill = props.fill;
	}

	return (
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" style={{ width: "100%" }} viewBox="171.167 0 48 48" xmlSpace="preserve">
			<g>
				<path fill={fill} d="M176.661,43.84h5.359c0.741,0,1.34-0.602,1.34-1.338V19.075c0-0.741-0.599-1.34-1.34-1.34h-5.359
			    c-0.737,0-1.338,0.6-1.338,1.34v23.427C175.323,43.238,175.924,43.84,176.661,43.84z"/>
				<path fill={fill} d="M176.661,13.617h5.359c0.741,0,1.34-0.599,1.34-1.339v-4.76c0-0.738-0.599-1.338-1.34-1.338h-5.359
			    c-0.737,0-1.338,0.6-1.338,1.338v4.76C175.323,13.018,175.924,13.617,176.661,13.617z"/>
				<path fill={fill} d="M214.534,20.452c-0.753-0.991-1.864-1.806-3.333-2.445c-1.468-0.64-3.089-0.958-4.864-0.958
                c-3.6,0-6.648,1.374-9.149,4.122c-0.498,0.547-0.86,0.427-0.86-0.312v-1.784c0-0.741-0.599-1.34-1.337-1.34h-4.788
                c-0.738,0-1.34,0.6-1.34,1.34v23.427c0,0.736,0.602,1.338,1.34,1.338h5.359c0.739,0,1.339-0.602,1.339-1.338v-8.149
                c0-3.394,0.205-5.719,0.614-6.978c0.411-1.258,1.169-2.269,2.275-3.032c1.106-0.763,2.354-1.146,3.744-1.146
                c1.089,0,2.019,0.269,2.79,0.803c0.772,0.533,1.331,1.282,1.675,2.245c0.343,0.963,0.513,3.084,0.513,6.363v9.894
                c0,0.736,0.601,1.338,1.34,1.338h5.359c0.74,0,1.339-0.602,1.339-1.338v-13.27c0-2.344-0.148-4.146-0.444-5.406
                C215.812,22.569,215.286,21.445,214.534,20.452z"/>
			</g>
		</svg>
	);
};

export default LinkedinIcon;
