/*
    Created on : 12.10.2022, 06:18:59
    Author     : Andy Kar
*/
import { takeEvery, put, call } from "redux-saga/effects";
import {
	FETCH_LANGUAGE_INFO,
	SET_LANGUAGE_INFO,
	CHANGE_LANGUAGE,
	FETCH_LANGUAGE_COPYTRADE,
	SET_LANGUAGE_COPYTRADE,
	SET_SESSION_LANG,
	FETCH_LANGUAGE_TRADING,
	SET_LANGUAGE_TRADING,
	FETCH_LANGUAGE_DATATABLE,
	SET_LANGUAGE_DATATABLE,
	FETCH_LANGUAGE_MY_ACCOUNTS,
	SET_LANGUAGE_MY_ACCOUNTS,
	SET_LANGUAGE_FINANCE,
	FETCH_LANGUAGE_FINANCE,
	// FETCH_LANGUAGE_MY_TRADING,
	// SET_LANGUAGE_MY_TRADING,
	SET_LANGUAGE_BONUS,
	FETCH_LANGUAGE_BONUS,
	FETCH_LANGUAGE_PARTNERSHIP,
	SET_LANGUAGE_PARTNERSHIP,
	FETCH_LANGUAGE_SMART_DOLLAR,
	SET_LANGUAGE_SMART_DOLLAR,
	SET_LANGUAGE_TRADES,
	FETCH_LANGUAGE_TRADES,
	SET_LANGUAGE_MAIL_SUPPORT,
	FETCH_LANGUAGE_MAIL_SUPPORT,
	SET_LANGUAGE_BANNERS,
	FETCH_LANGUAGE_BANNERS
} from "../store/actions/actionTypes";
import API from "../utils/API";
import API_LANG from "../utils/API_LANG";
import { ResponseGenerator } from "../interfaces/mainInterfaces";

/**
 Get language variables from server and set it to store
**/
export function * languageWatcher () {
	yield takeEvery(FETCH_LANGUAGE_INFO, languageWorker);
}
export function * languageWorker (action:{type:string, language: string}) {
	const data:ResponseGenerator = yield call(getLanguageInfo, action.language);
	if (Object.keys(data).length) { yield put({ type: SET_LANGUAGE_INFO, payload: data }); }
}
async function getLanguageInfo (language:string = "english") {
	const response:ResponseGenerator = await API_LANG.get(language + "/init.json");
	return response?.data || {};
}

/**
 Change language in session on server (method React/changeLanguage)
 and get new language variables
 **/
export function * changeLanguageWatcher () {
	yield takeEvery(CHANGE_LANGUAGE, changeLanguageWorker);
}
export function * changeLanguageWorker (action:{type:string, language: string}) {
	yield put({ type: SET_SESSION_LANG, payload: { site_lang: action.language } });
	const data: {changed?: boolean, language?: string} = yield call(changeLanguageInfo, action.language);
	if (data.changed) {
		// yield put({type: FETCH_SESSION_INFO})
	}
}
async function changeLanguageInfo (language:string) {
	const response:any = await API.get("changeLanguage", { params: { language } });
	return response?.data || {};
}

/**
 Get language Copytrade variables from server and set it to store
**/
export function * languageCopytradeWatcher () {
	yield takeEvery(FETCH_LANGUAGE_COPYTRADE, languageCopytradeWorker);
}
export function * languageCopytradeWorker (action:{type:string, language: string}) {
	const data: {} = yield call(getLanguageCopytradeInfo, action.language);
	if (Object.keys(data).length) { yield put({ type: SET_LANGUAGE_COPYTRADE, payload: data }); }
}
async function getLanguageCopytradeInfo (language:string = "english") {
	const response:any = await API_LANG.get(language + "/copytrade.json");
	return response?.data || {};
}
/**
 Get language My Accounts variables from server and set it to store
**/
export function * languageMyAccountsWatcher () {
	yield takeEvery(FETCH_LANGUAGE_MY_ACCOUNTS, languageMyAccountsWorker);
}
export function * languageMyAccountsWorker (action:{type:string, language: string}) {
	const data: {} = yield call(getLanguageMyAccountsInfo, action.language);
	if (Object.keys(data).length) { yield put({ type: SET_LANGUAGE_MY_ACCOUNTS, payload: data }); }
}
async function getLanguageMyAccountsInfo (language:string = "english") {
	const response:any = await API_LANG.get(language + "/myAccounts.json");
	return response?.data || {};
}
// /**
//  Get language My Trading variables from server and set it to store
// **/
// export  function* languageMyTradingWatcher() {
//     yield takeEvery(FETCH_LANGUAGE_MY_TRADING, languageMyTradingWorker)
// }
// export function* languageMyTradingWorker(action:{type:string, language: string}) {
//     const data: {} = yield call(getLanguageMyTradingInfo, action.language)
//     if(Object.keys(data).length){ yield put({type: SET_LANGUAGE_MY_TRADING, payload: data}) }
// }
// async function getLanguageMyTradingInfo(language:string = 'english') {
//     const response:any = await API_LANG.get(language + '/myTrading.json')
//     return response?.data || {}
// }
/**
 Get language Finance variables from server and set it to store
**/
export function * languageFinanceWatcher () {
	yield takeEvery(FETCH_LANGUAGE_FINANCE, languageFinanceWorker);
}
export function * languageFinanceWorker (action:{type:string, language: string}) {
	const data: {} = yield call(getLanguageFinanceInfo, action.language);
	if (Object.keys(data).length) { yield put({ type: SET_LANGUAGE_FINANCE, payload: data }); }
}
async function getLanguageFinanceInfo (language:string = "english") {
	const response:any = await API_LANG.get(language + "/finance.json");
	return response?.data || {};
}
/**
 Get language Bonus variables from server and set it to store
**/
export function * languageBonusWatcher () {
	yield takeEvery(FETCH_LANGUAGE_BONUS, languageBonusWorker);
}
export function * languageBonusWorker (action:{type:string, language: string}) {
	const data: {} = yield call(getLanguageBonusInfo, action.language);
	if (Object.keys(data).length) { yield put({ type: SET_LANGUAGE_BONUS, payload: data }); }
}
async function getLanguageBonusInfo (language:string = "english") {
	const response:any = await API_LANG.get(language + "/bonus.json");
	return response?.data || {};
}
/**
 Get language Partnership variables from server and set it to store
**/
export function * languagePartnershipWatcher () {
	yield takeEvery(FETCH_LANGUAGE_PARTNERSHIP, languagePartnershipWorker);
}
export function * languagePartnershipWorker (action:{type:string, language: string}) {
	const data: {} = yield call(getLanguagePartnershipInfo, action.language);
	if (Object.keys(data).length) { yield put({ type: SET_LANGUAGE_PARTNERSHIP, payload: data }); }
}
async function getLanguagePartnershipInfo (language:string = "english") {
	const response:any = await API_LANG.get(language + "/partnership.json");
	return response?.data || {};
}
/**
 Get language Smart Dollar variables from server and set it to store
**/
export function * languageSmartDollarWatcher () {
	yield takeEvery(FETCH_LANGUAGE_SMART_DOLLAR, languageSmartDollarWorker);
}
export function * languageSmartDollarWorker (action:{type:string, language: string}) {
	const data: {} = yield call(getLanguageSmartDollarInfo, action.language);
	if (Object.keys(data).length) { yield put({ type: SET_LANGUAGE_SMART_DOLLAR, payload: data }); }
}
async function getLanguageSmartDollarInfo (language:string = "english") {
	const response:any = await API_LANG.get(language + "/smartDollar.json");
	return response?.data || {};
}
/**
 Get language Datatable variables from server and set it to store
**/
export function * languageDatatableWatcher () {
	yield takeEvery(FETCH_LANGUAGE_DATATABLE, languageDatatableWorker);
}
export function * languageDatatableWorker (action:{type:string, language: string}) {
	const data: {} = yield call(getLanguageDatatableInfo, action.language);
	if (Object.keys(data).length) { yield put({ type: SET_LANGUAGE_DATATABLE, payload: data }); }
}
async function getLanguageDatatableInfo (language:string = "english") {
	const response:any = await API_LANG.get(language + "/dataTable.json");
	return response?.data || {};
}
/**
 Get language Trading variables from server and set it to store
**/
export function * languageTradingWatcher () {
	yield takeEvery(FETCH_LANGUAGE_TRADING, languageTradingWorker);
}
export function * languageTradingWorker (action:{type:string, language: string}) {
	const data: {} = yield call(getLanguageTradingInfo, action.language);
	if (Object.keys(data).length) { yield put({ type: SET_LANGUAGE_TRADING, payload: data }); }
}
async function getLanguageTradingInfo (language:string = "english") {
	const response:any = await API_LANG.get(language + "/trading.json");
	return response?.data || {};
}

/**
 Get language Trades variables from server and set it to store
**/
export function * languageTradesWatcher () {
	yield takeEvery(FETCH_LANGUAGE_TRADES, languageTradesWorker);
}
export function * languageTradesWorker (action:{type:string, language: string}) {
	const data: {} = yield call(getLanguageTradesInfo, action.language);
	if (Object.keys(data).length) { yield put({ type: SET_LANGUAGE_TRADES, payload: data }); }
}
async function getLanguageTradesInfo (language:string = "english") {
	const response:any = await API_LANG.get(language + "/trades.json");
	return response?.data || {};
}

/**
 Get language mail support variables from server and set it to store
**/
export function * languageMailSupportTradesWatcher () {
	yield takeEvery(FETCH_LANGUAGE_MAIL_SUPPORT, languageMailSupportWorker);
}
export function * languageMailSupportWorker (action:{type:string, language: string}) {
	const data: {} = yield call(getLanguageMailSupportInfo, action.language);
	if (Object.keys(data).length) { yield put({ type: SET_LANGUAGE_MAIL_SUPPORT, payload: data }); }
}
async function getLanguageMailSupportInfo (language:string = "english") {
	const response:any = await API_LANG.get(language + "/mailSupport.json");
	return response?.data || {};
}

/**
 Get language banners variables from server and set it to store
**/
export function * languageBannerWatcher () {
	yield takeEvery(FETCH_LANGUAGE_BANNERS, languageBannersWorker);
}
export function * languageBannersWorker (action:{type:string, language: string}) {
	const data: {} = yield call(getLanguageBannerInfo, action.language);
	if (Object.keys(data).length) { yield put({ type: SET_LANGUAGE_BANNERS, payload: data }); }
}
async function getLanguageBannerInfo (language:string = "english") {
	const response:any = await API_LANG.get(language + "/banner.json");
	return response?.data || {};
}
