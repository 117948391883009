import * as React from "react";
import { DepositAsianBankForms } from "../../../../components/Forms/DepositAsianBankForms";
import { TDepositAsianBankFormsOne } from "../../../../components/Forms/DepositAsianBankForms/One";
import API from "../../../../utils/API";
import { AxiosResponse } from "axios";
import { getEmptyForm } from "../../../../components/Forms/EmptyForm/getEmptyForm";
import { useRedirectFormControls } from "../../../../utils/hooks/useEmptyForm";
import { TResponseWithGatewayParams } from "../types";

const REDIRECT_URL = "https://payment.pa-sys.com/app/page/52574773-a2bc-4fa5-bd1f-2208f8f5faa4";

const RedirectForm = getEmptyForm(REDIRECT_URL);

export function GCash () {
	const {
		ref,
		params,
		setParams
	} = useRedirectFormControls();
	async function submit (values: TDepositAsianBankFormsOne) {
		const formData = new FormData();

		formData.append("amount1", values.amount);
		formData.append("amount", values.amount);
		formData.append("first_name", values.firstName);
		formData.append("last_name", values.lastName);
		formData.append("phone_number", values.phone);

		const { data } = await API.post<unknown, AxiosResponse<TResponseWithGatewayParams>>("depositGCash", formData);

		setParams(data.params.fields);
	}

	return (
		<>
			<DepositAsianBankForms.One
				showRepeater={false}
				submit={submit}
				currency="PHP"
			/>
			<RedirectForm refValue={ref} params={params} />
		</>
	);
}
