import { Typography } from "@mui/material";
import * as React from "react";
import { useWatch } from "react-hook-form";
import { FieldWrapper as FieldWrapperUI } from "../../UI/FieldWrapper";
import { FormContext } from "../Form";

export interface InputRepeaterProps {
	fields: string;
	label: string;
	tips?: string;
	onChange?: (value: any) => string;
}

export function InputRepeater (props: InputRepeaterProps) {
	const {
		fields,
		label,
		tips,
		onChange = (values: any) => values.join(",")
	} = props;

	const [ value, setValue ] = React.useState("");
	const { control, fieldWrapperVariant } = React.useContext(FormContext);
	const FieldWrapper = React.useMemo(() => FieldWrapperUI[fieldWrapperVariant], [ fieldWrapperVariant ]);

	const values = useWatch({
		control,
		name: fields.split(",")
	});

	React.useEffect(() => {
		setValue(onChange(values));
	}, values);

	return (
		<FieldWrapper label={label} tips={tips}>
			<Typography
				textAlign="left"
				color="#7A8B98"
				sx={{
					width: "100%",
					padding: "10px",
					border: "1px solid #7A8B98",
					borderRadius: "4px",
					background: "#eee"
				}}
			>
				{value}
			</Typography>
		</FieldWrapper>
	);
}
