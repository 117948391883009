import React from "react";
import PageTitle from "../../../components/Dashboard/dashboardComponents/PageTitle";

import { useAppSelector } from "../../../utils/hooks/reduxHooks";
import { SimpleTabs } from "../../../components/UI/Tabs";
import { AvatarChanger } from "./Tabs/AvatarChanger/AvatarChanger";
import { AccountInformation } from "./Tabs/AccountInformation";
import { CopyingTerms } from "./Tabs/CopyingTerms";
import { ContactInformation } from "./Tabs/ContactInformation";
import { SetupNotifications } from "./Tabs/SetupNotifications";

export default function PageCopytradeProfile () {
	const lang = useAppSelector((state) => state.language.language);
	const trading = useAppSelector((state) => state.language.languageTrading);
	const langCopytrade = useAppSelector((state) => state.language.languageCopytrade);

	return (
		<>
			<PageTitle
				layoutTitle={lang.sb_li_12}
				pageTitle={langCopytrade.ctrd_menu_5}
			/>
			<SimpleTabs
				tabSx={{
					padding: 0,
					border: 0
				}}
				labels={[
					trading.trd_174,
					trading.trd_179,
					langCopytrade.reg_trader_11,
					trading.trd_189,
					trading.trd_194
				]}
			>
				<AvatarChanger />
				<AccountInformation />
				<CopyingTerms />
				<ContactInformation />
				<SetupNotifications />
			</SimpleTabs>
		</>
	);
}
