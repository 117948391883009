/*
    Created on : 12.11.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { useSelector } from "react-redux";
import {
	StoreInterface, TradersItemInterface
} from "../../../../interfaces/StoreInterfaces";
import CustomButton from "../../../../components/CustomButton";
import { IFMTableComponentProps } from "../../../../components/FMTable/FMTableCellComponent";
import { SxProps } from "@mui/material";
import { useAdaptive } from "../../../../utils/hooks/themeBreakpointsHooks";

const xsButtonStyles: SxProps = {
	width: "90px"
};

const smButtonStyles: SxProps = {
	width: "95px"
};

export default function TraderActivityCell (props: IFMTableComponentProps<TradersItemInterface, {}>) {
	const { toggleCollapsible, SubscriptionStatus } = props;
	const langCopytrade:any = useSelector((state:StoreInterface) => state.language.languageCopytrade);
	const adaptiveButtonStyles = useAdaptive({
		xs: xsButtonStyles,
		sm: smButtonStyles
	});

	return <CustomButton
		sx={adaptiveButtonStyles}
		onClick={toggleCollapsible}
		text={langCopytrade.copytrade_62}
		disabled={SubscriptionStatus === 1}
	/>;
}
