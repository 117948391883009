/*
    Created on : 20.01.2022, 06:18:59
    Author     : Andy Kar
*/
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import {
	FETCH_BALANCE_OPERATIONS,
	FETCH_LANGUAGE_FINANCE,
	FETCH_PANDING_TRANSACTIONS, FETCH_USER_BONUSES_INFO,
	FETCH_USER_PAYMENT_METHODS_INFO,
	FETCH_USER_WITHDRAW_METHODS_INFO
} from "../../store/actions/actionTypes";
import {
	StoreInterface
} from "../../interfaces/StoreInterfaces";
import "./LayoutFinance.scss";
import PageTransfer from "../../pages/Finance/PageTransfer/PageTransfer";
import PageTransactionHistory from "../../pages/Finance/PageTransactionHistory/PageTransactionHistory";
import { DepositLayout } from "./layouts";
import { WithdrawLayout } from "./layouts/WithdrawLayout";

const LayoutFinance = (props:any) => {
	const siteLang:string|undefined = useSelector((state:StoreInterface) => state.session.userData.site_lang);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch({ type: FETCH_PANDING_TRANSACTIONS });
		dispatch({ type: FETCH_BALANCE_OPERATIONS });
		dispatch({ type: FETCH_USER_WITHDRAW_METHODS_INFO });
		dispatch({ type: FETCH_USER_PAYMENT_METHODS_INFO });
		dispatch({ type: FETCH_USER_BONUSES_INFO });
	}, []);

	useEffect(() => {
		dispatch({ type: FETCH_LANGUAGE_FINANCE, language: siteLang });
	}, [ siteLang, dispatch ]);

	return (
		<div className="layout layout-finance">
			<Switch>
				<Route path="/app/deposit" render={() => <DepositLayout />} />
				<Route path="/app/withdraw" render={() => <WithdrawLayout />} />
				<Route exact path="/app/transfer" render={() => <PageTransfer {...props} />} />
				<Route exact path="/app/transaction-history" render={() => <PageTransactionHistory {...props} />} />
			</Switch>
		</div>
	);
};

export default withRouter(LayoutFinance);
