import React from "react";

const WithdrawFundsIcon = (props: any) => {
	let width = 16;
	let fill = "#fff";
	if (props.width) {
		width = props.width;
	}
	if (props.fill) {
		fill = props.fill;
	}

	return (
		<svg width={width} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 16">
			<defs>
				<clipPath id="clip-path">
					<rect width="16" height="16" transform="translate(35 595)" fill={fill} stroke="#707070" strokeWidth="1"/>
				</clipPath>
			</defs>
			<g transform="translate(-35 -595)" clipPath="url(#clip-path)">
				<g id="icon-fm-invert-deposit-02" transform="translate(33.679 520.005)">
					<path d="M7.715,76.388a2.3,2.3,0,0,0-1.631.669L1.992,81.15a2.318,2.318,0,0,0,0,3.261L6.382,88.8H1.321v.8h16v-.8H14.7l1.563-1.562a2.319,2.319,0,0,0,0-3.261L9.345,77.057A2.3,2.3,0,0,0,7.715,76.388Zm0,1.586a.707.707,0,0,1,.5.216l6.922,6.92a.688.688,0,0,1,0,1L12.59,88.654l-.148.148h-3.8l-.108-.108L3.123,83.28a.688.688,0,0,1,0-1L7.215,78.19a.7.7,0,0,1,.5-.216Zm1.609,4.117a1.245,1.245,0,0,0-.759.406,1.208,1.208,0,0,0,0,1.7l1.131,1.131a.381.381,0,0,1,0,.566.55.55,0,0,1-.27.177c-.047.008-.118,0-.294-.175l-1.981-1.98-.566.566,1.13,1.13-.577.578.566.566.577-.578.281.281a1.2,1.2,0,0,0,.942.409,1.246,1.246,0,0,0,.758-.406,1.206,1.206,0,0,0,0-1.7L9.129,83.632a.383.383,0,0,1,0-.567A.544.544,0,0,1,9.4,82.89c.048-.008.118-.008.291.172h0l1.416,1.417.566-.566-.566-.566.564-.564-.566-.566-.564.564-.28-.28a1.191,1.191,0,0,0-.941-.411Z" fill={fill}/>
				</g>
			</g>
		</svg>
	);
};

export default WithdrawFundsIcon;
