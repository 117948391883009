import * as React from "react";
import { Typography } from "@mui/material";
import { useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { StatusCode } from "../types";
import { IFMTableComponentProps } from "../../../../components/FMTable/FMTableCellComponent";
import { Trader } from "../../../../interfaces/CopytradeInterfaces";

export function StatusCell (props: IFMTableComponentProps<Trader, {}>) {
	const { StatusId } = props;
	const { languageCopytrade, languageFinance } = useAppSelector((status) => status.language);

	const statusDescriptions = React.useMemo(() => {
		return {
			[StatusCode.APPROVED]: languageCopytrade.oth_com_02,
			[StatusCode.PENDING]: languageFinance.oth_com_03,
			[StatusCode.CANCELED_BY_FOLLOWER]: languageCopytrade.canceled_by_follower,
			[StatusCode.CANCELED_BY_TRADER]: languageCopytrade.canceled_by_trader
		};
	}, []);

	return (
		<>
			<Typography>{ statusDescriptions[StatusId] }</Typography>
		</>
	);
};
