/*
    Created on : 02.11.2022, 06:18:59
    Author     : Andy Kar
*/
import {
	SET_PARTNERSHIP_CLICKS_DATA,
	SET_PARTNERSHIP_CLICKS_LOADING,
	SET_PARTNERSHIP_COMMISSION_DATA,
	SET_PARTNERSHIP_COMMISSION_LOADING,
	SET_PARTNERSHIP_DATA,
	SET_PARTNERSHIP_FILTERED_CLICKS_DATA,
	SET_PARTNERSHIP_LOADING,
	SET_PARTNERSHIP_REFERRALS_DATA,
	SET_PARTNERSHIP_REFERRALS_LOADING, SET_PARTNERSHIP_REFERRALS_REGISTRATION_DATA,
	SET_PARTNERSHIP_REFERRALS_REGISTRATION_LOADING,
	SET_PARTNERSHIP_SECURITY_CODE_DATA,
	SET_PARTNERSHIP_SECURITY_CODE_LOADING
} from "../actions/actionTypes";

const initial = {
	info: {},
	commission: {},
	clicks: {},
	referrals: {},
	referralsRegistration: [],
	security: {},
	loading: false,
	commissionLoading: false,
	clicksLoading: false,
	referralsLoading: false,
	referralsRegistrationLoading: false,
	securityCodeLoading: false
};

export default function partnershipReducer (state = initial, action:{type:string, payload:any}) {
	switch (action.type) {
		case SET_PARTNERSHIP_DATA:
			return {
				...state,
				info: action.payload
			};
		case SET_PARTNERSHIP_COMMISSION_DATA:
			return {
				...state,
				commission: action.payload
			};
		case SET_PARTNERSHIP_CLICKS_DATA:
			return {
				...state,
				clicks: action.payload
			};
		case SET_PARTNERSHIP_FILTERED_CLICKS_DATA:
			return {
				...state,
				clicks: {
					...state.clicks,
					data: action.payload.data,
					charts_data: action.payload.charts_data
				}
			};
		case SET_PARTNERSHIP_REFERRALS_DATA:
			return {
				...state,
				referrals: action.payload
			};
		case SET_PARTNERSHIP_REFERRALS_REGISTRATION_DATA:
			return {
				...state,
				referralsRegistration: action.payload
			};
		case SET_PARTNERSHIP_SECURITY_CODE_DATA:
			return {
				...state,
				security: action.payload
			};
		case SET_PARTNERSHIP_LOADING:
			return {
				...state,
				loading: action.payload
			};
		case SET_PARTNERSHIP_COMMISSION_LOADING:
			return {
				...state,
				commissionLoading: action.payload
			};
		case SET_PARTNERSHIP_CLICKS_LOADING:
			return {
				...state,
				clicksLoading: action.payload
			};
		case SET_PARTNERSHIP_REFERRALS_LOADING:
			return {
				...state,
				referralsLoading: action.payload
			};
		case SET_PARTNERSHIP_REFERRALS_REGISTRATION_LOADING:
			return {
				...state,
				referralsRegistrationLoading: action.payload
			};
		case SET_PARTNERSHIP_SECURITY_CODE_LOADING:
			return {
				...state,
				securityCodeLoading: action.payload
			};
		default:
			return state;
	}
}
