/* eslint-disable camelcase */
/*
    Created on : 20.01.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import AccountTypes from "../../../layouts/components/AccountTypes/AccountTypes";
import PageTitle from "../../../components/Dashboard/dashboardComponents/PageTitle";
import { useAppSelector } from "../../../utils/hooks/reduxHooks";
import { validationSchema } from "./validation";
import { Box, Grid } from "@mui/material";
import { Form } from "../../../components/Form";
import { Input } from "../../../components/Form/Input";
import { Textarea } from "../../../components/Form/Textarea";
import { ButtonWithLoader } from "../../../components/ButtonWithLoader";
import { Row } from "../../../components/UI/Row";
import { ReactComponent as ArrowRightIcon } from "../../../images/arrow-right.svg";
import { useIsMobile } from "../../../utils/hooks/useIsMobile";
import { useIsRtl } from "../../../utils/hooks/useIsRtl";
import { ResponseGenerator } from "../../../interfaces/mainInterfaces";
import API from "../../../utils/API";
import { FMAlert } from "../../../components/Form/Swal/FMAlert";

export default function PageTransfer () {
	const [ isFetch, setIsFetch ] = React.useState(false);
	const [ financeTransferResponse, setFinanceTransferResponse ] = React.useState({});
	const lang = useAppSelector((state) => state.language.language);
	const langFinance = useAppSelector((state) => state.language.languageFinance);
	const { tra_04, trd_241, trd_291 } = useAppSelector((state) => state.language.languageTrading);
	const isMobile = useIsMobile();
	const isRtl = useIsRtl();

	const onSubmit = (data: any) => {
		FMAlert.fire({
			title: trd_241,
			html: `
				<p>${tra_04}</p>
				<p><b>${langFinance.tra_00}:</b> ${data.transferTo}</p>
				<p><b>${trd_291}:</b> ${data.amount}</p>
			`,
			confirmButtonText: "confirm",
			showCancelButton: true,
			cancelButtonText: "cancel",
			cancelButtonColor: "#d9534f",
			confirmButtonColor: "#5cb85c"
		}).then((result) => {
			if (result.isConfirmed) {
				const financeTransferData = new FormData();
				financeTransferData.append("amount", data.amount);
				financeTransferData.append("comment", data.message);
				financeTransferData.append("account_receiver", data.transferTo);
				return sendFinanceTransferInfo(financeTransferData);
			}
		});
	};

	async function sendFinanceTransferInfo (financeTransferData:{}) {
		setIsFetch(false);
		const response:ResponseGenerator = await API.post("financeTransfer", financeTransferData);
		setFinanceTransferResponse(response.data || {});
		if (response.data?.validateProcess?.error) {
			FMAlert.fire({
				text: response.data.validateProcess.errorMsg,
				confirmButtonText: "Ok",
				confirmButtonColor: "#5cb85c"
			}).then((result) => {
				if (result.isConfirmed) {
					setIsFetch(false);
					console.log(financeTransferResponse);
				}
			});
		}
	}

	return (
		<>
			<div className="page-transfer">
				<PageTitle
					layoutTitle={lang.sb_li_2}
					pageTitle={langFinance.finav_03}
				/>
				<Box sx={{ padding: isMobile ? "0 16px" : "0" }}>
					<Form
						validationSchema={validationSchema(langFinance)}
						onSubmit={onSubmit}
						defaultValues={{
							transferTo: "",
							amount: "",
							message: ""
						}}
						fieldWrapperVariant="with-tip"
					>
						<Row justifyContent="space-between" wrap={isMobile ? "wrap" : "nowrap"}>
							<Grid
								item
								sx={{
									marginRight: isMobile ? "0" : isRtl ? "0" : "28px",
									marginLeft: isMobile ? "0" : isRtl ? "28px" : "0",
									width: "100%",
									marginBottom: isMobile ? "15px" : "0"
								}}
							>
								<Input
									label="Transfer To"
									name="transferTo"
									tips="The recipient must be verified."
									variant="rounded"
									placeholder="Account Number"
								/>
							</Grid>
							<Grid item sx={{ width: "100%" }}>
								<Input
									label="Sum"
									name="amount"
									variant="rounded"
									placeholder="Amount"
								/>
							</Grid>
						</Row>
						<Box
							sx={{
								marginTop: isMobile ? "15px" : "26px",
								marginBottom: isMobile ? "15px" : "17px"
							}}
						>
							<Textarea
								name="message"
								minHeight="130px"
								variant="rounded"
							/>
						</Box>
						<Box sx={{ display: "flex", justifyContent: isRtl ? "end" : "start" }}>
							<ButtonWithLoader
								text="Transfer"
								color="success"
								isFetch={isFetch}
								minHeight="45px"
								contentAlign="space-around"
								icon={<ArrowRightIcon />}
							/>
						</Box>
					</Form>
				</Box>
			</div>
			<AccountTypes/>
		</>
	);
};
