import * as React from "react";
import { Form } from "../../Form";
import { Input } from "../../Form/Input";
import { getWithdrawalValue } from "../../../utils/getReceivedValue";
import { useAppSelector } from "../../../utils/hooks/reduxHooks";
import { depositAsianBankFormOneValidationSchema } from "./validation";
import { FormBottomWrapper } from "../../Form/FormBottomWrapper";
import { ButtonWithLoader } from "../../ButtonWithLoader";
import { Alert, Box, Snackbar } from "@mui/material";
import { useErrorHandling } from "../../../utils/hooks/useErrorHandling";
import { InputRepeater } from "../../Form/InputRepeater";
import { FinanceFormProps } from "../types";
import { DepositAsianBankFormError } from "../../../utils/errors/DepositAsianBankFormError";
import { CurrencyType } from "../../../utils/currency";

export type TDepositAsianBankFormsOne = {
	amount: string,
	firstName: string,
	lastName: string,
	phone: string
}

export interface DepositAsianBankFormOneProps extends FinanceFormProps<TDepositAsianBankFormsOne> {
	showRepeater?: boolean;
	currency?: CurrencyType;
}

export function One (props: DepositAsianBankFormOneProps) {
	const {
		submit: _submit,
		calcInCurrency = () => "",
		showRepeater = true,
		currency: _currency
	} = props;
	const [ isFetch, setIsFetch ] = React.useState(false);
	const language = useAppSelector((state) => state.language);
	const accountNumber = useAppSelector((state) => `${state.session.userData.account_number ?? ""}`);
	const phone = useAppSelector((state) => state.user.user.phone1);
	const [ firstName, lastName ] = useAppSelector((state) => {
		const fullNameArray = state.user.user.full_name?.split(" ");
		if (!fullNameArray) return [ state.user.user.full_name, "" ];

		return fullNameArray;
	});
	const currency = useAppSelector((state) => (_currency ?? state.session.userData.currency) ?? "USD");
	const { isOpen, errorMsg, hideError, showError } = useErrorHandling();

	const getWithdrawalValueCB = React.useCallback((values: any) => getWithdrawalValue(values, currency), [ currency ]);

	async function submit (values: TDepositAsianBankFormsOne) {
		try {
			setIsFetch(true);

			await _submit(values);
		} catch (e) {
			if (e instanceof DepositAsianBankFormError) {
				showError(e.message);
			} else {
				showError("Something went wrong");
			}
		} finally {
			setIsFetch(false);
		}
	}

	function calcInPHP (values: [string]) {
		const [ amount ] = values;
		return calcInCurrency(parseInt(amount));
	}

	return (
		<Box sx={{ paddingTop: "20px" }}>
			<Form
				onSubmit={submit}
				validationSchema={depositAsianBankFormOneValidationSchema()}
				defaultValues={{
					accountNumber,
					amount: "",
					firstName,
					lastName,
					phone
				}}
			>
				<Input
					name="accountNumber"
					label={language.languageCopytrade.cpy_mon_accnum}
					variant="rounded"
				/>
				<Input
					name="amount"
					label={language.languageFinance.s_03}
					variant="rounded"
				/>
				{
					/*

					*/
				}
				{
					showRepeater
						? (
							<InputRepeater
								fields="amount"
								label={""}
								onChange={calcInPHP}
							/>
						)
						: (
							<></>
						)
				}
				<Input
					name="firstName"
					label={language.languageMyAccounts.fn_01}
					variant="rounded"
				/>
				<Input
					name="lastName"
					label={language.languageMyAccounts.ln_01}
					variant="rounded"
				/>
				<Input
					name="phone"
					label={language.languageFinance.int_reg_45}
					variant="rounded"
				/>
				<FormBottomWrapper
					forFields={[ "amount", "currency" ]}
					onChange={getWithdrawalValueCB}
					label={language.languageMyAccounts.form_message_01}
				>
					<ButtonWithLoader
						isFetch={isFetch}
						color={"primary"}
						text={language.languageMyAccounts.chapas_04}
					/>
				</FormBottomWrapper>
			</Form>
			<Snackbar open={isOpen} autoHideDuration={4000} onClose={hideError}>
				<Alert variant="filled" color="error">
					{errorMsg}
				</Alert>
			</Snackbar>
		</Box>
	);
}
