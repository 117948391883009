import * as React from "react";
import FMTable from "../../../components/FMTable/FMTable";
import FMTableColumn from "../../../components/FMTable/FMTableColumn";
import { BalanceOperationActionCell } from "./components/BalanceOperationActionCell";
import { BalanceOperationDetailsRow } from "./components/BalanceOperationDetailsRow";
import { useAppSelector } from "../../../utils/hooks/reduxHooks";

export function BalanceOperation () {
	const langFinance = useAppSelector((state) => state.language.languageFinance);
	const balanceOperations = useAppSelector((state) => state.finance.balanceOperations);
	return (
		<FMTable
			data={balanceOperations}
			mobileScheme={{ mode: "auto" }}
			theme="FinanceTheme"
			collapsibleComponent={BalanceOperationDetailsRow}
		>
			<FMTableColumn id="id" label="#" />
			<FMTableColumn id="fundType" label={langFinance.trans_06} />
			<FMTableColumn id="fundStatus" label={langFinance.trans_status} />
			<FMTableColumn id="amount" label={langFinance.trans_08} />
			<FMTableColumn id="comment" label={langFinance.trans_comment} />
			<FMTableColumn id="date" label={langFinance.trans_10} />
			<FMTableColumn id="action" label={langFinance.trans_11} component={BalanceOperationActionCell}/>
		</FMTable>
	);
};
