import React from "react";

export default function PromotionalMaterialsIcon (props:any) {
	let width = 16;
	let fill = "#417cbf"; // 2a88c9
	if (props.width) {
		width = props.width;
	}
	if (props.fill) {
		fill = props.fill;
	}

	return (
		<svg width={width} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 16">
			<defs>
				<clipPath id="clip-path-PromotionalMaterialsIcon">
					<rect width={width} height={width} transform="translate(20 22)" fill="#fff"/>
				</clipPath>
			</defs>
			<g transform="translate(-20 -22)" clipPath="url(#clip-path-PromotionalMaterialsIcon)">
				<path id="icon-fm-megaphone" d="M16.136,5.7V2.418a.624.624,0,0,0-.045-.26.319.319,0,0,0-.076-.133l-.133-.15a.648.648,0,0,0-.606-.133h-.308V1.86L8.689,4.471H4.016a.688.688,0,0,0-.686.686v.079a2.721,2.721,0,0,0,0,5.273v.076a.688.688,0,0,0,.686.686h.734l.207.442c.509,1.087,1.433,3.062,1.852,3.975a1.855,1.855,0,0,0,1.847,1.184H9.774a1.04,1.04,0,0,0,.965-.411c.261-.425.075-.885-.157-1.361-.406-.832-1.213-2.572-1.768-3.777l6.372,2.652a.676.676,0,0,0,.6-.04l.071-.047a.66.66,0,0,0,.228-.279.364.364,0,0,0,.037-.147h0l.017-3.413a2.642,2.642,0,0,0,0-4.356Zm-7.914.149V9.9H4.7V5.844ZM8.628,15.5c-.272,0-.4,0-.574-.384-.4-.877-1.269-2.732-1.79-3.844H7.279c.456.994,1.442,3.132,1.97,4.227H8.628Zm6.135-12.07v8.882l-5.335-2.22V5.651ZM3.329,6.7v2.35a1.36,1.36,0,0,1,0-2.35Z" transform="translate(18.718 20.705)" fill={fill}/>
			</g>
		</svg>
	);
};
