import { createDepositApiRequest } from "../../../../utils/createDepositApiRequest";
import { PaymentMethod } from "../types";

export type SkrillInfoType = {
	amount: string;
	cancel_url: string;
	currency: string;
	detail1_description: string;
	detail1_text: string;
	language: string;
	link: string;
	merchant_fields: string;
	pay_to_email: string;
	return_url: string;
	status_url: string;
	transaction_id: string;
	user_id: string;
}

export const getInfo = createDepositApiRequest<SkrillInfoType>("depositSkrill", PaymentMethod.SKRILL);
