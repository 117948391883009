import * as React from "react";
import FMTableToolbar from "./FMTableToolbar";
import FMTablePeriod from "./FMTablePeriod";
import FMTableChart from "./FMTableChart";
import { ISetState } from "../../types/common";
import { useEffect, useState } from "react";
import { chartFMTable, dataFMTable, headCellsFMTable, IFMTableRowObj, IHeadCell } from "./formatDataFMTable";
import { searchFMTableChart, searchFMTableRows } from "./searchFMTable";
import getDateWithOffset from "../../utils/getDateWithOffset";
import { IFMTable } from "./IFMTable";

export interface IFMTableHeader<T, M> extends IFMTable<T, M>{
	rows: Array<IFMTableRowObj<T, M>>
	headCells: Array<IHeadCell<T>>
	setOrder: ISetState
	setRowsPerPage: ISetState
	setOrderBy: ISetState
	setRows: ISetState
	setPage: ISetState
	setHeadCells: ISetState
	handleChangeRowsPerPage: ISetState
	rowsPerPage: number
};

export default function FMTableHeader<T, M> (props: IFMTableHeader<T, M>) {
	const {
		children,
		collapsibleComponent,
		handleChangeRowsPerPage,
		rowsPerPage,
		data, chartsData, chartScheme,
		withPeriodFilter,
		withApplyButton, withChart, withExport,
		innerComponent,
		rowsPerPageOptions = [ 5, 10, 15 ],
		setRows,
		rows,
		setPage,
		setHeadCells
	} = props;

	const periodsNames: string[] = [ "", "All", "Day", "Week", "Month", "3 Months", "Year" ];
	const [ filter, setFilter ] = React.useState("");
	const [ chart, setChart ] = React.useState(chartsData);
	const [ showChart, setShowChart ] = useState(false);
	const [ period, setCurrentPeriod ] = useState(1);
	const [ dateFrom, setCurrentDateFrom ] = useState(() => getDateWithOffset(365 * 10));
	const [ dateTo, setCurrentDateTo ] = useState(Date.now());

	const { allRows = [] as Array<IFMTableRowObj<T, M>>, headCells = [] } = props;
	const toggleShowChart = () => { setShowChart(prevState => !prevState); };

	useEffect(() => {
		let allRowsData: Array<IFMTableRowObj<T, M>> = allRows as Array<IFMTableRowObj<T, M>>;
		let allChartData = chartsData;
		if (data && children) { allRowsData = dataFMTable<T, M>({ columns: children, data, collapsibleComponent }); }
		setRows(() => searchFMTableRows({ dateFrom, dateTo, filter, allRowsData }));
		if (chartScheme) { allChartData = chartFMTable<T, M>({ allRowsData, chartScheme }); }
		setChart(() => searchFMTableChart({ dateFrom, dateTo, allChartData }));
		setPage(0);
		if (children) { setHeadCells(headCellsFMTable<T, M>({ columns: children })); }
	}, [ filter, data, dateFrom, dateTo ]);

	const setPeriod = (p:number) => {
		onPeriodChange(p);
		setCurrentPeriod(p);
		return null;
	};

	const setDateFrom = (value: any) => {
		setCurrentDateFrom(value);
		setCurrentPeriod(0);
	};

	const setDateTo = (value: any) => {
		setCurrentDateTo(value);
		setCurrentPeriod(0);
	};

	const setExtDateFrom = (value: any) => {
		setCurrentDateFrom(value);
	};

	const setExtDateTo = (value: any) => {
		setCurrentDateTo(value);
	};

	const onPeriodChange = (period: number) => {
		const setValueWithOffset = (offset: number) => {
			setExtDateFrom(getDateWithOffset(offset));
			setExtDateTo(getDateWithOffset(0));
		};

		switch (period) {
			case 0: return null;
			case 1: return setValueWithOffset(365 * 10);
			case 2: return setValueWithOffset(1);
			case 3: return setValueWithOffset(7);
			case 4: return setValueWithOffset(31);
			case 5: return setValueWithOffset(3 * 31);
			case 6: return setValueWithOffset(365);
			default: return null;
		};
	};

	return (
		<>
			{ withPeriodFilter && <FMTablePeriod
				rows={rows}
				headCells={headCells}
				period={period}
				setPeriod={setPeriod}
				periodsNames={periodsNames}
				dateFrom={dateFrom}
				dateTo={dateTo}
				setDateFrom={setDateFrom}
				setDateTo={setDateTo}
				withApplyButton={withApplyButton || false}
				withChart={withChart || false}
				withExport={withExport || false}
				toggleShowChart={toggleShowChart}
			/> }
			{innerComponent}
			{ withChart && chart && showChart && <FMTableChart
				valueName={"Statistics"}
				data={chart}
			/> }
			<FMTableToolbar
				{ ...props }
				rowsPerPage={rowsPerPage}
				handleChangeRowsPerPage={handleChangeRowsPerPage}
				rowsPerPageOptions={rowsPerPageOptions}
				filter={filter}
				setFilter={setFilter}
				period={period}
				setPeriod={setPeriod}
				periodsNames={periodsNames}
			/>
		</>
	);
};
