import {
	IBreakpointCodes
} from "../../utils/Contexts/ThemeBreakpointContext";

export type IMobileSchemeMode = "auto" | "points";
export type IFMTableBreakpoint = "auto" | number;

interface IFMTableColumnCollapseHide {
	collapsibleColumns?: IBreakpointCodes[] | number[]
	hideColumns?: IBreakpointCodes[] | number[]
}

export type IFMTableMobileSchemeOptions = {
	[K in IFMTableBreakpoint ]?: IFMTableColumnCollapseHide
};

export interface IFMTableMobileScheme extends IFMTableMobileSchemeOptions {
	mode?: IMobileSchemeMode
};

interface IFMTableColumnMobile {
	key: any
	index: number
	mobileScheme?: IFMTableMobileScheme
	rowLength: number
	mobileAutoSlice?: number,
	breakpoint?: number
}

export function filterFMTableColumnMobile (props: IFMTableColumnMobile): {include: boolean, collapse: boolean} {
	const { key, index, mobileScheme, rowLength, mobileAutoSlice, breakpoint } = props;

	if (!mobileScheme || !mobileScheme.mode || !breakpoint) { return { include: true, collapse: false }; };
	const data = { key, index, mobileScheme, rowLength, mobileAutoSlice };

	const breakpointCode = breakpoint;

	switch (mobileScheme.mode) {
		case "auto":
			return sortByType({ ...data, breakpointName: "auto", mobileAutoSlice });
		case "points":
			return sortByType({ ...data, breakpointName: breakpointCode });
		default:
			return { include: true, collapse: false };
	}
};

interface IFMTableData extends IFMTableColumnMobile{
	breakpointName: IFMTableBreakpoint
	mobileScheme: IFMTableMobileScheme
}

function sortByType (props : IFMTableData): { include: boolean, collapse: boolean } {
	const { mobileScheme, key, breakpointName, index, rowLength, mobileAutoSlice } = props;

	let collapsibleDataKey: any;
	if (typeof breakpointName !== "number") {
		collapsibleDataKey = Object.keys(mobileScheme)
			.filter((key) => key as keyof IFMTableMobileScheme === breakpointName as keyof IFMTableMobileScheme)[0];
	} else {
		collapsibleDataKey = Object.keys(mobileScheme)
			.sort((a, b) => parseInt(a) - parseInt(b))
			.filter((key) => parseInt(key) > breakpointName)[0];
	}

	if (!collapsibleDataKey && breakpointName !== "auto") { return { include: true, collapse: false }; };

	const collapsible = mobileScheme[collapsibleDataKey as keyof IFMTableMobileScheme] as IFMTableColumnCollapseHide;

	let collapsibleIndexes = collapsible?.collapsibleColumns as number[];
	let collapsibleIds = collapsible?.collapsibleColumns as string[];
	let hideIndexes = collapsible?.hideColumns as number[];
	let hideIds = collapsible?.hideColumns as string[];

	if (breakpointName === "auto") {
		const overSlice = (mobileAutoSlice || 0) - (collapsible?.collapsibleColumns?.length || 0);
		const hideLSlice = overSlice > 0 ? overSlice : 0;
		collapsibleIndexes = collapsible?.collapsibleColumns?.slice(0, (mobileAutoSlice || 0)) as number[];
		collapsibleIds = collapsible?.collapsibleColumns?.slice(0, (mobileAutoSlice || 0)) as string[];
		hideIndexes = collapsible?.hideColumns?.slice(0, hideLSlice) as number[];
		hideIds = collapsible?.hideColumns?.slice(0, hideLSlice) as string[];
	}

	let type = "auto";
	if (mobileScheme.mode === "auto" && !mobileScheme.auto) {
		type = "auto";
	} else if ((collapsible.collapsibleColumns?.length && typeof collapsible.collapsibleColumns[0] === "number") ||
		(collapsible.hideColumns?.length && typeof collapsible.hideColumns[0] === "number")) {
		type = "number";
	} else if ((collapsible.collapsibleColumns?.length && typeof collapsible.collapsibleColumns[0] === "string") ||
		(collapsible.hideColumns?.length && typeof collapsible.hideColumns[0] === "string")) {
		type = "string";
	}

	switch (type) {
		case "string":
			return {
				include: !(collapsibleIds?.includes(key) || hideIds?.includes(key)),
				collapse: collapsibleIds?.includes(key)
			};
		case "number":
			return {
				include: !(collapsibleIndexes?.includes(index + 1) || hideIndexes?.includes(index + 1)),
				collapse: collapsibleIndexes?.includes(index + 1)
			};
		case "auto":
			return {
				include: index < (rowLength - (mobileAutoSlice || 0)),
				collapse: index >= (rowLength - (mobileAutoSlice || 0))
			};
		default:
			return {
				include: index < (rowLength - (mobileAutoSlice || 0)),
				collapse: index >= (rowLength - (mobileAutoSlice || 0))
			};
	}
};
