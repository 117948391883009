import * as React from "react";
import { Box, SxProps, Tabs, Theme } from "@mui/material";
import { ITabsProps } from "../types";
import { Line } from "../helpers/Line";
import { Tab } from "./Tab";
import { useIsMobile } from "../../../../utils/hooks/useIsMobile";
import { useAdaptive } from "../../../../utils/hooks/themeBreakpointsHooks";

export interface ISimpleTabsProps extends ITabsProps {
	fontSize?: string;
	tabSx?: SxProps<Theme>;
}

const containerAdaptiveStyles: SxProps = {
	padding: "12px 0",
	border: 0
};

export function SimpleTabs (props: ISimpleTabsProps) {
	const { labels, fontSize = "18px", children, tabSx = {} } = props;

	const [ value, setValue ] = React.useState(0);

	const isMobile = useIsMobile();
	const containerAdaptive = useAdaptive({
		xs: containerAdaptiveStyles,
		sm: tabSx
	});

	const handleChange = React.useCallback((event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	}, [ setValue ]);

	return (
		<>
			<Tabs
				value={value}
				onChange={handleChange}
				textColor="inherit"
				variant="scrollable"
				allowScrollButtonsMobile
				TabScrollButtonProps={{
					sx: {
						padding: 0,
						background: "#E2E7E8",
						borderRadius: "4px",
						margin: "0 4px"
					}
				}}
				sx={{
					borderBottom: "1px"
				}}
				TabIndicatorProps={{
					style: {
						background: "transparent"
					}
				}}
			>
				{
					labels.map((label, index) => (
						<Tab
							key={`${label}-${index}`}
							fontSize={isMobile ? "14px" : fontSize}
							label={label}
							padding={isMobile ? "5px 10px" : "10px 40px"}
							sx={{
								borderRadius: isMobile ? "4px" : "0px"
							}}
						/>
					))
				}
				{
					isMobile ? null : <Line color="#2988CA" />
				}
			</Tabs>
			<Box
				sx={{
					padding: "12px 17px",
					borderLeft: "1px solid #E2E7E8",
					borderRight: "1px solid #E2E7E8",
					borderBottom: "1px solid #E2E7E8",
					...containerAdaptive
				}}
			>
				{Array.isArray(children)
					? children.map((child, index) => (
						<Box
							key={index}
							sx={{
								display: value === index ? "block" : "none"
							}}
						>
							{child}
						</Box>
					))
					: children}
			</Box>
		</>
	);
}
