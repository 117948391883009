/*
    Created on : 20.12.2021, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { useSelector } from "react-redux";
import { SessionUserDataInterface, StoreInterface } from "../../../../interfaces/StoreInterfaces";
import FacebookIcon from "../../../Icons/FacebookIcon";
import TwitterIcon from "../../../Icons/TwitterIcon";
import TelegramIcon from "../../../Icons/TelegramIcon";
import LinkedinIcon from "../../../Icons/LinkedinIcon";
import "./FooterAuthSocial.scss";
import VkIcon from "../../../Icons/VkIcon";
import languageLink from "../../../../utils/languageLink";
import ModalFeedback from "../../../Modal/ModalFeedback/ModalFeedback";
import AuthFeedbackIcon from "../../../Icons/AuthFeedbackIcon";

function FooterAuthSocial () {
	const lang:any = useSelector((state:StoreInterface) => state.language.language);
	const init:any = useSelector((state:StoreInterface) => state.init.init);
	const session:SessionUserDataInterface = useSelector((state:StoreInterface) => state.session.userData);

	const telegram:any = {
		russuan: "forexmartru",
		malay: "forexmartmalaysia",
		indonesian: "forexmartindonesia"
	};

	return (
		<div className="footer-auth-contacts">
			<div className="phone-number">
				<img src="https://www.forexmart.com/assets/images/eu_flag_foot.png" className="flag-foot" alt="" />
				<a href="tel:+442030976697">
					<span className="quick-link"> + 442 030 976 697</span>
				</a>
			</div>
			<a className="contact-us-btn" href={init.links.link_contact_us && session.site_lang ? languageLink(init.links.link_contact_us, session.site_lang) : ""}>{lang.bn_li_cu}</a>
			<ModalFeedback
				buttonText={lang.bn_li_f}
				buttonIcon={<AuthFeedbackIcon/>}
			/>
			<div className="footer-auth-social flexRow">
				{session.site_lang === "russuan"
					? <>
						<a href="https://www.facebook.com/forexmartru" target="_blank" rel="noreferrer" className="btn-social btn-fb">
							<span className="icon-sm icon-facebook">
								<FacebookIcon/>
							</span>
						</a>

						<a href="https://twitter.com/ForexMartRu" target="_blank" rel="noreferrer" className="btn-social btn-twitter">
							<span className="icon-sm icon-twitter">
								<TwitterIcon/>
							</span>
						</a>

						<a href="https://vk.com/forexmart" target="_blank" rel="noreferrer" className="btn-social btn-vk">
							<span className="icon-sm icon-vk">
								<VkIcon/>
							</span>
						</a>

						<a href={"https://t.me/" + telegram[session.site_lang]} target="_blank" rel="noreferrer" className="btn-social btn-tg">
							<span className="icon-sm icon-tg">
								<TelegramIcon/>
							</span>
						</a>
					</>
					: <>
						<a href={init.links.link_domain_facebook} target="_blank" rel="noreferrer" className="btn-social btn-fb">
							<span className="icon-sm icon-facebook">
								<FacebookIcon/>
							</span>
						</a>

						<a href={init.links.link_domain_twitter} target="_blank" rel="noreferrer" className="btn-social btn-twitter">
							<span className="icon-sm icon-twitter">
								<TwitterIcon/>
							</span>
						</a>

						<a href="https://www.linkedin.com/" target="_blank" rel="noreferrer" className="btn-social btn-linkedin">
							<span className="icon-sm icon-linkedin">
								<LinkedinIcon/>
							</span>
						</a>

						{session.site_lang === "russuan" || session.site_lang === "malay" || session.site_lang === "indonesian"
							? <a href={"https://t.me/" + telegram[session.site_lang]} target="_blank" rel="noreferrer" className="btn-social btn-tg">
								<span className="icon-sm icon-tg">
									<TelegramIcon/>
								</span>
							</a>
							: null
						}
					</>
				}
			</div>
			<div className="footer-auth-icons-wrap">
				<a href="https://itunes.apple.com/app/metatrader-4/id496212596?l=en&mt=8" target="_blank" rel="noreferrer">
					<img alt="App store" src="https://www.forexmart.com/assets/images/appstore_footer.png" className="app-icon" />
				</a>
				<a href="https://play.google.com/store/apps/details?id=net.metaquotes.metatrader4" target="_blank" rel="noreferrer">
					<img alt="google play" src="https://www.forexmart.com/assets/images/google_footer.png" className="app-icon" />
				</a>
			</div>
		</div>
	);
}

export default FooterAuthSocial;
