import { Box, Button } from "@mui/material";
import * as React from "react";
import { ButtonGroup } from "reactstrap";
import { useAppSelector } from "../../../../../../utils/hooks/reduxHooks";
import { CloseTrades } from "./CloseTrades";
import { OpenTrades } from "./OpenTrades";

const tables = {
	0: OpenTrades,
	1: CloseTrades
};

export interface ITradersTableProps {
	connectionId: number;
}

export function TradersTable (props: ITradersTableProps) {
	const { connectionId } = props;
	const langCopytrade = useAppSelector((state) => state.language.languageCopytrade);

	const [ selectedTable, setSelectedTable ] = React.useState<keyof typeof tables>(0);

	const Table = React.useMemo(() => tables[selectedTable], [ selectedTable ]);

	return (
		<Box
			sx={{
				overflow: "hidden",
				background: "#F6F8F9",
				padding: "15px"
			}}
		>
			<ButtonGroup>
				<Button
					color="default"
					onClick={() => setSelectedTable(0)}
					variant="contained"
					sx={{ borderRadius: 0, opacity: selectedTable === 0 ? 1 : 0.5 }}
				>
					{langCopytrade.copytrade_38}
				</Button>
				<Button
					sx={{ borderRadius: 0, opacity: selectedTable === 1 ? 1 : 0.5 }}
					color="default"
					onClick={() => setSelectedTable(1)}
					variant="contained"
				>
					{langCopytrade.copytrade_64}
				</Button>
			</ButtonGroup>
			<Table connectionId={connectionId} />
		</Box>
	);
}
