/*
    Created on : 20.01.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import "./PageCommission.scss";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../interfaces/StoreInterfaces";
import PageTitle from "../../../components/Dashboard/dashboardComponents/PageTitle";
import Title from "../../../components/Typography/Title";
import CommissionCard from "./CommissionCard";
import { ReactComponent as UpIcon } from "../images/up.svg";
import { ReactComponent as DollarsIcon } from "../images/dollars.svg";
import FMTable from "../../../components/FMTable/FMTable";
import { useAppSelector } from "../../../utils/hooks/reduxHooks";
import FMTableColumn from "../../../components/FMTable/FMTableColumn";

const PageCommission = () => {
	const lang:any = useSelector((state:StoreInterface) => state.language.language);
	const langPartnership:any = useSelector((state:StoreInterface) => state.language.languagePartnership);
	const data = useAppSelector(state => state.partnership.commission.data);
	const commonStatics = useAppSelector(state => state.partnership.commission.recordsTotal);
	const totalCommission = useAppSelector(state => state.partnership.commission.commission?.toFixed(2));
	return (
		<div className="page-partnership-commission">
			<PageTitle
				layoutTitle={lang.sb_li_4}
				pageTitle={langPartnership.parnav_04}
			/>

			<Title>{langPartnership.com_01}</Title>
			<div className={"commission-row"}>
				<CommissionCard color={"#2988CA"} title={"Common Statics"} icon={<UpIcon/>} number={commonStatics}/>
				<CommissionCard color={"#29A643"} title={"Total Commissions:"} icon={<DollarsIcon/>} number={totalCommission}/>
			</div>
			<FMTable
				data={data}
				rowsPerPageOptions={[ 5, 10, 25, 50, 100 ]}
				withPeriodFilter={true}
				withPeriodButtons={false}
				withChart={true}
				chartScheme={{ xColumnId: "date", yColumnId: "sum" }}
				mobileScheme={{ mode: "auto" }}
				theme="PartnershipTheme"
			>
				<FMTableColumn id="DT_RowId" label="#" />
				<FMTableColumn id="date" label="Date" />
				<FMTableColumn id="sum" label="Sum" />
				<FMTableColumn id="referral" label="Referral"/>
				<FMTableColumn id="instrument" label="Trading instrument" />
				<FMTableColumn id="order" label="Ticket" />
			</FMTable>
		</div>
	);
};

export default React.memo(PageCommission);
