/*
    Created on : 09.11.2021, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import "./PageCopytradeRecommendeds.scss";
import { useSelector } from "react-redux";
import { StoreInterface, TradersItemInterface } from "../../../interfaces/StoreInterfaces";
import PageTitle from "../../../components/Dashboard/dashboardComponents/PageTitle";
import Title from "../../../components/Typography/Title";
import FMTableColumn from "../../../components/FMTable/FMTableColumn";
import FMTable from "../../../components/FMTable/FMTable";
import TraderActivityCell from "../PageCopytradeMonitoring/components/TraderActivityCell";
import TraderAccountCell from "../PageCopytradeMonitoring/components/TraderAccountCell";

export default function PageCopytradeRecommendeds () {
	const lang:any = useSelector((state:StoreInterface) => state.language.language);
	const langCopytrade:any = useSelector((state:StoreInterface) => state.language.languageCopytrade);
	const langTrading:any = useSelector((state:StoreInterface) => state.language.languageTrading);
	// const traders = useSelector((state:StoreInterface) => state.copytrade.tradersMonitoring);
	const tradersAll: TradersItemInterface[] = useSelector((state:StoreInterface) => state.copytrade.tradersAll);
	const traders = tradersAll?.sort((a, b) => b.TotalProfit - a.TotalProfit).slice(0, 20);

	return (
		<div className="page-copytrade-recommendeds">
			<PageTitle
				layoutTitle={lang.sb_li_12}
				pageTitle={langCopytrade.ctrd_menu_6}
			/>
			<Title mb="27px">Check all your current trades and pending orders. For closed and canceled deals, go to History of Trades.</Title>
			<FMTable
				data={traders}
				mobileScheme={{
					mode: "points",
					700: {
						collapsibleColumns: [ 2 ]
					},
					520: {
						collapsibleColumns: [ 3, 2 ]
					},
					380: {
						collapsibleColumns: [ 4, 3, 2 ]
					}
				}}
			>
				<FMTableColumn id="Account" label={langTrading.trd_80} component={TraderAccountCell} />
				<FMTableColumn id="ProjectName" label={langTrading.reb_txt_9} />
				<FMTableColumn id="TotalProfit" label={langCopytrade.copytrade_48} />
				<FMTableColumn id="Action" label={langTrading.reb_txt_13} component={TraderActivityCell}

				// 	 component={() => <CustomButton onClick={() => {}} text={"Subscribe"} icon={
				// 	<Box ml={"5px"}>
				// 		<svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16">
				// 			<path id="bell-solid" d="M7,16a2,2,0,0,0,2-2H5A2,2,0,0,0,7,16Zm6.731-4.678C13.127,10.673,12,9.7,12,6.5A4.935,4.935,0,0,0,8,1.651V1A1,1,0,1,0,6,1v.651A4.935,4.935,0,0,0,2,6.5C2,9.7.873,10.673.269,11.322A.976.976,0,0,0,0,12a1,1,0,0,0,1,1H13a1,1,0,0,0,1-1A.976.976,0,0,0,13.731,11.322Z" transform="translate(0)" fill="#fff"/>
				// 		</svg>
				// 	</Box>
				// }></CustomButton>}
				/>
			</FMTable>
		</div>
	);
};
