import { AxiosResponse } from "axios";
import API from "../../../../utils/API";

export type DepositInfoType = {
	payment_data: {
		transaction_code: string;
		account_number: string;
		pre_amount: string;
		payment_type: string;
		bonus_type: string;
		bonus: string;
		additional_temp_bonus: string;
		deposit_amount: string;
		extra_compensation: string;
		currency: string;
		bank_code: string;
		bank_account_number: string;
		idcard_number: string;
		card_image: string;
		person_name: string;
		person_email: string;
		person_phone: string;
		ip_address: string;
		user_id: string;
		create_date: string;
		transaction_type: string;
		username: string;
		password: string;
		auth_username: string;
		auth_password: string;
		txpay_id: string;
		x_api_key: string;
		callback_url: string;
		api_url: string;
		base_card_image: string;
	};
	errors: [];
};

export type DepositData = {
	currency: string;
	banks: string;
	bankAccountNumber: string;
	idCardNumber: string;
	fullName: string;
	email: string;
	number: string;
	cardImage: File;
	amount: string;
}

export async function getInfo (depostiData: DepositData) {
	const formData = new FormData();
	formData.append("amount", depostiData.amount);
	formData.append("currency", "THB");
	formData.append("bank_code", depostiData.banks);
	formData.append("bank_account_number", depostiData.bankAccountNumber);
	formData.append("idcard_number", depostiData.idCardNumber);
	formData.append("person_name", depostiData.fullName);
	formData.append("person_email", depostiData.email);
	formData.append("person_phone", depostiData.number);
	formData.append("card_image", depostiData.cardImage);

	const { data }: AxiosResponse<DepositInfoType> = await API.post("depositThunderXPay", formData);

	return convertResponseInfo(data);
}

function convertResponseInfo (data: DepositInfoType): {
	link: string;
	[key: string]: string;
} {
	return {
		link: data.payment_data.api_url,
		transaction_code: data.payment_data.transaction_code,
		auth_username: data.payment_data.auth_username,
		auth_password: data.payment_data.auth_password,
		txpay_id: data.payment_data.txpay_id,
		account_number: data.payment_data.account_number,
		person_name: data.payment_data.person_name,
		person_email: data.payment_data.person_email,
		person_phone: data.payment_data.person_phone,
		deposit_amount: data.payment_data.deposit_amount,
		currency: data.payment_data.currency,
		bank_code: data.payment_data.bank_code,
		bank_account_number: data.payment_data.bank_account_number,
		card_image: data.payment_data.base_card_image,
		idcard_number: data.payment_data.idcard_number,
		callback_url: data.payment_data.callback_url,
		user_id: data.payment_data.user_id
	};
}
