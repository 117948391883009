/*
    Created on : 03.12.2022, 06:18:59
    Author     : Andy Kar
*/
import { ActionType } from "../../utils/reudxHelpers";
import {
	SET_AUTH_LOADING,
	SET_AUTH_REQUEST_INFO,
	SET_REDIRECT_TO_TFA,
	SET_TFA_ENABLED,
	SET_TWO_FACTOR_AUTH_INFO,
	SET_TWO_FACTOR_AUTH_INFO_LOADING
} from "../actions/actionTypes";

type TAuthState = {
	auth: any;
	tfa: {
		secret: string,
		QRCode: string,
		loading: boolean,
		enabled: boolean,
	};
	redirectToTFA: boolean;
	loading: boolean;
};

const initial: TAuthState = {
	auth: {},
	tfa: {
		secret: "",
		QRCode: "",
		loading: false,
		enabled: false
	},
	redirectToTFA: false,
	loading: false
};

type Actions = ActionType<"SET_AUTH_REQUEST_INFO", any>
| ActionType<"SET_AUTH_LOADING", boolean>
| ActionType<"SET_TWO_FACTOR_AUTH_INFO", { secret: string, QRCode: string }>
| ActionType<"SET_TWO_FACTOR_AUTH_INFO_LOADING", boolean>
| ActionType<"SET_REDIRECT_TO_TFA", boolean>
| ActionType<"SET_TFA_ENABLED", boolean | undefined>;

export default function authReducer (state = initial, action: Actions): TAuthState {
	switch (action.type) {
		case SET_AUTH_REQUEST_INFO:
			return {
				...state,
				auth: action.payload
			};
		case SET_AUTH_LOADING:
			return {
				...state,
				loading: action.payload
			};
		case SET_TWO_FACTOR_AUTH_INFO:
			return {
				...state,
				tfa: {
					...state.tfa,
					...action.payload
				}
			};
		case SET_TWO_FACTOR_AUTH_INFO_LOADING:
			return {
				...state,
				tfa: {
					...state.tfa,
					loading: action.payload
				}
			};
		case SET_REDIRECT_TO_TFA:
			return {
				...state,
				redirectToTFA: action.payload
			};
		case SET_TFA_ENABLED:
			return {
				...state,
				tfa: {
					...state.tfa,
					enabled: action.payload || true
				}
			};
		default:
			return state;
	}
}
