/*
    Created on : 30.12.2021, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import SocialSignIn from "./SocialSignIn";
import SocialSignOr from "./SocialSignOr";
import SignInHeader from "./SignInHeader";
import { useLocation } from "react-router-dom";
import SignInForm from "./SignInForm";

export type SignInType = "partner" | "client" | null;

export default function AuthForm () {
	const location = useLocation();
	const path = location.pathname;

	let title = "";
	let type: SignInType = null;
	if (path.includes("partner/signin")) {
		title = "Partner Cabinet";
		type = "partner";
	} else if (path.includes("client/signin")) {
		title = "Client Cabinet";
		type = "client";
	}

	return (
		<>
			<SignInHeader title={title} type={type}/>
			<SocialSignIn type={type}/>
			<SocialSignOr/>
			<SignInForm type={type}/>
		</>
	);
};
