/*
    Created on : 26.06.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { Box, Typography } from "@mui/material";
import { useAdaptive } from "../../../utils/hooks/themeBreakpointsHooks";

interface navigationInterface {
	layoutTitle?: string
	pageTitle?: string
}

export default function PageTitle (props:navigationInterface) {
	const adaptiveSX = useAdaptive(aSX);

	return (
		<Box
			className="flexRow"
			sx={{
				width: "100%",
				display: "flex",
				justifyContent: "flex-start",
				marginBottom: "25px"
			}}
		>
			{props.layoutTitle
				? <>
					<Typography
						sx={{
							...adaptiveSX,
							color: "#2988CA"
						}}
					>{props.layoutTitle}</Typography>
					<Typography sx={adaptiveSX}>&nbsp;/&nbsp;</Typography>
				</>
				: null
			}
			<Typography sx={adaptiveSX}>{props.pageTitle}</Typography>
		</Box>
	);
};

const aSX = {
	mobile: {
		fontSize: "16px",
		fontWeight: "bold",
		lineHeight: "34px",
		textTransform: "uppercase",
		color: "#5C7080",
		"& span": {
			fontSize: "14px",
			lineHeight: "14px"
		},
		"& div": {
			fontSize: "41px",
			lineHeight: "41px"
		}
	},
	tablet: {
		fontSize: "30px",
		"& span": {
			fontSize: "33px"
		},
		"& div": {
			fontSize: "31px"
		}
	}
};
