import { AxiosResponse } from "axios";
import API from "../../../../utils/API";

export type FasapayInfoType = {
	fasapay: {
		link: string;
		fp_acc: string;
		fp_acc_by: string;
		fp_acc_from: string;
		fp_amnt: string;
		fp_currency: string;
		fp_success_url: string;
		fp_fail_url: string;
		fp_status_url: string;
		fp_status_form: string;
		track_id: string;
		order_id: string;
		bonus: string;
		fp_comments: string;
	}
}

export async function getInfo ({ sum, currency, account }: {sum: string, currency: string, account: string}): Promise<FasapayInfoType> {
	const formData = new FormData();
	formData.append("sum", sum);
	formData.append("currency", currency);
	formData.append("account", account);

	const { data }: AxiosResponse<FasapayInfoType> = await API.post("depositFasapay", formData);

	return data;
};
