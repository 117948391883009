import * as React from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { useIsRtl } from "../../../../utils/hooks/useIsRtl";

export interface QuickNavLinkProps {
	title: string;
	to: string;
}

export function QuickNavLink (props: QuickNavLinkProps): JSX.Element {
	const { title, to } = props;
	const isRtl = useIsRtl();

	return (
		<NavLink
			to={to}
			className={classNames("quick-nav__link", "light", { "quick-nav__link_rtl": isRtl })}
			isActive={
				(match) => {
					return match?.url === to;
				}
			}
			activeClassName={"quick-nav__link_active"}
		>
			{title}
		</NavLink>
	);
}
