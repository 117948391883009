/*
Created on : 07.08.2022, 06:18:59
Author     : Andy Kar
*/
import React from "react";
import { useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { SessionUserDataInterface, StoreInterface } from "../../../interfaces/StoreInterfaces";
import languageLink from "../../../utils/languageLink";
import { Box, Button, SxProps } from "@mui/material";

function AppBarAuthButtons (props:any) {
	const lang:any = useSelector((state:StoreInterface) => state.language.language);
	const init:any = useSelector((state:StoreInterface) => state.init.init);
	const session:SessionUserDataInterface = useSelector((state:StoreInterface) => state.session.userData);
	const path = props.location.pathname;

	const webTradeLink = ():string => {
		switch (init.html_url) {
			case "en":
			case "":
				return "https://webterminal.forexmart.com/";
			case "ru":
				return "https://webterminal.forexmart.com/";
			default:
				return "https://webterminal.forexmart.com/";
		}
	};

	return (
		<Box className="flexRow"
			sx={{
				display: "flex",
				marginBottom: 0
			}}
		>
			<Button
				href={webTradeLink()}
				target="_blank"
				rel="noreferrer"
				variant="outlined"
				color="primary"
				sx={buttonSX}
				onClick={props.toggle ? props.toggle : null}
			>
				{lang.xnv_webtrader}
			</Button>
			{!path.includes("client/signin") &&
				<Link to="/app/client/signin">
					<Button

						variant="outlined"
						color="success"
						sx={buttonSX}
						onClick={props.toggle ? props.toggle : null}
					>
						{lang.xnv_CL}
					</Button>
				</Link>
			}
			{!path.includes("partner/signin") &&
				<Link to="/app/partner/signin">
					<Button
						variant="outlined"
						color="success"
						sx={buttonSX}
						onClick={props.toggle ? props.toggle : null}
					>
						{lang.xnv_PL}
					</Button>
				</Link>
			}
			<Button
				href={init.links.link_register && session.site_lang ? languageLink(init.links.link_register, session.site_lang) : ""}
				target="_blank"
				rel="noreferrer"
				variant="contained"
				color="primary"
				sx={{
					...buttonSX,
					"&:hover": {
						color: "#fff"
					}
				}}
				onClick={props.toggle ? props.toggle : null}
			>
				{lang.xnv_R}
			</Button>
		</Box>
	);
}
export default withRouter(AppBarAuthButtons);

const buttonSX: SxProps = {
	width: "max-content",
	borderRadius: 0,
	textTransform: "capitalize",
	marginX: "4px",
	padding: "9px 15px",
	lineHeight: "20px",
	fontSize: "15px"
};
