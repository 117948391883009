/*
    Created on : 04.02.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import "./FormTooltip.scss";
import { ReactComponent as InfoIcon } from "../../../UI/FieldWrapper/WrapperWithTip/icons/InfoIcon.svg";

interface FormTooltipInterface {
	message?: any
	icon?: any
	type?: "top" | "bottom" | "left" | "right"
}

function FormTooltip (props:FormTooltipInterface) {
	let tooltipMessageClass = "tooltip-message flexRow";
	if (props.type) {
		tooltipMessageClass += " " + props.type;
	}
	return (
		<>
			{props.message
				? <div className="form-tooltip">
					{props.icon ? props.icon : <InfoIcon />}
					<div className={tooltipMessageClass}>
						{props.message || ""}
					</div>
				</div>
				: null
			}
		</>
	);
}

export default FormTooltip;
