/*
    Created on : 09.11.2021, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { AccountType, StoreInterface } from "../../../interfaces/StoreInterfaces";
import { useSelector } from "react-redux";
import PageTitle from "../../../components/Dashboard/dashboardComponents/PageTitle";
import Title from "../../../components/Typography/Title";
import TraderTable from "./components/TraderTable";
import { CopytradingModal } from "./components/CopytradingModal";
import { useAppSelector } from "../../../utils/hooks/reduxHooks";

export default function PageCopytradeMonitoring () {
	const accountType = useAppSelector((state) => state.user.user.account_type);
	// const langCopytrade:any = useSelector((state:StoreInterface) => state.language.languageCopytrade);
	const langTrading:any = useSelector((state:StoreInterface) => state.language.languageTrading);
	const lang:any = useSelector((state:StoreInterface) => state.language.language);

	const [ isOpen, setIsOpen ] = React.useState(false);

	React.useEffect(() => {
		setIsOpen(accountType === AccountType.NOT_REGISTERED);
	}, [ accountType ]);

	return (
		<div className="page-copytrade-monitoring">
			<PageTitle
				layoutTitle={lang.sb_li_12}
				pageTitle={lang.sb_li_12}
			/>
			<Title>{langTrading.trd_78}</Title>
			<Title fontSize={"24px"} m={"24px 0 15px 0"}>{langTrading.trd_79}</Title>
			<TraderTable/>
			{
				accountType === AccountType.NOT_REGISTERED
					? (
						<CopytradingModal isOpen={isOpen} onClose={setIsOpen} />
					)
					: (<></>)
			}
		</div>
	);
};
