import React from "react";
import { DefaultTextarea, DefaultTextareaProps } from "./DefaultTextarea";
import { RoundedTextarea } from "./RoundedTextarea";

export type TextareaVariant = "default" | "rounded";

type TextareaModuleI = {
	[key in TextareaVariant]: React.FC<DefaultTextareaProps>
}

export const Textarea: TextareaModuleI = {
	default: DefaultTextarea,
	rounded: RoundedTextarea
};
