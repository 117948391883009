import * as React from "react";
import * as Yup from "yup";
import { Alert, Box, Button, Divider, Grid, Snackbar, Typography, SxProps } from "@mui/material";
import { Form } from "../../../../components/Form";
import { Checkbox } from "../../../../components/Form/Checkbox";
import { Input } from "../../../../components/Form/Input";
import { Radio } from "../../../../components/Form/Radio";
import { SuccessNote } from "../../../../components/UI/Note/SuccessNote";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { ReactComponent as WhiteGearIcon } from "../../../../images/WhiteGearIcon.svg";
import {
	GBP_USD,
	USD_CHF,
	AUD_USD,
	EUR_JPY,
	EUR_GBP,
	EUR_USD,
	USD_JPY,
	NZD_USD,
	EUR_CHF,
	USD_CAD,
	CurrencyCode
} from "../../../../interfaces/CopytradeInterfaces";
import { Select } from "../../../../components/Form/Select";
import { Option } from "../../../../components/Form/Option";
import { For } from "../../../../components/Form/For";
import { ButtonWithLoader } from "../../../../components/ButtonWithLoader";
import API from "../../../../utils/API";
import { LotRangeOptions } from "../../../../components/LotRangeOptions";
import { cleanMySubscriptionTraders, SubscriptionStatus, updateTraderSubscriptionStatus } from "../../../../store/actions/actionTypes";
import { useAdaptive } from "../../../../utils/hooks/themeBreakpointsHooks";

export interface ISettingsProps {
	accountNumber: string;
	status: SubscriptionStatus,
}

const CurrencyPairs = {
	GBP_USD,
	USD_CHF,
	AUD_USD,
	EUR_JPY,
	EUR_GBP,
	EUR_USD,
	USD_JPY,
	NZD_USD,
	EUR_CHF,
	USD_CAD
};

const settingsValidation = Yup.object().shape({});

const xsColumnStyle: SxProps = {
	width: "100%"
};

const smFirstColumn: SxProps = {
	width: "45%"
};

const smSecondColumn: SxProps = {
	width: "55%"
};

const xsMoreSettingsStyles: SxProps = {
	flexWrap: "wrap",
	marginBottom: "30px"
};

const smMoreSettingsStyles: SxProps = {
	flexWrap: "nowrap"
};

const xsMoreSettingsColumnStyles: SxProps = {
	maxWidth: "100%",
	width: "100%"
};

const smMoreSettingsFirstColumnStyles: SxProps = {
	maxWidth: "60%"
};

const xsMoreSettingsSecondColumnStyles: SxProps = {
	marginTop: "15px",
	...xsMoreSettingsColumnStyles
};

const smMoreSettingsSecondColumnStyles: SxProps = {
	marginLeft: "20px",
	marginTop: 0
};

export function Settings (props: ISettingsProps) {
	const { accountNumber, status } = props;
	const [ viewMoreSettings, setViewMoreSettings ] = React.useState(false);
	const [ isFetch, setIsFetch ] = React.useState(false);
	const [ isOpen, setIsOpen ] = React.useState(false);
	const [ errorMsg, setErrorMsg ] = React.useState("");
	const dispatch = useAppDispatch();
	const langTrading = useAppSelector((state) => state.language.languageTrading);
	const langCopytrade = useAppSelector((state) => state.language.languageCopytrade);
	const langMyAccounts = useAppSelector((state) => state.language.languageMyAccounts);

	const submit = React.useCallback(async (values: any) => {
		setIsFetch(true);
		try {
			const formData = new FormData();

			let quotesSum = 0;
			const currencyPairs: CurrencyCode[] = [];
			if (![ "1", "2" ].includes(values.variant)) {
				for (const pair of Object.keys(CurrencyPairs)) {
					const value = values[pair];
					if (value) {
						currencyPairs.push(CurrencyPairs[pair as keyof typeof CurrencyPairs] as CurrencyCode);
						formData.append("quotes[]", `${CurrencyPairs[pair as keyof typeof CurrencyPairs]}`);
						quotesSum += CurrencyPairs[pair as keyof typeof CurrencyPairs];
					}
				}
			}

			formData.append("account_number", accountNumber);
			formData.append("copy_settings_values_1", quotesSum > 2 ? "-1" : values.variant);
			formData.append("copy_settings_2_default", values.copyingRatio);
			formData.append("copy_settings_values_2", values.copyingRatio);
			formData.append("copy_settings_values_3", values.dailyLimit);
			formData.append("dont_copy", `${+values.dontCopy}`);
			formData.append("copy_options", `${+values.copyOptionsFromThis}`);
			formData.append("copy_inverse", "0");
			formData.append("fixed_lot", "1");

			if (values.lotRange === "1") {
				formData.append("min_lot_open", values.fixedLotRange);
				formData.append("max_lot_open", values.fixedLotRange);
				formData.append("limited_or_fixed", "1");
			}
			if (values.lotRange === "0") {
				formData.append("min_lot_open", values.minLotRange);
				formData.append("max_lot_open", values.maxLotRange);
				formData.append("limited_or_fixed", "0");
			}

			const response = await API.post<{ success: boolean, error: string }>("copytradeSubscribeToTrader", formData);
			if (!response.data.success) {
				setErrorMsg(response.data.error);
				setIsOpen(true);
			}

			if (response.data.success) {
				dispatch(updateTraderSubscriptionStatus({ userId: +accountNumber }));
				dispatch(cleanMySubscriptionTraders({}));
			}
		} catch (e) {
			//
		} finally {
			setIsFetch(false);
		}
	}, [ setIsFetch, accountNumber ]);

	const hideErrorMsg = React.useCallback(() => {
		setIsOpen(false);
		setErrorMsg("");
	}, [ setErrorMsg, setIsOpen ]);

	const adaptiveFirstColumnStyles = useAdaptive({
		xs: xsColumnStyle,
		sm: smFirstColumn
	});

	const adaptiveSecondColumnStyles = useAdaptive({
		xs: xsColumnStyle,
		sm: smSecondColumn
	});

	const adaptiveMoreSettingsStyles = useAdaptive({
		xs: xsMoreSettingsStyles,
		sm: smMoreSettingsStyles
	});

	const adaptiveMoreSettingsFirstColumnStyles = useAdaptive({
		xs: xsMoreSettingsColumnStyles,
		sm: smMoreSettingsFirstColumnStyles
	});

	const adaptiveMoreSettingsSecondColumnStyles = useAdaptive({
		xs: xsMoreSettingsSecondColumnStyles,
		sm: smMoreSettingsSecondColumnStyles
	});

	return (
		<>
			<Form
				defaultValues={{
					variant: "1",
					copyingRatio: "",
					dailyLimit: "",
					GBP_USD: false,
					USD_CHF: false,
					AUD_USD: false,
					EUR_JPY: false,
					EUR_GBP: false,
					EUR_USD: false,
					USD_JPY: false,
					NZD_USD: false,
					EUR_CHF: false,
					USD_CAD: false,
					lotRange: "1",
					minLotRange: "0.01",
					maxLotRange: "1000",
					fixedLotRange: "0.01",
					dontCopy: "1",
					copyOptionsFromThis: "1"
				}}
				onSubmit={submit}
				validationSchema={settingsValidation}
				fieldWrapperVariant="with-tip"
			>
				<Box sx={{
					padding: "19px",
					backgroundColor: "#F6F8F9"
				}}>
					<Grid
						container
						flexDirection="row"
					>
						<Grid item sx={adaptiveFirstColumnStyles}>
							<Typography
								fontWeight="bold"
								color="#5C7080"
								lineHeight="1.125rem"
								sx={{ marginBottom: "11px" }}
							>
								{langTrading.trd_144}
							</Typography>
							<Box sx={{ marginBottom: "4px" }}>
								<Radio name="variant" value="1" label={langCopytrade.label_1}/>
							</Box>
							<Box sx={{ marginBottom: "4px" }}>
								<Radio name="variant" value="2" label={langCopytrade.label_2}/>
							</Box>
							<Box sx={{ paddingLeft: "30px", marginBottom: "4px" }}>
								<SuccessNote width="14px" height="14px" color="#707070" message="EURUSD"/>
								<SuccessNote width="14px" height="14px" color="#707070" message="USDJPY"/>
								<SuccessNote width="14px" height="14px" color="#707070" message="GBPUSD"/>
								<SuccessNote width="14px" height="14px" color="#707070" message="USDCHF"/>
							</Box>
							<Box>
								<Radio
									name="variant"
									value={value => value > 2}
									label={langCopytrade.label_3}
								/>
								<Grid
									container
									justifyContent="space-between"
									flexDirection="row"
									sx={{
										paddingLeft: "30px",
										paddingTop: "4px",
										paddingRight: "100px"
									}}
								>
									<Grid item>
										<Checkbox name="GBP_USD" variant="square" label="GBP/USD" />
										<Checkbox name="USD_CHF" variant="square" label="USD/CHF" />
										<Checkbox name="AUD_USD" variant="square" label="AUD/USD" />
										<Checkbox name="EUR_JPY" variant="square" label="EUR/JPY" />
										<Checkbox name="EUR_GBP" variant="square" label="EUR/GBP" />
									</Grid>
									<Grid item>
										<Checkbox name="EUR_USD" variant="square" label="EUR/USD" />
										<Checkbox name="USD_JPY" variant="square" label="USD/JPY" />
										<Checkbox name="NZD_USD" variant="square" label="NZD/USD" />
										<Checkbox name="EUR_CHF" variant="square" label="EUR/CHF" />
										<Checkbox name="USD_CAD" variant="square" label="USD/CAD" />
									</Grid>
								</Grid>
							</Box>
						</Grid>
						<Grid item sx={adaptiveSecondColumnStyles}>
							<Input name="copyingRatio" label={langCopytrade.trd_146} variant="rounded" />
							<Typography sx={{ marginBottom: "30px", paddingTop: "8px" }} lineHeight={"1.1875rem"} color="#707070">
								{langCopytrade.subtitle_1}
							</Typography>
							<Input name="dailyLimit" label={`${langCopytrade.copytrade_10} (${langCopytrade.label_4})`} variant="rounded" />
							<Typography sx={{ marginBottom: "30px", paddingTop: "8px" }} lineHeight={"1.1875rem"} color="#707070">
								{langCopytrade.subtitle_2}
							</Typography>
						</Grid>
					</Grid>
					<Divider sx={{ marginTop: "22px", marginBottom: "15px" }} />
					<Typography lineHeight="1.1875rem" color="#707070" sx={{ marginBottom: "15px" }}>
						{langCopytrade.copytrade_14}
					</Typography>
					<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
						<Button
							variant="contained"
							color="success"
							startIcon={<WhiteGearIcon />}
							type="button"
							onClick={() => setViewMoreSettings(!viewMoreSettings)}
						>
							{viewMoreSettings ? langCopytrade.copytrade_63 : langCopytrade.copytrade_15}
						</Button>
						<ButtonWithLoader
							color="success"
							type="submit"
							text={langMyAccounts.perdet_11}
							isFetch={isFetch}
							disabled={status === SubscriptionStatus.SUBSCRIBED}
						/>
					</Box>
					{
						viewMoreSettings
							? (
								<>
									<Divider sx={{ marginTop: "22px", marginBottom: "15px" }} />
									<Grid container flexDirection="row" sx={adaptiveMoreSettingsStyles}>
										<Grid item sx={adaptiveMoreSettingsFirstColumnStyles}>
											<Select name="lotRange" label={langCopytrade.copytrade_16}>
												<Option value="0">{langCopytrade.copytrade_17}</Option>
												<Option value="1">{langCopytrade.copytrade_18}</Option>
											</Select>
											<Typography sx={{ marginTop: "10px" }}>
												{langCopytrade.copytrade_25}
											</Typography>
										</Grid>
										<Grid item sx={adaptiveMoreSettingsSecondColumnStyles}>
											<For field="lotRange" withValues="0">
												<Select name="minLotRange" label={langCopytrade.copytrade_20}>
													<LotRangeOptions />
												</Select>
												<Select name="maxLotRange" label={langCopytrade.copytrade_21}>
													<LotRangeOptions />
												</Select>
											</For>
											<For field="lotRange" withValues="1">
												<Select name="fixedLotRange" label={langCopytrade.copytrade_18}>
													<LotRangeOptions />
												</Select>
											</For>
										</Grid>
									</Grid>
									<Checkbox name="dontCopy" variant="square" label={langCopytrade.copytrade_23} />
									<Box sx={{ marginTop: "10px" }}>
										{
											langCopytrade.copytrade_28.split("<br>").map((value: string, index: number) => (
												<Typography key={value + index} color="#707070">{value}</Typography>
											))
										}
									</Box>
									<Checkbox name="copyOptionsFromThis" variant="square" label={langCopytrade.copytrade_29} />
								</>
							)
							: (
								<></>
							)
					}
				</Box>
			</Form>
			<Snackbar open={isOpen} autoHideDuration={4000} onClose={hideErrorMsg} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
				<Alert variant="filled" color="error">
					{errorMsg}
				</Alert>
			</Snackbar>
		</>

	);
}
