import { takeEvery, put } from "redux-saga/effects";
import API from "../utils/API";
import { FETCH_USER_MAILS, SET_USER_MAILS } from "../store/actions/actionTypes";
import { IUserSupportMail } from "../store/reducers/userMailsReducer";

/**
 Get user mails info from server and set it to store
**/
export interface IMailUserMails {
	mails: Array<IUserSupportMail>
}

export function * userMailsWatcher () {
	yield takeEvery(FETCH_USER_MAILS, userMailsWorker);
}
export function * userMailsWorker () {
	const data: IMailUserMails = yield getUserMailsInfo();
	if (Object.keys(data).length) { yield put({ type: SET_USER_MAILS, payload: data.mails }); }
}
async function getUserMailsInfo () {
	const response:any = await API.get("mailUserMails");
	console.log(response);
	return response?.data || {};
}
