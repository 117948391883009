import * as React from "react";
import { Button } from "@mui/material";
import { useAppSelector } from "../../utils/hooks/reduxHooks";
import { useAdaptive } from "../../utils/hooks/themeBreakpointsHooks";
import { ReactComponent as MobileArrowLeft } from "../../images/MobileArrowLeft.svg";
import { useHistory } from "react-router-dom";

export function BackButton () {
	const adaptiveButtonBack = useAdaptive(adaptiveButtonBackStyles);
	const langInit = useAppSelector((state) => state.language.language);
	const history = useHistory();

	return (
		<Button
			startIcon={<MobileArrowLeft />}
			variant="contained"
			color="success"
			sx={adaptiveButtonBack}
			onClick={history.goBack}
		>
			{langInit.its_35}
		</Button>
	);
}

const adaptiveButtonBackStyles = {
	xs: {
		width: "100%",
		marginBottom: "20px",
		visibility: "visible",
		justifyContent: "left",
		height: "auto"
	},
	sm: {
		visibility: "hidden",
		height: 0,
		margin: 0,
		padding: 0
	}
};
