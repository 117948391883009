import * as React from "react";
import { Form } from "../../../../components/Form";
import { Input } from "../../../../components/Form/Input";
import { getWithdrawalValue } from "../../../../utils/getReceivedValue";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { fasapayWithdrawValidationSchema } from "./validation";
import { FormBottomWrapper } from "../../../../components/Form/FormBottomWrapper";
import { ButtonWithLoader } from "../../../../components/ButtonWithLoader";
import API from "../../../../utils/API";
import { AxiosResponse } from "axios";
import { Alert, Box, Skeleton, Snackbar } from "@mui/material";
import { fetchFasapayInfo } from "../../../../store/actions/actionTypes";
import { WaningNote } from "../../../../components/UI/Note";

export function Fasapay () {
	const [ success, setSuccess ] = React.useState(false);
	const [ isFetch, setIsFetch ] = React.useState(false);
	const language = useAppSelector((state) => state.language);
	const accountNumber = useAppSelector((state) => `${state.session.userData.account_number ?? ""}`);
	const currency = useAppSelector((state) => state.session.userData.currency ?? "USD");
	const [ errorMsg, setErrorMsg ] = React.useState("");
	const [ isOpen, setIsOpen ] = React.useState<boolean>(false);
	const {
		feeDetails,
		loading: infoLoading,
		complete
	} = useAppSelector((state) => state.withdrawMethodsInfo.fasaPay);
	const dispatch = useAppDispatch();

	React.useEffect(() => {
		if (!complete) {
			dispatch(fetchFasapayInfo({}));
		}
	}, []);
	const getWithdrawalValueCB = React.useCallback((values: any) => getWithdrawalValue(values, currency), [ currency ]);

	const hideErrorMsg = React.useCallback(() => {
		setIsOpen(false);
		setErrorMsg("");
	}, [ setErrorMsg ]);

	const submit = React.useCallback(async (values: any) => {
		try {
			setIsFetch(true);
			const formData = new FormData();
			formData.append("amount_withdraw", values.amount);
			formData.append("fasa_account", values.account);
			const { data } = await API.post<unknown, AxiosResponse<{ success: boolean, errorMsg: string }>>("withdrawFasapay", formData);
			if (!data.success) {
				setErrorMsg(data.errorMsg || "Something went wrong");
				setIsOpen(true);
			} else {
				setSuccess(true);
			}
		} catch (e) {
			// handle error
		} finally {
			setIsFetch(false);
		}
	}, []);

	return (
		<Form
			onSubmit={submit}
			validationSchema={fasapayWithdrawValidationSchema(language.languageFinance)}
			defaultValues={{
				accountNumber,
				amount: "",
				account: ""
			}}
		>
			<Input
				name="accountNumber"
				label={language.languageCopytrade.cpy_mon_accnum}
				variant="rounded"
			/>
			<Input
				name="amount"
				label={language.languageFinance.trd_230}
				variant="rounded"
			/>
			<Input
				name="account"
				label={language.languageFinance.trd_276}
				variant="rounded"
			/>
			<FormBottomWrapper
				forFields={[ "amount", "currency" ]}
				onChange={getWithdrawalValueCB}
				label={language.languageMyAccounts.form_message_01}
			>
				<ButtonWithLoader
					isFetch={isFetch}
					color={"primary"}
					text={language.languageMyAccounts.chapas_04}
				/>
			</FormBottomWrapper>
			<Box sx={{ marginTop: "6px" }}>
				{
					infoLoading
						? (
							<Skeleton width={"220px"} height={"26px"} />
						)
						: feeDetails
							? (
								<WaningNote
									message={`Fee is ${feeDetails}`}
								/>
							)
							: <></>
				}
			</Box>
			<Snackbar
				open={success}
				autoHideDuration={4000}
				onClose={() => setSuccess(false)}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
			>
				<Alert variant="filled" color="success">
					Success
				</Alert>
			</Snackbar>
			<Snackbar open={isOpen} autoHideDuration={4000} onClose={hideErrorMsg}>
				<Alert variant="filled" color="error">
					{errorMsg}
				</Alert>
			</Snackbar>
		</Form>
	);
}
