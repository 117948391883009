import dayjs from "dayjs";

function descendingComparator<T> (a: T|any, b: T|any, orderBy: keyof T|any) {
	let A = typeof a[orderBy] === "object" && a[orderBy].searchSort ? a[orderBy].searchSort : a[orderBy];
	let B = typeof a[orderBy] === "object" && b[orderBy].searchSort ? b[orderBy].searchSort : b[orderBy];
	if (orderBy.toLowerCase() === "date") {
		A = typeof a[orderBy] === "object" && a[orderBy].searchSort ? dayjs(a[orderBy].searchSort).valueOf() : dayjs(a[orderBy]).valueOf();
		B = typeof a[orderBy] === "object" && b[orderBy].searchSort ? dayjs(b[orderBy].searchSort).valueOf() : dayjs(b[orderBy]).valueOf();
	}
	if (B < A) { return -1; }
	if (B > A) { return 1; }
	return 0;
};

export type IOrder = "asc" | "desc";

export function getComparator<Key extends keyof any> (
	order: IOrder,
	orderBy: Key
): (
		a: { [key in Key]: number | string },
		b: { [key in Key]: number | string }
	) => number {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export function stableSort<T> (array: readonly T [], comparator: (a: any, b: any) => number) { // comparator: (a: T, b: T) => number) {
	const stabilizedThis = array.map((el, index) => [ el, index ] as [T, number]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}
