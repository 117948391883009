import { SxProps } from "@mui/material";
import { useAdaptive } from "./themeBreakpointsHooks";

const mdWrapperStyles: SxProps = {
	minWidth: "300px"
};

const smWrapperStyles: SxProps = {
	minWidth: "200px"
};

const xsWrapperStyles: SxProps = {
	minWidth: "80px"
};

export function useCellWrapperStyle () {
	const adaptiveWrapperStyles = useAdaptive({
		xs: xsWrapperStyles,
		sm: smWrapperStyles,
		md: mdWrapperStyles
	});

	return adaptiveWrapperStyles;
}
