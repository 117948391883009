/*
    Created on : 04.02.2022, 06:18:59
    Author     : Andy Kar
*/
import React, { ReactNode } from "react";
import "./FormHorizontalGroup.scss";
import FormTooltip from "../FormTooltip/FormTooltip";
import { Box, SxProps, Theme, Typography } from "@mui/material";

interface FormHorizontalGroupInterface {
	label_text?: string
	required?: boolean
	type?: string
	options?: ReactNode
	name?: string
	placeholder?: string
	defaultValue?:any
	error?: string
	tooltip_message?: string
	sx?: SxProps<Theme>
}

function FormHorizontalGroup (props:FormHorizontalGroupInterface) {
	return (
		<Box className="form-group" sx={props.sx}>
			<Box justifyContent={"space-between"} className="form-horizontal-left-column flexRow">
				<Typography fontWeight={"bold"} color={"#5C7080"} className="form-horizontal-left-column flexRow">
					{props.label_text}
					{props.required
						? <cite className="form-horizontal-req">*</cite>
						: null
					}
				</Typography>
				<FormTooltip
					// icon={InfoIcon}
					message={props.tooltip_message}
				/>
			</Box>
			<div className="form-horizontal-right-column flexRow">
				{props.type !== "select"
					? <input
						type={props.type || "text"}
						name={props.name}
						className="form-control form-horizontal-input"
						placeholder={props.placeholder || ""}
						defaultValue={props.defaultValue}
					/>
					: <select
						name={props.name}
						className="form-horizontal-select form-control"
					>
						{props.options}
					</select>
				}
				<span
					className="name-error error"
					style={{ display: "none" }}
				>
					{props.error}
				</span>
			</div>

		</Box>
	);
}

export default FormHorizontalGroup;
