/*
    Created on : 16.02.2022, 06:18:59
    Author     : Andy Kar
*/

import React, { useState } from "react";
import "./AccountsLeverageCell.scss";
import { useDispatch, useSelector } from "react-redux";
import { StoreInterface } from "../../../interfaces/StoreInterfaces";
import { SEND_UPDATE_LEVERAGE_INFO } from "../../../store/actions/actionTypes";
import { CellText } from "../../../pages/MyAccounts/CellText/CellText";
import { useAppSelector } from "../../../utils/hooks/reduxHooks";

function AccountsLeverageCell (props:any) {
	// const langMyAccount:any = useSelector((state:StoreInterface) => state.language.languageMyAccounts)
	const init:any = useSelector((state:StoreInterface) => state.init.init);
	const myAccounts = useAppSelector((state) => state.myAccounts.myAccounts);

	const [ editLeverageOpen, setEditLeverageOpen ] = useState(false);
	// const toggleLeverage = () => setEditLeverageOpen(prevState => !prevState)

	const leverageOptionsData = [
		"1:10",
		"1:20",
		"1:25",
		"1:30",
		"1:50",
		"1:100",
		"1:200",
		"1:300",
		"1:400",
		"1:500"
	];

	const leverageOptions = () => {
		return leverageOptionsData.map((option, key) => {
			if (props.account && props.account.leverage && option === props.account.leverage) {
				return (<option key={"leverageOption" + key} value={option} selected={true}>{option}</option>);
			} else {
				return (<option key={"leverageOption" + key} value={option}>{option}</option>);
			}
		});
	};

	const dispatch = useDispatch();

	const updateLeverage = () => {
		const formName:any = "leverageForm";
		const leverageForm: HTMLFormElement | null = document.forms[formName];
		if (leverageForm) {
			const leverageData = new FormData(leverageForm);
			dispatch({ type: SEND_UPDATE_LEVERAGE_INFO, leverageData });
		}
	};

	const editLeverage = () => {
		if (myAccounts.show_fx_bonus.is_showfxbonus === "1") {
			return null;
		} else if (myAccounts.users.nodepositbonus === "0" || myAccounts.user_profiles.country === "PL") {
			if (myAccounts.acc_status && (myAccounts.acc_status.accountstatus === "1" || myAccounts.acc_status.accountstatus === "3")) {
				if (init.isAccountFromEUCountry) {
					return (
						<form name="leverageForm">
							<select
								name="leverage"
								className="form-control select-leverage"
								onChange={updateLeverage}
							>
								{leverageOptions()}
							</select>
						</form>
					);
				} else {
					return (
						<form name="leverageForm">
							<select
								name="leverage"
								className="form-control select-leverage"
								onChange={updateLeverage}
							>
								{leverageOptions()}
							</select>
						</form>
					);
				}
			} else {
				return (
					<form name="leverageForm">
						<select
							name="leverage"
							className="form-control select-leverage"
							onChange={updateLeverage}
						>
							{leverageOptions()}
						</select>
					</form>
				);
			}
		}
	};

	return (
		<div className="accounts-leverage-cell">
			{!editLeverageOpen
				? <>
					<CellText width={"auto"} id="txt_leverage" textAlign={"left"}>{props?.leverage}</CellText>
					{props?.id
						? <i className="fa fa-pencil edit-lev" onClick={() => setEditLeverageOpen(true)}/>
						: null
					}
				</>
				: editLeverage()
			}
		</div>
	);
}

export default AccountsLeverageCell;
