export const getLocalStorageItem = (name:string):string|null => {
	const item = localStorage.getItem(name);
	return item;
};

export const setLocalStorageItem = (name:string, value:string) => {
	return localStorage.setItem(name, value);
};

export const getLocalStorageObj = (name:string) => {
	const item: string|null = localStorage.getItem(name);
	if (item) return JSON.parse(item);
	return null;
};

export const setLocalStorageObj = (name:string, value:{}) => {
	return localStorage.setItem(name, JSON.stringify(value));
};

export const removeLocalStorageItem = (name:string) => {
	return localStorage.removeItem(name);
};

export const clearLocalStorage = () => {
	return localStorage.clear();
};
