import { Button, CircularProgress } from "@mui/material";
import * as React from "react";
import { AccountType } from "../../../../interfaces/StoreInterfaces";
import { setAccountType } from "../../../../store/actions/actionTypes";
import API from "../../../../utils/API";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks/reduxHooks";

export interface IRegisterFollowerProps {
	agree?: boolean;
}

export function RegisterFollower (props: IRegisterFollowerProps) {
	const { agree } = props;
	const langTrading = useAppSelector((state) => state.language.languageTrading);
	const [ isFetch, setIsFetch ] = React.useState(false);
	const dispatch = useAppDispatch();

	const registerAsFollower = React.useCallback(async () => {
		setIsFetch(true);
		try {
			const response = await API.post<{ success: boolean }>("copytradeRegisterFollower");
			if (response.data.success) {
				dispatch(setAccountType(AccountType.FOLLOWER));
			}
		} catch (e) {
			//
		} finally {
			setIsFetch(false);
		}
	}, []);

	return (
		<Button
			disabled={!agree || isFetch}
			variant="outlined"
			color="primary"
			sx={{ padding: "9px 12px", marginLeft: "15px", position: "relative" }}
			onClick={registerAsFollower}
		>
			{isFetch ? <CircularProgress size={24} sx={{ position: "absolute", transform: "translateX(50%)" }}/> : <></>}
			{langTrading.trd_93}
		</Button>
	);
}
