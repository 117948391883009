import { ActionType } from "../../utils/reudxHelpers";
import { SET_BONUSES_BY_DATE, SET_BONUS_LOADING } from "../actions/actionTypes";

type TBonusState = {
	bonuses?: ({ stamp: string, amount: number })[],
	loading: boolean,
}

const initialState: TBonusState = {
	bonuses: undefined,
	loading: false
};

type Actions = ActionType<"SET_BONUSES_BY_DATE", { data: ({ stamp: string, amount: number })[] }>
| ActionType<"SET_BONUS_LOADING", boolean>;

export function bonusReducer (state = initialState, action: Actions): TBonusState {
	switch (action.type) {
		case SET_BONUSES_BY_DATE:
			return {
				...state,
				bonuses: action.payload.data
			};
		case SET_BONUS_LOADING:
			return {
				...state,
				loading: action.payload
			};
		default:
			return state;
	}
}
