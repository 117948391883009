import { AxiosResponse } from "axios";
import API from "./API";
import { PaymentMethod } from "../pages/Finance/Deposit/types";

export function createDepositApiRequest<T> (endpoint: string, paymentMethod?: PaymentMethod) {
	return async function (amount: string, currency: string): Promise<T> {
		const formData = new FormData();
		formData.append("amount", amount);
		formData.append("currency", currency);

		if (paymentMethod) {
			formData.append("payment_method", paymentMethod);
		}

		const { data }: AxiosResponse<T> = await API.post(endpoint, formData);

		return data;
	};
}
