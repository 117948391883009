import * as React from "react";
import { Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import { useIsRtl } from "../../../utils/hooks/useIsRtl";
import { FieldWrapper as FieldWrapperUI } from "../../UI/FieldWrapper";
import { FormContext } from "../Form";

export interface FileSelectProps {
	name: string;
	label: string;
	accept: string;
	emptyLabel?: string;
}

export function FileSelect (props: FileSelectProps): JSX.Element {
	const { name, label, accept, emptyLabel = "Citizen or Passport" } = props;
	const { control, fieldWrapperVariant } = React.useContext(FormContext);
	const FieldWrapper = React.useMemo(() => FieldWrapperUI[fieldWrapperVariant], [ fieldWrapperVariant ]);
	const inputRef = React.useRef<HTMLInputElement | null>(null);
	const isRtl = useIsRtl();

	const selectFile = React.useCallback(() => {
		if (inputRef.current) inputRef.current.click();
	}, [ inputRef.current ]);

	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { value, onChange }, fieldState: { error } }) => (
				<FieldWrapper label={label}>
					<input
						ref={inputRef}
						hidden
						type="file"
						onChange={(event) => onChange(event.target.files ? event.target.files[0] : {})}
						accept={accept}
					/>
					<ButtonUI
						iserror={!!error}
						isRtl={isRtl}
						onClick={selectFile}
						type="button"
					>
						{value.name ? value.name : emptyLabel}
					</ButtonUI>
					<Typography
						color="#ff0000"
						fontSize={12}
						sx={{
							position: "absolute",
							top: "100%",
							width: "100%",
							textAlign: isRtl ? "right" : "left"
						}}
					>
						{error?.message}
					</Typography>
				</FieldWrapper>
			)
			}
		/>
	);
}

export const ButtonUI = styled.button<{ iserror: boolean, isRtl: boolean, children?: any, onClick?: any, type?: any }>`
  width: 100%;
  padding: 10px;
  border: 1px solid;
  border-radius: 4px !important;
	border-color: ${({ iserror }) => iserror ? "#ff0000" : "#7A8B98"};
	text-align: ${({ isRtl }) => isRtl ? "right" : "left"};
  background-color: #fff;

  &:focus {
    color: #7A8B98;
  }
`;
