import * as React from "react";
import { Box, Button, Checkbox, Grid, Link, Typography } from "@mui/material";
import { ModalPortal } from "../../../../components/ModalPortal";
import { ReactComponent as CloseIcon } from "../../../../images/CloseIcon.svg";
import { useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { CleanButton } from "../../../../components/UI/CleanButton";
import { useHistory } from "react-router-dom";
import { RegisterFollower } from "./RegisterFollower";

export interface ICopytradingModalProps {
	isOpen: boolean;
	onClose: (value: boolean) => void;
}

export function CopytradingModal (props: ICopytradingModalProps) {
	const { isOpen, onClose } = props;
	const history = useHistory();
	const [ agree, setAgree ] = React.useState(false);
	const langTrading = useAppSelector((state) => state.language.languageTrading);
	const langCopytrade = useAppSelector((state) => state.language.languageCopytrade);

	const goToCopytradeTraderRegister = React.useCallback(() => {
		history.push("/app/copytrade/register-trader");
	}, []);

	return isOpen
		? (
			<ModalPortal>
				<Box
					sx={{
						width: "100%",
						height: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center"
					}}
				>
					<Grid
						container
						sx={{
							maxWidth: "800px",
							width: "100%",
							padding: "18px 35px 28px",
							background: "#fff"
						}}
						flexDirection="column"
					>
						<Grid item>
							<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
								<CleanButton
									onClick={() => onClose(false)}
								>
									<CloseIcon />
								</CleanButton>
							</Box>
						</Grid>
						<Grid item sx={{ marginBottom: "11px" }}>
							<Typography
								variant="h2"
								fontWeight="bold"
								color="#5C7080"
							>
								{langTrading.trd_88.toUpperCase()}
							</Typography>
						</Grid>
						<Grid item sx={{ marginBottom: "20px" }}>
							<Typography
								variant="subtitle1"
								fontWeight="light"
								lineHeight="24px"
								color="#5C7080"
							>
								{langCopytrade.modal_trd_01}
							</Typography>
						</Grid>
						<Grid item sx={{ marginBottom: "13px" }}>
							<Link href="https://my.forexmart.com/assets/pdf/Copytrading-guide.pdf" target="_blank" >
								{langCopytrade.modal_trd_02}
							</Link>
						</Grid>
						<Grid item sx={{ marginBottom: "15px" }}>
							<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
								<Checkbox sx={{ paddingLeft: "0px", paddingRight: "8px" }} onChange={() => setAgree(!agree)}/>
								<Typography
									variant="subtitle1"
									lineHeight="22px"
									fontWeight="light"
									color="#5C7080"
								>
									{langTrading.trd_90}
								</Typography>
								<Link sx={{ marginLeft: "5px" }} href="/app/copytrade/agreement" target="_blank">
									{langCopytrade.cpy_trd_agrmnt1}
								</Link>
							</Box>
						</Grid>
						<Grid item>
							<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
								<Typography
									variant="subtitle1"
									fontWeight="light"
									lineHeight="24px"
									color="#5C7080"
								>
									{`${langCopytrade.modal_trd_03}: `}
								</Typography>
								<RegisterFollower agree={agree} />
								<Button
									disabled={!agree}
									onClick={goToCopytradeTraderRegister}
									variant="outlined"
									color="success"
									sx={{ padding: "9px 12px", marginLeft: "15px" }}
								>
									{langTrading.trd_94}
								</Button>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</ModalPortal>
		)
		: (<></>);
}
