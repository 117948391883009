import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../interfaces/StoreInterfaces";

export default function FMTableRowNoFound ({ colSpan }: { colSpan: number }) {
	const langDatatable:any = useSelector((state:StoreInterface) => state.language.languageDatatable);
	return (
		<TableBody>
			<TableRow tabIndex={-1}>
				<TableCell sx={{ border: "none", margin: "5px", maxWidth: "100%", textAlign: "center" }} colSpan={colSpan}>
					<Typography>{langDatatable.dta_tbl_11}</Typography>
				</TableCell>
			</TableRow>
		</TableBody>
	);
};
