/*
    Created on : 12.11.2022, 06:18:59
    Author     : Andy Kar
*/
/* eslint-disable camelcase */
import React from "react";
import { IFMTableComponentProps } from "../../../../components/FMTable/FMTableCellComponent";
import { IUserSupportMail } from "../../../../store/reducers/userMailsReducer";
import { Box, Typography } from "@mui/material";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../../interfaces/StoreInterfaces";
import { IDepartment } from "../../PageMailSupportCompose/PageMailSupportCompose";
import SupportMailFileList from "./SupportMailFileList";
import { IInit } from "../../../../interfaces/InitInterface";

export default function SupportMailMessage (props: IFMTableComponentProps<IUserSupportMail, {}>) {
	const { message, sender, contact_emails_id, images = [] } = props;
	const init: IInit = useSelector((state:StoreInterface) => state.init.init);
	const departments:Array<IDepartment> = init?.contact_emails;
	const department:Array<IDepartment> = departments.filter((department) => department.id === contact_emails_id);

	return <Box sx={messageBoxSx}>
		<Typography>{sender}</Typography>
		<Typography>{department[0] ? department[0].email : ""}</Typography>
		{ReactHtmlParser(message)}
		<SupportMailFileList images={images}/>
	</Box>;
}

const messageBoxSx = {
	padding: "10px 20px",
	backgroundColor: "#F1F9FF"
};
