import * as React from "react";
import { Typography, Box, Skeleton } from "@mui/material";
import { ContentWrapper } from "../../../../components/UI/ContentWrapper";
import { useAppSelector } from "../../../../utils/hooks/reduxHooks";

export function MyProjectTradersDescription () {
	const languageTrading = useAppSelector((state) => state.language.languageTrading);
	const description = useAppSelector((state) => state.copytrade.myProject.fc_details?.language_desc);

	return (
		<ContentWrapper title={languageTrading.trd_138}>
			<Box sx={{ padding: "20px 23px" }}>
				{
					description
						? (
							<Typography color="#5c7080">{description}</Typography>
						)
						: (
							<Skeleton
								width="240px"
								height="21px"
							/>
						)
				}
			</Box>
		</ContentWrapper>
	);
}
