import * as React from "react";
import { Box, Portal } from "@mui/material";
import { FormNavigation } from "../../../../components/FormNavigation";
import { Redirect, Route } from "react-router-dom";
import { ZotaPay } from "./ZotaPay";
import { VNPay } from "./VNPay";
import { PayTrust } from "./PayTrust";

export interface LocalBankTransferVietnamProps {
	tabsMenuContainer: React.MutableRefObject<HTMLElement | null>
}

export function LocalBankTransferVietnam (props: LocalBankTransferVietnamProps) {
	const { tabsMenuContainer } = props;
	return (
		<>
			<Portal container={tabsMenuContainer.current}>
				<Box sx={{ paddingTop: "20px" }}>
					<FormNavigation
						paths={[
							{
								to: "/app/deposit/vnd-local-bank-transfer/zota-pay",
								title: "ZotaPay",
								styles: {
									marginRight: "15px"
								}
							},
							{
								to: "/app/deposit/vnd-local-bank-transfer/paytrust",
								title: "PayTrust",
								styles: {
									marginRight: "15px"
								}
							},
							{
								to: "/app/deposit/vnd-local-bank-transfer/vn-pay",
								title: "VN Pay"
							}
						]}
					/>
				</Box>
			</Portal>
			<Route exact path="/app/deposit/vnd-local-bank-transfer">
				<Redirect to="/app/deposit/vnd-local-bank-transfer/zota-pay" />
			</Route>
			<Route
				path="/app/deposit/vnd-local-bank-transfer/zota-pay"
			>
				<ZotaPay />
			</Route>
			<Route
				path="/app/deposit/vnd-local-bank-transfer/paytrust"
			>
				<PayTrust />
			</Route>
			<Route
				path="/app/deposit/vnd-local-bank-transfer/vn-pay"
			>
				<VNPay />
			</Route>
		</>
	);
}
