/*
    Created on : 07.08.2021, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import FooterAuth from "../../components/FooterAuth/FooterAuth";
import DashboardAuthAppBarMobile from "./DashboardAuthAppBarMobile";
import DashboardAuthSidebarMobile from "./DashboardAuthSidebarMobile";
import DashboardAuthContent from "./DashboardAuthContent";
import DashboardPage from "./DashboardPage";

export default function DashboardAuthMobile ({ children }: { children: JSX.Element | JSX.Element[]}) {
	const [ open, setOpen ] = React.useState(false);
	return (
		<>
			<DashboardAuthAppBarMobile setOpen={setOpen} />
			<DashboardAuthContent>
				<DashboardPage>
					{children}
				</DashboardPage>
			</DashboardAuthContent>
			<FooterAuth/>
			<DashboardAuthSidebarMobile open={open} setOpen={setOpen} />
		</>
	);
};
