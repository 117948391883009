import * as React from "react";
import { ICheckboxProps } from "./types";

import { SquareCheckBox } from "./SquareCheckBox";
import { RoundedCheckBox } from "./RoundedCheckBox";

export type TCheckboxVariant = "square" | "rounded";

type CheckboxModule = {
	[key in TCheckboxVariant]: React.FC<ICheckboxProps>
}

export const Checkbox: CheckboxModule = {
	square: SquareCheckBox,
	rounded: RoundedCheckBox
};
