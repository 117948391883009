import * as React from "react";
import Box from "@mui/material/Box";
import ForexmartLogoInt from "../../Icons/ForexmartLogoInt";
import Search from "../../Header/headerComponents/Search/Search";
import SidebarAccordionMenu from "./SidebarAccordionMenu";
import SidebarListButton from "./SidebarListButton";

export default function DashboardSidebarMenu (props: any) {
	return (
		<>
			<Box sx={{ flex: 1, overflowX: "hidden" }}>
				<Box
					sx={{
						width: "276px",
						padding: props.open ? "0 20px 6px 20px" : "0 6px 6px 6px",
						cursor: !props.open ? "pointer" : "initial",
						display: "flex",
						justifyContent: "flex-start",
						transition: "padding 200ms ease"
					}}
					onClick={() => props.setOpen(!open)}
				>
					<ForexmartLogoInt/>
				</Box>
				<Search open={props.open} setOpen={props.setOpen}/>
				<SidebarAccordionMenu
					open={props.open}
					setOpen={props.setOpen}
					accordion={props.accordion}
					setAccordion={props.setAccordion}
				/>
			</Box>
			<SidebarListButton
				open={props.open}
				setOpen={props.setOpen}
				setAccordion={props.setAccordion}
			/>
		</>
	);
}
