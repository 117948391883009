/*
    Created on : 24.01.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import PageTitle from "../../../components/Dashboard/dashboardComponents/PageTitle";
import CommonRebate from "../CommonRebate";
import FMTableColumn from "../../../components/FMTable/FMTableColumn";
import FMTable from "../../../components/FMTable/FMTable";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks/reduxHooks";
import { getRebateSystemPersonalProjects, setRebateSystemPersonalProjectsPage } from "../../../store/actions/actionTypes";
import { ClientCell } from "./cells/ClientCell";
import { ChangeRebateValueCell } from "./cells/ChangeRebateValueCell";
import { ActionCell } from "./cells/ActionCell";
import { Storage } from "../../../components/Storage";

const MAX_LIMIT = 100;
const TABLE_ID = "rebateSystemPersonalProjectTable";

export default function PageRebatePersonal () {
	const { list, isLoad, page } = useAppSelector(state => state.rebateSystem.personalProjects);
	const dispatch = useAppDispatch();

	const processedData = React.useMemo(() => list?.map((project, index) => ({ index: index + 2, blank: false, ...project })), [ list ]);

	function increasePage () {
		dispatch(setRebateSystemPersonalProjectsPage(page + 1));
	}

	function fetchPostSubscriptionsByParts () {
		if (isLoad) {
			dispatch(getRebateSystemPersonalProjects({
				page,
				limit: MAX_LIMIT,
				actionAfterGettingProjects: increasePage
			}));
		}
	}

	React.useEffect(() => {
		fetchPostSubscriptionsByParts();
	}, [ page ]);

	return (
		<div className="page-rebate-personal">
			<PageTitle
				layoutTitle={"Rebate System"}
				pageTitle={"Personal Rebate"}
			/>

			<CommonRebate />

			<FMTable
				data={[ { index: 1, blank: true }, ...(processedData ?? []) ]}
				theme={"CopyTradingTheme"}
				rowWrapper={Storage}
				id={TABLE_ID}
				mobileScheme={{
					mode: "points",
					880: {
						collapsibleColumns: [ 4 ]
					},
					580: {
						collapsibleColumns: [ 4, 3 ]
					}
				}}
			>
				<FMTableColumn id="index" label={"#"} />
				<FMTableColumn id="client" label={"Select"} component={ClientCell} />
				<FMTableColumn id="rebateValue" label={"Rebate (%)"} component={ChangeRebateValueCell} />
				<FMTableColumn id="Action" label={"Action"} component={ActionCell} />
			</FMTable>
		</div>
	);
};
