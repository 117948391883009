/*
    Created on : 11.11.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { useSelector } from "react-redux";
import { StoreInterface, TradersItemInterface } from "../../../../interfaces/StoreInterfaces";
import FMTable from "../../../../components/FMTable/FMTable";
import FMTableColumn from "../../../../components/FMTable/FMTableColumn";
import TraderActivityCell from "./TraderActivityCell";
import TraderAccountCell from "./TraderAccountCell";
import { CollapsibleComponent } from "./CollapsibleComponent";

export default function TraderTable () {
	const langTrading:any = useSelector((state:StoreInterface) => state.language.languageTrading);
	const traders: TradersItemInterface[] = useSelector((state:StoreInterface) => state.copytrade.tradersAll);
	const targetRowCount: number = useSelector((state:StoreInterface) => state.copytrade.tradersLength);

	return (
		<FMTable
			id="CopyTradingTraderTable"
			data={traders}
			theme="CopyTradingTheme"
			mobileScheme={{
				mode: "points",
				500: {
					collapsibleColumns: [ 2, 3, 4, 5, 6, 7, 8 ]
				},
				700: {
					collapsibleColumns: [ 2, 3, 4, 5, 6, 7 ]
				},
				800: {
					collapsibleColumns: [ 3, 4, 5, 6, 7 ]
				},
				900: {
					collapsibleColumns: [ 4, 5, 6, 7 ]
				},
				1000: {
					collapsibleColumns: [ 5, 6, 7 ]
				},
				1010: {
					collapsibleColumns: [ 6, 7 ]
				},
				1120: {
					collapsibleColumns: [ 6 ]
				}
			}}
			targetRowCount={targetRowCount}
			collapsibleComponent={CollapsibleComponent}
		>
			<FMTableColumn
				id="Account" label={langTrading.trd_80}
				component={TraderAccountCell}
				dataPropName="UserId"
			/>
			<FMTableColumn id="ProjectName" label={langTrading.reb_txt_9} />
			<FMTableColumn id="Balance" label={langTrading.trd_81} />
			<FMTableColumn id="Equity" label={langTrading.trd_82} />
			<FMTableColumn id="CurrentTrades" label={langTrading.trd_83} />
			<FMTableColumn id="TotalTrades" label={langTrading.trd_84} />
			<FMTableColumn id="DailyEquity" label={langTrading.trd_85} />
			<FMTableColumn id="RegisteredDays" label={langTrading.trd_86} />
			<FMTableColumn id="Activity" label={langTrading.trd_87} component={TraderActivityCell} />
		</FMTable>
	);
};
