import { styled } from "@mui/material/styles";
import * as React from "react";
import { IFMTableComponentProps } from "../../../../components/FMTable/FMTableCellComponent";
import { SelectUI } from "../../../../components/Form/Select";
import { IRebateSystemProject, RebateSystemProjectStatus } from "../../../../interfaces/RebateSystemInterfaces";
import { useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { useInputStorage } from "../../../../components/Storage";
import { Box } from "@mui/material";
import { mdJustifyContentStyles, xsJustifyContentStyles } from "./commonStyles";
import { useAdaptive } from "../../../../utils/hooks/themeBreakpointsHooks";

const Select = styled(SelectUI)`
  width: 100%;
  max-width: 180px;
`;

export function ChangeStatusCell (props: IFMTableComponentProps<IRebateSystemProject, {}>) {
	const { Status } = props;
	const langPartnership = useAppSelector(state => state.language.languagePartnership);
	const { value, handleChange } = useInputStorage("status", Status);

	const adaptiveWrapperStyles = useAdaptive({
		xs: xsJustifyContentStyles,
		md: mdJustifyContentStyles
	});

	return (
		<Box sx={adaptiveWrapperStyles}>
			<Select value={value} onChange={handleChange}>
				<option value={RebateSystemProjectStatus.ACTIVATED}>{langPartnership.txt_Active}</option>
				<option value={RebateSystemProjectStatus.DEACTIVATED}>{langPartnership.txt_Deactivated}</option>
			</Select>
		</Box>
	);
}
