import * as React from "react";
import FMTable from "../../../components/FMTable/FMTable";
import FMTableColumn from "../../../components/FMTable/FMTableColumn";
import { PendingTransactionActionCell } from "./components/PendingTransactionActionCell";
import { useAppSelector } from "../../../utils/hooks/reduxHooks";

export function PendingTransaction () {
	const langFinance = useAppSelector((state) => state.language.languageFinance);
	const pendingTransactions = useAppSelector((state) => state.finance.pendingTransactions);

	return (
		<FMTable
			data={pendingTransactions}
			mobileScheme={{ mode: "auto" }}
			theme="FinanceTheme"
		>
			<FMTableColumn id="id" label="#" />
			<FMTableColumn id="fundType" label={langFinance.trans_06} />
			<FMTableColumn id="operationType" label={langFinance.trans_07} />
			<FMTableColumn id="amount" label={langFinance.trans_08} />
			<FMTableColumn id="paymentSystem" label={langFinance.trans_09} />
			<FMTableColumn id="date" label={langFinance.trans_10} />
			<FMTableColumn id="action" label={langFinance.trans_11} component={PendingTransactionActionCell}/>
		</FMTable>
	);
};
