import * as React from "react";
import { useSelector } from "react-redux";
import { SessionUserDataInterface, StoreInterface } from "../../../interfaces/StoreInterfaces";
import { List } from "@mui/material";
import MyTradingIcon from "../../Icons/MyTradingIcon";
import SidebarLinkListItem from "./SidebarLinkListItem";
import FinanceIcon from "../../Icons/FinanceIcon";
import BonusIcon from "../../Icons/BonusIcon";
import PartnershipIcon from "../../Icons/PartnershipIcon";
import CopyTradingIcon from "../../Icons/CopyTradingIcon";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import SidebarAccordionLinkSummary from "./SidebarAccordionLinkSummary";
import { useEffect } from "react";
import RebateSystemIcon from "../../Icons/RebateSystemIcon";
import MailSupportIcon from "../../Icons/MailSupportIcon";
import PromotionalMaterialsIcon from "../../Icons/PromotionalMaterialsIcon";

const Accordion = styled((props: AccordionProps) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
	border: "none",
	background: "#E2E7E8",
	color: "#5C7080",
	padding: 0,
	margin: 0,
	"&:before": {
		display: "none"
	},
	"&.Mui-expanded": {
		background: "transparent"
	},
	"& .MuiTypography-root": {
		color: "#5C7080"
	},
	"&.Mui-expanded .MuiSvgIcon-root": {
		fill: "#2988CA"
	}
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
	backgroundColor: "#F6F8F9",
	padding: 0,
	borderTop: "none"
}));

export default function SidebarAccordionMenu (props:any) {
	const session:SessionUserDataInterface = useSelector((state:StoreInterface) => state.session.userData);
	const lang:any = useSelector((state:StoreInterface) => state.language.language);
	const langMyAccounts:any = useSelector((state:StoreInterface) => state.language.languageMyAccounts);
	const langFinance:any = useSelector((state:StoreInterface) => state.language.languageFinance);
	const langBonus:any = useSelector((state:StoreInterface) => state.language.languageBonus);
	const langPartnership:any = useSelector((state:StoreInterface) => state.language.languagePartnership);
	const langCopytrade:any = useSelector((state:StoreInterface) => state.language.languageCopytrade);
	const langMailSupport:any = useSelector((state:StoreInterface) => state.language.languageMailSupport);

	const userAccountType = useSelector((state:StoreInterface) => state.user.user.account_type);
	const [ expanded, setExpanded ] = React.useState<string | false>(false);

	const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
		props.setAccordion(isExpanded ? panel : false);
		setExpanded(isExpanded ? panel : false);
		props.setOpen(true);
	};

	useEffect(() => {
		if (props.open) {
			setExpanded(props.accordion);
		} else if (!props.open && expanded) {
			props.setAccordion(expanded);
			setExpanded(false);
		}
	}, [ props.accordion, props.open ]);

	useEffect(() => {
		if (expanded) props.setAccordion(expanded);
	}, [ expanded ]);

	return (
		<List
			sx={{
				width: "100%",
				backgroundColor: "#F6F8F9",
				padding: 0
			}}
			component="nav"
		>
			{session.login_type !== "1" &&
			<Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
				<SidebarAccordionLinkSummary
					to={expanded === "panel1" ? null : "my-trading/current-trades"}
					aria-controls="panel1bh-content"
					id="panel1bh-header"
					icon={<MyTradingIcon width={props.open ? 16 : 26}/>}
					text={lang.curtra_03}
					open={props.open}
				/>
				<AccordionDetails>
					<SidebarLinkListItem
						to={"my-trading/current-trades"}
						text={langMyAccounts.man_01}
					/>
					<SidebarLinkListItem
						to={"my-trading/history-of-trades"}
						text={langMyAccounts.man_02}
					/>
				</AccordionDetails>
			</Accordion>}
			<Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
				<SidebarAccordionLinkSummary
					to={expanded === "panel2" ? null : "deposit"}
					aria-controls="panel2bh-content"
					id="panel2bh-header"
					icon={<FinanceIcon width={props.open ? 16 : 26}/>}
					text={lang.sb_li_2}
					open={props.open}
				/>
				<AccordionDetails>
					<SidebarLinkListItem
						to={"deposit"}
						text={langFinance.finav_01}
					/>
					<SidebarLinkListItem
						to={"withdraw"}
						text={langFinance.finav_02}
					/>
					<SidebarLinkListItem
						to={"transfer"}
						text={langFinance.finav_03}
					/>
					<SidebarLinkListItem
						to={"transaction-history"}
						text={langFinance.finav_04}
					/>
				</AccordionDetails>
			</Accordion>
			{session.login_type !== "1" &&
			<Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
				<SidebarAccordionLinkSummary
					to={expanded === "panel3" ? null : "bonus/bonuses"}
					aria-controls="panel3bh-content"
					id="panel3bh-header"
					icon={<BonusIcon width={props.open ? 16 : 26}/>}
					text={lang.sb_li_3}
					open={props.open}
				/>
				<AccordionDetails>
					<SidebarLinkListItem
						to={"bonus/bonuses"}
						text={langBonus.bon_tab_00}
					/>
					<SidebarLinkListItem
						to={"bonus/bonuses-statistic"}
						text={langBonus.bon_tab_01}
					/>
				</AccordionDetails>
			</Accordion>}
			<Accordion expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
				<SidebarAccordionLinkSummary
					to={expanded === "panel4" ? null : "partnership/commission"}
					aria-controls="panel4bh-content"
					id="panel4bh-header"
					icon={<PartnershipIcon width={props.open ? 16 : 26}/>}
					text={lang.sb_li_4}
					open={props.open}
				/>
				<AccordionDetails>
					<SidebarLinkListItem
						to={"partnership/commission"}
						text={langPartnership.parnav_04}
					/>
					<SidebarLinkListItem
						to={"partnership/clicks"}
						text={langPartnership.parnav_05}
					/>
					<SidebarLinkListItem
						to={"partnership/referrals"}
						text={langPartnership.parnav_06}
					/>
					<SidebarLinkListItem
						to={"partnership/chart-statistics"}
						text={langPartnership.parnav_13}
					/>
					<SidebarLinkListItem
						to={"partnership/internal-transit-service"}
						text={langPartnership.oth_com_18}
					/>
				</AccordionDetails>
			</Accordion>
			{session.login_type !== "1" &&
			<Accordion expanded={expanded === "panel5"} onChange={handleChange("panel5")}>
				<SidebarAccordionLinkSummary
					to={expanded === "panel5" ? null : "copytrade/copytrading"}
					aria-controls="panel5bh-content"
					id="panel4bh-header"
					icon={<CopyTradingIcon width={props.open ? 16 : 26}/>}
					text={lang.sb_li_12}
					open={props.open}
				/>
				<AccordionDetails>
					<SidebarLinkListItem
						to={"copytrade/copytrading"}
						text={langCopytrade.reg_trader_01}
					/>
					<SidebarLinkListItem
						to={"copytrade/subscriptions"}
						text={langCopytrade.reg_trader_02}
					/>
					{userAccountType === "1"
						? <SidebarLinkListItem
							to={"copytrade/my-project"}
							text={langCopytrade.ctrd_menu_4}
						/>
						: null
					}
					<SidebarLinkListItem
						to={"copytrade/profile"}
						text={langCopytrade.ctrd_menu_5}
					/>
					<SidebarLinkListItem
						to={"copytrade/recommendeds"}
						text={langCopytrade.ctrd_menu_6}
					/>
				</AccordionDetails>
			</Accordion>}
			{session.login_type && session.login_type !== "0" && session.partner_type !== "cpa" && session.partner_type !== "extra_commission" &&
			<Accordion expanded={expanded === "panel6"} onChange={handleChange("panel6")}>
				<SidebarAccordionLinkSummary
					to={expanded === "panel6" ? null : "rebate/default"}
					aria-controls="panel6bh-content"
					id="panel6bh-header"
					icon={<RebateSystemIcon width={props.open ? 16 : 26}/>}
					text="Rebate System"
					open={props.open}
				/>
				<AccordionDetails>
					<SidebarLinkListItem
						to={"rebate/default"}
						text="Default"
					/>
					<SidebarLinkListItem
						to={"rebate/personal"}
						text="Personal Rebate"
					/>
					<SidebarLinkListItem
						to={"rebate/statistics"}
						text="Statistics"
					/>
				</AccordionDetails>
			</Accordion>}
			{session.login_type && session.partner_type !== "cpa" && session.partner_type !== "extra_commission" &&
			<Accordion expanded={expanded === "panel7"} onChange={handleChange("panel7")}>
				<SidebarAccordionLinkSummary
					to={expanded === "panel7" ? null : "mail/compose"}
					aria-controls="panel6bh-content"
					id="panel6bh-header"
					icon={<MailSupportIcon width={props.open ? 16 : 26}/>}
					text={langMailSupport.ms_01}
					open={props.open}
				/>
				<AccordionDetails>
					<SidebarLinkListItem
						to={"mail/compose"}
						text={langMailSupport.ms_08}
					/>
					<SidebarLinkListItem
						to={"mail/my-email"}
						text={langMailSupport.ms_06}
					/>
				</AccordionDetails>
			</Accordion>}
			{session.login_type && session.partner_type !== "cpa" && session.partner_type !== "extra_commission" &&
			<Accordion expanded={expanded === "panel9"} onChange={handleChange("panel9")}>
				<SidebarAccordionLinkSummary
					to={expanded === "panel9" ? null : "loyalty-programs/smart-dollars"}
					aria-controls="panel9bh-content"
					id="panel9bh-header"
					icon={<BonusIcon width={props.open ? 16 : 26}/>}
					text={"Loyalty Programs"}
					open={props.open}
				/>
				<AccordionDetails>
					<SidebarLinkListItem
						to={"loyalty-programs/smart-dollars"}
						text={"Smart Dollars"}
					/>
				</AccordionDetails>
				<AccordionDetails>
					<SidebarLinkListItem
						to={"loyalty-programs/my-achievements"}
						text={"My Achievements"}
					/>
				</AccordionDetails>
			</Accordion>}
			{session.login_type && session.login_type !== "0" && session.partner_type !== "cpa" && session.partner_type !== "extra_commission" &&
			<Accordion expanded={expanded === "panel8"} onChange={handleChange("panel8")}>
				<SidebarAccordionLinkSummary
					to={expanded === "panel8" ? null : "promotional-materials/banners"}
					aria-controls="panel6bh-content"
					id="panel8bh-header"
					icon={<PromotionalMaterialsIcon width={props.open ? 16 : 26}/>}
					text="Promotional Materials"
					open={props.open}
				/>
				<AccordionDetails>
					<SidebarLinkListItem
						to={"promotional-materials/banners"}
						text="Banners"
					/>
					<SidebarLinkListItem
						to={"promotional-materials/forexmart-logo"}
						text="ForexMart Logo"
					/>
				</AccordionDetails>
			</Accordion>}
		</List>
	);
}
