import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Box } from "@mui/material";
import AppBarLang from "./dashboardComponents/AppBarLang";
import AppBarMyAccount from "./dashboardComponents/AppBarMyAccount";

interface AppBarProps extends MuiAppBarProps {
	open?: boolean
}

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open"
})<AppBarProps>(({ theme, open }) => ({
	background: "#2988CA",
	boxShadow: "none",
	position: "relative",
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create([ "width", "margin" ], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen
	}),
	...(open && {
		transition: theme.transitions.create([ "width", "margin" ], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	})
}));

function DashboardAppBar ({ open, setAccordion }: any) {
	return (
		<AppBar open={open}>
			<Toolbar sx={{ justifyContent: "flex-end", padding: "0!important" }} className={"flexRow"}>
				<Box className={"flexRow"} sx={{ height: "100%" }}>
					<AppBarLang mobile={false}/>
					<Box sx={{ height: "64px", width: "2px", background: "#2A7CB6" }}/>
					<AppBarMyAccount setAccordion={setAccordion}/>
				</Box>
			</Toolbar>
		</AppBar>
	);
}

export default DashboardAppBar;
