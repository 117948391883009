import React from "react";
import { Typography } from "@mui/material";
import { useIsRtl } from "../../../utils/hooks/useIsRtl";
import { IChildren } from "../../../types/common";

const PartnershipLabel = (children: IChildren) => {
	const isRtl = useIsRtl();
	return (
		<Typography fontWeight={"bold"} textAlign={isRtl ? "right" : "left"} lineHeight={"19px"} mb={"8px"} color={"secondary"}>
			{children}
		</Typography>

	);
};

export default PartnershipLabel;
