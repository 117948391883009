import React, { FC } from "react";

interface IForm extends React.HTMLProps<HTMLFormElement> {
	handleFormSubmit: () => void
}

export const Form:FC<any> = (props:IForm) => {
	return (
		<form noValidate onSubmit={props.handleFormSubmit}>
			{props.children}
		</form>
	);
};
