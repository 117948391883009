/*
    Created on : 01.07.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import FMTable from "../../../../../components/FMTable/FMTable";
import FMTableTitle from "../../../../../components/FMTable/FMTableTitle";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../../../interfaces/StoreInterfaces";
import { AccountsInterface, AccountsItemInterface } from "../../../../../interfaces/myAccountsInterfaces";
import AccountsAccountCell from "../../../../../components/Tables/AccountsAccountCell/AccountsAccountCell";
import AccountsLeverageCell from "../../../../../components/Tables/AccountsLeverageCell/AccountsLeverageCell";
import AccountsVerificationCell
	from "../../../../../components/Tables/AccountsVerificationCell/AccountsVerificationCell";
import AccountsTradeStatusCell from "../../../../../components/Tables/AccountsTradeStatusCell/AccountsTradeStatusCell";
import AccountsSwapCell from "../../../../../components/Tables/AccountsSwapCell/AccountsSwapCell";
import AccountsActionCell from "../../../../../components/Tables/AccountsActionCell/AccountsActionCell";
import FMTableColumn from "../../../../../components/FMTable/FMTableColumn";
import { CellText } from "../../../CellText/CellText";

interface Data {
	Accounts?: any
	Leverage?: any
	Currency?: string
	Balance?: number
	Status?: any
	TradingStatus?: any
	AccountType?: string
	SwapFree?: any
	Action?: any
};

export interface HeadCell {
	disablePadding?: boolean;
	id: keyof Data;
	label: string;
	align?: string
};

export default function AccountsList () {
	const langMyAccount:any = useSelector((state:StoreInterface) => state.language.languageMyAccounts);
	const langTrading:any = useSelector((state:StoreInterface) => state.language.languageTrading);
	const myAccounts:AccountsInterface = useSelector((state:StoreInterface) => state.myAccounts.myAccounts);

	const AccountsCurrencyCell = (props: any) => {
		return <CellText textAlign={"left"}>{props.mt_currency_base === "RUB" ? "RUR" : props.mt_currency_base}</CellText>;
	};

	let data: AccountsItemInterface[] | [] = myAccounts.accounts || [];
	if (myAccounts.accountsRelated) {
		data = data.concat(myAccounts.accountsRelated);
	}

	const AccountsBalanceCell = ({ amount }: any) => {
		return <CellText textAlign={"left"}>{typeof amount === "string" ? parseFloat(amount) : amount}</CellText>;
	};

	return (
		<>
			<FMTableTitle title="List of Accounts"/>
			<FMTable
				data={data}
				rowsPerPageOptions={[ 5, 10, 15 ]}
				mobileScheme={{
					mode: "auto",
					auto: {
						collapsibleColumns: [ 1, 3, 2, 4 ],
						hideColumns: [ 5, 6, 7 ]
					}
					// mobile: {
					// 	collapsibleColumns: [ 2, 3 ],
					// 	hideColumns: [ 4 ]
					// }
				}}
			>
				<FMTableColumn
					id="Accounts"
					label={langMyAccount.macv_00}
					component={AccountsAccountCell}
					dataPropName="account_number"
				/>
				<FMTableColumn
					id="Leverage"
					label={langMyAccount.macv_01}
					component={AccountsLeverageCell}
					dataPropName="leverage"
				/>
				<FMTableColumn
					id="Currency"
					label={langMyAccount.macv_02}
					component={AccountsCurrencyCell}
					dataPropName="mt_currency_base"
				/>
				<FMTableColumn
					id="Balance"
					label={langMyAccount.macv_03}
					component={AccountsBalanceCell}
					dataPropName="amount"
				/>
				<FMTableColumn
					id="Status"
					label={langMyAccount.macv_04}
					component={AccountsVerificationCell}
					dataPropName="accountstatus"
				/>
				<FMTableColumn
					id="TradingStatus"
					label={langTrading.trd_status}
					component={AccountsTradeStatusCell}
					dataPropName="trade_status"
				/>
				<FMTableColumn
					id="AccountType"
					label={langMyAccount.macv_05}
					dataPropName="account_type"
					component={(props: any) => {
						return <CellText>{props.account_type}</CellText>;
					}}
				/>
				<FMTableColumn
					id="SwapFree"
					label={langMyAccount.macv_06}
					component={AccountsSwapCell}
					dataPropName="swap_free"
				/>
				<FMTableColumn
					id="Action"
					label={langMyAccount.mya_33}
					align="center"
					component={AccountsActionCell}
				/>
			</FMTable>

		</>
	);
};
