/*
Created on : 09.05.2022, 06:18:59
Author     : Andy Kar
*/
import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import { useIsRtl } from "../../../utils/hooks/useIsRtl";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface StyledMenuProps extends MenuProps{
	paperprops: any
	isrtl: string | null | boolean
}

const StyledMenu = styled((props: StyledMenuProps) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: "bottom",
			horizontal: !props.isrtl ? "left" : "right"
		}}
		transformOrigin={{
			vertical: "top",
			horizontal: !props.isrtl ? "left" : "right"
		}}
		{...props}
	/>
))(({ theme, paperprops }) => ({
	"& .MuiPaper-root": {
		...paperprops,
		borderRadius: 0,
		marginTop: 0,
		minWidth: 180,
		color:
			theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
		boxShadow:
			"rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
		"& .MuiMenu-list": {
			padding: "4px 0",
			display: "flex",
			flexWrap: "wrap"
		},
		"& .MuiMenuItem-root": {
			width: "160px",
			marginBottom: "30px",
			"& .MuiSvgIcon-root": {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5)
			},
			"&:active": {
				backgroundColor: alpha(
					theme.palette.primary.main,
					theme.palette.action.selectedOpacity
				)
			}
		}
	},
	"& .MuiButton-endIcon": {
		marginRight: "0px",
		marginLeft: "0px"
	},
	"& .MuiButton-startIcon": {
		marginRight: "0px",
		marginLeft: "0px"
	}
}));

export default function AppBarAuthDropdownButton (props:any) {
	const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const isRtl = useIsRtl();

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Button
				className={"flexRow"}
				id={props.menuId + "-button"}
				aria-controls={open ? props.menuId : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				variant="outlined"
				color="secondary"
				disableElevation
				onClick={handleClick}
				endIcon={!props.isRtl ? <KeyboardArrowDownIcon/> : null}
				startIcon={props.isRtl ? <KeyboardArrowDownIcon/> : null}
				sx={{
					cursor: "pointer",
					height: "40px",
					padding: "0 5px",
					margin: "8px",
					borderRadius: 0,
					"&:hover": {
						backgroundColor: "transparent"
					}
				}}
			>
				{props.children}
			</Button>
			<StyledMenu
				isrtl={isRtl ? "true" : null}
				id={props.menuId + "-menu"}
				MenuListProps={{
					"aria-labelledby": `${props.menuId}-button`
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				paperprops={props.paperProps}
			>
				{props.menuInner(handleClose)}
			</StyledMenu>
		</>
	);
}
