import * as React from "react";
import { Box, Typography } from "@mui/material";
import { useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { DefaultModal, ModalBlock } from "../../../UI/Modals";
import { Form } from "../../../Form";
import { smsSecurityValidation } from "./validation";
import { Radio } from "../../../Form/Radio";
import { ModalButtons } from "../components/ModalButtons";

export interface ISmsSecurityProps {
	isOpen: boolean;
	onClose: () => void;
}

enum SmsSecurityVariant {
	CORPORATE_ACCOUNT_VERIFICATION = "1",
	DEACTIVATE = "2"
}

export function SmsSecurity (props: ISmsSecurityProps) {
	const { isOpen, onClose } = props;
	const langMyAccounts = useAppSelector((state) => state.language.languageMyAccounts);
	const [ isFetch, setIsFetch ] = React.useState(false);

	const submit = React.useCallback((values: any) => {
		setIsFetch(true);
		console.log(values);
		setTimeout(() => setIsFetch(false), 2000);
	}, []);

	return (
		<DefaultModal
			title={langMyAccounts.mfn_006}
			isOpen={isOpen}
			onClose={onClose}
		>
			<Form
				onSubmit={submit}
				defaultValues={{}}
				validationSchema={smsSecurityValidation}
			>
				<ModalBlock>
					<Typography
						color="#ff0000"
						variant="subtitle1"
						sx={{
							marginBottom: "20px"
						}}
					>
						{langMyAccounts.mfn_07}
					</Typography>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							flexWrap: "wrap"
						}}
					>
						<Box sx={{ marginRight: "15px" }}>
							<Radio
								label={langMyAccounts.mfn_05}
								name="smsSecurityVariant"
								value={SmsSecurityVariant.CORPORATE_ACCOUNT_VERIFICATION}
							/>
						</Box>
						<Radio
							label={langMyAccounts.mfn_06}
							name="smsSecurityVariant"
							value={SmsSecurityVariant.DEACTIVATE}
						/>
					</Box>
				</ModalBlock>
				<ModalButtons isLoad={isFetch} onClose={onClose} />
			</Form>
		</DefaultModal>
	);
}
