/*
Created on : 09.05.2022, 06:18:59
Author     : Andy Kar
*/
import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { StoreInterface } from "../../../interfaces/StoreInterfaces";
import LangFlagIcon from "../../Icons/flagIcons/LangFlagIcon";
import languageName from "../../../utils/languageName";
import { Typography } from "@mui/material";
import siteLanguagesList from "../../../utils/siteLanguagesList";
import { CHANGE_LANGUAGE } from "../../../store/actions/actionTypes";
import AppBarDropdownButton from "./AppBarDropdownButton";

export default function AppBarLang ({ mobile }: { mobile: boolean | undefined }) {
	const lang = useSelector((state:StoreInterface) => state.session.userData.site_lang);
	const dispatch = useDispatch();
	const setLang = (langCode:string):boolean => {
		dispatch({ type: CHANGE_LANGUAGE, language: langCode });
		return true;
	};

	const menuInner = (handleClose:any) => {
		return siteLanguagesList().map((language, index) => {
			return (
				<MenuItem key={index} onClick={() => { setLang(language); handleClose(); }} disableRipple>
					<LangFlagIcon lang={language} />
					<Typography component={"span"} sx={{ padding: "0 9px", textTransform: "capitalize" }}>{languageName(language).name}</Typography>
				</MenuItem>
			);
		});
	};

	const paperProps = {
		width: "700px",
		maxWidth: "100%",
		padding: "30px 30px 10px 30px"
	};

	const paperPropsMobile = {
		width: "700px",
		maxWidth: "100%",
		padding: "30px 10px 10px 10px",
		top: "64px!important",
		left: "0!important"
	};

	return (
		<>
			<AppBarDropdownButton
				menuId="language"
				menuInner={menuInner}
				paperProps={mobile ? paperPropsMobile : paperProps}
			>
				<LangFlagIcon lang={lang} />
				<Typography component={"span"} sx={{ padding: "0 9px", textTransform: mobile ? "capitalize" : "uppercase" }}>{languageName(lang).name}</Typography>
			</AppBarDropdownButton>
		</>
	);
}
