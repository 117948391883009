/*
    Created on : 20.01.2022, 06:18:59
    Author     : Andy Kar
*/
import React, { useMemo } from "react";
import "./PageClicks.scss";
import PageTitle from "../../../components/Dashboard/dashboardComponents/PageTitle";
import { useAppSelector } from "../../../utils/hooks/reduxHooks";
import CommissionCard from "../PageCommission/CommissionCard";
import { ReactComponent as UpIcon } from "../images/up.svg";
import Title from "../../../components/Typography/Title";
import FMTable from "../../../components/FMTable/FMTable";
import FMTableAffiliateCode from "../../../components/FMTable/FMTableAffiliateCode";
import FMTableColumn from "../../../components/FMTable/FMTableColumn";

export default function PageClicks () {
	const lang = useAppSelector((state) => state.language.language);
	const langPartnership:any = useAppSelector((state) => state.language.languagePartnership);
	const data: any[] = useAppSelector(state => state.partnership.clicks?.data);
	const codes = useAppSelector(state => state.partnership.info.affiliate_code_info);

	const commonStatics = useMemo(() => data?.reduce((acc, curr) => +curr.num + acc, 0), [ data ]);

	return (
		<div className="page-partnership-clicks">
			<PageTitle
				layoutTitle={lang.sb_li_4}
				pageTitle={langPartnership.parnav_05}
			/>
			<Title>{langPartnership.com_01}</Title>
			<div className="commission-row">
				<CommissionCard color={"#ED1F24"} title={"Common Statics"} icon={<UpIcon/>} number={commonStatics} />
			</div>
			<FMTable
				data={data}
				rowsPerPageOptions={[ 5, 10, 25, 50, 100 ]}
				withPeriodFilter={true}
				withPeriodButtons={false}
				withChart={true}
				chartScheme={{ xColumnId: "Date", yColumnId: "Sum" }}
				innerComponent={<FMTableAffiliateCode codes={codes}/>}
				mobileScheme={{ mode: "auto" }}
				theme="PartnershipTheme"
			>
				<FMTableColumn id="Date" label="Date" dataPropName="date" />
				<FMTableColumn id="Sum" label="Number of clicks" dataPropName="num" />
				<FMTableColumn id="Accounts" label="Number of Accounts opened" dataPropName="acnum" />
			</FMTable>
		</div>
	);
};
