import * as React from "react";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks/reduxHooks";
import { getMySubscriptionsTraders } from "../../../store/actions/actionTypes";
import FMTable from "../../../components/FMTable/FMTable";
import FMTableColumn from "../../../components/FMTable/FMTableColumn";
import { AccountProjectNameCellTrader } from "./components/AccountProjectNameCell";
import { ActivityCell } from "./components/ActivityCell";
import { UnsubscribeActionCell } from "./components/ActionsCell";
import { StatusCell } from "./components/StatusCell";
import { ExpandedRow } from "./components/ExpandedRow";

const LIMIT = 1000;

export function TradersYouSubscribedTo () {
	const languageTrading = useAppSelector(state => state.language.languageTrading);
	const languageMyAccounts = useAppSelector(state => state.language.languageMyAccounts);
	const dispatch = useAppDispatch();
	const { data, count } = useAppSelector((state) => state.copytrade.mySubscriptions.traders);

	const fetchTradersByParts = React.useCallback(() => {
		if ((data && data.length < count) || !data) {
			const currentDataLength = data?.length || 0;
			const page = Math.ceil(currentDataLength / LIMIT + 1);
			dispatch(getMySubscriptionsTraders({ page, limit: LIMIT }));
		}
	}, [ data, count ]);

	React.useEffect(() => {
		fetchTradersByParts();
	}, [ data?.length, count ]);

	return (
		<>
			<FMTable
				data={data}
				targetRowCount={count}
				collapsibleComponent={ExpandedRow}
				mobileScheme={{
					mode: "auto"
				}}
			>
				<FMTableColumn id="ConnectionId" label={languageTrading.trd_102} />
				<FMTableColumn id="Account" label={languageMyAccounts.account_project} component={AccountProjectNameCellTrader} />
				<FMTableColumn id="CreateTime" label={languageTrading.trd_104} />
				<FMTableColumn id="StatusId" label={languageTrading.trd_106} component={StatusCell} />
				<FMTableColumn id="PendingRollOver" label={languageTrading.trd_280} />
				<FMTableColumn id="Activity" label={languageTrading.trd_87} component={ActivityCell} />
				<FMTableColumn id="Actions" label={languageTrading.reb_txt_13} component={UnsubscribeActionCell} />
			</FMTable>
		</>
	);
}
