import React from "react";

const SecurityInformationIcon = (props: any) => {
	let width = 16;
	if (props.width) {
		width = props.width;
	}

	return (
		<svg width={width} height={width} fill={props.fill} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 16">
			<defs>
				<clipPath id="icon-profile-secure-clip-path">
					<rect width={width} height={width} transform="translate(27 18)" fill={"#e4e4e4"}/>
				</clipPath>
			</defs>
			<g transform="translate(-27 -18)" clipPath="url(#icon-profile-secure-clip-path)">
				<g id="icon-profile-secure" transform="translate(25.619 16.271)">
					<path d="M16.2,8.7l-.005-.254V8.436c-.044-.881-.044-2.034-.044-3.493a.683.683,0,0,0-.482-.64L9.651,1.784a.648.648,0,0,0-.52,0L3.1,4.265l-.018.008a.79.79,0,0,0-.45.671c0,.821-.014,1.543-.027,2.179-.01.5-.018.932-.018,1.322-.092,2.328-.079,3.455.425,4.465.631,1.3,2.914,2.74,4.747,3.9.441.279.858.542,1.192.767a.73.73,0,0,0,.44.153.786.786,0,0,0,.352-.088l.029-.017c.329-.222.742-.485,1.18-.762,1.86-1.18,4.175-2.649,4.808-3.95C16.26,11.918,16.237,10.774,16.2,8.7Zm-1.684,3.588c-.479.958-2.817,2.428-4.365,3.4-.293.184-.553.347-.778.493-.214-.138-.459-.293-.734-.467-1.545-.977-3.878-2.452-4.407-3.423-.318-.722-.331-1.709-.24-3.782V8.494c0-.43.009-.893.019-1.383.011-.535.022-1.086.024-1.688L9.369,3.178,14.75,5.424c0,1.17,0,2.187.044,3.076l.006.286C14.841,10.588,14.863,11.581,14.511,12.285Z" />
					<path d="M11.633,7.826a.737.737,0,0,0-1.024,0L8.792,9.643l-.486-.487a.693.693,0,1,0-.981.981l.976.976a.738.738,0,0,0,1.026,0l2.306-2.306.024-.027a.785.785,0,0,0,0-.927Z" />
				</g>
			</g>
		</svg>
	);
};

export default SecurityInformationIcon;
