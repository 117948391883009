const languageLink = (link:string, language:string = ""):string => {
	let newLink = link;
	let lang = "";
	switch (language) {
		case "english": lang = ""; break;
		case "arabic": lang = "sa"; break;
		case "french": lang = "fr"; break;
		case "german": lang = "de"; break;
		case "indonesian": lang = "id"; break;
		case "portuguese": lang = "pt"; break;
		case "russuan": lang = "ru"; break;
		case "bulgarian": lang = "bg"; break;
		case "malay": lang = "my"; break;
		case "polish": lang = "pl"; break;
		case "urdu": lang = "pk"; break;
		case "greek": lang = "gr"; break;
		case "czech":lang = "cz"; break;
		case "chinese": lang = "zh"; break;
		case "bangladesh": lang = "bd"; break;
		case "vietnam": lang = "vn"; break;
		default: lang = "";
	}

	if (lang) {
		const url = link.split("//");
		const protocol = url[0];
		const location = url[1];
		const segments = location.split("/");
		const host = segments.shift();
		segments.unshift(lang);

		newLink = protocol + "//" + host + "/" + segments.join("/");
	}

	return newLink;
};

export default languageLink;
