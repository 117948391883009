/*
    Created on : 24.01.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import PageTitle from "../../../components/Dashboard/dashboardComponents/PageTitle";
import PromotionCommon from "../PromotionCommon";
import FMTableColumn from "../../../components/FMTable/FMTableColumn";
import FMTable from "../../../components/FMTable/FMTable";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../interfaces/StoreInterfaces";
import { IBannerListItem } from "../../../store/reducers/bannersReducer";
import BannersActionCell from "./components/BannersActionCell";
import BannersRow from "./components/BannersRow";
import { PagePromotionalMaterials } from "../../../layouts/LayoutPromotionalMaterials/LayoutPromotionalMaterials";

export default function PagePromotionalMaterialsBanners (props: PagePromotionalMaterials) {
	const { affiliateCode, setAffiliateCode, affiliateCodes } = props;
	const lang:any = useSelector((state:StoreInterface) => state.language.languageBanners);
	const bannersTableData:Array<IBannerListItem> = useSelector((state:StoreInterface) => state.banners.banners.bannerList);

	return (
		<div className="page-rebate-default">
			<PageTitle
				layoutTitle={lang.b_t_td_8} // "Promotional Materials"
				pageTitle={lang.b_t_td_9} // "Banners"
			/>

			<PromotionCommon
				affiliateCode={affiliateCode}
				setAffiliateCode={setAffiliateCode}
				affiliateCodes={affiliateCodes}
			/>

			<FMTable
				id="PromotionalMaterialsBanner"
				data={bannersTableData}
				rowsPerPageOptions={[ 15, 30 ]}
				componentData={{ affiliateCode }}
				collapsibleComponent={BannersRow}
				theme={"CopyTradingTheme"}
				mobileScheme={{
					mode: "auto",
					auto: { hideColumns: [ 1, 4, 3 ] }
				}}
			>
				<FMTableColumn id={"num"} label={"#"} />
				<FMTableColumn id={"size"} label={lang.b_p_2} />
				<FMTableColumn id={"count"} label={lang.b_t_td_1} />
				<FMTableColumn id={"type"} label={lang.b_t_td_2} />
				<FMTableColumn id={"Action"} label={lang.b_t_td_3} component={BannersActionCell} />
			</FMTable>
		</div>
	);
};
