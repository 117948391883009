/* eslint-disable no-constant-condition */
/*
    Created on : 01.02.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { useSelector } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { StoreInterface, UserDataInterface } from "../../../../../interfaces/StoreInterfaces";
import { Box, Button, Typography } from "@mui/material";
import DemoAccountIcon from "./DemoAccountIcon";
import RealAccountIcon from "./RealAccountIcon";
import AngleRightIcon from "./AngleRightIcon";
import { useIsRtl } from "../../../../../utils/hooks/useIsRtl";
import { ISxAdaptive, useAdaptive } from "../../../../../utils/hooks/themeBreakpointsHooks";

const adaptiveInnerBoxStyles: ISxAdaptive = {
	xs: {
		display: "flex",
		flexDirection: "row",
		maxWidth: "100%",
		width: "100%",
		flexWrap: "wrap"
	},
	sm: {
		justifyContent: "flex-end",
		flexWrap: "nowrap"
	}
};

const adaptiveButtonsStyles: ISxAdaptive = {
	xs: {
		maxWidth: "100%",
		minWidth: "200px",
		marginBottom: "15px"
	},
	sm: {
		maxWidth: "245px",
		marginLeft: "15px",
		marginBottom: 0
	},
	md: {
		maxWidth: "345px"
	}
};

function NewAccountButtons () {
	const langMyAccounts:any = useSelector((state:StoreInterface) => state.language.languageMyAccounts);
	const init:any = useSelector((state:StoreInterface) => state.init.init);
	const user:UserDataInterface = useSelector((state:StoreInterface) => state.user.user);
	const isRtl = useIsRtl();
	const history = useHistory();

	const adaptiveInnerBox = useAdaptive(adaptiveInnerBoxStyles);
	const adaptiveButtons = useAdaptive(adaptiveButtonsStyles);

	const buttonSX = React.useMemo(() => ({
		width: "100%",
		height: "45px",
		margin: 0,
		borderRadius: 0,
		fontSize: "14px",
		padding: !isRtl ? "0 9px 0 14px" : "0 14px 0 9px",
		backgroundColor: "#fff",
		"&:hover": {
			backgroundColor: "#fff"
		},
		"& .MuiTypography-root": {
			flex: 1,
			color: "#5C7080",
			textTransform: "none"
		},
		...adaptiveButtons
	}), [ isRtl, adaptiveButtons ]);

	return (
		<Box
			className="flexRow"
			sx={{
				flex: 1
			}}
		>
			<form
				action={init.links.link_register_demo_setName}
				method="post"
				style={{ margin: "0 15px", width: "100%" }}
			>
				<Box sx={{
					display: "flex",
					justifyContent: "flex-end"
				}}>
					<Box sx={adaptiveInnerBox}>
						<input type="hidden" name="email" value={user.email || ""} />
						<input type="hidden" name="full_name" value={user.full_name || ""} />
						<Button
							sx={buttonSX}
							startIcon={!isRtl ? <DemoAccountIcon/> : <AngleRightIcon/>}
							endIcon={isRtl ? <DemoAccountIcon/> : <AngleRightIcon/>}
							variant="outlined"
							type="submit"
						>
							<Typography className={"flexRow"}>{langMyAccounts.mya_03}</Typography>
						</Button>

						{user.accountstatus === "1" || user.accountstatus === "3"
							? (
								<Button
									sx={buttonSX}
									startIcon={!isRtl ? <RealAccountIcon/> : <AngleRightIcon/>}
									endIcon={isRtl ? <RealAccountIcon/> : <AngleRightIcon/>}
									variant="outlined"
									type="button"
									onClick={() => history.push("/app/registration")}
								>
									<Typography className={"flexRow"}>{langMyAccounts.reg_in_02}</Typography>
								</Button>
							)
							: (<></>)
						}
					</Box>
				</Box>
			</form>
		</Box>
	);
}
export default withRouter(NewAccountButtons);
