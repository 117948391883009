import React from "react";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../interfaces/StoreInterfaces";
import { Button } from "@mui/material";

export default function AppBarVerifiedButton () {
	const init:any = useSelector((state:StoreInterface) => state.init.init);
	let isVerified:boolean = false;
	let btnText:string = "";
	if (init.accStatus && init.accStatus.accountStatusData && init.accStatus.accountStatusData.accountstatus) {
		const status = parseInt(init.accStatus.accountStatusData.accountstatus);
		switch (status) {
			case 1:
			case 3:
				isVerified = true;
				btnText = init.accStatus.accountStatusData.accountstatustext.toLowerCase();
				break;
			default:
				btnText = "Not Verified";
				break;
		}
	};

	return (
		<> {init.accStatus && init.accStatus.accountStatusData && init.accStatus.accountStatusData.accountstatus &&
		<Button
			color={isVerified ? "success" : "secondary"}
			variant="contained"
			sx={{
				height: "28px",
				padding: "0 6px",
				textTransform: "capitalize",
				backgroundColor: isVerified ? "#29A643" : "#A0ACAF",
				overflow: "hidden"
			}}
		>
			{btnText}
		</Button>}</>
	);
};
