import React from "react";
import { Row } from "../../components/UI/Row";
import PromotionImg from "./img/promotion.png";
import Title from "../../components/Typography/Title";
import { Box, InputBase, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { useIsMobile } from "../../utils/hooks/useIsMobile";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../interfaces/StoreInterfaces";
import ReactHtmlParser from "react-html-parser";
import { IAffiliateCode } from "../../store/reducers/bannersReducer";
import { styled } from "@mui/styles";
import { ISetState } from "../../types/common";

export type IPromotionCommon = {
	affiliateCode: string
	setAffiliateCode: ISetState
	affiliateCodes: Array<IAffiliateCode>
}

export const SelectInput = styled(InputBase)(() => ({
	width: "100%",
	padding: 0,
	margin: 0,
	"& .MuiInputBase-input": {
		fontSize: 24,
		fontWeight: 200,
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	}
}));

export default function PromotionCommon (props: IPromotionCommon) {
	const { affiliateCode, setAffiliateCode, affiliateCodes } = props;
	const isMobile = useIsMobile();
	const lang:any = useSelector((state:StoreInterface) => state.language.languageBanners);

	const changeAffiliateCode = (event: SelectChangeEvent) => {
		setAffiliateCode(event.target.value as string);
	};

	const renderOptions = () => {
		return affiliateCodes?.map((item, index:number) => {
			return <MenuItem
				key={index}
				value={item.affiliate_code}
				sx={{
					height: "50px",
					fontSize: 24,
					fontWeight: 200,
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					cursor: "pointer"
				}}
			>
				{item.affiliate_code}
			</MenuItem>;
		});
	};

	return (
		<Row sx={{ alignItems: "center", gap: "24px", flexWrap: isMobile ? "wrap" : "nowrap", mb: "10px" }}>
			<img style={{ margin: "0 auto" }} src={PromotionImg} alt=""/>
			<div>
				<Title>{ReactHtmlParser(lang.b_p_0)}</Title>
				<Title m={"15px 0 23px 0"}>{lang.b_p_1}</Title>
				<Box
					width={isMobile ? "100%" : "252px"}
					height={"58px"}
					mb={isMobile ? "15px" : 0}
					sx={{ background: "#E2E7E8" }}
					display={"flex"}
				>
					{affiliateCodes?.length > 1
						? <Select
							input={<SelectInput/>}
							value={affiliateCode}
							onChange={changeAffiliateCode}
							sx={{
								width: "70px",
								marginX: "10px"
							}}
							MenuProps={{
								sx: {
									"& .MuiPaper-root": {
										borderRadius: 0
									},
									"& .MuiList-root": {
										padding: 0
									}
								}
							}}
						>
							{renderOptions()}
						</Select>
						: affiliateCodes?.length === 1
							? <Typography
								fontWeight={"lighter"}
								color={"#5C7080"}
								m={"auto"}
								fontSize={"24px"}
							>
								{affiliateCodes[0].affiliate_code}
							</Typography>
							: null
					}
				</Box>
			</div>
		</Row>
	);
};
