import * as React from "react";
import { Box, Button, Grid, GridWrap, SxProps } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

export interface Path {
	to: string,
	title: string,
	styles?: SxProps,
	marginRight?: number,
}

export interface FormNavigationProps {
	paths: Path[],
	wrap?: GridWrap,
}

export function FormNavigation (props: FormNavigationProps) {
	const { paths, wrap = "nowrap" } = props;

	return (
		<Grid container flexDirection="row" wrap={wrap} sx={{ marginBottom: "20px" }}>
			{
				paths.map((path) => {
					return <FormNavigationItem key={path.title} {...path}/>;
				})
			}
		</Grid>
	);
}

function FormNavigationItem (props: Path) {
	const { title, to, styles } = props;
	const location = useLocation();

	return (
		<Box sx={{ width: "100%", ...styles }}>
			<StyledLink
				to={to}
			>
				<Button
					variant="contained"
					color={"secondary"}
					sx={{
						width: "100%",
						textTransform: "none",
						background: location.pathname === to ? "#5C7080" : "#AEB6BC"
					}}
				>
					{title}
				</Button>
			</StyledLink>
		</Box>
	);
}

const StyledLink = styled(Link)`
	display: block;
	width: 100%;
`;
