import * as React from "react";
import { useEffect, useState } from "react";
import { chartData, searchChartData } from "./formatStatisticData";
import getDateWithOffset from "../../../../utils/getDateWithOffset";
import StatisticChartsPeriod from "./StatisticChartsPeriod";
import Title from "../../../../components/Typography/Title";
import { Box } from "@mui/material";
import StatisticChart, { IStatisticChart } from "./StatisticChart";
import { IStatisticChartData } from "../PageChartStatistics";
import { SimpleTabs } from "../../../../components/UI/Tabs/SimpleTabs";

export interface IStatisticChartsTabs<T> {
	data: Array<IStatisticChartData<T>>
};

export default function StatisticChartsTabs<T> (props: IStatisticChartsTabs<T>) {
	const { data } = props;
	const periodsNames: string[] = [ "", "All", "Day", "Week", "Month", "3 Months", "Year" ];
	const [ charts, setCharts ] = React.useState([] as Array<IStatisticChart>);
	const [ period, setCurrentPeriod ] = useState(1);
	const [ dateFrom, setCurrentDateFrom ] = useState(() => getDateWithOffset(365 * 10));
	const [ dateTo, setCurrentDateTo ] = useState(Date.now());

	const setChartsData = () => {
		const filteredChartsData: Array<IStatisticChart> = [];
		data.map((dataItem) => {
			const filteredData = searchChartData<T>({ dateFrom, dateTo, data: dataItem.data });
			const filteredChartData: IStatisticChart = {
				data: chartData<T>({ data: filteredData, yId: dataItem.yId }),
				valueName: dataItem.name
			};
			filteredChartsData.push(filteredChartData);
			return null;
		});
		setCharts(filteredChartsData);
	};

	useEffect(() => {
		setChartsData();
	}, [ data, dateFrom, dateTo ]);

	useEffect(() => {
		setChartsData();
	}, []);

	const setPeriod = (p:number) => {
		onPeriodChange(p);
		setCurrentPeriod(p);
		return null;
	};

	const setDateFrom = (value: any) => {
		setCurrentDateFrom(value);
		setCurrentPeriod(0);
	};

	const setDateTo = (value: any) => {
		setCurrentDateTo(value);
		setCurrentPeriod(0);
	};

	const setExtDateFrom = (value: any) => {
		setCurrentDateFrom(value);
	};

	const setExtDateTo = (value: any) => {
		setCurrentDateTo(value);
	};

	const onPeriodChange = (period: number) => {
		const setValueWithOffset = (offset: number) => {
			setExtDateFrom(getDateWithOffset(offset));
			setExtDateTo(getDateWithOffset(0));
		};

		switch (period) {
			case 0: return null;
			case 1: return setValueWithOffset(365 * 10);
			case 2: return setValueWithOffset(1);
			case 3: return setValueWithOffset(7);
			case 4: return setValueWithOffset(31);
			case 5: return setValueWithOffset(3 * 31);
			case 6: return setValueWithOffset(365);
			default: return null;
		};
	};

	const Chart = (data: IStatisticChart) => {
		return <React.Fragment>
			<Title textAlign={"center"}>Chart for {data.valueName}</Title>
			<Box height={"294px"} border={"1px solid #5C7080"} m={"12px 42px 42px"}>
				<StatisticChart
					data={data.data}
					valueName={data.valueName}
				/>
			</Box>
		</React.Fragment>;
	};

	return (
		<>
			<StatisticChartsPeriod
				period={period}
				setPeriod={setPeriod}
				periodsNames={periodsNames}
				dateFrom={dateFrom}
				dateTo={dateTo}
				setDateFrom={setDateFrom}
				setDateTo={setDateTo}
			/>
			{charts.length && <SimpleTabs
				labels={[
					charts[0].valueName,
					charts[1].valueName,
					charts[2].valueName
				]}
			>
				{Chart(charts[0])}
				{Chart(charts[1])}
				{Chart(charts[2])}
			</SimpleTabs>}
		</>
	);
};
