import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Link, withRouter } from "react-router-dom";
import { Box } from "@mui/material";
import { useIsRtl } from "../../../utils/hooks/useIsRtl";

const SidebarLinkListItem = (props:any) => {
	const path = props.location.pathname;
	const active = path.includes("/app/" + props.to);
	const isRtl = useIsRtl();

	return (
		<Link
			to={"/app/" + props.to}
			className={"flexRow"}
			style={{ position: "relative" }}
		>
			<ListItemButton
				className={"flexRow"}
				sx={{
					height: "60px",
					padding: !isRtl ? "0px 16px 0px 52px" : "0px 52px 0px 16px",
					width: "100%",
					backgroundColor: active ? "#EAF3F7" : "transparent",
					"&:hover": {
						backgroundColor: "#EAF3F7",
						cursor: active ? "initial" : "pointer"
					},
					"& .MuiTypography-root": {
						color: active ? "#2988CA" : "#5C7080"
					},
					"&:hover .MuiTypography-root": {
						color: "#2988CA"
					},
					"&:hover .activeMarker": {
						opacity: 1
					}
				}}
			>
				<ListItemText primary={props.text} />
				<Box
					className="activeMarker"
					sx={{
						display: "block",
						opacity: active ? 1 : 0,
						width: "5px",
						backgroundColor: "#2988CA",
						position: "absolute",
						left: !isRtl ? 0 : "auto",
						right: !isRtl ? "auto" : 0,
						top: 0,
						bottom: 0,
						zIndex: 9
					}}
				>
				</Box>
			</ListItemButton>
		</Link>
	);
};

export default withRouter(SidebarLinkListItem);
