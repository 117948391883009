import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Box, Container } from "@mui/material";
import AppBarMyAccountMobile from "./dashboardComponents/AppBarMyAccountMobile";
import AppBarMobileBurgerButton from "./dashboardComponents/AppBarMobileBurgerButton";
import AppBarLang from "./dashboardComponents/AppBarLang";

const AppBar = styled(MuiAppBar)(() => ({
	background: "#2988CA",
	boxShadow: "none"
}));

export default function DashboardAppBarMobile ({ setAccordion, setOpen }: any) {
	return (
		<AppBar>
			<Container>
				<Toolbar sx={{ justifyContent: "space-between", padding: "0!important" }} className={"flexRow"}>
					<AppBarMobileBurgerButton setOpen={setOpen}/>
					<Box className={"flexRow"} sx={{ height: "100%" }}>
						<AppBarLang mobile={true} />
						<AppBarMyAccountMobile setAccordion={setAccordion}/>
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	);
};
