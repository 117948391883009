import React from "react";

const TwitterIcon = (props:any) => {
	let fill = "#3396E6";
	if (props.fill) {
		fill = props.fill;
	}

	return (
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" style={{ width: "100%" }} viewBox="57.5 0 48 48" xmlSpace="preserve">
			<g>
				<path fill={fill} d="M101.681,11.768c-0.66,0.292-1.339,0.54-2.034,0.74c0.821-0.929,1.449-2.023,1.831-3.22
                c0.087-0.268-0.002-0.561-0.225-0.737c-0.22-0.175-0.525-0.197-0.767-0.053c-1.475,0.874-3.065,1.502-4.733,1.87
                c-1.678-1.641-3.961-2.576-6.319-2.576c-4.978,0-9.027,4.05-9.027,9.029c0,0.392,0.024,0.782,0.073,1.166
                c-6.178-0.542-11.921-3.578-15.863-8.416c-0.141-0.172-0.359-0.266-0.581-0.248c-0.221,0.018-0.42,0.143-0.533,0.335
                c-0.798,1.372-1.223,2.943-1.223,4.54c0,2.175,0.776,4.239,2.149,5.853c-0.417-0.145-0.821-0.326-1.207-0.541
                c-0.207-0.114-0.461-0.112-0.667,0.006c-0.209,0.117-0.336,0.334-0.343,0.572c0,0.04,0,0.08,0,0.121
                c0,3.247,1.748,6.168,4.419,7.764c-0.229-0.023-0.459-0.057-0.687-0.099c-0.234-0.047-0.476,0.036-0.635,0.216
                c-0.158,0.178-0.212,0.427-0.139,0.654c0.99,3.09,3.536,5.359,6.615,6.053c-2.554,1.597-5.474,2.436-8.538,2.436
                c-0.641,0-1.284-0.037-1.913-0.111c-0.313-0.037-0.612,0.149-0.718,0.446c-0.106,0.298,0.005,0.628,0.271,0.798
                c3.938,2.523,8.491,3.86,13.167,3.86c9.192,0,14.942-4.336,18.146-7.97c3.998-4.535,6.292-10.537,6.292-16.466
                c0-0.248-0.005-0.498-0.014-0.748c1.577-1.188,2.937-2.625,4.039-4.278c0.169-0.25,0.15-0.582-0.044-0.813
                C102.279,11.719,101.957,11.645,101.681,11.768z"/>
			</g>
		</svg>
	);
};

export default TwitterIcon;
