import * as React from "react";
import { Option } from "../Form/Option";
import { useAppSelector } from "../../utils/hooks/reduxHooks";

// 0.01 - 0.09
const firstValuesStage = Array(9).fill(0.01).map((value, index) => value + (0.01 * index));

// 0.1 - 0.9
const secondValuesStage = Array(9).fill(0.1).map((value, index) => value + (0.1 * index));

// 1 - 9
const thirdValuesStage = Array(9).fill(1).map((value, index) => value + index);

// 10 - 95
const fourthValuesStage = Array(19).fill(10).map((value, index) => value + 5 * index);

// 100 - 1000
const fifthValuesStage = Array(10).fill(100).map((value, index) => value + 100 * index);

export function LotRangeOptions () {
	const langCopytrade = useAppSelector(state => state.language.languageCopytrade);
	return (
		<>
			{firstValuesStage.map((value) => <Option key={value} value={value}>{value.toFixed(2)} {langCopytrade.copytrade_ratio2}</Option>)}
			{secondValuesStage.map((value) => <Option key={value} value={value}>{value.toFixed(1)} {langCopytrade.copytrade_ratio2}</Option>)}
			{thirdValuesStage.map((value) => <Option key={value} value={value}>{value} {langCopytrade.copytrade_ratio2}</Option>)}
			{fourthValuesStage.map((value) => <Option key={value} value={value}>{value} {langCopytrade.copytrade_ratio2}</Option>)}
			{fifthValuesStage.map((value) => <Option key={value} value={value}>{value} {langCopytrade.copytrade_ratio2}</Option>)}
		</>
	);
}
