import React from "react";
import Title from "../../../components/Typography/Title";
import { IChildren } from "../../../types/common";

const SecondaryText = (props: {children: IChildren}) => {
	return (
		<Title fontSize={"14px"} mb={"15px"}>{ props.children }</Title>

	);
};

export default SecondaryText;
