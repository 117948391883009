import * as Yup from "yup";

type FasapayErrors = {
	account_number_02: string;
}

export const fasapayValidationSchema = (language: FasapayErrors) => Yup.object().shape({
	fasapayAccountNumber: Yup.string().required(language.account_number_02),
	currency: Yup.string().required()
});
