import createSagaMiddleware from "redux-saga";
import { rootWatcher } from "../saga/rootSaga";
import { createStore, compose, applyMiddleware } from "redux";
import rootReducer from "./reducers/rootReducer";

declare global {
	interface Window {
		Tawk_LoadStart?: Date;
		Tawk_API?: any;
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
	}
}
const saga = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer,
	composeEnhancers(
		compose(
			applyMiddleware(saga)
		)
	)
);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

saga.run(rootWatcher);

export { store };
