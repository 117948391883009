import * as React from "react";
import { DefaultWrapper, DefaultWrapperProps } from "./Default";
import { WrapperForAuth } from "./ForAuth";
import { WrapperWithTip } from "./WrapperWithTip";
import { WrapperForTableDate } from "./ForTableDate";
import { InlineBoldWrapper } from "./InlineBold";

export type FieldWrapperVariant = "default" | "for-auth" | "with-tip" | "for-table-date" | "inline-bold";

type FieldWrapperModuleI = {
	[key in FieldWrapperVariant]: React.FC<DefaultWrapperProps>
};

export const FieldWrapper: FieldWrapperModuleI = {
	default: DefaultWrapper,
	"for-auth": WrapperForAuth,
	"with-tip": WrapperWithTip,
	"for-table-date": WrapperForTableDate,
	"inline-bold": InlineBoldWrapper
};
