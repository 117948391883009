import * as React from "react";
import { Form } from "../../../../components/Form";
import { Input } from "../../../../components/Form/Input";
import { InputRepeater } from "../../../../components/Form/InputRepeater";
import { CurrenciesSymbols, CurrencyType } from "../../../../utils/currency";
import { getWithdrawalValue } from "../../../../utils/getReceivedValue";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { payTrustWithdrawValidationSchema } from "./validation";
import { FormBottomWrapper } from "../../../../components/Form/FormBottomWrapper";
import { ButtonWithLoader } from "../../../../components/ButtonWithLoader";
import { Alert, Box, Skeleton, Snackbar } from "@mui/material";
import { WaningNote } from "../../../../components/UI/Note";
import API from "../../../../utils/API";
import { AxiosResponse } from "axios";
import { useErrorHandling } from "../../../../utils/hooks/useErrorHandling";
import { fetchIdrPayTrustInfo } from "../../../../store/actions/actionTypes";

export function PayTrust () {
	const [ isFetch, setIsFetch ] = React.useState(false);
	const [ success, setSuccess ] = React.useState(false);
	const language = useAppSelector((state) => state.language);
	const accountNumber = useAppSelector((state) => `${state.session.userData.account_number ?? ""}`);
	const currency = useAppSelector((state) => state.session.userData.currency ?? "USD");
	const {
		fee,
		addon,
		loading: infoLoading,
		feeDetails,
		complete
	} = useAppSelector((state) => state.withdrawMethodsInfo.idrPayTrust);
	const dispatch = useAppDispatch();
	const { isOpen, errorMsg, hideError, showError } = useErrorHandling();

	React.useEffect(() => {
		if (!complete) {
			dispatch(fetchIdrPayTrustInfo({}));
		}
	}, []);

	function getWithdrawalValueCB (values: [string, CurrencyType]) {
		const [ amount = "0", currencyType ] = values;
		const totalAmount = +amount + +amount * fee + addon;

		return getWithdrawalValue(
			[
				totalAmount.toString(),
				currencyType
			],
			currency,
			3
		);
	}

	function calcFee (values: [string, CurrencyType]) {
		const [ amount = "0", currencyType ] = values;

		return `${(+amount * fee).toFixed(3)} ${CurrenciesSymbols[currencyType] || CurrenciesSymbols[currency]}`;
	}

	async function submit (values: {
		amount: string,
		bankAccountNumber: string,
		bankAccountName: string,
		bankName: string,
		branch: string,
		province: string,
		city: string
	}) {
		setIsFetch(true);
		try {
			setIsFetch(true);
			const formData = new FormData();
			formData.append("amount_withdraw", values.amount);
			formData.append("idr_account", values.bankAccountNumber);
			formData.append("bank_account_name", values.bankAccountName);
			formData.append("bank_name", values.bankName);
			formData.append("bank_branch", values.branch);
			formData.append("bank_province", values.province);
			formData.append("bank_city", values.city);

			const { data } = await API.post<unknown, AxiosResponse<{ success: boolean, errorMsg: string }>>("withdrawPaytrustIndonesia", formData);
			if (data.success) {
				setSuccess(true);
			} else {
				showError(data.errorMsg ?? "Something went wrong");
			}
		} catch (e) {
			// handle error
		} finally {
			setIsFetch(false);
		}
	}

	return (
		<>
			<Form
				onSubmit={submit}
				validationSchema={payTrustWithdrawValidationSchema(language.languageFinance)}
				defaultValues={{
					accountNumber,
					amount: "",
					bankAccountName: "",
					bankName: "",
					bankAccountNumber: "",
					branch: "",
					province: "",
					city: ""
				}}
			>
				<Input
					name="accountNumber"
					label={language.languageCopytrade.cpy_mon_accnum}
					variant="rounded"
				/>
				<Input
					name="amount"
					label={language.languageFinance.trd_230}
					variant="rounded"
				/>
				<InputRepeater
					fields="amount,currency"
					label={language.languageFinance["with-p-04"]}
					onChange={calcFee}
				/>
				<Input
					name="bankAccountName"
					label={language.languageFinance.with_04}
					variant="rounded"
				/>
				<Input
					name="bankName"
					label={language.languageFinance.with_03}
					variant="rounded"
				/>
				<Input
					name="bankAccountNumber"
					label={language.languageFinance.wunip_06}
					variant="rounded"
				/>
				<Input
					name="branch"
					label={language.languageFinance.with_02}
					variant="rounded"
				/>
				<Input
					name="province"
					label={language.languageFinance.with_06}
					variant="rounded"
				/>
				<Input
					name="city"
					label={language.languageFinance.with_05}
					variant="rounded"
				/>
				<FormBottomWrapper
					forFields={[ "amount", "currency" ]}
					onChange={getWithdrawalValueCB}
					label={language.languageMyAccounts.form_message_01}
				>
					<ButtonWithLoader
						isFetch={isFetch}
						color={"primary"}
						text={language.languageMyAccounts.chapas_04}
					/>
				</FormBottomWrapper>
				<Box sx={{ marginTop: "6px" }}>
					{
						infoLoading
							? (
								<Skeleton width={"220px"} height={"26px"} />
							)
							: (
								<WaningNote
									message={`Fee is ${feeDetails}`}
								/>
							)
					}
				</Box>
			</Form>
			<Snackbar open={isOpen} autoHideDuration={4000} onClose={hideError}>
				<Alert variant="filled" color="error">
					{errorMsg}
				</Alert>
			</Snackbar>
			<Snackbar
				open={success}
				autoHideDuration={4000}
				onClose={() => setSuccess(false)}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
			>
				<Alert variant="filled" color="success">
					Success
				</Alert>
			</Snackbar>
		</>
	);
}
