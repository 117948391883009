import * as React from "react";
import { Grid } from "@mui/material";
import { CleanButton } from "../../../../components/UI/CleanButton";
import { ReactComponent as GearIcon } from "../../../../images/GearIcon.svg";
import { ReactComponent as MagnifierIcon } from "../../../../images/MagnifierIcon.svg";
import { ReactComponent as PaperIcon } from "../../../../images/PaperIcon.svg";
import { SettingsModal } from "./SettignsModal";
import { IFMTableComponentProps } from "../../../../components/FMTable/FMTableCellComponent";
import { Trader } from "../../../../interfaces/CopytradeInterfaces";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { getCommissionSettings, showSettings, showTradesHistory } from "../../../../store/actions/actionTypes";

export function ActivityCell (props: IFMTableComponentProps<Trader, {}>) {
	const { toggleCollapsible, ConnectionId } = props;
	const commissionSettings = useAppSelector((state) => state.copytrade.mySubscriptions.traders.data?.find((trader) => trader.ConnectionId === ConnectionId)?.commissionSettings);
	const [ isOpen, setIsOpen ] = React.useState(false);
	const dispatch = useAppDispatch();

	const showSettingsHandler = React.useCallback(() => {
		dispatch(showSettings({}));
		toggleCollapsible && toggleCollapsible();
	}, [ toggleCollapsible ]);

	const showTradesHistoryHandler = React.useCallback(() => {
		dispatch(showTradesHistory({}));
		toggleCollapsible && toggleCollapsible();
	}, [ toggleCollapsible ]);

	const showCommissionSettings = React.useCallback(() => {
		if (!commissionSettings) {
			dispatch(getCommissionSettings({ connectionId: ConnectionId }));
		}
		setIsOpen(true);
	}, [ setIsOpen, ConnectionId, commissionSettings ]);

	return (
		<>
			<Grid
				container
				flexDirection="row"
				alignItems="center"
				justifyContent="center"
			>
				<Grid item>
					<CleanButton onClick={showSettingsHandler}>
						<GearIcon />
					</CleanButton>
				</Grid>
				<Grid item>
					<CleanButton onClick={showCommissionSettings}>
						<MagnifierIcon />
					</CleanButton>
				</Grid>
				<Grid item>
					<CleanButton>
						<PaperIcon onClick={showTradesHistoryHandler}/>
					</CleanButton>
				</Grid>
			</Grid>
			<SettingsModal isOpen={isOpen} onClose={setIsOpen} connectionId={ConnectionId} />
		</>
	);
}
