import * as React from "react";
import Typography from "@mui/material/Typography";

export interface FMTableTitleProps {
	title: string;
}

export default function FMTableTitle (props:FMTableTitleProps) {
	const { title } = props;
	return <Typography
		component="h3"
		className="flexRow"
		sx={{
			width: "100%",
			fontSize: "24px",
			lineHeight: "24px",
			fontWeight: 100,
			alignItems: "flex-start",
			paddingBottom: "12px",
			marginBottom: "24px",
			borderBottom: "1px solid rgba(92, 112, 128, 0.2)"
		}}
	>
		{title}
	</Typography>;
};
