/*
    Created on : 22.11.2023, 06:18:59
    Author     : Andy Kar
*/

import {
	SET_MY_ACHIEVEMENTS_INFO
} from "../actions/actionTypes";
import { IMyAchievementsInfo } from "../../saga/myAchievementsSaga";

const initial = {
	status: false,
	info: {} as IMyAchievementsInfo
};

export default function myAchievementsReducer (state = initial, action:{type:string, payload:any}) {
	switch (action.type) {
		case SET_MY_ACHIEVEMENTS_INFO:
			return {
				...state,
				info: action.payload,
				status: action.payload.status
			};

		default:
			return state;
	}
}
