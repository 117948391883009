/*
    Created on : 12.11.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import UserIcon from "../../../../components/Icons/UserIcon";
import { Box } from "@mui/material";
import { useIsRtl } from "../../../../utils/hooks/useIsRtl";
import { IFMTableComponentProps } from "../../../../components/FMTable/FMTableCellComponent";
import { TradersItemInterface } from "../../../../interfaces/StoreInterfaces";

export default function TraderAccountCell (props: IFMTableComponentProps<TradersItemInterface, {}>) {
	const isRtl = useIsRtl();
	const avatarStyle = {
		backgroundImage: props.Avatar && `url(${props.Avatar})`,
		width: "50px",
		height: "50px",
		display: "block",
		backgroundPosition: "center center",
		backgroundSize: "cover",
		marginRight: isRtl ? 0 : "7px",
		marginLeft: isRtl ? "7px" : 0,
		borderRadius: "50%",
		overflow: "hidden"
	};

	return (
		<Box
			className="flexRow"
			sx={{
				alignItems: "center",
				justifyContent: "flex-start",
				padding: "10px 0"
			}}
		>
			{props.Avatar
				? <Box sx={avatarStyle}></Box>
				: <UserIcon
					width={50}
					sx={{
						marginRight: isRtl ? 0 : "7px",
						marginLeft: isRtl ? "7px" : 0
					}}
				/>}
			<Box>
				<Box sx={{ fontSize: "18px", textAlign: "start" }}> {props.UserId}</Box>
				<Box>ForexMart{props.AccountType}</Box>
			</Box>
		</Box>
	);
};
