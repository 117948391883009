import React from "react";

const LogOutIcon = (props: any) => {
	let width = 16;
	if (props.width) {
		width = props.width;
	}

	return (
		<svg width={width} height={width} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 16">
			<defs>
				<clipPath id="log-out-clip-path">
					<rect width={width} height={width} transform="translate(27 18)" fill="#e4e4e4"/>
				</clipPath>
			</defs>
			<g transform="translate(-27 -18)" clipPath="url(#log-out-clip-path)">
				<g id="icon-profile-logout" transform="translate(22.792 14.417)">
					<path d="M6.049,17.144V6.02a.6.6,0,0,1,.6-.6H9.083V3.584H6.644A2.439,2.439,0,0,0,4.208,6.02V17.144a2.44,2.44,0,0,0,2.436,2.439H9.083V17.741H6.644A.6.6,0,0,1,6.049,17.144Z" />
					<path d="M19.965,10.958h-.052V10.9l-3.66-3.659-1.3,1.3,2.111,2.113H8.252V12.5h8.809L14.95,14.615l1.3,1.3,3.686-3.686A.922.922,0,0,0,19.965,10.958Z" />
				</g>
			</g>
		</svg>
	);
};

export default LogOutIcon;
