/*
    Created on : 17.02.2022, 06:18:59
    Author     : Andy Kar
*/

import React from "react";
import "./AccountsVerificationCell.scss";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../interfaces/StoreInterfaces";
import { Link } from "react-router-dom";
import { CellText } from "../../../pages/MyAccounts/CellText/CellText";

function AccountsVerificationCell (props:any) {
	const langMyAccount:any = useSelector((state:StoreInterface) => state.language.languageMyAccounts);

	const verifiedText = () => {
		if (props?.accountstatus === "3" || props?.accountstatus === "1") {
			return langMyAccount.verified_text;
		} else {
			const messageArray = langMyAccount.non_verified_text.split("<a>");
			const message = messageArray[0];
			const messageA = messageArray[1].replace("</a>", "");
			return (
				<>
					{message}
					<Link to="/app/profile/upload-document">{messageA}</Link>
				</>
			);
		}
	};

	return (
		<div className="accounts-verification-cell">
			<CellText textAlign='left'>{verifiedText()}</CellText>
		</div>
	);
}

export default AccountsVerificationCell;
