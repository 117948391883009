/* eslint-disable camelcase */
import * as React from "react";
import { Form } from "../../Form";
import { Input } from "../../Form/Input";
import { getWithdrawalValue } from "../../../utils/getReceivedValue";
import { useAppSelector } from "../../../utils/hooks/reduxHooks";
import { mobileMoneyValidationSchema } from "./validation";
import { FormBottomWrapper } from "../../Form/FormBottomWrapper";
import { ButtonWithLoader } from "../../ButtonWithLoader";
import { Alert, Box, Snackbar } from "@mui/material";
import { WaningNote } from "../../UI/Note";
import { useErrorHandling } from "../../../utils/hooks/useErrorHandling";
import { InputRepeater } from "../../Form/InputRepeater";
import { MobileMoneyFormError } from "../../../utils/errors/MobileMoneyFormError";
import { FinanceFormProps } from "../types";
import { CurrencyType } from "../../../utils/currency";

export type TMobileMoneyFormSubmitValues = {
	amount: string,
	phone: string,
	firstName: string,
	lastName: string,
	address: string,
	city: string,
	zip: string
};
export interface MobileMoneyFormProps extends FinanceFormProps<TMobileMoneyFormSubmitValues> {
	currency?: CurrencyType;
}
export function DepositMobileMoneyForm (props: MobileMoneyFormProps) {
	const {
		submit: _submit,
		calcInCurrency,
		currency = "USD"
	} = props;
	const [ isFetch, setIsFetch ] = React.useState(false);
	const language = useAppSelector((state) => state.language);
	const accountNumber = useAppSelector((state) => `${state.session.userData.account_number ?? ""}`);
	const phone = useAppSelector((state) => state.user.user.phone1 ?? "");
	const [ firstName, lastName ] = useAppSelector((state) => {
		const fullNameArray = state.user.user.full_name?.split(" ");
		if (!fullNameArray) return [ state.user.user.full_name ?? "", "" ];

		return fullNameArray;
	});
	const { street = "", city = "", zip = "" } = useAppSelector((state) => state.user.user);
	const { isOpen, errorMsg, hideError, showError } = useErrorHandling();

	const getWithdrawalValueCB = React.useCallback((values: any) => getWithdrawalValue(values, currency), [ currency ]);

	async function submit (values: TMobileMoneyFormSubmitValues) {
		try {
			setIsFetch(true);

			await _submit(values);
		} catch (e) {
			if (e instanceof MobileMoneyFormError) {
				showError(e.message);
			} else {
				showError("Something went wrong");
			}
		} finally {
			setIsFetch(false);
		}
	}

	function calcInZMW (values: [string]) {
		const [ amount ] = values;
		if (calcInCurrency) {
			return calcInCurrency(parseInt(amount));
		}

		return "";
	}

	return (
		<Box sx={{ paddingTop: "20px" }}>

			<Form
				onSubmit={submit}
				validationSchema={mobileMoneyValidationSchema()}
				defaultValues={{
					accountNumber,
					amount: "",
					phone,
					firstName,
					lastName,
					address: street,
					city,
					zip
				}}
			>
				<Input
					name="accountNumber"
					label={language.languageCopytrade.cpy_mon_accnum}
					variant="rounded"
				/>
				<Input
					name="amount"
					label={language.languageFinance.trd_230}
					variant="rounded"
				/>
				{
					calcInCurrency
						? (
							<InputRepeater
								fields="amount"
								label={""}
								onChange={calcInZMW}
							/>
						)
						: (
							<></>
						)
				}
				<Input
					name="firstName"
					label={language.languageMyAccounts.fn_01}
					variant="rounded"
				/>
				<Input
					name="lastName"
					label={language.languageMyAccounts.ln_01}
					variant="rounded"
				/>
				<Input
					name="phone"
					label={language.languageFinance.int_reg_45}
					variant="rounded"
				/>
				<Input
					name="address"
					label={language.languageMyAccounts.perdet_03}
					variant="rounded"
				/>
				<Input
					name="city"
					label={"City"}
					variant="rounded"
				/>
				<Input
					name="zip"
					label={language.languageMyAccounts.perdet_06}
					variant="rounded"
				/>
				<FormBottomWrapper
					forFields={[ "amount", "currency" ]}
					onChange={getWithdrawalValueCB}
					label={language.languageMyAccounts.form_message_01}
				>
					<ButtonWithLoader
						isFetch={isFetch}
						color={"primary"}
						text={language.languageMyAccounts.chapas_04}
					/>
				</FormBottomWrapper>
				<Box sx={{ marginTop: "6px" }}>
					<WaningNote
						message="Deposit is processed within 24 hours."
					/>
					<WaningNote
						message="Attention! The conversion amount in the account currency is not final and may change! After the balance is credited, the account balance may be adjusted based on the actual exchange rate of the payment provider."
					/>
				</Box>
				<Snackbar open={isOpen} autoHideDuration={4000} onClose={hideError}>
					<Alert variant="filled" color="error">
						{errorMsg}
					</Alert>
				</Snackbar>
			</Form>
		</Box>

	);
}
