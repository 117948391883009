/*
    Created on : 20.01.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import "./PageCompanyDetails.scss";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../interfaces/StoreInterfaces";
import PageTitle from "../../../components/Dashboard/dashboardComponents/PageTitle";

export default function PageCompanyDetails () {
	const langMyAccounts:any = useSelector((state:StoreInterface) => state.language.languageMyAccounts);

	return (
		<div className="page-company-details">
			<PageTitle pageTitle={langMyAccounts.cmp_dtls_01}/>
		</div>
	);
};
