import React from "react";

const AuthFeedbackIcon = (props:any) => {
	let width = 18;
	let fill = "#fff";
	if (props.width) {
		width = props.width;
	}
	if (props.fill) {
		fill = props.fill;
	}

	return (
		<svg width={width} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M13.4992 1.89604C17.8032 4.3809 19.2778 9.8843 16.7929 14.1882C15.8785 15.772 14.5554 16.9726 13.0337 17.7339L12.0191 20.9573L9.30991 18.6833C7.68091 18.741 6.01645 18.357 4.50075 17.4819C0.196836 14.997 -1.27779 9.49363 1.20707 5.18972C3.69194 0.885801 9.19533 -0.588829 13.4992 1.89604ZM12.309 10.169C12.1768 10.0143 12.1106 9.8597 12.1106 9.66089C12.1106 9.46209 12.1768 9.30746 12.309 9.15284C12.4632 9.0203 12.6175 8.95404 12.8159 8.95404C12.9922 8.95404 13.1685 9.0203 13.3007 9.15284C13.4329 9.30746 13.499 9.46209 13.499 9.66089C13.499 9.8597 13.4329 10.0364 13.3007 10.169C13.1685 10.3015 13.0142 10.3678 12.8159 10.3678C12.6175 10.3678 12.4412 10.3015 12.309 10.169ZM9.46583 10.2572C9.62009 10.1025 9.70825 9.90374 9.70825 9.66076C9.70825 9.41778 9.62009 9.21897 9.46583 9.06435C9.31156 8.90972 9.11322 8.82136 8.8708 8.82136C8.62838 8.82136 8.43004 8.90972 8.27577 9.06435C8.1215 9.21897 8.03335 9.41778 8.03335 9.66076C8.03335 9.90374 8.1215 10.1025 8.27577 10.2572C8.43004 10.4118 8.62838 10.5002 8.8708 10.5002C9.11322 10.5002 9.31156 10.4339 9.46583 10.2572ZM5.6534 8.97656C5.85175 9.15327 5.9399 9.39625 5.9399 9.66133C5.9399 9.9264 5.82971 10.1694 5.6534 10.3461C5.4771 10.5449 5.23468 10.6333 4.97022 10.6333C4.70577 10.6333 4.48539 10.5449 4.28704 10.3461C4.0887 10.1694 4.00055 9.9264 4.00055 9.66133C4.00055 9.39625 4.0887 9.17536 4.28704 8.97656C4.46335 8.77775 4.70577 8.6894 4.97022 8.6894C5.23468 8.6894 5.45506 8.77775 5.6534 8.97656Z"/>
		</svg>
	);
};

export default AuthFeedbackIcon;
