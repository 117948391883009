import * as React from "react";
import * as Yup from "yup";
import { styled } from "@mui/system";
import LoadingButton from "@mui/lab/LoadingButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
	Alert,
	Box,
	Chip,
	Grid,
	List,
	ListItem as ListItemMUI,
	Skeleton,
	Snackbar,
	Typography
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks/reduxHooks";
import PageTitle from "../../../components/Dashboard/dashboardComponents/PageTitle";
import { Form } from "../../../components/Form";
import { Input } from "../../../components/Form/Input";
import { SideWrapper } from "./SideWrapper";
import { useAdaptive } from "../../../utils/hooks/themeBreakpointsHooks";
import { BackButton } from "../../../components/BackButton";
import { getTwoFactorAuthInfo, setTwoFactorAuthInfo } from "../../../store/actions/actionTypes";
import API from "../../../utils/API";
import { TResponse } from "../../../utils/types";
import { useModalControls } from "../../../utils/hooks/useModalControl";

const ListItem = styled(ListItemMUI)({
	display: "list-item",
	paddingLeft: "4px",
	letterSpacing: 0,
	lineHeight: "24px",
	color: "#5C7080",
	listStyleType: "decimal"
});

export default function PageSecurityInfo () {
	const langMyAccounts = useAppSelector((state) => state.language.languageMyAccounts);
	const { secret, QRCode, loading } = useAppSelector((state) => state.auth.tfa);
	const dispatch = useAppDispatch();
	const [ isOpen, setIsOpen ] = React.useState(false);
	const [ isFetch, setIsFetch ] = React.useState(false);
	const success = useModalControls();

	const adaptiveContainer = useAdaptive(adaptiveContainerStyles);
	const adaptiveItem = useAdaptive(adaptiveItemStyles);
	const adaptiveTitle = useAdaptive(adaptiveTitleStyles);

	React.useEffect(() => {
		if (!secret && !QRCode) {
			dispatch(getTwoFactorAuthInfo({}));
		}
	}, [ secret, QRCode ]);

	async function copyToClipboard () {
		try {
			await navigator.clipboard.writeText(secret);
			setIsOpen(true);
		} catch (e) {
			console.log(e);
		}
	}

	async function submit (values: { code: string }) {
		setIsFetch(true);
		try {
			const formData = new FormData();
			formData.append("tfaCode", values.code);
			const response = await API.post<TResponse<null>>("/enableTwoFactorAuth", formData);
			if (response.data.success) {
				dispatch(setTwoFactorAuthInfo({
					secret: "",
					QRCode: ""
				}));
				success.openModal();
			}
		} catch (err) {
			//
		} finally {
			setIsFetch(false);
		}
	}

	async function disableTFA () {
		setIsFetch(true);
		try {
			const response = await API.post<TResponse<null>>("/disableTwoFactorAuth");
			if (response.data.success) {
				dispatch(getTwoFactorAuthInfo({}));
				success.openModal();
			}
		} catch (err) {
			//
		} finally {
			setIsFetch(false);
		}
	}

	return (
		<>
			<PageTitle pageTitle={langMyAccounts.mfn_08}/>
			<BackButton />
			<Box
				sx={{
					padding: "20px",
					borderTop: "1px solid #29A643",
					borderBottom: "1px solid #29A643",
					...adaptiveTitle
				}}
			>
				<Typography
					fontWeight="bold"
					color="#5C7080"
					sx={{
						letterSpacing: 0,
						fontSize: "18px"
					}}
				>
					Turn on Two-Step Verification
				</Typography>
			</Box>
			<Grid
				container
				sx={adaptiveContainer}
			>
				<Grid
					item
					sx={{
						...adaptiveItem
					}}
				>
					<SideWrapper label="Install the Google Authenticator app for iPhone.">
						<List
							component="ol"
							disablePadding
							sx={{
								listStyle: "inside"
							}}
						>
							<ListItem>On your iphone, tap the app store icon.</ListItem>
							<ListItem>Search for Google Authenticator.</ListItem>
							<ListItem>Tap the app and then tap free to download and install it.</ListItem>
						</List>
					</SideWrapper>
				</Grid>
				<Grid
					item
					sx={{
						...adaptiveItem,
						marginRight: "0"
					}}
				>
					<SideWrapper label="Now open and configure Google Authenticator.">
						<List
							component="ol"
							disablePadding
							sx={{
								listStyle: "inside"
							}}
						>
							<ListItem>In Google Authenticator, tap "+", and then "Scan Barcode"</ListItem>
							<ListItem>Use your phone's camera to scan this barcode.</ListItem>
						</List>

						<Box sx={{
							display: "flex",
							paddingLeft: "8px",
							paddingBottom: "4px",
							alignItems: "center",
							justifyContent: "space-between"
						}}>
							{
								loading || (!QRCode && !secret)
									? (
										<>
											<Skeleton
												variant="rectangular"
												width={120}
												height={120}
											/>
											OR
											<Skeleton
												variant="rectangular"
												width={180}
												height={32}
											/>
										</>
									)
									: (
										<>
											<img
												src={QRCode}
												width={120}
												height={120}
											/>
											OR
											<Chip
												label={secret}
												icon={<ContentCopyIcon sx={{ width: "16px" }} />}
												variant="outlined"
												sx={{
													paddingLeft: "10px"
												}}
												onClick={copyToClipboard}
											/>
										</>
									)
							}
						</Box>

						<Typography
							textAlign="left"
							sx={{
								letterSpacing: 0,
								lineHeight: "24px",
								color: "#5C7080",
								marginBottom: "20px"
							}}
						>
							Once you have scanned the barcode, enter the 6-digits verification code generated by the Authenticator app.
						</Typography>
						{
							(!loading && !QRCode && !secret)
								? (
									<LoadingButton
										fullWidth
										variant="contained"
										color="success"
										loading={isFetch}
										type="button"
										onClick={disableTFA}
										sx={{
											letterSpacing: 0,
											textTransform: "none",
											height: "45px",
											marginTop: "15px"
										}}
									>
										Disable TFA
									</LoadingButton>
								)
								: (
									<Form
										defaultValues={{ code: "" }}
										validationSchema={Yup.object().shape({})}
										onSubmit={submit}
										fieldWrapperVariant="with-tip"
									>
										<Input label="" name="code" variant="rounded" placeholder="Code" />
										<LoadingButton
											fullWidth
											variant="contained"
											color="success"
											loading={isFetch}
											disabled={loading}
											sx={{
												letterSpacing: 0,
												textTransform: "none",
												height: "45px",
												marginTop: "15px"
											}}
											type="submit"
										>
											Verify and Save
										</LoadingButton>
									</Form>
								)
						}
					</SideWrapper>
				</Grid>
			</Grid>
			<Snackbar open={isOpen} autoHideDuration={4000} onClose={() => setIsOpen(false)} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
				<Alert variant="filled" color="info">
					Copied!
				</Alert>
			</Snackbar>
			<Snackbar open={success.isOpen} autoHideDuration={4000} onClose={success.closeModal} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
				<Alert variant="filled" color="success">
					Success!
				</Alert>
			</Snackbar>
		</>
	);
};

const adaptiveContainerStyles = {
	xs: {
		flexWrap: "wrap",
		flexDirection: "column",
		alignItems: "center"
	},
	sm: {
		flexDirection: "row",
		alignItems: "flex-start",
		flexWrap: "nowrap"
	}
};

const adaptiveItemStyles = {
	xs: {
		marginRight: "0",
		marginBottom: "20px",
		width: "100%"
	},
	sm: {
		width: "50%",
		marginRight: "27px"
	}
};

const adaptiveTitleStyles = {
	xs: {
		marginBottom: "20px"
	},
	sm: {
		marginBottom: "30px"
	}
};
