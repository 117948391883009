/* eslint-disable camelcase */
import * as React from "react";
import {
	ComposedChart,
	XAxis,
	YAxis,
	Tooltip,
	Legend,
	ResponsiveContainer,
	Line,
	Bar,
	CartesianGrid,
	LineChart
} from "recharts";
import { Payload } from "recharts/types/component/DefaultLegendContent";
import { DataKey } from "recharts/types/util/types";
import { SimpleTabs } from "../../../../components/UI/Tabs";
import { useAppSelector } from "../../../../utils/hooks/reduxHooks";

export function MyProjectChart () {
	const { languageTrading, languageCopytrade } = useAppSelector((state) => state.language);

	return (
		<SimpleTabs
			labels={[
				languageTrading.trd_158,
				languageCopytrade.copytrade_48
			]}
		>
			<BalanceAndEquityChart />
			<ProfitChart />
		</SimpleTabs>
	);
}

interface IChartData {
	balance: number;
	equity: number;
	margin: number;
	date: string;
}

function BalanceAndEquityChart () {
	const {
		data_margin,
		data_equity,
		data_balance
	} = useAppSelector((state) => state.copytrade.myProject.balance_history || {});
	const { languageCopytrade } = useAppSelector((state) => state.language);
	const [ balanceIsHide, setBalanceIsHide ] = React.useState(false);
	const [ equityIsHide, setEquityIsHide ] = React.useState(false);
	const [ marginIsHide, setMarginIsHide ] = React.useState(false);

	const chartData: IChartData[] = React.useMemo(() => {
		const result: IChartData[] = [];
		if (data_margin && data_equity && data_balance) {
			const size = data_margin.length;

			for (let i = 0; i < size; i += 1) {
				result.push({
					date: new Date(data_balance[i][0]).toLocaleDateString(),
					balance: data_balance[i][1],
					equity: data_equity[i][1],
					margin: data_margin[i][1]
				});
			}
		}

		return result;
	}, [ data_margin, data_equity, data_balance ]);

	const { min, max } = React.useMemo(() => {
		if (data_balance && data_equity) {
			const y_data_equity = data_equity.map((point) => point[1]);
			const y_data_balance = data_balance.map((point) => point[1]);

			return {
				min: Math.min(...y_data_balance, ...y_data_equity),
				max: Math.max(...y_data_balance, ...y_data_equity)
			};
		}
		return { min: 0, max: 0 };
	}, [ data_equity, data_balance ]);

	function toggleChartPart (e: Payload & {
		dataKey?: DataKey<keyof IChartData>;
	}) {
		const { dataKey } = e;

		switch (dataKey) {
			case "margin":
				return setMarginIsHide((prevMarginIsHide) => !prevMarginIsHide);
			case "balance":
				return setBalanceIsHide((prevBalanceIsHide) => !prevBalanceIsHide);
			case "equity":
				return setEquityIsHide((prevEquityIsHide) => !prevEquityIsHide);
		}
	}

	return (
		<ResponsiveContainer width="100%" height={400}>
			<ComposedChart data={chartData}>
				<XAxis dataKey="date" />
				<YAxis domain={[ min * 0.99, max * 1.01 ]} allowDataOverflow yAxisId="left" />
				<YAxis allowDataOverflow yAxisId="right" orientation="right"/>
				<Tooltip />
				<Legend onClick={toggleChartPart} />
				<CartesianGrid stroke="#f5f5f5" />
				<Line yAxisId="left" dot={false} type="monotone" dataKey="balance" stroke="#29a643" hide={balanceIsHide} name={languageCopytrade.cpy_bal} />
				<Line yAxisId="left" dot={false} type="monotone" dataKey="equity" stroke="#ff7300" hide={equityIsHide} name={languageCopytrade.cpy_eqty} />
				<Bar dataKey="margin" barSize={2} fill="#413ea0" yAxisId="right" hide={marginIsHide} name={languageCopytrade.cpy_mgn} />
			</ComposedChart>
		</ResponsiveContainer>
	);
}

function ProfitChart () {
	const { languageCopytrade } = useAppSelector((state) => state.language);
	const profitHistory = useAppSelector((state) => state.copytrade.myProject.profit_history?.map(([ date, profit ]) => ({ date: new Date(date).toLocaleDateString(), profit })) || []);
	return (
		<ResponsiveContainer width="100%" height={400}>
			<LineChart data={profitHistory}>
				<XAxis dataKey="date" />
				<YAxis />
				<Tooltip />
				<Legend />
				<CartesianGrid stroke="#f5f5f5" />
				<Line dot={false} type="monotone" dataKey="profit" stroke="#29a643" name={languageCopytrade.copytrade_48} />
			</LineChart>
		</ResponsiveContainer>
	);
}
