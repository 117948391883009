import * as React from "react";

export type TData = { [key: string]: any | undefined };
export type TStorage = {
	data: TData;
	changeData: (newData: TData) => void;
}

export const StorageContext = React.createContext<TStorage>({
	data: {},
	changeData: () => {}
});

export interface IStorageProps {
	children: JSX.Element | JSX.Element[];
}

export function useStorage () {
	const { data, changeData } = React.useContext(StorageContext);

	return {
		data,
		changeData
	};
}

export function useInputStorage<T> (key: string, initValue: T) {
	const { data, changeData } = useStorage();
	const [ value, setValue ] = React.useState("");

	React.useEffect(() => {
		setValue(`${initValue}`);
	}, [ initValue ]);

	function handleChange (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
		const newValue = e.target.value;
		setValue(newValue);

		changeData({
			...data,
			[key]: newValue
		});
	};

	return {
		value,
		handleChange
	};
}

export const Storage: React.FC<IStorageProps> = (props) => {
	const { children } = props;
	const [ data, setData ] = React.useState<TData>({});

	return (
		<StorageContext.Provider value={{ data, changeData: setData }}>
			{children}
		</StorageContext.Provider>
	);
};

export {};
