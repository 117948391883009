import React from "react";

const DownloadPlatformIcon = (props: any) => {
	let width = 16;
	let fill = "#417cbf";
	if (props.width) {
		width = props.width;
	}
	if (props.fill) {
		fill = props.fill;
	}

	return (
		<svg width={width} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 16">
			<defs>
				<clipPath id="clip-path">
					<rect width={width} height={width} transform="translate(35 595)" fill="#fff" stroke="#707070" strokeWidth="1"/>
				</clipPath>
			</defs>
			<g transform="translate(-35 -595)" clipPath="url(#clip-path)">
				<g id="icon-fm-platform" transform="translate(33.557 593.876)">
					<path d="M2.42,4.046H14.294V5.9h.994V3.675a.633.633,0,0,0-.623-.623H2.066a.633.633,0,0,0-.623.623v8.539a.622.622,0,0,0,.623.623H7.136v1.129H6.024a.3.3,0,0,0-.3.3v.589a.3.3,0,0,0,.3.3H10.74a.3.3,0,0,0,.3-.3v-.589a.3.3,0,0,0-.3-.3H9.612V12.837h2.324V10.665H2.42ZM8.365,11.3a.438.438,0,1,1-.438.438A.446.446,0,0,1,8.365,11.3Z" fill={fill}/>
					<path d="M16.77,6.606H13.3a.661.661,0,0,0-.657.674v7.242a.672.672,0,0,0,.657.674h3.47a.676.676,0,0,0,.674-.674V7.262A.661.661,0,0,0,16.77,6.606Zm-2.291.488h1.095a.152.152,0,1,1,0,.3H14.479a.152.152,0,0,1,0-.3Zm.556,7.6a.438.438,0,1,1,.438-.438A.446.446,0,0,1,15.035,14.69Zm1.684-1.364H13.351V7.886h3.385v5.44h-.017Z" fill={fill}/>
				</g>
			</g>
		</svg>
	);
};

export default DownloadPlatformIcon;
