import React from "react";

const RuFlag = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
			<g fillRule="evenodd" strokeWidth="1pt">
				<path fill="#fff" d="M0 0h640v480H0z"/>
				<path fill="#01017e" d="M0 160.003h640V480H0z"/>
				<path fill="#fe0101" d="M0 319.997h640V480H0z"/>
			</g>
		</svg>
	);
};

export default RuFlag;
