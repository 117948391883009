import * as React from "react";
import { Button } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

export default function AppBarMobileBurgerButton ({ setOpen }: any) {
	return (
		<Button
			sx={{
				color: "#2988CA",
				minWidth: "35px",
				height: "35px",
				background: "#fff",
				"&:hover": {
					color: "#2988CA",
					background: "#fff"
				}
			}}
			onClick={() => setOpen(true)}>
			<MenuIcon sx={{ marginTop: "2px" }}/>
		</Button>
	);
};
