import * as React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import DepositOptionIconCards from "../../Icons/depositOptionIcons/DepositOptionIconCards";
import DepositOptionIconMasterCard from "../../Icons/depositOptionIcons/DepositOptionIconMasterCard";
import DepositOptionIconNeteller from "../../Icons/depositOptionIcons/DepositOptionIconNeteller";
import DepositOptionIconSkrill from "../../Icons/depositOptionIcons/DepositOptionIconSkrill";
import DepositOptionIconVisa from "../../Icons/depositOptionIcons/DepositOptionIconVisa";
import DepositOptionIconPayCo from "../../Icons/depositOptionIcons/DepositOptionIconPayCo";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../interfaces/StoreInterfaces";

export default function FooterDepositOption () {
	const langTrading:any = useSelector((state:StoreInterface) => state.language.languageTrading);
	return (
		<Box
			sx={{
				flexWrap: "wrap",
				maxWidth: "100%",
				marginBottom: "20px",
				flex: "0 1 700px"
			}}
		>
			<Typography
				component="h3"
				className="flexRow"
				sx={{
					width: "100%",
					fontWeight: "bold",
					marginBottom: "13px"
				}}
			>
				{langTrading.trd_15}
			</Typography>
			<Box
				className={"flexRow"}
				sx={{
					justifyContent: "space-between",
					"& svg": {
						marginBottom: "15px"
					}
				}}
			>
				<DepositOptionIconCards/>
				<DepositOptionIconMasterCard/>
				<DepositOptionIconNeteller/>
				<DepositOptionIconSkrill/>
				<DepositOptionIconVisa/>
				<DepositOptionIconPayCo/>
			</Box>
		</Box>
	);
}
