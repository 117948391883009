/*
    Created on : 30.12.2021, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { Box, Button, Typography } from "@mui/material";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../../interfaces/StoreInterfaces";
import { useObjectAdaptive } from "../../../../utils/hooks/themeBreakpointsHooks";

export default function ClientAuthOpenRealAccount () {
	const lang:any = useSelector((state:StoreInterface) => state.language.language);
	const init:any = useSelector((state:StoreInterface) => state.init.init);
	const titleSx:any = useObjectAdaptive({ title1: title1Sx, title2: title2Sx });
	return (
		<Box sx={{
			textAlign: "left",
			padding: "73px 0 100px",
			maxWidth: "400px"
		}}>
			<Typography
				variant="body2"
				component="p"
				sx={titleSx.title1}
			>{lang.cs_07}</Typography>
			<Typography
				component="h3"
				sx={titleSx.title2}
			>{ReactHtmlParser(lang.cs_11)}</Typography>
			<Button
				variant="outlined"
				target="_blank" rel="noreferrer"
				href={init.links.link_register}
				sx={{
					height: "50px",
					paddingX: "49px",
					color: "white",
					border: "1px solid rgba(255,255,255,0.5)",
					cursor: "pointer",
					fontSize: "18px",
					fontWeight: "light",
					textTransform: "none",
					"&:hover": {
						color: "white",
						borderColor: "#1ECB40"
					}
				}}
			>
				{/* {lang.cs_10} */}
				Sign Up Now!
			</Button>
		</Box>
	);
};

const title1Sx = {
	mobile: {
		fontSize: "18px",
		lineHeight: "54px"
	},
	tablet: {
		fontSize: "24px",
		lineHeight: "60px"
	}
};

const title2Sx = {
	mobile: {
		fontSize: "50px",
		lineHeight: "54px",
		marginBottom: "42px"
	},
	tablet: {
		fontSize: "56px",
		lineHeight: "60px",
		marginBottom: "48px"
	}
};
