import { createTheme } from "@mui/material/styles";

export const mainTheme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 980,
			lg: 1200,
			xl: 1536,
			mobile: 0,
			tablet: 740,
			laptop: 1024,
			desktop: 1366
		}
	},
	palette: {
		secondary: {
			main: "#5C7080",
			contrastText: "#FFFFFF"

		},
		default: {
			main: "#5C7080",
			light: "#5C7080",
			dark: "#5C7080",
			contrastText: "#FFFFFF"
		},
		primary: {
			main: "#2988CA",
			light: "#2988CA",
			dark: "#2988CA",
			contrastText: "#fff"
		},
		"primary-dark": {
			main: "#01487A",
			light: "#01487A",
			dark: "#01487A",
			contrastText: "#FFFFFF"
		},
		success: {
			main: "#29A643",
			light: "#29A643",
			dark: "#29A643",
			contrastText: "#FFFFFF"
		},
		error: {
			main: "#ED1F24",
			light: "#ED1F24",
			dark: "#ED1F24",
			contrastText: "#FFFFFF"
		},
		warning: {
			main: "#F7931A",
			light: "#F7931A",
			dark: "#F7931A",
			contrastText: "#FFFFFF"
		}
	},
	components: {
		MuiInput: {
			styleOverrides: {
				input: ({ theme }) => ({
					padding: "12px 20px",
					border: `1px solid ${theme.palette.default.main}`,
					borderRadius: "4px",
					fontSize: "0.875rem",
					":focus": {
						borderColor: theme.palette.primary.main
					}
				})
			}
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					fontSize: "0.875rem",
					fontWeight: 300
				}
			}
		},
		MuiButton: {
			styleOverrides: {
				startIcon: {
					minWidth: "16px",
					marginLeft: 0,
					marginRight: "4px"
				},
				root: ({ ownerState, theme }) => ({
					...(
						isMorePadding(ownerState.size)
							? {
								paddingTop: ownerState.variant === "outlined" ? "12px" : "13px",
								paddingBottom: ownerState.variant === "outlined" ? "12px" : "13px"
							}
							: {
								paddingTop: ownerState.variant === "outlined" ? "9px" : "10px",
								paddingBottom: ownerState.variant === "outlined" ? "9px" : "10px"
							}
					),
					paddingLeft: ownerState.startIcon || ownerState.endIcon ? (ownerState.variant === "outlined" ? "15px" : "27px") : "24px",
					paddingRight: ownerState.startIcon || ownerState.endIcon ? (ownerState.variant === "outlined" ? "15px" : "27px") : "24px",
					boxShadow: "none",
					textTransform: "none",
					borderColor: ownerState.color && ownerState.color !== "inherit" ? theme.palette[ownerState.color].main : theme.palette.default.main,

					":hover": {
						boxShadow: "none"
					}
				})
			},
			variants: [
				{
					props: { variant: "pagination" },
					style: ({ theme }) => ({
						border: `1px solid ${theme.palette.default.main}`,
						padding: "9px 6px"
					})
				},
				{
					props: { variant: "pagination-selected" },
					style: ({ theme }) => ({
						border: `1px solid ${theme.palette.default.main}`,
						padding: "9px 6px",
						backgroundColor: theme.palette.primary.main,
						color: theme.palette.primary.contrastText,
						":hover": {
							backgroundColor: theme.palette.primary.main
						}
					})
				},
				{
					props: { variant: "pagination" || "pagination-selected", size: "more-padding" },
					style: {
						padding: "12px 6px"
					}
				},
				{
					props: { variant: "text" },
					style: {
						padding: "10px"
					}
				},
				{
					props: { variant: "rectangular" },
					style: ({ theme }) => ({
						borderRadius: 0,
						backgroundColor: theme.palette.primary.main,
						color: theme.palette.primary.contrastText,

						":hover": {
							backgroundColor: theme.palette.primary.main
						}
					})
				},
				{
					props: { variant: "rectangular-with-icon" },
					style: ({ theme }) => ({
						borderRadius: 0,
						backgroundColor: theme.palette.primary.main,
						color: theme.palette.primary.contrastText,
						paddingLeft: "25px",

						":hover": {
							backgroundColor: theme.palette.primary.main
						}
					})
				}
			]
		}
	},
	typography: {
		fontFamily: "Open Sans",
		fontWeightBold: 700,
		fontWeightLight: 300,
		fontWeightMedium: 400,

		h1: {
			fontSize: "1.875rem"
		},
		h2: {
			fontSize: "1.5rem"
		},
		subtitle1: {
			fontSize: "1.125rem"
		},
		subtitle2: {
			fontSize: "1rem"
		},
		body1: {
			fontSize: "0.875rem"
		}
	}
});

function isMorePadding (value: string | undefined) {
	return value === "more-padding";
}
