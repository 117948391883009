import React from "react";
import { ToggleButtonGroup } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";

export type TSetPeriod = (newPeriod:number) => void | null | undefined;

interface IProps {
	className?: string
	setPeriod: TSetPeriod
	period: number
	periodsNames: string[]
};

export default function FMTablePeriodButtons (props: IProps) {
	const { period, setPeriod, periodsNames, className } = props;
	const handleAlignment = (
		event: React.MouseEvent<HTMLElement>,
		newPeriod: number
	) => {
		setPeriod(newPeriod);
	};

	const renderDateButtons = () => {
		return periodsNames.map((period, index) => {
			return (
				<ToggleButton
					className="periodButton"
					key={index}
					value={index}
					sx={{
						display: index ? "block" : "none",
						...toggleButtonSX

					}}
				>
					{period}
				</ToggleButton>
			);
		});
	};

	return (
		<ToggleButtonGroup
			className={"flexRow " + className}
			value={period}
			exclusive
			onChange={handleAlignment}
			aria-label="text alignment"
		>
			{renderDateButtons()}
		</ToggleButtonGroup>
	);
};

const toggleButtonSX = {
	borderTopLeftRadius: "4px!important",
	borderBottomLeftRadius: "4px!important",
	borderTopRightRadius: "4px!important",
	borderBottomRightRadius: "4px!important",
	flex: "1 1 auto",
	marginX: "2.5px!important",
	backgroundColor: "#E2E7E8",
	border: "none",
	height: "45px",
	minWidth: "64px",
	paddingX: "11px",
	textTransform: "capitalize",
	width: "max-content",
	"&:hover": {
		backgroundColor: "#2988CA",
		color: "#fff"
	},
	"&.Mui-selected": {
		backgroundColor: "#2988CA",
		color: "#fff"
	},
	"&.Mui-selected:hover": {
		backgroundColor: "#2988CA",
		color: "#fff"
	}
};
