import { Box } from "@mui/material";
import React from "react";
import PageTitle from "../../../components/Dashboard/dashboardComponents/PageTitle";
import { useAppSelector } from "../../../utils/hooks/reduxHooks";
import { MyProjectChart } from "./components/MyProjectChart";
import { MyProjectCopyingTerms } from "./components/MyProjectCopyingTerms";
import { MyProjectTradersDescription } from "./components/MyProjectTradersDescription";
import { MyProjectTradersInformation } from "./components/MyProjectTradersInforamtion";

export default function PageCopytradeMyProject () {
	const lang = useAppSelector((state) => state.language.language);
	const langCopytrade = useAppSelector((state) => state.language.languageCopytrade);

	return (
		<>
			<PageTitle
				layoutTitle={lang.sb_li_12}
				pageTitle={langCopytrade.ctrd_menu_4}
			/>
			<Box sx={{ marginBottom: "15px" }}>
				<MyProjectTradersDescription />
			</Box>
			<Box sx={{ marginBottom: "15px" }}>
				<MyProjectCopyingTerms />
			</Box>
			<Box sx={{ marginBottom: "15px" }}>
				<MyProjectTradersInformation />
			</Box>
			<MyProjectChart />
		</>
	);
};
