import * as React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useIsRtl } from "../../../../utils/hooks/useIsRtl";
import { DefaultWrapperProps } from "../Default";

export function WrapperForAuth (props: DefaultWrapperProps) {
	const { label, children } = props;
	const isRtl = useIsRtl();

	return (
		<Grid
			container
			justifyContent="space-between"
			alignItems="center"
			flexDirection={isRtl ? "row-reverse" : "row"}
			flexWrap="wrap"
			sx={{
				position: "relative",
				marginBottom: "16px"
			}}
		>
			<Typography
				sx={{
					width: "100%",
					lineHeight: "19px",
					marginBottom: "8px",
					color: "#333"
				}}
				fontSize={15}
				textAlign={isRtl ? "right" : "left"}
			>
				{label}
			</Typography>
			<Box
				sx={{
					position: "relative",
					width: "100%"
				}}
			>
				{children}
			</Box>
		</Grid>
	);
}
