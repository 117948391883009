import * as React from "react";
import { useHistory } from "react-router-dom";
import { Select } from "../../../../components/Form/Select";
import { Option } from "../../../../components/Form/Option";
import { DefaultForm } from "../../../../components/Forms/DepositForm/DefaultForm";
import { useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { bitcoinValidationSchema } from "./validation";
import { getInfo } from "./api";
import { useDispatch } from "react-redux";
import { setBitcoinStatusInfo } from "../../../../store/actions/actionTypes";

export function Bitcoin () {
	const [ isFetch, setIsFetch ] = React.useState(false);
	const history = useHistory();
	const language = useAppSelector((state) => state.language);
	const dispatch = useDispatch();

	const onSubmit = React.useCallback(async (values: any) => {
		try {
			setIsFetch(true);
			const { amount, currency } = values;
			const info = await getInfo(amount, currency);
			dispatch(setBitcoinStatusInfo({ amount, ...info }));
			setIsFetch(false);
			history.push("/app/bitcoin-deposit-status");
		} catch (e) {
			// handle some error
		} finally {
			setIsFetch(false);
		}
	}, []);

	return (
		<DefaultForm
			defaultValues={{ currency: "btc" }}
			notes={language.languageFinance.s_06}
			onSubmit={onSubmit}
			validationSchema={bitcoinValidationSchema(language.languageFinance)}
			isFetch={isFetch}
		>
			<Select
				name="currency"
				label={language.languageMyAccounts.mya_20}
			>
				<Option value="btc">Bitcoin</Option>
			</Select>
		</DefaultForm>
	);
}
