import * as React from "react";
import { IFMTableComponentProps } from "../../../../components/FMTable/FMTableCellComponent";
import { Box, SxProps } from "@mui/material";

export function CreateBonusStatisticsCell (
	dataKey: "name" | "value",
	options?: SxProps
) {
	return (props: IFMTableComponentProps<{ name: string, value: string }, {}>) => {
		return (
			<Box
				sx={{
					padding: "20px 22px",
					textOverflow: "ellipsis",
					overflow: "hidden",
					whiteSpace: "nowrap",
					...(options ?? {})
				}}
				title={props[dataKey]}
			>
				{props[dataKey]}
			</Box>
		);
	};
}
