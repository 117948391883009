import * as React from "react";
import { Grid, SxProps, Theme } from "@mui/material";
import { useIsRtl } from "../../../utils/hooks/useIsRtl";

export interface RowProps {
	children: JSX.Element | JSX.Element[] | string;
	justifyContent?: "start" | "center" | "space-between" | "space-around" | "space-evenly";
	alignItems?: "center" | "start" | "end" | "stretch";
	wrap?: "nowrap" | "wrap";
	sx?: SxProps<Theme>;
}

export function Row (props: RowProps) {
	const {
		children,
		justifyContent = "start",
		alignItems = "start",
		sx = {},
		wrap = "nowrap"
	} = props;
	const isRtl = useIsRtl();

	return (
		<Grid
			container
			sx={{
				display: "flex",
				flexDirection: isRtl ? "row-reverse" : "row",
				justifyContent,
				alignItems,
				flexWrap: wrap,
				...sx
			}}
		>
			{children}
		</Grid>
	);
}
