import * as React from "react";
import SocialBoxSSLCert from "./dashboardComponents/SocialBoxSSLCert";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../interfaces/StoreInterfaces";
import { Button, Typography } from "@mui/material";
import { useIsRtl } from "../../utils/hooks/useIsRtl";
import SocialBoxIconlinks from "./dashboardComponents/SocialBoxIconlinks";
import { useState } from "react";

export default function DashboardSocialBox () {
	const lang:any = useSelector((state:StoreInterface) => state.language.language);
	const [ openSocial, setOpenSocial ] = useState(false);
	const toggleSocialBox = () => {
		setOpenSocial(prevState => !prevState);
	};
	const isRtl = useIsRtl();
	const buttonSocialSX = {
		flexDirection: "column",
		height: "max-content",
		position: "fixed",
		backgroundColor: "#fff",
		textAlign: "center",
		bottom: "22px",
		"&:hover": {
			backgroundColor: "#fff"
		},
		"& .ssl-cert": {
			marginBottom: "8px",
			cursor: "pointer",
			maxWidth: "100%",
			overflow: "hidden"
		}
	};
	const openSX = {
		padding: "12px 17px",
		minHeight: "146px",
		minWidth: "214px",
		boxShadow: "0 0 6px 3px rgba(0,0,0,0.1)",
		right: isRtl ? "auto" : "24px",
		left: isRtl ? "24px" : "auto"
	};
	const closeSX = {
		right: isRtl ? "auto" : "0",
		left: isRtl ? "0" : "auto",
		boxShadow: "none",
		width: "40px",
		minWidth: "40px",
		padding: "5px",
		top: "calc(50% + 20px)",
		"& .ssl-cert": {
			maxWidth: "100%",
			marginBottom: 0,
			overflow: "hidden"
		},
		"& .ssl-cert svg": {
			width: "100px"
		},
		"& .socialIcons": {
			flexDirection: "column",
			marginTop: 0
		},
		"& .socialIcons a": {
			marginBottom: 0
		}
	};
	const boxSX = openSocial ? openSX : closeSX;

	return (
		<Button
			variant="outlined"
			color="primary"
			sx={{
				...buttonSocialSX,
				...boxSX
			}}
		>
			<SocialBoxSSLCert toggleSocialBox={toggleSocialBox}/>
			{openSocial &&
				<Typography
					sx={{
						fontWeight: "bold",
						textTransform: "uppercase"
					}}
				>
					{lang.botnav_13}
				</Typography>
			}
			<SocialBoxIconlinks/>
		</Button>
	);
};
