import * as React from "react";
import { IFMTableComponentProps } from "./FMTableCellComponent";
import ReactHtmlParser from "react-html-parser";

export default function FMTableSimpleCell<T, M> (props: IFMTableComponentProps<T, M>) {
	const { searchSort } = props;
	if (typeof searchSort === "string" && /<\/?[a-z][\s\S]*>/i.test(searchSort)) {
		return <>{ReactHtmlParser(searchSort)}</>;
	} else {
		return <>{searchSort}</>;
	}
};
