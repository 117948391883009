/*
    Created on : 05.02.2022, 06:18:59
    Author     : Andy Kar
*/

import {
	SET_MY_ACCOUNTS_DATA,
	SET_MY_ACCOUNTS_LOADING,
	SET_PERSONAL_DETAILS_LOADING,
	SET_PERSONAL_DETAILS_RESPONSE,
	SET_PHONE_VALIDATE_RESPONSE,
	SET_PHONE_VERIFY_CODE_RESPONSE, SET_UPDATE_LEVERAGE_RESPONSE

} from "../actions/actionTypes";

const initial = {
	myAccounts: {},
	myAccountsLoading: false,
	personalDetailsLoading: false,
	personalDetailsResponse: {},
	verifyResponse: {},
	verifyCodeResponse: {},
	leverageResponse: {}

};

export default function myAccountsReducer (state = initial, action:{type:string, payload:any}) {
	switch (action.type) {
		case SET_MY_ACCOUNTS_DATA:
			return {
				...state,
				myAccounts: action.payload
			};
		case SET_MY_ACCOUNTS_LOADING:
			return {
				...state,
				myAccountsLoading: action.payload
			};
		case SET_PERSONAL_DETAILS_LOADING:
			return {
				...state,
				personalDetailsLoading: action.payload
			};
		case SET_PERSONAL_DETAILS_RESPONSE:
			return {
				...state,
				personalDetailsResponse: action.payload
			};
		case SET_PHONE_VALIDATE_RESPONSE:
			return {
				...state,
				verifyResponse: action.payload
			};
		case SET_PHONE_VERIFY_CODE_RESPONSE:
			return {
				...state,
				verifyCodeResponse: action.payload
			};
		case SET_UPDATE_LEVERAGE_RESPONSE:
			return {
				...state,
				leverageResponse: action.payload
			};
		default:
			return state;
	}
}
