/*
    Created on : 05.02.2022, 06:18:59
    Author     : Andy Kar
*/

import { takeEvery, put, call } from "redux-saga/effects";
import {
	FETCH_PARTNERSHIP_CLICKS_DATA,
	FETCH_PARTNERSHIP_COMMISSION_DATA,
	FETCH_PARTNERSHIP_DATA,
	FETCH_PARTNERSHIP_REFERRALS_DATA,
	FETCH_PARTNERSHIP_REFERRALS_REGISTRATION_DATA,
	FETCH_PARTNERSHIP_SECURITY_CODE_DATA,
	SET_PARTNERSHIP_CLICKS_DATA,
	SET_PARTNERSHIP_CLICKS_LOADING,
	SET_PARTNERSHIP_COMMISSION_DATA,
	SET_PARTNERSHIP_COMMISSION_LOADING,
	SET_PARTNERSHIP_DATA,
	SET_PARTNERSHIP_FILTERED_CLICKS_DATA,
	SET_PARTNERSHIP_LOADING,
	SET_PARTNERSHIP_REFERRALS_DATA,
	SET_PARTNERSHIP_REFERRALS_LOADING, SET_PARTNERSHIP_REFERRALS_REGISTRATION_DATA,
	SET_PARTNERSHIP_REFERRALS_REGISTRATION_LOADING,
	SET_PARTNERSHIP_SECURITY_CODE_DATA,
	SET_PARTNERSHIP_SECURITY_CODE_LOADING
} from "../store/actions/actionTypes";
import { ResponseGenerator } from "../interfaces/mainInterfaces";
import API from "../utils/API";
import dayjs from "dayjs";
import { IPartnershipReferralsData } from "../pages/Partnership/PageReferrals/PageReferrals";

export interface IPartnershipInterface {
	isReqforCode: {
		account_number: string,
		Email: string
		Active: string
		userId: string
		affiliate_code: string
		country: string
		req_for_affiliate_code?: string
	},
	isApprovedRecCode: {
		account_number: string,
		Email: string
		Active: string
		userId: string
		affiliate_code: string
		country: string
		req_for_affiliate_code?: string
	},
	data: {
		PartnershipAgreementStatus: boolean
		accountnumber: string
	},
	isCPA: boolean
	affiliate_code_info: {affiliate_code: string}[]
	affiliate_code: string
	user_id: string
	users: {
		partner_agreement: string
	},
	partner_agreement: number
}

/**
 Get Partnership data from server and set it to store
 **/
export function * partnershipWatcher () {
	yield takeEvery(FETCH_PARTNERSHIP_DATA, partnershipWorker);
}

export function * partnershipWorker () {
	yield put({ type: SET_PARTNERSHIP_LOADING, payload: true });
	const data:IPartnershipInterface | {} = yield call(getPartnershipInfo);
	yield put({ type: SET_PARTNERSHIP_LOADING, payload: false });
	if (Object.keys(data).length) {
		yield put({ type: SET_PARTNERSHIP_DATA, payload: data });
	}
}

async function getPartnershipInfo () {
	const response:ResponseGenerator = await API.post("partnership");
	return response?.data || {};
}

/**
 Get Partnership Commission data from server and set it to store
 **/
export interface IPartnershipCommissionDataInterface {
	start?: string
	length?: string
	from?: string
	to?: string
	cumulative?: "yes" | "no"
}

export interface IPartnershipCommissionInterface {
	recordsTotal: number
	data: {
		DT_RowId: number
		date: string
		sum: number
		referral: number
		instrument: string
		order: number
	}[]
	chart: [ number, number ][]
	commission: number
	account: number
}

export function * partnershipCommissionWatcher () {
	yield takeEvery(FETCH_PARTNERSHIP_COMMISSION_DATA, partnershipCommissionWorker);
}

export function * partnershipCommissionWorker (action:{type:string, partnershipCommissionData:IPartnershipCommissionDataInterface}) {
	yield put({ type: SET_PARTNERSHIP_COMMISSION_LOADING, payload: true });
	const data:IPartnershipCommissionInterface | {} = yield call(getPartnershipCommissionInfo, action.partnershipCommissionData = {});
	yield put({ type: SET_PARTNERSHIP_COMMISSION_LOADING, payload: false });
	if (Object.keys(data).length) {
		yield put({ type: SET_PARTNERSHIP_COMMISSION_DATA, payload: data });
	}
}

async function getPartnershipCommissionInfo (partnershipCommissionData:IPartnershipCommissionDataInterface|any) {
	const formData = new FormData();
	Object.keys(partnershipCommissionData).map((key) => {
		formData.append(key, partnershipCommissionData[key]);
		return null;
	});
	const response:ResponseGenerator = await API.post("partnershipCommission", formData);
	return response?.data || {};
}

/**
 Get Partnership Clicks data from server and set it to store
 **/
export interface IPartnershipClicksDataInterface {
	"code": string
}

export interface IPartnershipClicksInterface {
	code: string
	data?: {
		date: string | number
		num: string | number
		acnum: string | number
	}[]
	chart_data?: [ number, number ][]
}

export function * partnershipClicksWatcher () {
	yield takeEvery(FETCH_PARTNERSHIP_CLICKS_DATA, partnershipClicksWorker);
}

export function * partnershipClicksWorker (action:{type:string, partnershipClicksData:IPartnershipClicksDataInterface}) {
	yield put({ type: SET_PARTNERSHIP_CLICKS_LOADING, payload: true });
	const data:IPartnershipClicksInterface | {} = yield call(getPartnershipClicksInfo, action.partnershipClicksData);
	yield put({ type: SET_PARTNERSHIP_CLICKS_LOADING, payload: false });
	if (Object.keys(data).length) {
		yield put({ type: SET_PARTNERSHIP_CLICKS_DATA, payload: data });
	}
}

async function getPartnershipClicksInfo (partnershipClicksData:IPartnershipClicksDataInterface|any) {
	const formData = new FormData();
	Object.keys(partnershipClicksData).map((key) => {
		formData.append(key, partnershipClicksData[key]);
		return null;
	});
	const response:ResponseGenerator = await API.post("partnershipClicks", formData);
	return response?.data || {};
}

export function * partnershipFilteredClicksWorker (action:{type:string, payload:IPartnershipClicksDataInterface & {to: number; from: number}}) {
	yield put({ type: SET_PARTNERSHIP_CLICKS_LOADING, payload: true });
	const data:IPartnershipClicksInterface = yield call(getPartnershipClicksInfo, action.payload);
	const { from, to } = action.payload;
	data.data = data.data?.filter(click => {
		const unix = dayjs(click.date).valueOf();
		return unix >= from && unix <= to;
	});
	data.chart_data = data.chart_data?.filter(click => {
		return click[0] >= from && click[0] <= to;
	});

	yield put({ type: SET_PARTNERSHIP_CLICKS_LOADING, payload: false });
	if (Object.keys(data).length) {
		yield put({ type: SET_PARTNERSHIP_CLICKS_DATA, payload: data });
	}
}

export function * partnershipFilteredClicksWatcher () {
	yield takeEvery(SET_PARTNERSHIP_FILTERED_CLICKS_DATA, partnershipFilteredClicksWorker);
}

/**
 Get Partnership Referrals data from server and set it to store
 **/

export interface IPartnershipReferralsInterface {
	account: number | string
	recordsTotal: number
	recordsFiltered: number
	data: IPartnershipReferralsData[]
	saldo: number | null
	commission: number | null
	lots: number | null
	balance: number | null
}

export function * partnershipReferralsWatcher () {
	yield takeEvery(FETCH_PARTNERSHIP_REFERRALS_DATA, partnershipReferralsWorker);
}

export function * partnershipReferralsWorker () {
	yield put({ type: SET_PARTNERSHIP_REFERRALS_LOADING, payload: true });
	const data:IPartnershipReferralsInterface | {} = yield call(getPartnershipReferralsInfo);
	yield put({ type: SET_PARTNERSHIP_REFERRALS_LOADING, payload: false });
	if (Object.keys(data).length) {
		yield put({ type: SET_PARTNERSHIP_REFERRALS_DATA, payload: data });
	}
}

async function getPartnershipReferralsInfo () {
	const response:ResponseGenerator = await API.post("partnershipReferrals");
	return response?.data || {};
}

/**
 Get Partnership Referrals Registration data from server and set it to store
 **/
export interface IPartnershipReferralsRegistration {
	date: string
	num: number
}
export interface IPartnershipReferralsRegistrationData {
	referralRegistration: Array<IPartnershipReferralsRegistration>,
	referralData: any,
	requestData: any
}

export function * partnershipReferralsRegistrationWatcher () {
	yield takeEvery(FETCH_PARTNERSHIP_REFERRALS_REGISTRATION_DATA, partnershipReferralsRegistrationWorker);
}

export function * partnershipReferralsRegistrationWorker () {
	yield put({ type: SET_PARTNERSHIP_REFERRALS_REGISTRATION_LOADING, payload: true });
	const data: IPartnershipReferralsRegistrationData | {} = yield call(getPartnershipReferralsRegistrationInfo);
	yield put({ type: SET_PARTNERSHIP_REFERRALS_REGISTRATION_LOADING, payload: false });
	if (Object.keys(data).length) {
		yield put({ type: SET_PARTNERSHIP_REFERRALS_REGISTRATION_DATA, payload: data });
	}
}

async function getPartnershipReferralsRegistrationInfo () {
	const response:ResponseGenerator = await API.post("partnershipReferralsRegistration");
	return response?.data || {};
}
/**
 Get Partnership securityCode data from server and set it to store
 **/

export interface IPartnershipSecurityCodeInterface {
	account: number | string
	code: string
	diff: number | string | null
	message: string
	success: boolean
}

export function * partnershipSecurityCodeWatcher () {
	yield takeEvery(FETCH_PARTNERSHIP_SECURITY_CODE_DATA, partnershipSecurityCodeWorker);
}

export function * partnershipSecurityCodeWorker () {
	yield put({ type: SET_PARTNERSHIP_SECURITY_CODE_LOADING, payload: true });
	const data:IPartnershipSecurityCodeInterface | {} = yield call(getPartnershipSecurityCodeInfo);
	yield put({ type: SET_PARTNERSHIP_SECURITY_CODE_LOADING, payload: false });
	if (Object.keys(data).length) {
		yield put({ type: SET_PARTNERSHIP_SECURITY_CODE_DATA, payload: data });
	}
}

async function getPartnershipSecurityCodeInfo () {
	const response:ResponseGenerator = await API.post("partnershipGetSecurityCode");
	return response?.data || {};
}
