/*
    Created on : 20.01.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import "./PageUploadDocumentsForCorporateAccount.scss";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../interfaces/StoreInterfaces";
import PageTitle from "../../../components/Dashboard/dashboardComponents/PageTitle";

export default function PageUploadDocumentsForCorporateAccount () {
	const langMyAccounts:any = useSelector((state:StoreInterface) => state.language.languageMyAccounts);

	return (
		<div className="page-upload-documents-for-corporate-account">
			<PageTitle pageTitle={langMyAccounts.mfn_05}/>
		</div>
	);
};
