/*
    Created on : 13.10.2021, 06:18:59
    Author     : Andy Kar
*/
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import {
	FETCH_PART_TRADERS, FETCH_SUBSCRIPTIONS_TRADERS, GET_MY_PROJECT, GET_PROFILE_INFORMATION
} from "../../store/actions/actionTypes";
import PageCopytradeMyProject from "../../pages/Copytrade/PageCopytradeMyProject/PageCopytradeMyProject";
import PageCopytradeProfile from "../../pages/Copytrade/PageCopytradeProfile/PageCopytradeProfile";
import PageCopytradeRecommendeds from "../../pages/Copytrade/PageCopytradeRecommendeds/PageCopytradeRecommendeds";
import PageCopytradeMonitoring from "../../pages/Copytrade/PageCopytradeMonitoring/PageCopytradeMonitoring";
import AccountTypes from "../components/AccountTypes/AccountTypes";
import { MySubscriptions } from "../../pages/Copytrade/MySubscriptions";
import { StoreInterface } from "../../interfaces/StoreInterfaces";
import { RegisterTrader } from "../../pages/Copytrade/RegisterTrader";
import { PageCopytradeAgreement } from "../../pages/Copytrade/PageCopytradeAgreement";

const LayoutCopytrade = (props:any) => {
	const tradersLength:number = useSelector((state:StoreInterface) => state.copytrade.tradersLength);
	const tradersAllLength:number = useSelector((state:StoreInterface) => state.copytrade.tradersAllLength);

	const fetchTradersByParts = () => {
		if (!tradersLength || tradersAllLength < tradersLength) {
			const mData = { start: tradersAllLength, length: 30 };
			dispatch({ type: FETCH_PART_TRADERS, mData });
		}
	};

	const dispatch = useDispatch();
	useEffect(() => {
		fetchTradersByParts();
	}, [ tradersAllLength ]);

	useEffect(() => {
		dispatch({ type: FETCH_SUBSCRIPTIONS_TRADERS, sData: { start: 0, length: 10 } });
		dispatch({ type: GET_PROFILE_INFORMATION });
		dispatch({ type: GET_MY_PROJECT });
	}, []);

	return (
		<>
			<Switch>
				{/* <Route exact path="/app/copytrade/copytrading" component={Copytrading} /> */}
				<Route exact path="/app/copytrade/copytrading" render={() => <PageCopytradeMonitoring {...props} />} />
				<Route exact path="/app/copytrade/subscriptions" render={() => <MySubscriptions {...props} />} />
				<Route exact path="/app/copytrade/my-project" render={() => <PageCopytradeMyProject {...props} />} />
				<Route exact path="/app/copytrade/profile" render={() => <PageCopytradeProfile {...props} />} />
				<Route exact path="/app/copytrade/recommendeds" render={() => <PageCopytradeRecommendeds {...props} />} />
				<Route exact path="/app/copytrade/register-trader" component={RegisterTrader} />
				<Route exact path="/app/copytrade/agreement" component={PageCopytradeAgreement} />
				<Redirect exact from="/app/copytrade" to="/app/copytrade/copytrading" />
			</Switch>
			<AccountTypes/>
		</>
	);
};

export default withRouter(LayoutCopytrade);
