/*
    Created on : 15.02.2022, 06:18:59
    Author     : Andy Kar
*/

import React, { useState } from "react";
import "./AccountsAccountCell.scss";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../interfaces/StoreInterfaces";
import { Link } from "react-router-dom";

export default function AccountsAccountCell (props:any) {
	const [ dropdownOpen, setDropdownOpen ] = useState(() => false);
	const toggle = () => setDropdownOpen(prevState => !prevState);
	const langMyAccount:any = useSelector((state:StoreInterface) => state.language.languageMyAccounts);
	const init:any = useSelector((state:StoreInterface) => state.init.init);
	const whitelistPIPCandCC:boolean = init.WhitelistPIPCandCC || false;

	const dropdownAccountMenu = () => {
		return (
			<ul className="dropdown-menu account-menu multi-level" role="menu" aria-labelledby="dropdownMenu">
				<li className="dropdown-submenu">
					<Link to='/app/deposit'>{langMyAccount.mya_04}</Link>
					<ul className="dropdown-menu level-2">
						<li><Link to='/app/deposit/debit-credit-cards'>{langMyAccount.mya_06}</Link></li>
						{whitelistPIPCandCC
							? <li><Link to='/app/deposit/debit-credit-cards'>{langMyAccount.mya_07}</Link></li>
							: null
						}
						<li><Link to='/app/deposit/skrill'>{langMyAccount.mya_08}</Link></li>
						<li><Link to='/app/deposit/neteller'>{langMyAccount.mya_09}</Link></li>
						{whitelistPIPCandCC
							? <>
								<li><Link to='/app/deposit/webmoney'>{langMyAccount.mya_10}</Link></li>
								<li><Link to='/app/deposit/paxum'>{langMyAccount.mya_11}</Link></li>
								<li><Link to='/app/deposit/ukash'>{langMyAccount.mya_12}</Link></li>
								<li><Link to='/app/deposit/payco'>{langMyAccount.mya_13}</Link></li>
								<li><Link to='/app/deposit/filspay'>{langMyAccount.mya_14}</Link></li>
								<li><Link to='/app/deposit/cashu'>{langMyAccount.mya_15}</Link></li>
								<li><Link to='/app/deposit/hipay'>{langMyAccount.mya_16}</Link></li>
							</>
							: null
						}
						<li><Link to='/app/deposit/paypal'>{langMyAccount.mya_17}</Link></li>
					</ul>
				</li>
				<li className="dropdown-submenu">
					<Link to='/app/withdraw'>{langMyAccount.mya_18}</Link>
					<ul className="dropdown-menu level-2">
						<li><Link to='/app/withdraw/bank-transfer'>{langMyAccount.mya_05}</Link></li>
						<li><Link to='/app/withdraw/debit-credit-cards'>{langMyAccount.mya_06}</Link></li>
						<li><Link to='/app/withdraw/unionpay'>{langMyAccount.mya_07}</Link></li>
						<li><Link to='/app/withdraw/skrill'>{langMyAccount.mya_08}</Link></li>
						<li><Link to='/app/withdraw/neteller'>{langMyAccount.mya_09}</Link></li>
						<li><Link to='/app/withdraw/webmoney'>{langMyAccount.mya_10}</Link></li>
						<li><Link to='/app/withdraw/paxum'>{langMyAccount.mya_11}</Link></li>
						<li><Link to='/app/withdraw/ukash'>{langMyAccount.mya_12}</Link></li>
						<li><Link to='/app/withdraw/payco'>{langMyAccount.mya_13}</Link></li>
						<li><Link to='/app/withdraw/filspay'>{langMyAccount.mya_14}</Link></li>
						<li><Link to='/app/withdraw/cashu'>{langMyAccount.mya_15}</Link></li>
						<li><Link to='/app/withdraw/paypal'>{langMyAccount.mya_17}</Link></li>
					</ul>
				</li>
			</ul>
		);
	};

	return (
		<div className="accounts-account-cell">
			<button
				type="button"
				className={classNames("btn btn-default", { "dropdown-toggle": (props && props.id) })}
				onClick={toggle}
				disabled={props && !props.id}
			>
				{props?.account_number}
			</button>
			<div className={classNames("dropdown-wrap", { open: (dropdownOpen) })} >
				{dropdownAccountMenu()}
			</div>
		</div>
	);
};
