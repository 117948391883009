import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import SquareSpinner from "../UI/FetchSpinner/SquareSpinner";

export default function FMTableRowLoading ({ colSpan }: { colSpan: number }) {
	return (
		<TableBody>
			<TableRow tabIndex={-1}>
				<TableCell sx={{ border: "none", margin: "5px", maxWidth: "100%", textAlign: "center" }} colSpan={colSpan}>
					<SquareSpinner
						color={[ "#2988CA", "#29A643", "#2988CA", "#29A643" ]}
						// color={[ "#2988CA", "#29A643", "#2988CA", "#29A643" ]}
						// color={[ "#5C7080", "#2988CA", "#5C7080", "#2988CA" ]}
					/>
				</TableCell>
			</TableRow>
		</TableBody>
	);
};
