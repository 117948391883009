import * as React from "react";
import { Box } from "@mui/material";

interface IFMTableCollapsibleCell {
	index: number
	toggle: (index: number) => void
	open: number []
	children: React.ReactElement
}

export default function FMTableCollapsibleCell (props: IFMTableCollapsibleCell) {
	const { index, toggle, open, children } = props;
	return (
		<Box className="flexRow" sx={{ alignItems: "center" }}>
			<Box
				sx={{
					padding: "4px",
					cursor: "pointer"
				}}
				onClick={() => toggle(index)}
			>
				{open[index]
					? <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
						<circle cx="7" cy="7" r="7" fill="#ec1717"/>
						<g transform="translate(3 6)">
							<rect id="Прямоугольник_127" data-name="Прямоугольник 127" width="2" height="8" rx="1" transform="translate(8) rotate(90)" fill="#fff"/>
						</g>
					</svg>
					: <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
						<circle cx="7" cy="7" r="7" fill="#29a643"/>
						<g transform="translate(3 3)">
							<rect id="Прямоугольник_126" data-name="Прямоугольник 126" width="2" height="8" rx="1" transform="translate(3)" fill="#fff"/>
							<rect id="Прямоугольник_127" data-name="Прямоугольник 127" width="2" height="8" rx="1" transform="translate(8 3) rotate(90)" fill="#fff"/>
						</g>
					</svg>
				}

			</Box>
			{children}
		</Box>
	);
};
