/*
    Created on : 15.06.2021, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { Box, Typography } from "@mui/material";

export default function SocialSignOr () {
	return (
		<Box sx={socialBoxSX}>
			<hr style={hrSX}/><Typography sx={orSX}>or</Typography><hr style={hrSX}/>
		</Box>
	);
};

const socialBoxSX = {
	display: "flex",
	alignItems: "center",
	width: "100%",
	marginBottom: "23px"
};
const orSX = {
	marginX: "8px",
	fontSize: "16px",
	lineHeight: "26px"
};
const hrSX = {
	width: "100%",
	margin: "12px 0"
};
