import * as React from "react";
import { Controller } from "react-hook-form";
import { FieldWrapper as FieldWrapperUI } from "../../UI/FieldWrapper";
import { FormContext } from "../Form";
import { Input as InputUI, InputVariant } from "../../UI/Input";
import { Text } from "../../UI/Text";

export interface InputProps {
	name: string;
	type?: string;
	tips?: string;
	label: string;
	placeholder?: string;
	disabled?: boolean;
	variant?: InputVariant;
}

export function Input (props: InputProps): JSX.Element {
	const {
		name,
		label,
		type = "text",
		variant = "default",
		tips,
		placeholder,
		disabled = false
	} = props;
	const { control, fieldWrapperVariant } = React.useContext(FormContext);
	const FieldWrapper = React.useMemo(() => FieldWrapperUI[fieldWrapperVariant], [ fieldWrapperVariant ]);
	const Input = React.useMemo(() => InputUI[variant], [ variant ]);

	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { value, onChange }, fieldState: { error } }) => (
				<FieldWrapper label={label} tips={tips}>
					<Input
						value={value}
						type={type}
						iserror={!!error}
						onChange={onChange}
						placeholder={placeholder}
						disabled={disabled}
					/>
					<Text
						color="#ff0000"
						fontSize={12}
						sx={{
							position: "absolute",
							top: "100%",
							width: "100%"
						}}
					>
						{error?.message}
					</Text>
				</FieldWrapper>
			)}
		/>
	);
}
