import * as React from "react";
import { Form } from "../../../../components/Form";
import { onlineNairaWithdrawValidationSchema } from "./validation";
import { Input } from "../../../../components/Form/Input";
import { ButtonWithLoader } from "../../../../components/ButtonWithLoader";
import { FormBottomWrapper } from "../../../../components/Form/FormBottomWrapper";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { fetchOnlineNairaInfo } from "../../../../store/actions/actionTypes";
import { Select } from "../../../../components/Form/Select";
import { Option } from "../../../../components/Form/Option";
import { For } from "../../../../components/Form/For";
import API from "../../../../utils/API";
import { AxiosResponse } from "axios";
import { useErrorHandling } from "../../../../utils/hooks/useErrorHandling";
import { Alert, Snackbar } from "@mui/material";

export function OnlineNaira () {
	const [ success, setSuccess ] = React.useState(false);
	const [ isFetch, setIsFetch ] = React.useState(false);
	const accountNumber = useAppSelector((state) => state.user.user.account_number);
	const language = useAppSelector((state) => state.language);
	const {
		fee,
		addon,
		complete
	} = useAppSelector((state) => state.withdrawMethodsInfo.onlineNaira);
	const dispatch = useAppDispatch();
	const { isOpen, showError, hideError, errorMsg } = useErrorHandling();

	React.useEffect(() => {
		if (!complete) {
			dispatch(fetchOnlineNairaInfo({}));
		}
	}, []);
	async function submit (values: {
		accountNumber: string,
		amount: string,
		withdrawalType: "NAIRA_WALLET" | "NAIRA_BANK",
		email: string,
		bankAccountFirstName: string,
		bankAccountLastName: string,
		bankAccountNumber: string,
		bankName: string,
		bankBranch: string,
		bankCity: string,
		bankProvince: string,
		bankZipCode: string,
		phoneNumber: string,
		address: string
	}) {
		setIsFetch(true);
		try {
			const formData = new FormData();

			formData.append("total_amount", values.amount);
			formData.append("withdrawal_type", values.withdrawalType);

			if (values.withdrawalType === "NAIRA_BANK") {
				formData.append("bank_account_number", values.bankAccountNumber);
				formData.append("firstname", values.bankAccountFirstName);
				formData.append("lastname", values.bankAccountLastName);
				formData.append("phone", values.phoneNumber);
				formData.append("bank_name", values.bankName);
				formData.append("bank_branch", values.bankBranch);
				formData.append("bank_province", values.bankProvince);
				formData.append("bank_city", values.bankCity);
				formData.append("bank_zip", values.bankZipCode);
				formData.append("address", values.address);
			}

			if (values.withdrawalType === "NAIRA_WALLET") {
				formData.append("email", values.email);
			}

			const response = await API.post<unknown, AxiosResponse<{
				success: "valid_error" | string | boolean,
				error: string,
				formError: {
					[key: string]: string
				}
			}>>("withdrawOnlineNaira", formData);

			const checkForErrors = () => {
				if (response.data?.success === "valid_error" || !response.data?.success) {
					const firstKey = Object.keys(response.data.formError ?? {})[0];

					if (firstKey && response.data.formError) {
						throw new Error(response.data.formError[firstKey]);
					}

					if (response.data.error) {
						throw new Error(response.data.error);
					}

					throw new Error("Something went wrong!");
				}
			};

			checkForErrors();

			setSuccess(true);
		} catch (e) {
			if (e instanceof Error) {
				showError(e.message);
			}
		} finally {
			setIsFetch(false);
		}
	}

	function calcFullAmount (values: [string]): string {
		const [ withdrawalAmount ] = values;
		const _fee = +withdrawalAmount * fee + addon;

		return `${isNaN(parseInt(withdrawalAmount)) ? 0 : (+withdrawalAmount + _fee).toFixed(3)} USD`;
	}

	return (
		<>
			<Form
				onSubmit={submit}
				validationSchema={onlineNairaWithdrawValidationSchema()}
				defaultValues={{
					accountNumber,
					amount: "",
					withdrawalType: "NAIRA_WALLET",
					email: "",
					bankAccountFirstName: "",
					bankAccountLastName: "",
					bankAccountNumber: "",
					bankName: "",
					bankBranch: "",
					bankCity: "",
					bankProvince: "",
					bankZipCode: "",
					phoneNumber: "",
					address: ""
				}}
			>
				<Input
					name="accountNumber"
					label={"Account Number"}
					variant="rounded"
				/>
				<Input
					name="amount"
					label={"Withdraw amount"}
					variant="rounded"
				/>
				<Select
					name="withdrawalType"
					label={"Withdraw Type"}
				>
					<Option value="NAIRA_WALLET">WALLET</Option>
					<Option value="NAIRA_BANK">BANK TRANSFER</Option>
				</Select>
				<For field="withdrawalType" withValues="NAIRA_WALLET">
					<Input
						name="email"
						label={"Online Naira Email"}
						variant="rounded"
					/>
				</For>
				<For field="withdrawalType" withValues="NAIRA_BANK">
					<Input
						name="bankAccountFirstName"
						label={"First Name"}
						variant="rounded"
					/>
					<Input
						name="bankAccountLastName"
						label={"Last Name"}
						variant="rounded"
					/>
					<Input
						name="bankAccountNumber"
						label={"Bank Account Number"}
						variant="rounded"
					/>
					<Input
						name="bankName"
						label={"Bank Name/Telephone network"}
						variant="rounded"
					/>
					<Input
						name="bankBranch"
						label={"Bank Branch"}
						variant="rounded"
					/>
					<Input
						name="bankCity"
						label={"Bank City"}
						variant="rounded"
					/>
					<Input
						name="bankProvince"
						label={"Bank Province"}
						variant="rounded"
					/>
					<Input
						name="bankZipCode"
						label={"Bank Zip Code"}
						variant="rounded"
					/>
					<Input
						name="phoneNumber"
						label={"Recipient's phone number"}
						variant="rounded"
					/>
					<Input
						name="address"
						label={"Recipient's address"}
						variant="rounded"
					/>
				</For>
				<FormBottomWrapper
					forFields={[ "amount" ]}
					onChange={calcFullAmount}
					label={language.languageMyAccounts.form_message_01}
				>
					<ButtonWithLoader
						isFetch={isFetch}
						color={"primary"}
						text={language.languageMyAccounts.chapas_04}
					/>
				</FormBottomWrapper>
				<Snackbar
					open={success}
					autoHideDuration={4000}
					onClose={() => setSuccess(false)}
					anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
				>
					<Alert variant="filled" color="success">
						Success
					</Alert>
				</Snackbar>
				<Snackbar open={isOpen} autoHideDuration={4000} onClose={hideError}>
					<Alert variant="filled" color="error">
						{errorMsg}
					</Alert>
				</Snackbar>
			</Form>
		</>
	);
}
