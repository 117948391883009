/*
    Created on : 24.11.2021, 06:18:59
    Author     : Andy Kar
*/

import React from "react";
import "./LoadingSpinner.scss";

const LoadingSpinner = (props:{percent?: number|null}) => {
	return (
		<div className="loading-spinner-wrap">
			<div className="loading-spinner-percent-wrap">
				<span className="loading-spinner-percent">{props.percent ? props.percent + "%" : null}</span>
			</div>
			<div className="loading-spinner"></div>
		</div>
	);
};

export default LoadingSpinner;
