import * as React from "react";
import API from "../../../../utils/API";
import { AxiosResponse } from "axios";
import { DepositPayTrust } from "../../../../components/Forms/DepositPayTrustForm";
import { calcInMYR } from "../../../../utils/calcInCurrencies";

export function PayTrust () {
	async function submit (values: { amount: string, firstName: string, lastName: string, phone: string}) {
		const formData = new FormData();
		formData.append("amount", values.amount);
		formData.append("first_name", values.firstName);
		formData.append("last_name", values.lastName);
		formData.append("phone_number", values.phone);

		const { data } = await API.post<unknown, AxiosResponse<{ redirect: string; error: { [key: string]: string } }>>("depositPaytrustMalaysia", formData);

		if (data.error) {
			let errorsMessage = "";
			Object.keys(data.error).forEach((key) => {
				errorsMessage += data.error[key];
			});

			throw new Error(errorsMessage);
		}
		if (!data.redirect) {
			throw new Error("Something went wrong");
		}

		window.open(data.redirect, "_self");
	}

	return (
		<>
			<DepositPayTrust
				submit={submit}
				calcInCurrency={calcInMYR}
			/>
		</>
	);
}
