import * as React from "react";
import { useHistory } from "react-router-dom";
import { Select } from "../../../../components/Form/Select";
import { Option } from "../../../../components/Form/Option";
import { DefaultForm } from "../../../../components/Forms/DepositForm/DefaultForm";
import { useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { bitcoinValidationSchema } from "./validation";
import { getInfo } from "./api";
import { useDispatch } from "react-redux";
import { setTetherStatusInfo } from "../../../../store/actions/actionTypes";
import { Checkbox } from "../../../../components/Form/Checkbox";
import { Box } from "@mui/material";

export function Tether () {
	const [ isFetch, setIsFetch ] = React.useState(false);
	const history = useHistory();
	const language = useAppSelector((state) => state.language);
	const dispatch = useDispatch();

	const onSubmit = React.useCallback(async (values: any) => {
		try {
			setIsFetch(true);
			const { amount, currency } = values;
			const info = await getInfo(amount, currency);
			console.log(info.redirect.split("/").slice(-2)[0]);
			dispatch(setTetherStatusInfo({
				amount,
				address: info.display_massage.split(":")[1].split("<")[0].trim(),
				orderId: info.redirect.split("/").slice(-2)[0],
				createDate: new Date().toDateString()
			}));
			setIsFetch(false);
			history.push("/app/tether-deposit-status");
		} catch (e) {
			// handle some error
			console.error(e);
		} finally {
			setIsFetch(false);
		}
	}, []);

	return (
		<DefaultForm
			defaultValues={{ currency: "btc" }}
			notes={language.languageFinance.s_06}
			onSubmit={onSubmit}
			validationSchema={bitcoinValidationSchema(language.languageFinance)}
			isFetch={isFetch}
			agree={
				<Box sx={{ marginBottom: "20px" }}>
					<Checkbox
						name="agree"
						label="The information is accepted"
						variant="square"
					/>
				</Box>
			}
		>
			<Select
				name="currency"
				label={language.languageMyAccounts.mya_20}
			>
				<Option value="trc20_usdt">TRC20-USDT</Option>
				<Option value="erc20_usdt">ERC20-USDT</Option>
				<Option value="bep20_usdt">BEP20-USDT</Option>
			</Select>
		</DefaultForm>
	);
}
