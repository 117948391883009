import * as React from "react";

export interface EmptyFormProps {
	refValue: React.MutableRefObject<HTMLFormElement | null>;
	action: string;
	method: "POST" | "GET";
	params?: { [key: string]: string | number };
}

export function EmptyForm (props: EmptyFormProps) {
	const {
		refValue,
		action,
		method,
		params = {}
	} = props;
	return (
		<form
			ref={refValue}
			action={action}
			method={method}
		>
			{
				Object.keys(params).map((key) => {
					return <input key={key} name={key} value={(params)[key]} hidden />;
				})
			}
		</form>
	);
}
