/*
    Created on : 01.02.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { StoreInterface, UserDataInterface } from "../../../../../interfaces/StoreInterfaces";
import { Box, Typography } from "@mui/material";
import PencilEditIcon from "./PencilEditIcon";
import UserIcon from "../../../../../components/Icons/UserIcon";
import { ISxAdaptive, useAdaptive } from "../../../../../utils/hooks/themeBreakpointsHooks";

const adaptiveWrapperStyles: ISxAdaptive = {
	xs: {
		width: "100%",
		marginBottom: "21px"
	},
	sm: {
		width: "auto",
		marginBottom: 0
	}
};

const adaptiveAvatarStyles: ISxAdaptive = {
	xs: {
		width: "40px",
		height: "40px",
		marginLeft: 0
	},
	sm: {
		marginX: "7px",
		width: "56px",
		height: "56px"
	}
};

const adaptiveFullNameStyles: ISxAdaptive = {
	xs: {
		fontSize: "18px",
		color: "#5C7080",
		marginLeft: "8px",
		lineHeight: "22px"
	},
	sm: {
		fontSize: "24px",
		lineHeight: "33px",
		marginX: "20px"
	}
};

const adaptiveEmailStyles: ISxAdaptive = {
	xs: {
		fontSize: "14px",
		lineHeight: "18px",
		color: "#5C7080"
	},
	sm: {
		fontSize: "16px",
		lineHeight: "22px",
		textAlign: "left"
	}
};

export default function UserDetails () {
	const init:any = useSelector((state: StoreInterface) => state.init.init);
	const user: UserDataInterface = useSelector((state: StoreInterface) => state.user.user);

	const adaptiveWrapper = useAdaptive(adaptiveWrapperStyles);
	const adaptiveAvatar = useAdaptive(adaptiveAvatarStyles);
	const adaptiveFullName = useAdaptive(adaptiveFullNameStyles);
	const adaptiveEmail = useAdaptive(adaptiveEmailStyles);

	let avatar:string = "";
	if (user.image) {
		avatar = init.links.main_site_user_images + user.image;
	}

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "flex-start",
				...adaptiveWrapper
			}}
		>
			<Box sx={{ ...avatarWrapSX, ...adaptiveAvatar }}>
				{avatar ? <img src={avatar} alt="avatar"/> : <UserIcon/>}
			</Box>
			<Box sx={{ marginX: "7px" }}>
				<Box
					className="flexRow"
					sx={{
						justifyContent: "space-between",
						alignItems: "center"
					}}
				>
					<Typography sx={adaptiveFullName}>{user.full_name || ""}</Typography>
					<Link to='/app/profile/edit' className="change-detail-btn">
						<PencilEditIcon/>
					</Link>
				</Box>
				<Typography sx={adaptiveEmail}>{user.email || ""}</Typography>
			</Box>
		</Box>
	);
};

const avatarWrapSX = {
	"& img": {
		maxWidth: "100%",
		maxHeight: "100%"
	}
};
