/*
    Created on : 15.06.2021, 06:18:59
    Author     : Andy Kar
*/
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { StoreInterface } from "../../../../interfaces/StoreInterfaces";
import {
	SEND_AUTH_INFO,
	SEND_PARTNER_AUTH_INFO,
	SEND_RESET_PASS_INFO
} from "../../../../store/actions/actionTypes";
import { Box, Typography } from "@mui/material";
import { Form } from "../../../../components/Form";
import * as yup from "yup";
import { authDataInterface } from "../../../../saga/authSaga";
import { Input } from "../../../../components/Form/Input";
import { SignInType } from "./AuthForm";
import { ButtonWithLoader } from "../../../../components/ButtonWithLoader";

function SignInForm (props:any) {
	const type: SignInType = props.type;
	const lang:any = useSelector((state:StoreInterface) => state.language.language);
	const auth:any = useSelector((state:StoreInterface) => state.auth.auth);
	const authLoading:boolean = useSelector((state:StoreInterface) => state.auth.loading);

	const dispatch = useDispatch();
	const sendAuth = (authData:authDataInterface) => {
		if (type === "partner" as SignInType) {
			dispatch({ type: SEND_PARTNER_AUTH_INFO, authData });
		} else {
			dispatch({ type: SEND_AUTH_INFO, authData });
		}
	};
	const sendReset = () => {
		if (props.match.params.token && !auth.token && !authLoading) {
			const resetData = new FormData();
			resetData.append("token", props.match.params.token);
			dispatch({ type: SEND_RESET_PASS_INFO, resetPassData: resetData });
		}
	};

	useEffect(() => {
		sendReset();
	}, [ auth ]);

	const schema = yup.object().shape({
		username: yup.string().required("Field is required"),
		password: yup.string().required("Field is required")
	}).required();

	return (
		<Box>
			{(props.match.params.token && auth.reset_success) ? <p style={{ color: "green" }}>{lang.cs_02}</p> : null}
			{(props.match.params.token && auth.reset_error) ? <p style={{ color: "red" }}>{auth.reset_error}</p> : null}
			<Form
				defaultValues={{ username: "", password: "" }}
				onSubmit={sendAuth}
				validationSchema={schema}
				fieldWrapperVariant="for-auth"
			>
				<Box sx={{ marginBottom: "30px" }}>
					<Input
						type="text"
						name="username"
						label={lang.for_pas_05}
					/>
					<Input
						type="password"
						name="password"
						label={lang.ps_10}
					/>
					{(auth.logged && auth.logged.error) ? <span className="error-message">{auth.logged.error}</span> : null}
				</Box>
				<ButtonWithLoader
					type="submit"
					color="success"
					sx={buttonSX}
					text={lang.cs_06}
					isFetch={authLoading}
				/>
				<Link to={"/app/forgot-password"}>
					<Typography component="h3" sx={linkSX}>
						{lang.cs_05}
					</Typography>
				</Link>
			</Form>
		</Box>
	);
};

export default withRouter(SignInForm);

const buttonSX = {
	borderRadius: 0,
	height: "45px",
	margin: "0 auto 8px",
	padding: "0 13px",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	textTransform: "none",
	minWidth: "185px"
};

const linkSX = {
	fontSize: "13px",
	color: "#2988CA"
};
