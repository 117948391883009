/* eslint-disable camelcase */
import * as React from "react";
import { Grid, Typography } from "@mui/material";
import { Copy } from "../../../../components/UI/Copy";
import { FieldWrapper as FieldWrapperUI } from "../../../../components/UI/FieldWrapper";
import { useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { useIsRtl } from "../../../../utils/hooks/useIsRtl";
import MyanmarDepositorsLogos from "../../../../images/MMDepositor.png";

export function LocalDepositorNigeria () {
	const {
		trd_43
	} = useAppSelector((state) => state.language.languageTrading);
	const isRtl = useIsRtl();
	const FieldWrapper = React.useMemo(() => FieldWrapperUI.default, []);

	return (
		<>
			<Grid
				container
				flexDirection={isRtl ? "row-reverse" : "row"}
				sx={{ marginBottom: "16px", paddingTop: "20px" }}
			>
				<img
					src={MyanmarDepositorsLogos}
					width="94px"
					alt="MyanmarDepositorsLogos"
				/>
			</Grid>
			<Typography
				color="#5C7080"
				textAlign={isRtl ? "right" : "left"}
				className="light"
				sx={{ marginBottom: "15px" }}
			>
				To proceed with the deposit or buy USDt in Myanmar, you may contact our Local Deposit service provider in Myanmar.
			</Typography>
			<Typography
				color="#5C7080"
				textAlign={isRtl ? "right" : "left"}
				className="bold"
				sx={{ marginBottom: "15px" }}
			>
				{trd_43}
			</Typography>
			<FieldWrapper label="Local Exchanger name">
				<Copy
					value="Fast Pay"
				/>
			</FieldWrapper>
			<FieldWrapper label="Phone">
				<Copy
					value="+95 9899931886"
				/>
			</FieldWrapper>
			<FieldWrapper label="Email">
				<Copy
					value="deposit-myanmar@forexmart.com"
				/>
			</FieldWrapper>
		</>
	);
}
