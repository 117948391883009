import * as MUI from "@mui/material";
import { styled } from "@mui/material/styles";

export const Tab = styled<MUI.ExtendButtonBase<MUI.TabTypeMap<{ fontSize: string, padding?: string, minHeight?: string }, "div">>>(MUI.Tab)((props) => props.theme.unstable_sx({
	"&.MuiTab-root": {
		textTransform: "none",
		justifyContent: "center",
		borderTopLeftRadius: "4px",
		borderTopRightRadius: "4px",
		background: "#E2E7E8",
		color: "#5C7080",
		fontSize: props.fontSize,
		fontWeight: 400,
		marginRight: "8px",
		letterSpacing: 0,
		lineHeight: "19px",
		padding: props.padding ?? "10px 40px",
		minHeight: props.minHeight ?? "48px",
		opacity: 1

	},

	"&.Mui-selected": {
		backgroundColor: "#2988CA",
		color: "#E2E7E8"
	}
}));
