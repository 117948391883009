import * as React from "react";
import { Box, Button, Typography } from "@mui/material";
import { FormContext } from "../../../../../components/Form";
import { Controller } from "react-hook-form";
import { styled } from "@mui/styles";
import { useAppSelector } from "../../../../../utils/hooks/reduxHooks";
import { SelectPhotoModal } from "./SelectPhotoModal";
import { useModalControls } from "../../../../../utils/hooks/useModalControl";

export const PHOTOS_URL = "https://my.forexmart.com/assets/images/trader_avatar/";

export interface IAvatarSelectProps {
	name: string;
}

const AvatarWrapper = styled(Box)({
	width: "150px",
	height: "148px",
	border: "2px solid #5c7080",
	overflow: "hidden",
	margin: "0 auto",
	marginBottom: "20px",
	"& img": {
		width: "100%",
		height: "100%",
		objectFit: "cover"
	}
});

const FileChooseWrapper = styled(Box)({
	position: "relative",
	maxWidth: "400px",
	margin: "0 auto"
});

export function AvatarSelect (props: IAvatarSelectProps) {
	const { name } = props;
	const { control } = React.useContext(FormContext);
	const { languageCopytrade } = useAppSelector(state => state.language);
	const { isOpen, openModal, closeModal } = useModalControls();
	const fileRef = React.useRef<HTMLInputElement>(null);

	const selectFile = React.useCallback(() => {
		if (fileRef.current) {
			fileRef.current.click();
		}
	}, [ fileRef.current ]);

	const onFileChange = React.useCallback((args: {
		prevValue: { path: string },
		event: React.ChangeEvent<HTMLInputElement>,
		onChange: (...event: any[]) => void
	}) => {
		const { prevValue, event, onChange } = args;
		const file = event.target.files ? event.target.files[0] : null;

		if (!file) {
			return onChange(prevValue);
		}

		return onChange({
			path: URL.createObjectURL(file),
			file,
			selectedPhoto: ""
		});
	}, []);

	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { value, onChange } }) => {
				return (
					<>
						<AvatarWrapper>
							{
								value.path
									? (
										<img
											src={value.path}
											alt={name}
											loading="lazy"
										/>
									)
									: (
										<></>
									)
							}
						</AvatarWrapper>
						<input
							ref={fileRef}
							type="file"
							onChange={(event) => onFileChange({
								prevValue: value,
								event,
								onChange
							})}
							hidden
						/>
						<FileChooseWrapper>
							<Button
								variant="contained"
								color="default"
								onClick={selectFile}
								sx={{
									maxWidth: "400px",
									width: "100%"
								}}
							>
								{languageCopytrade.boo_str_01}
							</Button>
							<Typography
								sx={{
									position: "absolute",
									left: "calc(100% + 10px)",
									top: 0,
									width: "100%",
									maxWidth: "100px",
									transform: "translateY(50%)",
									whiteSpace: "nowrap",
									textOverflow: "ellipsis",
									overflow: "hidden"
								}}
							>
								{value.file ? value.file.name : languageCopytrade.boo_str_02}
							</Typography>
							<Typography
								fontWeight="bold"
								color="#5c7080"
							>
								{languageCopytrade.or_1}
							</Typography>
							<Button
								variant="contained"
								onClick={openModal}
								sx={{
									maxWidth: "400px",
									width: "100%"
								}}
							>
								{languageCopytrade.select_photo_1}
							</Button>
							<SelectPhotoModal
								isOpen={isOpen}
								onClose={closeModal}
								onSelect={onChange}
							/>
						</FileChooseWrapper>
					</>
				);
			}}
		/>
	);
}
