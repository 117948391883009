import dayjs from "dayjs";
import { IFMTableColumn } from "./IFMTable";
import { IFMTableCellComponentType } from "./FMTableCellComponent";
import FMTableSimpleCell from "./FMTableSimpleCell";
import { SxProps } from "@mui/material";

export type IFMTableColumnCellAlign = "left" | "right" | "center";

export interface IHeadCellsFMTableColumn<T, M> {
	props: IFMTableColumn<T, M>
};

interface IHeadCellsFMTable<T, M> {
	columns: Array<IHeadCellsFMTableColumn<T, M>>
};

export interface IHeadCell<T> {
	disablePadding?: boolean;
	id: keyof T | string;
	label?: string;
	align?: IFMTableColumnCellAlign;
	sx?: SxProps;
};

export function headCellsFMTable<T, M> (props: IHeadCellsFMTable<T, M>) {
	const { columns } = props;
	const headCells: Array<IHeadCell<T>> = [] as Array<IHeadCell<T>>;
	const columnsInfo: Array<IFMTableColumn<T, M>> = [] as Array<IFMTableColumn<T, M>>;
	columns?.map((column, index: number) => {
		columnsInfo[index] = column.props;
		headCells[index] = {
			disablePadding: column.props.disablePadding,
			id: column.props.id,
			label: column.props.label,
			align: column.props.align,
			sx: column.props.headStyle
		};
		return null;
	});

	return headCells;
};

interface IRowsFMTable<T, M> {
	data: Array<T>
	columns: Array<IHeadCellsFMTableColumn<T, M>>
	collapsibleComponent?: IFMTableCellComponentType<T, M>
};

export interface IFMTableRowsArrayItem<T, M> {
	rowItem: T
	component?: IFMTableCellComponentType<T, M>
	searchSort: any
	collapsibleComponent?: IFMTableCellComponentType<T, M>
	label?: string,
	align?: IFMTableColumnCellAlign
};

export type IFMTableRowObj<T, M> = {
	[K in keyof T | string]: IFMTableRowsArrayItem<T, M>
};

export function dataFMTable<T, M> (props: IRowsFMTable<T, M>): Array<IFMTableRowObj<T, M>> {
	const { data, columns, collapsibleComponent } = props;
	const columnsInfo: Array<IFMTableColumn<T, M>> = [] as Array<IFMTableColumn<T, M>>;
	const rowsArray: Array<IFMTableRowObj<T, M>> = [] as Array<IFMTableRowObj<T, M>>;

	columns?.map((column:IHeadCellsFMTableColumn<T, M>, index: number) => {
		columnsInfo[index] = { ...column.props };
		return null;
	});

	if (data) {
		data.map((rowItem: T) => {
			const rowObj: IFMTableRowObj<T, M> = {} as IFMTableRowObj<T, M>;
			columnsInfo.map((column: IFMTableColumn<T, M>, index) => {
				const propName: keyof T = (typeof column.dataPropName === "string") ? column.dataPropName?.split(".")[0] as keyof T : column.dataPropName as keyof T;
				const searchSort = rowItem[propName as keyof T || column.id as keyof T];
				const columnComponent = typeof column.component === "function" ? column.component : FMTableSimpleCell;
				rowObj[column.id] = {
					component: columnComponent,
					collapsibleComponent: index === 0 ? collapsibleComponent : undefined,
					rowItem,
					searchSort,
					label: column.label,
					align: column.align
				};
				return null;
			});
			rowsArray.push(rowObj);
			return null;
		});
	};

	return rowsArray;
};

interface IChartFMTable<T, M> {
	allRowsData: Array<IFMTableRowObj<T, M>>
	chartScheme?: {
		xColumnId: keyof T
		yColumnId: keyof T
	}
}

export function chartFMTable<T, M> (props: IChartFMTable<T, M>) {
	const { allRowsData, chartScheme } = props;
	const chartArray: [number, number][] = [];

	if (allRowsData) {
		allRowsData.map((rowItem: IFMTableRowObj<T, M>) => {
			if (chartScheme && rowItem[chartScheme.xColumnId] && rowItem[chartScheme.yColumnId]) {
				let x = rowItem[chartScheme.xColumnId].searchSort;
				if (typeof rowItem[chartScheme.xColumnId] === "string" && chartScheme.xColumnId.toString().toLowerCase() === "date") {
					x = dayjs(rowItem[chartScheme.xColumnId].searchSort).valueOf();
				} else if (typeof rowItem[chartScheme.xColumnId] === "string") {
					x = parseFloat(rowItem[chartScheme.xColumnId].searchSort.toString());
				}
				const y = (typeof rowItem[chartScheme.yColumnId].searchSort === "string")
					? parseFloat(rowItem[chartScheme.yColumnId].searchSort.toString())
					: rowItem[chartScheme.yColumnId].searchSort;
				chartArray.push([ x as number, y ]);
			}
			return null;
		});
	};

	return chartArray;
};
