/*
    Created on : 22.11.2023, 06:18:59
    Author     : Andy Kar
*/
import React from "react";

export default function PageMyAchievements () {
	return (
		<div className="page-my-achievements">
			<h1>Page My Achievements</h1>
		</div>
	);
}
