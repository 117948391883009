import * as React from "react";
import { Grid } from "@mui/material";
import { useIsRtl } from "../../../../utils/hooks/useIsRtl";
import { Props } from "./types";
import { useIsMobile } from "../../../../utils/hooks/useIsMobile";

export function Wrapper (props: Props): JSX.Element {
	const isRtl = useIsRtl();
	const isMobile = useIsMobile();
	const { children } = props;

	return (
		<Grid
			container
			flexDirection={isMobile ? "column" : isRtl ? "row-reverse" : "row"}
		>
			{children}
		</Grid>
	);
}
