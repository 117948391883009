import * as React from "react";
import { Box, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { useAppSelector } from "../../../utils/hooks/reduxHooks";
import { useIsRtl } from "../../../utils/hooks/useIsRtl";
import { FieldWrapper as FieldWrapperUI } from "../../UI/FieldWrapper";
import { FormContext } from "../Form";
// @ts-ignore
import { OptionUI } from "../Option";
// @ts-ignore
import { SelectUI } from "../Select";

export interface ExpiryDateProps {
	name: string;
	label: string;
	children: JSX.Element | JSX.Element[];
}

export function ExpiryDate (props: ExpiryDateProps) {
	const { name, label, children } = props;
	const { control, fieldWrapperVariant } = React.useContext(FormContext);
	const FieldWrapper = React.useMemo(() => FieldWrapperUI[fieldWrapperVariant], [ fieldWrapperVariant ]);
	const language = useAppSelector((state) => state.language.languageFinance);
	const isRtl = useIsRtl();

	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { value, onChange }, fieldState: { error } }) => (
				<FieldWrapper label={label}>
					<Box sx={{ maxWidth: "165px", width: "100%", marginRight: "auto" }}>
						<SelectUI
							defaultValue={value ? value.month : 0}
							onChange={(e: { target: { value: any; }; }) => onChange({ ...value, month: e.target.value })}
						>
							<OptionUI isRtl={isRtl} value={0}>
              Select month
							</OptionUI>
							<OptionUI isRtl={isRtl} value={1}>
								{language.season_01}
							</OptionUI>
							<OptionUI isRtl={isRtl} value={2}>
								{language.season_02}
							</OptionUI>
							<OptionUI isRtl={isRtl} value={3}>
								{language.season_03}
							</OptionUI>
							<OptionUI isRtl={isRtl} value={4}>
								{language.season_04}
							</OptionUI>
							<OptionUI isRtl={isRtl} value={5}>
								{language.season_05}
							</OptionUI>
							<OptionUI isRtl={isRtl} value={6}>
								{language.season_06}
							</OptionUI>
							<OptionUI isRtl={isRtl} value={7}>
								{language.season_07}
							</OptionUI>
							<OptionUI isRtl={isRtl} value={8}>
								{language.season_08}
							</OptionUI>
							<OptionUI isRtl={isRtl} value={9}>
								{language.season_09}
							</OptionUI>
							<OptionUI isRtl={isRtl} value={10}>
								{language.season_10}
							</OptionUI>
							<OptionUI isRtl={isRtl} value={11}>
								{language.season_11}
							</OptionUI>
							<OptionUI isRtl={isRtl} value={12}>
								{language.season_12}
							</OptionUI>
						</SelectUI>
					</Box>
					<Box sx={{ maxWidth: "165px", width: "100%" }}>
						<SelectUI
							defaultValue={value ? value.year : new Date().getFullYear()}
							onChange={(e: { target: { value: any; }; }) => onChange({ ...value, year: e.target.value })}
						>
							{children}
						</SelectUI>
					</Box>
					<Typography
						color="#ff0000"
						fontSize={12}
						sx={{
							position: "absolute",
							top: "100%",
							width: "100%",
							textAlign: isRtl ? "right" : "left"
						}}
					>
						{error?.message}
					</Typography>
				</FieldWrapper>
			)}
		/>
	);
}
