import * as React from "react";
import { TableBody, TableRow } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import { filterFMTableColumnMobile, IFMTableMobileScheme } from "./mobileFMTable";
import { IFMTableRowObj } from "./formatDataFMTable";
import FMTableCellComponent from "./FMTableCellComponent";

interface IFMTableCollapsibleColumns<T, M> {
	row: IFMTableRowObj<T, M>
	mobileScheme?: IFMTableMobileScheme
	mobileAutoSlice?: number
	breakpoint: number
	page: number
	componentData?: M
}

export default function FMTableCollapsibleColumns<T, M> (props: IFMTableCollapsibleColumns<T, M>) {
	const { row, mobileScheme, mobileAutoSlice, breakpoint, page, componentData } = props;
	const rowLength = Object.keys(row).length;

	const renderRows = () => {
		return Object.keys(row)
			.filter((key:string, index: number) => filterFMTableColumnMobile({ key, index, mobileScheme, rowLength, mobileAutoSlice, breakpoint }).collapse)
			.map((key, i) => {
				return (
					<TableRow key={"tr-" + i}>
						<TableCell
							align={"left"}
							sx={{ border: "none", height: "40px", padding: "0px 16px" }}
						>
							{row[key].label}</TableCell>
						<TableCell
							align={"center"}
							sx={{ border: "none", height: "40px", padding: "0px 16px" }}
						>
							<FMTableCellComponent<T, M>
								comp={row[key].component}
								data={row[key].rowItem}
								pr={{
									searchSort: row[key].searchSort,
									label: row[key].label,
									align: row[key].align,
									page,
									componentData
								}}
							/>
						</TableCell>
					</TableRow>
				);
			});
	};
	return (
		<Table
			sx={{
				minWidth: "300px",
				width: "100%",
				backgroundColor: "#F1F9FF"
			}}
			aria-labelledby="tableTitle"
			size={"small"}
		>
			<TableBody>
				{renderRows()}
			</TableBody>
		</Table>
	);
};
