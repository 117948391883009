/* eslint-disable camelcase */
import * as React from "react";
import * as Yup from "yup";
import { Box, Grid, SxProps } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../utils/hooks/reduxHooks";
import { ButtonWithLoader } from "../../../../../components/ButtonWithLoader";
import API from "../../../../../utils/API";
import { createUpdateProfileDetailsAction } from "../../../../../store/actions/actionTypes";
import { DatePicker } from "../../../../../components/Form/DatePicker";
import { Form } from "../../../../../components/Form";
import { Select } from "../../../../../components/Form/Select";
import { Option } from "../../../../../components/Form/Option";
import { Checkbox } from "../../../../../components/Form/Checkbox";
import { useIsMobile } from "../../../../../utils/hooks/useIsMobile";
import { useAdaptive } from "../../../../../utils/hooks/themeBreakpointsHooks";

const validation = Yup.object().shape({});

const xsColumnStyles: SxProps = {
	width: "100%",
	margin: "0"
};
const xsRightColumnStyles: SxProps = {
	...xsColumnStyles,
	paddingTop: "20px"
};
const smLeftColumnStyles: SxProps = {
	marginRight: "16px"
};

const smRightColumnStyles: SxProps = {
	marginLeft: "16px",
	paddingTop: 0
};

export function SetupNotifications () {
	const languageCopytrade = useAppSelector(state => state.language.languageCopytrade);
	const languageInit = useAppSelector(state => state.language.language);
	const {
		MonitoringStart,
		AlertMail,
		AlertSms,
		ShowDeals,
		Deactivate
	} = useAppSelector(state => state.copytrade.profile.details || {});
	const dispatch = useAppDispatch();
	const [ isFetch, setIsFetch ] = React.useState(false);
	const isMobile = useIsMobile(649);

	const submit = React.useCallback(async (values: any) => {
		setIsFetch(true);
		try {
			console.log(values);

			if (Deactivate && !values.project_close) {
				await API.post("copytradeActivateAccount");
				dispatch(createUpdateProfileDetailsAction({
					Deactivate: false
				}));
			}

			if (!Deactivate) {
				const formData = new FormData();
				formData.append("mon_start", new Date(values.mon_start).toISOString().split("T")[0]);
				formData.append("alerts_email", values.alerts_email ? "1" : "0");
				formData.append("show_deals", values.show_deals);
				formData.append("alerts_sms", values.alerts_sms ? "1" : "0");

				if (values.project_close) {
					formData.append("project_close", "1");
				}

				await API.post("copytradeUpdateSetupNotifications", formData);
				dispatch(createUpdateProfileDetailsAction(values));
			}
		} catch (e) {
			//
		} finally {
			setIsFetch(false);
		}
	}, []);

	const adaptiveLeftColumnStyles = useAdaptive({
		xs: xsColumnStyles,
		sm: smLeftColumnStyles
	});

	const adaptiveRightColumnStyles = useAdaptive({
		xs: xsRightColumnStyles,
		sm: smRightColumnStyles
	});

	return (
		<Form
			validationSchema={validation}
			defaultValues={{
				mon_start: new Date(MonitoringStart || Date.now()),
				show_deals: ShowDeals,
				alerts_email: AlertMail,
				alerts_sms: AlertSms,
				project_close: Deactivate
			}}
			onSubmit={submit}
			fieldWrapperVariant="with-tip"
		>
			<Box
				sx={{
					border: "1px solid #e2e7e8",
					borderTop: 0,
					padding: "23px"
				}}
			>
				<Grid
					container
					flexDirection="row"
					flexWrap={isMobile ? "wrap" : "nowrap"}
					sx={{
						marginBottom: "20px"
					}}
				>
					<Grid item sx={adaptiveLeftColumnStyles}>
						<DatePicker name="mon_start" label={languageCopytrade.mon_start} />
					</Grid>
					<Grid item sx={adaptiveRightColumnStyles}>
						<Select name="show_deals" label={languageCopytrade.trd_196}>
							<Option value="0">{languageCopytrade.trd_306}</Option>
							<Option value="1">{languageCopytrade.trd_307}</Option>
							<Option value="2">{languageCopytrade.trd_308}</Option>
						</Select>
					</Grid>
				</Grid>
				<Box sx={{ marginBottom: "20px" }}>
					<Checkbox name="alerts_email" label={languageCopytrade.trd_197} variant="square" />
				</Box>
				<Box sx={{ marginBottom: "20px" }}>
					<Checkbox name="alerts_sms" label={languageCopytrade.trd_198} variant="square" />
				</Box>
				<Box sx={{ marginBottom: "20px" }}>
					<Checkbox name="project_close" label={languageCopytrade.trd_209} variant="square" />
				</Box>
			</Box>
			<ButtonWithLoader
				sxButton={{
					maxWidth: "240px",
					width: "100%"
				}}
				sx={{
					marginTop: "30px",
					maxWidth: "240px"
				}}
				color="success"
				isFetch={isFetch}
				text={languageInit.cs_06}
			/>
		</Form>
	);
}
