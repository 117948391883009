/*
    Created on : 20.01.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";

function PageTotalLots () {
	return (
		<div className="page-total-lots">
			<h1>Page Total Lots</h1>
		</div>
	);
}

export default PageTotalLots;
