import * as React from "react";
import { useIsRtl } from "../../utils/hooks/useIsRtl";
import { Box, Drawer } from "@mui/material";
import AppBarAuthButtonsMobile from "./dashboardComponents/AppBarAuthButtonsMobile";

export default function DashboardAuthSidebarMobile (props: any) {
	const { open, setOpen } = props;
	const isRtl = useIsRtl();
	const anchor = isRtl ? "right" : "left";
	return (
		<>
			<Drawer anchor={anchor} open={open} onClose={() => setOpen(!open)}>
				<Box sx={{ maxWidth: "250px" }}>
					<AppBarAuthButtonsMobile {... props} />
				</Box>
			</Drawer>
		</>
	);
}
