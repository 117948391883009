/*
    Created on : 12.11.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { Box, Button } from "@mui/material";
import { IBannerShowItem } from "../../../../store/reducers/bannersReducer";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../../interfaces/StoreInterfaces";
import { FMAlert } from "../../../../components/Form/Swal/FMAlert";

type IBanner = {
	banner: IBannerShowItem
	affiliateCode: string | undefined
}

export default function Banner (props: IBanner) {
	const { banner, affiliateCode } = props;
	const lang:any = useSelector((state:StoreInterface) => state.language.languageBanners);

	async function showCode () {
		const href = banner.href + "?affiliate_code=" + affiliateCode;
		const codeText = `
			<a href="${href}" target="_blank" style="outline: none">
				<img src="${banner.url}" width="${banner.width}" height="${banner.height}" alt="Forexmart" border="0" />
			</a>`;
		await FMAlert.fire({
			text: codeText
		});
	}

	return <Box sx={{
		m: "15px",
		border: "1px solid #ddd",
		borderRadius: "5px",
		display: "flex",
		flexDirection: "column"
	}}>
		<a href={banner.href} target="_blank" rel="noreferrer" style={{ outline: "none" }}>
			<img style={{ margin: "15px" }} id={banner.id} src={banner.url}/>
		</a>
		<Button
			variant="contained"
			color="success"
			sx={{ borderRadius: "0 0 5px 5px" }}
			onClick={showCode}
		>{lang.showcode}</Button>
	</Box>;
}
