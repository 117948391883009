/*
    Created on : 12.10.2022, 06:18:59
    Author     : Andy Kar
*/

import {
	SET_ACCOUNT_TYPE,
	SET_CHANGED_AVATAR,
	SET_CPY_AVATAR, SET_USER_BONUSES_INFO, SET_USER_CORP_DOCS_INFO, SET_USER_DOC_UPLOADING, SET_USER_DOCS_INFO,
	SET_USER_INFO, SET_USER_PAYMENT_METHODS_INFO, SET_USER_WITHDRAW_METHODS_INFO
} from "../actions/actionTypes";
import { UserDataInterface } from "../../interfaces/StoreInterfaces";

const initial = {
	user: {} as UserDataInterface,
	userPaymentMethods: [],
	userWithdrawMethods: [],
	userBonuses: {},
	userDocs: {},
	userCorpDocs: {},
	userDocUploading: false
};

export default function userReducer (state = initial, action:{type:string, payload:any}) {
	switch (action.type) {
		case SET_USER_INFO:
			return {
				...state,
				user: action.payload
			};
		case SET_USER_PAYMENT_METHODS_INFO:
			return {
				...state,
				userPaymentMethods: action.payload
			};
		case SET_USER_WITHDRAW_METHODS_INFO:
			return {
				...state,
				userWithdrawMethods: action.payload
			};
		case SET_USER_BONUSES_INFO:
			return {
				...state,
				userBonuses: action.payload
			};
		case SET_USER_DOCS_INFO:
			return {
				...state,
				userDocs: action.payload.user_doocument_data
			};
		case SET_USER_CORP_DOCS_INFO:
			return {
				...state,
				userCorpDocs: action.payload.user_doocument_data
			};
		case SET_USER_DOC_UPLOADING:
			return {
				...state,
				userDocUploading: action.payload.upload_data
			};
		case SET_CHANGED_AVATAR:
			return {
				...state,
				user: {
					...state.user,
					image: action.payload.image
				}
			};
		case SET_CPY_AVATAR:
			return {
				...state,
				user: {
					...state.user,
					mt_accounts_cpy_avatar: action.payload.mt_account_set_id
				}
			};
		case SET_ACCOUNT_TYPE:
			return {
				...state,
				user: {
					...state.user,
					account_type: action.payload
				}
			};

		default:
			return state;
	}
}
