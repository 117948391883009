/*
    Created on : 12.10.2021, 06:18:59
    Author     : Andy Kar
*/

import { MySubscriptions } from "../../interfaces/CopytradeInterfaces";
import { TradersItemInterface } from "../../interfaces/StoreInterfaces";
import { MyProjectData, Profile } from "../../interfaces/myAccountsInterfaces";
import { StatusCode } from "../../pages/Copytrade/MySubscriptions/types";
import {
	APPROVE_YOUR_FOLLOWER_SUBSCRIPTION,
	CLEAN_MY_SUBSCRIPTION_TRADERS,
	COMPLETE_CLOSE_TRADES_HISTORY,
	COMPLETE_OPEN_TRADES_HISTORY,
	SET_CLOSE_TRADES_PAGINATION,
	SET_COMMISSION_SETTINGS,
	SET_LENGTH_MONITORING_TRADERS,
	SET_LENGTH_SUBSCRIPTIONS_TRADERS,
	SET_LENGTH_TRADERS,
	SET_MY_PROJECT,
	SET_MY_SUBSCRIPTIONS_CONDITIONS,
	SET_MY_SUBSCRIPTIONS_YOUR_FOLLOWERS_FETCH,
	SET_MY_SUBSCRIPTION_FOLLOWERS_PAGINATION,
	SET_MY_SUBSCRIPTION_PAST_SUBSCRIPTIONS,
	SET_MY_SUBSCRIPTION_PAST_SUBSCRIPTIONS_FETCH,
	SET_MY_SUBSCRIPTION_PAST_SUBSCRIPTIONS_PAGINATION,
	SET_MY_SUBSCRIPTION_TRADERS,
	SET_MY_SUBSCRIPTION_TRADERS_FETCH,
	SET_MY_SUBSCRIPTION_TRADER_PAGINATION,
	SET_MY_SUBSCRIPTION_YOUR_FOLLOWERS,
	SET_OPEN_TRADES_PAGINATION,
	SET_PART_TRADERS,
	SET_PROFILE_INFORMATION,
	SET_SUBSCRIPTIONS_LOADING,
	SET_SUBSCRIPTIONS_TRADERS,
	SubscriptionStatus,
	UNSUBSCRIBE_TRADER_BY_CONNECTION_ID,
	UNSUBSCRIBE_YOUR_FOLLOWER,
	UPDATE_PROFILE_DETAILS,
	UPDATE_TRADER_SETTINGS,
	UPDATE_TRADER_SUBSCRIPTION_STATUS
} from "../actions/actionTypes";

type TCopytradeState = {
	loadingMonitoring: boolean,
	tradersLength: number,
	tradersAll: TradersItemInterface[] | null,
	tradersAllLength: number,
	loadingSubscriptions: boolean,
	tradersSubscriptions: [] | null,
	tradersSubscriptionsTotalLength: number,
	profile: Profile,
	myProject: MyProjectData,
	mySubscriptions: MySubscriptions,
	tradersSubscriptionsLength: number;
}

const initial: TCopytradeState = {
	loadingMonitoring: false,
	tradersLength: 0,
	tradersAll: null as [] | null,
	tradersAllLength: 0,
	loadingSubscriptions: false,
	tradersSubscriptions: null as [] | null,
	tradersSubscriptionsTotalLength: 0,
	tradersSubscriptionsLength: 0,
	profile: {} as Profile,
	myProject: {} as MyProjectData,
	mySubscriptions: {
		yourFollowers: {
			data: undefined,
			page: 1,
			limit: 10,
			count: 0,
			isFetch: false
		},
		traders: {
			data: undefined,
			page: 1,
			limit: 10,
			count: 0,
			isFetch: false
		},
		pastSubscriptions: {
			data: undefined,
			page: 1,
			limit: 10,
			count: 0,
			isFetch: false
		}
	}
};

export default function copytradeReducer (state: TCopytradeState = initial, action:{type:string, payload:any}): TCopytradeState {
	switch (action.type) {
		case SET_PART_TRADERS:
			return {
				...state,
				tradersAll: state.tradersAll ? state.tradersAll.concat(action.payload) : action.payload
			};
		case SET_LENGTH_TRADERS:
			return {
				...state,
				tradersAllLength: state.tradersAllLength + action.payload
			};
		case SET_LENGTH_MONITORING_TRADERS:
			return {
				...state,
				tradersLength: action.payload
			};
		case SET_SUBSCRIPTIONS_LOADING:
			return {
				...state,
				loadingSubscriptions: action.payload
			};
		case SET_LENGTH_SUBSCRIPTIONS_TRADERS:
			return {
				...state,
				tradersSubscriptionsLength: action.payload
			};
		case SET_SUBSCRIPTIONS_TRADERS:
			return {
				...state,
				tradersSubscriptions: action.payload
			};
		case SET_PROFILE_INFORMATION:
			return {
				...state,
				profile: action.payload
			};
		case UPDATE_PROFILE_DETAILS:
			return {
				...state,
				profile: {
					...state.profile,
					details: {
						...state.profile.details,
						...action.payload
					}
				}
			};
		case SET_MY_PROJECT:
			return {
				...state,
				myProject: {
					...action.payload
				}
			};
		case SET_MY_SUBSCRIPTION_FOLLOWERS_PAGINATION:
			return {
				...state,
				mySubscriptions: {
					...state.mySubscriptions,
					yourFollowers: {
						...state.mySubscriptions.yourFollowers,
						...action.payload
					}
				}
			};
		case APPROVE_YOUR_FOLLOWER_SUBSCRIPTION:
			return {
				...state,
				mySubscriptions: {
					...state.mySubscriptions,
					yourFollowers: {
						...state.mySubscriptions.yourFollowers,
						data: state.mySubscriptions.yourFollowers.data?.map((yourFollower) => {
							if (yourFollower.ConnectionId !== action.payload) {
								return yourFollower;
							}
							return {
								...yourFollower,
								StatusId: StatusCode.APPROVED
							};
						})
					}
				}
			};
		case UNSUBSCRIBE_YOUR_FOLLOWER:
			return {
				...state,
				mySubscriptions: {
					...state.mySubscriptions,
					yourFollowers: {
						...state.mySubscriptions.yourFollowers,
						data: state.mySubscriptions.yourFollowers.data?.filter((yourFollower) => yourFollower.ConnectionId !== action.payload),
						count: state.mySubscriptions.yourFollowers.count - 1
					}
				}
			};
		case UNSUBSCRIBE_TRADER_BY_CONNECTION_ID:
			return {
				...state,
				mySubscriptions: {
					...state.mySubscriptions,
					traders: {
						...state.mySubscriptions.traders,
						data: state.mySubscriptions.traders.data?.filter((trader) => trader.ConnectionId !== action.payload),
						count: state.mySubscriptions.traders.count - 1
					}
				}
			};
		case SET_COMMISSION_SETTINGS:
			return {
				...state,
				mySubscriptions: {
					...state.mySubscriptions,
					traders: {
						...state.mySubscriptions.traders,
						data: state.mySubscriptions.traders.data?.map((trader) => {
							if (trader.ConnectionId === action.payload.connectionId) {
								return {
									...trader,
									commissionSettings: action.payload.commissionSettings
								};
							}

							return trader;
						})
					}
				}
			};
		case CLEAN_MY_SUBSCRIPTION_TRADERS:
			return {
				...state,
				mySubscriptions: {
					...state.mySubscriptions,
					traders: {
						data: undefined,
						page: 1,
						limit: 10,
						count: 0,
						isFetch: false
					}
				}
			};
		case SET_MY_SUBSCRIPTION_YOUR_FOLLOWERS:
			return {
				...state,
				mySubscriptions: {
					...state.mySubscriptions,
					yourFollowers: {
						...state.mySubscriptions.yourFollowers,
						data: state.mySubscriptions.yourFollowers.data ? state.mySubscriptions.yourFollowers.data.concat(action.payload) : action.payload
					}
				}
			};
		case SET_MY_SUBSCRIPTION_TRADER_PAGINATION:
			return {
				...state,
				mySubscriptions: {
					...state.mySubscriptions,
					traders: {
						...state.mySubscriptions.traders,
						...action.payload
					}
				}
			};
		case SET_MY_SUBSCRIPTION_TRADERS:
			return {
				...state,
				mySubscriptions: {
					...state.mySubscriptions,
					traders: {
						...state.mySubscriptions.traders,
						data: state.mySubscriptions.traders.data ? state.mySubscriptions.traders.data.concat(action.payload) : action.payload
					}
				}
			};
		case SET_MY_SUBSCRIPTION_PAST_SUBSCRIPTIONS_PAGINATION:
			return {
				...state,
				mySubscriptions: {
					...state.mySubscriptions,
					pastSubscriptions: {
						...state.mySubscriptions.pastSubscriptions,
						...action.payload
					}
				}
			};
		case SET_MY_SUBSCRIPTION_PAST_SUBSCRIPTIONS:
			return {
				...state,
				mySubscriptions: {
					...state.mySubscriptions,
					pastSubscriptions: {
						...state.mySubscriptions.pastSubscriptions,
						data: state.mySubscriptions.pastSubscriptions.data ? state.mySubscriptions.pastSubscriptions.data.concat(action.payload) : action.payload
					}
				}
			};
		case SET_MY_SUBSCRIPTIONS_CONDITIONS:
			return {
				...state,
				mySubscriptions: {
					...state.mySubscriptions,
					traders: {
						...state.mySubscriptions.traders,
						data: state.mySubscriptions.traders.data?.map((value) => {
							if (value.ConnectionId === action.payload.connectionId) {
								return {
									...value,
									conditions: action.payload.data
								};
							}
							return value;
						})
					}
				}
			};
		case SET_MY_SUBSCRIPTIONS_YOUR_FOLLOWERS_FETCH:
			return {
				...state,
				mySubscriptions: {
					...state.mySubscriptions,
					yourFollowers: {
						...state.mySubscriptions.yourFollowers,
						isFetch: action.payload
					}
				}
			};
		case SET_MY_SUBSCRIPTION_TRADERS_FETCH:
			return {
				...state,
				mySubscriptions: {
					...state.mySubscriptions,
					traders: {
						...state.mySubscriptions.traders,
						isFetch: action.payload
					}
				}
			};
		case SET_MY_SUBSCRIPTION_PAST_SUBSCRIPTIONS_FETCH:
			return {
				...state,
				mySubscriptions: {
					...state.mySubscriptions,
					pastSubscriptions: {
						...state.mySubscriptions.pastSubscriptions,
						isFetch: action.payload
					}
				}
			};
		case COMPLETE_OPEN_TRADES_HISTORY:
			return {
				...state,
				mySubscriptions: {
					...state.mySubscriptions,
					traders: {
						...state.mySubscriptions.traders,
						data: state.mySubscriptions.traders.data
							? state.mySubscriptions.traders.data.map((trader) => {
								if (trader.ConnectionId === action.payload.connectionId) {
									const prevTradesHistory = trader.tradesHistory || {};
									const prevOpenTrades = prevTradesHistory.openTrades || {};
									const data = prevOpenTrades.data;

									return {
										...trader,
										tradesHistory: {
											...prevTradesHistory,
											openTrades: {
												...prevOpenTrades,
												data: data ? data.concat(action.payload.data) : action.payload.data
											}
										}
									};
								}
								return trader;
							})
							: undefined

					}
				}
			};
		case SET_OPEN_TRADES_PAGINATION:
			return {
				...state,
				mySubscriptions: {
					...state.mySubscriptions,
					traders: {
						...state.mySubscriptions.traders,
						data: state.mySubscriptions.traders.data
							? state.mySubscriptions.traders.data.map((trader) => {
								if (trader.ConnectionId === action.payload.connectionId) {
									const prevTradesHistory = trader.tradesHistory || {};
									const prevOpenTrades = prevTradesHistory.openTrades || {};

									return {
										...trader,
										tradesHistory: {
											...prevTradesHistory,
											openTrades: {
												...prevOpenTrades,
												page: action.payload.data.page,
												limit: action.payload.data.limit,
												count: action.payload.data.count
											}
										}
									};
								}

								return trader;
							})
							: undefined
					}
				}
			};
		case COMPLETE_CLOSE_TRADES_HISTORY:
			return {
				...state,
				mySubscriptions: {
					...state.mySubscriptions,
					traders: {
						...state.mySubscriptions.traders,
						data: state.mySubscriptions.traders.data
							? state.mySubscriptions.traders.data.map((trader) => {
								if (trader.ConnectionId === action.payload.connectionId) {
									const prevTradesHistory = trader.tradesHistory || {};
									const prevCloseTrades = prevTradesHistory.closeTrades || {};
									const data = prevCloseTrades.data;

									return {
										...trader,
										tradesHistory: {
											...prevTradesHistory,
											closeTrades: {
												...prevCloseTrades,
												data: data ? data.concat(action.payload.data) : action.payload.data
											}
										}
									};
								}
								return trader;
							})
							: undefined

					}
				}
			};
		case SET_CLOSE_TRADES_PAGINATION:
			return {
				...state,
				mySubscriptions: {
					...state.mySubscriptions,
					traders: {
						...state.mySubscriptions.traders,
						data: state.mySubscriptions.traders.data
							? state.mySubscriptions.traders.data.map((trader) => {
								if (trader.ConnectionId === action.payload.connectionId) {
									const prevTradesHistory = trader.tradesHistory || {};
									const prevCloseTrades = prevTradesHistory.closeTrades || {};

									return {
										...trader,
										tradesHistory: {
											...prevTradesHistory,
											closeTrades: {
												...prevCloseTrades,
												page: action.payload.data.page,
												limit: action.payload.data.limit,
												count: action.payload.data.count
											}
										}
									};
								}

								return trader;
							})
							: undefined
					}
				}
			};
		case UPDATE_TRADER_SETTINGS:
			return {
				...state,
				mySubscriptions: {
					...state.mySubscriptions,
					traders: {
						...state.mySubscriptions.traders,
						data: state.mySubscriptions.traders.data
							? state.mySubscriptions.traders.data.map((trader) => {
								if (trader.ConnectionId === action.payload.connectionId) {
									return {
										...trader,
										conditions: action.payload.conditions
									};
								}

								return trader;
							})
							: undefined
					}
				}
			};
		case UPDATE_TRADER_SUBSCRIPTION_STATUS:
			return {
				...state,
				tradersAll: state.tradersAll?.map((trader) => {
					if (trader.UserId === action.payload.userId) {
						return {
							...trader,
							SubscriptionStatus: action.payload.status ?? SubscriptionStatus.SUBSCRIBED
						};
					}

					return trader;
				}) || null
			};
		default:
			return state;
	}
}
