import * as React from "react";
import { Grid, Typography } from "@mui/material";
import { useIsRtl } from "../../../../utils/hooks/useIsRtl";
import { ReactComponent as NoteIcon } from "./WarningNoteIcon.svg";
import { INoteProps } from "../types";

export function WaningNote (props: INoteProps): JSX.Element {
	const { message } = props;
	const isRtl = useIsRtl();

	return (
		<Grid
			container
			flexWrap="nowrap"
			flexDirection={isRtl ? "row-reverse" : "row"}
			alignItems="center"
		>
			<Grid
				item
				sx={{ [isRtl ? "marginLeft" : "marginRight"]: "14px" }}
			>
				<NoteIcon width="26px" height="26px" />
			</Grid>
			<Typography
				align={isRtl ? "right" : "left"}
				color="#F7931A"
				className="light"
			>
				{message}
			</Typography>
		</Grid>
	);
}
