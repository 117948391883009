/*
    Created on : 22.11.2023, 06:18:59
    Author     : Andy Kar
*/
import { takeEvery, put, call } from "redux-saga/effects";
import { ResponseGenerator } from "../interfaces/mainInterfaces";
import API from "../utils/API";
import {
	FETCH_MY_ACHIEVEMENTS_INFO,
	REQUEST_MY_ACHIEVEMENTS, SET_MY_ACHIEVEMENTS_INFO

} from "../store/actions/actionTypes";

export type IButtonClassText = (string | null) [] | {
	"1": string | null;
	"0": string | null;
}
export interface IPrize {
	"id": string;
	"lots": string;
	"type": string;
	"type_info": string;
	"created": string;
	"status": string;
	"comments": string;
}
export interface IMyAchievementsInfo {
	total_lots: number;
	start_data: string;
	prize_info: {
		"2000": string;
		"350": string;
		"250": string;
		"150": string;
		"75": string;
		"5": string;
	}
	"prize_name": {
		"2000": string;
		"350": string;
		"250": string;
		"150": string;
		"75": string;
		"5": string;
	}
	"prize_rejected": IPrize[];
	"button_class": {
		"5": IButtonClassText;
		"75": IButtonClassText;
		"150": IButtonClassText;
		"250": IButtonClassText;
		"350": IButtonClassText;
		"2000": IButtonClassText;
	}
	"button_text": {
		"5": IButtonClassText;
		"75": IButtonClassText;
		"150": IButtonClassText;
		"250": IButtonClassText;
		"350": IButtonClassText;
		"2000": IButtonClassText;
	},
	"prize_completed": IPrize[];
	"button_group_class": { [key: string]: string }
	prize_wait: IPrize[];
	initRequestsData: string;
	requst_count: number;
	available_lots: number
	excluded: boolean;
	status: boolean;
}

/**
 Get My Achievements data from server and set it to store
 **/

export function * myAchievementsWatcher () {
	yield takeEvery(FETCH_MY_ACHIEVEMENTS_INFO, myAchievementsWorker);
}

export function * myAchievementsWorker () {
	const data: IMyAchievementsInfo = yield call(getMyAchievementsInfo);
	if (Object.keys(data).length) {
		yield put({ type: SET_MY_ACHIEVEMENTS_INFO, payload: data });
	}
}

async function getMyAchievementsInfo () {
	const response:ResponseGenerator = await API.get("myAchievements");
	return response?.data || {};
};

/**
 Request My Achievements Prizes
 **/

export function * requestMyAchievementsWatcher () {
	yield takeEvery(REQUEST_MY_ACHIEVEMENTS, requestMyAchievementsWorker);
}

export function * requestMyAchievementsWorker () {
	const data: { success?: boolean } = yield call(requestMyAchievements);
	if (Object.keys(data).length && data.success) {
		const data: IMyAchievementsInfo = yield call(getMyAchievementsInfo);
		if (Object.keys(data).length) {
			yield put({ type: SET_MY_ACHIEVEMENTS_INFO, payload: data });
		}
	}
}

async function requestMyAchievements () {
	const response:ResponseGenerator = await API.post("myAchievements");
	return response?.data || {};
}
