/*
    Created on : 05.12.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { IFMTableComponentProps } from "../../../../components/FMTable/FMTableCellComponent";
import { ILogo } from "../../../../store/reducers/bannersReducer";
import { ILogoCompData } from "../PagePromotionalMaterialsLogo";
import { Box } from "@mui/material";

export default function LogoCell (props: IFMTableComponentProps<ILogo, ILogoCompData>) {
	const { componentData, svg } = props;
	// const lang:any = useSelector((state:StoreInterface) => state.language.languageBanners);
	console.log("LogoCell", props);
	return <Box sx={{
		textAlign: "left",
		p: "5px 10px"
	}}>
		<img style={{ maxWidth: "100%" }} src={componentData?.url_images + svg}/>
	</Box>;
}
