import { CurrenciesSymbols, CurrencyType } from "./currency";

export function getReceivedValue (values: [string[], string, CurrencyType], defaultCurrency: CurrencyType) {
	const [ bonuses, amount, currencyType ] = values;
	const bonusSum = Object
		.values(bonuses)
		.reduce((
			pervBonus: string,
			currentBonus: string
		) => {
			return +pervBonus + +currentBonus + "";
		}, "1");

	return `${(+bonusSum * +amount).toFixed(2)}${CurrenciesSymbols[currencyType] || CurrenciesSymbols[defaultCurrency]}`;
}

export function getWithdrawalValue (values: [string, CurrencyType], defaultCurrency: CurrencyType, fractionDigits = 2) {
	const [ amount, currencyType ] = values;
	const currency = CurrenciesSymbols[currencyType] || CurrenciesSymbols[defaultCurrency];
	return isNaN(+amount) ? `0 ${currency}` : `${(+amount).toFixed(fractionDigits)}${currency}`;
}
