import * as React from "react";
import { Box, Tabs } from "@mui/material";
import { mainTheme } from "../../../../theme/main.theme";
import { NumberLabel } from "./NumberLabel";
import { Tab } from "./Tab";
import { Line } from "../helpers/Line";
import { ITabsProps } from "../types";
import { useIsMobile } from "../../../../utils/hooks/useIsMobile";

export function NumberedTabs (props: ITabsProps) {
	const { labels, children } = props;

	const [ value, setValue ] = React.useState(0);
	const isMobile = useIsMobile();

	const handleChange = React.useCallback((event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	}, [ setValue ]);

	return (
		<>
			<Tabs
				value={value}
				onChange={handleChange}
				variant="fullWidth"
				textColor="inherit"
				orientation={isMobile ? "vertical" : "horizontal" }
				sx={{
					borderBottom: "1px"
				}}
				TabIndicatorProps={{
					style: {
						background: isMobile ? "transparent" : mainTheme.palette.success.main
					}
				}}
			>
				{
					labels.map((label, index) => (
						<Tab
							sx={{
								backgroundColor: isMobile ? "#F1F9FF" : "transparent",
								padding: isMobile ? "0 5px" : "16px 9px",
								minHeight: isMobile ? "40px" : "72px",
								marginBottom: isMobile ? "8px" : "0",
								"&.Mui-selected": {
									backgroundColor: isMobile ? "#2988CA" : "transparent",
									color: isMobile ? "#fff" : mainTheme.palette.success.main
								}
							}}
							key={`${label}-${index}`}
							iconPosition="start"
							icon={<NumberLabel value={index + 1} selected={value === index} />}
							label={label}
						/>
					))
				}

				<Line canHide />
			</Tabs>
			<Box
				sx={{
					padding: isMobile ? "15px" : "37px",
					paddingBottom: isMobile ? "20px" : "55px",
					borderLeft: "1px solid #E2E7E8",
					borderRight: "1px solid #E2E7E8",
					borderBottom: "1px solid #E2E7E8",
					borderTop: isMobile ? "1px solid #E2E7E8" : "0"
				}}
			>
				{Array.isArray(children)
					? children.map((child, index) => (
						<Box
							key={index}
							sx={{
								display: value === index ? "block" : "none"
							}}
						>
							{child}
						</Box>
					))
					: children}
			</Box>
		</>
	);
}
