/*
    Created on : 01.02.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import "./PagePersonalDetailsEdit.scss";
import PersonalDetailsForm from "../../../components/Forms/PersonalDetailsForm/PersonalDetailsForm";

export default function PagePersonalDetailsEdit () {
	return (
		<div className="page-personal-details-edit">
			<PersonalDetailsForm/>
		</div>
	);
};
