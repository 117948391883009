/*
    Created on : 13.06.2021, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { Box } from "@mui/material";
import ForexmartLogoInt from "../../Icons/ForexmartLogoInt";

export default function DashboardAuthAppBarWrapper ({ children }: { children: JSX.Element | JSX.Element[]}) {
	return (
		<Box sx={{
			background: "#fff"
		}}>
			<Box
				className="flexRow"
				sx={{
					justifyContent: "space-between",
					alignItems: "center",
					minHeight: "99px",
					maxWidth: "1103px",
					width: "100%",
					padding: "0 15px",
					margin: "0 auto"
				}}
			>
				<Box sx={{ marginX: "20px" }}>
					<ForexmartLogoInt/>
				</Box>
				<Box className="flexRow" sx={{ alignItems: "center", flex: 1, justifyContent: "flex-end" }}>
					{children}
				</Box>
			</Box>
		</Box>
	);
};
