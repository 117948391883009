/*
    Created on : 25.10.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import AccountTypes from "../components/AccountTypes/AccountTypes";
import LayoutSmartDollar from "../LayoutSmartDollar/LayoutSmartDollar";
import { ActivateSmartDollars } from "../../pages/SmartDollar/ActivateSmartDollars";
import LayoutMyAchievements from "../LayoutMyAchievements/LayoutMyAchievements";

const LoyaltyPrograms = () => {
	return (
		<div className="layout layout-smart-dollar">
			<Switch>
				<Route exact path="/app/loyalty-programs/smart-dollars" component={LayoutSmartDollar}/>
				<Route exact path="/app/loyalty-programs/smart-dollars/activate" component={ActivateSmartDollars}/>
				<Route exact path="/app/loyalty-programs/my-achievements" component={LayoutMyAchievements}/>
			</Switch>
			<AccountTypes/>
		</div>
	);
};

export default withRouter(LoyaltyPrograms);
