import React from "react";
import { DatePicker } from "../../components/Form/DatePicker";
import { Box } from "@mui/material";
import { Select } from "../Form/Select";
import { TSetPeriod } from "./FMTablePeriodButtons";
import { ISetState } from "../../types/common";
import { IFMTableTheme, useFMTableTheme } from "./FMTableThemes";

interface IFMTablePeriodSelect {
	period: number
	setPeriod: TSetPeriod
	periodsNames: string[]
	dateFrom: any
	dateTo: any
	setDateFrom: ISetState
	setDateTo: ISetState
}

export default function FMTablePeriodSelect (props: IFMTablePeriodSelect) {
	const { period, setPeriod, periodsNames, dateFrom, dateTo, setDateFrom, setDateTo } = props;

	const renderOptions = () => {
		return periodsNames.map((option:string, index:number) => {
			if (index) {
				return <option key={index} value={index}>{option}</option>;
			} else {
				return <option style={{ display: "none" }} key={index} value={index}>{option}</option>;
			}
		});
	};

	const theme: IFMTableTheme = useFMTableTheme();
	const boxSx = !theme.withPeriodSelect ? tradingThemeSx : {};

	return (
		<Box className="flexRow" sx={{ "&>*": { mb: "15px" }, ...boxSx }}>
			{theme.withPeriodSelect && <Select
				extValue={period ? period.toString() : "0"}
				name="period"
				label="Choose Period:"
				onChange={(e) => { setPeriod(parseInt(e.target.value)); }}
			>
				{renderOptions()}
			</Select>}
			<DatePicker
				extValue={dateFrom}
				extOnChange={setDateFrom}
				name="dateFrom"
				label="Date From:"
			/>
			<DatePicker
				extValue={dateTo}
				extOnChange={setDateTo}
				name="DateTo"
				label="Date To:"
			/>
		</Box>
	);
};

const tradingThemeSx = {
	width: "100%",
	"& > div": {
		flex: 1
	}
};
