import * as Yup from "yup";

interface ValidationSchemaArgs {
	field_err_01: string;
}

export const validationSchema = (laguage: ValidationSchemaArgs) => Yup.object().shape({
	amount: Yup.number().required(laguage.field_err_01).typeError("It must be a number"),
	transferTo: Yup.string().required(laguage.field_err_01)
});
