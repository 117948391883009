import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box, InputBase, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { styled } from "@mui/styles";

export interface FMTableToolbarRowsPerPageProps {
	className?: string
	rowsPerPage: number
	handleChangeRowsPerPage: any
	rowsPerPageOptions: number[]
}

const BootstrapInput = styled(InputBase)(() => ({
	"& .MuiInputBase-input": {
		borderRadius: 4,
		position: "relative",
		border: "1px solid #5C7080",
		fontSize: 14,
		padding: "10px 26px 10px 12px",
		"&:focus": {
			backgroundColor: "#fff"
		}
	}
}));

export default function FMTableToolbarRowsPerPage (props:FMTableToolbarRowsPerPageProps) {
	const {
		className,
		rowsPerPage,
		handleChangeRowsPerPage,
		rowsPerPageOptions
	} = props;

	const handleChange = (event: SelectChangeEvent) => {
		handleChangeRowsPerPage(event.target.value as string);
	};

	const renderOptions = () => {
		return rowsPerPageOptions.map((value, index) => {
			return (
				<MenuItem
					key={index}
					value={value}
					sx={{
						textAlign: "center",
						fontSize: "14px",
						cursor: "pointer"
					}}
				>
					{value}
				</MenuItem>
			);
		});
	};

	return (
		<Box
			className={"flexRow " + className}
			sx={{ alignItems: "center" }}
		>
			<Typography>Show</Typography>
			<Select
				input={<BootstrapInput/>}
				value={rowsPerPage.toString()}
				onChange={handleChange}
				sx={{
					width: "70px",
					marginX: "10px"
				}}
			>
				{renderOptions()}
			</Select>
			<Typography>entries</Typography>
		</Box>
	);
};
