import * as React from "react";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks/reduxHooks";
import { Form } from "../../Form";
import { withdrawMobileMoneyValidationSchema } from "./validation";
import { Input } from "../../Form/Input";
import { InputRepeater } from "../../Form/InputRepeater";
import { ButtonWithLoader } from "../../ButtonWithLoader";
import { FormBottomWrapper } from "../../Form/FormBottomWrapper";
import { FinanceFormProps } from "../types";
import { Alert, Box, Skeleton, Snackbar } from "@mui/material";
import { useErrorHandling } from "../../../utils/hooks/useErrorHandling";
import { MobileMoneyFormError } from "../../../utils/errors/MobileMoneyFormError";
import { MobileMoneyNames, TMobileMoneyNames } from "../../../store/reducers/withdrawMethodsInfoReducer";
import {
	fetchMobileMoneyAirtelInfo,
	fetchMobileMoneyEuMobileInfo,
	fetchMobileMoneyHalopesaInfo,
	fetchMobileMoneyMpesaInfo,
	fetchMobileMoneyMtnInfo,
	fetchMobileMoneyOrangeInfo,
	fetchMobileMoneyTigoInfo,
	fetchMobileMoneyVodacomInfo,
	fetchMobileMoneyVodafoneInfo,
	fetchMobileMoneyZamtelInfo
} from "../../../store/actions/actionTypes";
import { WaningNote } from "../../UI/Note";

const actions = {
	[MobileMoneyNames.TIGO]: fetchMobileMoneyTigoInfo({}),
	[MobileMoneyNames.AIRTEL]: fetchMobileMoneyAirtelInfo({}),
	[MobileMoneyNames.MTN]: fetchMobileMoneyMtnInfo({}),
	[MobileMoneyNames.VODACOM]: fetchMobileMoneyVodacomInfo({}),
	[MobileMoneyNames.VODAFONE]: fetchMobileMoneyVodafoneInfo({}),
	[MobileMoneyNames.MPESA]: fetchMobileMoneyMpesaInfo({}),
	[MobileMoneyNames.ZAMTEL]: fetchMobileMoneyZamtelInfo({}),
	[MobileMoneyNames.HALOPESA]: fetchMobileMoneyHalopesaInfo({}),
	[MobileMoneyNames.ORANGE]: fetchMobileMoneyOrangeInfo({}),
	[MobileMoneyNames.EU_MOBILE]: fetchMobileMoneyEuMobileInfo({})

};

export type TWithdrawMobileMoneyFormValues = {
	accountNumber: string,
	amount: string,
	bankCurrency: string,
	bankAccountName: string,
	bankAccountNumber: string,
	bankName: string,
	branch: string,
	bankCode: string,
	province: string,
	city: string
}
export interface WithdrawMobileMoneyFormProps extends FinanceFormProps<TWithdrawMobileMoneyFormValues> {
	bankName?: string;
	bankType: keyof TMobileMoneyNames;
}
export function WithdrawMobileMoneyForm (props: WithdrawMobileMoneyFormProps) {
	const {
		submit: _submit,
		bankName = "",
		bankType
	} = props;
	const [ success, setSuccess ] = React.useState(false);
	const [ isFetch, setIsFetch ] = React.useState(false);
	const accountNumber = useAppSelector((state) => state.user.user.account_number);
	const balance = useAppSelector((state) => state.account.funds.balance);
	const {
		fee,
		feeDetails,
		addon,
		bankCode = "",
		loading: infoLoading,
		bankName: _bankName = "",
		complete
	} = useAppSelector((state) => state.withdrawMethodsInfo.mobileMoney[bankType]);
	const dispatch = useAppDispatch();
	const language = useAppSelector((state) => state.language);
	const { isOpen, errorMsg, hideError, showError } = useErrorHandling();

	React.useEffect(() => {
		const action = actions[MobileMoneyNames[bankType]];
		if (action && !complete) {
			dispatch(action);
		}
	}, []);
	async function submit (values: TWithdrawMobileMoneyFormValues) {
		try {
			setIsFetch(true);

			await _submit({ ...values, bankCode, bankName: _bankName });
			setSuccess(true);
		} catch (e) {
			if (e instanceof MobileMoneyFormError) {
				showError(e.message);
			} else {
				showError("Something went wrong");
			}
		} finally {
			setIsFetch(false);
		}
	}

	function calcNewBalance (values: [string]): string {
		const [ withdrawalAmount ] = values;
		const FEE = fee ?? 0;
		const _fee = +withdrawalAmount * FEE;
		const _balance = +(balance ?? 0);
		return `${isNaN(parseInt(withdrawalAmount)) ? _balance : (_balance - +withdrawalAmount - _fee - addon).toFixed(3)} USD`;
	}

	function calcFullAmount (values: [string]): string {
		const [ withdrawalAmount ] = values;
		const FEE = fee ?? 0;
		const _fee = +withdrawalAmount * FEE;

		return `${isNaN(parseInt(withdrawalAmount)) ? 0 : (+withdrawalAmount + _fee + addon).toFixed(3)} USD`;
	}

	return (
		<>
			<Form
				onSubmit={submit}
				validationSchema={withdrawMobileMoneyValidationSchema()}
				defaultValues={{
					accountNumber,
					amount: "",
					bankCurrency: "UGX",
					bankAccountName: "",
					bankAccountNumber: "",
					bankName,
					branch: "",
					city: "",
					province: ""
				}}
			>
				<Input
					name="accountNumber"
					label={"Account Number"}
				/>
				<Input
					name="amount"
					label={"Withdraw amount"}
				/>
				<InputRepeater
					fields="amount"
					label={"New balance"}
					onChange={calcNewBalance}
				/>
				<Input
					name="bankCurrency"
					label={"Bank currency"}
				/>
				<Input
					name="bankAccountName"
					label={"Bank account name"}
				/>
				<Input
					name="bankAccountNumber"
					label={"Bank account number"}
				/>
				<Input
					name="bankName"
					label={"Bank name"}
				/>
				<Input
					name="branch"
					label={"Branch"}
				/>
				<Input
					name="province"
					label={"Province"}
				/>
				<Input
					name="city"
					label={"City"}
				/>
				<FormBottomWrapper
					forFields={[ "amount" ]}
					onChange={calcFullAmount}
					label={language.languageMyAccounts.form_message_01}
				>
					<ButtonWithLoader
						isFetch={isFetch}
						color={"primary"}
						text={language.languageMyAccounts.chapas_04}
					/>
				</FormBottomWrapper>
			</Form>
			<Box sx={{ marginTop: "6px" }}>
				{
					infoLoading
						? (
							<Skeleton width={"220px"} height={"26px"} />
						)
						: (
							<WaningNote
								message={`Fee is ${feeDetails}`}
							/>
						)
				}
			</Box>
			<Snackbar
				open={success}
				autoHideDuration={4000}
				onClose={() => setSuccess(false)}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
			>
				<Alert variant="filled" color="success">
					Success
				</Alert>
			</Snackbar>
			<Snackbar open={isOpen} autoHideDuration={4000} onClose={hideError}>
				<Alert variant="filled" color="error">
					{errorMsg}
				</Alert>
			</Snackbar>
		</>
	);
}
