import { ReactComponent as BCA } from "./cbca.svg";
import { ReactComponent as BNI } from "./cbni.svg";
import { ReactComponent as BRI } from "./cbri.svg";
import { ReactComponent as DANAMON } from "./cdanamon.svg";
import { ReactComponent as MANDIRI } from "./cmandiri.svg";

export const IDRIcons = {
	BCA,
	BNI,
	BRI,
	DANAMON,
	MANDIRI
};
