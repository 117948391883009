import * as React from "react";
import Toolbar from "@mui/material/Toolbar";
import FMTableToolbarRowsPerPage from "./FMTableToolbarRowsPerPage";
import FMTableToolbarSearch from "./FMTableToolbarSearch";
import FMTablePeriodButtons, { TSetPeriod } from "./FMTablePeriodButtons";
import { IFMTable } from "./IFMTable";
import { useAdaptive } from "../../utils/hooks/themeBreakpointsHooks";
import { ISetState } from "../../types/common";

interface FMTableToolbarProps<T, M> extends IFMTable<T, M>{
	rowsPerPage: number
	handleChangeRowsPerPage: ISetState
	rowsPerPageOptions: number[]
	filter: string
	setFilter: ISetState
	period: number
	setPeriod: TSetPeriod
	periodsNames: string[]
}

export default function FMTableToolbar<T, M> (props:FMTableToolbarProps<T, M>) {
	const { rowsPerPage, handleChangeRowsPerPage, rowsPerPageOptions, filter, setFilter, period, setPeriod, periodsNames = [], withSearch = true, withPeriodButtons, withRowsPerPage } = props;

	const toolbarSx = useAdaptive({
		xs: {
			display: "block",
			justifyContent: "space-between",
			alignItems: "center",
			marginBottom: "16px",
			"&.MuiToolbar-root": {
				padding: 0,
				minHeight: 0
			},
			"&>.periodButtonGroup": {
				width: "100%",
				marginX: 0,
				mb: "22px"
			},
			"&>.rowsPerPage": {
				justifyContent: "center",
				mb: "11px"
			},
			"&>.toolbarSearch div": {
				flex: "1"
			}
		},
		sm: {
			display: "flex",
			flexWrap: "wrap",
			"&>.rowsPerPage": {
				mb: 0
			},
			"&>.toolbarSearch div": {
				flex: "0 0 auto"
			}
		},
		md: {
			flexWrap: "nowrap",
			"&>.periodButtonGroup": {
				width: "max-content",
				marginX: "10px",
				mb: 0
			},
			"&>.rowsPerPage": {
				order: -1
			}
		}
	});

	return (
		<> { (withSearch || withPeriodButtons || rowsPerPageOptions.length > 1) &&
			<Toolbar className="flexRow" sx={toolbarSx}>
				{withPeriodButtons && <FMTablePeriodButtons
					className="periodButtonGroup"
					period={period}
					setPeriod={setPeriod}
					periodsNames={periodsNames}
				/>}
				{withRowsPerPage && <FMTableToolbarRowsPerPage
					className="rowsPerPage"
					rowsPerPage={rowsPerPage}
					handleChangeRowsPerPage={handleChangeRowsPerPage}
					rowsPerPageOptions={rowsPerPageOptions}
				/>}
				{withSearch && <FMTableToolbarSearch
					className="toolbarSearch"
					filter={filter}
					setFilter={setFilter}
				/>}
			</Toolbar>
		}</>
	);
};
