import { createDepositApiRequest } from "../../../../utils/createDepositApiRequest";
import { PaymentMethod } from "../types";

export type PaycoInfoType = {
	MID: string;
	MWALLET: string;
	desc: string;
	curr: string;
	amt: string;
	TID: string;
	SEP: string;
	SECRET: string;
	SIGN: string;
	urlaction: string;
	choice: number;
}

export const getInfo = createDepositApiRequest<PaycoInfoType>("depositPayco", PaymentMethod.PAYCO);
