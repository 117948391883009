import * as React from "react";
import { Grid } from "@mui/material";
import "./infoBar.scss";
import { InfoBarItem } from "./InfoBarItem";
import { useIsRtl } from "../../../../utils/hooks/useIsRtl";
import { useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { CurrenciesSymbols } from "../../../../utils/currency";

export interface InfoBarProps {
	extended?: boolean;
}

export function InfoBar (props: InfoBarProps) {
	const { extended } = props;
	const isRtl = useIsRtl();
	const language = useAppSelector((state) => state.language);
	const currency = useAppSelector((state) => state.session.userData.currency ?? "USD");
	const balance = useAppSelector((state) => state.init.init.require_data.account_fund_info?.balance);
	const { withrawableRealFund, withrawableBonusFund, bonusFund } = useAppSelector((state) => state.init.init.require_data.account_fund_info);

	return (
		<Grid
			container
			flexDirection={isRtl ? "row-reverse" : "row"}
			sx={{
				border: "1px solid #707070",
				overflow: "hidden",
				flexWrap: "nowrap"
			}}
		>
			<InfoBarItem
				value={`${(balance).toFixed(2)}${CurrenciesSymbols[currency]}`}
				label={language.languageCopytrade.cpy_bal}
				first
			/>
			{
				extended
					? (
						<>
							<InfoBarItem
								value={`${(withrawableRealFund + withrawableBonusFund).toFixed(2)}${CurrenciesSymbols[currency]}`}
								label={language.languageFinance.ibar_01}
							/>
							<InfoBarItem
								value={`${(bonusFund).toFixed(2)}${CurrenciesSymbols[currency]}`}
								label={language.languageBonus.bon_tab_00}
							/>
						</>
					)
					: (<></>)
			}
		</Grid>
	);
}
