import * as React from "react";
import { Box, InputBase, Typography } from "@mui/material";

interface FMTableToolbarSearchProps {
	className?: string
	filter: string
	setFilter: any
}

export default function FMTableToolbarSearch (props:FMTableToolbarSearchProps) {
	const { filter, setFilter, className } = props;

	return (
		<Box
			className={"flexRow " + className}
			sx={{ alignItems: "center" }}
		>
			<Typography sx={{ marginX: "5px" }}>Search:</Typography>
			<InputBase
				value={filter}
				onChange={(event) => setFilter(event.target.value)}
				sx={{
					marginX: "5px",
					"& .MuiInputBase-input": {
						width: "100%",
						// maxWidth: "170px",
						height: "45px",
						padding: "0 10px",
						border: "1px solid #5C7080",
						borderRadius: "4px"
					}
				}}
			/>
		</Box>
	);
};
