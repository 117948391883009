import { StatusCode } from "../pages/Copytrade/MySubscriptions/types";

export const EUR_USD = 4;
export const GBP_USD = 8;
export const USD_JPY = 16;
export const USD_CHF = 32;
export const USD_CAD = 64;
export const AUD_USD = 128;
export const NZD_USD = 256;
export const EUR_JPY = 512;
export const EUR_CHF = 1024;
export const EUR_GBP = 2048;

export type CurrencyCode = typeof EUR_USD | typeof GBP_USD | typeof USD_JPY | typeof USD_CHF | typeof USD_CAD | typeof AUD_USD | typeof NZD_USD | typeof EUR_JPY | typeof EUR_CHF | typeof EUR_GBP;
export type TPaginationData = {
	page?: number;
	limit?: number;
	count?: number;
};

export interface OpenTradesHistoryRow {
	DT_RowId: number,
	CopyId: number,
	CopyStatus: string | null;
	MasterTicket: string | null;
	Ticket: string | null;
	Symbol: string | null;
	CmdStatus: string | null;
	Volume: string | null
	OpenPrice: string | null;
	ClosePrice: string | null;
	OpenTimed: string | null;
	Sl: string | null;
	Tp: string | null;
	Profit: string | null;
}

export interface CloseTradesHistoryRow extends OpenTradesHistoryRow {
	Commission_T: string | null;
}

export interface TradesHistory {
	openTrades: {
		data?: OpenTradesHistoryRow[]
	} & TPaginationData;
	closeTrades: {
		data?: CloseTradesHistoryRow[]
	} & TPaginationData;
}

export interface Conditions {
	copysetting_1: string | null;
	copysetting_2: string | null;
	copysetting_3: string | null;
	copysetting_5: string | null;
	copysetting_6: string | null;
	copysetting_7: string | null;
	copysetting_8: string | null;
	copysetting_9: string | null;
	currency_code: CurrencyCode[] | null;
	test: 1
}

export interface CommissionSettings {
	td_2?: string; // Commission per 0.01 lots (profitable trades)
	td_3?: string; // Profit share (in %)
	td_4?: string; // Daily commission
	td_7?: string; // Spread commission for company type
	td_10?: string; // Commission per 0.01 lots (all trades)
}

export interface Trader {
	ConnectionId: number;
	Follower: number;
	CreateTime: string | null;
	LastModify: string | null;
	StatusId: StatusCode;
	RollOverStatus: number;
	RollOverLogo: string | null;
	RolloverNote: boolean;
	PendingRollOver: number;
	StartBalance: string | null;
	StatusDesc: string | null;
	Trader: number;
	conditions?: Conditions;
	tradesHistory: TradesHistory;
	commissionSettings?: CommissionSettings;
}

export interface PastSubscription extends Trader {}

export interface YourFollower extends Trader {
	ConditionType: number;
}

export type TWithPagination<T> = {
	data?: T,
	page: number;
	limit: number;
	count: number;
	isFetch: boolean;
}

export interface MySubscriptions {
	yourFollowers: TWithPagination<YourFollower[]>,
	traders: TWithPagination<Trader[]>,
	pastSubscriptions: TWithPagination<PastSubscription[]>,
}
