import React, { useState } from "react";
import { Form } from "../formComponents/Form/Form";
import { useForm } from "react-hook-form";
import { Input } from "../formComponents/Input/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import { StoreInterface } from "../../../interfaces/StoreInterfaces";
import { ResponseGenerator } from "../../../interfaces/mainInterfaces";
import API from "../../../utils/API";
import { useSelector } from "react-redux";
import { FMAlert } from "../../Form/Swal/FMAlert";

interface passwordChangeData {
	old_password: string
	new_password: string
	confirm_password: string
}

const schema = yup.object().shape({
	old_password: yup.string().required("Field is required").min(7, "This value must be minimum of 7 characters."),
	new_password: yup.string().required("Field is required").min(7, "This value must be minimum of 7 characters."),
	confirm_password: yup.string().required("Field is required").min(7, "This value must be minimum of 7 characters.").oneOf([ yup.ref("new_password"), null ], "Passwords don't match!")
}).required();

export const PasswordChangeForm = () => {
	const { register, handleSubmit, formState: { errors } } = useForm<passwordChangeData>({
		mode: "onBlur",
		resolver: yupResolver(schema)
	});

	const langMyAccount:any = useSelector((state:StoreInterface) => state.language.languageMyAccounts);

	const [ , setPassResponse ] = useState({});
	const [ formKey, setFormKey ] = useState("");

	// const MySwal = withReactContent(Swal)

	const onSubmit = (data:passwordChangeData) => {
		FMAlert.fire({
			title: langMyAccount.change_pass_03,
			text: langMyAccount.change_pass_01,
			confirmButtonText: langMyAccount.change_pass_05,
			showCancelButton: true,
			cancelButtonText: langMyAccount.change_pass_04,
			cancelButtonColor: "#d9534f",
			confirmButtonColor: "#5cb85c"
		}).then((result) => {
			if (result.isConfirmed) {
				const passwordChangeData = new FormData();
				passwordChangeData.append("old_password", data.old_password);
				passwordChangeData.append("new_password", data.new_password);
				passwordChangeData.append("confirm_password", data.confirm_password);
				passwordChangeData.append("form_key", formKey);
				return sendPasswordChangeInfo(passwordChangeData);
			}
		});
	};

	async function sendPasswordChangeInfo (passwordChangeData:{}) {
		const response:ResponseGenerator = await API.post("changePassword", passwordChangeData);
		setPassResponse(response.data || {});
		if (response.data && response.data.success) {
			FMAlert.fire({
				text: langMyAccount.pass_change_yes,
				color: "green",
				icon: "success"
			});
		}
		if (response.data && formKey && !response.data.success && (response.data.error || response.data.tank_error || response.data.max_pass_msg_error)) {
			FMAlert.fire({
				text: response.data.max_pass_msg_error ? langMyAccount.max_pass_msg : response.data.error.old_password || response.data.tank_error,
				color: "red",
				icon: "error"
			});
		}
		if (response.data && response.data.form && response.data.form.form_key) {
			setFormKey(response.data.form.form_key);
		}
	}

	return (
		<Form handleFormSubmit={handleSubmit(onSubmit)}>
			<Box sx={{ width: 500, maxWidth: "100%" }}>
				<Input
					label="Current Password"
					inputType="password"
					error={!!errors.old_password}
					helpertext={errors.old_password?.message}
					{ ...register("old_password")}
				/>
				<Input
					label="New Password"
					inputType="password"
					error={!!errors.new_password}
					helpertext={errors.new_password?.message}
					{ ...register("new_password")}
				/>
				<Input
					label="Confirm Password"
					inputType="password"
					error={!!errors.confirm_password}
					helpertext={errors.confirm_password?.message}
					{ ...register("confirm_password")}
				/>
			</Box>
			<Box sx={{ width: 500, maxWidth: "100%" }} style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
				<Button type="submit" variant="contained" color="success">Send Request</Button>
			</Box>
		</Form>
	);
};
