import React, { useCallback } from "react";
import "./PageBonusesStatistic.scss";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../interfaces/StoreInterfaces";
import PageTitle from "../../../components/Dashboard/dashboardComponents/PageTitle";
import { Box, Typography, TypographyProps } from "@mui/material";
import ReactHtmlParser from "react-html-parser";
import { useIsRtl } from "../../../utils/hooks/useIsRtl";
import FMTable from "../../../components/FMTable/FMTable";
import FMTableColumn from "../../../components/FMTable/FMTableColumn";
import { CreateBonusStatisticsCell } from "./components/BonusStatisticsCell";
import { Chart } from "./components/Chart";
import { useAppSelector } from "../../../utils/hooks/reduxHooks";

export default function PageBonusesStatistic () {
	const lang:any = useSelector((state:StoreInterface) => state.language.language);
	const langBonus:any = useSelector((state:StoreInterface) => state.language.languageBonus);
	const loadingChart = useAppSelector((state) => state.bonus.loading);

	const isRtl = useIsRtl();

	const { funds, loading, trading } = useSelector((state:StoreInterface) => state.account);
	const { bonusFund } = funds;
	const user = useSelector((state:StoreInterface) => state.user.user);
	const bonusPercent = user.bonus?.bonus ?? 0.2;

	const currencyBase = user.mt_currency_base;
	const withCurrency = useCallback((value: number | string) => {
		return value + " " + (currencyBase === "RUB" ? "RUR" : currencyBase ?? "");
	}, [ currencyBase ]);

	const deposit = bonusFund / bonusPercent;
	const dataForAccountSummaryTable = [
		{
			name: langBonus.bo_st_22,
			value: withCurrency(funds.balance)
		},
		{
			name: langBonus.bo_st_20,
			value: withCurrency(bonusFund)
		},
		{

			name: langBonus.bo_st_5,
			value: withCurrency(funds.withrawableRealFund)
		},
		{

			name: langBonus.bo_st_6,
			value: withCurrency(funds.withrawableRealFund - deposit)
		},
		{

			name: langBonus.bo_st_3,
			value: withCurrency(funds.equity)
		},
		{

			name: langBonus.bo_st_4,
			value: withCurrency(funds.margin)
		}
	];

	const necessaryNumberOfLotsForBonusWithdrawal = (bonusFund * bonusPercent).toFixed(2);
	const numberOfLotsToTrade = Number(necessaryNumberOfLotsForBonusWithdrawal) - trading.accountTotalLots <= 0 ? 0 : Number(necessaryNumberOfLotsForBonusWithdrawal) - trading.accountTotalLots;

	const dataForYourBonusStatisticsTable = [
		{
			name: langBonus.bo_st_10,
			value: necessaryNumberOfLotsForBonusWithdrawal
		},
		{
			name: langBonus.bo_st_11,
			value: trading.accountTotalLots
		},
		{

			name: langBonus.bo_st_12,
			value: numberOfLotsToTrade.toFixed(2)
		},
		{

			name: langBonus.bo_st_13,
			value: bonusFund && numberOfLotsToTrade === 0 ? langBonus.bon_aval_1 : langBonus.bon_aval_2
		}
	];

	const textProps: TypographyProps = {
		textAlign: isRtl ? "right" : "left",
		// @ts-ignore
		component: "h3",
		lineHeight: "22px",
		fontSize: "18px",
		className: "page-bonus__title"
	};

	return (
		<div className="page-bonuses-statistic">
			<PageTitle
				layoutTitle={lang.sb_li_3}
				pageTitle={langBonus.bon_tab_01}
			/>

			<Typography {...textProps}>
				{langBonus.bo_st_00} {user.account_number}. {langBonus.bo_st_01}
			</Typography>
			<Typography {...textProps} marginTop={"8px"}>
				{langBonus.bo_st_02}
			</Typography>
			<Typography {...textProps} marginTop={"8px"} mb={"28px"}>
				{ReactHtmlParser(langBonus.bo_st_03)}
			</Typography>

			<FMTable
				theme="AccountSummaryTheme"
				data={loading ? undefined : dataForAccountSummaryTable}
				rowsPerPageOptions={[ 10 ]}
				withSearch={false}
				disableFooter
				mobileScheme={{ mode: "auto" }}
			>
				<FMTableColumn
					id="name"
					label={langBonus.bo_st_1}
					headStyle={{
						textAlign: "left",
						padding: "17px 0 18px 23px",
						fontSize: "18px",
						borderRight: "0"
					}}
					component={CreateBonusStatisticsCell("name", { textAlign: "left" })}
				/>
				<FMTableColumn
					id="value"
					headStyle={{
						textAlign: "left",
						padding: "17px 0 18px 23px",
						fontSize: "18px"
					}}
					component={CreateBonusStatisticsCell("value")}
				/>
			</FMTable>
			<Box marginTop={"31px"}>
				<Box sx={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
					<Box sx={{ maxWidth: "550px", width: "100%" }}>
						<FMTable
							theme="ChartBonusStatisticsTheme"
							data={loadingChart ? undefined : [ {} ]}
							rowsPerPageOptions={[ 6 ]}
							withSearch={false}
							disableFooter
							mobileScheme={{ mode: "auto" }}
						>
							<FMTableColumn
								id="Name"
								label={langBonus.bo_st_9}
								headStyle={{
									textAlign: "left",
									padding: "17px 0 18px 23px",
									fontSize: "18px"
								}}
								component={Chart}
							/>
							<></>
						</FMTable>
					</Box>
					<Box sx={{ maxWidth: "550px", width: "100%" }}>
						<FMTable
							theme="BonusStatisticsTheme"
							data={loading ? undefined : dataForYourBonusStatisticsTable}
							rowsPerPageOptions={[ 6 ]}
							withSearch={false}
							disableFooter
							mobileScheme={{ mode: "auto" }}
						>
							<FMTableColumn
								id="Name"
								label={langBonus.bo_st_9}
								headStyle={{
									textAlign: "left",
									padding: "17px 0 18px 23px",
									fontSize: "18px"
								}}
								component={CreateBonusStatisticsCell("name")}
								align="left"
							/>
							<FMTableColumn
								id="Value"
								headStyle={{ width: "15%" }}
								align="left"
								component={CreateBonusStatisticsCell("value", { maxWidth: "125px", cursor: "pointer" })}
							/>
						</FMTable>
					</Box>
				</Box>
			</Box>
		</div>
	);
};
