import React from "react";

const MyAccountIcon = (props: any) => {
	let width = 16;
	if (props.width) {
		width = props.width;
	}

	return (
		<svg width={width} height={width} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 16">
			<defs>
				<clipPath id="icon-profile-user-clip-path">
					<rect width={width} height={width} transform="translate(27 18)" fill="#e4e4e4"/>
				</clipPath>
			</defs>
			<g transform="translate(-27 -18)" clipPath="url(#icon-profile-user-clip-path)">
				<g id="icon-profile-user" transform="translate(25.192 15.951)">
					<path d="M9.808,9.6A3.773,3.773,0,1,0,6.035,5.822,3.778,3.778,0,0,0,9.808,9.6Zm0-6.039A2.266,2.266,0,1,1,7.542,5.822,2.268,2.268,0,0,1,9.808,3.556Z" />
					<path d="M11.62,10.5H8a5.591,5.591,0,0,0-5.584,5.586V17.3a.754.754,0,0,0,.754.753H16.451A.754.754,0,0,0,17.2,17.3V16.089A5.591,5.591,0,0,0,11.62,10.5ZM8,12.01H11.62A4.034,4.034,0,0,1,15.7,16.089v.453H3.918v-.453A4.034,4.034,0,0,1,8,12.01Z" />
				</g>
			</g>
		</svg>
	);
};

export default MyAccountIcon;
