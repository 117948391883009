import * as React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { SuccessNote } from "../../../components/UI/Note/SuccessNote";
import { UploadForm } from "./components/UploadForm";
import { useIsMobile } from "../../../utils/hooks/useIsMobile";
import { StoreInterface } from "../../../interfaces/StoreInterfaces";
import { useSelector } from "react-redux";

export function Step1 () {
	const isMobile = useIsMobile();
	const userDocs = useSelector((state:StoreInterface) => state.user.userDocs);
	const langMyAccounts:any = useSelector((state:StoreInterface) => state.language.languageMyAccounts);

	let fileHashName = "";
	if (userDocs.level_one_first) fileHashName = userDocs.level_one_first?.file_name;
	let decline = "";
	if (userDocs.level_one_first?.decline_explained) decline = userDocs.level_one_first?.decline_explained;
	let fileHashNameSecond = "";
	if (userDocs.level_one_second) fileHashNameSecond = userDocs.level_one_second?.file_name;
	let declineSecond = "";
	if (userDocs.level_one_second?.decline_explained) declineSecond = userDocs.level_one_second?.decline_explained;

	return (
		<Grid
			container
			flexDirection="row"
		>
			<Grid item sx={{ width: isMobile ? "100%" : "50%" }}>
				<Typography
					sx={{ maxWidth: "430px", letterSpacing: "0", lineHeight: "24px", marginBottom: isMobile ? "15px" : "45px" }}
					textAlign="left"
				>
					{langMyAccounts.pro_up_doc_13}
				</Typography>
				<SuccessNote message={langMyAccounts.accver_11} sx={{ marginBottom: "15px" }} />
				<SuccessNote message={langMyAccounts.accver_12} sx={{ marginBottom: "15px" }} />
				<SuccessNote message={langMyAccounts.accver_13} sx={{ marginBottom: "15px" }} />
				<SuccessNote message={langMyAccounts.accver_14} sx={{ marginBottom: "15px" }} />
				<SuccessNote message={langMyAccounts.accver_15} sx={{ marginBottom: "15px" }} />
				<SuccessNote message={langMyAccounts.accver_16} sx={{ marginBottom: "15px" }} />
			</Grid>
			<Grid item sx={{ width: isMobile ? "100%" : "50%" }}>
				<UploadForm
					tips="Tip 1"
					label={langMyAccounts.pro_up_doc_2}
					type="1"
					name="doc_type"
					level="1"
					fileHashName={fileHashName}
					decline={decline}
				/>
				<Box sx={{ marginTop: isMobile ? "12px" : "30px", marginBottom: isMobile ? "0" : "24px" }}>
					<UploadForm
						tips="Tip 2"
						label={langMyAccounts.accver_05}
						type="2"
						name="doc_type"
						level="1"
						fileHashName={fileHashNameSecond}
						decline={declineSecond}
					/>
				</Box>
			</Grid>
		</Grid>
	);
}
