import * as React from "react";
import { Button } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

export default function AppBarAuthMobileBurgerButton ({ setOpen }: any) {
	return (
		<Button
			sx={{
				height: "40px",
				width: "40px",
				minWidth: "40px",
				border: "1px solid #2988CA",
				borderRadius: 0,
				marginX: "5px",
				"&:hover": {
					color: "#2988CA",
					background: "#fff"
				},
				"& svg": {
					marginTop: 0
				}
			}}
			onClick={() => setOpen(true)}>
			<MenuIcon sx={{ marginTop: "2px" }}/>
		</Button>
	);
};
