import * as React from "react";
import { useSelector } from "react-redux";
import { SessionUserDataInterface, StoreInterface } from "../../../interfaces/StoreInterfaces";
import { List } from "@mui/material";
import SidebarContainedButton from "./SidebarContainedButton";
import WithdrawIcon from "../../Icons/WithdrawIcon";
import VerifyAccountIcon from "../../Icons/VerifyAccountIcon";
import WithdrawFundsIcon from "../../Icons/WithdrawFundsIcon";
import SidebarOutlinedButton from "./SidebarOutlinedButton";
import DownloadPlatformIcon from "../../Icons/DownloadPlatformIcon";
import WebTerminalIcon from "../../Icons/WebTerminalIcon";

const SidebarListButton = ({ open, setAccordion }: any) => {
	const lang: any = useSelector((state: StoreInterface) => state.language.language);
	const init: any = useSelector((state: StoreInterface) => state.init.init);
	const session: SessionUserDataInterface = useSelector((state: StoreInterface) => state.session.userData);

	return (
		<React.Fragment>
			<List
				sx={{
					width: "100%",
					padding: "15px 0 35px 0"
				}}
				component="nav"
			>
				<SidebarContainedButton
					setAccordion={setAccordion}
					accordionTo={false}
					open={open}
					to={"profile/upload-document"}
					icon={<VerifyAccountIcon />}
					text={lang.sb_a_0}
				/>

				{session.login_type === "0"
					? <SidebarContainedButton
						setAccordion={setAccordion}
						accordionTo={"panel2"}
						open={open}
						to={"deposit"}
						icon={<WithdrawFundsIcon />}
						text={lang.sb_a_1}
					/>
					: null
				}

				{!init.prohibition_2
					? <SidebarContainedButton
						setAccordion={setAccordion}
						accordionTo={"panel2"}
						open={open}
						to={"withdraw"}
						icon={<WithdrawIcon />}
						text={lang.sb_a_2}
					/>
					: null
				}
				{session.login_type === "0"
					? <>
						<SidebarOutlinedButton
							open={open}
							href={"https://download.mql5.com/cdn/web/instant.trading.eu/mt4/forexmart4setup.exe"}
							icon={<DownloadPlatformIcon />}
							text={lang.sb_a_3}
						/>
						<SidebarOutlinedButton
							open={open}
							href={"https://webterminal.forexmart.com/"}
							icon={<WebTerminalIcon />}
							text={lang.xnv_webtrader}
						/>
					</>
					: null
				}
			</List>
		</React.Fragment>
	);
};

export default SidebarListButton;
