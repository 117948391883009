/*
    Created on : 27.10.2021, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { useSelector } from "react-redux";
import { SessionUserDataInterface, StoreInterface } from "../../../../interfaces/StoreInterfaces";
import "./FooterAuthDesk.css";

function FooterAuthDesk () {
	const lang:any = useSelector((state:StoreInterface) => state.language.language);
	const init:any = useSelector((state:StoreInterface) => state.init.init);
	const session:SessionUserDataInterface = useSelector((state:StoreInterface) => state.session.userData);
	const currentYear = new Date().getFullYear();

	return (
		<>
			<div className="footer-auth-second-row-wrap">
				<div className="container">
					<div className="footer-auth-second-row flexRow">
						<div className="footRestriction contentFooter">
							{session.site_lang === "russuan"
								? <>
									<p>
										{lang.foot_frx_ru_1}<br/>
										{lang.foot_frx_ru_2}<b>&nbsp;{init.companyName}&nbsp;</b> {lang.foot_frx_ru_3}
									</p>
									<p>
										{lang.foot_frx_ru_4}
									</p>
								</>
								: <>
									<p>
										<span className="blue-text">ForexMart</span>{lang.new_with_foo_01}<br/><br/>
										{lang.new_with_foo_02}
										<b>&nbsp;{init.companyName}&nbsp;</b>
										{lang.new_with_foo_04}
									</p>
									<p>
										{lang.new_with_foo_05}
										<b>&nbsp;{init.companyName}&nbsp;</b>
										{lang.new_with_foo_06}
									</p>
								</>
							}
						</div>
					</div>
				</div>
			</div>
			<div className="footer-auth-copyright-row-wrap">
				<div className="container">
					<div className="footer-auth-second-row flexRow">
						<div className="footer-copyright"> &copy; 2015 - {currentYear} <b>&nbsp;{init.companyName}</b></div>
					</div>
				</div>
			</div>
		</>

	);
}

export default FooterAuthDesk;
