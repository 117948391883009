import * as React from "react";
import { Form } from "../../../../components/Form";
import { asianBankWithdrawValidationSchema } from "./validation";
import { Input } from "../../../../components/Form/Input";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { InputRepeater } from "../../../../components/Form/InputRepeater";
import { ButtonWithLoader } from "../../../../components/ButtonWithLoader";
import { FormBottomWrapper } from "../../../../components/Form/FormBottomWrapper";
import { fetchAsianBanksInfo } from "../../../../store/actions/actionTypes";
import { Alert, Box, Skeleton, Snackbar } from "@mui/material";
import { WaningNote } from "../../../../components/UI/Note";
import API from "../../../../utils/API";
import { AxiosResponse } from "axios";
import { useErrorHandling } from "../../../../utils/hooks/useErrorHandling";

export function AsianBank () {
	const [ isFetch, setIsFetch ] = React.useState(false);
	const [ success, setSuccess ] = React.useState(false);
	const accountNumber = useAppSelector((state) => state.session.userData.account_number);
	const balance = useAppSelector((state) => state.account.funds.balance);
	const language = useAppSelector((state) => state.language);
	const {
		fee,
		addon,
		complete,
		feeDetails,
		loading: infoLoading
	} = useAppSelector((state) => state.withdrawMethodsInfo.asianBank);
	const { isOpen, errorMsg, hideError, showError } = useErrorHandling();
	const dispatch = useAppDispatch();

	React.useEffect(() => {
		if (!complete) {
			dispatch(fetchAsianBanksInfo({}));
		}
	}, []);
	async function submit (values: {
		amount: string,
		bankAccountNumber: string,
		bankAccountName: string,
		bankName: string,
		branch: string,
		province: string,
		city: string,
	}) {
		setIsFetch(true);
		try {
			const formData = new FormData();
			formData.append("amount_withdraw", values.amount);
			formData.append("vndasia_account", values.bankAccountNumber);
			formData.append("bank_account_name", values.bankAccountName);
			formData.append("bank_name", values.bankName);
			formData.append("bank_branch", values.branch);
			formData.append("bank_province", values.province);
			formData.append("bank_city", values.city);

			const { data } = await API.post<unknown, AxiosResponse<{ success: boolean, error: string }>>("withdrawAsianBanks", formData);

			if (data.success) {
				setSuccess(true);
			} else {
				showError(data.error || "Something went wrong");
			}
		} catch (e) {
			// handle error
		} finally {
			setIsFetch(false);
		}
	}

	function calcFee (values: [string]): string {
		const [ withdrawalAmount = "0" ] = values;

		return (+withdrawalAmount * fee).toFixed(3) + " USD";
	}

	function calcNewBalance (values: [string]): string {
		const [ withdrawalAmount = "0" ] = values;
		const feeAmount = +withdrawalAmount * fee;

		return balance ? `${(+balance - +withdrawalAmount * feeAmount + addon - +withdrawalAmount).toFixed(3)} USD` : "0 USD";
	}

	function calcFullAmount (values: [string]): string {
		const [ withdrawalAmount = "0" ] = values;
		const feeAmount = +withdrawalAmount * fee;

		return `${isNaN(parseInt(withdrawalAmount)) ? 0 : (+withdrawalAmount + feeAmount).toFixed(3)} USD`;
	}

	return (
		<Form
			onSubmit={submit}
			validationSchema={asianBankWithdrawValidationSchema()}
			defaultValues={{
				accountNumber,
				amount: "0",
				bankAccountName: "",
				bankName: "",
				branch: "",
				province: "",
				city: ""
			}}
		>
			<Input
				name="accountNumber"
				label={"Account Number"}
			/>
			<Input
				name="amount"
				label={"Withdraw amount"}
			/>
			<InputRepeater
				fields="amount"
				label={"Fee"}
				onChange={calcFee}
			/>
			<InputRepeater
				fields="amount"
				label={"New Balance"}
				onChange={calcNewBalance}
			/>

			<Input
				name="bankAccountName"
				label={"Bank account name"}
			/>
			<Input
				name="bankName"
				label={"Bank Name"}
			/>
			<Input
				name="bankAccountNumber"
				label={"Bank account number "}
			/>
			<Input
				name="branch"
				label={"Branch"}
			/>
			<Input
				name="province"
				label={"Province"}
			/>
			<Input
				name="city"
				label={"City"}
			/>
			<FormBottomWrapper
				forFields={[ "amount" ]}
				onChange={calcFullAmount}
				label={language.languageMyAccounts.form_message_01}
			>
				<ButtonWithLoader
					isFetch={isFetch}
					color={"primary"}
					text={language.languageMyAccounts.chapas_04}
				/>
			</FormBottomWrapper>
			<Box sx={{ marginTop: "6px" }}>
				{
					infoLoading
						? (
							<Skeleton width={"220px"} height={"26px"} />
						)
						: (
							<WaningNote
								message={`Fee is ${feeDetails}`}
							/>
						)
				}
			</Box>
			<Snackbar open={isOpen} autoHideDuration={4000} onClose={hideError}>
				<Alert variant="filled" color="error">
					{errorMsg}
				</Alert>
			</Snackbar>
			<Snackbar
				open={success}
				autoHideDuration={4000}
				onClose={() => setSuccess(false)}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
			>
				<Alert variant="filled" color="success">
					Success
				</Alert>
			</Snackbar>
		</Form>
	);
}
