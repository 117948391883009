import * as React from "react";
import { Box } from "@mui/material";

export interface ICenterProps {
	children: JSX.Element | JSX.Element[],
}

export function Center (props: ICenterProps) {
	const { children } = props;

	return (
		<Box
			sx={{
				width: "100%",
				height: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center"
			}}
		>
			{children}
		</Box>
	);
}
