import * as React from "react";
import { Form } from "../../../../components/Form";
import { thunderXPayWithdrawValidationSchema } from "./validation";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { Input } from "../../../../components/Form/Input";
import { ButtonWithLoader } from "../../../../components/ButtonWithLoader";
import { FormBottomWrapper } from "../../../../components/Form/FormBottomWrapper";
import { getWithdrawalValue } from "../../../../utils/getReceivedValue";
import { useErrorHandling } from "../../../../utils/hooks/useErrorHandling";
import API from "../../../../utils/API";
import { AxiosResponse } from "axios";
import { Alert, Box, Skeleton, Snackbar } from "@mui/material";
import { WithdrawalTypes } from "../../../../types/WithdrawalTypes";
import { fetchThunderXPayInfo } from "../../../../store/actions/actionTypes";
import { WaningNote } from "../../../../components/UI/Note";

enum Currency {
	LAK = "LAK",
	MMK = "MMK",
	KHR = "KHR",
	ZAR = "ZAR",
	THB = "THB"
}
export function ThunderXPay () {
	const [ success, setSuccess ] = React.useState(false);
	const [ isFetch, setIsFetch ] = React.useState(false);
	const accountNumber = useAppSelector((state) => `${state.session.userData.account_number ?? ""}`);
	const language = useAppSelector((state) => state.language);
	const { isOpen, showError, hideError, errorMsg } = useErrorHandling();
	const {
		feeDetails,
		loading: infoLoading,
		complete
	} = useAppSelector((state) => state.withdrawMethodsInfo.thunderXPay);
	const dispatch = useAppDispatch();

	React.useEffect(() => {
		if (!complete) {
			dispatch(fetchThunderXPayInfo({}));
		}
	}, []);
	async function submit (values: {
		accountNumber: string,
		amount: string,
		bankAccountNumber: string
	}) {
		setIsFetch(true);
		try {
			const formData = new FormData();

			formData.append("bank_account_number", values.bankAccountNumber);
			formData.append("withdraw_amount", values.amount);
			formData.append("withdrawal_type", WithdrawalTypes.THUNDER_XPAY);

			const response = await API.post<unknown, AxiosResponse<{
				success: boolean,
				error: string
			}>>("withdrawLocalBank", formData);

			if (!response.data.success) {
				showError(response.data.error ?? "Something went wrong.");
			} else {
				setSuccess(true);
			}
		} catch (e) {
			showError("Something went wrong.");
		} finally {
			setIsFetch(false);
		}
	}

	function getWithdrawalValueCB (values: [string, Currency]) {
		return getWithdrawalValue(values, "USD");
	}

	return (
		<>
			<Form
				defaultValues={{
					accountNumber,
					amount: "",
					bankAccountNumber: ""
				}}
				onSubmit={submit}
				validationSchema={thunderXPayWithdrawValidationSchema()}
			>
				<Input
					name="accountNumber"
					label={"Account Number"}
					variant="rounded"
				/>
				<Input
					name="amount"
					label={"Withdraw amount"}
					variant="rounded"
				/>
				<Input
					name="bankAccountNumber"
					label={"Bank Account Number"}
					variant="rounded"
				/>
				<FormBottomWrapper
					forFields={[ "amount" ]}
					onChange={getWithdrawalValueCB}
					label={language.languageMyAccounts.form_message_01}
				>
					<ButtonWithLoader
						isFetch={isFetch}
						color={"primary"}
						text={language.languageMyAccounts.chapas_04}
					/>
				</FormBottomWrapper>
				<Box sx={{ marginTop: "6px" }}>
					{
						infoLoading
							? (
								<Skeleton width={"220px"} height={"26px"} />
							)
							: feeDetails
								? (
									<WaningNote
										message={`Fee is ${feeDetails}`}
									/>
								)
								: <></>
					}
				</Box>
			</Form>
			<Snackbar
				open={success}
				autoHideDuration={4000}
				onClose={() => setSuccess(false)}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
			>
				<Alert variant="filled" color="success">
					Success
				</Alert>
			</Snackbar>
			<Snackbar open={isOpen} autoHideDuration={4000} onClose={hideError}>
				<Alert variant="filled" color="error">
					{errorMsg}
				</Alert>
			</Snackbar>
		</>
	);
}
