import * as React from "react";
import { Box } from "@mui/material";
import { IFMTableComponentProps } from "../../../../components/FMTable/FMTableCellComponent";
import { IRebateSystemProject } from "../../../../interfaces/RebateSystemInterfaces";
import { useCellWrapperStyle } from "../../../../utils/hooks/useCellWrapperStyle";

export function ProjectNameCell (props: IFMTableComponentProps<IRebateSystemProject, {}>) {
	const wrapperStyle = useCellWrapperStyle();

	return <Box sx={wrapperStyle}>{props.ProjectName}</Box>;
}
