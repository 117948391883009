/* eslint-disable camelcase */
import * as React from "react";
import { Select } from "../../../../components/Form/Select";
import { Option } from "../../../../components/Form/Option";
import { useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { validationSchema } from "./validation";
import { DefaultForm } from "../../../../components/Forms/DepositForm/DefaultForm";
import { getInfo } from "./api";
import { getPaymentNetworkRequest } from "../../../../utils/getPaymentNetworkRequest";

export function Skrill () {
	const [ isFetch, setIsFetch ] = React.useState(false);
	const language = useAppSelector((state) => state.language);
	const currency = useAppSelector((state) => state.session.userData.currency ?? "USD");

	const onSubmit = React.useCallback(async (values: any) => {
		try {
			setIsFetch(true);
			const { amount, currency } = values;
			const info = await getInfo(amount, currency);
			const requestLink = getPaymentNetworkRequest(info);
			window.open(requestLink, "_self");
		} catch (e) {
			// handle some error
		} finally {
			setIsFetch(false);
		}
	}, []);

	return (
		<>
			<DefaultForm
				defaultValues={{ currency }}
				notes={`${language.languageFinance.s_06} ${language.languageFinance.s_07}`}
				onSubmit={onSubmit}
				validationSchema={validationSchema}
				isFetch={isFetch}
			>
				<Select
					name="currency"
					label={language.languageMyAccounts.mya_20}
				>
					<Option value="USD">USD</Option>
					<Option value="EUR">EUR</Option>
					<Option value="RUB">RUB</Option>
				</Select>
			</DefaultForm>
		</>
	);
}
