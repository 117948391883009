/* eslint-disable camelcase */
import * as React from "react";
import { Form } from "../../../../components/Form";
import { Input } from "../../../../components/Form/Input";
import { getWithdrawalValue } from "../../../../utils/getReceivedValue";
import { useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { boletoValidationSchema } from "./validation";
import { FormBottomWrapper } from "../../../../components/Form/FormBottomWrapper";
import { ButtonWithLoader } from "../../../../components/ButtonWithLoader";
import { Alert, Box, Snackbar } from "@mui/material";
import { WaningNote } from "../../../../components/UI/Note";
import { useErrorHandling } from "../../../../utils/hooks/useErrorHandling";
import { Option } from "../../../../components/Form/Option";
import { Select } from "../../../../components/Form/Select";
import API from "../../../../utils/API";
import { AxiosResponse } from "axios";

export function Boleto () {
	const [ isFetch, setIsFetch ] = React.useState(false);
	const language = useAppSelector((state) => state.language);
	const accountNumber = useAppSelector((state) => `${state.session.userData.account_number ?? ""}`);
	const currency = useAppSelector((state) => state.session.userData.currency ?? "USD");
	const fullName = useAppSelector((state) => state.user.user.full_name);
	const { isOpen, errorMsg, hideError, showError } = useErrorHandling();

	const getWithdrawalValueCB = React.useCallback((values: any) => getWithdrawalValue(values, currency), [ currency ]);

	// TO-DO: Implement API call to deposit with indonesian boleto
	async function submit (values: {
		amount: string,
		phone: string,
		firstName: string,
		lastName: string,
		address: string,
		city: string,
		zip: string
	}) {
		try {
			setIsFetch(true);
			const formData = new FormData();

			formData.append("amount1", values.amount);
			formData.append("amount", values.amount);
			formData.append("bonus", "");
			formData.append("currency", currency);

			const { data } = await API.post<unknown, AxiosResponse<{
				redirect: string,
				error_msg: boolean,
				error: {}
			}>>("depositBoleto", formData);

			const errorKeys = Object.keys(data.error);
			if (errorKeys.length) {
				throw new Error(data.error[errorKeys[0] as keyof {}]);
			}

			if (!data.redirect) {
				throw new Error("Something went wrong");
			}

			window.open(data.redirect, "_target");
		} catch (e) {
			if (e instanceof Error) {
				showError(e.message);
			}
		} finally {
			setIsFetch(false);
		}
	}

	return (
		<Box sx={{ paddingTop: "20px" }}>

			<Form
				onSubmit={submit}
				validationSchema={boletoValidationSchema()}
				defaultValues={{
					accountNumber,
					amount: "",
					fullName,
					currency: "USD"
				}}
			>
				<Input
					name="accountNumber"
					label={language.languageCopytrade.cpy_mon_accnum}
					variant="rounded"
				/>
				<Input
					name="amount"
					label={language.languageFinance.trd_230}
					variant="rounded"
				/>
				<Input
					name="fullName"
					label="Full Name"
					variant="rounded"
				/>
				<Select
					name="currency"
					label="Currency"
				>
					<Option value="USD">USD</Option>
				</Select>
				<FormBottomWrapper
					forFields={[ "amount", "currency" ]}
					onChange={getWithdrawalValueCB}
					label={language.languageMyAccounts.form_message_01}
				>
					<ButtonWithLoader
						isFetch={isFetch}
						color={"primary"}
						text={language.languageMyAccounts.chapas_04}
					/>
				</FormBottomWrapper>
				<Box sx={{ marginTop: "6px" }}>
					<WaningNote
						message="Please fill all fields"
					/>
				</Box>
				<Snackbar open={isOpen} autoHideDuration={4000} onClose={hideError}>
					<Alert variant="filled" color="error">
						{errorMsg}
					</Alert>
				</Snackbar>
			</Form>
		</Box>
	);
}
