import * as React from "react";
import { Box, Button, SxProps, Typography } from "@mui/material";
import { Row } from "../UI/Row";

export interface ButtonProps {
	onClick: () => void
	text: string;
	contentAlign?: "start" | "center" | "space-between" | "space-around" | "space-evenly";
	color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning" | "default" | "primary-dark";
	minHeight?: string;
	icon?: JSX.Element;
	iconStart?: JSX.Element;
	sx?: SxProps<any>;
	type?: "submit" | "button" | "reset";
	disabled?: boolean
}

function CustomButton (props: ButtonProps): JSX.Element {
	const {
		text,
		color = "primary",
		minHeight = "40px",
		contentAlign = "center",
		icon = <></>,
		iconStart = <></>,
		onClick,
		sx,
		type = "submit",
		disabled
	} = props;

	return (
		<Box sx={{ position: "relative" }}>
			<Button
				disabled={disabled}
				onClick={onClick}
				variant="contained"
				color={color}
				type={type}
				disableElevation
				sx={{
					padding: "10px",
					width: "150px",
					textTransform: "none",
					minHeight,
					...sx
				}}
			>
				<Row justifyContent={contentAlign} alignItems="center">
					{iconStart}
					<Typography>{text}</Typography>
					{icon}
				</Row>
			</Button>
		</Box>
	);
}

export default CustomButton;
