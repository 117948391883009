import * as React from "react";
import { IFMTableComponentProps } from "../../../../../components/FMTable/FMTableCellComponent";
import { Trader } from "../../../../../interfaces/CopytradeInterfaces";
import { useAppSelector } from "../../../../../utils/hooks/reduxHooks";
import { Settings } from "./Settings";
import { TradersTable } from "./TradersTable";

export function ExpandedRow (props: IFMTableComponentProps<Trader, {}>) {
	const { ConnectionId, Trader, Follower } = props;
	const { settings, tradesHistory } = useAppSelector(state => state.expandedRowPages.traderTable);

	return settings
		? (
			<Settings
				connectionId={ConnectionId}
				trader={Trader}
				follower={Follower}
			/>
		)
		: tradesHistory
			? (<TradersTable connectionId={ConnectionId} />)
			: (<></>);
}
