/*
    Created on : 25.10.2023, 06:18:59
    Author     : Andy Kar
*/

import {
	SET_ACTIVATE_SMART_DOLLARS, SET_REQUEST_SMART_DOLLARS_INFO,
	SET_SMART_DOLLARS_INFO
} from "../actions/actionTypes";
import { ISmartDollarsInfo, ISmartDollarsWithdrawInfo } from "../../saga/smartDollarsSaga";

const initial = {
	smart_status: false,
	smart_info: {} as ISmartDollarsInfo,
	smart_withdraw: [] as ISmartDollarsWithdrawInfo[],
	activate_info: null,
	request_info: null
};

export default function smartDollarsReducer (state = initial, action:{type:string, payload:any}) {
	switch (action.type) {
		case SET_SMART_DOLLARS_INFO:
			return {
				...state,
				smart_status: action.payload.smart_status,
				smart_info: action.payload.smart_dollar_info,
				smart_withdraw: action.payload.withdraw_info
			};
		case SET_REQUEST_SMART_DOLLARS_INFO:
			return {
				...state,
				request_info: action.payload
			};
		case SET_ACTIVATE_SMART_DOLLARS:
			return {
				...state,
				activate_info: action.payload
			};

		default:
			return state;
	}
}
