/*
    Created on : 13.01.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import "./PageForgotPass.scss";
import ForgotPassForm from "../../../components/Forms/ForgotPassForm/ForgotPassForm";

function PageForgotPass () {
	return (
		<div className="page-forgot-password container">
			<ForgotPassForm/>
		</div>
	);
}

export default PageForgotPass;
