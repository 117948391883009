import React from "react";

const FeedbackIcon = (props:any) => {
	let width = 26;
	let fill = "#707071";
	if (props.width) {
		width = props.width;
	}
	if (props.fill) {
		fill = props.fill;
	}

	return (
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width={width} viewBox="230.834 0 48 48" xmlSpace="preserve">
			<path fill={fill} d="M271.758,41.689l-8.904-2.228c-8.379,4.688-18.972,1.696-23.659-6.684
            c-4.688-8.379-1.695-18.971,6.684-23.659s18.972-1.695,23.659,6.684c2.951,5.275,2.951,11.705-0.002,16.98L271.758,41.689z
             M264.516,14.109c-5.611-5.624-14.72-5.634-20.344-0.023c-5.623,5.611-5.634,14.721-0.022,20.343
            c5.075,5.086,13.124,5.648,18.855,1.317l4.218,1.409l-1.406-4.222C270.14,27.213,269.585,19.181,264.516,14.109z M261.932,26.573
            c-1.253,0-2.268-1.016-2.268-2.268c0-1.252,1.015-2.268,2.268-2.268c1.252,0,2.268,1.016,2.268,2.268
            C264.199,25.558,263.184,26.573,261.932,26.573z M254.372,26.573c-1.252,0-2.267-1.016-2.267-2.268c0-1.252,1.015-2.268,2.267-2.268
            c1.253,0,2.27,1.016,2.27,2.268C256.642,25.558,255.625,26.573,254.372,26.573z M246.813,26.573c-1.251,0-2.267-1.016-2.267-2.268
            c0-1.252,1.016-2.268,2.267-2.268c1.254,0,2.269,1.016,2.269,2.268C249.082,25.558,248.067,26.573,246.813,26.573z"/>
		</svg>
	);
};

export default FeedbackIcon;
