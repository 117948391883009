import React from "react";

const WebTerminalIcon = (props: any) => {
	let width = 16;
	let fill = "#417cbf";
	if (props.width) {
		width = props.width;
	}
	if (props.fill) {
		fill = props.fill;
	}

	return (
		<svg width={width} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 16">
			<defs>
				<clipPath id="clip-path">
					<rect width={width} height={width} transform="translate(35 595)" fill="#fff" stroke="#707070" strokeWidth="1"/>
				</clipPath>
			</defs>
			<g transform="translate(-35 -595)" clipPath="url(#clip-path)">
				<path id="icon-fm-webterminal" d="M15.073,4.026a7.815,7.815,0,1,0,0,11.052A7.815,7.815,0,0,0,15.073,4.026ZM7.457,2.807a5.976,5.976,0,0,0-.872,1.316,8.9,8.9,0,0,0-.493,1.208A8.885,8.885,0,0,1,4.413,4.7,7.1,7.1,0,0,1,7.457,2.807ZM3.919,5.283a9.385,9.385,0,0,0,1.958.763,13.944,13.944,0,0,0-.421,3.132H2.488A7.047,7.047,0,0,1,3.919,5.283Zm0,8.547A7.047,7.047,0,0,1,2.488,9.926H5.456a13.892,13.892,0,0,0,.421,3.127A9.3,9.3,0,0,0,3.919,13.831Zm.5.575a8.673,8.673,0,0,1,1.676-.632,9.136,9.136,0,0,0,.493,1.208A6.043,6.043,0,0,0,7.461,16.3,7.082,7.082,0,0,1,4.416,14.405Zm1.79-4.48h2.96v2.669a14.415,14.415,0,0,0-2.563.288A13.189,13.189,0,0,1,6.206,9.926Zm2.967,6.646c-.958-.236-1.81-1.367-2.35-2.972a13.675,13.675,0,0,1,2.35-.264Zm0-7.393H6.211a13.181,13.181,0,0,1,.405-2.961,14.358,14.358,0,0,0,2.557.292V9.179Zm0-3.41a13.756,13.756,0,0,1-2.35-.26C7.367,3.9,8.215,2.769,9.173,2.532Zm6-.479A7.039,7.039,0,0,1,16.6,9.179H13.637a14.12,14.12,0,0,0-.419-3.126A9.419,9.419,0,0,0,15.176,5.289Zm-.5-.591A8.662,8.662,0,0,1,13,5.331a9.438,9.438,0,0,0-.492-1.208,6.123,6.123,0,0,0-.874-1.316A7.085,7.085,0,0,1,14.679,4.7ZM9.921,5.763V2.532c.957.237,1.81,1.367,2.347,2.972a13.668,13.668,0,0,1-2.347.264v0Zm2.563.459a13.244,13.244,0,0,1,.4,2.962H9.926l0-2.674A14.3,14.3,0,0,0,12.484,6.222ZM9.921,12.589V9.926h2.96a13.1,13.1,0,0,1-.4,2.961,14.46,14.46,0,0,0-2.556-.293Zm0,3.983V13.336a13.52,13.52,0,0,1,2.347.261C11.726,15.206,10.878,16.336,9.921,16.572Zm1.715-.274a6.128,6.128,0,0,0,.874-1.317A9.1,9.1,0,0,0,13,13.774a8.907,8.907,0,0,1,1.679.632A7.1,7.1,0,0,1,11.636,16.3Zm3.54-2.468a9.271,9.271,0,0,0-1.961-.772,13.753,13.753,0,0,0,.422-3.133H16.6A7.065,7.065,0,0,1,15.176,13.831Z" transform="translate(33.453 593.448)" fill={fill} stroke={fill} strokeMiterlimit="10" strokeWidth="0.369"/>
			</g>
		</svg>
	);
};

export default WebTerminalIcon;
