import * as Yup from "yup";

export type PaycoErrors = {
	account_number_01: string;
	field_err_01: string;
	field_err_02: string;
	field_err_03: string;
	deposit_err_06: string;
}

export const paycoWithdrawValidationSchema = (language: PaycoErrors) => Yup.object().shape({
	accountNumber: Yup.string().required(language.account_number_01),
	amount: Yup
		.number()
		.typeError(language.deposit_err_06),
	wallet: Yup
		.number()
		.typeError(language.deposit_err_06)
});
