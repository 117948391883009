import * as React from "react";
import { CryptoCurrencyStatus } from "./CryptoCurrencyStatus";
import TetherLogo from "../../../../images/crypt_api.png";
import { useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { useDispatch } from "react-redux";
import API from "../../../../utils/API";
import { TResponse } from "../../../../utils/types";
import { updateTetherStatus } from "../../../../store/actions/actionTypes";

export function TetherDepositStatus () {
	const {
		status,
		createDate,
		orderId,
		address
	} = useAppSelector(state => state.cryptoCurrencyStatus.tether);
	const [ isLoad, setIsLoad ] = React.useState(false);
	const dispatch = useDispatch();

	async function refresh () {
		setIsLoad(true);
		try {
			const { data } = await API.get<TResponse<{ invData: { status: "string"} }>>("getCryptocurrencyStatus", { params: { orderId, currency: "" } });
			if (data.success) {
				dispatch(updateTetherStatus({ status: +data.data.invData.status }));
			}
		} catch (e) {
			//
		} finally {
			setIsLoad(false);
		}
	}

	return (
		<CryptoCurrencyStatus
			isLoad={isLoad}
			logo={TetherLogo}
			status={status}
			createDate={createDate}
			orderId={orderId}
			inputAddress={address}
			onRefresh={refresh}
		/>
	);
}
