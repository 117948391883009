import * as React from "react";
import { Theme, SxProps } from "@mui/material";
import { createRef, useEffect, useState } from "react";
import {
	IBreakpointCodes,
	initThemeBreakpoint,
	IThemeBreakpoints,
	ThemeBreakpointContext
} from "../Contexts/ThemeBreakpointContext";
import { mainTheme } from "../../theme/main.theme";

export function useCurrentThemeBreakpointsCodes () {
	const [ codes, setCodes ] = useState(initThemeBreakpoint);
	const ref: any = createRef();
	const theme = mainTheme;
	const breakpointsKeys = theme.breakpoints.keys;
	const breakpointsValues = theme.breakpoints.values;
	const keys = breakpointsKeys.sort((a, b) => breakpointsValues[a] - breakpointsValues[b]);
	let timer: any = null;

	function getThemeBreakpoint (width: number) {
		return keys.reduce(function (points: Array<IBreakpointCodes>, key) {
			if (key && breakpointsValues[key] < width) {
				points.push(key);
			}
			return points;
		}, []);
	}

	function getBreakpoints (width: number = ref.current.clientHeight) {
		if (width) { return getThemeBreakpoint(width); } else { return codes; }
	};

	const containerStack: number[] = [];
	function setBreakpoint () {
		const newWidth: number = containerStack[containerStack.length - 1];
		const breakpoints = getBreakpoints(newWidth);
		setCodes(prev => {
			if (breakpoints.length !== prev.length) {
				return breakpoints;
			} else {
				return prev;
			};
		});
		containerStack.length = 0;
	}
	function delayedSetContainerWidth (width: number) {
		containerStack.push(width);
		if (containerStack.length === 1) {
			timer = setTimeout(() => { setBreakpoint(); }, 100);
		}
	}

	useEffect(() => {
		const ro = new ResizeObserver((entries) => {
			if (entries[0] && Math.abs(entries[0].target.clientWidth) > 320) {
				delayedSetContainerWidth(entries[0].target.clientWidth);
			}
		});

		ro.observe(ref.current);
		const startCodes = getThemeBreakpoint(ref.current.clientWidth);
		setCodes(startCodes);
		return () => {
			if (timer) { clearTimeout(timer); }
			ro.disconnect();
		};
	}, []);

	return { ref, codes };
};

export function useBreakpoint (): IThemeBreakpoints {
	return React.useContext<IThemeBreakpoints>(ThemeBreakpointContext);
};

export function useElementWidth (delay: number = 0) {
	const [ currentWidth, setCurrentWidth ] = useState(1366);
	const ref: any = createRef();
	let timer: any = null;

	const containerStack: number[] = [];
	function setDelayedWidth (width?: number) {
		const newWidth: number = width || containerStack[containerStack.length - 1];
		if (containerStack.length > 0 && typeof newWidth === "number") {
			setCurrentWidth((prev) => prev !== newWidth ? newWidth : prev);
			containerStack.length = 0;
		}
	}
	function delayedSetContainerWidth (width: number) {
		containerStack.push(width);
		if (containerStack.length === 1) {
			timer = setTimeout(() => setDelayedWidth(), delay);
		}
	}

	useEffect(() => {
		const ro = new ResizeObserver((entries) => {
			if (entries[0] && Math.abs(entries[0].target.clientWidth - currentWidth) > 3) {
				delayedSetContainerWidth(entries[0].target.clientWidth);
			}
		});
		if (ref.current) {
			ro.observe(ref.current);
			setCurrentWidth(ref.current.clientWidth);
		}
		return () => {
			if (timer) { clearTimeout(timer); }
			ro.disconnect();
		};
	}, [ ref.current ]);

	return { ref, width: currentWidth };
};

export interface IUseScrollElement {
	ref: any
	containerRef: any
	scroll: number
	containerWidth: number
};

export function useScrollElement (): IUseScrollElement {
	const [ scroll, setScroll ] = useState(0);

	const container = useElementWidth(1000);
	const current = useElementWidth(100);

	useEffect(() => {
		setScroll(current.width - container.width);
	}, [ current.width, container.width ]);

	return { ref: current.ref, containerRef: container.ref, scroll, containerWidth: container.width };
};

export type ISxAdaptive = {
	[K in IBreakpointCodes]?: SxProps<Theme>
};

type ISxObjectAdaptive<T> = {
	[K in keyof T]: ISxAdaptive
};

export function useAdaptive (asx: ISxAdaptive, breakpoint: IThemeBreakpoints = useBreakpoint()) {
	return adaptiveReduce(asx, breakpoint) as SxProps<Theme>;
};

export function useArrayAdaptive (asx: Array<ISxAdaptive>, breakpoint: IThemeBreakpoints = useBreakpoint()): Array<SxProps<Theme>> {
	return asx.reduce((ar: any, a) => {
		return ar.push(adaptiveReduce(a, breakpoint));
	}, [] as Array<SxProps<Theme>>);
};

export function useObjectAdaptive<T> (asx: ISxObjectAdaptive<T>, breakpoint: IThemeBreakpoints = useBreakpoint()): ISxObjectAdaptive<T> {
	return Object.keys(asx).reduce((ar: any, key) => {
		ar[key as keyof T] = adaptiveReduce(asx[key as keyof T], breakpoint);
		return ar;
	}, {} as ISxObjectAdaptive<T>);
};

export function adaptiveReduce<T> (asx: T, breakpoint: IThemeBreakpoints): SxProps<Theme> {
	const codes = breakpoint;
	let sx: SxProps<Theme> = {};
	function reduceProps (prop: SxProps<Theme>, sx: SxProps<Theme>) {
		return Object.keys(prop as object).filter((key) => key.includes("&")).map((key) => {
			const existProps = sx && sx[key as keyof SxProps<Theme>] ? sx[key as keyof SxProps<Theme>] : {};
			const newProps = prop && prop[key as keyof SxProps<Theme>] ? prop[key as keyof SxProps<Theme>] : {};
			return { [key]: { ...existProps, ...newProps } };
		}).reduce((newSx, item) => { return { ...newSx, ...item }; }, {});
	}
	(codes as Array<keyof T>)?.map((key: keyof T) => {
		if (asx[key]) { sx = { ...sx, ...asx[key], ...reduceProps(asx[key] as SxProps<Theme>, sx) }; };
		return null;
	}, {});
	return sx as SxProps<Theme>;
};
