import * as React from "react";
import { Select } from "../../../../components/Form/Select";
import { Option } from "../../../../components/Form/Option";
import { useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { Input } from "../../../../components/Form/Input";
import { debitValidationSchema } from "./validation";
import { FileSelect } from "../../../../components/Form/FileSelect";
import { getInfo } from "./api";
import { Form } from "../../../../components/Form";
import { FormBottomWrapper } from "../../../../components/Form/FormBottomWrapper";
import { ButtonWithLoader } from "../../../../components/ButtonWithLoader";
import { getReceivedValue } from "../../../../utils/getReceivedValue";
import { Box } from "@mui/material";
import { WaningNote } from "../../../../components/UI/Note";
import { BonusSelector } from "../../../../components/Form/BonusSelector";
import { InputRepeater } from "../../../../components/Form/InputRepeater";

const USD_THB = 34.57;

export function ThunderXPay () {
	const redirectRef = React.useRef<HTMLInputElement>(null);
	const [ isFetch, setIsFetch ] = React.useState(false);
	const [ thunderXData, setThunderXData ] = React.useState<{[key: string]: string}>({});
	const language = useAppSelector((state) => state.language);
	const currency = useAppSelector((state) => state.session.userData.currency ?? "USD");
	const accountNumber = useAppSelector((state) => `${state.session.userData.account_number ?? ""}`);

	const getReceivedValueCB = React.useCallback((values: any) => getReceivedValue(values, currency), [ currency ]);

	const onSubmit = React.useCallback(async (values: any) => {
		try {
			setIsFetch(true);
			const info = await getInfo({ ...values, amount: values.amount * USD_THB });
			setThunderXData(info);
			if (redirectRef.current) {
				redirectRef.current?.click();
			}
		} catch (e) {
			// handle some error
		} finally {
			setIsFetch(false);
		}
	}, []);

	function calcInTHB (values: [string]) {
		const [ amount ] = values;
		return amount ? (parseInt(amount) * USD_THB).toFixed(3) + " THB" : "0 THB";
	}

	return (
		<>
			<Box sx={{ marginTop: "20px" }}>
				<Form
					onSubmit={onSubmit}
					validationSchema={debitValidationSchema(language.languageFinance)}
					defaultValues={{
						accountNumber,
						amount: "",
						banks: "TTB",
						bankAccountNumber: "",
						idCardNumber: "",
						fullName: "",
						email: "",
						number: "",
						cardImage: {},
						bonuses: {}
					}}
				>
					<Input
						name="accountNumber"
						label={language.languageCopytrade.cpy_mon_accnum}
						variant="rounded"
					/>
					<Input
						name="amount"
						label={language.languageFinance.s_03}
						variant="rounded"
					/>
					<InputRepeater
						fields="amount"
						label=""
						onChange={calcInTHB}
					/>
					<Select
						name="banks"
						label={language.languageFinance.d_01}
					>
						<Option value="TTB">TTB Bank</Option>
						<Option value="UOB">United Overseas Bank</Option>
						<Option value="BAAC">BAAC</Option>
						<Option value="CIMB">CIMB Bank</Option>
						<Option value="CITI">Citibank</Option>
						<Option value="KBANK">Kasikornbank</Option>
						<Option value="BBL">Bangkok Bank</Option>
						<Option value="BAY">Bank of Ayudhya</Option>
						<Option value="KTB">Krungthai Bank</Option>
					</Select>
					<Input
						name="bankAccountNumber"
						label={language.languageFinance.wunip_06}
						variant="rounded"
					/>
					<Input
						name="idCardNumber"
						label={language.languageFinance.d_02}
						variant="rounded"
					/>
					<Input
						name="fullName"
						label={language.languageMyAccounts.accver_11}
						variant="rounded"
					/>
					<Input
						name="email"
						type="email"
						label={language.languageMyAccounts.perdet_08}
						variant="rounded"
					/>
					<Input
						name="number"
						type="number"
						label={language.languageFinance.int_reg_45}
						variant="rounded"
					/>
					<FileSelect
						name="cardImage"
						accept=".jpeg,.png,.jpg"
						label={"Card Image (JPEG/PNG)"}
					/>
					<BonusSelector
						name="bonuses"
						label="Bonus"
					/>
					<FormBottomWrapper
						forFields={[ "bonuses", "amount", "currency" ]}
						onChange={getReceivedValueCB}
						label={language.languageMyAccounts.form_message_01}
					>
						<ButtonWithLoader
							isFetch={isFetch}
							color={"primary"}
							text={language.languageMyAccounts.chapas_04}
						/>
					</FormBottomWrapper>
					<Box sx={{ marginTop: "6px" }}>
						<WaningNote
							message={language.languageFinance.s_06}
						/>
					</Box>
				</Form>
			</Box>
			<form action={thunderXData.link} method="POST">
				{
					Object.keys(thunderXData).map((key: string) => (
						<input key={key} type="hidden" name={key} value={thunderXData[key]} />
					))
				}
				<input ref={redirectRef} style={{ display: "none" }} type="submit" value="Pay!" />
			</form>
		</>

	);
}
