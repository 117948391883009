import * as React from "react";
import { Box, Button } from "@mui/material";
import { useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { ISxAdaptive, useAdaptive } from "../../../../utils/hooks/themeBreakpointsHooks";
import { ButtonWithLoader } from "../../../ButtonWithLoader";

export interface IModalButtonsProps {
	onClose: () => void;
	isLoad: boolean;
}

const buttonAdaptiveStyles: ISxAdaptive = {
	xs: {
		width: "50%"
	},
	sm: {
		width: "auto"
	}
};

const sendButtonContainerAdaptiveStyles: ISxAdaptive = {
	xs: {
		maxWidth: "50%",
		width: "100%",
		paddingLeft: "15px"
	},
	sm: {
		width: "auto"
	}
};

const sendButtonAdaptiveStyles: ISxAdaptive = {
	xs: {
		maxWidth: "100%",
		width: "100%",
		padding: "13px 15px"
	},
	sm: {
		maxWidth: "120px"
	}
};

export function ModalButtons (props: IModalButtonsProps) {
	const { onClose, isLoad } = props;
	const myAccountLang = useAppSelector((state) => state.language.languageMyAccounts);
	const initLang = useAppSelector((state) => state.language.language);

	const buttonAdaptive = useAdaptive(buttonAdaptiveStyles);
	const sendButtonAdaptive = useAdaptive(sendButtonAdaptiveStyles);
	const sendButtonContainerAdaptive = useAdaptive(sendButtonContainerAdaptiveStyles);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "flex-end",
				padding: "21px 28px"
			}}
		>
			<Button variant="contained" color="default" onClick={onClose} sx={buttonAdaptive}>
				{initLang.fid_bck_19}
			</Button>
			<ButtonWithLoader
				color="success"
				isFetch={isLoad}
				text={myAccountLang.chapas_04}
				sx={sendButtonContainerAdaptive}
				sxButton={sendButtonAdaptive}
			/>
		</Box>
	);
}
