/*
    Created on : 21.10.2021, 06:18:59
    Author     : Andy Kar
*/

import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { PageAuth } from "./pages/Auth/PageAuth/PageAuth";
import PageForgotPass from "./pages/Auth/PageForgotPass/PageForgotPass";
import { TFAVerify } from "./pages/Auth/TFAVerify";

export default function AuthSwitch ({ logout }: { logout: boolean }) {
	return (
		<Switch>
			<Route path="/app/client/signin/:token?" render={() => <PageAuth/>}/>
			<Route path="/app/partner/signin/:token?" render={() => <PageAuth/>}/>
			<Route path="/app/verify-tfa" render={() => <TFAVerify />}/>
			<Route path="/app/forgot-password" render={() => <PageForgotPass/>}/>
			{logout
				? <Redirect from="*" to={"/app/" + logout} />
				: <Redirect from="*" to="/app/client/signin"/>
			}
		</Switch>
	);
};
