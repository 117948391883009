/*
    Created on : 13.06.2021, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import AppBarAuthLang from "./dashboardComponents/AppBarAuthLang";
import AppBarAuthMobileBurgerButton from "./dashboardComponents/AppBarAuthMobileBurgerButton";
import DashboardAuthAppBarWrapper from "./dashboardComponents/DashboardAuthAppBarWrapper";

export default function DashboardAuthAppBar ({ setOpen }: any) {
	return (
		<DashboardAuthAppBarWrapper>
			<AppBarAuthMobileBurgerButton setOpen={setOpen}/>
			<AppBarAuthLang/>
		</DashboardAuthAppBarWrapper>
	);
}
