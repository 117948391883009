import * as React from "react";
import { Form } from "../../../../components/Form";
import { Input } from "../../../../components/Form/Input";
import { getWithdrawalValue } from "../../../../utils/getReceivedValue";
import { useAppSelector } from "../../../../utils/hooks/reduxHooks";
import { asianBankDepositValidationSchema } from "./validation";
import { FormBottomWrapper } from "../../../../components/Form/FormBottomWrapper";
import { ButtonWithLoader } from "../../../../components/ButtonWithLoader";
import API from "../../../../utils/API";
import { AxiosResponse } from "axios";
import { Alert, Snackbar } from "@mui/material";
import { useErrorHandling } from "../../../../utils/hooks/useErrorHandling";
import { InputRepeater } from "../../../../components/Form/InputRepeater";

export function AsianBank () {
	const [ isFetch, setIsFetch ] = React.useState(false);
	const language = useAppSelector((state) => state.language);
	const accountNumber = useAppSelector((state) => `${state.session.userData.account_number ?? ""}`);
	const phone = useAppSelector((state) => state.user.user.phone1);
	const [ firstName, lastName ] = useAppSelector((state) => state.user.user.full_name?.split(" "));
	const currency = useAppSelector((state) => state.session.userData.currency ?? "USD");
	const { isOpen, errorMsg, hideError, showError } = useErrorHandling();

	const getWithdrawalValueCB = React.useCallback((values: any) => getWithdrawalValue(values, currency), [ currency ]);

	async function submit (values: { amount: string, firstName: string, lastName: string, phone: string}) {
		setIsFetch(true);
		try {
			const formData = new FormData();
			formData.append("amount", values.amount);
			formData.append("amount1", values.amount);
			formData.append("first_name", values.firstName);
			formData.append("last_name", values.lastName);
			formData.append("phone_number", values.phone);

			const { data } = await API.post<unknown, AxiosResponse<unknown>>("depositAsianBank", formData);
			console.log(data);

			showError("Fix me");
		} catch (e) {
			// handle error
		} finally {
			setIsFetch(false);
		}
	}

	function calcInMYR (values: [string]) {
		const [ amount ] = values;
		return amount ? (parseInt(amount) * 4.66).toFixed(2) + " MYR" : "0 MYR";
	}

	return (
		<>
			<Form
				onSubmit={submit}
				validationSchema={asianBankDepositValidationSchema()}
				defaultValues={{
					accountNumber,
					amount: "",
					firstName,
					lastName,
					phone
				}}
			>
				<Input
					name="accountNumber"
					label={language.languageCopytrade.cpy_mon_accnum}
					variant="rounded"
				/>
				<Input
					name="amount"
					label={language.languageFinance.s_03}
					variant="rounded"
				/>
				<InputRepeater
					fields="amount"
					label={""}
					onChange={calcInMYR}
				/>
				<Input
					name="firstName"
					label={language.languageMyAccounts.fn_01}
					variant="rounded"
				/>
				<Input
					name="lastName"
					label={language.languageMyAccounts.ln_01}
					variant="rounded"
				/>
				<Input
					name="phone"
					label={language.languageFinance.int_reg_45}
					variant="rounded"
				/>
				<FormBottomWrapper
					forFields={[ "amount", "currency" ]}
					onChange={getWithdrawalValueCB}
					label={language.languageMyAccounts.form_message_01}
				>
					<ButtonWithLoader
						isFetch={isFetch}
						color={"primary"}
						text={language.languageMyAccounts.chapas_04}
					/>
				</FormBottomWrapper>
			</Form>
			<Snackbar open={isOpen} autoHideDuration={4000} onClose={hideError}>
				<Alert variant="filled" color="error">
					{errorMsg}
				</Alert>
			</Snackbar>
		</>
	);
}
