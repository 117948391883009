import * as React from "react";
import TableRow from "@mui/material/TableRow";
import FMTableCells from "./FMTableCells";
import { IFMTableMobileScheme } from "./mobileFMTable";
import { IFMTableRowObj } from "./formatDataFMTable";
import { IFMTableTheme, useFMTableTheme } from "./FMTableThemes";

interface IFMTableRow<T, M> {
	index: number
	row: IFMTableRowObj<T, M>
	page: number
	componentData?: M
	toggle: (index:number) => void
	open: number[]
	mobileScheme?: IFMTableMobileScheme
	mobileAutoSlice?: number
	breakpoint: number
}

export default function FMTableRow<T, M> (props: IFMTableRow<T, M>) {
	const { index, row, toggle, open, mobileScheme, mobileAutoSlice, breakpoint, page, componentData } = props;
	const theme: IFMTableTheme = useFMTableTheme();
	return (
		<TableRow
			tabIndex={-1}
			key={index}
			sx={{
				backgroundColor: index % 2 && theme ? theme.TableRowBgColor : "transparent",
				borderBottom: theme.TableCellBorderColor === "transparent" || theme.TableCellBorderColor === "#fff" ? "none" : "1px solid",
				borderColor: theme.TableCellBorderColor,
				"&:last-of-type": {
					borderBottom: "none"
				}
			}}
		>
			<FMTableCells
				row={row}
				page={page}
				componentData={componentData}
				index={index}
				toggle={toggle}
				open={open}
				mobileScheme={mobileScheme}
				mobileAutoSlice={mobileAutoSlice}
				breakpoint={breakpoint}
			/>
		</TableRow>
	);
};
