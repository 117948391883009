import * as React from "react";
import {
	DepositMobileMoneyForm,
	TMobileMoneyFormSubmitValues
} from "../../../../../components/Forms/DepositMobileMoneyForm";
import { MobileMoneyFormError } from "../../../../../utils/errors/MobileMoneyFormError";
import API from "../../../../../utils/API";
import { AxiosResponse } from "axios";

export function Zamtel () {
	async function submit (values: TMobileMoneyFormSubmitValues) {
		const formData = new FormData();

		formData.append("amount", values.amount);
		formData.append("currency", "USD");
		formData.append("first_name", values.firstName);
		formData.append("last_name", values.lastName);
		formData.append("phone_number", values.phone);
		formData.append("address", values.address);
		formData.append("city", values.city);
		formData.append("zip", values.zip);

		const { data } = await API.post<unknown, AxiosResponse<{ redirect: string, error: { amount: string } }>>("depositZamtel", formData);

		if (Object.keys(data.error).length) {
			throw new MobileMoneyFormError(data.error.amount);
		}

		window.open(data.redirect, "_target");
	}

	return (
		<DepositMobileMoneyForm
			submit={submit}
			currency="TZS"
		/>
	);
}
