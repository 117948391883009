/*
    Created on : 14.11.2022, 06:18:59
    Author     : Andy Kar
*/
import React from "react";
import { Box, Button, List } from "@mui/material";
import CustomButton from "../../../../components/CustomButton";
import { styled } from "@mui/system";
import { FaLocationArrow, FaRegTrashAlt } from "react-icons/fa";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { AiOutlineFileImage, AiOutlineFilePdf } from "react-icons/ai";
import ListItemText from "@mui/material/ListItemText";
import { FMAlert } from "../../../../components/Form/Swal/FMAlert";
import { ISetState } from "../../../../types/common";
import { useSelector } from "react-redux";
import { StoreInterface } from "../../../../interfaces/StoreInterfaces";

const AttachIcon = styled(FaLocationArrow)`margin-right: 4px`;

export type IAttachFiles = {
	files: Array<File>
	setFiles: ISetState
	inputRef: any
	name: string
	maxFiles?: number
	accept? : string // "image/x-png, image/png, image/jpg, image/jpeg, application/pdf"
}

export default function AttachFiles (props: IAttachFiles) {
	const { files, setFiles, inputRef, name, maxFiles = 3, accept = "image/x-png, image/png, image/jpg, image/jpeg, application/pdf" } = props;
	const lang:any = useSelector((state:StoreInterface) => state.language.languageMailSupport);

	async function selectFile () {
		if (files.length >= maxFiles) {
			await FMAlert.fire({
				html: lang.ms_24,
				color: "#ED1F24"
			});
			return null;
		}

		if (inputRef.current) inputRef.current.click();
	}

	const stringifyFile = (file: File) => {
		return JSON.stringify([ file.lastModified, file.name, file.size, file.type ]);
	};

	const stringifyFiles = (files: Array<File>) => {
		return files.reduce((acc: Array<string>, el) => {
			acc.push(stringifyFile(el));
			return acc;
		}, []);
	};

	const existFiles = stringifyFiles(files);

	const fileExist = (file: File): boolean => {
		const currentFile = stringifyFile(file);
		return existFiles.includes(currentFile);
	};

	async function reduceFiles (filesArr: Array<File>) {
		const newFilesArr = filesArr.filter(file => !fileExist(file));
		const filesLength = files.length + newFilesArr.length;
		if (filesLength > maxFiles) {
			await FMAlert.fire({
				html: lang.ms_24,
				color: "#ED1F24"
			});
			newFilesArr.length = maxFiles - files.length;
		}
		return newFilesArr;
	}

	async function onChange (e: any) {
		const eFiles = e.target.files;
		const filesArr = Array.prototype.slice.call(eFiles);
		const newFilesArr: Array<File> = await reduceFiles(filesArr);
		setFiles([ ...files, ...newFilesArr ]);
	};

	const removeFiles = (f: File) => {
		setFiles(files.filter(x => x !== f));
	};

	function fileListItems () {
		return files.map((file, i) => (
			<ListItem key={i} className="flexRow">
				<ListItemIcon>
					{file.type.includes("image/")
						? <AiOutlineFileImage color="#2988CA" fontSize={26}/>
						: <AiOutlineFilePdf color="#2988CA" fontSize={26}/>
					}
				</ListItemIcon>
				<ListItemText primary={file.name} secondary={file.size}/>
				<Button
					onClick={() => removeFiles(file)}
					type="button"
				>
					<FaRegTrashAlt onClick={() => removeFiles(file)} color="#ED1F24" fontSize={16}/>
				</Button>
			</ListItem>
		));
	};

	return (
		<Box className="flexRow" sx={{
			flex: "1",
			justifyContent: "space-between",
			"& ul": { padding: "0" },
			"& li": { padding: "0 10px" }
		}}>
			<input
				style={{ display: "none" }}
				type="file"
				accept={accept}
				name={name}
				ref={inputRef}
				multiple
				onChange={onChange}
			/>
			<List>
				{fileListItems()}
			</List>
			<Box className="flexRow">
				<CustomButton onClick={selectFile} color={"success"} text={lang.ms_21} iconStart={<AttachIcon />} type="button"/>
			</Box>
		</Box>
	);
};
