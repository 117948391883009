import * as React from "react";
import { IFMTableComponentProps } from "../../../../components/FMTable/FMTableCellComponent";
import { StoreInterface } from "../../../../interfaces/StoreInterfaces";
import { ResponseGenerator } from "../../../../interfaces/mainInterfaces";
import { useSelector } from "react-redux";
import { IPendingTransaction } from "../../../../store/reducers/financeReducer";
import { Button } from "@mui/material";
import { useState } from "react";
import API from "../../../../utils/API";
import { FMAlert } from "../../../../components/Form/Swal/FMAlert";

export function PendingTransactionActionCell (props: IFMTableComponentProps<IPendingTransaction, {}>) {
	const { isBlock, ticket } = props;
	const langFinance:any = useSelector((state:StoreInterface) => state.language.languageFinance);
	const [ , setSuccessRecall ] = useState({});

	const recallTransfer = () => {
		FMAlert.fire({
			text: "Are you sure you want to proceed?",
			confirmButtonText: "Yes",
			showCancelButton: true,
			cancelButtonText: "Cancel",
			cancelButtonColor: "#d9534f",
			confirmButtonColor: "#5cb85c"
		}).then((result) => {
			if (result.isConfirmed) {
				const recallTransferData = new FormData();
				recallTransferData.append("ticket", ticket as string);
				return sendRecallTransferData(recallTransferData);
			}
		});
	};

	async function sendRecallTransferData (recallTransferData: {}) {
		const response:ResponseGenerator = await API.post("financeRecallTransaction", recallTransferData);
		setSuccessRecall(response.data || {});
		if (response.data && response.data.success) {
			await FMAlert.fire({
				text: "",
				color: "green",
				icon: "success"
			});
		}
		if (response.data && !response.data.success) {
			await FMAlert.fire({
				text: "Recall error",
				color: "red",
				icon: "error"
			});
		}
	};

	const blockMessage = () => {
		FMAlert.fire({
			text: "Your withdrawal request is under processing.",
			color: "red",
			icon: "error"
		});
	};

	return <Button
		variant="text"
		onClick={ isBlock ? recallTransfer : blockMessage }
		sx={{
			padding: 0,
			width: "100%"
		}}
	>
		{langFinance.frahis_12}
	</Button>;
}
