/*
    Created on : 28.02.2022, 06:18:59
    Author     : Andy Kar
*/

import React, { useState } from "react";
import "./AccountsSwapCell.scss";
import { useDispatch, useSelector } from "react-redux";
import { SEND_SWAP_INFO } from "../../../store/actions/actionTypes";
import { AccountsInterface } from "../../../interfaces/myAccountsInterfaces";
import { StoreInterface } from "../../../interfaces/StoreInterfaces";
import { Box, Checkbox } from "@mui/material";

function AccountsSwapCell (props:any) {
	const myAccounts:AccountsInterface = useSelector((state:StoreInterface) => state.myAccounts.myAccounts);
	const [ swap, setSwap ] = useState(!!parseInt(props?.swap_free || "0"));
	const toggleSwap = () => {
		if (swap) {
			setSwap(false);
			updateSwap("0");
		} else {
			setSwap(true);
			updateSwap("1");
		}
	};

	const dispatch = useDispatch();
	const updateSwap = (swapVal:string) => {
		const swapData = new FormData();
		swapData.append("swap", swapVal);
		dispatch({ type: SEND_SWAP_INFO, swapData });
	};
	const disabled = !!(myAccounts.reg_loc && (myAccounts.reg_loc === 7 || myAccounts.reg_loc === "7")) || (props && !props.id);

	return (
		<Box justifyContent={"center"}>
			<Checkbox disabled={disabled} onChange={toggleSwap}></Checkbox>
		</Box>
	);
}

export default AccountsSwapCell;
